import { dateFormatter, booleanFormatter } from '../../../../common/utils/formatters';

export const pgSelectItemsMapper = lsi => priceGuarantee => ({
  value: priceGuarantee.id.toString(),
  content: selectContentFormatter(priceGuarantee, lsi),
  priceGuarantee: priceGuarantee
});

const selectContentFormatter = (priceGuarantee, lsi) => {
  if (!priceGuarantee || !lsi) {
    return 'N/A';
  }

  const { status, marketingActionCode, name,
    commissionCreationDateTo, commissionProduceWeekFirstTo, commissionSellingDateTo,
    type, daysFromInvoiceToSale, twoWeeksAfterSale
  } = priceGuarantee;

  const formatters = lsi.getFormatters();

  return contentFormat(
    lsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.PRICE_GUARANTEE_STATUS.${status}`),
    marketingActionCode,
    name,
    `${lsi.getLSIItem('PRICE_GUARANTEE.LABEL.COMMISSION_CREATION_DATE_TO')} ${dateFormatter(commissionCreationDateTo, formatters)}`,
    `${lsi.getLSIItem('PRICE_GUARANTEE.LABEL.COMMISSION_PRODUCE_WEEK_FIRST_TO')} ${commissionProduceWeekFirstTo}`,
    `${lsi.getLSIItem('PRICE_GUARANTEE.LABEL.COMMISSION_SELLING_DATE_TO')} ${dateFormatter(commissionSellingDateTo, formatters)}`,
    `${lsi.getLSIItem('PRICE_GUARANTEE.LABEL.EVALUATION_VARIANT')} ${lsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.PRICE_GUARANTEE_TYPE.${type}`)}`,
    `${lsi.getLSIItem('PRICE_GUARANTEE.LABEL.DAYS_FROM_INVOICE_TO_SALE')} ${daysFromInvoiceToSale}`,
    `${lsi.getLSIItem('PRICE_GUARANTEE.LABEL.TWO_WEEKS_AFTER_SALE')} ${booleanFormatter(twoWeeksAfterSale, lsi)}`
  );
};

const contentFormat = (
  statusLabel, marketingActionCode, name,
  commissionCreationDateToLabel, commissionProduceWeekFirstToLabel, commissionSellingDateToLabel,
  typeLabel, daysFromInvoiceToSaleLabel, twoWeeksAfterSaleLabel
) => {
  return `${statusLabel} - ${marketingActionCode} (${name}) - ${typeLabel}, ${commissionCreationDateToLabel}, ${commissionSellingDateToLabel}, ${daysFromInvoiceToSaleLabel}, ${twoWeeksAfterSaleLabel}`;
};
