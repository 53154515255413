import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const commissionsApi = ApiServiceInstances.annualTarget.commissionsApi;

export const SAVE_COMMISSION_LOAD_PARAMS_DEALER_REQUEST = 'SAVE_COMMISSION_LOAD_PARAMS_DEALER_REQUEST';
export const SAVE_COMMISSION_LOAD_PARAMS_DEALER_SUCCESS = 'SAVE_COMMISSION_LOAD_PARAMS_DEALER_SUCCESS';
export const SAVE_COMMISSION_LOAD_PARAMS_DEALER_FAILURE = 'SAVE_COMMISSION_LOAD_PARAMS_DEALER_FAILURE';

export const SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_REQUEST = 'SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_REQUEST';
export const SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_SUCCESS = 'SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_SUCCESS';
export const SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_FAILURE = 'SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_FAILURE';

const initialState = {
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COMMISSION_LOAD_PARAMS_DEALER_REQUEST:
    case SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_REQUEST: {
      return { ...state, isFetching: true };
    }
    case SAVE_COMMISSION_LOAD_PARAMS_DEALER_SUCCESS:
    case SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case SAVE_COMMISSION_LOAD_PARAMS_DEALER_FAILURE:
    case SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleSaveCommissionsLoadParamsForDealer = (commissionLoadParamsDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => commissionsApi.saveCommissionsLoadParamsForDealerWithHttpInfo(commissionLoadParamsDTO),
      types: [SAVE_COMMISSION_LOAD_PARAMS_DEALER_REQUEST, SAVE_COMMISSION_LOAD_PARAMS_DEALER_SUCCESS, SAVE_COMMISSION_LOAD_PARAMS_DEALER_FAILURE]
    }
  };
};

export const handleSaveCommissionsLoadParamsForImporter = (commissionLoadParamsDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => commissionsApi.saveCommissionsLoadParamsForImporterWithHttpInfo(commissionLoadParamsDTO),
      types: [SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_REQUEST, SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_SUCCESS, SAVE_COMMISSION_LOAD_PARAMS_IMPORTER_FAILURE]
    }
  };
};

