import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  modelGroupMap: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired // property from AG Grid
};

const defaultProps = {};

const ModelGroupLabelRowInnerRenderer = (props) => {
  const modelGroup = props.value;
  const modelGroupData = props.modelGroupMap[modelGroup];

  return (
    <div
      className='ovex-agg-ModelGroupLabelRowInnerRenderer'
    >
      <strong>{modelGroup}</strong>
      {modelGroupData ? ` - ${modelGroupData.name} ` : ' '}
    </div>
  );
};

ModelGroupLabelRowInnerRenderer.propTypes = propTypes;
ModelGroupLabelRowInnerRenderer.defaultProps = defaultProps;

export default ModelGroupLabelRowInnerRenderer;
