import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import { resolveCellClass } from '../../ValidationResultPGItemTable.helpers';
import { RowType } from '../../ValidationResultPGItemTable.const';
import { nonGroupNode } from '../../../../../../../common/components/ag-grid';

class PgidCUID extends AbstractColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.PGID;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList } = additionalParams;

    return {
      colId: this.getCode(),
      hide: !visible,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PGID'),
      cellClass: resolveCellClass(''),
      valueGetter: valueGetterParams => {
        const data = valueGetterParams.data;
        if (nonGroupNode(valueGetterParams) && RowType.isPGItem(data.rowType)) {
          return data.pgid;
        }
        return null;
      },
      valueFormatter: valueFormatterParams => {
        const data = valueFormatterParams.data;
        if (nonGroupNode(valueFormatterParams) && RowType.isCommission(data.rowType)) {
          return valueFormatterParams.context.agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_ITEM_ROW_TYPE.COMMISSION');
        } else if (nonGroupNode(valueFormatterParams) && RowType.isPGItem(data.rowType)) {
          return valueFormatterParams.value;
        }
        return '';
      },
      type: [...columnTypeList],
      filter: 'agTextColumnFilter',
      pinned: 'left',
      width: 208,
      minWidth: 208
    };
  }
  /** @override */
  excelExportValueFormatter = (processCellForExportParams) => {
    return processCellForExportParams.column.colDef.valueFormatter({
      ...processCellForExportParams,
      data: processCellForExportParams.node.data
    });
  }
}

const pgidCUID = new PgidCUID();

export default pgidCUID;