import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { LsiContext } from '../../../packages/common/contexts';
import { AlertTypeEnum } from '../../../packages/common/objects';
import { labelNotFoundFormatter } from '../../../packages/common/utils/formatters';

import './AlertContent.scss';

const getIcon = (level) => {
  if (level.toUpperCase() === 'INFO') {
    return 'mdi-information-outline';
  }
  if (level.toUpperCase() === 'WARNING') {
    return 'mdi-alert-outline';
  }
  if (level.toUpperCase() === 'ERROR') {
    return 'mdi-alert';
  }
  return null;
};

const AlertContent = (props) => {
  const lsi = React.useContext(LsiContext);

  const classNames = ['ovex-AlertContent'];
  props.className && classNames.push(props.className);

  const _getMessage = () => {
    if (props.message != null) {
      return props.message;
    } else if (props.lsiCode != null) {
      return lsi.getLSIItem(props.lsiCode);
    }
    return labelNotFoundFormatter(props.code);
  };

  const _renderUl = () => {
    const errorLi = props.level === 'ERROR' ? (<Bricks.Li content={props.uuid} />) : null;
    const parametersLi = (props.message == null && props.parameters != null) ? (<Bricks.Li content={props.parameters.join(', ')} />) : null;

    if (errorLi == null && parametersLi == null) {
      return null;
    }
    return (
      <Bricks.Ul>
        {errorLi}
        {parametersLi}
      </Bricks.Ul>
    );
  };

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      <Bricks.Div
        className='ovex-AlertContent-icon'
      >
        <Bricks.Icon
          icon={getIcon(props.level)}
        />
      </Bricks.Div>
      <Bricks.Div
        className='ovex-AlertContent-content'
      >
        <Bricks.Span
          className='ovex-AlertContent-content-message'
          content={_getMessage()}
        />
        {_renderUl()}
      </Bricks.Div>
    </Bricks.Div>
  );
};

AlertContent.propTypes = {
  className: PropTypes.string,
  code: PropTypes.string.isRequired,
  level: PropTypes.oneOf([AlertTypeEnum.INFO, AlertTypeEnum.WARNING, AlertTypeEnum.ERROR]).isRequired,
  lsiCode: PropTypes.string,
  message: PropTypes.string,
  parameters: PropTypes.arrayOf(PropTypes.any),
  uuid: PropTypes.string.isRequired
};

AlertContent.defaultProps = {
  className: null,
  lsiCode: null,
  message: null,
  parameters: null
};

export default AlertContent;