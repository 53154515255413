import React from 'react';
import PropTypes from 'prop-types';
import { PriceGuaranteeDTOPUB, PriceGuaranteeItemDTOPUB } from '@ovex/price-guarantee-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { useAlertBusOvex, usePrivilege } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';
import { PUBGetPriceGuaranteeItemsPFC } from '../../privilege/context';

import PriceGuaranteeItemsButtonBar from './PriceGuaranteeItemsButtonBar/PriceGuaranteeItemsButtonBar';
import PriceGuaranteeSelector from './PriceGuaranteeSelector/PriceGuaranteeSelector';
import PriceGuaranteeItemPublishedTable from './PriceGuaranteeItemPublishedTable/PriceGuaranteeItemPublishedTable';

import './PriceGuaranteeItemsPublished.scss';

const propTypes = {
  isFetching: PropTypes.bool,
  onCleanPriceGuaranteeItems: PropTypes.func.isRequired,
  onGetPriceGuaranteeItems: PropTypes.func.isRequired,
  onGetPriceGuaranteeList: PropTypes.func.isRequired,
  priceGuaranteeItemList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeItemDTOPUB)),
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTOPUB))
};

const defaultProps = {
  isFetching: true,
  priceGuaranteeItemList: null,
  priceGuaranteeList: null
};

const PriceGuaranteeItemsPublished = (props) => {
  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();
  const { handleIsPrivileged } = usePrivilege();

  const handleIsPrivilegedRef = React.useRef(handleIsPrivileged);
  handleIsPrivilegedRef.current = handleIsPrivileged;

  const { onGetPriceGuaranteeList, onGetPriceGuaranteeItems, onCleanPriceGuaranteeItems } = props;

  const handleReloadPriceGuaranteeList = React.useCallback(
    async () => {
      try {
        await onGetPriceGuaranteeList();
      } catch (e) {
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_LIST_ITM_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetPriceGuaranteeList, handleAddAlertSimple]
  );
  React.useEffect(
    () => {
      handleReloadPriceGuaranteeList().then();
    },
    [handleReloadPriceGuaranteeList]
  );

  const pgList = props.priceGuaranteeList;
  const pgSelected = React.useRef(null);

  const handleGetPriceGuaranteeItems = React.useCallback(
    async (priceGuarantee) => {
      pgSelected.current = priceGuarantee;

      if (priceGuarantee == null) {
        onCleanPriceGuaranteeItems();
        return;
      }

      if (handleIsPrivilegedRef.current(new PUBGetPriceGuaranteeItemsPFC(priceGuarantee.id))) {
        try {
          return await onGetPriceGuaranteeItems(priceGuarantee.id);
        } catch (e) {
          handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_ITEMS_ITM_FAILURE', AlertTypeEnum.WARNING);
        }
      } else {
        onCleanPriceGuaranteeItems();
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_ITEMS_PRIVILEGE_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetPriceGuaranteeItems, onCleanPriceGuaranteeItems, handleAddAlertSimple]
  );

  React.useEffect(
    () => {
      let selected;
      if (pgList && pgList.length > 0) {
        if (pgSelected.current != null) {
          selected = pgList.find(pg => pg.id === pgSelected.current.id);
        }
        if (selected == null) {
          selected = pgList[0];
        }
      }
      pgSelected.current = selected;
      handleGetPriceGuaranteeItems(selected).then();
    },
    [pgList, handleGetPriceGuaranteeItems]
  );

  return (
    <Page
      className='ovex-pg-PriceGuaranteeItemsPublished'
      header={lsi.getLSIItem('PRICE_GUARANTEE.PAGE_TITLE.PRICE_GUARANTEE_ITEMS_DEALER')}
      loading={props.isFetching}
    >
      <PriceGuaranteeSelector
        onChangePriceGuarantee={handleGetPriceGuaranteeItems}
        priceGuaranteeList={pgList}
        priceGuaranteeSelected={pgSelected.current}
      />
      <PriceGuaranteeItemsButtonBar
        onReload={handleReloadPriceGuaranteeList}
      />
      <PriceGuaranteeItemPublishedTable
        priceGuarantee={pgSelected.current}
        priceGuaranteeItemList={props.priceGuaranteeItemList}
      />
    </Page>
  );
};

PriceGuaranteeItemsPublished.propTypes = propTypes;
PriceGuaranteeItemsPublished.defaultProps = defaultProps;

export default PriceGuaranteeItemsPublished;
