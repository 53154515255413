import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  handleGetCommissionValidationResultDealerVLD,
  handleResetValidationResultVLD
} from '../../redux/modules/actions';
import routes from '../../routes';
import CommissionValidatorConnector from '../CommissionValidator/CommissionValidatorConnector';

const CommissionValidatorDealerReduxConnector = () => {
  const selectedState = useSelector(state => {
    return {
      pageStatus: state.priceGuarantee.commissionValidatorResult.pageStatus,
      validationResult: state.priceGuarantee.commissionValidatorResult.validationResult
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => ({
      handleGetCommissionValidationResultDealerVLD: (priceGuaranteeId, commissionNumber, order) => dispatch(handleGetCommissionValidationResultDealerVLD(priceGuaranteeId, commissionNumber, order)),
      handleResetValidationResultVLD: () => dispatch(handleResetValidationResultVLD())
    }),
    [dispatch]
  );

  return (
    <CommissionValidatorConnector
      onGetCommissionValidationResult={callbacks.handleGetCommissionValidationResultDealerVLD}
      onResetValidationResult={callbacks.handleResetValidationResultVLD}
      pageStatus={selectedState.pageStatus}
      routerPath={routes.paths.ROUTE_PATH_COMMISSION_VALIDATOR_DEALER}
      titleLsiCode='REPORT_COMMISSION_VALIDATOR_DEALER'
      validationResult={selectedState.validationResult}
    />
  );
};

export default CommissionValidatorDealerReduxConnector;
