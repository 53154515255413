import CommissionRow from './CommissionTable/CommissionRow';

class CommissionDTOConverter {

  constructor() {
    throw new Error('Utility class');
  }

  static convertFromCommissionDTOList = (dtoList) => {
    return dtoList
      ? dtoList.map(CommissionDTOConverter.convertFromCommissionDTO)
      : [];
  };

  static convertFromCommissionDTO = (dto) => {
    if (dto == null) {
      return null;
    }

    const row = new CommissionRow();

    row.aggregationType = dto.aggregationType;
    row.commission = dto.commission;
    row.commissionYear = dto.commissionYear;
    row.ownerDealerNumber = dto.ownerDealerNumber;
    row.branchOwnerDealerNumber = dto.branchOwnerDealerNumber;
    row.modelGroup = dto.modelGroup;
    row.status = dto.status;
    row.statusInterval = dto.statusInterval;
    row.insertTypeFactoryStock = dto.insertTypeFactoryStock;
    row.alwaysQ4 = dto.alwaysQ4;

    row.invoicedDealerNumber = dto.invoicedDealerNumber;
    row.preProductionWeek = dto.preProductionWeek;
    row.preProductionWeekYear = dto.preProductionWeekYear;
    row.preProductionWeekUnitOrder = dto.preProductionWeekUnitOrder;
    row.productionWeek = dto.productionWeek;
    row.productionWeekYear = dto.productionWeekYear;
    row.productionWeekUnitOrder = dto.productionWeekUnitOrder;
    row.invoicingDate = dto.invoicingDate;
    row.invoicingDateYear = dto.invoicingDateYear;
    row.invoicingDateUnitOrder = dto.invoicingDateUnitOrder;
    row.sellingDate = dto.sellingDate;
    row.sellingDateYear = dto.sellingDateYear;
    row.sellingDateUnitOrder = dto.sellingDateUnitOrder;

    return row;
  };

}

export default CommissionDTOConverter;

