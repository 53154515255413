import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const priceGuaranteeItemsPublishedApi = ApiServiceInstances.priceGuarantee.priceGuaranteeItemsPublishedApi;

export const GET_PRICE_GUARANTEE_LIST_PUB_REQUEST = 'GET_PRICE_GUARANTEE_LIST_PUB_REQUEST';
export const GET_PRICE_GUARANTEE_LIST_PUB_SUCCESS = 'GET_PRICE_GUARANTEE_LIST_PUB_SUCCESS';
export const GET_PRICE_GUARANTEE_LIST_PUB_FAILURE = 'GET_PRICE_GUARANTEE_LIST_PUB_FAILURE';

export const GET_PRICE_GUARANTEE_ITEMS_PUB_REQUEST = 'GET_PRICE_GUARANTEE_ITEMS_PUB_REQUEST';
export const GET_PRICE_GUARANTEE_ITEMS_PUB_SUCCESS = 'GET_PRICE_GUARANTEE_ITEMS_PUB_SUCCESS';
export const GET_PRICE_GUARANTEE_ITEMS_PUB_FAILURE = 'GET_PRICE_GUARANTEE_ITEMS_PUB_FAILURE';

export const CLEAR_PRICE_GUARANTEE_ITEMS_PUB = 'CLEAR_PRICE_GUARANTEE_ITEMS_PUB';

const initialState = {
  isFetching: false,
  error: null,
  priceGuaranteeList: null,
  priceGuaranteeItemList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRICE_GUARANTEE_LIST_PUB_REQUEST:
    case GET_PRICE_GUARANTEE_ITEMS_PUB_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_PRICE_GUARANTEE_LIST_PUB_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        priceGuaranteeList: action.payload
      };
    }
    case GET_PRICE_GUARANTEE_ITEMS_PUB_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        priceGuaranteeItemList: action.payload
      };
    }
    case GET_PRICE_GUARANTEE_LIST_PUB_FAILURE:
    case GET_PRICE_GUARANTEE_ITEMS_PUB_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case CLEAR_PRICE_GUARANTEE_ITEMS_PUB: {
      return {
        ...state,
        priceGuaranteeItemList: null
      };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetPriceGuaranteeListPUB = () => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeItemsPublishedApi.getPriceGuaranteeListPUBWithHttpInfo(),
      types: [GET_PRICE_GUARANTEE_LIST_PUB_REQUEST, GET_PRICE_GUARANTEE_LIST_PUB_SUCCESS, GET_PRICE_GUARANTEE_LIST_PUB_FAILURE]
    }
  };
};

export const handleGetPriceGuaranteeItemListPUB = (priceGuaranteeId) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeItemsPublishedApi.getPriceGuaranteeItemListPUBWithHttpInfo(priceGuaranteeId),
      types: [GET_PRICE_GUARANTEE_ITEMS_PUB_REQUEST, GET_PRICE_GUARANTEE_ITEMS_PUB_SUCCESS, GET_PRICE_GUARANTEE_ITEMS_PUB_FAILURE]
    }
  };
};

export const handleClearPriceGuaranteeItemListPUB = () => {
  return {
    type: CLEAR_PRICE_GUARANTEE_ITEMS_PUB
  };
};
