import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Bricks, Common } from 'uu5g04';
import 'uu5g04/bricks';

import { AlertTypeEnum } from '../../../packages/common/objects';
import { handleClearAlerts } from '../../../redux/modules/main/alertBus';

import AlertBus from './AlertBus';
import AlertContent from './AlertContent';

import './UUPageAlertBus.scss';

const propTypes = {
  className: PropTypes.string,
  ref_: PropTypes.func
};

const defaultProps = {
  className: null,
  ref_: undefined
};

/**
 * Toto reseni pres **addAlert** zpusobuje chybku v konzoli:
 * - *Warning: Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state.*
 *
 * @param {string} alertType
 * @param {object} content
 * @param {number} closeTimer
 * @param {function} onClose
 * @return {boolean} **true** if *alert* is successfully added to *alert bus*, otherwise ***false*
 */
const addAlert = (alertType, content, closeTimer, onClose) => {
  if (Common.Tools.getPage()) {
    Common.Tools.getPage().getAlertBus().addAlert({
      content: content,
      colorSchema: alertType,
      closeTimer: closeTimer,
      onClose: onClose
    });
    return true;
  }
  return false;
};

const logFailedAddedAlert = (alertItem) => {
  const content = JSON.stringify(alertItem);
  console.warn(`FAILED addition *alert item* to *alert bus*\n${content}`);
};

const getColorSchema = (level) => {
  if (level === AlertTypeEnum.INFO) {
    return 'info';
  }
  if (level === AlertTypeEnum.WARNING) {
    return 'warning';
  }
  if (level === AlertTypeEnum.ERROR) {
    return 'danger';
  }
  return null;
};

const getCloseTimer = (level) => {
  if (level === AlertTypeEnum.ERROR) {
    return 0;
  }
  return null; // default time from **AlertBus** will be used
};

const UUPageAlertBus = React.memo((props) => {
  const alertList = useSelector(state => state.main.alertBus.alertList);
  const dispatch = useDispatch();

  const classNames = ['ovex-UUPageAlertBus'];
  props.className && classNames.push(props.className);

  const handleClearAlertsCallback = React.useCallback((alertUuidList) => dispatch(handleClearAlerts(alertUuidList)), [dispatch]);

  const [countErrors, setCountErrors] = React.useState(0);
  const handleDecreaseCountErrors = React.useCallback(() => setCountErrors(prevCountErrors => prevCountErrors - 1), []);

  (0 < countErrors) && classNames.push('ovex-UUPageAlertBus--block');

  if (alertList.length) {
    let countNewErrors = 0;
    alertList.forEach(alert => {
      const uuid = alert.uuid;
      alert.alertItemList && alert.alertItemList.forEach(alertItem => {
        let handleCloseAlert;
        if (alertItem.level === AlertTypeEnum.ERROR) {
          countNewErrors++;
          handleCloseAlert = handleDecreaseCountErrors;
        }
        const content = (
          <AlertContent
            code={alertItem.code}
            level={alertItem.level}
            lsiCode={alertItem.lsiCode}
            message={alertItem.message}
            parameters={alertItem.parameters}
            uuid={uuid}
          />
        );
        const added = addAlert(getColorSchema(alertItem.level), content, getCloseTimer(alertItem.level), handleCloseAlert);
        if (!added && handleCloseAlert != null) {
          logFailedAddedAlert(alertItem);
          handleCloseAlert();
        }
      });
    });

    setCountErrors(prevCountErrors => prevCountErrors + countNewErrors);

    handleClearAlertsCallback(alertList.map(alert => alert.uuid));
  }

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      <AlertBus
        ref_={props.ref_}
      />
    </Bricks.Div>
  );
});

UUPageAlertBus.propTypes = propTypes;
UUPageAlertBus.defaultProps = defaultProps;

export default UUPageAlertBus;