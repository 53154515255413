import { privilegeEditable } from '../../../../common/components/ag-grid';
import { integerFormatter, simpleNullishValueFormatter } from '../../../../common/utils/formatters';
import { isString, parseNumber } from '../../../../common/utils/helpers';

import { AG_GROUP_TOTAL } from './const';

export const handleEditableRecommendedNumberColumn = (params) => {
  return params.context.editable
      && !params.node.group
      && params.data.isEditable()
      && privilegeEditable(params, params.data.privilegeFunctionalityContextObject.updateRecommendedFnCtx);
};

export const handleRecommendedValueParser = (valueParserParams) => {
  if (/^[0-9]{1,5}$/.test(valueParserParams.newValue)) {
    return parseNumber(valueParserParams.newValue);
  } else if (/^$|^-$/.test(valueParserParams.newValue)) {
    return '-';
  }
  return null;
};

export const handleRecommendedValueSetter = (valueSetterParams) => {
  if (isString(valueSetterParams.newValue)) {
    valueSetterParams.newValue = handleRecommendedValueParser(valueSetterParams);
  }
  if (valueSetterParams.newValue != null && valueSetterParams.newValue !== valueSetterParams.oldValue) {
    const newValue = valueSetterParams.newValue !== '-' ? valueSetterParams.newValue : null;
    valueSetterParams.data.setRecommendedNumber(newValue);
    return true;
  }
  return false;
};

export const handleNumberValueFormatter = (valueFormatterParams) => {
  const value = valueFormatterParams.value;
  const formatters = valueFormatterParams.context.agLsi.getFormatters();

  return simpleNullishValueFormatter(integerFormatter(value, formatters), '-');
};

export const handleInnerRendererTOTAL = (params) => {
  if (params.node.group && params.value === AG_GROUP_TOTAL) {
    return params.context.agLsi.getLSIItem('AG_GRID.ovexTotal');
  }
  return params.valueFormatted === null ? params.value : params.valueFormatted;
};
