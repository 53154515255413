import React from 'react';
import PropTypes from 'prop-types';
import {CommissionValidationResultDTOVLD, PriceGuaranteeDTOVLD} from '@ovex/price-guarantee-web-api';

import {Page} from '../../../common/components';
import {LsiContext} from '../../../common/contexts';
import {FormsContextSection} from '../../../common/components/forms';
import {useAlertBusOvex} from '../../../common/hooks';
import {AlertTypeEnum} from '../../../common/objects';

import CommissionValidatorParams from './CommissionValidatorParams/CommissionValidatorParams';
import CommissionValidatorButtonBar from './CommissionValidatorButtonBar/CommissionValidatorButtonBar';
import ValidationResult from './ValidationResult/ValidationResult';
import ValidationResultNotFound from './ValidationResultNotFound/ValidationResultNotFound';
import CommissionValidatorPageStatus from './CommissionValidatorPageStatus';

import './CommissionValidator.scss';

const propTypes = {
  commissionNumber: PropTypes.string,
  commissionOrder: PropTypes.string,
  marketingActionCode: PropTypes.string,
  onGetCommissionValidationResult: PropTypes.func.isRequired,
  onGetPriceGuaranteeList: PropTypes.func.isRequired,
  onResetValidationResult: PropTypes.func.isRequired,
  pageStatus: PropTypes.oneOf(Object.values(CommissionValidatorPageStatus)).isRequired,
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTOVLD)),
  titleLsiCode: PropTypes.string.isRequired,
  validationResult: PropTypes.instanceOf(CommissionValidationResultDTOVLD)
};

const defaultProps = {
  commissionNumber: null,
  commissionOrder: null,
  marketingActionCode: null,
  priceGuaranteeList: null,
  validationResult: null
};

const CommissionValidator = (props) => {
  const lsi = React.useContext(LsiContext);
  const {
    commissionNumber, commissionOrder, marketingActionCode,
    titleLsiCode, pageStatus, validationResult, priceGuaranteeList,
    onGetPriceGuaranteeList, onGetCommissionValidationResult, onResetValidationResult
  } = props;

  const { handleAddAlertSimple } = useAlertBusOvex();

  React.useEffect(
    () => {
      try {
        onGetPriceGuaranteeList();
      } catch (e) {
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_LIST_VLD_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetPriceGuaranteeList, handleAddAlertSimple]
  );

  const handleGetCommissionValidationResult = React.useCallback(
    async (formValues) => {
      try {
        await onGetCommissionValidationResult(formValues);
      } catch (e) {
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_COMMISSION_VALIDATION_RESULT_VLD_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetCommissionValidationResult, handleAddAlertSimple]
  );

  const priceGuarantee = priceGuaranteeList?.find(pg => pg.marketingActionCode === marketingActionCode);

  return (
    <Page
      className='ovex-pg-CommissionValidator'
      header={lsi.getLSIItem(`PRICE_GUARANTEE.PAGE_TITLE.${titleLsiCode}`)}
      loading={CommissionValidatorPageStatus.isLoading(pageStatus)}
    >
      <FormsContextSection>
        <CommissionValidatorParams
          commissionNumber={commissionNumber}
          commissionOrder={commissionOrder}
          marketingActionCode={marketingActionCode}
          onLoadValidationResult={handleGetCommissionValidationResult}
          onResetValidationResult={onResetValidationResult}
          orderList={validationResult?.cOrderList}
          priceGuaranteeList={priceGuaranteeList}
        />
        <CommissionValidatorButtonBar />
      </FormsContextSection>
      { CommissionValidatorPageStatus.isNotFound(pageStatus) &&
        <ValidationResultNotFound
          commissionNumber={commissionNumber}
          marketingActionCode={marketingActionCode}
          priceGuarantee={priceGuarantee}
        />
      }
      { CommissionValidatorPageStatus.isDataFound(pageStatus) && validationResult &&
        <ValidationResult
          validationResult={validationResult}
        />
      }
    </Page>
  );
};

CommissionValidator.propTypes = propTypes;
CommissionValidator.defaultProps = defaultProps;

export default CommissionValidator;
