import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class SCPSwapCommissionsPvtDealerFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PVT_SCP__SWAP_COMMISSIONS_SWAP_DEALER);
  }

}

export default SCPSwapCommissionsPvtDealerFunctionalityContext;