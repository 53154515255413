import React from 'react';
import { Route } from 'react-router-dom';

import { NotFound } from '../../layouts';

const NotFoundRoute = () => {
  return (
    <Route>
      <NotFound />
    </Route>
  );
};

export default NotFoundRoute;