import { mdiClose, mdiMinus, mdiPencilOutline, mdiPlus } from '@mdi/js';

import { ProductionCorridorTypeEnum } from '../../objects';
import { getExportExcelSkipRowGroupsItem } from '../../../../../common/components/ag-grid/contextMenu/excelExport';
import { MenuItemDefBuilder } from '../../../../../common/components/ag-grid';

export const getContextMenuEditMode = (params) => {
  if (ProductionCorridorTypeEnum.isModelGroupRow(params.node.data.type)) {
    return [
      ProductionCorridorTableContextMenuItems.getUpdateModelGroupItem(params),
      ProductionCorridorTableContextMenuItems.getAddLimitationItem(params),
      ProductionCorridorTableContextMenuItems.getDeleteModelGroupItem(params),
      'separator',
      ProductionCorridorTableContextMenuItems.getUndoDeleteModelGroupItem(params),
      ProductionCorridorTableContextMenuItems.getUndoModificationsItem(params)
    ];
  } else if (ProductionCorridorTypeEnum.isLimitationRow(params.node.data.type)) {
    return [
      ProductionCorridorTableContextMenuItems.getUpdateLimitationItem(params),
      ProductionCorridorTableContextMenuItems.getAddLimitationItem(params),
      ProductionCorridorTableContextMenuItems.getDeleteLimitationItem(params),
      'separator',
      ProductionCorridorTableContextMenuItems.getUndoDeleteLimitationItem(params),
      ProductionCorridorTableContextMenuItems.getUndoModificationsItem(params)
    ];
  }
  return [];
};

export const getContextMenuReadOnlyMode = (params) => {
  return [
    'copy',
    'copyWithHeaders',
    'separator',
    getExportExcelSkipRowGroupsItem(params)
  ];
};

const ProductionCorridorTableContextMenuItems = {
  getAddLimitationItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.LIMITATION_ADD')
      .iconFromPath(mdiPlus)
      .action(() => {
        const modelGroupNode = params.node.parent.allLeafChildren.find(node => ProductionCorridorTypeEnum.isModelGroupRow(node.data.type) && params.node.data.modelGroup === node.data.modelGroup);
        params.context.onShowLimitationProductionCorridorCreationModalForm({
          modelGroup: modelGroupNode.data.modelGroup,
          weekFrom: modelGroupNode.data.weekFrom,
          weekTo: modelGroupNode.data.weekTo
        });
      })
      .build();
  },
  getUpdateModelGroupItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.MODEL_GROUP_UPDATE')
      .iconFromPath(mdiPencilOutline)
      .action(() => {
        params.context.onShowModelGroupProductionCorridorUpdateForm(params.node.data);
      })
      .build();
  },
  getUpdateLimitationItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.LIMITATION_UPDATE')
      .iconFromPath(mdiPencilOutline)
      .action(() => {
        const modelGroupNode = params.node.parent.allLeafChildren.find(node => ProductionCorridorTypeEnum.isModelGroupRow(node.data.type) && params.node.data.modelGroup === node.data.modelGroup);
        params.context.onShowLimitationProductionCorridorUpdateForm({
          limitWeekFrom: modelGroupNode.data.weekFrom,
          limitWeekTo: modelGroupNode.data.weekTo,
          rowData: params.node.data
        });
      })
      .build();
  },
  getUndoModificationsItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.UNDO_CHANGES')
      .iconFromPath(mdiClose)
      .disabled(!params.node.data.modified)
      .action(() => {
        const data = params.node.data;
        data.undoModifiedFlag();
        params.api.applyTransaction({ update: [data] });
      })
      .build();
  },
  getDeleteLimitationItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.LIMITATION_DELETE')
      .iconFromPath(mdiMinus)
      .disabled(params.node.data.archived || params.node.data.archivedModelGroup)
      .action(() => {
        const data = params.node.data;
        if (data.isNew()) {
          params.api.applyTransaction({ remove: [data] });
        } else {
          data.setArchivedFlag();
          params.api.applyTransaction({ update: [data] });
        }
      })
      .build();
  },
  getUndoDeleteLimitationItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.LIMITATION_DELETE_UNDO')
      .iconFromPath(mdiClose)
      .disabled(!params.node.data.archived || params.node.data.archivedModelGroup)
      .action(() => {
        const data = params.node.data;
        data.undoArchivedFlag();

        params.api.applyTransaction({ update: [data] });
      })
      .build();
  },
  getDeleteModelGroupItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.MODEL_GROUP_DELETE')
      .iconFromPath(mdiMinus)
      .disabled(params.node.data.archivedModelGroup)
      .action(() => {
        const data = params.node.data;
        const rows = [];
        params.api.forEachLeafNode(node => data.modelGroup === node.data.modelGroup && rows.push(node.data));

        if (data.isNew()) {
          params.api.applyTransaction({ remove: rows });
        } else {
          data.setArchivedFlag();
          rows.forEach(row => row.setArchivedModelGroupFlag());
          params.api.applyTransaction({ update: rows });
        }
      })
      .build();
  },
  getUndoDeleteModelGroupItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.MODEL_GROUP_DELETE_UNDO')
      .iconFromPath(mdiClose)
      .disabled(!params.node.data.archivedModelGroup)
      .action(() => {
        const data = params.node.data;
        const rows = [];
        params.api.forEachLeafNode(node => data.modelGroup === node.data.modelGroup && rows.push(node.data));

        data.undoArchivedFlag();
        rows.forEach(row => row.undoArchivedModelGroupFlag());

        params.api.applyTransaction({ update: rows });
      })
      .build();
  }
};
