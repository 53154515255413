/**
 * Codes from 'vwg.skoda.ovex.pvt.privilege.common.Functionality'
 */
const FunctionalityCodes = {

  PVT_PC__GET_PRODUCTION_CORRIDOR_LIST: 'PVT_PC__GET_PRODUCTION_CORRIDOR_LIST',
  PVT_PC__UPDATE_PRODUCTION_CORRIDOR_BULK: 'PVT_PC__UPDATE_PRODUCTION_CORRIDOR_BULK',

  PVT_QR__GET_RESTRICTION_LIST: 'PVT_QR__GET_RESTRICTION_LIST',
  PVT_QR__UPDATE_RESTRICTION_BULK: 'PVT_QR__UPDATE_RESTRICTION_BULK',

  PVT_SCP__GET_COMMISSIONS_SWAP_DEALER: 'PVT_SCP__GET_COMMISSIONS_SWAP_DEALER',
  PVT_SCP__SWAP_COMMISSIONS_SWAP_DEALER: 'PVT_SCP__SWAP_COMMISSIONS_SWAP_DEALER',

  PVT_SCP__GET_COMMISSIONS_SWAP_IMPORTER: 'PVT_SCP__GET_COMMISSIONS_SWAP_IMPORTER',
  PVT_SCP__SWAP_COMMISSIONS_SWAP_IMPORTER: 'PVT_SCP__SWAP_COMMISSIONS_SWAP_IMPORTER',

  PVT_BALANCE__GET_BALANCE: 'PVT_BALANCE__GET_BALANCE',
  PVT_BALANCE__UPDATE_BALANCE: 'PVT_BALANCE__UPDATE_BALANCE',

  PVT_AC__GET_DEALER_RESTRICTION_LIST: 'PVT_AC__GET_DEALER_RESTRICTION_LIST'

};

export default FunctionalityCodes;