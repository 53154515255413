import { LOCATION_CHANGE } from 'connected-react-router';

import { CALL_API } from '../../../../../redux/middleware';

export const EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_REQUEST = 'EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_REQUEST';
export const EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_SUCCESS = 'EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_SUCCESS';
export const EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_FAILURE = 'EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_FAILURE';

const initialState = {
  isFetching: false,
  excelFile: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        excelFile: action.payload
      };
    }
    case EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleExportPriceGuaranteeReportImporterPGR = (opt) => {
  return {
    [CALL_API]: {
      apiFunction: () => {
        // TODO OS/PK - implement me
        console.log('BE CALL | Export to excel - write here');
        return Promise.resolve({ data: 'Excel was here!' });
      },
      types: [EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_REQUEST, EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_SUCCESS, EXPORT_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_FAILURE]
    }
  };
};
