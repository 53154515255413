import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetModelGroupsFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_NEGOTIATIONS__GET_MODEL_GROUPS);
  }

}

export default GetModelGroupsFunctionalityContext;