/**
 * @typedef SelectOption
 * @property {string} value
 * @property {string} content
 */

class FilterOptionsBuilder {

  /**
   * @param {Array.<Object>} filterContentList - a list of allowed combinations of filter values
   * @param {Object.<String, Array.<SelectOption>>} selectOptionsMap - select options map
   * @param {Object.<String, function>} filterContentMap - map of filter content handlers
   * @param {Object.<String, function>} filterContentValueGetterMap - map of value getters
   */
  constructor(filterContentList, selectOptionsMap, filterContentMap, filterContentValueGetterMap) {
    this._filterContentList = filterContentList;
    this._selectOptionsMap = selectOptionsMap;
    this._filterContentMap = filterContentMap;
    this._filterContentValueGetterMap = filterContentValueGetterMap;
  }

  /**
   * @param {Object} filterValues
   * @return {Object.<String, Array.<SelectOption>>} filtered select options map
   */
  getFilteredOptions = (filterValues) => {
    const filteredSelectOptionsMap = {};

    filterValues && Object.keys(this._selectOptionsMap)
      .forEach(key => {
        const selectOptionList = this._selectOptionsMap[key];
        const valueGetter = this._filterContentValueGetterMap[key];

        if (Array.isArray(selectOptionList) && valueGetter != null) {
          const filtered = this._filterContentList.filter(fc => this._filterContentMap[key](fc, filterValues));
          filteredSelectOptionsMap[key] = selectOptionList.filter(option => filtered.some(fc => valueGetter(fc) === option.value));
        } else {
          filteredSelectOptionsMap[key] = [];
        }
      });

    return filteredSelectOptionsMap;
  }

}

export default FilterOptionsBuilder;