import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import './Tabs.scss';

const propTypes = {
  activeName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object, // instance of Bricks.Tabs.Item
    PropTypes.arrayOf(PropTypes.object) // instance of Bricks.Tabs.Item
  ]).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

const defaultProps = {
  className: null
};

const Tabs = (props) => {
  const { children, className, activeName, onChange } = props;

  const classNames = ['ovex-Tabs'];
  className && classNames.push(className);

  return (
    <Bricks.Tabs
      activeName={activeName}
      className={classNames.join(' ')}
      onChange={onChange}
    >
      {children}
    </Bricks.Tabs>
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

Tabs.Item = Bricks.Tabs.Item;

export default Tabs;
