import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import { isGroupNode } from '../../../../../../../common/components/ag-grid';
import { resolveCellClass } from "../../ValidationResultPGItemTable.helpers";

class COrderModeCUID extends AbstractColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.C_ORDER_MODE;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList } = additionalParams;

    return {
      colId: this.getCode(),
      hide: !visible,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.MODE'),
      cellClass: resolveCellClass(''),
      valueGetter: (valueGetterParams) => {
        if (isGroupNode(valueGetterParams)) {
          const accountPartData = valueGetterParams.node.allLeafChildren[0].data.accountPartData;
          return accountPartData.cOrderMode;
        }
        return null;
      },
      valueFormatter: (valueFormatterParams) => {
        const { value, context } = valueFormatterParams;
        if (isGroupNode(valueFormatterParams)) {
          return context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.C_ORDER_MODE.${value}`);
        }
        return '';
      },
      filterParams: {
        valueFormatter: ({ value, context }) => {
          return value ? context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.C_ORDER_MODE.${value}`) : '';
        }
      },
      type: [...columnTypeList],
      pinned: 'left',
      width: 180,
      minWidth: 120
    };
  }
  /** @override */
  excelExportValueFormatter = (processCellForExportParams) => {
    return processCellForExportParams.column.colDef.valueFormatter({
      ...processCellForExportParams,
      data: processCellForExportParams.node.data
    });
  }

}

const cOrderModeCUID = new COrderModeCUID();

export default cOrderModeCUID;