import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const swapCommissionPvtApi = ApiServiceInstances.pvt.swapCommissionPvtApi;

export const GET_COMMISSIONS_SWAP_DEALER_REQUEST = 'GET_COMMISSIONS_SWAP_DEALER_REQUEST';
export const GET_COMMISSIONS_SWAP_DEALER_SUCCESS = 'GET_COMMISSIONS_SWAP_DEALER_SUCCESS';
export const GET_COMMISSIONS_SWAP_DEALER_FAILURE = 'GET_COMMISSIONS_SWAP_DEALER_FAILURE';
export const SWAP_COMMISSIONS_SWAP_DEALER_REQUEST = 'SWAP_COMMISSIONS_SWAP_DEALER_REQUEST';
export const SWAP_COMMISSIONS_SWAP_DEALER_SUCCESS = 'SWAP_COMMISSIONS_SWAP_DEALER_SUCCESS';
export const SWAP_COMMISSIONS_SWAP_DEALER_FAILURE = 'SWAP_COMMISSIONS_SWAP_DEALER_FAILURE';
export const GET_COMMISSIONS_SWAP_IMPORTER_REQUEST = 'GET_COMMISSIONS_SWAP_IMPORTER_REQUEST';
export const GET_COMMISSIONS_SWAP_IMPORTER_SUCCESS = 'GET_COMMISSIONS_SWAP_IMPORTER_SUCCESS';
export const GET_COMMISSIONS_SWAP_IMPORTER_FAILURE = 'GET_COMMISSIONS_SWAP_IMPORTER_FAILURE';
export const SWAP_COMMISSIONS_SWAP_IMPORTER_REQUEST = 'SWAP_COMMISSIONS_SWAP_IMPORTER_REQUEST';
export const SWAP_COMMISSIONS_SWAP_IMPORTER_SUCCESS = 'SWAP_COMMISSIONS_SWAP_IMPORTER_SUCCESS';
export const SWAP_COMMISSIONS_SWAP_IMPORTER_FAILURE = 'SWAP_COMMISSIONS_SWAP_IMPORTER_FAILURE';

export const RESET_COMMISSIONS_SWAP_PVT = 'RESET_COMMISSIONS_SWAP_PVT';

const initialState = {
  commission: null,
  commissionSwapAdeptList: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMISSIONS_SWAP_DEALER_REQUEST:
    case SWAP_COMMISSIONS_SWAP_DEALER_REQUEST:
    case GET_COMMISSIONS_SWAP_IMPORTER_REQUEST:
    case SWAP_COMMISSIONS_SWAP_IMPORTER_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case GET_COMMISSIONS_SWAP_DEALER_SUCCESS:
    case GET_COMMISSIONS_SWAP_IMPORTER_SUCCESS: {
      return {
        ...state,
        commission: action.payload.commission,
        commissionSwapAdeptList: action.payload.commissionSwapAdeptList,
        isFetching: false
      };
    }
    case SWAP_COMMISSIONS_SWAP_DEALER_SUCCESS:
    case SWAP_COMMISSIONS_SWAP_IMPORTER_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }
    case GET_COMMISSIONS_SWAP_DEALER_FAILURE:
    case SWAP_COMMISSIONS_SWAP_DEALER_FAILURE:
    case GET_COMMISSIONS_SWAP_IMPORTER_FAILURE:
    case SWAP_COMMISSIONS_SWAP_IMPORTER_FAILURE:
      return {
        ...state,
        commission: null,
        commissionSwapAdeptList: null,
        error: action.payload.error,
        isFetching: false
      };
    case RESET_COMMISSIONS_SWAP_PVT:
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetCommissionSwapDealer = (commissionNumber) => {
  return {
    [CALL_API]: {
      apiFunction: () => swapCommissionPvtApi.getCommissionsSwapDealerWithHttpInfo(commissionNumber),
      types: [GET_COMMISSIONS_SWAP_DEALER_REQUEST, GET_COMMISSIONS_SWAP_DEALER_SUCCESS, GET_COMMISSIONS_SWAP_DEALER_FAILURE]
    }
  };
};

export const handleGetCommissionSwapImporter = (commissionNumber) => {
  return {
    [CALL_API]: {
      apiFunction: () => swapCommissionPvtApi.getCommissionsSwapImporterWithHttpInfo(commissionNumber),
      types: [GET_COMMISSIONS_SWAP_IMPORTER_REQUEST, GET_COMMISSIONS_SWAP_IMPORTER_SUCCESS, GET_COMMISSIONS_SWAP_IMPORTER_FAILURE]
    }
  };
};
export const handleSwapCommissionsPvtDealer = (swapCommissionsPvt) => {
  return {
    [CALL_API]: {
      apiFunction: () => swapCommissionPvtApi.swapCommissionsPvtDealerWithHttpInfo(swapCommissionsPvt),
      types: [SWAP_COMMISSIONS_SWAP_DEALER_REQUEST, SWAP_COMMISSIONS_SWAP_DEALER_SUCCESS, SWAP_COMMISSIONS_SWAP_DEALER_FAILURE]
    }
  };
};

export const handleSwapCommissionsPvtImporter = (swapCommissionsPvt) => {
  return {
    [CALL_API]: {
      apiFunction: () => swapCommissionPvtApi.swapCommissionsPvtImporterWithHttpInfo(swapCommissionsPvt),
      types: [SWAP_COMMISSIONS_SWAP_IMPORTER_REQUEST, SWAP_COMMISSIONS_SWAP_IMPORTER_SUCCESS, SWAP_COMMISSIONS_SWAP_IMPORTER_FAILURE]
    }
  };
};

export const handleResetState = () => {
  return {
    type: RESET_COMMISSIONS_SWAP_PVT
  };
};
