import React from 'react';
import PropTypes from 'prop-types';
import { PriceGuaranteeNewDTO, AccountingBasisDTO } from '@ovex/price-guarantee-web-api';

import { Button, ButtonBar, Modal, Tabs } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import PriceGuaranteeTabs from '../PriceGuaranteeTabs';
import PriceGuaranteeFormHeader from '../PriceGuaranteeFormHeader/PriceGuaranteeFormHeader';
import PriceGuaranteeFormAccounting from '../PriceGuaranteeAccountingForm/PriceGuaranteeFormAccounting';

import './PriceGuaranteeCreationModalForm.scss';

const propTypes = {
  accountingBasisList: PropTypes.arrayOf(PropTypes.instanceOf(AccountingBasisDTO)),
  editRules: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  onGetAccountingBasisList: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired
};

const defaultProps = {
  accountingBasisList: null,
  editRules: []
};

const DefaultValues = {
  accountingBasisList: null,
  marketingActionCode: null,
  name: null,
  commissionCreationDateTo: null,
  commissionProduceWeekFirstTo: null,
  commissionSellingDateTo: null,
  type: null,
  daysFromInvoiceToSale: 0,
  twoWeeksAfterSale: true
};

const PriceGuaranteeCreationModalForm = (props) => {
  const lsi = React.useContext(LsiContext);

  const tabFormValues = React.useRef({});

  const headerFormRef = React.useRef(null);
  const pgAccountingTableRef = React.useRef(null);

  const [selectedTab, setSelectedTab] = React.useState(PriceGuaranteeTabs.header);

  const handleSetSelectedTab = React.useCallback(
    (opt) => {
      setSelectedTab(opt.tab.props.name);
    },
    []
  );
  const onGetAccountingBasisList = props.onGetAccountingBasisList;
  React.useEffect(() => {
    onGetAccountingBasisList();
  }, [onGetAccountingBasisList]);

  const handleSaveTabFrom = React.useCallback(
    ({ formId, values }) => {
      tabFormValues.current[formId] = values;
    },
    []
  );

  const onSave = props.onSave;
  const handleSave = React.useCallback(() => {
    if (headerFormRef.current.isValid()) {
      headerFormRef.current.save();
      if (pgAccountingTableRef.current.isValid()) {
        pgAccountingTableRef.current.save();
        const newDTO = PriceGuaranteeNewDTO.constructFromObject({
          ...tabFormValues.current[PriceGuaranteeTabs.header],
          ...tabFormValues.current[PriceGuaranteeTabs.pgAccountingList]
        });
        onSave(newDTO);
      } else {
        setSelectedTab(PriceGuaranteeTabs.pgAccountingList);
      }
    } else {
      setSelectedTab(PriceGuaranteeTabs.header);
    }
  }, [onSave]);

  return (
    <Modal
      className='ovex-pg-PriceGuaranteeCreationModalForm'
      header={lsi.getLSIItem('PRICE_GUARANTEE.PAGE_TITLE.PRICE_GUARANTEE_NEW')}
      onClose={props.onClose}
      shown={props.shown}
      size='m'
    >
      <Tabs
        activeName={selectedTab}
        onChange={handleSetSelectedTab}
      >
        <Tabs.Item
          header={lsi.getLSIItem('PRICE_GUARANTEE.TABS.HEADER')}
          name={PriceGuaranteeTabs.header}
        >
          <PriceGuaranteeFormHeader
            editRules={props.editRules}
            onClose={props.onClose}
            onSave={handleSaveTabFrom}
            priceGuarantee={DefaultValues}
            ref={headerFormRef}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('PRICE_GUARANTEE.TABS.PG_PACKAGE_LIST')}
          name={PriceGuaranteeTabs.pgAccountingList}
        >
          <PriceGuaranteeFormAccounting
            editRules={props.editRules}
            onSave={handleSaveTabFrom}
            ref={pgAccountingTableRef}
            rowData={props.accountingBasisList}/>
        </Tabs.Item>
      </Tabs>
      <ButtonBar
        align={ButtonBar.align.center}
        formControlsBar
      >
        <Button
          content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.CANCEL')}
          onClick={props.onClose}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.SAVE')}
          onClick={handleSave}
          type={Button.type.PRIMARY}
        />
      </ButtonBar>
    </Modal>
  );
};

PriceGuaranteeCreationModalForm.propTypes = propTypes;
PriceGuaranteeCreationModalForm.defaultProps = defaultProps;

export default PriceGuaranteeCreationModalForm;
