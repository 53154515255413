import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const modelGroupsApi = ApiServiceInstances.annualTarget.modelGroupsApi;

export const CLEAN_MODEL_GROUPS = 'CLEAN_MODEL_GROUPS';

export const GET_MODEL_GROUPS_REQUEST = 'GET_MODEL_GROUPS_REQUEST';
export const GET_MODEL_GROUPS_SUCCESS = 'GET_MODEL_GROUPS_SUCCESS';
export const GET_MODEL_GROUPS_FAILURE = 'GET_MODEL_GROUPS_FAILURE';

const initialState = {
  modelGroupList: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MODEL_GROUPS_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_MODEL_GROUPS_SUCCESS: {
      return { ...state, isFetching: false,
        modelGroupList: action.payload
      };
    }
    case GET_MODEL_GROUPS_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case CLEAN_MODEL_GROUPS:
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleCleanModelGroups = () => {
  return {
    type: CLEAN_MODEL_GROUPS
  };
};

export const getModelGroups = () => {
  return {
    [CALL_API]: {
      apiFunction: modelGroupsApi.getModelGroupsWithHttpInfo.bind(modelGroupsApi),
      types: [GET_MODEL_GROUPS_REQUEST, GET_MODEL_GROUPS_SUCCESS, GET_MODEL_GROUPS_FAILURE]
    }
  };
};
