import React from 'react';
import PropTypes from 'prop-types';
import { mdiCheckBoxOutline, mdiCheckboxBlankOutline } from '@mdi/js';
import Icon from '@mdi/react';

import { DYNAMIC_COLUMN_ID } from '../UserDetailInfo.columDef';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    negotiable: PropTypes.bool
  })
};

const defaultProps = {
  className: null,
  value: null
};

const UserInfoIconRenderer = (props) => {
  const { node, context, propertyName, value } = props;

  if (!node.data.rowId.includes(DYNAMIC_COLUMN_ID.PREPOSITION)) {
    return value;
  }
  let iconPath;
  if (context[propertyName].roleList.includes(node.data.rowId)) {
    iconPath = mdiCheckBoxOutline;
  } else if (!context[propertyName].roleList.includes(node.data.rowId)) {
    iconPath = mdiCheckboxBlankOutline;
  }

  return (
    <div
    >
      <div>
        { iconPath &&
          <Icon
            path={iconPath}
            size={0.75}
          />
        }
      </div>
    </div>
  );
};

UserInfoIconRenderer.propTypes = propTypes;
UserInfoIconRenderer.defaultProps = defaultProps;

export default UserInfoIconRenderer;