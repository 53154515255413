import { ConditionResultHeaderEnum } from '../../../../../../utils/const';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class ClaimSendByDealerRUID extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.CLAIM_SEND_BY_DEALER__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.CLAIM_SEND_BY_DEALER_TYPE');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_SEND_BY_DEALER_TYPE');
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    return null;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;

    return agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.DEALER_STANDARD');
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    // if the result is OK, the dealer type is standard (external)
    return data.result === ConditionResultHeaderEnum.OK;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;

    return value
      ? agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.DEALER_STANDARD')
      : agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.DEALER_INTERNAL');
  }
}

const claimSendByDealerRUID = new ClaimSendByDealerRUID();

export default claimSendByDealerRUID;