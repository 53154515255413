
const UserImitationValidatorDzcExists = {
  EMPTY: 'empty',
  DATA_FOUND: 'dataFound',
  NOT_FOUND: 'notFound',

  isEmpty: function (status) { return this.EMPTY === status; },
  isDataFound: function (status) { return this.DATA_FOUND === status; },
  isNotFound: function (status) { return this.NOT_FOUND === status; }
};

export default UserImitationValidatorDzcExists;
