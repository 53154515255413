
class PrivilegeWebResult {

  /**
   * @param {string|null} rejectionReason
   */
  constructor(rejectionReason) {
    this.rejectionReason = rejectionReason;
  }
}

export default PrivilegeWebResult;