import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class VLDGetCommissionValidationResultDealerPFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_VLD__GET_COMMISSION_VALIDATION_RESULT_DEALER);
  }

}

export default VLDGetCommissionValidationResultDealerPFC;
