import PropTypes from 'prop-types';
import React from 'react';
import { Switch } from 'react-router-dom';

import { ModuleContext, ModuleContextData } from '../../../contexts';
import { NotFoundRoute } from '../index';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  moduleContextData: PropTypes.instanceOf(ModuleContextData).isRequired
};

const defaultProps = {};

const ModuleRoutes = (props) => {
  return (
    <ModuleContext.Provider value={props.moduleContextData} >
      <Switch>
        {props.children}
        <NotFoundRoute />
      </Switch>
    </ModuleContext.Provider>
  );
};

ModuleRoutes.propTypes = propTypes;
ModuleRoutes.defaultProps = defaultProps;

export default ModuleRoutes;