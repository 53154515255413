/**
* Enum class AlertTypeEnum.
* @module ovex/main/AlertTypeEnum
* @enum {string}
* @readonly
*/
export default class AlertTypeEnum {
  /**
   * value: "INFO"
   * @const
   */
  static INFO = 'INFO';

  /**
   * value: "WARNING"
   * @const
   */
  static WARNING = 'WARNING';

  /**
   * value: "ERROR"
   * @const
   */
  static ERROR = 'ERROR';

  /**
   * Returns a <code>AlertTypeEnum</code> enum value from a Javascript object name.
   * @param {Object} data The plain JavaScript object containing the name of the enum value.
   * @return {module:ovex/main/AlertTypeEnum} The enum <code>AlertTypeEnum</code> value.
   */
  static constructFromObject(data) {
    return data;
  }

}

