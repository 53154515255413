import React from 'react';
import PropTypes from 'prop-types';
import { mdiCheckBoxOutline, mdiCheckboxBlankOutline } from '@mdi/js';
import Icon from '@mdi/react';

import { decimalToPercentFormatter } from '../../../../utils/formatters';

import './ATSalesPeriodRenderer.scss';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    minAchievementRatio: PropTypes.number,
    negotiable: PropTypes.bool
  })
};

const defaultProps = {
  className: null,
  value: null
};

const ATSalesPeriodRenderer = React.memo((props) => {
  const { node, value, className } = props;

  if (node.group || node.data.isNegotiationClosureRow()) {
    return '';
  }

  const classNames = ['ovex-ATSalesPeriodRenderer'];
  className && classNames.push(className);

  const negotiable = value?.negotiable;
  const minAchievementRatio = value.minAchievementRatio; // minAchievementRatio only not available for negotiation CLOSURE

  let iconPath;
  if (negotiable === true) {
    iconPath = mdiCheckBoxOutline;
  } else if (negotiable === false) {
    iconPath = mdiCheckboxBlankOutline;
  }

  return (
    <div
      className={classNames.join(' ')}
    >
      <div>
        { iconPath &&
          <Icon
            path={iconPath}
            size={0.6}
          />
        }
      </div>
      <div>
        {decimalToPercentFormatter(minAchievementRatio)}
      </div>
    </div>
  );
});

ATSalesPeriodRenderer.propTypes = propTypes;
ATSalesPeriodRenderer.defaultProps = defaultProps;

export default ATSalesPeriodRenderer;
