import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecPreviousInteriorNegCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__PREVIOUS__INTERIOR_NEG;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.PREVIOUS;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.INTERIOR_NEG;
  }

}

const specPreviousInteriorNegCUID = new SpecPreviousInteriorNegCUID();

export default specPreviousInteriorNegCUID;