import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const supportApi = ApiServiceInstances.support.userCredentialsApi;

export const IMITATE_USER_REQUEST = 'IMITATE_USER_REQUEST';
export const IMITATE_USER_SUCCESS = 'IMITATE_USER_SUCCESS';
export const IMITATE_USER_FAILURE = 'IMITATE_USER_FAILURE';

const initialState = {
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IMITATE_USER_REQUEST: {
      return { ...state, isFetching: true };
    }
    case IMITATE_USER_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case IMITATE_USER_FAILURE: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    default:
      return state;
  }
};

export const handleImitateUserCredentials = (uid) => {
  return {
    [CALL_API]: {
      apiFunction: supportApi.imitateUserCredentialsWithHttpInfo.bind(supportApi, uid),
      types: [IMITATE_USER_REQUEST, IMITATE_USER_SUCCESS, IMITATE_USER_FAILURE]
    }
  };
};
