/**
 * Codes from 'vwg.skoda.ovex.priceguarantee.privilege.common.Functionality'
 */
const FunctionalityCodes = {
  PG_PG__GET_PRICE_GUARANTEES: 'PG_PG__GET_PRICE_GUARANTEES',
  PG_PG__CREATE_PRICE_GUARANTEE: 'PG_PG__CREATE_PRICE_GUARANTEE',
  PG_PG__UPDATE_PRICE_GUARANTEE: 'PG_PG__UPDATE_PRICE_GUARANTEE',
  PG_PG__GET_ACCOUNTING_BASIS: 'PG_PG__GET_ACCOUNTING_BASIS',
  PG_PG__SHIFT_PRICE_GUARANTEE_STATUS: 'PG_PG__SHIFT_PRICE_GUARANTEE_STATUS',

  PG_ITM__GET_PRICE_GUARANTEES: 'PG_ITM__GET_PRICE_GUARANTEES',
  PG_ITM__GET_PRICE_GUARANTEE_ITEMS: 'PG_ITM__GET_PRICE_GUARANTEE_ITEMS',
  PG_ITM__CREATE_PRICE_GUARANTEE_ITEMS: 'PG_ITM__CREATE_PRICE_GUARANTEE_ITEMS',
  PG_ITM__UPDATE_PRICE_GUARANTEE_ITEMS: 'PG_ITM__UPDATE_PRICE_GUARANTEE_ITEMS',
  PG_ITM__DELETE_PRICE_GUARANTEE_ITEMS: 'PG_ITM__DELETE_PRICE_GUARANTEE_ITEMS',

  PG_PUB__GET_PRICE_GUARANTEES: 'PG_PUB__GET_PRICE_GUARANTEES',
  PG_PUB__GET_PRICE_GUARANTEE_ITEMS: 'PG_PUB__GET_PRICE_GUARANTEE_ITEMS',

  PG_VLD__GET_PRICE_GUARANTEES: 'PG_VLD__GET_PRICE_GUARANTEES',
  PG_VLD__GET_COMMISSION_VALIDATION_RESULT_DEALER: 'PG_VLD__GET_COMMISSION_VALIDATION_RESULT_DEALER',
  PG_VLD__GET_COMMISSION_VALIDATION_RESULT_IMPORTER: 'PG_VLD__GET_COMMISSION_VALIDATION_RESULT_IMPORTER',

  PG_PGR__GET_PRICE_GUARANTEES: 'PG_PGR__GET_PRICE_GUARANTEES',
  PG_PGR__GET_PRICE_GUARANTEE_REPORT_DEALER: 'PG_PGR__GET_PRICE_GUARANTEE_REPORT_DEALER',
  PG_PGR__GET_PRICE_GUARANTEE_REPORT_IMPORTER: 'PG_PGR__GET_PRICE_GUARANTEE_REPORT_IMPORTER',
  // TODO OS/PK - check functionality code for export to excel
  // for NOW is currently same as PG_PGR__GET_PRICE_GUARANTEE_REPORT_IMPORTER code
  PG_PGR__GET_PRICE_GUARANTEE_REPORT_EXPORT_IMPORTER: 'PG_PGR__GET_PRICE_GUARANTEE_REPORT_IMPORTER',

  PG_PGA__REFRESH_STORAGE_DATA: 'PG_PGA__REFRESH_STORAGE_DATA'
};

export default FunctionalityCodes;
