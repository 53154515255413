import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetDealerContractsFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__GET_DEALER_CONTRACTS);
  }

}

export default GetDealerContractsFunctionalityContext;