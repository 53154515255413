import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class QRGetRestrictionListFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PVT_QR__GET_RESTRICTION_LIST);
  }

}

export default QRGetRestrictionListFunctionalityContext;