import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useHistoryOvex, usePrivilege } from '../../../common/hooks';
import {
  GetDealerContractListFunctionalityContext,
  GetDealerContractPreviewFunctionalityContext
} from '../../privilege/context';
import { handleGetDealerContractPreview, handleGetDealerContractList } from '../../redux/modules/actions';

import ContractPreviewPage from './ContractPreviewPage';
import generatePath from './generatePath';

const ContraContractPreviewPageConnector = () => {
  const history = useHistoryOvex();
  const pathParams = useParams();

  const selectedState = useSelector(state => {
    return {
      contractList: state.at.contracts.contractList,
      contractPreview: state.at.contractPreview.contractPreview,
      isFetching: state.at.contracts.isFetching || state.at.contractPreview.isFetching
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        onGetDealerContractList: (contractId) => dispatch(handleGetDealerContractList(contractId)),
        onGetDealerContractPreview: (contractId) => dispatch(handleGetDealerContractPreview(contractId))
      };
    },
    [dispatch]
  );

  React.useEffect(
    () => {
      callbacks.onGetDealerContractList(pathParams.contractId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callbacks] // ESLint missing dependency 'pathParams.contractId' je OK. Nejsou generovany zbytecne requesty na BE.
  );
  React.useEffect(
    () => {
      if (selectedState.contractList != null) {
        callbacks.onGetDealerContractPreview(pathParams.contractId);
      }
    },
    [selectedState.contractList, pathParams.contractId, callbacks]
  );

  const { handlePrivilegeRequest } = usePrivilege();

  React.useEffect(
    () => {
      if (Array.isArray(selectedState.contractList) && selectedState.contractList.length > 0 ) {
        const functionalityContextList = [];
        selectedState.contractList.forEach(contract => {
          functionalityContextList.push(new GetDealerContractListFunctionalityContext(contract.id));
          functionalityContextList.push(new GetDealerContractPreviewFunctionalityContext(contract.id));
        });

        handlePrivilegeRequest(functionalityContextList);
      }
    },
    [handlePrivilegeRequest, selectedState.contractList]
  );

  const handleChangeContractPreview = React.useCallback(
    (contractId) => history.push(generatePath(contractId), { keepContractList: true }),
    [history]
  );

  return (
    <ContractPreviewPage
      contractIdSelected={pathParams.contractId}
      contractList={selectedState.contractList}
      contractPreview={selectedState.contractPreview}
      fetching={selectedState.isFetching}
      onChangeContractPreview={handleChangeContractPreview}
    />
  );
};

export default ContraContractPreviewPageConnector;