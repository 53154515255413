
import history from '../../history';
import { INITIAL_STATE } from '../../utils/consts';

import configureStore from './configureStore';

const initialState = sessionStorage.getItem(INITIAL_STATE);
const store = configureStore(history, initialState ? JSON.parse(initialState) : {});

export default store;
