import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {CommissionValidationResultDTOVLD} from '@ovex/price-guarantee-web-api';

import {useHistoryOvex, useQueryParams} from '../../../common/hooks';
import {
  handleGetPriceGuaranteeListVLD
} from '../../redux/modules/commission-validator/commission-validator-price-guarantee';

import CommissionValidatorPageStatus from './CommissionValidatorPageStatus';
import CommissionValidator from './CommissionValidator';
import generatePathCommissionValidator from './generatePathCommissionValidator';

const propTypes = {
  onGetCommissionValidationResult: PropTypes.func.isRequired,
  onResetValidationResult: PropTypes.func.isRequired,
  pageStatus: PropTypes.oneOf(Object.values(CommissionValidatorPageStatus)).isRequired,
  routerPath: PropTypes.string.isRequired,
  titleLsiCode: PropTypes.string.isRequired,
  validationResult: PropTypes.instanceOf(CommissionValidationResultDTOVLD)
};

const defaultProps = {
  validationResult: null
};

const CommissionValidatorConnector = (props) => {
  const {
    titleLsiCode, pageStatus, validationResult,
    onGetCommissionValidationResult, onResetValidationResult, routerPath
  } = props;
  const { commissionNumber, marketingActionCode, order } = useQueryParams();
  const history = useHistoryOvex();

  const selectedState = useSelector(state => {
    return {
      priceGuaranteeList: state.priceGuarantee.commissionValidatorPG.priceGuaranteeList
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => ({
      handleGetPriceGuaranteeListVLD: () => dispatch(handleGetPriceGuaranteeListVLD())
    }),
    [dispatch]
  );

  const handleGetCommissionValidationResult = React.useCallback(
    (formValues) => {
      const { priceGuaranteeId, commissionNumber: cn, marketingActionCode: maCode, order: o } = formValues;
      if (maCode !== marketingActionCode || cn !== commissionNumber || o !== order) {
        history.push(generatePathCommissionValidator(routerPath, maCode, cn, o), { keepPriceGuaranteeList: true });
      }
      return onGetCommissionValidationResult(priceGuaranteeId, cn, o);
    },
    [onGetCommissionValidationResult, history, routerPath, commissionNumber, marketingActionCode]
  );
  const handleResetValidationResult = React.useCallback(
    async () => {
      history.push(routerPath, { keepPriceGuaranteeList: true });
      onResetValidationResult();
    },
    [onResetValidationResult, history, routerPath]
  );

  return (
    <CommissionValidator
      commissionNumber={commissionNumber}
      commissionOrder={order}
      marketingActionCode={marketingActionCode}
      onGetCommissionValidationResult={handleGetCommissionValidationResult}
      onGetPriceGuaranteeList={callbacks.handleGetPriceGuaranteeListVLD}
      onResetValidationResult={handleResetValidationResult}
      pageStatus={pageStatus}
      priceGuaranteeList={selectedState.priceGuaranteeList}
      titleLsiCode={titleLsiCode}
      validationResult={validationResult}
    />
  );
};

CommissionValidatorConnector.propTypes = propTypes;
CommissionValidatorConnector.defaultProps = defaultProps;

export default CommissionValidatorConnector;
