import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const commissionValidatorApi = ApiServiceInstances.priceGuarantee.commissionValidatorApi;

export const GET_PRICE_GUARANTEE_LIST_VLD_REQUEST = 'GET_PRICE_GUARANTEE_LIST_VLD_REQUEST';
export const GET_PRICE_GUARANTEE_LIST_VLD_SUCCESS = 'GET_PRICE_GUARANTEE_LIST_VLD_SUCCESS';
export const GET_PRICE_GUARANTEE_LIST_VLD_FAILURE = 'GET_PRICE_GUARANTEE_LIST_VLD_FAILURE';

const initialState = {
  isFetching: false,
  error: null,
  priceGuaranteeList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRICE_GUARANTEE_LIST_VLD_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_PRICE_GUARANTEE_LIST_VLD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        priceGuaranteeList: action.payload
      };
    }
    case GET_PRICE_GUARANTEE_LIST_VLD_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case LOCATION_CHANGE: {
      const location = action.payload.location;
      if (location?.state?.keepPriceGuaranteeList) {
        return {
          ...state,
          ...initialState,
          priceGuaranteeList: state.priceGuaranteeList
        };
      }
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetPriceGuaranteeListVLD = () => {
  return {
    [CALL_API]: {
      apiFunction: () => commissionValidatorApi.getPriceGuaranteeListVLDWithHttpInfo(),
      types: [GET_PRICE_GUARANTEE_LIST_VLD_REQUEST, GET_PRICE_GUARANTEE_LIST_VLD_SUCCESS, GET_PRICE_GUARANTEE_LIST_VLD_FAILURE]
    }
  };
};
