
export const getCellData = (params) => {
  // parametr "data" neni pro context menu primo v objektu "params", ale v "params.node" ano
  // totozne pro "colDef": nekdy je primo v "params", vzdy v "params.column", ale to tam byt nemusi
  const rowData = params.hasOwnProperty('data') ? params.data : params.node.data;
  const colDef = params.hasOwnProperty('colDef') ? params.colDef : params.column.colDef;

  return rowData && rowData[colDef.colId];
};

export const setCellData = (params, colId, newCellData) => {
  const rowData = params.hasOwnProperty('data') ? params.data : params.node.data;
  if(rowData){
    rowData[colId] = newCellData;
  }
};

export const removeCellData = (params, colId) => {
  const rowData = params.hasOwnProperty('data') ? params.data : params.node.data;
  if (rowData && rowData.hasOwnProperty(colId)) {
    delete rowData[colId];
  }
};
