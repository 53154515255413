import { PvtApiClient } from '../../../../api';

import AvailableCapacityRestrictionWeekCell from './AvailableCapacityCell';

class AvailableCapacityRow {

  /**
   * @param {module:model/RestrictionDTO} restriction
   * @param acRowTypeEnum
   * @param message
   * @param restrictionCount
   * @return {RestrictionRow}
   */
  static constructFromRestrictionDTO(restriction, acRowTypeEnum, message, restrictionCount = 0) {
    let obj;
    if (restriction) {
      obj = new AvailableCapacityRow();

      // RestrictionDTO properties
      obj['id'] = restriction.hasOwnProperty('id') && PvtApiClient.convertToType(restriction['id'], 'Number');
      obj['brand'] = restriction.hasOwnProperty('brand') && PvtApiClient.convertToType(restriction['brand'], 'String');
      obj['bid'] = restriction.hasOwnProperty('bid') && PvtApiClient.convertToType(restriction['bid'], 'String');
      obj['modelGroup'] = restriction.hasOwnProperty('modelGroup') && PvtApiClient.convertToType(restriction['modelGroup'], 'String');
      obj['restrictionType'] = restriction.hasOwnProperty('restrictionType') && PvtApiClient.convertToType(restriction['restrictionType'], 'String');
      obj['modelMask'] = restriction.hasOwnProperty('modelMask') && PvtApiClient.convertToType(restriction['modelMask'], 'String');
      obj['colour'] = restriction.hasOwnProperty('colour') && PvtApiClient.convertToType(restriction['colour'], 'String');
      obj['interior'] = restriction.hasOwnProperty('interior') && PvtApiClient.convertToType(restriction['interior'], 'String');
      obj['prNumber'] = restriction.hasOwnProperty('prNumber') && PvtApiClient.convertToType(restriction['prNumber'], 'String');
      obj['type'] = acRowTypeEnum;
      if (message) {
        obj['value'] = message;
      }
      obj['restrictionCount'] = restrictionCount;
      // own properties + generated week properties
      if (Array.isArray(restriction.restrictionWeekList)) {
        restriction.restrictionWeekList.forEach(restrictionWeek => {
          obj[restrictionWeek.week] = AvailableCapacityRestrictionWeekCell.constructFromObject(restrictionWeek, acRowTypeEnum);
        });
      }
    }

    return obj;
  }

}

// RestrictionDTO properties
AvailableCapacityRow.prototype['id'] = undefined;
AvailableCapacityRow.prototype['brand'] = undefined;
AvailableCapacityRow.prototype['bid'] = undefined;
AvailableCapacityRow.prototype['modelGroup'] = undefined;
AvailableCapacityRow.prototype['restrictionType'] = undefined;
AvailableCapacityRow.prototype['modelMask'] = undefined;
AvailableCapacityRow.prototype['colour'] = undefined;
AvailableCapacityRow.prototype['interior'] = undefined;
AvailableCapacityRow.prototype['prNumber'] = undefined;

export default AvailableCapacityRow;

