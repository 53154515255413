import PrivilegeFunctionalityContextPropType from '../privilege/PrivilegeFunctionalityContextPropType';

import usePrivilege from './usePrivilege';

/**
 * @param {object} componentProps component props
 * @param {boolean} whenNotSet - when privilege property not set, return this value (Default: true)
 * @return {boolean}
 */
const useIsPrivileged = (componentProps, whenNotSet = true) => {
  const { handleIsPrivileged } = usePrivilege();

  return handleIsPrivileged(componentProps[PrivilegeFunctionalityContextPropType.propName], whenNotSet);
};

export default useIsPrivileged;