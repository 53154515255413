import { ApiServiceInstances } from '../../../../api';
import { CALL_API } from '../../../../../../redux/middleware';

const userInfoApi = ApiServiceInstances.about.userInfoApi;

export const GET_USER_INFO_REQUEST = 'PRICE_GUARANTEE__GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'PRICE_GUARANTEE__GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'PRICE_GUARANTEE__GET_USER_INFO_FAILURE';

const initialState = {
  userInfo: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        userInfo: action.payload
      };
    }
    case GET_USER_INFO_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    default:
      return state;
  }
};

export const handleGetUserInfo = () => {
  return {
    [CALL_API]: {
      apiFunction: () => userInfoApi.getUserInfoWithHttpInfo(),
      types: [GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE]
    }
  };
};
