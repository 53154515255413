import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import {evaluateConditionResultItem, resolveCellClass} from '../../ValidationResultPGItemTable.helpers';
import {isGroupNode} from '../../../../../../../common/components/ag-grid';
import {RowType} from "../../ValidationResultPGItemTable.const";

class ProductionDateToCUID extends AbstractColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.PRODUCTION_DATE_TO;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList } = additionalParams;

    return {
      colId: this.getCode(),
      hide: !visible,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRODUCTION_DATE_TO'),
      headerTooltip: [
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_1'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_2'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_3')
      ],
      cellClass: resolveCellClass(this._evaluateConditionResultItemProductionDateTo),
      field: 'productionDateTo',
      type: [...columnTypeList],
      flex: 11,
      minWidth: 110
    };
  }
  _evaluateConditionResultItemProductionDateTo = (cellClassParams) => {
    if (isGroupNode(cellClassParams) || RowType.isCommission(cellClassParams.data.rowType)) {
      return 'ovex-ag-grid--td-right';
    }
    const data = cellClassParams.data;
    const conditionResult = data.conditionResultList.find(cr => cr.uiDefinition === this.getCode());
    return evaluateConditionResultItem(data.rowType, conditionResult.result);
  }
}

const productionDateToCUID = new ProductionDateToCUID();

export default productionDateToCUID;