import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import OvexRoutes from '../../../routes/OvexRoutes';
import { OVEX3_ENVIRONMENT } from '../../../utils/consts';
import { EnvironmentBar, Footer, NavBar } from '../../index';
import { UserModalContext, UserModalContextValue, UserModalSelector } from '../../user-modal';
import { UUPageAlertBus } from '../../uu';

import './App.scss';
import './App.print.scss';

const App = React.memo(() => {
  const environment = window.OVEX3_ENVIRONMENT;

  const appPage = React.useMemo(
    () => {
      let top = (<NavBar />);
      if (environment !== OVEX3_ENVIRONMENT.PROD) {
        top = (
          <Bricks.Div>
            <EnvironmentBar />
            {top}
          </Bricks.Div>
        );
      }

      return (
        <Bricks.Page
          alertBus={<UUPageAlertBus />}
          bottom={<Footer />}
          className='ovex-app'
          modal={<Bricks.Modal />}
          top={top}
          topFixed='always'
        >
          <UserModalSelector />
          <OvexRoutes />
        </Bricks.Page>
      );
    },
    [environment]
  );

  const [ value, setValue ] = React.useState(null);
  const userModalContextValue = React.useMemo(
    () => new UserModalContextValue(value, setValue),
    [value]
  );

  return (
    <UserModalContext.Provider value={userModalContextValue}>
      {appPage}
    </UserModalContext.Provider>
  );
});

export default App;
