import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import { BalanceDTO, ProductionCorridorDTO } from '@ovex/pvt-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';

import { ProductionCorridorTable } from './components';

import './ProductionCorridor.scss';

class ProductionCorridor extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    balanceData: PropTypes.instanceOf(BalanceDTO),
    isFetching: PropTypes.bool,
    onGetBalance: PropTypes.func.isRequired,
    onGetProductionCorridorList: PropTypes.func.isRequired,
    onUpdateBalance: PropTypes.func.isRequired,
    onUpdateProductionCorridorBulk: PropTypes.func.isRequired,
    productionCorridorData: PropTypes.arrayOf(PropTypes.instanceOf(ProductionCorridorDTO))
  };

  static defaultProps = {
    balanceData: null,
    isFetching: true,
    productionCorridorData: null
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.handleReloadProductionCorridor();
    this.handleGetBalance();
  }

  storeAlertRef = (ref) => {
    this.alert = ref;
  };
  handleGetBalance = async () => {
    const { onGetBalance } = this.props;

    try {
      await onGetBalance();
    } catch (e) {
    }
  };
  handleUpdateBalance = async (changes) => {
    const lsi = this.context;
    const { onUpdateBalance } = this.props;

    try {
      const response = await onUpdateBalance(changes);

      this.alert.addAlert({ content: lsi.getLSIItem('PVT.ERROR_MESSAGE.UPDATE_PRODUCTION_CORRIDOR_SUCCESS') });
      this.handleGetBalance();

      return response;
    } catch (e) {
    }
  };

  handleReloadProductionCorridor = async () => {
    const { onGetProductionCorridorList } = this.props;

    try {
      await onGetProductionCorridorList();
    } catch (e) {
      const lsi = this.context;
      this.alert.addAlert({
        colorSchema: 'danger',
        content: lsi.getLSIItem('PVT.ERROR_MESSAGE.GET_PRODUCTION_CORRIDOR_FAILURE')
      });
    }
  };

  handleUpdateProductionCorridorBulk = async (changes) => {
    const lsi = this.context;
    const { onUpdateProductionCorridorBulk } = this.props;

    try {
      const response = await onUpdateProductionCorridorBulk(changes);

      this.alert.addAlert({ content: lsi.getLSIItem('PVT.ERROR_MESSAGE.UPDATE_PRODUCTION_CORRIDOR_SUCCESS') });
      this.handleReloadProductionCorridor();

      return response;
    } catch (e) {
    }
  };

  render() {
    const lsi = this.context;
    const { isFetching, productionCorridorData, balanceData } = this.props;
    return (
      <Page
        className={'ovex-production-corridor'}
        header={lsi.getLSIItem('PVT.PAGE_TITLE.PRODUCTION_CORRIDOR')}
        loading={isFetching}
      >
        <ProductionCorridorTable
          balanceData={balanceData}
          onReloadProductionCorridor={this.handleReloadProductionCorridor}
          onUpdateBalance={this.handleUpdateBalance}
          onUpdateProductionCorridor={this.handleUpdateProductionCorridorBulk}
          productionCorridorData={productionCorridorData}
        />
        <Bricks.AlertBus
          hidden
          ref_={this.storeAlertRef}
        />
      </Page>
    );
  }
}

export default ProductionCorridor;
