import { RestrictionWeekUsedDTO } from '@ovex/pvt-web-api';

import { PvtApiClient } from '../../../../api';
import RestrictionsTabEnum from '../RestrictionsTabEnum';

class RestrictionWeekCell {

  /**
   * @param {Object|RestrictionWeekCell|module:model/RestrictionWeekDTO} data
   * @return {RestrictionWeekCell}
   */
  static constructFromObject(data) {
    let obj;
    if (data) {
      obj = new RestrictionWeekCell();

      // RestrictionWeekDTO attributes
      if (data.hasOwnProperty('id')) {
        obj['id'] = PvtApiClient.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('week')) {
        obj['week'] = PvtApiClient.convertToType(data['week'], 'String');
      }
      if (data.hasOwnProperty('numberTotal')) {
        obj['numberTotal'] = PvtApiClient.convertToType(data['numberTotal'], 'Number');
      }
      if (data.hasOwnProperty('numberTotalFuture')) {
        obj['numberTotalFuture'] = PvtApiClient.convertToType(data['numberTotalFuture'], 'Number');
        obj['numberTotalFutureOld'] = obj['numberTotalFuture'];
      }
      if (data.hasOwnProperty('version')) {
        obj['version'] = PvtApiClient.convertToType(data['version'], 'Number');
      }

      if (data.hasOwnProperty('used') && data['used']) {
        const used = PvtApiClient.convertToType(data['used'], RestrictionWeekUsedDTO);

        // RestrictionWeekUsedDTO properties
        obj['numberUsedAssigned'] = used['numberUsedAssigned'];
        obj['numberNoRestrictionCarAssigned'] = used['numberNoRestrictionCarAssigned'];
        obj['numberUsedManual'] = used['numberUsedManual'];

        obj.numberManualTotal = used.numberManualTotal;
        obj.numberNoRestrictionCarManual = used.numberNoRestrictionCarManual;
        obj.numberRemainingsCar = used.numberRemainingsCar;
        obj.numberRemainingsNoRestrictionCar = used.numberRemainingsNoRestrictionCar;
      }
    }
    return obj;
  }

  /**
   * @param {String} week
   * @param {Number} oldValue
   * @return {RestrictionWeekCell}
   */
  static constructWeekForNewRow(week, oldValue=0) {
    let obj = new RestrictionWeekCell();

    obj['numberTotalFuture'] = 0;
    obj['numberTotalFutureOld'] = oldValue;
    obj['week'] = PvtApiClient.convertToType(week, 'String');

    return obj;
  }

  getNumberTotalWithManual = () => {
    if (this.numberTotal == null) {
      return null;
    }
    return this.numberTotal + this.numberManualTotal;
  };

  getNumberUsed = () => {
    if (this.numberUsedAssigned == null || this.numberUsedManual == null) {
      return null;
    }
    return this.numberUsedAssigned + this.numberUsedManual;
  };

  getNumberCommonUsed = () => {
    if (this.numberNoRestrictionCarAssigned == null || this.numberNoRestrictionCarManual == null) {
      return null;
    }
    return this.numberNoRestrictionCarAssigned + this.numberNoRestrictionCarManual;
  };

  getNumberDifference = () => {
    if (this.numberRemainingsCar == null) {
      return null;
    }
    return this.numberRemainingsCar;
  };

  updateNumberTotal = (numberTotal) => {
    this.numberTotalFuture = numberTotal;
  };

  undoUpdateNumberTotal = () => {
    this.numberTotalFuture = this.numberTotalFutureOld;
  };

  isModified = () => {
    return this.id != null && ((this.numberTotalFuture != null && this.numberTotalFutureOld == null) ||
    (this.numberTotalFuture != null && this.numberTotalFutureOld != null && this.numberTotalFuture !== this.numberTotalFutureOld));
  };

  isDeleted = () => {
    return this.numberTotalFutureOld != null && this.numberTotalFuture == null;
  };

  isAdded = () => {
    return this.id == null && this.numberTotalFuture != null;
  };

  isAddedAndEdited = () => {
    return !(this.id == null && this.numberTotalFutureOld === this.numberTotalFuture)
  }

  isChanged= () => {
    return this.isModified() || this.isDeleted() || this.isAdded();
  }

  isFull = () => {
    return this.numberTotal != null && this.getNumberDifference() <= 0;
  };

  isNumberTotalDifferent = () => {
    return (this.numberTotal == null && this.numberTotalFuture != null) ||
      (this.numberTotal != null && this.numberTotalFuture == null) ||
      (this.numberTotal != null && this.numberTotalFuture != null && this.numberTotal !== this.numberTotalFuture);
  };

  containsManualAssignment = () => {
    return false;
  };

  getTabValue = (tab) => {
    switch (tab) {
      case RestrictionsTabEnum.NEW_PLAN: {
        return this.numberTotalFuture;
      }
      case RestrictionsTabEnum.ACTUAL_PLAN: {
        return this.getNumberTotalWithManual();
      }
      case RestrictionsTabEnum.USED: {
        return this.getNumberUsed();
      }
      case RestrictionsTabEnum.DIFFERENCE: {
        return this.getNumberDifference();
      }
      default: {
        return null;
      }
    }
  };

  getTabCommonValue = (tab) => {
    switch (tab) {
      case RestrictionsTabEnum.NEW_PLAN: {
        return this.getTabValue(tab);
      }
      case RestrictionsTabEnum.ACTUAL_PLAN: {
        return this.getTabValue(tab);
      }
      case RestrictionsTabEnum.USED: {
        return this.getNumberCommonUsed();
      }
      case RestrictionsTabEnum.DIFFERENCE: {
        return this.numberRemainingsNoRestrictionCar;
      }
      default: {
        return null;
      }
    }
  };

}

// RestrictionWeekDTO properties
RestrictionWeekCell.prototype['id'] = undefined;
RestrictionWeekCell.prototype['week'] = undefined;
RestrictionWeekCell.prototype['numberTotal'] = undefined;
RestrictionWeekCell.prototype['numberTotalFuture'] = undefined;
RestrictionWeekCell.prototype['version'] = undefined;

// RestrictionWeekUsedDTO properties
RestrictionWeekCell.prototype['numberUsedAssigned'] = undefined;
RestrictionWeekCell.prototype['numberNoRestrictionCarAssigned'] = undefined;
RestrictionWeekCell.prototype['numberUsedManual'] = undefined;
RestrictionWeekCell.prototype['numberManualTotal'] = undefined;
RestrictionWeekCell.prototype['numberNoRestrictionCarManual'] = undefined;
RestrictionWeekCell.prototype['numberRemainingsCar'] = undefined;
RestrictionWeekCell.prototype['numberRemainingsNoRestrictionCar'] = undefined;

// own properties
RestrictionWeekCell.prototype['numberTotalFutureOld'] = undefined;

export default RestrictionWeekCell;

