import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

import PrivilegeFunctionalityContextPropType from '../../../privilege/PrivilegeFunctionalityContextPropType';
import useIsPrivileged from '../../../hooks/useIsPrivileged';
import { NotFoundRoute } from '../index';

const propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  privilegeFunctionalityContext: PrivilegeFunctionalityContextPropType.propType
};

const defaultProps = {
  privilegeFunctionalityContext: PrivilegeFunctionalityContextPropType.defaultProp
};

const ProtectedRoute = (props) => {
  const isAllowed = useIsPrivileged(props, false);

  if (isAllowed) {
    return (
      <Route
        exact
        path={props.path}
      >
        {props.children}
      </Route>
    );
  }
  return (
    <NotFoundRoute />
  );
};

ProtectedRoute.propTypes = propTypes;
ProtectedRoute.defaultProps = defaultProps;

export default ProtectedRoute;