import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const priceGuaranteeReportApi = ApiServiceInstances.priceGuarantee.priceGuaranteeReportApi;

export const GET_PRICE_GUARANTEE_LIST_PGR_REQUEST = 'GET_PRICE_GUARANTEE_LIST_PGR_REQUEST';
export const GET_PRICE_GUARANTEE_LIST_PGR_SUCCESS = 'GET_PRICE_GUARANTEE_LIST_PGR_SUCCESS';
export const GET_PRICE_GUARANTEE_LIST_PGR_FAILURE = 'GET_PRICE_GUARANTEE_LIST_PGR_FAILURE';

export const GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_REQUEST = 'GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_REQUEST';
export const GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_SUCCESS = 'GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_SUCCESS';
export const GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_FAILURE = 'GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_FAILURE';

export const GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_REQUEST = 'GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_REQUEST';
export const GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_SUCCESS = 'GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_SUCCESS';
export const GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_FAILURE = 'GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_FAILURE';

export const RESET_PRICE_GUARANTEE_REPORT_PGR = 'RESET_PRICE_GUARANTEE_REPORT_PGR';

const initialState = {
  isFetching: false,
  priceGuaranteeList: null,
  priceGuaranteeReport: null,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRICE_GUARANTEE_LIST_PGR_REQUEST:
    case GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_REQUEST:
    case GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_PRICE_GUARANTEE_LIST_PGR_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        priceGuaranteeList: action.payload
      };
    }
    case GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_SUCCESS:
    case GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_SUCCESS: {
      const priceGuaranteeReport = action.payload;
      return {
        ...state,
        isFetching: false,
        priceGuaranteeReport: priceGuaranteeReport
      };
    }
    case GET_PRICE_GUARANTEE_LIST_PGR_FAILURE:
    case GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_FAILURE:
    case GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case RESET_PRICE_GUARANTEE_REPORT_PGR: {
      return {
        ...state,
        ...initialState,
        priceGuaranteeList: state.priceGuaranteeList
      };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetPriceGuaranteeListPGR = () => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeReportApi.getPriceGuaranteeListPGRWithHttpInfo(),
      types: [GET_PRICE_GUARANTEE_LIST_PGR_REQUEST, GET_PRICE_GUARANTEE_LIST_PGR_SUCCESS, GET_PRICE_GUARANTEE_LIST_PGR_FAILURE]
    }
  };
};

export const handleGetPriceGuaranteeReportDealerPGR = (opt) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeReportApi.getPriceGuaranteeReportDealerPGRWithHttpInfo(opt),
      types: [GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_REQUEST, GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_SUCCESS, GET_PRICE_GUARANTEE_REPORT_DEALER_PGR_FAILURE]
    }
  };
};

export const handleGetPriceGuaranteeReportImporterPGR = (opt) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeReportApi.getPriceGuaranteeReportImporterPGRWithHttpInfo(opt),
      types: [GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_REQUEST, GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_SUCCESS, GET_PRICE_GUARANTEE_REPORT_IMPORTER_PGR_FAILURE]
    }
  };
};

export const handleResetPriceGuaranteeReportPGR = () => {
  return {
    type: RESET_PRICE_GUARANTEE_REPORT_PGR
  };
};
