
export const SET_MODULE_MENU_ITEM_LIST = 'SET_MODULE_MENU_ITEM_LIST';

const initialState = {
  menuItemsModuleMap: {},
  menuItems: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MODULE_MENU_ITEM_LIST: {
      const menuItemsModuleMap = {
        ...state.menuItemsModuleMap,
        [action.moduleCode]: action.menuItemList
      };
      return {
        ...state,
        menuItemsModuleMap: menuItemsModuleMap,
        menuItems: _mergeToList(menuItemsModuleMap)
      };
    }
    default:
      return state;
  }
};

/**
 *
 * @param moduleCode
 * @param menuItemList
 * @returns {{moduleCode, menuItemList, type: string}}
 */
export const handleSetMenuItemList = (moduleCode, menuItemList) => {
  return {
    menuItemList: menuItemList,
    moduleCode: moduleCode,
    type: SET_MODULE_MENU_ITEM_LIST
  };
};

const _mergeToList = (menuItemsModuleMap) => {
  return Object.values(menuItemsModuleMap).flatMap(menuItem => menuItem);
};
