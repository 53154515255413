
/**
 * Typy objektu {@see module:model/RestrictionDTO}
 *
 * @enum
 */
const RestrictionRowTypeEnum = {

  /**
   * Kvóta (pro modelovou skupinu) je označní pro restrikci, která má nastaveny pouze vlastnosti: **brand**, **bid**, **modelGroup**, **annualTarget**, **stockCar**.
   */
  MODEL_GROUP: 'MODEL_GROUP',

  /**
   * Restrikce je označení pro všechny objekty {@see module:model/RestrictionDTO}, kromě kvót. Restrikce by neměla mít nastavené vlastnosti: **annualTarget**, **stockCar**.
   */
  RESTRICTION: 'RESTRICTION',

  /**
   * @param {module:model/RestrictionDTO} restriction
   */
  valueOf: (restriction) => {
    if (restriction == null) {
      return null;
    }
    if (restriction.modelMask == null) {
      return RestrictionRowTypeEnum.MODEL_GROUP;
    }
    return RestrictionRowTypeEnum.RESTRICTION;
  },

  /**
   * @param {RestrictionTypeEnum} type
   * @return {boolean}
   */
  isModelGroup: (type) => type === RestrictionRowTypeEnum.MODEL_GROUP,

  /**
   * @param {RestrictionTypeEnum} type
   * @return {boolean}
   */
  isRestriction: (type) => type === RestrictionRowTypeEnum.RESTRICTION
};

export default RestrictionRowTypeEnum;