import { mdiEarth, mdiNotebookPlusOutline, mdiPencilOutline, mdiTextBoxSearchOutline } from '@mdi/js';

import { MenuItemDefBuilder } from '../../../../common/components/ag-grid';
import { NegotiationStatusEnum, NegotiationTypeEnum } from '../../../utils/const';

const NegotiationDetailMenuLabels = {
  [NegotiationTypeEnum.CONTRACT]: 'ANNUAL_TARGET.CONTEXT_MENU.SHOW_NEGOTIATION_DETAIL_CONTRACT',
  [NegotiationTypeEnum.ADDITION]: 'ANNUAL_TARGET.CONTEXT_MENU.SHOW_NEGOTIATION_DETAIL_ADDITIONS',
  [NegotiationTypeEnum.CLOSURE]: 'ANNUAL_TARGET.CONTEXT_MENU.SHOW_NEGOTIATION_DETAIL_CLOSURE'
};

const NegotiationsContextMenuItems = {
  getShowNegotiationDetailItem: (params) => {
    const lsiCode = NegotiationDetailMenuLabels[params.node.data.negotiationType];
    if (lsiCode) {
      return new MenuItemDefBuilder(params, lsiCode)
        .iconFromPath(mdiTextBoxSearchOutline)
        .action(() => {
          params.context.onShowNegotiationDetailModalForm(params.node.data);
        })
        .build();
    }
  },
  getUpdateNegotiationItem: (params) => {
    const pFnCtxObject = params.node.data.privilegeFunctionalityContextObject;
    const data = params.node.data;
    if (data.negotiationType === NegotiationTypeEnum.CONTRACT) {
      return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.UPDATE_NEGOTIATION_CONTRACTS')
        .iconFromPath(mdiPencilOutline)
        .disabled(!data.isNegotiationCreated())
        .action(() => {
          params.context.onShowNegotiationUpdateModalForm(data);
        })
        .privilegeFunctionalityContext(pFnCtxObject.updateNegotiationFnCtx)
        .build();
    } else if (data.negotiationType === NegotiationTypeEnum.ADDITION) {
      return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.UPDATE_NEGOTIATION_ADDITIONS')
        .iconFromPath(mdiPencilOutline)
        .disabled(!data.isNegotiationCreated())
        .action(() => {
          params.context.onShowNegotiationUpdateModalForm(data);
        })
        .privilegeFunctionalityContext(pFnCtxObject.updateNegotiationFnCtx)
        .build();
    }
  },
  getUpdateActivatedNegotiationItem: (params) => {
    const pFnCtxObject = params.node.data.privilegeFunctionalityContextObject;
    const data = params.node.data;
    if (data.negotiationType === NegotiationTypeEnum.CONTRACT) {
      return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.UPDATE_ACTIVATED_NEGOTIATION_CONTRACTS')
        .iconFromPath(mdiPencilOutline)
        .disabled(!data.isNegotiationActivated())
        .action(() => {
          params.context.onShowNegotiationUpdateModalForm(data);
        })
        .privilegeFunctionalityContext(pFnCtxObject.updateActivatedNegotiationFnCtx)
        .build();
    } else if (data.negotiationType === NegotiationTypeEnum.ADDITION) {
      return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.UPDATE_ACTIVATED_NEGOTIATION_ADDITIONS')
        .iconFromPath(mdiPencilOutline)
        .disabled(!data.isNegotiationActivated())
        .action(() => {
          params.context.onShowNegotiationUpdateModalForm(data);
        })
        .privilegeFunctionalityContext(pFnCtxObject.updateActivatedNegotiationFnCtx)
        .build();
    }
  },
  getNegotiationWorkflowItem: (params) => {
    const pFnCtxObject = params.node.data.privilegeFunctionalityContextObject;
    const data = params.node.data;
    if (data.isNegotiationCreated()) {
      return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.ACTIVATE_NEGOTIATION')
        .iconFromPath(mdiEarth)
        .action(() => {
          params.context.onShiftNegotiationWorkflow({
            status: NegotiationStatusEnum.ACTIVATED,
            negotiationId: data.id
          });
        })
        .privilegeFunctionalityContext(pFnCtxObject.negotiationShiftStateToActivatedFnCtx)
        .build();
    }
  },
  getCancelNegotiationClosureItem: (params) => {
    const pFnCtxObject = params.node.data.privilegeFunctionalityContextObject;
    return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.CANCEL_NEGOTIATION_CLOSURE')
      .iconFromPath(mdiNotebookPlusOutline)
      .action(() => {
        params.context.onCancelNegotiationClosure({
          negotiationId: params.node.data.id
        });
      })
      .privilegeFunctionalityContext(pFnCtxObject.cancelNegotiationClosureFnCtx)
      .build();
  }
};

export default NegotiationsContextMenuItems;
