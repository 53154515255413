import { userRoleSorter } from '../../../utils/userRoleSorter';

export const UserDetailInfoRowIds = {
  LOGIN_UID: 'LOGIN_UID',
  DISPLAY_NAME: 'DISPLAY_NAME',
  BID: 'BID',
  BRAND: 'BRAND',
  DEALER_NUMBER: 'DEALER_NUMBER'
};

export const getDynamicRows = (userInfo) => {
  const rowIds = [
    { rowId: UserDetailInfoRowIds.LOGIN_UID },
    { rowId: UserDetailInfoRowIds.DISPLAY_NAME },
    { rowId: UserDetailInfoRowIds.BID },
    { rowId: UserDetailInfoRowIds.BRAND },
    { rowId: UserDetailInfoRowIds.DEALER_NUMBER }
  ];
  // union of user own roles and expected roles
  const roleList = [...new Set([...userInfo.userInfoOwn.roleList, ...userInfo.userInfoExpected.roleList])];
  const imitatedRoleList = userInfo.userInfoImitated.roleList;

  roleList.sort(userRoleSorter(imitatedRoleList));

  roleList.forEach((role) => {
    rowIds.push({
      rowId: role
    });
  });

  return rowIds;
};
