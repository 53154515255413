import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetAvailableBidsFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.SUPP_USER_CREDENTIALS__GET_AVAILABLE_BID_LIST);
  }

}

export default GetAvailableBidsFunctionalityContext;