import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import {
    dateValueFormatter,
    evaluateConditionResultItem,
    resolveCellClass
} from '../../ValidationResultPGItemTable.helpers';
import {EXCEL_STYLES_IDS, nonGroupNode} from '../../../../../../../common/components/ag-grid';
import {RowType} from "../../ValidationResultPGItemTable.const";

class PriceValidFromCUID extends AbstractColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.PRICE_VALID_FROM;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList } = additionalParams;

    return {
      colId: this.getCode(),
      hide: !visible,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRICE_VALID_FROM'),
      headerTooltip: [
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRICE_VALIDITY_PART_1'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRICE_VALIDITY_PART_2')
      ],
      cellClass: resolveCellClass(this._evaluateConditionResultItemPriceValidFrom),
      field: 'priceValidFrom',
      valueFormatter: dateValueFormatter,
      type: [...columnTypeList],
      filter: 'agDateColumnFilter',
      flex: 13,
      minWidth: 135
    };
  }
  _evaluateConditionResultItemPriceValidFrom = (cellClassParams) => {
    if (nonGroupNode(cellClassParams) && RowType.isPGItem(cellClassParams.data.rowType)) {
      const data = cellClassParams.data;
      const conditionResult = data.conditionResultList.find(cr => cr.uiDefinition === this.getCode());
      return [
        ...evaluateConditionResultItem(data.rowType, conditionResult.result),
        EXCEL_STYLES_IDS.DATE
      ];
    }
    return [EXCEL_STYLES_IDS.DATE, 'ovex-ag-grid--td-right'];
  }
  /** @override */
  excelExportValueFormatter = (processCellForExportParams) => {
    return processCellForExportParams.value?.toISOString();
  }
}

const priceValidFromCUID = new PriceValidFromCUID();

export default priceValidFromCUID;