import React from 'react';
import PropTypes from 'prop-types';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';

import CommissionRow from './CommissionRow';
import { columnTypes, getColumnDefinitions } from './CommissionTable.columnDefs';

const propTypes = {
  className: PropTypes.string,
  commissionList: PropTypes.arrayOf(PropTypes.instanceOf(CommissionRow))
};

const defaultProps = {
  className: null,
  commissionList: null
};

const CommissionTable = (props) => {
  const { commissionList, className } = props;

  const lsi = React.useContext(LsiContext);

  const classNames = ['ovex-CommissionTable'];
  className && classNames.push(className);

  return (
    <OvexAGTable
      className={classNames.join(' ')}
      columnDefs={getColumnDefinitions(lsi)}
      columnTypes={columnTypes}
      enableRangeSelection
      height='640px'
      rowData={commissionList}
    />
  );
};

CommissionTable.propTypes = propTypes;
CommissionTable.defaultProps = defaultProps;

export default CommissionTable;
