import { combineReducers } from 'redux';

import { moduleReducerName } from '../../config/moduleConfig';

import annualTargetYear from './annual-target-year/annual-target-year';
import commissions from './commissions/commissions';
import commissionsLoadParams from './commissions/commissions-load-params';
import contractPreview from './contract-preview/contract-preview';
import contracts from './contracts/contracts';
import dealerContract from './dealer-contract/dealer-contract';
import dealerContracts from './dealer-contract/dealer-contracts';
import updateContractAnnualTarget from './dealer-contract/update-contract-annual-target';
import dealers from './dealers/dealers';
import productionCapacityRatio from './productionCapacityRatio/production-capacity-ratio';
import modelGroups from './model-group/modelGroups';
import negotiations from './negotiations/negotiations';
import negotiationsClosure from './negotiations/negotiations-closure';
import views from './views/views';
//
import userInfo from './about/userInfo/userInfo';
//
import privilege from './privilege/privilege/privilege';
//
import initialization from './initialization/initialization';

const moduleReducers = {
  propName: moduleReducerName,
  combineReducers: combineReducers({
    annualTargetYear: annualTargetYear,
    commissions: commissions,
    commissionsLoadParams: commissionsLoadParams,
    contractPreview: contractPreview,
    contracts: contracts,
    dealerContract: dealerContract,
    dealerContracts: dealerContracts,
    updateContractAnnualTarget: updateContractAnnualTarget,
    dealers: dealers,
    productionCapacityRatio: productionCapacityRatio,
    modelGroups: modelGroups,
    negotiations: negotiations,
    negotiationsClosure: negotiationsClosure,
    views: views,

    userInfo: userInfo,

    privilege: privilege,

    initialization: initialization
  })
};

export default moduleReducers;
