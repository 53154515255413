import React from 'react';

import { ButtonBar } from '../../../../common/components';
import { FilterContextButtons } from '../../../../common/components/forms';

const propTypes = {};

const defaultProps = {};

const CommissionValidatorButtonBar = (props) => {
  return (
    <ButtonBar
      className='ovex-pg-CommissionValidatorButtonBar'
    >
      <FilterContextButtons />
    </ButtonBar>
  );
};

CommissionValidatorButtonBar.propTypes = propTypes;
CommissionValidatorButtonBar.defaultProps = defaultProps;

export default CommissionValidatorButtonBar;
