import { RESTRICTION_COLUMN_IDS } from './RestrictionsTableData';

const handleProcessCellCallback = (params) => {
  if ([
    RESTRICTION_COLUMN_IDS.RESTRICTION_TYPE
  ].includes(params.column.colId)) {
    return params.column.colDef.valueFormatter(params);
  }
  return params.value;
};

export const exportExcel = {
  processCellCallback: handleProcessCellCallback,
  skipRowGroups: true
};
