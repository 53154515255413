import React from 'react';
import PropTypes from 'prop-types';
import { ModelGroupDTO } from '@ovex/annual-target-web-api';

import { Button, ButtonBar, Modal, Tabs } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';
import NegotiationRow from '../NegotiationsTable/NegotiationRow';

import YearSettingsTabs from './YearSettingsTabs';
import YearSettingsHeader from './YearSettingsHeader/YearSettingsHeader';
import YearSettingsModelGroups from './YearSettingsModelGroups/YearSettingsModelGroups';
import YearSettingsTexts from './YearSettingsTexts/YearSettingsTexts';

import './YearSettingsModalForm.scss';

const propTypes = {
  className: PropTypes.string,
  modelGroupList: PropTypes.arrayOf(PropTypes.instanceOf(ModelGroupDTO)),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  shown: PropTypes.bool,
  yearSettings: PropTypes.instanceOf(NegotiationRow).isRequired
};

const defaultProps = {
  className: null,
  modelGroupList: null,
  onSave: null,
  shown: false
};

const YearSettingsModalForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = props.onSave == null;

  const classNames = ['ovex-YearSettingsModalForm'];
  props.className && classNames.push(props.className);

  const [ selectedTab, setSelectedTab ] = React.useState(YearSettingsTabs.header);
  const handleSetSelectedTab = React.useCallback(
    (opt) => setSelectedTab(opt.tab.props.name),
    []
  );

  const tabFormValues = React.useRef({});

  const headerFormRef = React.useRef(null);
  const modelGroupsFormRef = React.useRef(null);
  const textsFormRef = React.useRef(null);

  const onSave = props.onSave;
  const handleSave = React.useCallback(() => {
    if (headerFormRef.current.isValid()) {
      headerFormRef.current.save();

      if (modelGroupsFormRef.current.isValid()) {
        modelGroupsFormRef.current.save();

        if (textsFormRef.current.isValid()) {
          textsFormRef.current.save();

          onSave && onSave({
            atYearId: props.yearSettings.id,
            header: tabFormValues.current[YearSettingsTabs.header],
            modelGroupWithMixRatioList: tabFormValues.current[YearSettingsTabs.modelGroups],
            texts: tabFormValues.current[YearSettingsTabs.texts]
          });
        } else {
          setSelectedTab(YearSettingsTabs.texts);
        }
      } else {
        setSelectedTab(YearSettingsTabs.modelGroups);
      }
    } else {
      setSelectedTab(YearSettingsTabs.header);
    }
  }, [onSave, props.yearSettings]);

  const handleSaveTabFrom = React.useCallback(
    ({ formId, values }) => {
      tabFormValues.current[formId] = values;
    },
    []
  );

  return (
    <Modal
      className={classNames.join(' ')}
      header={readOnly ? lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.YEAR_SETTING_DETAIL') : lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.YEAR_SETTING_UPDATE')}
      onClose={props.onClose}
      shown={props.shown}
      size='m'
    >
      <Tabs
        activeName={selectedTab}
        onChange={handleSetSelectedTab}
      >
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.YEAR_SETTINGS_MODAL.HEADER')}
          name={YearSettingsTabs.header}
        >
          <YearSettingsHeader
            atSalesPeriodList={props.yearSettings.atSalesPeriodList}
            coefficient={props.yearSettings.coefficient}
            formId={YearSettingsTabs.header}
            onSave={readOnly ? undefined : handleSaveTabFrom}
            readOnlyYear
            ref={headerFormRef}
            year={props.yearSettings.year}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.YEAR_SETTINGS_MODAL.MODELS')}
          name={YearSettingsTabs.modelGroups}
        >
          <YearSettingsModelGroups
            formId={YearSettingsTabs.modelGroups}
            modelGroupList={props.modelGroupList}
            onSave={readOnly ? undefined : handleSaveTabFrom}
            ref={modelGroupsFormRef}
            yearSettingsModelGroupList={props.yearSettings.modelGroupList}
          />
        </Tabs.Item>
        <Tabs.Item
          header={lsi.getLSIItem('ANNUAL_TARGET.TABS.YEAR_SETTINGS_MODAL.TEXTS')}
          name={YearSettingsTabs.texts}
        >
          <YearSettingsTexts
            formId={YearSettingsTabs.texts}
            onSave={readOnly ? undefined : handleSaveTabFrom}
            ref={textsFormRef}
            textMap={props.yearSettings.textMap}
          />
        </Tabs.Item>
      </Tabs>
      <ButtonBar
        align={ButtonBar.align.center}
        formControlsBar
        hidden={readOnly}
      >
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.CANCEL')}
          onClick={props.onClose}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.SAVE')}
          onClick={handleSave}
          type={Button.type.PRIMARY}
        />
      </ButtonBar>
    </Modal>
  );
});

YearSettingsModalForm.propTypes = propTypes;
YearSettingsModalForm.defaultProps = defaultProps;

export default YearSettingsModalForm;
