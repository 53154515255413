import { NegotiationTypeEnum } from '../../../../../utils/const';
import { YEAR_SHIFT_CURRENT_YEAR } from '../../NegotiationModalFrom.consts';
import { fixNumber } from '../../../../../../common/utils/helpers';

const MIN_SUM_PROD_CAPACITY_RATIO = 0;
const MAX_SUM_PROD_CAPACITY_RATIO = 99.9999;
const MAX_SUM_PROD_CAPACITY_RATIO_CURRENT_YEAR = 1;
const MAX_DECIMAL_PERCENTAGE_DIGITS = 4; // 99.9999

class ModelGroupRow {

  constructor(modelGroup, yearShiftList, unitOrderList, productionCapacityRatioList, negotiationType) {
    this.id = modelGroup.id;
    this.modelGroup = modelGroup.modelGroup;
    this.modelGroupName = modelGroup.modelGroupName;
    this.modelMixRatio = modelGroup.modelMixRatio;

    this.productionCapacityATPeriodList = initProductionCapacityATPeriodList(modelGroup.productionCapacityATPeriodList, yearShiftList, unitOrderList, productionCapacityRatioList, modelGroup.modelGroup, negotiationType);
    this.negotiable = initNegotiable(modelGroup.negotiable);
  }

  isModified(periodColumnContext) {
    const helper = this._findProductionCapacityATPeriod(periodColumnContext);
    return helper?.productionCapacityRatio !== helper?._productionCapacityRatioValue;
  }

  isPeriodValid(periodColumnContext) {
    const helper = this._findProductionCapacityATPeriod(periodColumnContext);
    return helper?.productionCapacityRatio != null;
  }

  setValue(value, periodColumnContext) {
    const helper = this._findProductionCapacityATPeriod(periodColumnContext);
    if (helper) {
      helper.productionCapacityRatio = value;
    }
  }

  getValue(periodColumnContext) {
    const helper = this._findProductionCapacityATPeriod(periodColumnContext);
    if (helper) {
      return helper.productionCapacityRatio;
    }
    return null;
  }

  getTotal(yearShift) {
    const total = this.productionCapacityATPeriodList
      .filter(p => p.yearShift === yearShift)
      .reduce(
        (sum, atPeriod) => atPeriod.productionCapacityRatio != null ? sum + atPeriod.productionCapacityRatio : sum,
        null
      );
    return fixNumber(total, MAX_DECIMAL_PERCENTAGE_DIGITS);
  }

  setNegotiable(negotiable) {
    this.negotiable = negotiable;
  }

  isValid(yearShiftList, atPeriodList) {
    return yearShiftList.every(yearShift => {
      return (yearShift === YEAR_SHIFT_CURRENT_YEAR && !this.negotiable)
        || (this.isPeriodsValid(yearShift, atPeriodList) && this.isTotalValid(yearShift));
    });
  }

  _findProductionCapacityATPeriod = ({ yearShift, unitOrder }) => {
    return this.productionCapacityATPeriodList.find(pc => pc.yearShift === yearShift && pc.unitOrder === unitOrder);
  };

  isPeriodsValid(yearShift, atPeriodList = null) {
    const yearShiftPeriodList = this.productionCapacityATPeriodList.filter(pc => pc.yearShift === yearShift);

    if (yearShift === YEAR_SHIFT_CURRENT_YEAR) {
      const periodNegotiableMap = {};
      atPeriodList.forEach(period => {
        periodNegotiableMap[period.unitOrder] = period.negotiable;
      });
      return yearShiftPeriodList.every(pc => !periodNegotiableMap[pc.unitOrder] || pc.productionCapacityRatio != null);
    }

    return yearShiftPeriodList.every(pc => pc.productionCapacityRatio != null);
  }

  isTotalValid(yearShift) {
    const sum = this.getTotal(yearShift);

    if (yearShift === YEAR_SHIFT_CURRENT_YEAR) {
      return !this.negotiable || (sum != null && sum === MAX_SUM_PROD_CAPACITY_RATIO_CURRENT_YEAR);
    }

    return sum != null && MIN_SUM_PROD_CAPACITY_RATIO <= sum && sum <= MAX_SUM_PROD_CAPACITY_RATIO;
  }
}

const initProductionCapacityATPeriodList = (productionCapacityATPeriodList, yearShiftList, unitOrderList, productionCapacityRatioList, modelGroup, negotiationType) => {
  if (productionCapacityATPeriodList) {
    // READ, or EDIT mode
    return productionCapacityATPeriodList.map(pc => ({
      id: pc.id,
      yearShift: pc.yearShift,
      unitOrder: pc.unitOrder,
      productionCapacityRatio: pc.productionCapacityRatio,
      _productionCapacityRatioValue: pc.productionCapacityRatio
    }));
  }

  // CREATION mode
  return yearShiftList.flatMap(yearShift => {
    return unitOrderList.flatMap(unitOrder => {
      return [
        constructProductionCapacityRatioRow(negotiationType, yearShift, unitOrder, modelGroup, productionCapacityRatioList)
      ];
    });
  });
};

const constructProductionCapacityRatioRow = (negotiationType, yearShift, unitOrder, modelGroup, productionCapacityRatioList) => {
  const productionCapacityRatio = findProductionCapacityRatio(negotiationType, yearShift, unitOrder, modelGroup, productionCapacityRatioList);
  return {
    id: undefined,
    yearShift: yearShift,
    unitOrder: unitOrder,
    productionCapacityRatio: productionCapacityRatio,
    _productionCapacityRatioValue: productionCapacityRatio
  };
};

const findProductionCapacityRatio = (negotiationType, yearShift, unitOrder, modelGroup, productionCapacityRatioList) => {
  if (negotiationType === NegotiationTypeEnum.ADDITION && productionCapacityRatioList != null) {
    const productionCapacityRatio = productionCapacityRatioList.find(pcr => pcr.yearShift === yearShift && pcr.unitOrder === unitOrder && pcr.modelGroup === modelGroup);
    return productionCapacityRatio == null || false ? null : productionCapacityRatio.productionCapacityRatio;
  } else {
    return null;
  }
};

const initNegotiable = (negotiable) => {
  return negotiable == null ? true : negotiable;
};

export default ModelGroupRow;
