import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class GetDealerContractPreviewFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} contractId
   */
  constructor(contractId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__GET_CONTRACT_PREVIEW);
    this.setParams({
      [ContextConstants.CONTRACT_ID]: contractId
    });
  }

}

export default GetDealerContractPreviewFunctionalityContext;