import { ContractAnnualTargetSalesRecommendedUpdateDTO } from '@ovex/annual-target-web-api';

import { AG_GROUP_TOTAL } from '../../DealerAnnualTarget/utils/const';

class PeriodAnnualTargetSalesRow {

  constructor(groupValue) {
    this.groupValue = groupValue;
    this._atsRecommendedNumberOriginal = null;
  }

  isTotalRow = () => this.groupValue === AG_GROUP_TOTAL;
  isPeriodRow = () => !this.isTotalRow();

  isEditable = () => {return this.isPeriodRow();};

  setRecommendedNumber = (value) => {
    this.atsRecommendedNumber = value;
  };

  undoATSRecommendedNumber = () => {
    this.atsRecommendedNumber = this._atsRecommendedNumberOriginal;
  };

  isATSRecommendedNumberModified = () => {
    return this.atsRecommendedNumber !== this._atsRecommendedNumberOriginal && this.isPeriodRow();
  };

  getATSRecommendedNumberModification = () => {
    const dto = new ContractAnnualTargetSalesRecommendedUpdateDTO(this.id, this.version);
    dto.recommendedNumber = this.atsRecommendedNumber;
    return dto;
  }

}
PeriodAnnualTargetSalesRow.prototype.groupValue = undefined;

PeriodAnnualTargetSalesRow.prototype.id = undefined;
PeriodAnnualTargetSalesRow.prototype.version = undefined;
PeriodAnnualTargetSalesRow.prototype.dealerNumberCode = undefined;
PeriodAnnualTargetSalesRow.prototype.periodOrder = undefined;
PeriodAnnualTargetSalesRow.prototype.negotiable = undefined;

PeriodAnnualTargetSalesRow.prototype.atsDealerNumber = undefined;
PeriodAnnualTargetSalesRow.prototype.atsRecommendedNumber = undefined;
PeriodAnnualTargetSalesRow.prototype.atsApprovalNumber = undefined;
PeriodAnnualTargetSalesRow.prototype.atsApprovedNumber = undefined;
PeriodAnnualTargetSalesRow.prototype.atsStatisticsSoldCount = undefined;

PeriodAnnualTargetSalesRow.prototype.atsStatisticsRemainsCount = undefined;

PeriodAnnualTargetSalesRow.prototype.privilegeFunctionalityContextObject = undefined;

export default PeriodAnnualTargetSalesRow;
