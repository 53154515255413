import { v4 as uuidv4 } from 'uuid';

import { Alert, AlertTypeEnum } from '../../packages/common/objects';

/**
 * @param {object} error
 * @return {module:ovex/main/Alert}
 */
const resolveAlert = (error) => {
  const xhr = error.response ? error.response.xhr : error;

  if (xhr.readyState === 4) {
    if (error.body != null) {
      return Alert.constructFromObject(error.body);
    }
    return generalErrorAlert(typeof xhr.responseText === 'string' ? xhr.responseText : 'Undefined error');
  } else if (xhr?.error?.code === 'ECONNABORTED') {
    return timeoutErrorAlert(xhr?.error);
  }

  return networkErrorAlert(error);
};

const generalErrorAlert = (responseText) => {
  return Alert.constructFromObject({
    uuid: uuidv4(),
    alertItemList: [
      {
        level: AlertTypeEnum.ERROR,
        code: 'GENERAL_ERROR',
        lsiCode: 'MAIN.ERROR_MESSAGE.GENERAL_ERROR',
        parameters: [ responseText ]
      }
    ]
  });
};

const timeoutErrorAlert = (timeoutError) => {
  return Alert.constructFromObject({
    uuid: uuidv4(),
    alertItemList: [
      {
        level: AlertTypeEnum.ERROR,
        code: 'TIMEOUT_ERROR',
        lsiCode: 'MAIN.ERROR_MESSAGE.TIMEOUT_ERROR',
        parameters: [ `timeout: ${timeoutError.timeout}ms` ]
      }
    ]
  });
};

const networkErrorAlert = (error) => {
  return Alert.constructFromObject({
    uuid: uuidv4(),
    alertItemList: [
      {
        level: AlertTypeEnum.ERROR,
        code: 'NETWORK_ERROR',
        lsiCode: 'MAIN.ERROR_MESSAGE.NETWORK_ERROR',
        parameters: [ JSON.stringify(error) ]
      }
    ]
  });
};

export default resolveAlert;
