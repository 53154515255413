import { PriceGuaranteeStatusEnum } from '../../../utils/const';

import {
  InputNamesHeader,
  InputAccountingBasisList
} from './PriceGuaranteeFormInputNames';

/**
 * method that handles editable inputs for PG modal
 * @param priceGuaranteeStatus {object} status of PG/ if not present then creation mode
 * @returns {object} object with editable inputs
 */
export const handleEditableInputs = (priceGuaranteeStatus= null) => {
  switch (priceGuaranteeStatus) {
    case null:
    case PriceGuaranteeStatusEnum.CREATED:
      return [
        InputNamesHeader.marketingActionCode,
        InputNamesHeader.name,
        InputNamesHeader.commissionCreationDateTo,
        InputNamesHeader.commissionProduceWeekFirstTo,
        InputNamesHeader.commissionSellingDateTo,
        InputNamesHeader.type,
        InputNamesHeader.daysFromInvoiceToSale,
        InputNamesHeader.twoWeeksAfterSale,
        InputAccountingBasisList.accountingBasisList
      ];
    case PriceGuaranteeStatusEnum.RETURNED_TO_CORRECTION:
      return [
        InputAccountingBasisList.accountingBasisList
      ];
    default: return [];
  }
};
/**
 * Uses editRules, if not present, then uses global read only property
 * @param editRules {array} from {@link handleEditableInputs} function
 * @param inputName {string}
 * @param readOnly {boolean} is readOnly
 * @returns {boolean|boolean}
 */
export const isReadOnlyInput = (editRules, inputName, readOnly) => {
  return editRules ? !editRules.includes(inputName) : readOnly;
};