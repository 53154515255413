/**
 * @readonly
 * @enum {String}
 */
const UserModalEnum = {
  USER_INFO: 'USER_INFO',
  USER_IMITATE: 'USER_IMITATE',
  USER_EMIGRATE: 'USER_EMIGRATE',
  USER_REVERT: 'USER_REVERT',
  DEV_USER_CHANGE: 'DEV_USER_CHANGE'
};

export default UserModalEnum;