import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useEvaluateModuleContext from './useEvaluateModuleContext';

/**
 * @param {ModuleContextData} externModuleContextData - use only out of module scope
 */
const usePrivilege = (externModuleContextData = null) => {
  /**
   * @type {ModuleContextData}
   */
  let module = useEvaluateModuleContext(externModuleContextData);

  const dispatch = useDispatch();
  const privilegeAction = (module && module.privilegeAction) ? module.privilegeAction : () => {}; // Do nothing when the *privilegeAction* is not set
  const handlePrivilegeActionCallback = React.useCallback(
    (functionalityContextList) => dispatch(privilegeAction(functionalityContextList)),
    [dispatch, privilegeAction]
  );

  const privilegeSelector = (module && module.privilegeSelector) ? module.privilegeSelector : () => {};
  const cacheWrapper = useSelector(privilegeSelector);

  const handleIsPrivilegedCallback = React.useCallback(
    handleGetIsPrivilegedCallback(cacheWrapper),
    [cacheWrapper]
  );

  return {
    handleIsPrivileged: handleIsPrivilegedCallback,
    handlePrivilegeRequest: handlePrivilegeActionCallback
  };
};

const handleGetIsPrivilegedCallback = (cacheWrapper) => {
  /**
   * @param {object|Array.<object>} functionalityContext
   * @param {boolean} whenNotSet - when privilege property not set, return this value (Default: true)
   * @return {boolean}
   */
  return (functionalityContext, whenNotSet = true) => {
    if (cacheWrapper == null) {
      return true; // All allowed when the *cacheWrapper* not loaded
    }
    if (functionalityContext == null) {
      return whenNotSet;
    }
    const cache = cacheWrapper.cache;

    if (Array.isArray(functionalityContext)) {
      if (functionalityContext.length === 0) {
        return false;
      }
      return functionalityContext.every(fCtx => evaluateResult(cache.getPrivilegeResult(fCtx)));
    }
    return evaluateResult(cache.getPrivilegeResult(functionalityContext));
  };
};

/**
 * @param {object} result - PrivilegedComponentDTO
 * @return {boolean}
 */
const evaluateResult = (result) => result != null && result.rejectionReason == null;

export default usePrivilege;