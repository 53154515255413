import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { handleGetPriceGuaranteeListPGR, handleResetPriceGuaranteeReportPGR } from '../../redux/modules/price-guarantee-report/price-guarantee-report';
import PriceGuaranteeReportPageTypeContext from '../PriceGuaranteeReport/PriceGuaranteeReportPageTypeContext';

import PriceGuaranteeReportExport from './PriceGuaranteeReportExport';

const propTypes = {
  onExportPriceGuaranteeReportReduxFn: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  titleLsiCode: PropTypes.string.isRequired
};

const defaultProps = {};

const PriceGuaranteeReportExportConnector = (props) => {
  const { onExportPriceGuaranteeReportReduxFn, pageType, titleLsiCode } = props;

  const selectedState = useSelector(state => {
    return {
      isFetching: state.priceGuarantee.priceGuaranteeReport.isFetching,
      priceGuaranteeList: state.priceGuarantee.priceGuaranteeReport.priceGuaranteeList
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => ({
      handleGetPriceGuaranteeListPGR: () => dispatch(handleGetPriceGuaranteeListPGR()),
      handleExportPriceGuaranteeReportPGR: (filterValues) => dispatch(onExportPriceGuaranteeReportReduxFn(filterValues)),
      handleResetPriceGuaranteeReportPGR: () => dispatch(handleResetPriceGuaranteeReportPGR())
    }),
    [onExportPriceGuaranteeReportReduxFn, dispatch]
  );

  return (
    <PriceGuaranteeReportPageTypeContext.Provider value={pageType} >
      <PriceGuaranteeReportExport
        isFetching={selectedState.isFetching}
        onExportPriceGuaranteeReport={callbacks.handleExportPriceGuaranteeReportPGR}
        onGetPriceGuaranteeList={callbacks.handleGetPriceGuaranteeListPGR}
        onResetPriceGuaranteeReport={callbacks.handleResetPriceGuaranteeReportPGR}
        priceGuaranteeList={selectedState.priceGuaranteeList}
        titleLsiCode={titleLsiCode}
      />
    </PriceGuaranteeReportPageTypeContext.Provider>
  );
};

PriceGuaranteeReportExportConnector.propTypes = propTypes;
PriceGuaranteeReportExportConnector.defaultProps = defaultProps;

export default PriceGuaranteeReportExportConnector;
