import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class ACGetDealerRestrictionListFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PVT_AC__GET_DEALER_RESTRICTION_LIST);
  }

}

export default ACGetDealerRestrictionListFunctionalityContext;