import PriceGuaranteeReportRowType from './rowModel/PriceGuaranteeReportRowType';

export const getDataPath = data => data.hierarchy;

export const getRowClass = (rowClassParams) => {
  const data = rowClassParams.data;
  if (PriceGuaranteeReportRowType.isHeaderType(data.getRowType())) {
    return 'ovex-pg-PriceGuaranteeReportTable-row--commission';
  }
};

export const isCellProcessingExportToExcel = ({ type }) => type === 'excel';
