import convertToType from '../convertToType';

import AlertTypeEnum from './AlertTypeEnum';

/**
 * The AlertItem model module.
 * @module ovex/main/AlertItem
 * @version 1.0.0
 */
class AlertItem {
  /**
   * Constructs a new <code>AlertItem</code>.
   * @alias module:ovex/main/AlertItem
   * @param level {module:ovex/main/AlertTypeEnum}
   * @param code {String}
   */
  constructor(level, code) {

    AlertItem.initialize(this, level, code);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, level, code) {
    obj['level'] = level;
    obj['code'] = code;
  }

  /**
   * Constructs a <code>AlertItem</code> from a plain JavaScript object.
   * Copies all relevant properties from <code>data</code> to a new instance of <code>AlertItem</code>.
   *
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   *
   * @return {AlertItem} The populated <code>AlertItem</code> instance.
   */
  static constructFromObject(data) {
    if (data) {
      const obj = new AlertItem(null, null);

      if (data.hasOwnProperty('level')) {
        obj['level'] = AlertTypeEnum.constructFromObject(data['level']);
      }
      if (data.hasOwnProperty('code')) {
        obj['code'] = convertToType(data['code'], 'String');
      }
      if (data.hasOwnProperty('parameters')) {
        obj['parameters'] = convertToType(data['parameters'], [Object]);
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = convertToType(data['message'], 'String');
      }

      if (data.hasOwnProperty('lsiCode')) {
        obj['lsiCode'] = convertToType(data['lsiCode'], 'String');
      }

      return obj;
    }
    return null;
  }

}

/**
 * @member {module:ovex/main/AlertTypeEnum} level
 */
AlertItem.prototype.level = undefined;

/**
 * @member {String} code
 */
AlertItem.prototype.code = undefined;

/**
 * @member {Array.<Object>} parameters
 */
AlertItem.prototype.parameters = undefined;

/**
 * Localized message with parameters (if available)
 * @member {String} message
 */
AlertItem.prototype.message = undefined;

/**
 * @member {String} lsiCode - try to use if *message* not available
 */
AlertItem.prototype.lsiCode = undefined;

export default AlertItem;

