import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecCurrentModelNegCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__CURRENT__MODEL_NEG;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.CURRENT;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.MODEL_NEG;
  }

}

const specCurrentModelNegCUID = new SpecCurrentModelNegCUID();

export default specCurrentModelNegCUID;