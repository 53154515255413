import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const productionCapacityRatioApi = ApiServiceInstances.annualTarget.productionCapacityRatioApi;

export const CLEAN_PRODUCTION_CAPACITY_RATIO = 'CLEAN_PRODUCTION_CAPACITY_RATIO';

export const GET_PRODUCTION_CAPACITY_RATIO_REQUEST = 'GET_PRODUCTION_CAPACITY_RATIO_REQUEST';
export const GET_PRODUCTION_CAPACITY_RATIO_SUCCESS = 'GET_PRODUCTION_CAPACITY_RATIO_SUCCESS';
export const GET_PRODUCTION_CAPACITY_RATIO_FAILURE = 'GET_PRODUCTION_CAPACITY_RATIO_FAILURE';

const initialState = {
  productionCapacityRatioList: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_CAPACITY_RATIO_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_PRODUCTION_CAPACITY_RATIO_SUCCESS: {
      return { ...state, isFetching: false,
        productionCapacityRatioList: action.payload
      };
    }
    case GET_PRODUCTION_CAPACITY_RATIO_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case CLEAN_PRODUCTION_CAPACITY_RATIO:
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleCleanProductionCapacityRatio = () => {
  return {
    type: CLEAN_PRODUCTION_CAPACITY_RATIO
  };
};

export const getProductionCapacityRatio = (year) => {
  return {
    [CALL_API]: {
      apiFunction: productionCapacityRatioApi.getProductionCapacityRatioWithHttpInfo.bind(productionCapacityRatioApi, year),
      types: [GET_PRODUCTION_CAPACITY_RATIO_REQUEST, GET_PRODUCTION_CAPACITY_RATIO_SUCCESS, GET_PRODUCTION_CAPACITY_RATIO_FAILURE]
    }
  };
};

