
class WebModuleReduxData {

  /**
   * @param {String} reduxPropName
   * @param {Object} combineReducers - map of reducers
   * @param {Object} actions - map of actions
   */
  constructor(reduxPropName, combineReducers, actions) {
    this.reduxPropName = reduxPropName;
    this.combineReducers = combineReducers;
    this.actions = actions;
  }

}

/**
 * @type {String}
 */
WebModuleReduxData.prototype.reduxPropName = undefined;
/**
 * @type {Object} map of reducers
 */
WebModuleReduxData.prototype.combineReducers = undefined;
/**
 * @type {Object} map of actions
 */
WebModuleReduxData.prototype.actions = undefined;

export default WebModuleReduxData;