import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CommissionDTO } from '@ovex/pvt-web-api';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';

import { columnTypes, getColumnDefinitions } from './SwapCommissionPvtTable.columDef';
import { getRowClass, getRowHeight } from './SwapCommissionPvtTable.helpers';

import './SwapCommissionPvtTable.scss';

const propTypes = {
  commission: PropTypes.instanceOf(CommissionDTO),
  commissionList: PropTypes.arrayOf(PropTypes.instanceOf(CommissionDTO)),
  onAdeptCommissionSelected: PropTypes.func.isRequired
};
const defaultProps = {
  commission: null,
  commissionList: null
};

/**
 * The table is set to **single** selection - the selected element is at index 0.
 * @type {number}
 */
const FIRST_SELECTED_ROW = 0;

const SwapCommissionPvtTable = (props) => {
  const lsi = useContext(LsiContext);

  const { commission, commissionList, onAdeptCommissionSelected } = props;

  const handleRowSelected = (params) => {
    const selectedCommission = params.api.getSelectedRows()?.[FIRST_SELECTED_ROW];

    onAdeptCommissionSelected(selectedCommission);
  };

  return <OvexAGTable
    agContext={{
      commissionSource: commission
    }}
    className='ovex-pvt-SwapCommissionPvtTable'
    columnDefs={getColumnDefinitions(lsi)}
    columnTypes={columnTypes}
    getRowClass={getRowClass}
    getRowHeight={getRowHeight}
    onRowSelected={handleRowSelected}
    pinnedTopRowData={commission ? [commission] : null}
    rowData={commissionList ? commissionList : []}
    rowMultiSelectWithClick={true}
    rowSelection={'single'}
  />;
};

SwapCommissionPvtTable.propTypes = propTypes;
SwapCommissionPvtTable.defaultProps = defaultProps;

export default SwapCommissionPvtTable;
