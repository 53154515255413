import { PGI_EDITABLE_COLUMN_ID_LIST } from './PriceGuaranteeItemTable.columnIds';

export const getSpecificationKey = (snapshot, type) => `${snapshot}__${type}`;

export const getEditableCell = (agParams) => {
  const colId = (agParams.column || agParams.colDef).colId;
  return agParams.node.group ? null : (agParams.data || agParams.node.data).editableCells[colId];
};

export const editableCellValueGetter = (valueGetterParams) => getEditableCell(valueGetterParams)?.getValue();

export const editableCellValueParser = (valueParserParams) => {
  const editableCell = getEditableCell(valueParserParams);
  if (editableCell) {
    return editableCell.parseValue(valueParserParams.newValue);
  }
  return valueParserParams.newValue;
};

export const editableCellValueSetter = (valueSetterParams) => {
  const editableCell = getEditableCell(valueSetterParams);
  editableCell && editableCell.setValue(valueSetterParams.newValue);
  return true;
};

export const handleEditableCellIsEditable = (agParams) => {
  const editableModelGroup = agParams.context.editableModelGroup;
  const onAgIsPrivileged = agParams.context.onAgIsPrivileged;
  const editableCell = getEditableCell(agParams);
  return agParams.data.isEditable(editableModelGroup, onAgIsPrivileged)
    && !!editableCell && editableCell.isEditable();
};

export const handleProcessCellFromClipboard = (processCellForExportParams) => {
  const value = processCellForExportParams.value;
  if (PGI_EDITABLE_COLUMN_ID_LIST.includes(processCellForExportParams.column.getColId())) {
    const editableCell = getEditableCell(processCellForExportParams);
    return editableCell && editableCell.parseValue(value);
  }
  return value;
};
