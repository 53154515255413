import React from 'react';
import { useDispatch } from 'react-redux';

import { handleAddAlert } from '../../../redux/modules/main/alertBus';
import { Alert, AlertTypeEnum } from '../objects';

const useAlertBusOvex = () => {
  const dispatch = useDispatch();

  const handleAddAlertCallback = React.useCallback(
    (alert) => dispatch(handleAddAlert(Alert.constructFromObject(alert))),
    [dispatch]
  );

  const handleAddAlertSimple = React.useCallback(
    (lsiCode, level = AlertTypeEnum.INFO) => handleAddAlertCallback({
      uuid: '12345678-abcd-4ace-8008-aedb9652c4f1',
      alertItemList: [{ code: lsiCode, lsiCode: lsiCode, level: level }]
    }),
    [handleAddAlertCallback]
  );

  return {
    handleAddAlert: handleAddAlertCallback,
    handleAddAlertSimple: handleAddAlertSimple
  };
};

export default useAlertBusOvex;