import { mdiTextBoxOutline } from '@mdi/js';
import { CommissionLoadParamsDTO, CommissionLoadATSalesParamsDTO } from '@ovex/annual-target-web-api';

import { handleGetDefaultContextMenuItems, MenuItemDefBuilder } from '../../../../common/components/ag-grid';
import { COLUMN_IDS, COMMISSION_LIST_ATS_AVAILABLE_COLUMN_IDS } from '../utils/tableBaseColumnDefs';
import { AggregationTypeEnum } from '../../../utils/const';
import { AG_GROUP_TOTAL } from '../utils/const';

export const handleGetContextMenuItems = (getContextMenuItemsParams ) => {
  return [
    PeriodAnnualTargetSalesTableMenuItems.getShowCommissionListItem(getContextMenuItemsParams),
    'separator',
    ...handleGetDefaultContextMenuItems(getContextMenuItemsParams)
  ];
};

const PeriodAnnualTargetSalesTableMenuItems = {
  getShowCommissionListItem: (getContextMenuItemsParams) => {
    const { column, context, node, api } = getContextMenuItemsParams;
    const { onShowCommissionListWithParams, statisticsRequestId, statisticsType, negotiationType } = context;

    const colId = column.getColId();
    const isAvailable = onShowCommissionListWithParams != null
      && COMMISSION_LIST_ATS_AVAILABLE_COLUMN_IDS.includes(colId);

    if (!isAvailable) {
      return null;
    }

    return new MenuItemDefBuilder(getContextMenuItemsParams, 'ANNUAL_TARGET.CONTEXT_MENU.SHOW_COMMISSION_LIST')
      .iconFromPath(mdiTextBoxOutline)
      .action(() => {
        const params = new CommissionLoadParamsDTO(_getAggregationTypeList(colId), statisticsRequestId, statisticsType, negotiationType);
        params.atSalesParams = new CommissionLoadATSalesParamsDTO();
        params.atSalesParams.contractAtSalesPeriodIdList = [];

        if (node.group && node.key === AG_GROUP_TOTAL) {
          // for all dealers and their periods
          api.forEachLeafNode(rowNode => {
            if (rowNode.data.isPeriodRow()) {
              params.atSalesParams.contractAtSalesPeriodIdList.push(rowNode.data.id);
            }
          });
        } else if (node.group) {
          // for all periods of one dealer
          node.allLeafChildren.forEach(rowNode => {
            params.atSalesParams.contractAtSalesPeriodIdList.push(rowNode.data.id);
          });
        } else if (node.data.isTotalRow()) {
          // for all dealers in one period
          const periodOrder = node.data.periodOrder;
          api.forEachLeafNode(rowNode => {
            if (rowNode.data.isPeriodRow() && rowNode.data.periodOrder === periodOrder) {
              params.atSalesParams.contractAtSalesPeriodIdList.push(rowNode.data.id);
            }
          });
        } else if (node.data.isPeriodRow()) {
          // for specified period of one dealer
          params.atSalesParams.contractAtSalesPeriodIdList.push(node.data.id);
        }

        onShowCommissionListWithParams(params);
      })
      .build();
  }
};

const _getAggregationTypeList = (colId) => {
  switch (colId) {
    case COLUMN_IDS.STATISTICS_DONE_NUMBER:
      return [AggregationTypeEnum.ATS_SOLD];
    default:
  }
};
