import { PvtApiClient } from '../../../../api';

class AvailableCapacityRestrictionWeekCell {

  /**
   * @param {Object|AvailableCapacityRestrictionWeekCell|module:model/RestrictionWeekDTO} data
   * @return {AvailableCapacityRestrictionWeekCell}
   */
  static constructFromObject(data, acRowTypeEnum) {
    let obj;
    if (data) {
      obj = new AvailableCapacityRestrictionWeekCell();

      // RestrictionWeekDTO attributes
      if (data.hasOwnProperty('id')) {
        obj['id'] = PvtApiClient.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('week')) {
        obj['week'] = PvtApiClient.convertToType(data['week'], 'String');
      }
      if (data.hasOwnProperty('numberTotal')) {
        obj['numberTotal'] = PvtApiClient.convertToType(data['numberTotal'], 'String');
      }
      if (data.hasOwnProperty('atAvailableCapacity')) {
        obj['atAvailableCapacity'] = PvtApiClient.convertToType(data['atAvailableCapacity'], 'String');
      }
      if (data.hasOwnProperty('nonRestrictionCar')) {
        obj['nonRestrictionCar'] = PvtApiClient.convertToType(data['nonRestrictionCar'], 'String');
      }
      if (acRowTypeEnum !== undefined) {
        obj['type'] = acRowTypeEnum;
      }
    }
    return obj;
  }
  getQuotaValue = () => {
    return this.numberTotal;
  };
  getQuotaNonRestrictiveValue = () => {
    return this.nonRestrictionCar;
  };

  getROCValue = () => {
    return this.atAvailableCapacity;
  };

}

// RestrictionWeekDTO properties
AvailableCapacityRestrictionWeekCell.prototype['id'] = undefined;
AvailableCapacityRestrictionWeekCell.prototype['week'] = undefined;
AvailableCapacityRestrictionWeekCell.prototype['numberTotal'] = undefined;
AvailableCapacityRestrictionWeekCell.prototype['atAvailableCapacity'] = undefined;
AvailableCapacityRestrictionWeekCell.prototype['nonRestrictionCar'] = undefined;

export default AvailableCapacityRestrictionWeekCell;

