
class UserModalContextValue {

  /**
   * @param {UserModalEnum} value
   * @param {Function} setValue
   */
  constructor(value, setValue) {
    this.value = value;
    this.setValue = setValue;
  }
}

/**
 * @type {UserModalEnum}
 */
UserModalContextValue.prototype.value = null;
/**
 * @type {Function}
 */
UserModalContextValue.prototype.setValue = null;

export default UserModalContextValue;