import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetNegotiationDealersFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_NEGOTIATIONS__GET_NEGOTIATION_DEALERS);
  }

}

export default GetNegotiationDealersFunctionalityContext;