import AbstractFunctionalityContext from '../../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../../FunctionalityCodes';

class GetCommissionListForImporterFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__GET_COMMISSION_LIST_IMPORTER);
  }

}

export default GetCommissionListForImporterFunctionalityContext;
