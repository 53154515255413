import { ContractStatusEnum } from '../utils/const';

/**
 * Same mapping as in JAVA:
 *  - vwg.skoda.ovex.annualtarget.workflow.contract.ContractWorkflow
 */
const ContractWorkflow = {
  [ContractStatusEnum.CREATED]: {
    followingWorkflowStatuses: [
      ContractStatusEnum.DIRECTOR_APPROVED,
      ContractStatusEnum.REJECTED
    ]
  },
  [ContractStatusEnum.DIRECTOR_APPROVED]: {
    followingWorkflowStatuses: [
      ContractStatusEnum.CREATED,
      ContractStatusEnum.AREA_ADVISOR_APPROVED,
      ContractStatusEnum.REJECTED
    ]
  },
  [ContractStatusEnum.AREA_ADVISOR_APPROVED]: {
    followingWorkflowStatuses: [
      ContractStatusEnum.CREATED,
      ContractStatusEnum.AA_MANAGER_APPROVED,
      ContractStatusEnum.REJECTED
    ]
  },
  [ContractStatusEnum.AA_MANAGER_APPROVED]: {
    followingWorkflowStatuses: []
  },
  [ContractStatusEnum.REJECTED]: {
    followingWorkflowStatuses: []
  },
  /**
   * @param {string} status - actual contract status
   * @param {string} followingStatus - following contract status
   * @return {boolean} *true* if following status is valid, otherwise *false*
   */
  isValidFollowingState: (status, followingStatus) => {
    const contractWorkflowElement = ContractWorkflow[status];
    if (contractWorkflowElement != null) {
      return contractWorkflowElement.followingWorkflowStatuses.includes(followingStatus);
    }
    return false;
  }
};

export default ContractWorkflow;