import React from 'react';
import PropTypes from 'prop-types';
import { AnnualTargetPreviewTableContentDTO, AnnualTargetPreviewTableFooterDTO, AnnualTargetPreviewTableHeaderDTO } from '@ovex/annual-target-web-api';

import { OvexAGTable, EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';

const propTypes = {
  heading: PropTypes.string.isRequired,
  tableContent: PropTypes.arrayOf(PropTypes.instanceOf(AnnualTargetPreviewTableContentDTO)).isRequired,
  tableFooter: PropTypes.instanceOf(AnnualTargetPreviewTableFooterDTO).isRequired,
  tableHeader: PropTypes.instanceOf(AnnualTargetPreviewTableHeaderDTO).isRequired
};

const defaultProps = {};

const agDefaultColDef = {
  suppressMovable: true,
  menuTabs: []
};

const ContractAnnualTargetPreviewTable = (props) => {

  const columnDefs = React.useMemo(() => ([
    {
      headerName: props.tableHeader.modelGroup,
      valueGetter: 'data && data.modelGroupCode',
      width: 194,
      cellStyle: { 'font-weight': 'bold' },
      cellClass: EXCEL_STYLES_IDS.STRING
    },
    {
      headerName: props.tableHeader.modelGroupName,
      valueGetter: 'data && data.modelGroupName',
      width: 400,
      cellStyle: params => params.data.rowType === 'footer' ? { 'text-align': 'right' } : null
    },
    {
      headerName: props.tableHeader.annualTargetNumber,
      valueGetter: 'data && data.annualTargetNumber',
      width: 120,
      cellStyle: { 'font-weight': 'bold' },
      type: 'numericColumn'
    }
  ]),
  [props.tableHeader]
  );

  const rowData = React.useMemo(() => ([
    ...props.tableContent,
    // footer object must correspond with content object
    {
      rowType: 'footer',
      modelGroupCode: '',
      modelGroupName: props.tableFooter.annualTargetTotal,
      annualTargetNumber: props.tableFooter.totalNumber
    }
  ]), [props.tableContent, props.tableFooter]);

  return (
    <div
      className='ovex-at-ContractPreview-tableBlock'
    >
      <div
        className='ovex-at-ContractPreview-tableBlock-header'
      >
        {props.heading}
      </div>
      <div
        className='ovex-at-ContractPreview-tableBlock-table'
      >
        <OvexAGTable
          columnDefs={columnDefs}
          defaultColDef={agDefaultColDef}
          domLayout='print'
          rowData={rowData}
        />
      </div>
    </div>
  );
};

ContractAnnualTargetPreviewTable.propTypes = propTypes;
ContractAnnualTargetPreviewTable.defaultProps = defaultProps;

export default ContractAnnualTargetPreviewTable;