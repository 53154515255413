import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import './ButtonBar.scss';

const ButtonBarAlign = {
  left: 'left',
  center: 'center',
  right: 'right'
};

const propTypes = {
  align: PropTypes.oneOf(Object.values(ButtonBarAlign) ),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  formControlsBar: PropTypes.bool,
  hidden: PropTypes.bool
};

const defaultProps = {
  align: ButtonBarAlign.left,
  className: null,
  formControlsBar: false,
  hidden: false
};

const ButtonBar = React.memo((props) => {
  if (props.hidden) {
    return null;
  }

  const classNames = ['ovex-ButtonBar'];
  props.className && classNames.push(props.className);
  props.formControlsBar && classNames.push('ovex-ButtonBar--formControls');

  if (props.align === ButtonBarAlign.center) {
    classNames.push('ovex-ButtonBar--align-center');
  } else if (props.align === ButtonBarAlign.right) {
    classNames.push('ovex-ButtonBar--align-right');
  }

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      {props.children}
    </Bricks.Div>
  );
});

ButtonBar.propTypes = propTypes;
ButtonBar.defaultProps = defaultProps;

ButtonBar.align = ButtonBarAlign;
ButtonBar.Separator = (props) => (<Bricks.Div className='ovex-ButtonBar-separator' hidden={props.hidden} />);

export default ButtonBar;