import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { ovexMiddleware } from '../middleware';
import createRootReducer from '../reducers/rootReducer';

const enhancers = [];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

/**
 * Configure Redux store.
 *
 * @param {object} history - initial state of the store
 * @param {object} preloadedState - initial state of the store
 */
const configureStore = (history, preloadedState) => {
  const middleware = [
    thunk,
    routerMiddleware(history), // for dispatching history actions
    ovexMiddleware
  ];

  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  return createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );
};

export default configureStore;
