import React from 'react';
import { useRouteMatch } from 'react-router-dom';

/**
 * @typedef {Object} MatchResultObject - Type definition based on return object from function **matchPath** (react-router.modules.matchPath.js)
 * @property {string} path
 * @property {string} url
 * @property {boolean} isExact
 * @property {Object} params
 */

/**
 * Hook is used to memorizing the result of standard *useRouteMatch* hook.
 *
 * @param {string} path
 * @return {MatchResultObject|null}
 */
const useRouteMatchMemo = (path) => {
  const match = useRouteMatch(path);

  const [ matchMemo, setMatchMemo ] = React.useState(match);

  if (!equalsMatch(match, matchMemo)) {
    setMatchMemo(match);
    return match;
  }

  return matchMemo;
};

/**
 * @param {MatchResultObject|null} matchA
 * @param {MatchResultObject|null} matchB
 * @return {boolean}
 */
const equalsMatch = (matchA, matchB) => {
  if (matchA != null && matchB != null) {
    return matchA.url === matchB.url;
  }
  return matchA === matchB;
};

export default useRouteMatchMemo;