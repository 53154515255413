/**
 * The name of the input that the SAML redirect page contains to login.
 * @type {string}
 */
const SAML_REDIRECT_INPUT_NAME = 'SAMLRequest';

/**
 * SAML redirect workaround
 *
 * @param request
 */
function samlPlugin(request) {
  request.on('response', resolveSAMLResponse);
}

/**
 * SAML redirect workaround
 *
 * @param {Response} response
 */
function resolveSAMLResponse(response) {
  if (response.type === 'text/html') {
    const htmlText = response.text || '';
    if (htmlText.includes(SAML_REDIRECT_INPUT_NAME) && response.status === 200) {
      const winUrl = URL.createObjectURL(new Blob([htmlText], { type: response.type }));
      window.open(winUrl, '_parent');

      response.status = 204;
    }
  }
}

export default samlPlugin;
