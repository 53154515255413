import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class CreateNegotiationFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} atYearId
   * @param {string} negotiationType
   */
  constructor(atYearId, negotiationType) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_NEGOTIATIONS__CREATE_NEGOTIATION);
    this.setParams({
      [ContextConstants.AT_YEAR_ID]: atYearId,
      [ContextConstants.NEGOTIATION_TYPE]: negotiationType
    });
  }

}

export default CreateNegotiationFunctionalityContext;