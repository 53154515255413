import {
  mdiMagnify
} from '@mdi/js';

import { MenuItemDefBuilder } from '../../../../common/components/ag-grid';

import PriceGuaranteeReportRowType from './rowModel/PriceGuaranteeReportRowType';

export const getContextMenuItems = (getContextMenuItemsParams) => {
  const { node } = getContextMenuItemsParams;

  if (PriceGuaranteeReportRowType.isHeaderType(node.data.getRowType())) {
    return [
      PriceGuaranteeReportMenuItems.geShowCommissionValidatorItem(getContextMenuItemsParams),
      'separator',
      'copy',
      'copyWithHeaders'
    ];
  }

  return [
    'copy',
    'copyWithHeaders'
  ];
};

const PriceGuaranteeReportMenuItems = {
  geShowCommissionValidatorItem: (getContextMenuItemsParams) => {
    const { node, context } = getContextMenuItemsParams;
    if (context.onShowCommissionValidator == null) {
      return null;
    }

    return new MenuItemDefBuilder(getContextMenuItemsParams, 'PRICE_GUARANTEE.CONTEXT_MENU.SHOW_COMMISSION_VALIDATOR')
      .iconFromPath(mdiMagnify)
      .action(() => context.onShowCommissionValidator(node.data.getCommissionValidatorData()))
      .build();
  }
};
