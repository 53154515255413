import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserModalContext } from '../../../../components/user-modal';
import { handleChangeUserBid, handleGetAvailableBidList } from '../../redux/modules/actions';

import UserChangeBidModal from './UserChangeBidModal';
import {HOME_PAGE_URL} from "../../utils/const";

const UserChangeBidModalConnector = () => {
  const userModal = React.useContext(UserModalContext);

  const selectedState = useSelector(state => {
    return {
      bidList: state.support.getAvailableBidList.bidList
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        onGetAvailableBidList: () => dispatch(handleGetAvailableBidList()),
        onChangeUserBid: async (bid) => {
          await dispatch(handleChangeUserBid(bid));
          window.location = HOME_PAGE_URL;
        }
      };
    },
    [dispatch]
  );
  React.useEffect(
    () => {
      callbacks.onGetAvailableBidList();
    }
    , [callbacks]
  );

  const handleClose = () => userModal?.setValue(null);
  return (
    <UserChangeBidModal
      bidList={selectedState.bidList}
      onChangeUserBid={callbacks.onChangeUserBid}
      onClose={handleClose}
      onGetAvailableBidList={callbacks.onGetAvailableBidList}
    />
  );
};

export default UserChangeBidModalConnector;