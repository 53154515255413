import React from 'react';

import { handleGetPriceGuaranteeReportImporterPGR } from '../../../redux/modules/actions';
import PriceGuaranteeReportConnector from '../PriceGuaranteeReportConnector';
import PriceGuaranteeReportPageType from '../PriceGuaranteeReportPageType';
import { VLDGetPriceGuaranteeListPFC, VLDGetCommissionValidationResultImporterPFC } from '../../../privilege/context';
import routes from '../../../routes';
import { usePrivilege } from '../../../../common/hooks';

/**
 * This "importer connector" is currently not used.
 * The importer role do not have access to the report page with the "table".
 *
 * @see PriceGuaranteeReportExportImporterConnector
 */
const PriceGuaranteeReportImporterConnector = () => {
  const { handleIsPrivileged } = usePrivilege();

  const commissionValidatorPath = handleIsPrivileged([new VLDGetPriceGuaranteeListPFC(), new VLDGetCommissionValidationResultImporterPFC()])
    ? routes.paths.ROUTE_PATH_COMMISSION_VALIDATOR_IMPORTER
    : null;

  return (
    <PriceGuaranteeReportConnector
      commissionValidatorPath={commissionValidatorPath}
      onGetPriceGuaranteeReportReduxFn={handleGetPriceGuaranteeReportImporterPGR}
      pageType={PriceGuaranteeReportPageType.IMPORTER}
      titleLsiCode='REPORT_PRICE_GUARANTEE_IMPORTER'
    />
  );
};

export default PriceGuaranteeReportImporterConnector;
