import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import { RestrictionResponseDTO } from '@ovex/pvt-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { FormsContextSection } from '../../../common/components/forms';

import RestrictionsFilter from './RestrictionsFilter/RestrictionsFilter';
import RestrictionsTable from './RestrictionsTable/RestrictionsTable';

import './Restrictions.scss';

class Restrictions extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    isFetching: PropTypes.bool,
    onGetRestrictionList: PropTypes.func.isRequired,
    onUpdateRestrictionBulk: PropTypes.func.isRequired,
    restrictionResponse: PropTypes.instanceOf(RestrictionResponseDTO)
  };

  static defaultProps = {
    isFetching: true,
    restrictionResponse: null
  };

  constructor(props) {
    super(props);

    this.state = {
      filterData: {}
    };
  }

  componentDidMount() {
    this.handleReloadRestrictions();
  }

  storeAlertRef = (ref) => {
    this.alert = ref;
  };

  handleReloadRestrictions = async (filterData = null) => {
    const { onGetRestrictionList } = this.props;

    try {
      await onGetRestrictionList(filterData);
      this.setState({ filterData: filterData });
    } catch (e) {
      const lsi = this.context;
      this.alert.addAlert({ colorSchema: 'danger', content: lsi.getLSIItem('PVT.ERROR_MESSAGE.GET_RESTRICTIONS_FAILURE') });
    }
  };

  handleUpdateRestrictionBulk = async (changes) => {
    const lsi = this.context;
    const { onUpdateRestrictionBulk } = this.props;
    const { filterData } = this.state;

    try {
      const response = await onUpdateRestrictionBulk(changes);

      this.alert.addAlert({ content: lsi.getLSIItem('PVT.ERROR_MESSAGE.UPDATE_RESTRICTIONS_SUCCESS') });
      this.handleReloadRestrictions(filterData);

      return response;
    } catch (e) {
      this.alert.addAlert({ colorSchema: 'danger', content: lsi.getLSIItem('PVT.ERROR_MESSAGE.UPDATE_RESTRICTIONS_FAILURE') });
    }
  };

  render() {
    const lsi = this.context;
    const { isFetching, restrictionResponse } = this.props;

    return (
      <Page
        className='Restrictions'
        header={lsi.getLSIItem('PVT.PAGE_TITLE.QUOTAS_AND_RESTRICTIONS')}
        loading={isFetching}
      >
        <FormsContextSection>
          <RestrictionsFilter
            onFilterSubmit={this.handleReloadRestrictions}
            weekLimits={restrictionResponse?.weekLimits}
          />
          <RestrictionsTable
            onUpdateRestrictions={this.handleUpdateRestrictionBulk}
            restrictions={restrictionResponse?.restrictionList}
            weekDetailList={restrictionResponse?.weekDetailList}
          />
        </FormsContextSection>
        <Bricks.AlertBus
          hidden
          ref_={this.storeAlertRef}
        />
      </Page>
    );
  }
}

export default Restrictions;
