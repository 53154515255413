import React from 'react';
import { useDispatch } from 'react-redux';

import { UserModalContext } from '../../../../components/user-modal';
import { handleRevertUserChanges } from '../../redux/modules/actions';
import { HOME_PAGE_URL } from '../../utils/const';

import UserRevertChangesModal from './UserRevertChangesModal';

const UserRevertChangesModalConnector = () => {
  const userModal = React.useContext(UserModalContext);

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        onRevertUserChanges: async () => {
          await dispatch(handleRevertUserChanges());
          window.location = HOME_PAGE_URL;
        }
      };
    },
    [dispatch]
  );

  const handleClose = () => userModal?.setValue(null);
  return (
    <UserRevertChangesModal
      onClose={handleClose}
      onRevertUserChanges={callbacks.onRevertUserChanges}
    />
  );
};

export default UserRevertChangesModalConnector;