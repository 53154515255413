import React from 'react';
import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();

  return React.useMemo(
    () => {
      const spObj = {};
      const urlSearchParams = new URLSearchParams(location.search);

      urlSearchParams.forEach((value, key) => {
        if (spObj.hasOwnProperty(key)) {
          // if the current key is already in object
          if (Array.isArray(spObj[key])) {
            // if the current key is already an array, we'll add the value to it
            spObj[key].push(value);
          } else {
            // if it's not an array, but contains a value, we'll convert it into an array
            // and add the current value to it
            spObj[key] = [spObj[key], value];
          }
        } else {
          // plain assignment if no special case is present
          spObj[key] = value;
        }
      });

      return spObj;
    },
    [location.search]
  );
};

export default useQueryParams;
