
const isOddRow = (params) => {
  const rowIndex = params.rowIndex;
  const node = params.node;
  const parentRowIndex = node.level === 0 || !!node.rowPinned ? 1 : node.parent.rowIndex;
  return Math.abs((parentRowIndex - rowIndex) % 2) === 1;
};

const leafRowOddEvenClassRules = {
  'ovex-ag-leaf-row-odd': (params) => {
    if (params.node.group) {
      return false;
    }
    return isOddRow(params);
  },
  'ovex-ag-leaf-row-even': (params) => {
    if (params.node.group) {
      return false;
    }
    return !isOddRow(params);
  }
};

export default leafRowOddEvenClassRules;
