import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { Code, SwitchSelector, WeekPicker } from '../../../../../common/components/forms';
import { Button, Modal } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import SAWeekUtils from '../../../../../common/utils/SAWeekUtils';
import { ProductionCorridorRow } from '../../objects';

import './LimitationProductionCorridorForm.scss';

const LimitationProductionCorridorForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = !props.onSave;

  const weekFromInit = SAWeekUtils.isAfter(props.limitWeekFrom, props.limitationProductionCorridor.weekFrom) ? props.limitationProductionCorridor.weekFrom : props.limitWeekFrom;
  const weekToInit = SAWeekUtils.isBefore(props.limitWeekTo, props.limitationProductionCorridor.weekTo) ? props.limitationProductionCorridor.weekTo : props.limitWeekTo;

  const [ weekFrom, setWeekFrom ] = React.useState(weekFromInit);
  const [ weekTo, setWeekTo ] = React.useState(weekToInit);

  const equipmentTypeSelectorMapper = (equipmentTypeMapper) => ({
    content: equipmentTypeMapper,
    value: equipmentTypeMapper
  });

  const handleSave = () => {
    props.onSave({
      weekFrom: weekFrom.toUpperCase(),
      weekTo: weekTo.toUpperCase()
    });
  };

  return (
    <Modal
      className='ovex-pvt-LimitationProductionCorridorForm'
      header={readOnly ? lsi.getLSIItem('PVT.PAGE_TITLE.LIMITATION_DETAIL') : lsi.getLSIItem('PVT.PAGE_TITLE.LIMITATION_UPDATE')}
      onClose={props.onClose}
      shown={props.shown}
    >
      <Forms.Form
        onCancel={props.onClose}
        onSave={handleSave}
      >
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.GROUP')}
          name='modelGroup'
          readOnly
          value={props.limitationProductionCorridor.modelGroup}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.MASK')}
          maxLength={6}
          name='modelMask'
          pattern='^[a-zA-Z0-9]{3}[a-zA-Z0-9-]{3}$'
          readOnly
          required
          upperCase
          value={props.limitationProductionCorridor.modelMask}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.COLOUR')}
          maxLength={4}
          name='colour'
          pattern='^$|^[a-zA-Z0-9]{4}$'
          readOnly
          upperCase
          value={props.limitationProductionCorridor.colour}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.INTERIOR')}
          maxLength={2}
          name='interior'
          pattern='^$|^[a-zA-Z0-9]{2}$'
          readOnly
          upperCase
          value={props.limitationProductionCorridor.interior}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.EQUIPMENT')}
          maxLength={3}
          name='equipment'
          pattern='^$|^[a-zA-Z0-9]{3}$'
          readOnly
          upperCase
          value={props.limitationProductionCorridor.equipment}
        />
        <SwitchSelector
          items={props.equipmentTypesAvailable.map(equipmentTypeSelectorMapper)}
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.EQUIPMENT_TYPE')}
          name={'equipmentType'}
          readOnly
          value={props.limitationProductionCorridor.equipmentType}
        />
        <WeekPicker
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_FROM')}
          name='weekFrom'
          onChange={setWeekFrom}
          readOnly={readOnly}
          tooltip={lsi.getLSIItem('PVT.TOOLTIP.PRODUCTION_CORRIDOR_INPUT_WEEK_DEFAULT_VALUE', props.limitWeekFrom)}
          value={weekFrom}
          weekFrom={props.limitWeekFrom}
          weekTo={weekTo == null ? props.limitWeekTo : weekTo}
        />
        <WeekPicker
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_TO')}
          name='weekTo'
          onChange={setWeekTo}
          readOnly={readOnly}
          tooltip={lsi.getLSIItem('PVT.TOOLTIP.PRODUCTION_CORRIDOR_INPUT_WEEK_DEFAULT_VALUE', props.limitWeekTo)}
          value={weekTo}
          weekFrom={weekFrom == null ? props.limitWeekFrom : weekFrom}
          weekTo={props.limitWeekTo}
        />
        <Forms.Controls
          buttonCancelProps={Button.formControlsProps[Button.type.SECONDARY]}
          buttonSubmitProps={Button.formControlsProps[Button.type.PRIMARY]}
        />
      </Forms.Form>
    </Modal>
  );
});

LimitationProductionCorridorForm.propTypes = {
  limitWeekFrom: PropTypes.string.isRequired,
  limitWeekTo: PropTypes.string.isRequired,
  limitationProductionCorridor: PropTypes.instanceOf(ProductionCorridorRow).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  shown: PropTypes.bool
};

LimitationProductionCorridorForm.defaultProps = {
  onSave: undefined,
  shown: false
};

export default LimitationProductionCorridorForm;
