import React from 'react';
import PropTypes from 'prop-types';
import { mdiCheckBoxOutline, mdiCheckboxBlankOutline } from '@mdi/js';
import Icon from '@mdi/react';

import './ATPeriodRenderer.scss';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    negotiable: PropTypes.bool
  })
};

const defaultProps = {
  className: null,
  value: null
};

const ATPeriodRenderer = React.memo((props) => {
  const { node, value, className } = props;

  if (node.group || node.data.isNegotiationClosureRow()) {
    return '';
  }

  const classNames = ['ovex-ATPeriodRenderer'];
  className && classNames.push(className);

  const negotiable = value?.negotiable;

  let iconPath;
  if (negotiable === true) {
    iconPath = mdiCheckBoxOutline;
  } else if (negotiable === false) {
    iconPath = mdiCheckboxBlankOutline;
  }

  return (
    <div
      className={classNames.join(' ')}
    >
      <div>
        { iconPath &&
          <Icon
            path={iconPath}
            size={0.6}
          />
        }
      </div>
    </div>
  );
});

ATPeriodRenderer.propTypes = propTypes;
ATPeriodRenderer.defaultProps = defaultProps;

export default ATPeriodRenderer;
