
class WebModule {

  /**
   * @param {String} code
   * @param {WebModuleInitializationData} initializationData
   * @param {WebModuleNavMenuData[]} navMenuDataList
   * @param {WebModuleReduxData} reduxData
   * @param {WebModuleRoutesData} routesData
   * @param {WebModuleUserInfoData} userInfoData
   */
  constructor(code, initializationData, navMenuDataList, reduxData, routesData, userInfoData) {
    this.code = code;
    this.initializationData = initializationData;
    this.navMenuDataList = navMenuDataList;
    this.reduxData = reduxData;
    this.routesData = routesData;
    this.userInfoData = userInfoData;
  }

}

/**
 * @type {String}
 */
WebModule.prototype.code = undefined;
/**
 * @type {WebModuleInitializationData}
 */
WebModule.prototype.initializationData = undefined;
/**
 * @type {WebModuleNavMenuData[]}
 */
WebModule.prototype.navMenuDataList = undefined;
/**
 * @type {WebModuleReduxData}
 */
WebModule.prototype.reduxData = undefined;
/**
 * @type {WebModuleRoutesData}
 */
WebModule.prototype.routesData = undefined;
/**
 * @type {WebModuleUserInfoData}
 */
WebModule.prototype.userInfoData = undefined;

export default WebModule;