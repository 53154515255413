import { WebModule, WebModuleInitializationData, WebModuleNavMenuData, WebModuleUserInfoData } from '../web-module';

import PriceGuaranteeInitialization from './components/PriceGuaranteeInitialization/PriceGuaranteeInitialization';
import PriceGuaranteeNavMenu from './components/PriceGuaranteeNavMenu/PriceGuaranteeNavMenu';
import { moduleCode, userInfoSelector, initializationStatusSelector } from './config/moduleConfig';
import reduxData from './redux';
import routesData from './routes';

const navMenuDataList = [
  new WebModuleNavMenuData(300, PriceGuaranteeNavMenu)
];

const modulePriceGuarantee = new WebModule(
  moduleCode,
  new WebModuleInitializationData(PriceGuaranteeInitialization, initializationStatusSelector),
  navMenuDataList,
  reduxData,
  routesData,
  new WebModuleUserInfoData(reduxData.actions.handleGetUserInfo, userInfoSelector)
);

export default modulePriceGuarantee;