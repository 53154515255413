import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

class Link extends React.PureComponent {

  static propTypes = {
    children: PropTypes.any,
    context: PropTypes.any,
    onClick: PropTypes.func.isRequired
  };

  static defaultProps = {
    children: null,
    context: null
  };

  handleOnClick = () => {
    const { context, onClick } = this.props;

    onClick && onClick(context);
  };

  render() {
    const { children } = this.props;

    return (
      <Bricks.Link onClick={this.handleOnClick}>
        {children}
      </Bricks.Link>
    );
  }
}

export default Link;
