import React from 'react';
import PropTypes from 'prop-types';
import { PriceGuaranteeDTOPGR } from '@ovex/price-guarantee-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { FormsContextSection } from '../../../common/components/forms';
import { useAlertBusOvex } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';
import { CLAIM_TYPE_HEADER_ENUM_LIST, LIMIT_PG_REPORT_EXPORT_ROWS } from '../../utils/const';

import PriceGuaranteeReportPageTypeContext from './PriceGuaranteeReportPageTypeContext';
import PriceGuaranteeReportFilter from './PriceGuaranteeReportFilter/PriceGuaranteeReportFilter';
import PriceGuaranteeReportButtonBar from './PriceGuaranteeReportButtonBar/PriceGuaranteeReportButtonBar';
import PriceGuaranteeReportTable from './PriceGuaranteeReportTable/PriceGuaranteeReportTable';
import { getExportExcelConfig } from './PriceGuaranteeReportTable/PriceGuaranteeReportTable.exportParams';
import { PriceGuaranteeReportRow } from './PriceGuaranteeReportTable/rowModel/PriceGuaranteeReportRow';

import './PriceGuaranteeReport.scss';

const propTypes = {
  currency: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  onGetPriceGuaranteeList: PropTypes.func.isRequired,
  onGetPriceGuaranteeReport: PropTypes.func.isRequired,
  onResetPriceGuaranteeReport: PropTypes.func.isRequired,
  onShowCommissionValidator: PropTypes.func,
  pgReportRowList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeReportRow)),
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTOPGR)),
  titleLsiCode: PropTypes.string.isRequired
};

const defaultProps = {
  currency: null,
  onShowCommissionValidator: null,
  pgReportRowList: null,
  priceGuaranteeList: null
};

const PriceGuaranteeReport = (props) => {
  const lsi = React.useContext(LsiContext);
  const pageType = React.useContext(PriceGuaranteeReportPageTypeContext);
  const {
    isFetching, titleLsiCode,
    priceGuaranteeList, pgReportRowList, currency,
    onGetPriceGuaranteeList, onGetPriceGuaranteeReport, onResetPriceGuaranteeReport, onShowCommissionValidator
  } = props;

  const { handleAddAlertSimple } = useAlertBusOvex();

  React.useEffect(
    () => {
      try {
        onGetPriceGuaranteeList();
      } catch (e) {
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_LIST_PGR_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetPriceGuaranteeList, handleAddAlertSimple]
  );

  const handleGetPriceGuaranteeReport = React.useCallback(
    async (filterValues) => {
      try {
        await onGetPriceGuaranteeList();
        await onGetPriceGuaranteeReport(filterValues);
      } catch (e) {
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_REPORT_PGR_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetPriceGuaranteeList, onGetPriceGuaranteeReport, handleAddAlertSimple]
  );

  const aggRef = React.useRef(null);
  const handleExport = React.useCallback(
    () => {
      if (aggRef.current) {
        const exportExcel = getExportExcelConfig(pageType);
        let rowExportCount = 0;
        aggRef.current.api.forEachNodeAfterFilter(node => {
          const rowExported = !exportExcel.shouldRowBeSkipped({ node });
          rowExported && (rowExportCount++);
        });

        if (rowExportCount > LIMIT_PG_REPORT_EXPORT_ROWS) {
          handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.EXPORT_PRICE_GUARANTEE_REPORT_PGR_ROW_LIMIT', AlertTypeEnum.WARNING);
        } else {
          aggRef.current.api.exportDataAsExcel(exportExcel);
        }
      }
    },
    [handleAddAlertSimple, pageType]
  );

  return (
    <Page
      className='ovex-pg-PriceGuaranteeReport'
      header={lsi.getLSIItem(`PRICE_GUARANTEE.PAGE_TITLE.${titleLsiCode}`)}
      loading={isFetching}
    >
      <FormsContextSection>
        <PriceGuaranteeReportFilter
          claimTypeHeaderList={CLAIM_TYPE_HEADER_ENUM_LIST}
          onLoadPriceGuaranteeReport={handleGetPriceGuaranteeReport}
          onResetPriceGuaranteeReport={onResetPriceGuaranteeReport}
          priceGuaranteeList={priceGuaranteeList}
        />
        <PriceGuaranteeReportButtonBar
          onExport={handleExport}
        />
      </FormsContextSection>
      <PriceGuaranteeReportTable
        currency={currency}
        onShowCommissionValidator={onShowCommissionValidator}
        pgReportRowList={pgReportRowList}
        ref={aggRef}
      />
    </Page>
  );
};

PriceGuaranteeReport.propTypes = propTypes;
PriceGuaranteeReport.defaultProps = defaultProps;

export default PriceGuaranteeReport;
