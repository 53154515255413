import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class UpdateContractAnnualTargetSalesDealerFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} contractAtSalesPeriodId
   */
  constructor(contractAtSalesPeriodId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__UPDATE_CONTRACT_ATS_DEALER);
    this.setParams({
      [ContextConstants.CONTRACT_AT_SALES_PERIOD_ID]: contractAtSalesPeriodId
    });
  }

}

export default UpdateContractAnnualTargetSalesDealerFunctionalityContext;