import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import { ContractDTO, ContractPreviewDTO } from '@ovex/annual-target-web-api';

import { Select } from '../../../common/components/forms';
import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { contractNameFormatter } from '../../utils/formatters';

import ContractPreview from './ContractPreview/ContractPreview';

import './ContractPreviewPage.scss';
import './ContractPreviewPage.print.scss';

const propTypes = {
  contractIdSelected: PropTypes.string.isRequired,
  contractList: PropTypes.arrayOf(PropTypes.instanceOf(ContractDTO)),
  contractPreview: PropTypes.instanceOf(ContractPreviewDTO),
  fetching: PropTypes.bool,
  onChangeContractPreview: PropTypes.func
};

const defaultProps = {
  contractList: null,
  contractPreview: null,
  fetching: true,
  onChangeContractPreview: null
};

const ContractPreviewPage = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const contractSelectItemList = React.useMemo(() => {
    const contractList = props.contractList;
    if (contractList == null) {
      return [];
    }
    return contractList
      .map(contract => ({
        contract: contract,
        content: `${lsi.getLSIItem('ANNUAL_TARGET.ENUM_LABELS.CONTRACT_STATUS')[contract.status]} - ${contractNameFormatter(contract, lsi)}`,
        value: contract.id.toString()
      }));
  }, [props.contractList, lsi]);

  const onChangeContractPreview = props.onChangeContractPreview;
  const handleChangeSelectedItem = React.useCallback((opt) => {
    const contractSelectItem = contractSelectItemList.find(item => item.value === opt.value);
    onChangeContractPreview(contractSelectItem && contractSelectItem.contract.id);
  }, [contractSelectItemList, onChangeContractPreview]);

  return (
    <Page
      className='ovex-at-ContractPreviewPage'
      header={lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.CONTRACT_DETAIL')}
      loading={props.fetching}
    >
      <Bricks.Row key='ovex-DealerAnnualTarget-Select-contract'>
        <Bricks.Column width={400}>
          <Select
            className='ovex-DealerAnnualTarget-select-contract'
            items={contractSelectItemList}
            name='contractValue'
            onChange={handleChangeSelectedItem}
            value={props.contractIdSelected}
          />
        </Bricks.Column>
      </Bricks.Row>
      <ContractPreview
        contractPreview={props.contractPreview}
      />
    </Page>
  );
});

ContractPreviewPage.propTypes = propTypes;
ContractPreviewPage.defaultProps = defaultProps;

export default ContractPreviewPage;
