import React from 'react';
import PropTypes from 'prop-types';

import BaseTooltip from '../BaseTooltip/BaseTooltip';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

const defaultProps = {
  className: null,
  value: []
};

const OvexTooltip = (props) => {
  const valueToDisplay = Array.isArray(props.value) ? props.value : [props.value];

  return (
    <BaseTooltip className={props.className}>
      {valueToDisplay.map(val => (<p>{val}</p>))}
    </BaseTooltip>
  );
};

OvexTooltip.propTypes = propTypes;
OvexTooltip.defaultProps = defaultProps;

export default OvexTooltip;
