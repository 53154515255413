
class WebModuleInitializationData {

  /**
   * @param {Function} component - React function component
   * @param {Function} statusSelector - status selector to get initialization status from redux store
   */
  constructor(component, statusSelector) {
    this.component = component;
    this.statusSelector = statusSelector;
  }

}

/**
 * @type {Function} React function component
 */
WebModuleInitializationData.prototype.component = undefined;
/**
 * @type {Function} status selector to get initialization status from redux store
 */
WebModuleInitializationData.prototype.statusSelector = undefined;

export default WebModuleInitializationData;