import React from 'react';
import { useDispatch } from 'react-redux';

import { handleSetMenuItemList } from '../../../redux/modules/main/userMenu';
import PrivilegeFunctionalityContextPropType from '../privilege/PrivilegeFunctionalityContextPropType';

import useEvaluateModuleContext from './useEvaluateModuleContext';

import { usePrivilege } from './index';

/**
 * @param {ModuleContextData} externModuleContextData - use only out of module scope
 */
const useSetMenuItems = (externModuleContextData = null) => {

  const module = useEvaluateModuleContext(externModuleContextData);
  const dispatch = useDispatch();
  const { handleIsPrivileged } = usePrivilege(module);
  const moduleCode = module?.code;

  if (moduleCode == null) {
    throw new Error('Module code can not be null!');
  }

  let handleSetMenuItemCallback = React.useCallback(
    (menuItemList) => {
      const menuItemListPrivileged = menuItemList
        .filter(menuItem => handleIsPrivileged(menuItem[PrivilegeFunctionalityContextPropType.propName]))
        .map((menuItem) => ({
          label: menuItem.label,
          onClick: menuItem.onClick,
          order: menuItem.order
        }));

      dispatch(handleSetMenuItemList(moduleCode, menuItemListPrivileged));
    },
    [dispatch, handleIsPrivileged, moduleCode]
  );

  return {
    handleSetMenuItem: handleSetMenuItemCallback
  };
};

export default useSetMenuItems;