import { ContractAnnualTargetDealerUpdateDTO, ContractAnnualTargetApprovalUpdateDTO } from '@ovex/annual-target-web-api';

import { calculateDoneNumber, calculatePotentialNumber, calculateRemainsNumber } from '../../../utils/statisticsHelpers';
import { AG_GROUP_TOTAL } from '../utils/const';

class DealerModelGroupAnnualTargetRow {

  constructor(groupValue) {
    this.groupValue = groupValue;
    this._atDealerNumberOriginal=null;
    this._atApprovalNumberOriginal=null;
  }

  isTotalRow = () => this.groupValue === AG_GROUP_TOTAL;
  isPeriodRow = () => !this.isTotalRow();

  isEditable = () => this.isTotalRow();

  setATDealerNumber = (value) => {
    this.atDealerNumber = value;
  };
  setATApprovalNumber = (value) => {
    this.atApprovalNumber = value;
  };

  undoATDealerNumber = () => {
    this.atDealerNumber = this._atDealerNumberOriginal;
  };
  undoATApprovalNumber = () => {
    this.atApprovalNumber = this._atApprovalNumberOriginal;
  };

  isATDealerNumberModified = () => {
    return this.atDealerNumber !== this._atDealerNumberOriginal && this.isTotalRow();
  };
  isATApprovalNumberModified = () => {
    return this.atApprovalNumber !== this._atApprovalNumberOriginal && this.isTotalRow();
  };

  isModified = () => {
    return this.isATApprovalNumberModified() || this.isATDealerNumberModified();
  };

  discardChanges = () => {
    this.undoATApprovalNumber();
    this.undoATDealerNumber();
  };

  getATDealerNumberModification = () => new ContractAnnualTargetDealerUpdateDTO(this.id, this.version, this.atDealerNumber);
  getATApprovalNumberModification = () => new ContractAnnualTargetApprovalUpdateDTO(this.id, this.version, this.atApprovalNumber);

  getATStatisticsDoneCount = () => {
    return calculateDoneNumber(this.atStatisticsInvoicedCount, this.atStatisticsProductionCount, this.atStatisticsPlannedCount);
  }

  getATStatisticsRemainsCount = () => {
    return calculateRemainsNumber(this.atApprovedNumber, this.getATStatisticsDoneCount());
  }

  getATStatisticsPotentialCount = () => {
    return calculatePotentialNumber(this.atStatisticsPotentialProductionCount, this.atStatisticsPotentialPlannedCount);
  }

}

DealerModelGroupAnnualTargetRow.prototype.groupValue = undefined; // property for ag-Grid grouping and aggregations

DealerModelGroupAnnualTargetRow.prototype.id = undefined;
DealerModelGroupAnnualTargetRow.prototype.version = undefined;
DealerModelGroupAnnualTargetRow.prototype.modelGroup = undefined;
DealerModelGroupAnnualTargetRow.prototype.modelGroupName = undefined;
DealerModelGroupAnnualTargetRow.prototype.negotiable = undefined;

DealerModelGroupAnnualTargetRow.prototype.atDealerNumber = undefined;
DealerModelGroupAnnualTargetRow.prototype.atRecommendedNumber = undefined;
DealerModelGroupAnnualTargetRow.prototype.atApprovalNumber = undefined;
DealerModelGroupAnnualTargetRow.prototype.atApprovedNumber = undefined;

DealerModelGroupAnnualTargetRow.prototype.atStatisticsInvoicedCount = undefined;
DealerModelGroupAnnualTargetRow.prototype.atStatisticsProductionCount = undefined;
DealerModelGroupAnnualTargetRow.prototype.atStatisticsPlannedCount = undefined;
DealerModelGroupAnnualTargetRow.prototype.atStatisticsPotentialProductionCount = undefined;
DealerModelGroupAnnualTargetRow.prototype.atStatisticsPotentialPlannedCount = undefined;

DealerModelGroupAnnualTargetRow.prototype.privilegeFunctionalityContextObject = undefined;

export default DealerModelGroupAnnualTargetRow;
