import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import './TagSelect.scss';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.string.isRequired
  })),
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  width: PropTypes.string
};

const defaultProps = {
  className: null,
  disabled: false,
  items: null,
  label: null,
  multiple: false,
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  tooltip: null,
  value: '',
  width: null
};

const TagSelectComponent = (props, ref) => {

  return (
    <Forms.TagSelect
      availableTags={props.items}
      bgStyle='underline'
      className={['ovex-forms-tagSelect', props.className].join(' ')}
      colorSchema='green'
      disabled={props.disabled}
      inputColWidth='xs12'
      label={props.label}
      labelColWidth='xs12'
      multiple={props.multiple}
      name={props.name}
      onChange={props.onChange}
      parent={props.parent}
      placeholder=' '
      readOnly={props.readOnly}
      ref_={ref}
      required={props.required}
      style={{
        width: props.width
      }}
      tooltip={props.tooltip}
      value={props.value}
    />
  );
};

const TagSelect = React.forwardRef(TagSelectComponent);
TagSelect.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

TagSelectComponent.propTypes = propTypes;
TagSelectComponent.defaultProps = defaultProps;

export default TagSelect;