import { resolveCellClass } from '../ValidationResultPGItemTable.helpers';
import { RowType } from '../ValidationResultPGItemTable.const';
import { ConditionResultItemEnum, PGI_SPECIFICATION_TYPE_POSITIVE_LIST } from '../../../../../utils/const';
import { isGroupNode, nonGroupNode } from '../../../../../../common/components/ag-grid';

import AbstractColumnUIDefinition from './AbstractColumnUIDefinition';
import specificationTypeColumnMetadata from './specificationTypeColumnMetadata';

class AbstractSpecColumnUIDefinition extends AbstractColumnUIDefinition {

  static SPECIFICATION_TYPE_COLUMN_METADATA = specificationTypeColumnMetadata;

  /** @abstract */
  getSpecificationSnapshot = () => {
    throw new Error('Function not implemented!');
    // eslint-disable-next-line no-unreachable
    return null;
  }
  /** @abstract */
  getSpecificationType = () => {
    throw new Error('Function not implemented!');
    // eslint-disable-next-line no-unreachable
    return null;
  }
  /** @abstract */
  isSameSpecification = (snapshot, type) => {
    return snapshot === this.getSpecificationSnapshot() && type === this.getSpecificationType();
  }
  /**
   * @abstract
   * @param {CommissionSpecificationDTOVLD} commission
   */
  commissionConverterValueGetter = (commission) => {
    return null;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const specificationAdditionalParams = this.prepareSpecificationAdditionalParams(columnUIDefinition, lsi, additionalParams);
    const { columnTypeList, flex, minWidth, visible, headerComponentParams } = specificationAdditionalParams;
    const type = this.getSpecificationType();

    return {
      colId: this.getCode(),
      hide: !visible,
      colSpan: this._getColSpanValue,
      headerName: lsi.getLSIItem(`PRICE_GUARANTEE.TABLE_HEADER.SPECIFICATION_TYPE.${type}`),
      headerTooltip: lsi.getLSIItem(`PRICE_GUARANTEE.TABLE_HEADER.SPECIFICATION_TYPE.${type}`),
      headerComponent: 'customHeaderLabel',
      headerComponentParams: headerComponentParams,
      cellClass: resolveCellClass(this._evaluateConditionResultItemSpecification),
      valueGetter: this._specificationValueGetter,
      tooltipValueGetter: this._specificationValueGetter,
      type: [...columnTypeList],
      filter: 'agTextColumnFilter',
      flex: flex,
      minWidth: minWidth,
      suppressMovable: true,
      lockPinned: true
    };
  }
  /** @abstract */
  prepareSpecificationAdditionalParams = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList } = additionalParams;
    const { headerComponentParams, flex, minWidth } = AbstractSpecColumnUIDefinition.SPECIFICATION_TYPE_COLUMN_METADATA[this.getSpecificationType()];

    return {
      columnTypeList: columnTypeList,
      visible: visible,
      headerComponentParams: headerComponentParams,
      flex: flex,
      minWidth: minWidth
    };
  }

  _evaluateConditionResultItemSpecification = (cellClassParams) => {
    const cellClassList = [''];

    if (nonGroupNode(cellClassParams) && RowType.isPGItem(cellClassParams.data.rowType)) {
      const colId = cellClassParams.colDef.colId;
      const cellData = cellClassParams.data.specificationMap[colId];
      const result = cellData?.conditionResult;

      if (ConditionResultItemEnum.REPAIRABLE === result) {
        cellClassList.push('ovex-pg-ValidationResultPGItemTable-validationResult--repairable');
      } else if (ConditionResultItemEnum.NOT_REPAIRABLE === result) {
        cellClassList.push('ovex-pg-ValidationResultPGItemTable-validationResult--notRepairable');
      }
    }
    return cellClassList;
  }
  _specificationValueGetter = (valueGetterParams) => {
    if (isGroupNode(valueGetterParams)) {
      return null;
    }
    const colId = valueGetterParams.column.colId;
    const data = valueGetterParams.data;
    const cellData = data.specificationMap[colId];

    if (RowType.isCommission(data.rowType)) {
      return cellData;
    } else if (RowType.isPGItem(data.rowType)) {
      return cellData?.value;
    }
    return null;
  };
  _getColSpanValue = (params) => {
    if (nonGroupNode(params) && RowType.isCommission(params.data.rowType) && PGI_SPECIFICATION_TYPE_POSITIVE_LIST.includes(this.getSpecificationType())) {
      return 2;
    }
    return 1;
  }
}

export default AbstractSpecColumnUIDefinition;