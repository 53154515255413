import AbstractFunctionalityContext from '../../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../../FunctionalityCodes';

class SaveCommissionLoadParamsForDealerFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__SAVE_LOAD_PARAMS_DEALER);
  }

}

export default SaveCommissionLoadParamsForDealerFunctionalityContext;
