import { booleanAGGridFormatter, dateFormatter, simpleNullishValueFormatter } from '../../../../common/utils/formatters';

import { COLUMN_IDS } from './CommissionTable.columnIds';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  }
};

export const getColumnDefinitions = (lsi) => {
  return [
    {
      colId: COLUMN_IDS.COMMISSION,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.COMMISSION_NUMBER'),
      valueGetter: 'data && data.commission',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 10,
      minWidth: 104
    },
    {
      colId: COLUMN_IDS.COMMISSION_YEAR,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.COMMISSION_YEAR'),
      valueGetter: 'data && data.commissionYear',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 13,
      minWidth: 128
    },
    {
      colId: COLUMN_IDS.OWNER_DEALER_NUMBER,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.OWNER'),
      valueGetter: 'data && data.ownerDealerNumber',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 11,
      minWidth: 112
    },
    {
      colId: COLUMN_IDS.BRANCH_OWNER_DEALER_NUMBER,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECIPIENT'),
      valueGetter: 'data && data.branchOwnerDealerNumber',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 11,
      minWidth: 112
    },
    {
      colId: COLUMN_IDS.INVOICED_DEALER_NUMBER,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DEALER_INVOICED'),
      valueGetter: 'data && data.invoicedDealerNumber',
      valueFormatter: nullishValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 21,
      minWidth: 216
    },
    {
      colId: COLUMN_IDS.MODEL_GROUP,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_GROUP'),
      valueGetter: 'data && data.modelGroup',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 16,
      minWidth: 160
    },
    {
      colId: COLUMN_IDS.STATUS,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.STATUS'),
      valueGetter: 'data && data.status',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 9,
      minWidth: 88
    },
    {
      colId: COLUMN_IDS.PRE_PRODUCTION_WEEK,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRE_PRODUCTION_WEEK'),
      valueGetter: 'data && data.preProductionWeek',
      valueFormatter: nullishValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 9,
      minWidth: 96
    },
    {
      colId: COLUMN_IDS.PRODUCTION_WEEK,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION_WEEK'),
      valueGetter: 'data && data.productionWeek',
      valueFormatter: nullishValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 14,
      minWidth: 144
    },
    {
      colId: COLUMN_IDS.INVOICING_DATE,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.INVOICING_DATE'),
      valueGetter: 'data && data.invoicingDate',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      flex: 15,
      minWidth: 152
    },
    {
      colId: COLUMN_IDS.SELLING_DATE,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.SELLING_DATE'),
      valueGetter: 'data && data.sellingDate',
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agDateColumnFilter',
      flex: 15,
      minWidth: 152
    },
    {
      colId: COLUMN_IDS.AT_ALWAYS_Q4,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.AT_ALWAYS_Q4'),
      valueGetter: 'data && data.alwaysQ4',
      valueFormatter: booleanAGGridFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filterParams: {
        valueFormatter: booleanAGGridFormatter
      },
      flex: 15,
      minWidth: 152
    },
    {
      colId: COLUMN_IDS.STATUS_INTERVAL,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.STATUS_INTERVAL'),
      valueGetter: 'data && data.statusInterval',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 14,
      minWidth: 144,
      hide: true
    },
    {
      colId: COLUMN_IDS.AGGREGATION_TYPE,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.AGGREGATION_TYPE'),
      valueGetter: 'data && data.aggregationType',
      valueFormatter: (valueFormatterParams) => lsi.getLSIItem(`ANNUAL_TARGET.ENUM_LABELS.AGGREGATION_TYPE.${valueFormatterParams.value}`),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      flex: 14,
      minWidth: 144,
      hide: true
    }
  ];
};

const nullishValueFormatter = (valueFormatterParams) => {
  const { value } = valueFormatterParams;

  return simpleNullishValueFormatter(value, '-');
};

const dateValueFormatter = (valueFormatterParams) => {
  const { value, context } = valueFormatterParams;
  const formatters = context.agLsi.getFormatters();

  return simpleNullishValueFormatter(dateFormatter(value, formatters), '-');
};
