import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import './DateRangePicker.scss';

const DateRangePickerComponent = (props, ref) => {

  return (
    <Forms.DateRangePicker
      bgStyle='underline'
      className={['ovex-forms-date-range-picker', props.className].join(' ')}
      colorSchema='green'
      disabled={props.disabled}
      inputColWidth='xs12'
      label={props.label}
      labelColWidth='xs12'
      name={props.name}
      onChange={props.onChange}
      parent={props.parent}
      readOnly={props.readOnly}
      ref_={ref}
      required={props.required}
      tooltip={props.tooltip}
      value={props.value}
    />
  );
};

const DateRangePicker = React.forwardRef(DateRangePickerComponent);
DateRangePicker.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

DateRangePickerComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]))
};

DateRangePickerComponent.defaultProps = {
  className: null,
  disabled: false,
  label: null,
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  tooltip: null,
  value: null
};

export default DateRangePicker;