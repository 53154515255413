import { simpleNullishValueFormatter, percent2Formatter } from '../../common/utils/formatters';

import { sumNullableAccumulator } from './helpers';

/**
 * @param {number} invoiced
 * @param {number} production
 * @param {number} planned
 * @return {number|null}
 */
export const calculateDoneNumber = (invoiced, production, planned) => {
  if (invoiced == null && production == null && planned == null) {
    return null;
  }
  return invoiced + production + planned;
};

/**
 * @param {number} potentialProduction
 * @param {number} potentialPlanned
 * @return {number|null}
 */
export const calculatePotentialNumber = (potentialProduction, potentialPlanned) => {
  if (potentialProduction == null || potentialPlanned == null) {
    return null;
  }
  return potentialProduction + potentialPlanned;
};

/**
 * @param {number} approvedNumber
 * @param {number} doneNumber
 * @return {number|null}
 */
export const calculateDonePercentNumber = (approvedNumber, doneNumber) => {
  if (approvedNumber == null || doneNumber == null || approvedNumber === 0) {
    return null;
  }
  return doneNumber / approvedNumber;
};

/**
 * @param {number} approvedNumber
 * @param {number} doneNumber
 * @return {number|null}
 */
export const calculateRemainsNumber = (approvedNumber, doneNumber) => {
  if (approvedNumber == null || doneNumber == null) {
    return null;
  }
  return approvedNumber - doneNumber;
};

/**
 * @typedef DonePercentObject
 * @property {number} approvedNumber
 * @property {number} doneNumber
 * @property {function} toString
 */

/**
 * @param approvedNumber
 * @param doneNumber
 * @param {object} formatters
 * @return {DonePercentObject}
 */
const buildDonePercentObject = (approvedNumber, doneNumber, formatters) => {
  const decimalPercent = calculateDonePercentNumber(approvedNumber, doneNumber);
  return {
    approvedNumber: approvedNumber,
    doneNumber: doneNumber,
    toString: () => simpleNullishValueFormatter(percent2Formatter(decimalPercent, formatters), '-')
  };
};

/**
 * @param {object} valueGetterParams
 * @param {number} approvedNumber
 * @param {number} doneNumber
 * @return {null|DonePercentObject}
 */
export const handleDonePercentNumberValueGetter = (valueGetterParams, approvedNumber, doneNumber) => {
  if (valueGetterParams.node.group) {
    return null;
  }
  const formatters = valueGetterParams.context.agLsi.getFormatters();

  return buildDonePercentObject(approvedNumber, doneNumber, formatters);
};

/**
 * @param {Object} aggFuncParams
 * @return {DonePercentObject}
 */
export const handleDonePercentNumberAggFunc = (aggFuncParams) => {
  const result = aggFuncParams.values.reduce((acc, curValue) => {
    acc.approvedNumberSum = sumNullableAccumulator(acc.approvedNumberSum, curValue.approvedNumber);
    acc.doneNumberSum = sumNullableAccumulator(acc.doneNumberSum, curValue.doneNumber);
    return acc;
  }, { approvedNumberSum: null, doneNumberSum: null });
  const formatters = aggFuncParams.context.agLsi.getFormatters();

  return buildDonePercentObject(result.approvedNumberSum, result.doneNumberSum, formatters);
};
