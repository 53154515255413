/**
 * @param {Array} array
 * @return {boolean} *true* if array empty, otherwise *false*
 */
export const isEmptyArray = (array) => {
  return array == null || array.length === 0;
};
/**
 * Negation of {@see isEmptyArray} function
 * @param {Array} array
 * @return {boolean}
 */
export const isNonEmptyArray = (array) => !isEmptyArray(array);

/**
 * Function compare two arrays by content
 * @param {Array.<>} arr1
 * @param {Array.<>} arr2
 * @param {function} getElementValue - access to specified value for compare array
 * @return {boolean}
 */
export const arraysEquals = (arr1, arr2, getElementValue = undefined) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  let arr1Val, arr2Val;
  if (typeof getElementValue === 'function') {
    arr1Val = arr1.map(item => getElementValue(item));
    arr2Val = arr2.map(item => getElementValue(item));
  } else {
    arr1Val = [...arr1];
    arr2Val = [...arr2];
  }
  arr1Val.sort();
  arr2Val.sort();

  return arr1Val.every((item, idx) => item === arr2Val[idx]);
};

/**
 * Function returns an array consisting of the distinct elements.
 * @param {Array<Object>} array
 * @param {function} getElementValue - access to specified value for distinct array
 * @return {Array<Object>} distinct array
 */
export const distinctByValue = (array, getElementValue = undefined) => {
  if (Array.isArray(array)) {
    if (typeof getElementValue === 'function') {
      return array.filter((element, index) => index === array.findIndex(innerElement => getElementValue(innerElement) === getElementValue(element)));
    }
    return array.filter((element, index) => index === array.findIndex(innerElement => innerElement === element));
  }
  return array;
};

/**
 * Function returns an object where properties are group names and the value of each property contain group items.
 * @param {Array<Object>} array - list of items
 * @param {function} getGroupValue - access to group breakdown value
 * @return {Object} group object
 */
export const groupByValue = (array, getGroupValue = undefined) => {
  let result = {};
  if (Array.isArray(array)) {
    array.forEach(item => {
      const value = (typeof getGroupValue === 'function') ? getGroupValue(item) : item;
      if (/^[\w$]+/.test(value)) {
        if (result.hasOwnProperty(value)) {
          result[value].push(item);
        } else {
          result[value] = [item];
        }
      }
    });
  }
  return result;
};
