import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonBar } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';
import { CreateAnnualTargetYearFunctionalityContext, GetAnnualTargetYearsFunctionalityContext } from '../../../privilege/context';

const createAnnualTargetYearFunctionalityContext = new CreateAnnualTargetYearFunctionalityContext();
const cetAnnualTargetYearsFunctionalityContext = new GetAnnualTargetYearsFunctionalityContext();

class NegotiationsButtonBar extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    onReload: PropTypes.func.isRequired,
    onShowYearSettingsCreationModalForm: PropTypes.func
  };

  static defaultProps = {
    onShowYearSettingsCreationModalForm: undefined
  };

  _renderReadButtons = () => {
    const lsi = this.context;
    const { onShowYearSettingsCreationModalForm, onReload } = this.props;

    return (
      <React.Fragment>
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.ADD_YEAR_SETTINGS')}
          disabled={!onShowYearSettingsCreationModalForm}
          key='ovex-NegotiationsButtonBar-btn-addYearSettings'
          onClick={onShowYearSettingsCreationModalForm}
          privilegeFunctionalityContext={createAnnualTargetYearFunctionalityContext}
          tooltip={!onShowYearSettingsCreationModalForm ? lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.NONE_YEARS_FOR_YEAR_SETTINGS') : ''}
          type={Button.type.PRIMARY}
        />
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.RELOAD')}
          key='ovex-NegotiationsButtonBar-btn-reload'
          onClick={onReload}
          privilegeFunctionalityContext={cetAnnualTargetYearsFunctionalityContext}
          type={Button.type.SECONDARY}
        />
      </React.Fragment>
    );
  };

  render() {
    let buttons = this._renderReadButtons();

    return (
      <ButtonBar className='ovex-NegotiationsButtonBar'>
        {buttons}
      </ButtonBar>
    );
  }
}

export default NegotiationsButtonBar;
