
class PrivilegeCache {

  constructor() {
    this._cache = new Map();
    this._cachePending = new Map(); // Privilege objects waiting for evaluation from BE
  }

  /**
   * Add *functionality context* into the pending cache.
   *
   * @param {object} functionalityContext
   */
  addPendingFunctionalityContext = (functionalityContext) => {
    this._cachePending.set(functionalityContext.getUuid(), functionalityContext);
  };

  /**
   * Add evaluated *functionality context* with web result into the cache.
   *
   * @param {object} functionalityContext
   */
  addFunctionalityContextWithWebResult = (functionalityContext) => {
    this._cache.set(functionalityContext.getKey(), functionalityContext);
  };

  /**
   * Set result to functionality context in cache.
   *
   * @param {object} privilegedComponent - PrivilegedComponentDTO
   * @return *true* if result successfully added, otherwise *false*
   */
  setResultToFunctionalityContext = (privilegedComponent) => {
    const item = this._cachePending.get(privilegedComponent.componentId);

    if (item == null) {
      return false;
    }

    this._cachePending.delete(item.getUuid());
    item.setResult(privilegedComponent);
    this._cache.set(item.getKey(), item);

    return true;
  };

  /**
   * @param {object} functionalityContext
   */
  getPrivilegeResult = (functionalityContext) => {
    const item = this._cache.get(functionalityContext.getKey());

    if (item == null) {
      return null;
    }
    return item.hasWebResult() ? item.getWebResult() : item.getResult();
  };
}

export default PrivilegeCache;