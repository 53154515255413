import { PGI_COLUMN_IDS } from './PriceGuaranteeItemPublishedTable.columnIds';

const handleProcessCellCallback = (params) => {
  if ([
    PGI_COLUMN_IDS.PRICE_VALID_FROM
  ].includes(params.column.colId)) {
    return params.value?.toISOString();
  }
  return params.value;
};

export const exportExcel = {
  processCellCallback: handleProcessCellCallback,

  skipRowGroups: true
};
