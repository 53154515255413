import { combineReducers } from 'redux';

import { moduleReducerName } from '../../config/moduleConfig';

import commissionValidatorResult from './commission-validator/commission-validator-result';
import commissionValidatorPG from './commission-validator/commission-validator-price-guarantee';
import priceGuaranteeItems from './price-guarantee-items/price-guarantee-items';
import priceGuaranteeItemsPublished from './price-guarantee-items-published/price-guarantee-items-published';
import priceGuarantees from './price-guarantees/price-guarantees';
import priceGuaranteeReport from './price-guarantee-report/price-guarantee-report';
import priceGuaranteeReportExcel from './price-guarantee-report/price-guarantee-report-export';
import accountingBasis from './accounting-basis/accounting-basis';
import refreshStorageData from './recovery/refresh-storage-data';
//
import userInfo from './about/userInfo/userInfo';
//
import privilege from './privilege/privilege/privilege';
//
import initialization from './initialization/initialization';

const moduleReducers = {
  propName: moduleReducerName,
  combineReducers: combineReducers({
    commissionValidatorResult: commissionValidatorResult,
    commissionValidatorPG: commissionValidatorPG,
    priceGuaranteeItems: priceGuaranteeItems,
    pgiPublished: priceGuaranteeItemsPublished,
    priceGuarantees: priceGuarantees,
    priceGuaranteeReport: priceGuaranteeReport,
    priceGuaranteeReportExcel: priceGuaranteeReportExcel,
    accountingBasis: accountingBasis,

    refreshStorageData: refreshStorageData,

    userInfo: userInfo,

    privilege: privilege,

    initialization: initialization
  })
};

export default moduleReducers;
