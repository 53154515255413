import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import webModuleRegister from '../../../webModuleRegister';
import { Loading } from '../../../packages/common/components';
import InitializationStatus from '../../../packages/common/utils/InitializationStatus';

const propTypes = {};

const defaultProps = {};

const AppInitializer = () => {
  const initializationDataList = webModuleRegister.getInitializationDataList();
  const initStatusList = useSelector(state => initializationDataList.map(initData => initData.statusSelector(state)));

  const dispatch = useDispatch();
  const handleGetUserInfoCallback = React.useCallback(
    () => {
      /**
       * We use SAML, it must be orchestrated one by one !
       * Sequential processing of async functions: {@see https://advancedweb.hu/how-to-use-async-functions-with-array-foreach-in-javascript/}
       */
      webModuleRegister.getUserInfoDataList().reduce(
        async (memo, userInfoData) => {
          await memo;
          try {
            await dispatch(userInfoData.action());
          } catch (e) {
            return e;
          }
        },
        undefined
      ).then();
    },
    [dispatch]
  );

  React.useEffect(() => {
    handleGetUserInfoCallback();
  }, [handleGetUserInfoCallback]);

  return (
    <React.Fragment>
      <Loading loading />
      {
        initializationDataList.map((InitData, idx) => {
          if (initStatusList[idx] === InitializationStatus.COMPLETED_USER_INFO) {
            return (
              <InitData.component key={InitData.component.name} />
            );
          }
          return null;
        })
      }
    </React.Fragment>
  );
};

AppInitializer.propTypes = propTypes;
AppInitializer.defaultProps = defaultProps;

export default AppInitializer;