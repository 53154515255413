import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import { RowType } from '../../ValidationResultPGItemTable.const';
import { resolveCellClass } from '../../ValidationResultPGItemTable.helpers';
import { isGroupNode, nonGroupNode } from '../../../../../../../common/components/ag-grid';

class SalesAccountTypeCUID extends AbstractColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SALES_ACCOUNT_TYPE;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList } = additionalParams;

    return {
      colId: this.getCode(),
      rowGroup: true,
      hide: !visible,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.SALES_ACCOUNT_TYPE'),
      cellClass: resolveCellClass(''),
      valueGetter: valueGetterParams => {
        const data = valueGetterParams.data;
        if (nonGroupNode(valueGetterParams) && RowType.isPGItem(data.rowType)) {
          return data.salesAccountType;
        }
        return null;
      },
      valueFormatter: valueFormatterParams => {
        if (isGroupNode(valueFormatterParams) && valueFormatterParams.value != null) {
          return lsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.SALES_ACCOUNT_TYPE.${valueFormatterParams.value}`);
        }
        const data = valueFormatterParams.data;
        if (nonGroupNode(valueFormatterParams) && RowType.isPGItem(data.rowType)) {
          return lsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.SALES_ACCOUNT_TYPE.${valueFormatterParams.value}`);
        }
        return '';
      },
      filterParams: {
        valueFormatter: ({ value, context }) => {
          return value ? context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.SALES_ACCOUNT_TYPE.${value}`) : '';
        }
      },
      type: [...columnTypeList],
      width: 120,
      minWidth: 120
    };
  }
  /** @override */
  excelExportValueFormatter = (processCellForExportParams) => {
    return processCellForExportParams.column.colDef.valueFormatter({
      ...processCellForExportParams,
      data: processCellForExportParams.node.data
    });
  }
}

const salesAccountTypeCUID = new SalesAccountTypeCUID();

export default salesAccountTypeCUID;