import { PGISpecificationSnapshotEnum } from '../../../../../../utils/const';
import AbstractSpecificationSnapshotGroupColumn from '../AbstractSpecificationSnapshotGroupColumn';

class PreviousSpecificationSnapshotGroupColumn extends AbstractSpecificationSnapshotGroupColumn {

  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.PREVIOUS;
  }

}

const previousSpecificationSnapshotGroupColumn = new PreviousSpecificationSnapshotGroupColumn();

export default previousSpecificationSnapshotGroupColumn;