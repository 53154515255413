import React from 'react';
import PropTypes from 'prop-types';
import { PGUniverseInfoDTO } from '@ovex/price-guarantee-web-api';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

const propTypes = {
  pgUniverseInfo: PropTypes.instanceOf(PGUniverseInfoDTO).isRequired
};

const defaultProps = {};

const ValidationResultPGUniverseInfo = (props) => {

  const { pgUniverseInfo } = props;

  return (
    <Bricks.Well
      borderRadius='4px'
      className='ovex-pg-ValidationResultPGUniverseInfo'
      colorSchema='info'
      elevation='1'
      style={{
        padding: '10px',
        fontSize: '12px'
      }}
    >
      <Bricks.Icon
        icon='mdi-information-outline'
        style={{ paddingRight: '10px' }}
      />
      <b>{pgUniverseInfo.pgUniverseCode}</b> <i>{pgUniverseInfo.description}</i>
    </Bricks.Well>
  );
};

ValidationResultPGUniverseInfo.propTypes = propTypes;
ValidationResultPGUniverseInfo.defaultProps = defaultProps;

export default ValidationResultPGUniverseInfo;
