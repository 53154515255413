import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { OVEX3_ENVIRONMENT } from '../../utils/consts';

import './EnvironmentBar.scss';

const EnvironmentBar = () => {
  const environment = window.OVEX3_ENVIRONMENT;
  if (environment === OVEX3_ENVIRONMENT.PROD) {
    return;
  }
  const bgColor = window.OVEX3_ENVIRONMENT_BAR_COLOR;
  const environmentDesc = window.OVEX3_ENVIRONMENT_DESC;

  return (
    <Bricks.Div
      className='ovex-environment-bar'
    >
      <Bricks.Div
        className='ovex-environment-bar-content'
        style={{
          backgroundColor: bgColor,
          boxShadow: `0 0 2px 1px ${bgColor}`
        }}
      >
        <Bricks.Span
          style={{
            fontWeight: 'bold'
          }}
        >
          {environment}
        </Bricks.Span>
        <Bricks.Span>
          {` | ${environmentDesc}`}
        </Bricks.Span>
      </Bricks.Div>
    </Bricks.Div>
  );
};

export default EnvironmentBar;