import ColumnUIDefinitionMap from './columnUIDefinition/ColumnUIDefinitionMap';

const handleProcessCellCallback = (processCellForExportParams) => {
  const cuid = ColumnUIDefinitionMap[processCellForExportParams.column.colId];
  if (cuid != null) {
    return cuid.excelExportValueFormatter(processCellForExportParams);
  }
  return processCellForExportParams.value;
};

export const exportExcel = {
  processCellCallback: handleProcessCellCallback,

  skipRowGroups: true
};
