
const OrderTypeViewConst = {
  K_V_H: 'K / V / H',
  K_V: 'K / V',
  K: 'K',
  V: 'V'
};
Object.freeze(OrderTypeViewConst);

export default OrderTypeViewConst;
