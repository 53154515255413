import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class PUBGetPriceGuaranteeListPFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PUB__GET_PRICE_GUARANTEES);
  }

}

export default PUBGetPriceGuaranteeListPFC;
