import { RowType } from './ValidationResultPGItemTable.const';

export const prepareRowData = (cOrderHeaderAccountList, specSnapshotCUIDList) => {
  return cOrderHeaderAccountList.flatMap(coHeaderAccount => {
    const accountPartData = coHeaderAccount.accountPartData;
    return coHeaderAccount.pgItemList.map(pgi => {
      pgi.rowType = RowType.PG_ITEM;
      pgi.accountPartData = accountPartData;
      pgi.salesAccountType = accountPartData.accountType; // TODO PK - toto nahrazuje hodnotu na PGI co prisla z BE, asi by mělo byt odebrano
      pgi.specificationMap = convertSpecificationListToMap(pgi.specificationList, pgi.conditionResultList, specSnapshotCUIDList);
      return pgi;
    });
  });
};

const convertSpecificationListToMap = (specificationList, conditionResultList, specSnapshotCUIDList) => {
  return specificationList.reduce(
    (map, specification) => {
      const { snapshot, type, value } = specification;
      const cuid = specSnapshotCUIDList.find(i => i.isSameSpecification(snapshot, type));
      const conditionResult = conditionResultList.find(cr => cr.uiDefinition === cuid.getCode());

      map[cuid.getCode()] = {
        value: value,
        conditionResult: conditionResult.result
      };

      return map;
    },
    {}
  );
};