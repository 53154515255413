import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecCurrentPackageNegCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__CURRENT__PACKAGE_NEG;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.CURRENT;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.PACKAGE_NEG;
  }

}

const specCurrentPackageNegCUID = new SpecCurrentPackageNegCUID();

export default specCurrentPackageNegCUID;