import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const negotiationsApi = ApiServiceInstances.annualTarget.negotiationsApi;

export const CREATE_NEGOTIATIONS_CLOSURE_REQUEST = 'CREATE_NEGOTIATIONS_CLOSURE_REQUEST';
export const CREATE_NEGOTIATIONS_CLOSURE_SUCCESS = 'CREATE_NEGOTIATIONS_CLOSURE_SUCCESS';
export const CREATE_NEGOTIATIONS_CLOSURE_FAILURE = 'CREATE_NEGOTIATIONS_CLOSURE_FAILURE';

export const CANCEL_NEGOTIATIONS_CLOSURE_REQUEST = 'CANCEL_NEGOTIATIONS_CLOSURE_REQUEST';
export const CANCEL_NEGOTIATIONS_CLOSURE_SUCCESS = 'CANCEL_NEGOTIATIONS_CLOSURE_SUCCESS';
export const CANCEL_NEGOTIATIONS_CLOSURE_FAILURE = 'CANCEL_NEGOTIATIONS_CLOSURE_FAILURE';

const initialState = {
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEGOTIATIONS_CLOSURE_REQUEST:
    case CANCEL_NEGOTIATIONS_CLOSURE_REQUEST: {
      return { ...state, isFetching: true };
    }
    case CREATE_NEGOTIATIONS_CLOSURE_SUCCESS:
    case CANCEL_NEGOTIATIONS_CLOSURE_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case CREATE_NEGOTIATIONS_CLOSURE_FAILURE:
    case CANCEL_NEGOTIATIONS_CLOSURE_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const createNegotiationClosure = (atYearId) => {
  return {
    [CALL_API]: {
      apiFunction: () => negotiationsApi.createNegotiationClosureWithHttpInfo(atYearId),
      types: [CREATE_NEGOTIATIONS_CLOSURE_REQUEST, CREATE_NEGOTIATIONS_CLOSURE_SUCCESS, CREATE_NEGOTIATIONS_CLOSURE_FAILURE]
    }
  };
};

export const cancelNegotiationClosure = (negotiationId) => {
  return {
    [CALL_API]: {
      apiFunction: () => negotiationsApi.cancelNegotiationClosureWithHttpInfo(negotiationId),
      types: [CANCEL_NEGOTIATIONS_CLOSURE_REQUEST, CANCEL_NEGOTIATIONS_CLOSURE_SUCCESS, CANCEL_NEGOTIATIONS_CLOSURE_FAILURE]
    }
  };
};