import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import {
  ContractATApprovalWrapperUpdateDTO,
  ContractATDealerWrapperUpdateDTO,
  ContractDetailDTO
} from '@ovex/annual-target-web-api';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';

import { Select } from '../../../common/components/forms';
import { Page } from '../../../common/components';
import { LsiContext, NewTabContext } from '../../../common/contexts';
import { dateTimeSecondsFormatter } from '../../../common/utils/formatters';
import { useAlertBusOvex, useConfirmModal, usePrivilege } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';
import generatePath from '../ContractPreview/generatePath';
import useShowCommissionListWithParamsForDealer from '../CommissionList/hooks/useShowCommissionListWithParamsForDealer';

import ContractSelectItem from './utils/ContractSelectItem';
import DealerAnnualTargetButtonBar from './DealerAnnualTargetButtonBar/DealerAnnualTargetButtonBar';
import DealerModelGroupAnnualTargetTable from './DealerModelGroupAnnualTargetTable/DealerModelGroupAnnualTargetTable';
import DealerPeriodAnnualTargetSalesTable
  from './DealerPeriodAnnualTargetSalesTable/DealerPeriodAnnualTargetSalesTable';

import './DealerAnnualTarget.scss';
import { filterErrorContractAtApprovalInsufficient } from './utils/helpers';

const propTypes = {
  dealerContractList: PropTypes.arrayOf(PropTypes.instanceOf(ContractDetailDTO)),
  error: PropTypes.object,
  fetching: PropTypes.bool,
  onApproveByDirector: PropTypes.func.isRequired,
  onGetDealerContract: PropTypes.func.isRequired,
  onUpdateContractAnnualTargetApproval: PropTypes.func.isRequired,
  onUpdateContractAnnualTargetDealer: PropTypes.func.isRequired
};

const defaultProps = {
  dealerContractList: null,
  error: null,
  fetching: true
};

const DealerAnnualTarget = (props) => {
  const lsi = React.useContext(LsiContext);
  const openInNewTab = React.useContext(NewTabContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const handleReloadDealerContract = props.onGetDealerContract;
  React.useEffect(
    () => {
      handleReloadDealerContract();
    },
    [handleReloadDealerContract]
  );

  const contractSelectItemList = React.useMemo(() => {
    return ContractSelectItem.constructFromContractList(props.dealerContractList, lsi);
  }, [props.dealerContractList, lsi]);

  const contractSelected = React.useRef(null);
  const [ contractSelectItemSelected, setContractSelectItemSelected ] = React.useState(null);
  React.useEffect(
    () => {
      let item;
      if (contractSelected.current != null) {
        item = contractSelectItemList.find(i => i.value === contractSelected.current);
      }
      if (item == null) {
        item = contractSelectItemList[0];
      }
      setContractSelectItemSelected(item);
    },
    [contractSelectItemList]
  );
  const handleSetSelectedItem = React.useCallback(
    (opt) => {
      const contractSelectItem = contractSelectItemList.find(item => item.value === opt.value);

      contractSelected.current = contractSelectItem.value;
      setContractSelectItemSelected(contractSelectItem);
    },
    [contractSelectItemList]
  );

  const { handlePrivilegeRequest, handleIsPrivileged } = usePrivilege();

  const updateDealerFnCtxList = React.useRef([]);
  const updateApprovalFnCtxList = React.useRef([]);
  React.useEffect(
    () => {
      if (contractSelectItemSelected != null) {
        updateDealerFnCtxList.current = [];
        updateApprovalFnCtxList.current = [];

        contractSelectItemSelected.modelGroupAnnualTargetList
          .filter(mg => mg.isTotalRow())
          .forEach(mg => {
            updateDealerFnCtxList.current.push(mg.privilegeFunctionalityContextObject.updateDealerFnCtx);
            updateApprovalFnCtxList.current.push(mg.privilegeFunctionalityContextObject.updateApprovalFnCtx);
          });
        contractSelectItemSelected.periodAnnualTargetSalesList
          .filter(p => p.isPeriodRow())
          .forEach(p => {
            updateDealerFnCtxList.current.push(p.privilegeFunctionalityContextObject.updateDealerFnCtx);
            updateApprovalFnCtxList.current.push(p.privilegeFunctionalityContextObject.updateApprovalFnCtx);
          });

        const fnCtxForContract = [
          contractSelectItemSelected.privilegeFunctionalityContextObject.getDealerContractPreviewFnCtx,
          contractSelectItemSelected.privilegeFunctionalityContextObject.shiftDealerContractWorkflowFnCtx,
          ...updateDealerFnCtxList.current,
          ...updateApprovalFnCtxList.current
        ];

        handlePrivilegeRequest(fnCtxForContract);
      }
    },
    [contractSelectItemSelected, handlePrivilegeRequest]
  );

  const [editableApprovalNumber, setEditableApprovalNumber] = React.useState(false);
  const [editableDealerNumber, setEditableDealerNumber] = React.useState(false);

  const onApproveByDirector = props.onApproveByDirector;
  const actionCallbacks = React.useMemo(() => {
    const callbacks = {
      handleEnableEditApprovalNumber: undefined,
      handleEnableEditDealerNumber: undefined,
      handleSaveApprovalNumberChanges: undefined,
      handleSaveDealerNumberChanges: undefined,
      handleApproveByDirector: undefined,
      handleShowContractPreview: undefined
    };

    if (contractSelectItemSelected == null) {
      return callbacks;
    }

    if (handleIsPrivileged(contractSelectItemSelected.privilegeFunctionalityContextObject.shiftDealerContractWorkflowFnCtx)) {
      callbacks.handleApproveByDirector = async (validate) => {
        try {
          await onApproveByDirector(contractSelectItemSelected.contract.id, validate);
          handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.SHIFT_CONTRACT_WORKFLOW_DIRECTOR_APPROVE_SUCCESS');
          handleReloadDealerContract();
        } catch (e) {
          const errorList = filterErrorContractAtApprovalInsufficient(e.error);
          if (errorList.length === 0) {
            handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.SHIFT_CONTRACT_WORKFLOW_DIRECTOR_APPROVE_FAILURE', AlertTypeEnum.WARNING);
          }
          setDealerModelGroupModalFormProps({
            ...dealerModelGroupModalFormProps,
            errorList: errorList, shown: errorList.length > 0, onSave: callbacks.handleApproveByDirector
          });
        }
      };
    }
    if (handleIsPrivileged(contractSelectItemSelected.privilegeFunctionalityContextObject.getDealerContractPreviewFnCtx)) {
      callbacks.handleShowContractPreview = () => openInNewTab(generatePath(contractSelectItemSelected.contract.id));
    }

    const isEditableDealerNumber = !!updateDealerFnCtxList.current && updateDealerFnCtxList.current.some(fnCtx => handleIsPrivileged(fnCtx));
    const isEditableApprovalNumber = !!updateApprovalFnCtxList.current && updateApprovalFnCtxList.current.some(fnCtx => handleIsPrivileged(fnCtx));

    if (isEditableDealerNumber) {
      callbacks.handleEnableEditDealerNumber = () => setEditableDealerNumber(true);
    }
    if (isEditableApprovalNumber) {
      callbacks.handleEnableEditApprovalNumber = () => setEditableApprovalNumber(true);
    }

    return callbacks;
  }, [contractSelectItemSelected, handleIsPrivileged, handleAddAlertSimple, onApproveByDirector, handleReloadDealerContract, openInNewTab]);

  const modelGroupTableRef = React.useRef(null);
  const periodTableRef = React.useRef(null);

  const handleCancelEdit = React.useCallback(() => {
    const isModified = contractSelectItemSelected.modelGroupAnnualTargetList.some(mg => mg.isModified())
      || contractSelectItemSelected.periodAnnualTargetSalesList.some(p => p.isModified());

    if (isModified) {
      modelGroupTableRef.current.discardChanges();
      periodTableRef.current.discardChanges();
    }

    setEditableApprovalNumber(false);
    setEditableDealerNumber(false);
  }, [contractSelectItemSelected]);

  const handleCloseModalForm = () => {
    setDealerModelGroupModalFormProps({
      ...dealerModelGroupModalFormProps,
      shown: false,
      errorList: null,
      onSave: null
    });
  };

  const [dealerModelGroupModalFormProps, setDealerModelGroupModalFormProps] = useState({
    shown: false,
    errorList: null,
    onSave: null,
    onClose: handleCloseModalForm
  });

  const onUpdateContractAnnualTargetDealer = props.onUpdateContractAnnualTargetDealer;
  const handleSaveDealerNumberChanges = React.useCallback(
    async (validate) => {
      const modelGroupAnnualTargetDealerChanges = contractSelectItemSelected.modelGroupAnnualTargetList
        .filter(mg => mg.isATDealerNumberModified())
        .map(mg => mg.getATDealerNumberModification());

      const periodAnnualTargetSalesDealerChanges = contractSelectItemSelected.periodAnnualTargetSalesList
        .filter(p => p.isATSDealerNumberModified())
        .map(p => p.getATSDealerNumberModification());

      if (modelGroupAnnualTargetDealerChanges.length === 0 && periodAnnualTargetSalesDealerChanges.length === 0) {
        return;
      }

      try {
        const updateContractAnnualTargetDealerResponse = onUpdateContractAnnualTargetDealer(ContractATDealerWrapperUpdateDTO.constructFromObject({
          atList: modelGroupAnnualTargetDealerChanges,
          atsList: periodAnnualTargetSalesDealerChanges,
          validate: validate
        }));
        await updateContractAnnualTargetDealerResponse;

        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_DEALER_CONTRACT_DEALER_NUMBER_SUCCESS');
        handleReloadDealerContract();
        setEditableDealerNumber(false);
        return Promise.resolve();
      } catch (e) {
        const errorList = filterErrorContractAtApprovalInsufficient(e.error);
        if (errorList.length === 0) {
          handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_DEALER_CONTRACT_DEALER_NUMBER_FAILURE', AlertTypeEnum.WARNING);
        }
        setDealerModelGroupModalFormProps({
          ...dealerModelGroupModalFormProps,
          errorList: errorList, shown: errorList.length > 0, onSave: handleSaveDealerNumberChanges
        });
        return Promise.reject();
      }
    },
    [contractSelectItemSelected, handleReloadDealerContract, handleAddAlertSimple, onUpdateContractAnnualTargetDealer]
  );

  const onUpdateContractAnnualTargetApproval = props.onUpdateContractAnnualTargetApproval;
  const handleSaveApprovalNumberChanges = React.useCallback(
    async (validate) => {
      const modelGroupAnnualTargetApprovalChanges = contractSelectItemSelected.modelGroupAnnualTargetList
        .filter(mg => mg.isATApprovalNumberModified())
        .map(mg => mg.getATApprovalNumberModification());

      const periodAnnualTargetSalesApprovalChanges = contractSelectItemSelected.periodAnnualTargetSalesList
        .filter(p => p.isATSApprovalNumberModified())
        .map(p => p.getATSApprovalNumberModification());

      if (modelGroupAnnualTargetApprovalChanges.length === 0 && periodAnnualTargetSalesApprovalChanges.length === 0) {
        return;
      }

      try {
        const data = ContractATApprovalWrapperUpdateDTO.constructFromObject({
          atList: modelGroupAnnualTargetApprovalChanges,
          atsList: periodAnnualTargetSalesApprovalChanges,
          validate: validate
        });
        const updateContractAnnualTargetApprovalResponse = onUpdateContractAnnualTargetApproval(data);
        await updateContractAnnualTargetApprovalResponse;

        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_DEALER_CONTRACT_APPROVAL_NUMBER_SUCCESS');
        handleReloadDealerContract();
        setEditableApprovalNumber(false);
        return Promise.resolve();
      } catch (e) {
        const errorList = filterErrorContractAtApprovalInsufficient(e.error);
        if (errorList.length === 0) {
          handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_DEALER_CONTRACT_APPROVAL_NUMBER_FAILURE', AlertTypeEnum.WARNING);
        }
        setDealerModelGroupModalFormProps({
          ...dealerModelGroupModalFormProps,
          errorList: errorList, shown: errorList.length > 0, onSave: handleSaveApprovalNumberChanges
        });
        return Promise.reject();
      }
    },
    [contractSelectItemSelected, handleReloadDealerContract, handleAddAlertSimple, onUpdateContractAnnualTargetApproval]
  );

  const [ renderConfirmApproveModal, handleShowConfirmApprove ] = useConfirmModal(actionCallbacks.handleApproveByDirector, {
    content: lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.CONTRACT_APPROVE')
  });

  const handleShowCommissionListWithParams = useShowCommissionListWithParamsForDealer();

  let pageContent;
  if (props.error) {
    pageContent = (
      <Bricks.Well
        colorSchema='warning'
        content={lsi.getLSIItem('ANNUAL_TARGET.ERROR_MESSAGE.DEALER_NUMBER_CONTENT_NOT_FOUNT')}
        style={{ margin: '0 30%', textAlign: 'center' }}
      />
    );
  } else if (props.dealerContractList) {
    let handleSaveChanges;
    if (editableDealerNumber) {
      handleSaveChanges = handleSaveDealerNumberChanges;
    } else if (editableApprovalNumber) {
      handleSaveChanges = handleSaveApprovalNumberChanges;
    }
    const handleSaveChangesCloseModalForm = (validate) => {
      dealerModelGroupModalFormProps.onSave(validate);
      handleCloseModalForm();
    };

    const renderContractStatisticsTimestamp = () => {
      if (contractSelectItemSelected == null || contractSelectItemSelected.contract.statisticsTimestamp == null) {
        return;
      }
      const statisticsTimestamp = contractSelectItemSelected.contract.statisticsTimestamp;
      const formatters = lsi.getFormatters();
      return (
        <Bricks.Column width={400}>
          <Bricks.Div
            className='ovex-DealerAnnualTarget-statistic-info'
          >
            <Icon
              className='ovex-DealerAnnualTarget-statistic-info-icon'
              path={mdiInformationOutline}
              size={0.8}
            />
            {lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.STATS_VALID_TO', dateTimeSecondsFormatter(statisticsTimestamp, formatters))}
          </Bricks.Div>
        </Bricks.Column>
      );
    };

    const dealerModelGroupATTableOptions = {
      alignedGrids: []
    };
    const dealerPeriodATTableOptions = {
      alignedGrids: []
    };

    dealerModelGroupATTableOptions.alignedGrids.push(dealerPeriodATTableOptions);
    dealerPeriodATTableOptions.alignedGrids.push(dealerModelGroupATTableOptions);

    pageContent = (
      <React.Fragment>
        <Bricks.Row key='ovex-DealerAnnualTarget-Select-contract'>
          <Bricks.Column width={400}>
            <Select
              className='ovex-DealerAnnualTarget-select-contract'
              disabled={editableApprovalNumber || editableDealerNumber}
              items={contractSelectItemList}
              name='contractValue'
              onChange={handleSetSelectedItem}
              value={contractSelectItemSelected && contractSelectItemSelected.value}
            />
          </Bricks.Column>
          {renderContractStatisticsTimestamp()}
        </Bricks.Row>
        <DealerAnnualTargetButtonBar
          contractSelectItemSelected={contractSelectItemSelected}
          key='ovex-DealerAnnualTarget-DealerAnnualTargetButtonBar'
          onApprove={handleShowConfirmApprove}
          onCancelEdit={handleCancelEdit}
          onEnableEditApprovalNumber={actionCallbacks.handleEnableEditApprovalNumber}
          onEnableEditDealerNumber={actionCallbacks.handleEnableEditDealerNumber}
          onReload={handleReloadDealerContract}
          onSave={handleSaveChanges}
          onShowContractPreview={actionCallbacks.handleShowContractPreview}
        />
        <DealerModelGroupAnnualTargetTable
          className='ovex-DealerAnnualTarget-table'
          contract={contractSelectItemSelected && contractSelectItemSelected.contract}
          editableApprovalNumberColumn={editableApprovalNumber}
          editableDealerNumberColumn={editableDealerNumber}
          gridOptions={dealerModelGroupATTableOptions}
          key='ovex-DealerAnnualTarget-DealerModelGroupAnnualTargetTable'
          modalProps={{ ...dealerModelGroupModalFormProps, onSave: handleSaveChangesCloseModalForm }}
          modelGroupAnnualTargetList={contractSelectItemSelected && contractSelectItemSelected.modelGroupAnnualTargetList}
          onShowCommissionListWithParams={handleShowCommissionListWithParams}
          ref={modelGroupTableRef}
        />
        <DealerPeriodAnnualTargetSalesTable
          className='ovex-DealerAnnualTarget-table'
          contract={contractSelectItemSelected && contractSelectItemSelected.contract}
          editableApprovalNumberColumn={editableApprovalNumber}
          editableDealerNumberColumn={editableDealerNumber}
          gridOptions={dealerPeriodATTableOptions}
          key='ovex-DealerAnnualTarget-DealerPeriodAnnualTargetSalesTable'
          onShowCommissionListWithParams={handleShowCommissionListWithParams}
          periodAnnualTargetSalesList={contractSelectItemSelected && contractSelectItemSelected.periodAnnualTargetSalesList}
          ref={periodTableRef}
        />
        {renderConfirmApproveModal()}
      </React.Fragment>
    );
  } else {
    pageContent = '';
  }

  return (
    <Page
      className='ovex-DealerAnnualTarget'
      header={lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.ANNUAL_TARGET')}
      loading={props.fetching}
    >
      {pageContent}
    </Page>
  );
};

DealerAnnualTarget.propTypes = propTypes;
DealerAnnualTarget.defaultProps = defaultProps;

export default DealerAnnualTarget;
