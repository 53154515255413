import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

import evaluateWebResultRecommendedFnCtx from './helpers/evaluateWebResultRecommendedFnCtx';

class UpdateContractAnnualTargetSalesRecommendedFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} contractAtSalesPeriodId
   * @param {object} webParams
   */
  constructor(contractAtSalesPeriodId, webParams = null) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__UPDATE_CONTRACT_ATS_RECOMMENDED);
    this.setParams({
      [ContextConstants.CONTRACT_AT_SALES_PERIOD_ID]: contractAtSalesPeriodId
    });

    this.setWebParams(webParams);
  }

  /**
   * @override
   */
  evaluateWebResult = () => {
    this._webResult = evaluateWebResultRecommendedFnCtx(this._webParams);
  };

}

export default UpdateContractAnnualTargetSalesRecommendedFunctionalityContext;