import {ConditionResultItemEnum} from '../../../../utils/const';
import {dateFormatter, number2Formatter} from '../../../../../common/utils/formatters';
import {nonGroupNode} from '../../../../../common/components/ag-grid';

import {RowType} from './ValidationResultPGItemTable.const';

/**
 * @param {Array.<COrderHeaderAccountWithPGItemsDTOVLD>} cOrderHeaderAccountList
 */
export const calculateSumAmounts = (cOrderHeaderAccountList) => {
  return cOrderHeaderAccountList.reduce(
    (accumulator, coHeaderAccount) => {
      accumulator.claimAmountSum += coHeaderAccount.accountPartData.amount;
      accumulator.totalAmountSum += coHeaderAccount.pgItemList.reduce((acc, pgItem) => acc + pgItem.totalAmount, 0);
      return accumulator;
    },
    { claimAmountSum: 0, totalAmountSum: 0 }
  );
};

export const evaluateConditionResultItem = (rowType, result) => {
  const cellClassList = ['ovex-ag-grid--td-right'];

  if (RowType.isPGItem(rowType)) {
    if (ConditionResultItemEnum.REPAIRABLE === result) {
      cellClassList.push('ovex-pg-ValidationResultPGItemTable-validationResult--repairable');
    } else if (ConditionResultItemEnum.NOT_REPAIRABLE === result) {
      cellClassList.push('ovex-pg-ValidationResultPGItemTable-validationResult--notRepairable');
    }
  }

  return cellClassList;
};

/**
 * @param {string|string[]|function|null} defaultCellClass
 * @return {function(*=): string|*[]}
 */
export const resolveCellClass = (defaultCellClass) => {
  let cellClassList;
  let cellClassFunction;
  if (defaultCellClass != null) {
    if (typeof defaultCellClass === 'function' || defaultCellClass instanceof Function) {
      cellClassFunction = defaultCellClass;
    } else {
      cellClassList = resolveCellClassList(defaultCellClass);
    }
  }

  return (cellClassParams) => {
    const externalCellClassList = cellClassList || resolveCellClassList(cellClassFunction(cellClassParams));
    const commissionCellClass = getCommissionCellClass(cellClassParams);

    let result;
    if (Array.isArray(externalCellClassList)) {
      result = [...externalCellClassList, commissionCellClass];
    } else {
      result = commissionCellClass;
    }

    return result;
  };
};

/**
 * @param {string|string[]} defaultCellClass
 * @return {string|string[]|null}
 */
const resolveCellClassList = (defaultCellClass) => {
  if (typeof defaultCellClass === 'string' || defaultCellClass instanceof String) {
    return [defaultCellClass];
  }
  if (Array.isArray(defaultCellClass)) {
    return defaultCellClass;
  }
  return null;
};

/**
 * @param {Object} cellClassParams
 * @return {string|null}
 */
const getCommissionCellClass = (cellClassParams) => {
  const data = cellClassParams.data;
  if (nonGroupNode(cellClassParams) && RowType.isCommission(data.rowType)) {
    return 'ovex-pg-ValidationResultPGItemTable-cell--commission';
  }
  return null;
};

export const amountValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  const value = valueFormatterParams.value;
  return value == '-' ? '-' : number2Formatter(value, formatters);
};

export const dateValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  return dateFormatter(valueFormatterParams.value, formatters);
};

export const packageListFormatter = (packageList) => packageList?.join(' ');

