import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../../packages/common/components';
import { LsiContext, UserInfoContext } from '../../../packages/common/contexts';

const propTypes = {
  onClose: PropTypes.func.isRequired
};
const defaultProps = {};

const UserInfoModal = (props) => {
  const lsi = React.useContext(LsiContext);
  const userInfo = React.useContext(UserInfoContext);

  return (
    <Modal
      className='ovex-UserInfoModal'
      header={lsi.getLSIItem('MAIN.PAGE_TITLE.USER_INFO')}
      onClose={props.onClose}
      shown
      size='m'
      stickyBackground={false}
    >
      <div style={{ marginBottom: '8px' }}>
        <strong>{lsi.getLSIItem('MAIN.LABELS.DISPLAY_NAME')}</strong>
        <span style={{ marginLeft: '16px' }}>{userInfo.displayName}</span>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <strong>{lsi.getLSIItem('MAIN.LABELS.LOGIN')}</strong>
        <span style={{ marginLeft: '16px' }}>{userInfo.login}</span>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <strong>{lsi.getLSIItem('MAIN.LABELS.LANGUAGE')}</strong>
        <span style={{ marginLeft: '16px' }}>{userInfo.language}</span>
      </div>
      <div>
        <strong>{lsi.getLSIItem('MAIN.LABELS.ROLE_LIST')}</strong>
        <ul>
          {userInfo.roleList.map(role => (<li key={role}>{role}</li>))}
        </ul>
      </div>
    </Modal>
  );
};

UserInfoModal.propTypes = propTypes;
UserInfoModal.defaultProps = defaultProps;

export default UserInfoModal;