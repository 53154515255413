import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class SCPSwapCommissionsPvtImporterFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PVT_SCP__SWAP_COMMISSIONS_SWAP_IMPORTER);
  }

}

export default SCPSwapCommissionsPvtImporterFunctionalityContext;