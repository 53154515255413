import PropTypes from 'prop-types';

const FunctionalityContextShape = PropTypes.shape({
  _functionalityCode: PropTypes.string,
  _params: PropTypes.object
});

const PrivilegeFunctionalityContextPropType = {
  propName: 'privilegeFunctionalityContext',
  propType: PropTypes.oneOfType([
    FunctionalityContextShape,
    PropTypes.arrayOf(FunctionalityContextShape)
  ]),
  defaultProp: null
};

export default PrivilegeFunctionalityContextPropType;