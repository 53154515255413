/**
 * Codes from 'vwg.skoda.ovex.support.privilege.common.Functionality'
 */
const FunctionalityCodes = {
  SUPP_USER_CREDENTIALS__GET_USER_INFO_CURRENT: 'SUPP_USER_CREDENTIALS__GET_USER_INFO_CURRENT',
  SUPP_USER_CREDENTIALS__GET_USER_INFO_WITH_ROLE_EVALUATION: 'SUPP_USER_CREDENTIALS__GET_USER_INFO_WITH_ROLE_EVALUATION',
  SUPP_USER_CREDENTIALS__GET_IMITATE_USER_CREDENTIALS: 'SUPP_USER_CREDENTIALS__GET_IMITATE_USER_CREDENTIALS',
  SUPP_USER_CREDENTIALS__IMITATE_USER_CREDENTIALS: 'SUPP_USER_CREDENTIALS__IMITATE_USER_CREDENTIALS',

  SUPP_USER_CREDENTIALS__GET_AVAILABLE_BID_LIST: 'SUPP_USER_CREDENTIALS__GET_AVAILABLE_BID_LIST',
  SUPP_USER_CREDENTIALS__CHANGE_USER_BID: 'SUPP_USER_CREDENTIALS__CHANGE_USER_BID',

  SUPP_USER_CREDENTIALS__REVERT_USER_CHANGES: 'SUPP_USER_CREDENTIALS__REVERT_USER_CHANGES'
};

export default FunctionalityCodes;
