
export const ADD_ALERT = 'ADD_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const CLEAR_ALL_ALERT = 'CLEAR_ALL_ALERT';

const initialState = {
  alertList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT: {
      if (!action.alert) {
        return state;
      }
      return {
        ...state,
        alertList: [...state.alertList, action.alert]
      };
    }
    case CLEAR_ALERT: {
      action.alertUuidList && action.alertUuidList.forEach(alertUuid => {
        const index = state.alertList.findIndex(alert => alert.uuid === alertUuid);
        if (-1 < index) {
          state.alertList.splice(index, 1);
        }
      });
      /**
       * It is not necessary to change reference of alert list.
       * The **Alert** component should be a single consumer.
       */
      return state;
    }
    case CLEAR_ALL_ALERT: {
      return {
        ...state,
        alertList: []
      };
    }
    default:
      return state;
  }
};

/**
 * @param {module:ovex/main/Alert} alert
 * @return {{alert: *, type: string}}
 */
export const handleAddAlert = (alert) => {
  return {
    alert: alert,
    type: ADD_ALERT
  };
};

/**
 * @param {string} alertUuidList
 * @return {{alertUuidList: *, type: string}}
 */
export const handleClearAlerts = (alertUuidList) => {
  return {
    alertUuidList: alertUuidList,
    type: CLEAR_ALERT
  };
};

export const handleClearAllAlert = () => {
  return {
    type: CLEAR_ALL_ALERT
  };
};
