import { calculateRemainsNumber } from '../../../utils/statisticsHelpers';
import { UpdateContractAnnualTargetSalesApprovalFunctionalityContext, UpdateContractAnnualTargetSalesDealerFunctionalityContext } from '../../../privilege/context';
import { AG_GROUP_TOTAL } from '../utils/const';

import DealerPeriodAnnualTargetSalesRow from './DealerPeriodAnnualTargetSalesRow';

const DealerPeriodAnnualTargetSalesConverter = {

  /**
   * @param {Array.<module:model/BranchDTO>} branchList
   * @return {Array.<DealerPeriodAnnualTargetSalesRow>}
   */
  constructFromBranchList: (branchList) => {
    if (Array.isArray(branchList) && branchList.length > 0) {
      const returnedFlatMap = branchList.flatMap(branch => branch.periodAnnualTargetSalesList.map(period => constructFromObject(branch, period)));
      const periodOrderList = [...new Set(returnedFlatMap.map(period => period.periodOrder))];
      const virtualRows = periodOrderList.map(period => constructFromPeriod(period));
      return [...virtualRows, ...returnedFlatMap];
    }
    return [];
  }
};

/**
 * @param {module:model/BranchDTO} branch
 * @param {Object|model/PeriodAnnualTargetSalesDTO} data
 * @return {DealerPeriodAnnualTargetSalesRow} The populated <code>DealerPeriodAnnualTargetSalesRow</code> instance.
 */
const constructFromObject = (branch, data) => {
  if (data) {

    const branchNumber = branch.hasOwnProperty('dealerDetail') ? branch.dealerDetail.dealerNumber : null;
    const row = new DealerPeriodAnnualTargetSalesRow(branchNumber);

    row.id = data.hasOwnProperty('id') ? data.id : null;
    row.version = data.hasOwnProperty('version') ? data.version : null;
    row.branchDealerNumber = branchNumber;
    row.periodOrder = data.hasOwnProperty('periodOrder') ? data.periodOrder : null;
    row.negotiable = data.hasOwnProperty('negotiable') ? data.negotiable : null;

    row.atsDealerNumber = data.hasOwnProperty('atsDealerNumber') ? data.atsDealerNumber : null;
    row.atsRecommendedNumber = data.hasOwnProperty('atsRecommendedNumber') ? data.atsRecommendedNumber : null;
    row.atsApprovalNumber = data.hasOwnProperty('atsApprovalNumber') ? data.atsApprovalNumber : null;
    row.atsApprovedNumber = data.hasOwnProperty('atsApprovedNumber') ? data.atsApprovedNumber : null;
    row.atsStatisticsSoldCount = data.hasOwnProperty('atsStatisticsSoldCount') ? data.atsStatisticsSoldCount : null;

    row._atsDealerNumberOriginal = row.atsDealerNumber;
    row._atsApprovalNumberOriginal = row.atsApprovalNumber;
    row.atsStatisticsRemainsCount = calculateRemainsNumber(row.atsApprovedNumber, row.atsStatisticsSoldCount);

    row.privilegeFunctionalityContextObject = {
      updateApprovalFnCtx: new UpdateContractAnnualTargetSalesApprovalFunctionalityContext(row.id),
      updateDealerFnCtx: new UpdateContractAnnualTargetSalesDealerFunctionalityContext(row.id)
    };

    return row;
  }
  return null;
};

const constructFromPeriod = (period) => {
  if (period) {
    const row = new DealerPeriodAnnualTargetSalesRow(AG_GROUP_TOTAL);
    row.periodOrder = period ? period : null;

    row.privilegeFunctionalityContextObject = {};

    return row;
  }
  return null;
};

export default DealerPeriodAnnualTargetSalesConverter;
