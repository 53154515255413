import React from 'react';
import { useDispatch } from 'react-redux';

import { usePrivilege } from '../../../common/hooks';
import moduleContextData from '../../config/moduleContextData';
import {
  GetPriceGuaranteeListPFC,
  ITMGetPriceGuaranteeListPFC,
  PUBGetPriceGuaranteeListPFC,
  VLDGetPriceGuaranteeListPFC, VLDGetCommissionValidationResultDealerPFC, VLDGetCommissionValidationResultImporterPFC,
  PGRGetPriceGuaranteeListPFC, PGRGetPriceGuaranteeReportDealerPFC, PGRGetPriceGuaranteeReportImporterPFC, RefreshStorageDataPFC
} from '../../privilege/context';
import { handlePrivilegeInitialized, handlePrivilegeInitializationFailed } from '../../redux/modules/actions';

const propTypes = {};
const defaultProps = {};

const PriceGuaranteeInitialization = () => {

  const dispatch = useDispatch();
  const { handlePrivilegeRequest } = usePrivilege(moduleContextData);

  React.useEffect(
    () => {
      const functionalityContextList = [
        new GetPriceGuaranteeListPFC(),
        new ITMGetPriceGuaranteeListPFC(),
        new PUBGetPriceGuaranteeListPFC(),
        new VLDGetPriceGuaranteeListPFC(),
        new VLDGetCommissionValidationResultDealerPFC(),
        new VLDGetCommissionValidationResultImporterPFC(),
        new PGRGetPriceGuaranteeListPFC(),
        new PGRGetPriceGuaranteeReportDealerPFC(),
        new PGRGetPriceGuaranteeReportImporterPFC(),
        new RefreshStorageDataPFC()
      ];

      const asyncCall = async () => {
        try {
          await handlePrivilegeRequest(functionalityContextList);
          dispatch(handlePrivilegeInitialized());
        } catch (e) {
          dispatch(handlePrivilegeInitializationFailed());
        }
      };
      asyncCall().then();
    },
    [handlePrivilegeRequest, dispatch]
  );

  return null;
};

PriceGuaranteeInitialization.propTypes = propTypes;
PriceGuaranteeInitialization.defaultProps = defaultProps;

export default PriceGuaranteeInitialization;
