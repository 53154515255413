import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const productionCorridorApi = ApiServiceInstances.pvt.productionCorridorApi;

export const GET_PRODUCTION_CORRIDOR_REQUEST = 'GET_PRODUCTION_CORRIDOR_REQUEST';
export const GET_PRODUCTION_CORRIDOR_SUCCESS = 'GET_PRODUCTION_CORRIDOR_SUCCESS';
export const GET_PRODUCTION_CORRIDOR_FAILURE = 'GET_PRODUCTION_CORRIDOR_FAILURE';

export const UPDATE_PRODUCTION_CORRIDOR_REQUEST = 'UPDATE_PRODUCTION_CORRIDOR_REQUEST';
export const UPDATE_PRODUCTION_CORRIDOR_SUCCESS = 'UPDATE_PRODUCTION_CORRIDOR_SUCCESS';
export const UPDATE_PRODUCTION_CORRIDOR_FAILURE = 'UPDATE_PRODUCTION_CORRIDOR_FAILURE';

const initialState = {
  productionCorridorData: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_CORRIDOR_REQUEST:
      return { ...state, isFetching: true };
    case GET_PRODUCTION_CORRIDOR_SUCCESS:
      return { ...state, isFetching: false, productionCorridorData: action.payload };
    case GET_PRODUCTION_CORRIDOR_FAILURE:
      return { ...state, error: action.payload.error, isFetching: false };
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetProductionCorridorList = () => {
  return {
    [CALL_API]: {
      apiFunction: productionCorridorApi.getProductionCorridorListWithHttpInfo.bind(productionCorridorApi),
      types: [GET_PRODUCTION_CORRIDOR_REQUEST, GET_PRODUCTION_CORRIDOR_SUCCESS, GET_PRODUCTION_CORRIDOR_FAILURE]
    }
  };
};

export const handleUpdateProductionCorridorBulk = (productionCorridorBulkUpdate) => {
  return {
    [CALL_API]: {
      apiFunction: productionCorridorApi.updateProductionCorridorBulkWithHttpInfo.bind(productionCorridorApi, productionCorridorBulkUpdate),
      types: [UPDATE_PRODUCTION_CORRIDOR_REQUEST, UPDATE_PRODUCTION_CORRIDOR_SUCCESS, UPDATE_PRODUCTION_CORRIDOR_FAILURE]
    }
  };
};
