import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';
import DealerContractsButtonBar from '../DealerContractsButtonBar/DealerContractsButtonBar';
import DealerModelGroupModalError from '../../DealerAnnualTarget/DealerModelGroupModalError/DealerModelGroupModalError';

import DealerContractRow from './DealerContractRow';
import DealerContractsTableDetail from './DealerContractsTableDetail';
import DealerContractsTableEditableContext from './DealerContractsTableEditableContext';
import { getContextMenuEditMode, getContextMenuReadOnlyMode } from './DealerContractsTableContextMenu';
import { COLUMN_GROUP_IDS } from './DealerContractsTable.columnIds';
import { columnTypes, getAutoGroupColumnDef, getColumnDefinitions } from './DealerContractsTable.columns';

import './DealerContractsTable.scss';

class DealerContractsTable extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    dealerContractRows: PropTypes.arrayOf(PropTypes.instanceOf(DealerContractRow)),
    disabledEditable: PropTypes.bool,
    hiddenRCP: PropTypes.bool,
    hiddenROC: PropTypes.bool,
    onIsPrivileged: PropTypes.func.isRequired,
    onShiftDealerContractWorkflow: PropTypes.func,
    onShowCommissionListWithParams: PropTypes.func.isRequired,
    onShowContractPreview: PropTypes.func.isRequired,
    onUpdateContractRecommendedNumber: PropTypes.func
  };

  static defaultProps = {
    dealerContractRows: null,
    disabledEditable: false,
    hiddenRCP: false,
    hiddenROC: false,
    onShiftDealerContractWorkflow: undefined,
    onUpdateContractRecommendedNumber: undefined
  };

  static getDerivedStateFromProps(props, state) {
    if (props.dealerContractRows !== state.prevPropsDealerContractRows) {
      return {
        prevPropsDealerContractRows: props.dealerContractRows,
        shiftWorkflowFnCtx: {
          toCreated: [],
          toAreaAdvisorApproved: [],
          toAAManagerApproved: [],
          toRejected: []
        }
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      prevPropsDealerContractRows: null,

      editable: false,
      shiftWorkflowFnCtx: {
        toCreated: [],
        toAreaAdvisorApproved: [],
        toAAManagerApproved: [],
        toRejected: []
      }
    };
  }

  handleRefTable = (refTable) => {
    this.refTable = refTable;
  };

  handleRefreshColumns = () => {
    this.refTable.api.refreshCells({ columns: [COLUMN_GROUP_IDS.RECOMMENDED_NUMBER_GROUP], force: true });
  };

  handleEnableEdit = () => {
    this.setState({ editable: true }, this.handleRefreshColumns);
  };

  handleCancelEdit = (callback) => {
    this.setState({ editable: false }, callback);
  };

  handleCancelEditAndDiscardChanges = () => {
    if (this._hasChanges()) {
      this.handleCancelEdit(this.handleDiscardChanges);
    } else {
      this.handleCancelEdit(this.handleRefreshColumns);
    }
  };

  handleDiscardChanges = () => {
    const { dealerContractRows } = this.props;

    dealerContractRows && dealerContractRows.forEach(dc => dc.discardChanges());
    this.handleRefreshColumns();
  };

  _hasChanges = () => {
    const data = [];
    this.refTable.api.forEachLeafNode((node) => {
      node.data.isEditableContractData() && data.push(node.data);
    });
    return data.some(row => {
      return row.contract.modelGroupAnnualTargetList.some( mg => mg.isATRecommendedNumberModified())
        || row.contract.periodAnnualTargetList.some( p => p.isATSRecommendedNumberModified());
    });
  };

  handleGetContextMenuItems = (params) => {
    const { editable } = this.state;

    if (editable) {
      return getContextMenuEditMode(params);
    }
    return getContextMenuReadOnlyMode(params);
  };

  handleIsRowSelectable = (node) => {
    const { onIsPrivileged } = this.props;
    if (!node.data) {
      return false;
    }
    const privilegeFnCtxObject = node.data.contract.privilegeFunctionalityContextObject;

    return onIsPrivileged(privilegeFnCtxObject.shiftWorkflowCreatedFnCtx)
      || onIsPrivileged(privilegeFnCtxObject.shiftWorkflowAreaAdvisorApprovedFnCtx)
      || onIsPrivileged(privilegeFnCtxObject.shiftWorkflowAAManagerApprovedFnCtx)
      || onIsPrivileged(privilegeFnCtxObject.shiftWorkflowRejectedFnCtx);
  };

  // ----- Save modified data ------------------------------------------------------------------------------------------

  handleSaveChanges = async () => {
    const { onUpdateContractRecommendedNumber } = this.props;

    if (!this._hasChanges()) {
      return;
    }

    const editableContractList = [];
    this.refTable.api.forEachLeafNode(node => {
      if (node.data.isEditableContractData()) {
        editableContractList.push(node.data.contract);
      }
    });

    const modelGroupAnnualTargetChanges = editableContractList
      .flatMap(contract => contract.modelGroupAnnualTargetList)
      .filter(mg => mg.isATRecommendedNumberModified())
      .map(mg => mg.getATRecommendedNumberModification());

    const periodAnnualTargetSalesChanges = editableContractList
      .flatMap(contract => contract.periodAnnualTargetList)
      .filter(p => p.isATSRecommendedNumberModified())
      .map(p => p.getATSRecommendedNumberModification());

    try {
      const response = await onUpdateContractRecommendedNumber(modelGroupAnnualTargetChanges, periodAnnualTargetSalesChanges);

      response && this.handleCancelEdit();
    } catch (e) {
    }
  };

  handleShiftDealerContractWorkflow = (status) => {
    const { onShiftDealerContractWorkflow } = this.props;

    const selectedContractList = this.refTable.api.getSelectedRows().map(row => row.contract);

    if (selectedContractList.length === 0) {
      return;
    }

    return onShiftDealerContractWorkflow && onShiftDealerContractWorkflow({
      nextStatus: status,
      selectedContractList: selectedContractList
    });
  };

  handleSelectionChanged = (params) => {
    const rows = params.api.getSelectedRows();
    const shiftWorkflowFnCtx = {
      toAAManagerApproved: [],
      toAreaAdvisorApproved: [],
      toCreated: [],
      toRejected: []
    };
    rows.forEach(row => {
      shiftWorkflowFnCtx.toAAManagerApproved.push(row.contract.privilegeFunctionalityContextObject.shiftWorkflowAAManagerApprovedFnCtx);
      shiftWorkflowFnCtx.toAreaAdvisorApproved.push(row.contract.privilegeFunctionalityContextObject.shiftWorkflowAreaAdvisorApprovedFnCtx);
      shiftWorkflowFnCtx.toCreated.push(row.contract.privilegeFunctionalityContextObject.shiftWorkflowCreatedFnCtx);
      shiftWorkflowFnCtx.toRejected.push(row.contract.privilegeFunctionalityContextObject.shiftWorkflowRejectedFnCtx);
    });
    this.setState({ shiftWorkflowFnCtx: shiftWorkflowFnCtx });
  };

  handleColumnGroupOpened = (columnGroupOpenedEvent) => {
    const lsi = this.context;
    const { hiddenROC, hiddenRCP } = this.props;

    const groupId = columnGroupOpenedEvent.columnGroup.groupId;
    if (groupId === COLUMN_GROUP_IDS.STATISTICS_DONE_COUNT_GROUP) {
      columnGroupOpenedEvent.api.setColumnDefs(
        getColumnDefinitions(lsi, hiddenROC, hiddenRCP, columnGroupOpenedEvent.columnGroup.isExpanded())
      );
    }
  };

  render() {
    const lsi = this.context;
    const { dealerContractRows, hiddenROC, hiddenRCP, disabledEditable,
      onShiftDealerContractWorkflow, onShowContractPreview, onUpdateContractRecommendedNumber, onShowCommissionListWithParams } = this.props;
    const { editable, shiftWorkflowFnCtx } = this.state;

    return (
      <Bricks.Div className='ovex-dealer-contracts-table'>
        {this.props.modalProps.errorList && <DealerModelGroupModalError modalProps={this.props.modalProps}/>}
        <DealerContractsButtonBar
          disabledEditable={disabledEditable}
          editable={editable}
          onCancelEdit={this.handleCancelEditAndDiscardChanges}
          onEnableEdit={onUpdateContractRecommendedNumber && this.handleEnableEdit}
          onSave={onUpdateContractRecommendedNumber && this.handleSaveChanges}
          onShiftDealerContractWorkflow={onShiftDealerContractWorkflow && this.handleShiftDealerContractWorkflow}
          shiftWorkflowFnCtx={shiftWorkflowFnCtx}
        />
        <DealerContractsTableEditableContext.Provider value={editable}>
          <OvexAGTable
            agContext={{
              editable,
              hiddenROC,
              hiddenRCP,
              onShowCommissionListWithParams: onShowCommissionListWithParams,
              onShowContractPreview: onShowContractPreview
            }}
            animateRows
            autoGroupColumnDef={getAutoGroupColumnDef(lsi)}
            columnDefs={getColumnDefinitions(lsi, hiddenROC, hiddenRCP, false)}
            columnTypes={columnTypes}
            detailCellRenderer='dealerContractDetailCellRenderer'
            detailRowHeight={240}
            domLayout='autoHeight'
            embedFullWidthRows
            enableRangeSelection
            frameworkComponents={{
              dealerContractDetailCellRenderer: DealerContractsTableDetail
            }}
            getContextMenuItems={this.handleGetContextMenuItems}
            groupDefaultExpanded={1}
            groupSelectsChildren
            isRowSelectable={this.handleIsRowSelectable}
            masterDetail
            onColumnGroupOpened={this.handleColumnGroupOpened}
            onGridReady={this.handleRefTable}
            onSelectionChanged={this.handleSelectionChanged}
            rowData={dealerContractRows}
            rowSelection='multiple'
          />
        </DealerContractsTableEditableContext.Provider>
      </Bricks.Div>
    );
  }
}

export default DealerContractsTable;
