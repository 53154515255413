
class WebModuleRegister {

  /**
   * @param {WebModule} webModule
   */
  register = (webModule) => {
    this.moduleList.push(webModule);
  }

  /**
   * @return {WebModuleInitializationData[]}
   */
  getInitializationDataList = () => {
    return this.moduleList.map(module => module.initializationData);
  };

  /**
   * @return {WebModuleNavMenuData[]}
   */
  getNavMenuDataList = () => {
    const navMenuDataList = this.moduleList.flatMap(module => module.navMenuDataList);
    navMenuDataList.sort((itemA, itemB) => itemA.order - itemB.order);
    return navMenuDataList;
  };

  /**
   * @return {WebModuleRoutesData[]}
   */
  getRoutesDataList = () => {
    return this.moduleList.map(module => module.routesData);
  }

  /**
   * @return {WebModuleReduxData[]}
   */
  getReduxDataList = () => {
    return this.moduleList.map(module => module.reduxData);
  }

  /**
   * @return {WebModuleUserInfoData[]}
   */
  getUserInfoDataList = () => {
    return this.moduleList.map(module => module.userInfoData);
  }

}

/**
 * @type {WebModule[]}
 */
WebModuleRegister.prototype.moduleList = [];

export default WebModuleRegister;