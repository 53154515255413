import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class UpdateAnnualTargetYearFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} atYearId
   */
  constructor(atYearId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_NEGOTIATIONS__UPDATE_YEAR);
    this.setParams({
      [ContextConstants.AT_YEAR_ID]: atYearId
    });
  }

}

export default UpdateAnnualTargetYearFunctionalityContext;