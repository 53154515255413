import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CommissionList from './CommissionList';

const propTypes = {
  onGetCommissionListReduxFn: PropTypes.func,
  titleLsiCode: PropTypes.string.isRequired
};

const defaultProps = {
  onGetCommissionListReduxFn: null
};

const CommissionListConnector = (props) => {
  const { titleLsiCode, onGetCommissionListReduxFn } = props;

  const { commissionsParamsUuid } = useParams();

  const selectedState = useSelector(state => {
    return {
      isFetching: state.at.commissions.isFetching,
      commissionList: state.at.commissions.commissionList
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => ({
      handleGetCommissionList: onGetCommissionListReduxFn && ( (uuid) => dispatch(onGetCommissionListReduxFn(uuid)) )
    }),
    [dispatch, onGetCommissionListReduxFn]
  );

  const handleGetCommissionListWithUuid = React.useCallback(
    () => callbacks.handleGetCommissionList?.(commissionsParamsUuid),
    [callbacks, commissionsParamsUuid]
  );

  return (
    <CommissionList
      commissionList={selectedState.commissionList}
      isFetching={selectedState.isFetching}
      onGetCommissionList={onGetCommissionListReduxFn && handleGetCommissionListWithUuid}
      titleLsiCode={titleLsiCode}
    />
  );
};

CommissionListConnector.propTypes = propTypes;
CommissionListConnector.defaultProps = defaultProps;

export default CommissionListConnector;
