
const makeColumnId = (groupId, partColumnId) => `${groupId}__${partColumnId}`;

export const COLUMN_GROUP_IDS = {
  DEALER_NUMBER_GROUP: 'at_ats_dealerNumber',
  RECOMMENDED_NUMBER_GROUP: 'at_ats_recommendedNumber',
  APPROVAL_NUMBER_GROUP: 'at_ats_approvalNumber',
  APPROVED_NUMBER_GROUP: 'at_ats_approvedNumber',

  STATISTICS_DONE_COUNT_GROUP: 'statistics_doneCount',
  STATISTICS_DONE_PERCENT_GROUP: 'statistics_donePercent',
  STATISTICS_REMAINS_COUNT_GROUP: 'statistics_remainsCount',
  STATISTICS_POTENTIAL_COUNT_GROUP: 'statistics_potentialCount'
};

export const COLUMN_IDS = {
  YEAR: 'year',
  AREA_NAME: 'areaName',
  DEALER_NUMBER: 'dealerNumber',
  DEALER_NAME: 'dealerName',
  CONTRACT_NAME: 'contractName',
  CONTRACT_STATUS: 'contractStatus',
  CONTRACT_STATISTICS_TIMESTAMP: 'contractStatisticsTimestamp',

  DEALER_NUMBER_AT: makeColumnId(COLUMN_GROUP_IDS.DEALER_NUMBER_GROUP, 'AT'),
  DEALER_NUMBER_ATS: makeColumnId(COLUMN_GROUP_IDS.DEALER_NUMBER_GROUP, 'ATS'),
  RECOMMENDED_NUMBER_AT: makeColumnId(COLUMN_GROUP_IDS.RECOMMENDED_NUMBER_GROUP, 'AT'),
  RECOMMENDED_NUMBER_ATS: makeColumnId(COLUMN_GROUP_IDS.RECOMMENDED_NUMBER_GROUP, 'ATS'),
  APPROVAL_NUMBER_AT: makeColumnId(COLUMN_GROUP_IDS.APPROVAL_NUMBER_GROUP, 'AT'),
  APPROVAL_NUMBER_ATS: makeColumnId(COLUMN_GROUP_IDS.APPROVAL_NUMBER_GROUP, 'ATS'),
  APPROVED_NUMBER_AT: makeColumnId(COLUMN_GROUP_IDS.APPROVED_NUMBER_GROUP, 'AT'),
  APPROVED_NUMBER_ATS: makeColumnId(COLUMN_GROUP_IDS.APPROVED_NUMBER_GROUP, 'ATS'),

  STATISTICS_DONE_COUNT_AT_INVOICED: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_DONE_COUNT_GROUP, 'AT_INVOICED'),
  STATISTICS_DONE_COUNT_AT_PRODUCTION: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_DONE_COUNT_GROUP, 'AT_PRODUCTION'),
  STATISTICS_DONE_COUNT_AT_PLANNED: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_DONE_COUNT_GROUP, 'AT_PLANNED'),
  STATISTICS_DONE_COUNT_AT_TOTAL: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_DONE_COUNT_GROUP, 'AT_TOTAL'),
  STATISTICS_DONE_COUNT_ATS: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_DONE_COUNT_GROUP, 'ATS'),
  STATISTICS_DONE_PERCENT_AT: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP, 'AT'),
  STATISTICS_DONE_PERCENT_ATS: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP, 'ATS'),
  STATISTICS_REMAINS_COUNT_AT: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_REMAINS_COUNT_GROUP, 'AT'),
  STATISTICS_REMAINS_COUNT_ATS: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_REMAINS_COUNT_GROUP, 'ATS'),
  STATISTICS_POTENTIAL_COUNT_AT_PRODUCTION: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_COUNT_GROUP, 'AT_PRODUCTION'),
  STATISTICS_POTENTIAL_COUNT_AT_PLANNED: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_COUNT_GROUP, 'AT_PLANNED'),
  STATISTICS_POTENTIAL_COUNT_AT_TOTAL: makeColumnId(COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_COUNT_GROUP, 'AT_TOTAL')
};

