import { Common } from 'uu5g04';

import { quarterPeriodFormatter, simpleNullishValueFormatter } from '../../../../common/utils/formatters';

const formatDate = (date) => Common.Tools.formatDate(date, 'dd.mm.Y');

export const handleContractTypeValueFormatter = (lsi) => (valueFormatterParams) => {
  return lsi.getLSIItem('ANNUAL_TARGET.ENUM_LABELS.CONTRACT_TYPE_ABOUT')[valueFormatterParams.value];
};

export const handleNegotiationStatusValueFormatter = (lsi) => (valueFormatterParams) => {
  return lsi.getLSIItem('ANNUAL_TARGET.ENUM_LABELS.NEGOTIATION_STATUS')[valueFormatterParams.value];
};

export const proposalDealerValueFormatter = (valueFormatterParams) => {
  const data = valueFormatterParams.data;
  if (valueFormatterParams.node.group || data.isYearSettingsRow() || data.isNegotiationClosureRow()) {
    return '';
  }
  return `${formatDate(data.proposalDealerFrom)} - ${formatDate(data.proposalDealerTo)}`;
};

export const proposalApprovalValueFormatter = (valueFormatterParams) => {
  const data = valueFormatterParams.data;
  if (valueFormatterParams.node.group || data.isYearSettingsRow() || data.isNegotiationClosureRow()) {
    return '';
  }
  return `${formatDate(data.proposalApprovalFrom)} - ${formatDate(data.proposalApprovalTo)}`;
};

export const periodHeaderValueGetter = ({ colDef }) => quarterPeriodFormatter(colDef.ovexUnitOrder);

export const atPeriodValueGetter = ({ data, colDef }) => data?.atPeriodMap?.[colDef.ovexUnitOrder];
export const atSalesPeriodValueGetter = ({ data, colDef }) => data?.atSalesPeriodMap?.[colDef.ovexUnitOrder];

export const atPeriodCorrectionValueGetter = ({ data }) => data?.atPeriodList.find(p => p.correction);
export const atPeriodCorrectionValueFormatter = (valueFormatterParams) => {
  const { node, data, value } = valueFormatterParams;
  if (node.group || data.isYearSettingsRow() || data.isNegotiationClosureRow()) {
    return '';
  }
  return simpleNullishValueFormatter(value ? quarterPeriodFormatter(value.unitOrder) : null, '-');
};

export const handlePostSort = (rowNodes) => {
  const move = (toIndex, fromIndex) => {
    rowNodes.splice(toIndex, 0, rowNodes.splice(fromIndex, 1)[0]);
  };

  let nextInsertPos = 0;
  rowNodes.forEach((rowNode, idx) => {
    if (!rowNode.group) {
      if (rowNode.data.isYearSettingsRow()) {
        move(nextInsertPos, idx);
        nextInsertPos++;
      }
    }
  });
};
