import PropTypes from 'prop-types';
import React from 'react';
import { Bricks, Forms } from 'uu5g04';
import 'uu5g04/forms';

import { Button, Modal } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import './BalanceRecalculationForm.scss';
import BalancePeriodCheckbox from '../QuarterPeriods/BalancePeriodCheckbox';
import { Checkbox } from '../../../../../common/components/forms';
import { useAlertBusOvex } from '../../../../../common/hooks';
import { AlertTypeEnum } from '../../../../../common/objects';

const BalanceRecalculationForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const [balanceActive, setBalanceActive] = React.useState(props.quarters.some(val => val.correction === true));
  const [quarters, setQuarters] = React.useState(props.quarters);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const handleSave = () => {

    const itemWithCorrection = quarters.find(item => item.correction === true);

    if (balanceActive && !itemWithCorrection) {
      handleAddAlertSimple('PVT.ERROR_MESSAGE.UPDATE_BALANCE_FAILURE', AlertTypeEnum.WARNING);
      return;
    }

    if (itemWithCorrection) {
      props.onSave(itemWithCorrection);
    } else {
      const emptyItem = {
        correction: false,
        quarter: null
      };
      props.onSave(emptyItem);
    }
  };
  const handleUpdateValue = (value) => {
    setBalanceActive(value);
    const updatedArray = quarters.map(obj => ({ ...obj, correction: false }));
    setQuarters(updatedArray);
  };

  const handleUpdateCheckBoxes = (v) => {
    const updatedArray = quarters.map(obj =>
      obj.quarter === v.quarter ? { ...obj, correction: v.correction } : { ...obj, correction: false }
    );
    setQuarters(updatedArray);
  };

  return (
    <Modal
      className={'ovex-pvt-BalanceRecalculationModalForm'}
      header={lsi.getLSIItem('PVT.PAGE_TITLE.BALANCE')}
      onClose={props.onClose}
      shown={props.shown}
      size={'l'}
    >
      <Forms.Form
        className={'ovex-pvt-BalanceRecalculationForm'}
        onCancel={props.onClose}
        onSave={handleSave}
      >
        <Bricks.Row className={'ovex-pvt-BalanceRecalculationForm-balanceApplication'}>
          <Bricks.Text className={'uu5-forms-label ovex-pvt-balanceLabel'}>
            {lsi.getLSIItem('PVT.FORM_INPUT_LABEL.BALANCE_APPLICATION')}
          </Bricks.Text>
          <Bricks.Column
            colWidth='xs3'
            key={'Balance_Checkbox'}>
            <Checkbox
              className={'uu5-forms-checkbox'}
              inputColWidth='xs3'
              name={'PVT_BALANCE'}
              onChange={(v) => handleUpdateValue(v)}
              value={balanceActive}
            />
          </Bricks.Column>
        </Bricks.Row>
        <Bricks.Row className={'ovex-pvt-BalanceRecalculationForm-balanceApplication'}>
          <Bricks.Text className={'uu5-forms-label ovex-pvt-half'}>
            <span className={'hidden'}>_</span>
          </Bricks.Text>
          <Bricks.Div className={'ovex-pvt-half'}>
            {quarters.map(period => {
              return (
                <Bricks.Column
                  className={'uu5-forms-label label period'}
                  colWidth='xs3'
                  key={period.quarter}
                >
                  {`${lsi.getLSIItem('PVT.FORM_INPUT_LABEL.PERIOD')} ${period.quarter}.`}
                </Bricks.Column>
              );
            })
            }
          </Bricks.Div>
        </Bricks.Row>
        <BalancePeriodCheckbox
          isBalanceApplicated={balanceActive}
          name={'PVT'}
          onUpdate={handleUpdateCheckBoxes}
          value={quarters}
        />
        <Forms.Controls
          buttonCancelProps={Button.formControlsProps[Button.type.SECONDARY]}
          buttonSubmitProps={Button.formControlsProps[Button.type.PRIMARY]}
        />
      </Forms.Form>
    </Modal>
  );
}
);

BalanceRecalculationForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  quarters: PropTypes.array,
  shown: PropTypes.bool
};

BalanceRecalculationForm.defaultProps = {
  shown: false
};

export default BalanceRecalculationForm;
