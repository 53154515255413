import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class UpdatePriceGuaranteePFC extends AbstractFunctionalityContext {

  /**
   * @param {number} priceGuaranteeId
   */
  constructor(priceGuaranteeId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PG__UPDATE_PRICE_GUARANTEE);
    this.setParams({
      [ContextConstants.PRICE_GUARANTEE_ID]: priceGuaranteeId
    });
  }

}

export default UpdatePriceGuaranteePFC;