import PriceGuaranteeReportPageType from '../PriceGuaranteeReportPageType';

export const InputNames = {
  priceGuaranteeIdList: 'priceGuaranteeIdList',
  claimTypeHeaderList: 'claimTypeHeaderList',
  commissionNumberList: 'commissionNumberList',
  dealerNumberList: 'dealerNumberList',
  commissionNumber: 'commissionNumber',
  dealerNumber: 'dealerNumber'
};

export const getDefaultValues = (pageType) => ({
  [InputNames.priceGuaranteeIdList]: PriceGuaranteeReportPageType.isDealer(pageType) ? [] : '',
  [InputNames.claimTypeHeaderList]: [],
  [InputNames.commissionNumber]: '',
  [InputNames.dealerNumber]: ''
});

export const convertFilterValues = (filterValues, pageType) => {
  const filterParams = {
    priceGuaranteeIdList: undefined,
    claimTypeHeaderList: undefined,
    commissionNumberList: undefined,
    dealerNumberList: undefined
  };

  const priceGuaranteeIdList = filterValues[InputNames.priceGuaranteeIdList];
  const commissionNumber = filterValues[InputNames.commissionNumber];

  filterParams.priceGuaranteeIdList = PriceGuaranteeReportPageType.isDealer(pageType) ? priceGuaranteeIdList: [priceGuaranteeIdList];
  filterParams.claimTypeHeaderList = filterValues[InputNames.claimTypeHeaderList];
  filterParams.commissionNumberList = commissionNumber ? [commissionNumber] : [];

  if (PriceGuaranteeReportPageType.isImporterVariant(pageType)) {
    const dealerNumber = filterValues[InputNames.dealerNumber];
    filterParams.dealerNumberList = dealerNumber ? [dealerNumber] : []; // convert single value to list
  }

  return filterParams;
};
