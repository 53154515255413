
export const moduleCode = 'PVT';

/**
 * REST API base path
 *
 * @type {string}
 */
export const apiBasePath = '/pvt/web-api';

/**
 * Path prefix for module paths (routes)
 *
 * @type {string}
 */
export const modulePathPrefix = '/pvt';

/**
 * Module name for reducer
 * @type {string}
 */
export const moduleReducerName = 'pvt';

/**
 * Redux selector for user info.
 *
 * @param state
 * @return UserInfoDTO
 */
export const userInfoSelector = state => state.pvt.userInfo.userInfo;

/**
 * Redux selector for initialization status.
 *
 * @param state
 * @return {string} of InitializationStatus
 */
export const initializationStatusSelector = state => state.pvt.initialization.status;
