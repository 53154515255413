import { PvtApiClient } from '../../../../api';

import RestrictionWeekCell from './RestrictionWeekCell';
import RestrictionRowTypeEnum from './RestrictionRowTypeEnum';
import { RestrictionTypeEnum } from '../../../../utils/const'

class RestrictionRow {

  /**
   * @param {Object} restrictionDefinition
   * @param {Array.<String>} weeks
   * @return {RestrictionRow}
   */
  static constructNewRow(restrictionDefinition, weeks) {
    let obj;
    if (restrictionDefinition) {
      obj = new RestrictionRow();

      // RestrictionDTO attributes
      obj['id'] = null;
      obj['added'] = true;
      obj['deletedInFuture'] = false;
      obj['brand'] = null;
      obj['bid'] = null;
      obj['modelGroup'] = restrictionDefinition.hasOwnProperty('modelGroup') && PvtApiClient.convertToType(restrictionDefinition['modelGroup'], 'String');
      obj['restrictionType'] = restrictionDefinition.hasOwnProperty('restrictionType') && PvtApiClient.convertToType(restrictionDefinition['restrictionType'], 'String');
      obj['modelMask'] = restrictionDefinition.hasOwnProperty('modelMask') && PvtApiClient.convertToType(restrictionDefinition['modelMask'], 'String');
      obj['colour'] = restrictionDefinition.hasOwnProperty('colour') && PvtApiClient.convertToType(restrictionDefinition['colour'], 'String');
      obj['interior'] = restrictionDefinition.hasOwnProperty('interior') && PvtApiClient.convertToType(restrictionDefinition['interior'], 'String');
      obj['prNumber'] = restrictionDefinition.hasOwnProperty('prNumber') && PvtApiClient.convertToType(restrictionDefinition['prNumber'], 'String');
      obj['annualTarget'] = null;
      obj['stockCar'] = null;

      // own attributes + generated week properties
      if(obj.restrictionType !== RestrictionTypeEnum.T){
        weeks && weeks.forEach(week => {
          obj[week] = RestrictionWeekCell.constructWeekForNewRow(week);
        });
      }

      obj['type'] = RestrictionRowTypeEnum.RESTRICTION;
      obj['deletedInFutureOld'] = obj['deletedInFuture'];
    }
    return obj;
  }

  /**
   * @param {module:model/RestrictionDTO} restriction
   * @return {RestrictionRow}
   */
  static constructFromRestrictionDTO(restriction) {
    let obj;
    if (restriction) {
      obj = new RestrictionRow();

      // RestrictionDTO properties
      obj['id'] = restriction.hasOwnProperty('id') && PvtApiClient.convertToType(restriction['id'], 'Number');
      obj['added'] = restriction.hasOwnProperty('added') && PvtApiClient.convertToType(restriction['added'], 'Boolean');
      obj['deletedInFuture'] = restriction.hasOwnProperty('deletedInFuture') && PvtApiClient.convertToType(restriction['deletedInFuture'], 'Boolean');
      obj['brand'] = restriction.hasOwnProperty('brand') && PvtApiClient.convertToType(restriction['brand'], 'String');
      obj['bid'] = restriction.hasOwnProperty('bid') && PvtApiClient.convertToType(restriction['bid'], 'String');
      obj['modelGroup'] = restriction.hasOwnProperty('modelGroup') && PvtApiClient.convertToType(restriction['modelGroup'], 'String');
      obj['restrictionType'] = restriction.hasOwnProperty('restrictionType') && PvtApiClient.convertToType(restriction['restrictionType'], 'String');
      obj['modelMask'] = restriction.hasOwnProperty('modelMask') && PvtApiClient.convertToType(restriction['modelMask'], 'String');
      obj['colour'] = restriction.hasOwnProperty('colour') && PvtApiClient.convertToType(restriction['colour'], 'String');
      obj['interior'] = restriction.hasOwnProperty('interior') && PvtApiClient.convertToType(restriction['interior'], 'String');
      obj['prNumber'] = restriction.hasOwnProperty('prNumber') && PvtApiClient.convertToType(restriction['prNumber'], 'String');
      obj['annualTarget'] = restriction.hasOwnProperty('annualTarget') && PvtApiClient.convertToType(restriction['annualTarget'], 'Boolean');
      obj['stockCar'] = restriction.hasOwnProperty('stockCar') && PvtApiClient.convertToType(restriction['stockCar'], 'Boolean');

      // own properties + generated week properties
      if (Array.isArray(restriction.restrictionWeeks)) {
        restriction.restrictionWeeks.forEach(restrictionWeek => {
          obj[restrictionWeek.week] = RestrictionWeekCell.constructFromObject(restrictionWeek);
        });
      }

      obj['type'] = RestrictionRowTypeEnum.valueOf(restriction);
      obj['deletedInFutureOld'] = obj['deletedInFuture'];
    }

    return obj;
  }

  isNew = () => {
    return this.id == null;
  };

  setDeleteFlag = () => {
    this.deletedInFuture = true;
  };

  undoDeleteFlag = () => {
    this.deletedInFuture = false;
  };

  isDeleteFlagChanged = () => {
    return this.deletedInFuture !== this.deletedInFutureOld;
  };

  isToDelete = () => {
    return this.deletedInFuture && !this.deletedInFutureOld;
  };

}

// RestrictionDTO properties
RestrictionRow.prototype['id'] = undefined;
RestrictionRow.prototype['added'] = undefined;
RestrictionRow.prototype['deletedInFuture'] = undefined;
RestrictionRow.prototype['brand'] = undefined;
RestrictionRow.prototype['bid'] = undefined;
RestrictionRow.prototype['modelGroup'] = undefined;
RestrictionRow.prototype['restrictionType'] = undefined;
RestrictionRow.prototype['modelMask'] = undefined;
RestrictionRow.prototype['colour'] = undefined;
RestrictionRow.prototype['interior'] = undefined;
RestrictionRow.prototype['prNumber'] = undefined;
RestrictionRow.prototype['annualTarget'] = undefined;
RestrictionRow.prototype['stockCar'] = undefined;

// own properties + generated week properties
RestrictionRow.prototype['type'] = undefined;
RestrictionRow.prototype['deletedInFutureOld'] = undefined;

export default RestrictionRow;

