import { connect } from 'react-redux';

import {
  handleGetBalance,
  handleGetProductionCorridorList,
  handleUpdateBalance,
  handleUpdateProductionCorridorBulk
} from '../../redux/modules/actions';

import ProductionCorridor from './ProductionCorridor';

const mapStateToProps = state => ({
  isFetching: state.pvt.productionCorridor.isFetching,
  productionCorridorData: state.pvt.productionCorridor.productionCorridorData,
  balanceData: state.pvt.balance.balanceData
});

const mapDispatchToProps = dispatch => ({
  onGetProductionCorridorList: () => dispatch(handleGetProductionCorridorList()),
  onGetBalance: () => dispatch(handleGetBalance()),
  onUpdateProductionCorridorBulk: (productionCorridorBulkUpdate) => dispatch(handleUpdateProductionCorridorBulk(productionCorridorBulkUpdate)),
  onUpdateBalance: (balanceUpdate) => dispatch(handleUpdateBalance(balanceUpdate))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionCorridor);