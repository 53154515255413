import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { Button, Modal } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';
import { OvexAGTable } from '../../../../common/components/ag-grid';

import { getColumnDefinitions } from './DealerModelGroupTable.columnDefs';
import './DealerModelGroupModalError.scss';

function DealerModelGroupModalError(props) {
  const lsi = React.useContext(LsiContext);

  function prepareErrorData(data) {
    return data
      .map((d) => {
        return d.parametersMap;
      });
  }

  return (
    <Modal
      header={lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.WARNING')}
      onClose={props.modalProps.onClose}
      shown={props.modalProps.shown}
      size={'m'}
    >

      <div
        className={'ovex-DealerModelGroupModalColoredInfoLabel'}
        dangerouslySetInnerHTML={{ __html: lsi.getLSIItem('ANNUAL_TARGET.LABELS.NEGATIVE_VALUE_INFO') }}/>
      <Bricks.Div className={'ovex-DealerModelGroupModalErrorLabel'}>
        {lsi.getLSIItem('ANNUAL_TARGET.LABELS.NEGATIVE_VALUE_INFO_2')}
      </Bricks.Div>
      <OvexAGTable
        columnDefs={getColumnDefinitions(lsi)}
        height={'320px'}
        rowData={prepareErrorData(props.modalProps.errorList)}/>
      <Bricks.Div className={'ovex-DealerModelGroupModalColoredErrorLabel'}>
        {lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.DEALER_NEGATIVE_VALUE_APPROVAL')}
      </Bricks.Div>
      <Bricks.Div className={'ovex-DealerModelGroupModalColoredNoteLabel'}>
        {lsi.getLSIItem('ANNUAL_TARGET.LABELS.NEGATIVE_VALUE_INFO_3')}
      </Bricks.Div>

      <Bricks.Div className={'ovex-DealerModelGroupModalButtonBar'}>
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.CANCEL')}
          key={'ovex-DealerModelGroupModalError-btn-cancel'}
          onClick={props.modalProps.onClose}
          type={Button.type.SECONDARY}/>
        <Button
          content={lsi.getLSIItem('COMMON.BUTTON.CONFIRM')}
          key={'ovex-DealerModelGroupModalError-btn-save'}
          onClick={() => props.modalProps.onSave(false)}
          type={Button.type.PRIMARY}/>
      </Bricks.Div>
    </Modal>

  );
}

export default DealerModelGroupModalError;