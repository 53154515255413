/**
 * Vyhodnoceni editovatelnosti na zaklade opravneni.
 * Adept na presun pod ColumnDefinitionWrapper
 *
 * @param {object} params - ag-grid cell editing params {@link https://www.ag-grid.com/javascript-grid-cell-editing/}
 * @param {object} privilegeFnCtx
 * @return {boolean}
 */
const privilegeEditable = (params, privilegeFnCtx) => {
  const handleIsPrivileged = params.context.onAgIsPrivileged;
  return handleIsPrivileged(privilegeFnCtx);
};

export default privilegeEditable;