import { handleSaveCommissionsLoadParamsForImporter } from '../../../redux/modules/actions';
import routes from '../../../routes';

import useShowCommissionListWithParams from './useShowCommissionListWithParams';

const useShowCommissionListWithParamsForImporter = () => {
  return useShowCommissionListWithParams(handleSaveCommissionsLoadParamsForImporter, routes.paths.ROUTE_PATH_ANNUAL_TARGET_COMMISSION_LIST_IMPORTER);
};

export default useShowCommissionListWithParamsForImporter;
