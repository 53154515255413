import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';
import NegotiationRow from '../../../layouts/Negotiations/NegotiationsTable/NegotiationRow';

const annualTargetYearApi = ApiServiceInstances.annualTarget.annualTargetYearApi;

export const GET_ANNUAL_TARGET_CALENDAR_YEARS_REQUEST = 'GET_ANNUAL_TARGET_CALENDAR_YEARS_REQUEST';
export const GET_ANNUAL_TARGET_CALENDAR_YEARS_SUCCESS = 'GET_ANNUAL_TARGET_CALENDAR_YEARS_SUCCESS';
export const GET_ANNUAL_TARGET_CALENDAR_YEARS_FAILURE = 'GET_ANNUAL_TARGET_CALENDAR_YEARS_FAILURE';

export const CREATE_ANNUAL_TARGET_YEAR_REQUEST = 'CREATE_ANNUAL_TARGET_YEAR_REQUEST';
export const CREATE_ANNUAL_TARGET_YEAR_SUCCESS = 'CREATE_ANNUAL_TARGET_YEAR_SUCCESS';
export const CREATE_ANNUAL_TARGET_YEAR_FAILURE = 'CREATE_ANNUAL_TARGET_YEAR_FAILURE';

export const GET_ANNUAL_TARGET_YEARS_REQUEST = 'GET_ANNUAL_TARGET_YEARS_REQUEST';
export const GET_ANNUAL_TARGET_YEARS_SUCCESS = 'GET_ANNUAL_TARGET_YEARS_SUCCESS';
export const GET_ANNUAL_TARGET_YEARS_FAILURE = 'GET_ANNUAL_TARGET_YEARS_FAILURE';

export const UPDATE_ANNUAL_TARGET_YEAR_REQUEST = 'UPDATE_ANNUAL_TARGET_YEAR_REQUEST';
export const UPDATE_ANNUAL_TARGET_YEAR_SUCCESS = 'UPDATE_ANNUAL_TARGET_YEAR_SUCCESS';
export const UPDATE_ANNUAL_TARGET_YEAR_FAILURE = 'UPDATE_ANNUAL_TARGET_YEAR_FAILURE';

const initialState = {
  annualTargetYearList: null,
  calendarYearList: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNUAL_TARGET_CALENDAR_YEARS_REQUEST:
    case CREATE_ANNUAL_TARGET_YEAR_REQUEST:
    case GET_ANNUAL_TARGET_YEARS_REQUEST:
    case UPDATE_ANNUAL_TARGET_YEAR_REQUEST: {
      return { ...state, isFetching: true };
    }
    case CREATE_ANNUAL_TARGET_YEAR_SUCCESS:
    case UPDATE_ANNUAL_TARGET_YEAR_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case GET_ANNUAL_TARGET_CALENDAR_YEARS_SUCCESS: {
      return { ...state, isFetching: false,
        calendarYearList: action.payload
      };
    }
    case GET_ANNUAL_TARGET_YEARS_SUCCESS: {
      return { ...state, isFetching: false,
        annualTargetYearList: action.payload,
        negotiationRowList: NegotiationRow.constructFromList(action.payload)
      };
    }
    case GET_ANNUAL_TARGET_CALENDAR_YEARS_FAILURE:
    case CREATE_ANNUAL_TARGET_YEAR_FAILURE:
    case GET_ANNUAL_TARGET_YEARS_FAILURE:
    case UPDATE_ANNUAL_TARGET_YEAR_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const getAnnualTargetCalendarYears = () => {
  return {
    [CALL_API]: {
      apiFunction: annualTargetYearApi.getAnnualTargetCalendarYearsWithHttpInfo.bind(annualTargetYearApi),
      types: [GET_ANNUAL_TARGET_CALENDAR_YEARS_REQUEST, GET_ANNUAL_TARGET_CALENDAR_YEARS_SUCCESS, GET_ANNUAL_TARGET_CALENDAR_YEARS_FAILURE]
    }
  };
};

export const createAnnualTargetYear = (annualTargetYearNewDTO) => {
  return {
    [CALL_API]: {
      apiFunction: annualTargetYearApi.createAnnualTargetYearWithHttpInfo.bind(annualTargetYearApi, annualTargetYearNewDTO),
      types: [CREATE_ANNUAL_TARGET_YEAR_REQUEST, CREATE_ANNUAL_TARGET_YEAR_SUCCESS, CREATE_ANNUAL_TARGET_YEAR_FAILURE]
    }
  };
};

export const getAnnualTargetYears = (year) => {
  return {
    [CALL_API]: {
      apiFunction: annualTargetYearApi.getAnnualTargetYearsWithHttpInfo.bind(annualTargetYearApi, { year: year }),
      types: [GET_ANNUAL_TARGET_YEARS_REQUEST, GET_ANNUAL_TARGET_YEARS_SUCCESS, GET_ANNUAL_TARGET_YEARS_FAILURE]
    }
  };
};

export const handleUpdateAnnualTargetYears = (atYearId, annualTargetYearUpdateDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => annualTargetYearApi.updateAnnualTargetYearsWithHttpInfo(atYearId, annualTargetYearUpdateDTO),
      types: [UPDATE_ANNUAL_TARGET_YEAR_REQUEST, UPDATE_ANNUAL_TARGET_YEAR_SUCCESS, UPDATE_ANNUAL_TARGET_YEAR_FAILURE]
    }
  };
};

