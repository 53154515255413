import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class PUBGetPriceGuaranteeItemsPFC extends AbstractFunctionalityContext {

  /**
   * @param {number} priceGuaranteeId
   */
  constructor(priceGuaranteeId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PUB__GET_PRICE_GUARANTEE_ITEMS);
    this.setParams({
      [ContextConstants.PRICE_GUARANTEE_ID]: priceGuaranteeId
    });
  }

}

export default PUBGetPriceGuaranteeItemsPFC;
