import { ConditionResultHeaderEnum } from '../../../../../../utils/const';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class ClaimSendByStatusRUID extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.CLAIM_SEND_BY_STATUS__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.CLAIM_SEND_BY_COMMISSION_STATUS');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return [
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_SEND_BY_COMMISSION_STATUS_PART_1'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_SEND_BY_COMMISSION_STATUS_PART_2'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLAIM_SEND_BY_COMMISSION_STATUS_PART_3')
    ];
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    const { priceGuarantee } = context;

    return priceGuarantee.twoWeeksAfterSale;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;

    return value
      ? agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.TWO_WEEKS_PERIOD_AFTER_SOLD_STATE')
      : agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.SOLD_STATE');
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { priceGuarantee, commission } = context;

    return {
      commissionStatus: commission.commissionCurrentData.status,
      twoWeeksAfterSale: priceGuarantee.twoWeeksAfterSale,
      conditionResult: data.result
    };
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    const { commissionStatus, twoWeeksAfterSale, conditionResult } = value;

    if (twoWeeksAfterSale && commissionStatus === 'R') {
      if (conditionResult === ConditionResultHeaderEnum.REPAIRABLE) {
        return agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.TWO_WEEKS_TILL_SOLD_STATE');
      }
      return agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.TWO_WEEKS_PERIOD_AFTER_SOLD_STATE');
    }
    return commissionStatus;
  }
}

const claimSendByStatusRUID = new ClaimSendByStatusRUID();

export default claimSendByStatusRUID;