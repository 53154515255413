import { handleAddAlert } from '../modules/main/alertBus';

import CALL_API from './CALL_API';
import resolveAlert from './resolveAlert';
import ApiMiddlewareRejectReason from './ApiMiddlewareRejectReason';
import ApiMiddlewareResponseValue from './ApiMiddlewareResponseValue';

export default store => next => action => {
  const callAPI = action[CALL_API];

  // So the middleware doesn"t get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { apiFunction, types, metaData, middlewareConfig } = callAPI;

  const [requestType, successType, failureType] = types;
  next({ type: requestType, metaData: metaData });

  return apiFunction()
    .then(({ data, response }) => {
      next({ payload: data, response: response, type: successType, metaData: metaData });

      return Promise.resolve(new ApiMiddlewareResponseValue(response, data));
    })
    .catch((error) => {
      const alert = resolveAlert(error);
      const showError = middlewareConfig?.validate?.(error.body) ?? true;
      if (showError) {
        next(handleAddAlert(alert));
      }

      next({ payload: { error: error }, type: failureType, metaData: metaData });

      return Promise.reject(new ApiMiddlewareRejectReason(error, alert));
    });
};
