import { ContractAnnualTargetSalesDealerUpdateDTO, ContractAnnualTargetSalesApprovalUpdateDTO } from '@ovex/annual-target-web-api';

import { AG_GROUP_TOTAL } from '../utils/const';

class DealerPeriodAnnualTargetSalesRow {

  constructor(groupValue) {
    this.groupValue = groupValue;
    this._atsDealerNumberOriginal = null;
    this._atsApprovalNumberOriginal = null;
  }

  isTotalRow = () => this.groupValue === AG_GROUP_TOTAL;
  isPeriodRow = () => !this.isTotalRow();

  isEditable = () => this.isPeriodRow();

  setATSDealerNumber = (value) => {
    this.atsDealerNumber = value;
  };
  setATSApprovalNumber = (value) => {
    this.atsApprovalNumber = value;
  };

  undoATSDealerNumber = () => {
    this.atsDealerNumber = this._atsDealerNumberOriginal;
  };
  undoATSApprovalNumber = () => {
    this.atsApprovalNumber = this._atsApprovalNumberOriginal;
  };

  isATSDealerNumberModified = () => {
    return this.atsDealerNumber !== this._atsDealerNumberOriginal && this.isPeriodRow();
  };
  isATSApprovalNumberModified = () => {
    return this.atsApprovalNumber !== this._atsApprovalNumberOriginal && this.isPeriodRow();
  };

  isModified = () => {
    return this.isATSApprovalNumberModified() || this.isATSDealerNumberModified();
  };

  discardChanges = () => {
    this.undoATSApprovalNumber();
    this.undoATSDealerNumber();
  };

  getATSDealerNumberModification = () => new ContractAnnualTargetSalesDealerUpdateDTO(this.id, this.version, this.atsDealerNumber);
  getATSApprovalNumberModification = () => new ContractAnnualTargetSalesApprovalUpdateDTO(this.id, this.version, this.atsApprovalNumber);
}

DealerPeriodAnnualTargetSalesRow.prototype.groupValue = undefined; // property for ag-Grid grouping and aggregations

DealerPeriodAnnualTargetSalesRow.prototype.id = undefined;
DealerPeriodAnnualTargetSalesRow.prototype.version = undefined;
DealerPeriodAnnualTargetSalesRow.prototype.branchDealerNumber = undefined;
DealerPeriodAnnualTargetSalesRow.prototype.periodOrder = undefined;
DealerPeriodAnnualTargetSalesRow.prototype.negotiable = undefined;

DealerPeriodAnnualTargetSalesRow.prototype.atsDealerNumber = undefined;
DealerPeriodAnnualTargetSalesRow.prototype.atsRecommendedNumber = undefined;
DealerPeriodAnnualTargetSalesRow.prototype.atsApprovalNumber = undefined;
DealerPeriodAnnualTargetSalesRow.prototype.atsApprovedNumber = undefined;
DealerPeriodAnnualTargetSalesRow.prototype.atsStatisticsSoldCount = undefined;

DealerPeriodAnnualTargetSalesRow.prototype.atsStatisticsRemainsCount = undefined;

DealerPeriodAnnualTargetSalesRow.prototype.privilegeFunctionalityContextObject = undefined;

export default DealerPeriodAnnualTargetSalesRow;
