import { currentYear } from '../../../config/config';

export const InputNames = {
  yearList: 'yearList',
  negotiationId: 'negotiationId',
  atsPeriodOrderList: 'atsPeriodOrderList'
};

export const SelectOptionMapKeys = {
  yearList: 'yearList',
  negotiationList: 'negotiationList',
  atsPeriodOrderList: 'atsPeriodOrderList'
};

export const valuesDefault = {
  [InputNames.yearList]: [currentYear.toString()],
  [InputNames.negotiationId]: null,
  [InputNames.atsPeriodOrderList]: []
};

export const filterContentValueGetterMap = {
  [SelectOptionMapKeys.yearList]: (filterContent) => filterContent.year.toString(),
  [SelectOptionMapKeys.negotiationList]: (filterContent) => filterContent.negotiationId.toString(),
  [SelectOptionMapKeys.atsPeriodOrderList]: (filterContent) => filterContent.atsPeriodOrder && filterContent.atsPeriodOrder.toString()
};

export const filterContentMap = {
  [SelectOptionMapKeys.yearList]: (filterContent, filterValues) => {
    const fc = _getFilterContentValue(filterContent);
    const fv = _getFilterValue(filterValues);

    return _evaluateNegotiationId(fc, fv)
      && _evaluateATPeriodOrderList(fc, fv);
  },
  [SelectOptionMapKeys.negotiationList]: (filterContent, filterValues) => {
    const fc = _getFilterContentValue(filterContent);
    const fv = _getFilterValue(filterValues);

    return _evaluateYearList(fc, fv)
      && _evaluateATPeriodOrderList(fc, fv);
  },
  [SelectOptionMapKeys.atsPeriodOrderList]: (filterContent, filterValues) => {
    const fc = _getFilterContentValue(filterContent);
    const fv = _getFilterValue(filterValues);

    return _evaluateYearList(fc, fv)
      && _evaluateNegotiationId(fc, fv);
  }
};

const _getFilterContentValue = (filterContent) => ({
  year: filterContentValueGetterMap[SelectOptionMapKeys.yearList](filterContent),
  negotiationId: filterContentValueGetterMap[SelectOptionMapKeys.negotiationList](filterContent),
  atsPeriodOrder: filterContentValueGetterMap[SelectOptionMapKeys.atsPeriodOrderList](filterContent)
});

const _getFilterValue = (filterValues) => ({
  yearList: filterValues[InputNames.yearList],
  negotiationId: filterValues[InputNames.negotiationId],
  atsPeriodOrderList: filterValues[InputNames.atsPeriodOrderList]
});

const _evaluateYearList = (fc, fv) => {
  return Array.isArray(fv.yearList)
    ? fv.yearList.includes(fc.year)
    : true;
};

const _evaluateNegotiationId = (fc, fv) => {
  return fv.negotiationId != null
    ? fv.negotiationId === fc.negotiationId
    : true;
};

const _evaluateATPeriodOrderList = (fc, fv) => {
  return Array.isArray(fv.atsPeriodOrderList) && fv.atsPeriodOrderList.length > 0
    ? fv.atsPeriodOrderList.includes(fc.atsPeriodOrder)
    : true;
};