import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetProductionCapacityRatioFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_NEGOTIATIONS__GET_PRODUCTION_CAPACITY_RATIO);
  }

}

export default GetProductionCapacityRatioFunctionalityContext;