import React from 'react';
import PropTypes from 'prop-types';
import {COrderDTOVLD, PriceGuaranteeDTOVLD} from '@ovex/price-guarantee-web-api';

import {Code, FilterForm, Select} from '../../../../common/components/forms';
import {LsiContext} from '../../../../common/contexts';
import {dateTimeSecondsFormatter} from "../../../../common/utils/formatters";

const propTypes = {
  commissionNumber: PropTypes.string,
  commissionOrder: PropTypes.string,
  marketingActionCode: PropTypes.string,
  onLoadValidationResult: PropTypes.func.isRequired,
  onResetValidationResult: PropTypes.func.isRequired,
  orderList: PropTypes.arrayOf(PropTypes.instanceOf(COrderDTOVLD)),
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTOVLD))
};

const defaultProps = {
  commissionNumber: null,
  commissionOrder: null,
  marketingActionCode: null,
  orderList: null,
  priceGuaranteeList: null
};

const InputNames = {
  priceGuaranteeId: 'priceGuaranteeId',
  commissionNumber: 'commissionNumber',
  order: 'order',

  marketingActionCode: 'marketingActionCode'
};

const CommissionValidatorParams = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const formatters = lsi.getFormatters();

  const {
    commissionNumber, marketingActionCode,
    orderList, commissionOrder, priceGuaranteeList,
    onLoadValidationResult, onResetValidationResult } = props;

  const priceGuaranteeSelectItems = React.useMemo(
    () => {
      return priceGuaranteeList?.map(pg => ({ value: pg.id.toString(), content: pg.marketingActionCode, pgData: pg })) || [];
    },
    [priceGuaranteeList]
  );

  const orderSelectItems = React.useMemo(
      () => {
        return orderList?.map(o => ({ value: o.order.toString(), content: dateTimeSecondsFormatter(o.calculationDate, formatters) })) || [];
      },
      [orderList, formatters]
  );

  const priceGuarantee = priceGuaranteeList?.find(pg => pg.marketingActionCode === marketingActionCode);

  const handleSubmit = React.useCallback(
    (formValues) => {
      const pgIdString = formValues[InputNames.priceGuaranteeId];
      const pg = priceGuaranteeSelectItems?.find(item => item.value === pgIdString).pgData;

      onLoadValidationResult({
        priceGuaranteeId: pg.id,
        commissionNumber: formValues[InputNames.commissionNumber],
        order: formValues[InputNames.order],

        marketingActionCode: pg.marketingActionCode
      });
    },
    [onLoadValidationResult, priceGuaranteeSelectItems]
  );

  return (
    <FilterForm
      className='ovex-pg-CommissionValidatorParams'
      onReset={onResetValidationResult}
      onSubmit={handleSubmit}
    >
      <Select
        items={priceGuaranteeSelectItems}
        label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.PRICE_GUARANTEE')}
        name={InputNames.priceGuaranteeId}
        required
        value={priceGuarantee?.id.toString()}
        width='160px'
      />
      <Code
        label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.COMMISSION_NUMBER')}
        name={InputNames.commissionNumber}
        pattern='^[0-9]{6}$'
        required
        value={commissionNumber}
        width='160px'
      />
      <Select
          items={orderSelectItems}
          label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.ORDER')}
          name={InputNames.order}
          value={commissionOrder || orderSelectItems[0]?.value}
          width='200px'
      />
    </FilterForm>
  );
});

CommissionValidatorParams.propTypes = propTypes;
CommissionValidatorParams.defaultProps = defaultProps;

export default CommissionValidatorParams;
