
const PriceGuaranteeReportRowType = {
  HEADER: 'header',
  HEADER_WITH_ITEMS: 'headerWithItems',

  ITEM: 'item',

  isHeader: function (type) { return this.HEADER === type; },
  isHeaderWithItems: function (type) { return this.HEADER_WITH_ITEMS === type; },

  isItem: function (type) { return this.ITEM === type; },

  isHeaderType: function (type) { return this.isHeader(type) || this.isHeaderWithItems(type); }
};

export default PriceGuaranteeReportRowType;
