import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const priceGuaranteeItemsApi = ApiServiceInstances.priceGuarantee.priceGuaranteeItemsApi;

export const GET_PRICE_GUARANTEE_LIST_ITM_REQUEST = 'GET_PRICE_GUARANTEE_LIST_ITM_REQUEST';
export const GET_PRICE_GUARANTEE_LIST_ITM_SUCCESS = 'GET_PRICE_GUARANTEE_LIST_ITM_SUCCESS';
export const GET_PRICE_GUARANTEE_LIST_ITM_FAILURE = 'GET_PRICE_GUARANTEE_LIST_ITM_FAILURE';

export const GET_PRICE_GUARANTEE_ITEMS_ITM_REQUEST = 'GET_PRICE_GUARANTEE_ITEMS_ITM_REQUEST';
export const GET_PRICE_GUARANTEE_ITEMS_ITM_SUCCESS = 'GET_PRICE_GUARANTEE_ITEMS_ITM_SUCCESS';
export const GET_PRICE_GUARANTEE_ITEMS_ITM_FAILURE = 'GET_PRICE_GUARANTEE_ITEMS_ITM_FAILURE';

export const UPDATE_PRICE_GUARANTEE_ITEMS_ITM_REQUEST = 'UPDATE_PRICE_GUARANTEE_ITEMS_ITM_REQUEST';
export const UPDATE_PRICE_GUARANTEE_ITEMS_ITM_SUCCESS = 'UPDATE_PRICE_GUARANTEE_ITEMS_ITM_SUCCESS';
export const UPDATE_PRICE_GUARANTEE_ITEMS_ITM_FAILURE = 'UPDATE_PRICE_GUARANTEE_ITEMS_ITM_FAILURE';

export const CLEAR_PRICE_GUARANTEE_ITEMS_ITM = 'CLEAR_PRICE_GUARANTEE_ITEMS_ITM';

const initialState = {
  isFetching: false,
  error: null,
  priceGuaranteeList: null,
  priceGuaranteeItemsResponse: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRICE_GUARANTEE_LIST_ITM_REQUEST:
    case GET_PRICE_GUARANTEE_ITEMS_ITM_REQUEST:
    case UPDATE_PRICE_GUARANTEE_ITEMS_ITM_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_PRICE_GUARANTEE_LIST_ITM_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        priceGuaranteeList: action.payload
      };
    }
    case GET_PRICE_GUARANTEE_ITEMS_ITM_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        priceGuaranteeItemsResponse: action.payload
      };
    }
    case UPDATE_PRICE_GUARANTEE_ITEMS_ITM_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }
    case GET_PRICE_GUARANTEE_LIST_ITM_FAILURE:
    case GET_PRICE_GUARANTEE_ITEMS_ITM_FAILURE:
    case UPDATE_PRICE_GUARANTEE_ITEMS_ITM_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case CLEAR_PRICE_GUARANTEE_ITEMS_ITM: {
      return {
        ...state,
        priceGuaranteeItemsResponse: null
      };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetPriceGuaranteeListITM = () => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeItemsApi.getPriceGuaranteeListITMWithHttpInfo(),
      types: [GET_PRICE_GUARANTEE_LIST_ITM_REQUEST, GET_PRICE_GUARANTEE_LIST_ITM_SUCCESS, GET_PRICE_GUARANTEE_LIST_ITM_FAILURE]
    }
  };
};

export const handleGetPriceGuaranteeItemsITM = (priceGuaranteeId) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeItemsApi.getPriceGuaranteeItemsITMWithHttpInfo(priceGuaranteeId),
      types: [GET_PRICE_GUARANTEE_ITEMS_ITM_REQUEST, GET_PRICE_GUARANTEE_ITEMS_ITM_SUCCESS, GET_PRICE_GUARANTEE_ITEMS_ITM_FAILURE]
    }
  };
};

export const handleUpdateBulkPriceGuaranteeItemsITM = (bulkUpdateDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeItemsApi.updateBulkPriceGuaranteeItemsITMWithHttpInfo(bulkUpdateDTO),
      types: [UPDATE_PRICE_GUARANTEE_ITEMS_ITM_REQUEST, UPDATE_PRICE_GUARANTEE_ITEMS_ITM_SUCCESS, UPDATE_PRICE_GUARANTEE_ITEMS_ITM_FAILURE]
    }
  };
};

export const handleClearPriceGuaranteeItemsITM = () => {
  return {
    type: CLEAR_PRICE_GUARANTEE_ITEMS_ITM
  };
};
