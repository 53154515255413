import { PrivilegeApi } from '@ovex/privilege-web-api';
import { UserInfoApi } from '@ovex/about-web-api';
import { UserCredentialsApi } from '@ovex/support-web-api';

import ApiClientInstances from './ApiClientInstances';

/**
 * Api service instances
 */
const ApiServiceInstances = {
  support: {
    userCredentialsApi: new UserCredentialsApi(ApiClientInstances.support)
  },
  about: {
    userInfoApi: new UserInfoApi(ApiClientInstances.about),
    userCredentialsApi: new UserCredentialsApi(ApiClientInstances.support)
  },
  privilege: {
    privilegeApi: new PrivilegeApi(ApiClientInstances.privilege)
  }
};

export default ApiServiceInstances;
