import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  handleGetCommissionValidationResultImporterVLD,
  handleResetValidationResultVLD
} from '../../redux/modules/actions';
import routes from '../../routes';
import CommissionValidatorConnector from '../CommissionValidator/CommissionValidatorConnector';

const CommissionValidatorImporterReduxConnector = () => {
  const selectedState = useSelector(state => {
    return {
      pageStatus: state.priceGuarantee.commissionValidatorResult.pageStatus,
      validationResult: state.priceGuarantee.commissionValidatorResult.validationResult
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => ({
      handleGetCommissionValidationResultImporterVLD: (priceGuaranteeId, commissionNumber, order) => dispatch(handleGetCommissionValidationResultImporterVLD(priceGuaranteeId, commissionNumber, order)),
      handleResetValidationResultVLD: () => dispatch(handleResetValidationResultVLD())
    }),
    [dispatch]
  );

  return (
    <CommissionValidatorConnector
      onGetCommissionValidationResult={callbacks.handleGetCommissionValidationResultImporterVLD}
      onResetValidationResult={callbacks.handleResetValidationResultVLD}
      pageStatus={selectedState.pageStatus}
      routerPath={routes.paths.ROUTE_PATH_COMMISSION_VALIDATOR_IMPORTER}
      titleLsiCode='REPORT_COMMISSION_VALIDATOR_IMPORTER'
      validationResult={selectedState.validationResult}
    />
  );
};

export default CommissionValidatorImporterReduxConnector;
