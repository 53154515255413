import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { Checkbox, Code, WeekPicker } from '../../../../../common/components/forms';
import { Button, Modal } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import { ProductionCorridorRow } from '../../objects';

import './ModelGroupProductionCorridorForm.scss';

const ModelGroupProductionCorridorForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = !props.onSave;

  const [ weekFrom, setWeekFrom ] = React.useState(props.modelGroupProductionCorridor.weekFrom);
  const [ weekTo, setWeekTo ] = React.useState(props.modelGroupProductionCorridor.weekTo);
  const [ annualTarget, setAnnualTarget ] = React.useState(props.modelGroupProductionCorridor.annualTarget);
  const [ stockCar, setStockCar ] = React.useState(props.modelGroupProductionCorridor.stockCar);

  const handleSave = () => {
    props.onSave({
      id: props.modelGroupProductionCorridor.id,
      modelGroup: props.modelGroupProductionCorridor.modelGroup,

      weekFrom: weekFrom.toUpperCase(),
      weekTo: weekTo.toUpperCase(),
      annualTarget: annualTarget,
      stockCar: stockCar
    });
  };

  return (
    <Modal
      className='ovex-pvt-ModelGroupProductionCorridorForm'
      header={readOnly ? lsi.getLSIItem('PVT.PAGE_TITLE.MODEL_GROUP_DETAIL') : lsi.getLSIItem('PVT.PAGE_TITLE.MODEL_GROUP_UPDATE')}
      onClose={props.onClose}
      shown={props.shown}
    >
      <Forms.Form
        onCancel={props.onClose}
        onSave={handleSave}
      >
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.GROUP')}
          maxLength={3}
          name='modelGroup'
          pattern='^[a-zA-Z0-9]{3}$'
          readOnly
          upperCase
          value={props.modelGroupProductionCorridor.modelGroup}
        />
        <WeekPicker
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_FROM')}
          name='weekFrom'
          onChange={setWeekFrom}
          readOnly={readOnly}
          required
          value={weekFrom}
          weekTo={weekTo}
        />
        <WeekPicker
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_TO')}
          name='weekTo'
          onChange={setWeekTo}
          readOnly={readOnly}
          required
          value={weekTo}
          weekFrom={weekFrom}
        />
        <Checkbox
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.ANNUAL_TARGETS')}
          labelPosition='right'
          name='annualTarget'
          onChange={setAnnualTarget}
          readOnly={readOnly}
          tooltip={lsi.getLSIItem('PVT.TOOLTIP.ANNUAL_TARGETS_CHECKED')}
          value={annualTarget}
        />
        <Checkbox
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.STOCK_CARS')}
          labelPosition='right'
          name='stockCar'
          onChange={setStockCar}
          readOnly={readOnly}
          tooltip={lsi.getLSIItem('PVT.TOOLTIP.STOCK_CAR_CHECKED')}
          value={stockCar}
        />
        {!readOnly &&
          <Forms.Controls
            buttonCancelProps={Button.formControlsProps[Button.type.SECONDARY]}
            buttonSubmitProps={Button.formControlsProps[Button.type.PRIMARY]}
          />
        }
      </Forms.Form>
    </Modal>
  );
});

ModelGroupProductionCorridorForm.propTypes = {
  modelGroupProductionCorridor: PropTypes.instanceOf(ProductionCorridorRow).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  shown: PropTypes.bool
};

ModelGroupProductionCorridorForm.defaultProps = {
  onSave: undefined,
  shown: false
};

export default ModelGroupProductionCorridorForm;
