
class LsiObject {
  /**
   * Constructor for Lsi context type definition
   * @param {function} getLocale function to retrieve current locale
   * @param {function} getLSIItem function to get lsi item
   * @param {function} getFormatters function to get formatters
   */
  constructor(getLocale, getLSIItem, getFormatters) {
    this.getLocale = getLocale;
    this.getLSIItem = getLSIItem;
    this.getFormatters = getFormatters;
  }

}

export default LsiObject;