import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetDealerContractsListFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__GET_DEALERS_CONTRACTS);
  }

}

export default GetDealerContractsListFunctionalityContext;