import { mdiFileExportOutline } from '@mdi/js';

import MenuItemDefBuilder from './MenuItemDefBuilder';

export const getExportExcelSkipRowGroupsItem = (params, exportParams = null) => {
  return new MenuItemDefBuilder(params, 'AG_GRID.excelExport')
    .iconFromPath(mdiFileExportOutline)
    .action(() => params.api.exportDataAsExcel(exportParams || {
      columnKeys: params.context.exportColumnKeys,
      skipRowGroups: true
    }))
    .build();
};
