import React from 'react';
import PropTypes from 'prop-types';
import { PriceGuaranteeDTO } from '@ovex/price-guarantee-web-api';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';

import { columnTypes, getColumnDefinitions } from './PriceGuaranteeTable.columnDefs';
import { getContextMenuItems } from './PriceGuaranteeTable.contextMenu';

import './PriceGuaranteeTable.scss';

const propTypes = {
  onShiftPriceGuaranteeStatus: PropTypes.func,
  onShowPGDetail: PropTypes.func,
  onShowPriceGuaranteeUpdateModalForm: PropTypes.func,
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTO))
};

const defaultProps = {
  onShiftPriceGuaranteeStatus: null,
  onShowPGDetail: null,
  onShowPriceGuaranteeUpdateModalForm: null,
  priceGuaranteeList: null
};

const PriceGuaranteeTable = (props) => {
  const lsi = React.useContext(LsiContext);

  return (
    <OvexAGTable
      agContext={{
        onShiftPriceGuaranteeStatus: props.onShiftPriceGuaranteeStatus,
        onShowPriceGuaranteeUpdateModalForm: props.onShowPriceGuaranteeUpdateModalForm,
        onShowPGDetail: props.onShowPGDetail
      }}
      className={'ovex-pg-PriceGuaranteeTable'}
      columnDefs={getColumnDefinitions(lsi)}
      columnTypes={columnTypes}
      getContextMenuItems={getContextMenuItems}
      height='640px'
      rowData={props.priceGuaranteeList}
    />
  );
};

PriceGuaranteeTable.propTypes = propTypes;
PriceGuaranteeTable.defaultProps = defaultProps;

export default PriceGuaranteeTable;
