import React from 'react';

import SwapCommissionPvtConnector from '../SwapCommissionPvt/SwapCommissionPvtConnector';
import { handleGetCommissionSwapImporter, handleSwapCommissionsPvtImporter } from '../../redux/modules/actions';

const SwapCommissionPvtImporterConnector = () => {

  return (
    <SwapCommissionPvtConnector
      onGetCommissionListAction={handleGetCommissionSwapImporter}
      onSwapCommissionAction={handleSwapCommissionsPvtImporter}
    />
  );
};

export default SwapCommissionPvtImporterConnector;