import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetDealerContractsATSPeriodsViewListFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_VIEWS__GET_DEALER_CONTRACTS_ATS_PERIODS);
  }

}

export default GetDealerContractsATSPeriodsViewListFunctionalityContext;