import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';
import ContractWorkflow from '../../workflow/ContractWorkflow';
import PrivilegeWebResult from '../../../common/privilege/PrivilegeWebResult';

class ShiftDealerContractWorkflowFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} contractId
   * @param {string} contractStatusTo
   * @param {object} webParams
   */
  constructor(contractId, contractStatusTo, webParams = null) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__CONTRACT_SHIFT_STATE);
    this.setParams({
      [ContextConstants.CONTRACT_ID]: contractId,
      [ContextConstants.CONTRACT_STATUS_TO]: contractStatusTo
    });

    this.setWebParams(webParams);
  }

  /**
   * @override
   */
  evaluateWebResult = () => {
    const webParams = this._webParams;
    const reasonList = [];

    if (webParams.hasOwnProperty('contractStatus') && webParams.contractStatus != null) {
      const status = webParams.contractStatus;
      const contractStatusTo = this._params[ContextConstants.CONTRACT_STATUS_TO];

      if (!ContractWorkflow.isValidFollowingState(status, contractStatusTo)) {
        reasonList.push(`wrong contractStatusTo={${contractStatusTo}} for contract in status={${status}}`);
      }
    }

    if (reasonList.length > 0) {
      this._webResult = new PrivilegeWebResult(reasonList.join(' && '));
    }
  };

}

export default ShiftDealerContractWorkflowFunctionalityContext;