// widths are in 'px' unit
const groupColumnWidth = 260;
const columnWidth = 128;

export const COLUMN_GROUP_IDS = {
  STATISTICS_WORKING_GROUP: 'statistics_workingGroup',
  STATISTICS_APPROVED_GROUP: 'statistics_approvedGroup',
  STATISTICS_DONE_GROUP: 'statistics_doneGroup',
  STATISTICS_DONE_PERCENT_GROUP: 'statistics_donePercentGroup',
  STATISTICS_REMAINING_GROUP: 'statistics_remainingGroup',
  STATISTICS_POTENTIAL_GROUP: 'statistics_potentialGroup'
};

export const COLUMN_IDS = {
  MODEL_GROUP: 'modelGroup',
  BRANCH_DEALER_NUMBER: 'branchDealerNumber',
  PERIOD: 'period',

  AT_ATS_DEALER_NUMBER: 'at_ats_dealerNumber',
  AT_ATS_RECOMMENDED_NUMBER: 'at_ats_recommendedNumber',
  AT_ATS_APPROVAL_NUMBER: 'at_ats_approvalNumber',
  AT_ATS_APPROVED_NUMBER: 'at_ats_approvedNumber',

  STATISTICS_INVOICED_COUNT: 'statistics_invoicedCount',
  STATISTICS_PRODUCTION_COUNT: 'statistics_productionCount',
  STATISTICS_PLANNED_COUNT: 'statistics_plannedCount',
  STATISTICS_DONE_NUMBER: 'statistics_doneNumber',
  STATISTICS_DONE_PERCENTAGE: 'statistics_DonePercentage',
  STATISTICS_REMAINING_NUMBER: 'statistics_remainingNumber',
  STATISTICS_POTENTIAL_PRODUCTION_COUNT: 'statistics_potentialProductionCount',
  STATISTICS_POTENTIAL_PLANNED_COUNT: 'statistics_potentialPlannedCount',
  STATISTICS_POTENTIAL_COUNT: 'statistics_potentialCount'
};

export const tableBaseColumnDefs = {
  [COLUMN_IDS.MODEL_GROUP]: {
    colId: COLUMN_IDS.MODEL_GROUP,
    width: groupColumnWidth
  },
  [COLUMN_IDS.BRANCH_DEALER_NUMBER]: {
    colId: COLUMN_IDS.BRANCH_DEALER_NUMBER,
    width: groupColumnWidth
  },
  [COLUMN_IDS.PERIOD]: {
    colId: COLUMN_IDS.PERIOD,
    width: 104
  },

  [COLUMN_IDS.AT_ATS_DEALER_NUMBER]: {
    colId: COLUMN_IDS.AT_ATS_DEALER_NUMBER,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-working']
  },
  [COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER]: {
    colId: COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-working']
  },
  [COLUMN_IDS.AT_ATS_APPROVAL_NUMBER]: {
    colId: COLUMN_IDS.AT_ATS_APPROVAL_NUMBER,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-working']
  },
  [COLUMN_IDS.AT_ATS_APPROVED_NUMBER]: {
    colId: COLUMN_IDS.AT_ATS_APPROVED_NUMBER,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-approved']
  },

  [COLUMN_IDS.STATISTICS_INVOICED_COUNT]: {
    colId: COLUMN_IDS.STATISTICS_INVOICED_COUNT,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    columnGroupShow: 'open'
  },
  [COLUMN_IDS.STATISTICS_PRODUCTION_COUNT]: {
    colId: COLUMN_IDS.STATISTICS_PRODUCTION_COUNT,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    columnGroupShow: 'open'
  },
  [COLUMN_IDS.STATISTICS_PLANNED_COUNT]: {
    colId: COLUMN_IDS.STATISTICS_PLANNED_COUNT,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    columnGroupShow: 'open'
  },
  [COLUMN_IDS.STATISTICS_DONE_NUMBER]: {
    colId: COLUMN_IDS.STATISTICS_DONE_NUMBER,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics']
  },
  [COLUMN_IDS.STATISTICS_DONE_PERCENTAGE]: {
    colId: COLUMN_IDS.STATISTICS_DONE_PERCENTAGE,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics']
  },
  [COLUMN_IDS.STATISTICS_REMAINING_NUMBER]: {
    colId: COLUMN_IDS.STATISTICS_REMAINING_NUMBER,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics']
  },
  [COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT]: {
    colId: COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    columnGroupShow: 'open'
  },
  [COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT]: {
    colId: COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    columnGroupShow: 'open'
  },
  [COLUMN_IDS.STATISTICS_POTENTIAL_COUNT]: {
    colId: COLUMN_IDS.STATISTICS_POTENTIAL_COUNT,
    width: columnWidth,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics']
  }
};

export const tableBaseColumnGroupDefs = {
  [COLUMN_GROUP_IDS.STATISTICS_WORKING_GROUP]: {
    groupId: COLUMN_GROUP_IDS.STATISTICS_WORKING_GROUP,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-working'],
    marryChildren: true
  },
  [COLUMN_GROUP_IDS.STATISTICS_APPROVED_GROUP]: {
    groupId: COLUMN_GROUP_IDS.STATISTICS_APPROVED_GROUP,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-approved'],
    marryChildren: true
  },
  [COLUMN_GROUP_IDS.STATISTICS_DONE_GROUP]: {
    groupId: COLUMN_GROUP_IDS.STATISTICS_DONE_GROUP,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    marryChildren: true
  },
  [COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP]: {
    groupId: COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    marryChildren: true
  },
  [COLUMN_GROUP_IDS.STATISTICS_REMAINING_GROUP]: {
    groupId: COLUMN_GROUP_IDS.STATISTICS_REMAINING_GROUP,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    marryChildren: true
  },
  [COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_GROUP]: {
    groupId: COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_GROUP,
    headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerAnnualTarget-column-statistics'],
    marryChildren: true
  }
};

export const AT_SALES_EXPORT_COLUMN_KEYS = [
  COLUMN_IDS.BRANCH_DEALER_NUMBER,
  COLUMN_IDS.PERIOD,

  COLUMN_IDS.AT_ATS_DEALER_NUMBER,
  COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER,
  COLUMN_IDS.AT_ATS_APPROVAL_NUMBER,
  COLUMN_IDS.AT_ATS_APPROVED_NUMBER,

  COLUMN_IDS.STATISTICS_INVOICED_COUNT,
  COLUMN_IDS.STATISTICS_PRODUCTION_COUNT,
  COLUMN_IDS.STATISTICS_PLANNED_COUNT,
  COLUMN_IDS.STATISTICS_DONE_NUMBER,
  COLUMN_IDS.STATISTICS_DONE_PERCENTAGE,
  COLUMN_IDS.STATISTICS_REMAINING_NUMBER,
  COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT,
  COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT,
  COLUMN_IDS.STATISTICS_POTENTIAL_COUNT
];

export const COMMISSION_LIST_AT_AVAILABLE_COLUMN_IDS = [
  COLUMN_IDS.STATISTICS_INVOICED_COUNT,
  COLUMN_IDS.STATISTICS_PRODUCTION_COUNT,
  COLUMN_IDS.STATISTICS_PLANNED_COUNT,
  COLUMN_IDS.STATISTICS_DONE_NUMBER,

  COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT,
  COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT,
  COLUMN_IDS.STATISTICS_POTENTIAL_COUNT
];

export const COMMISSION_LIST_ATS_AVAILABLE_COLUMN_IDS = [
  COLUMN_IDS.STATISTICS_DONE_NUMBER
];
