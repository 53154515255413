import { mdiTextBoxOutline } from '@mdi/js';
import { CommissionLoadParamsDTO, CommissionLoadATParamsDTO } from '@ovex/annual-target-web-api';

import { handleGetDefaultContextMenuItems, MenuItemDefBuilder } from '../../../../common/components/ag-grid';
import { COLUMN_IDS, COMMISSION_LIST_AT_AVAILABLE_COLUMN_IDS } from '../utils/tableBaseColumnDefs';
import { AggregationTypeEnum } from '../../../utils/const';
import { AG_GROUP_TOTAL } from '../utils/const';

export const handleGetContextMenuItems = (getContextMenuItemsParams ) => {
  return [
    DealerModelGroupAnnualTargetMenuItems.getShowCommissionListItem(getContextMenuItemsParams),
    'separator',
    ...handleGetDefaultContextMenuItems(getContextMenuItemsParams)
  ];
};

const DealerModelGroupAnnualTargetMenuItems = {
  getShowCommissionListItem: (getContextMenuItemsParams) => {
    const { column, context, node } = getContextMenuItemsParams;
    const { onShowCommissionListWithParams, statisticsRequestId, statisticsType, negotiationType } = context;

    const colId = column.getColId();
    const isAvailable = onShowCommissionListWithParams != null
      && COMMISSION_LIST_AT_AVAILABLE_COLUMN_IDS.includes(colId);

    if (!isAvailable) {
      return null;
    }

    return new MenuItemDefBuilder(getContextMenuItemsParams, 'ANNUAL_TARGET.CONTEXT_MENU.SHOW_COMMISSION_LIST')
      .iconFromPath(mdiTextBoxOutline)
      .action(() => {
        const params = new CommissionLoadParamsDTO(_getAggregationTypeList(colId), statisticsRequestId, statisticsType, negotiationType);
        params.atParams = new CommissionLoadATParamsDTO();

        if (node.group && node.key === AG_GROUP_TOTAL) {
          params.atParams.contractAtIdList = node.allLeafChildren.map(rowNode => rowNode.data.id);
        } else if (node.group) {
          params.atParams.contractAtPeriodIdList = node.allLeafChildren.map(rowNode => rowNode.data.id);
        } else if (node.data.isTotalRow()) {
          params.atParams.contractAtIdList = [node.data.id];
        } else if (node.data.isPeriodRow()) {
          params.atParams.contractAtPeriodIdList = [node.data.id];
        }

        onShowCommissionListWithParams(params);
      })
      .build();
  }
};

const _getAggregationTypeList = (colId) => {
  switch (colId) {
    case COLUMN_IDS.STATISTICS_INVOICED_COUNT:
      return [AggregationTypeEnum.AT_INVOICED];
    case COLUMN_IDS.STATISTICS_PRODUCTION_COUNT:
      return [AggregationTypeEnum.AT_PRODUCTION];
    case COLUMN_IDS.STATISTICS_PLANNED_COUNT:
      return [AggregationTypeEnum.AT_PLANNED];
    case COLUMN_IDS.STATISTICS_DONE_NUMBER:
      return [AggregationTypeEnum.AT_INVOICED, AggregationTypeEnum.AT_PRODUCTION, AggregationTypeEnum.AT_PLANNED];
    case COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT:
      return [AggregationTypeEnum.AT_POTENTIAL_PRODUCTION];
    case COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT:
      return [AggregationTypeEnum.AT_POTENTIAL_PLANNED];
    case COLUMN_IDS.STATISTICS_POTENTIAL_COUNT:
      return [AggregationTypeEnum.AT_POTENTIAL_PRODUCTION, AggregationTypeEnum.AT_POTENTIAL_PLANNED];
    default:
  }
};
