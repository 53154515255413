import { ApiServiceInstances } from '../../../../api';
import { CALL_API } from '../../../../../../redux/middleware';

const userInfoApi = ApiServiceInstances.about.userInfoApi;
const userCredentialsApi = ApiServiceInstances.support.userCredentialsApi;

export const GET_USER_INFO_REQUEST = 'SUPPORT__GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'SUPPORT__GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'SUPPORT__GET_USER_INFO_FAILURE';

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

const initialState = {
  userInfo: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO_REQUEST:
    case GET_CURRENT_USER_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_USER_INFO_SUCCESS: {
      return { ...state, isFetching: false, userInfo: action.payload };
    }
    case GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        userInfo: { ...state.userInfo, ...action.payload.userInfo },
        modification: action.payload.modification
      };
    }
    case GET_USER_INFO_FAILURE:
    case GET_CURRENT_USER_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    default:
      return state;
  }
};

export const handleGetUserInfo = () => {
  return {
    [CALL_API]: {
      apiFunction: () => userInfoApi.getUserInfoWithHttpInfo(),
      types: [GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE]
    }
  };
};

export const handleGetUserInfoCurrent = () => {
  return {
    [CALL_API]: {
      apiFunction: () => userCredentialsApi.getUserInfoCurrentWithHttpInfo(),
      types: [GET_CURRENT_USER_REQUEST, GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_FAILURE]
    }
  };
};
