
class CommissionRow {

}

CommissionRow.prototype.aggregationType = undefined;
CommissionRow.prototype.commission = undefined;
CommissionRow.prototype.commissionYear = undefined;
CommissionRow.prototype.ownerDealerNumber = undefined;
CommissionRow.prototype.branchOwnerDealerNumber = undefined;
CommissionRow.prototype.modelGroup = undefined;
CommissionRow.prototype.status = undefined;
CommissionRow.prototype.statusInterval = undefined;
CommissionRow.prototype.insertTypeFactoryStock = undefined;
CommissionRow.prototype.alwaysQ4 = undefined;

// optional attributes
CommissionRow.prototype.invoicedDealerNumber = undefined;
CommissionRow.prototype.preProductionWeek = undefined;
CommissionRow.prototype.preProductionWeekYear = undefined;
CommissionRow.prototype.preProductionWeekUnitOrder = undefined;
CommissionRow.prototype.productionWeek = undefined;
CommissionRow.prototype.productionWeekYear = undefined;
CommissionRow.prototype.productionWeekUnitOrder = undefined;
CommissionRow.prototype.invoicingDate = undefined;
CommissionRow.prototype.invoicingDateYear = undefined;
CommissionRow.prototype.invoicingDateUnitOrder = undefined;
CommissionRow.prototype.sellingDate = undefined;
CommissionRow.prototype.sellingDateYear = undefined;
CommissionRow.prototype.sellingDateUnitOrder = undefined;

export default CommissionRow;
