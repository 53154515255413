import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';
import UserImitationValidatorDzcExists from '../../../layouts/UserImitateModal/UserImitationValidatorDzcExists';

const supportApi = ApiServiceInstances.support.userCredentialsApi;

export const GET_USER_INFO_ROLE_EVALUATION_REQUEST = 'GET_USER_INFO_ROLE_EVALUATION_REQUEST';
export const GET_USER_INFO_ROLE_EVALUATION_SUCCESS = 'GET_USER_INFO_ROLE_EVALUATION_SUCCESS';
export const GET_USER_INFO_ROLE_EVALUATION_FAILURE = 'GET_USER_INFO_ROLE_EVALUATION_FAILURE';
export const CLEAR_DATA = 'CLEAR_DATA';

const initialState = {
  validationStatus: UserImitationValidatorDzcExists.EMPTY,
  userImitation: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO_ROLE_EVALUATION_REQUEST: {
      return { ...state, validationStatus: UserImitationValidatorDzcExists.EMPTY, isFetching: true };
    }
    case GET_USER_INFO_ROLE_EVALUATION_SUCCESS: {
      const validationStatus = action.payload != null ? UserImitationValidatorDzcExists.DATA_FOUND : UserImitationValidatorDzcExists.NOT_FOUND;
      return { ...state, isFetching: false, validationStatus: validationStatus, userImitation: action.payload };
    }
    case GET_USER_INFO_ROLE_EVALUATION_FAILURE: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    case CLEAR_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export const handleGetUserInfoWithRoleEvaluation = (loginUid) => {
  return {
    [CALL_API]: {
      apiFunction: supportApi.getUserInfoWithRoleEvaluationWithHttpInfo.bind(supportApi, loginUid),
      types: [GET_USER_INFO_ROLE_EVALUATION_REQUEST, GET_USER_INFO_ROLE_EVALUATION_SUCCESS, GET_USER_INFO_ROLE_EVALUATION_FAILURE]
    }
  };
};

export const handleClearData = () => {
  return {
    type: CLEAR_DATA
  };
};
