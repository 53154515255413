
/**
 * Typy objektu {@see module:model/ProductionCorridorDTO}
 *
 * @enum
 */
const ProductionCorridorTypeEnum = {

  /**
   * Typ "MODEL_GROUP_ROW" je označní pro omezení, které má nastaveny pouze vlastnosti: **brand**, **bid**, **modelGroup**, **annualTarget**, **stockCar**.
   */
  MODEL_GROUP_ROW: 'MODEL_GROUP_ROW',

  /**
   * Typ "LIMITATION_ROW" je označení pro všechny objekty {@see module:model/RestrictionDTO}, kromě "modelových skpin".
   * Omezení by neměla mít nastavené vlastnosti: **annualTarget**, **stockCar**.
   */
  LIMITATION_ROW: 'LIMITATION_ROW',

  /**
   * @param {module:model/ProductionCorridorDTO} producionCorridor
   */
  valueOf: (producionCorridor) => {
    if (producionCorridor == null) {
      return null;
    }
    if (producionCorridor.modelMask == null) {
      return ProductionCorridorTypeEnum.MODEL_GROUP_ROW;
    }
    return ProductionCorridorTypeEnum.LIMITATION_ROW;
  },

  /**
   * @param {ProductionCorridorTypeEnum} type
   * @return {boolean}
   */
  isModelGroupRow: (type) => type === ProductionCorridorTypeEnum.MODEL_GROUP_ROW,

  /**
   * @param {ProductionCorridorTypeEnum} type
   * @return {boolean}
   */
  isLimitationRow: (type) => type === ProductionCorridorTypeEnum.LIMITATION_ROW
};

export default ProductionCorridorTypeEnum;