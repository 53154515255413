import { modulePathPrefix } from '../config/moduleConfig';

const modulePaths = {
  ROUTE_PATH_COMMISSION_VALIDATOR_DEALER: modulePathPrefix + '/dealer/commission-validator-dealer',
  ROUTE_PATH_COMMISSION_VALIDATOR_IMPORTER: modulePathPrefix + '/importer/commission-validator-importer',
  ROUTE_PATH_PRICE_GUARANTEE_ITEMS_LIST: modulePathPrefix + '/price-guarantee-items',
  ROUTE_PATH_PRICE_GUARANTEE_ITEMS_PUBLISHED_LIST: modulePathPrefix + '/price-guarantee-items-published',
  ROUTE_PATH_PRICE_GUARANTEE_LIST: modulePathPrefix + '/price-guarantees',
  ROUTE_PATH_PRICE_GUARANTEE_REPORT_DEALER: modulePathPrefix + '/dealer/price-guarantee-report',
  ROUTE_PATH_PRICE_GUARANTEE_REPORT_IMPORTER: modulePathPrefix + '/importer/price-guarantee-report',
  ROUTE_PATH_PRICE_GUARANTEE_REFRESH_STORAGE_DATA: modulePathPrefix + '/refresh-storage-data'
};

export default modulePaths;
