import React from 'react';

import CommissionListConnector from '../CommissionList/CommissionListConnector';
import { handleGetCommissionListForImporter } from '../../redux/modules/actions';

const CommissionListImporterConnector = () => {

  return (
    <CommissionListConnector
      onGetCommissionListReduxFn={handleGetCommissionListForImporter}
      titleLsiCode='COMMISSION_LIST_IMPORTER'
    />
  );
};

export default CommissionListImporterConnector;
