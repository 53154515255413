import { COLUMN_IDS, tableBaseColumnDefs, COLUMN_GROUP_IDS, tableBaseColumnGroupDefs } from '../utils/tableBaseColumnDefs';
import { handleEditableRecommendedNumberColumn, handleInnerRendererTOTAL, handleNumberValueFormatter, handleRecommendedValueParser, handleRecommendedValueSetter } from '../utils/helpers';
import { handleDonePercentNumberAggFunc, handleDonePercentNumberValueGetter } from '../../../utils/statisticsHelpers';
import { sumNullableAccumulator } from '../../../utils/helpers';
import { quarterPeriodFormatter } from '../../../../common/utils/formatters';
import { ClientSideRowModelSteps } from '../../../../common/components/ag-grid';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  DATA_CD: 'dataDefinitionType'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.DATA_CD]: {
    cellClass: ['ovex-PeriodAnnualTargetSalesTable-cell-numeric'],
    valueFormatter: handleNumberValueFormatter,
    aggFunc: 'sum'
  }
};

export const getAutoGroupColumnDef = (lsi) => {
  return {
    minWidth: tableBaseColumnDefs[COLUMN_IDS.BRANCH_DEALER_NUMBER].minWidth,
    width: tableBaseColumnDefs[COLUMN_IDS.BRANCH_DEALER_NUMBER].width,
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BRANCH_PERIOD_RCP'),
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    valueGetter: 'data && data.periodOrder',
    valueFormatter: (valueFormatterParams) => quarterPeriodFormatter(valueFormatterParams.value),
    cellRendererParams: {
      innerRenderer: handleInnerRendererTOTAL,
      suppressCount: true
    }
  };
};

const getPeriodSumHandle = (attribute) => (params) => {
  const { node, data, api } = params;
  if (node.group) {
    return null;
  }
  if (data.isTotalRow()) {
    let sumValue = null;
    api.forEachLeafNode(rowNode => {
      if (data.periodOrder === rowNode.data.periodOrder) {
        sumValue = sumNullableAccumulator(sumValue, rowNode.data[attribute]);
      }
    });
    return sumValue;
  } else {
    return data[attribute];
  }
};

const getPeriodSumDealerNumber = getPeriodSumHandle('atsDealerNumber');
const getPeriodSumRecommendedNumber = getPeriodSumHandle('atsRecommendedNumber');
const getPeriodSumApprovalNumber = getPeriodSumHandle('atsApprovalNumber');
const getPeriodSumApprovedNumber = getPeriodSumHandle('atsApprovedNumber');
const getPeriodSumDoneNumber = getPeriodSumHandle('atsStatisticsSoldCount');
const getPeriodSumRemainsNumber = getPeriodSumHandle('atsStatisticsRemainsCount');

export const getColumnDefinitions = (lsi, doneCountGroupExpanded) => [
  {
    valueGetter: 'data && data.groupValue',
    rowGroup: true,
    hide: true
  },
  {
    ...tableBaseColumnDefs[COLUMN_IDS.BRANCH_DEALER_NUMBER],
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BRANCH_PERIOD_RCP'),
    valueGetter: 'data && data.dealerNumberCode',
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    hide: true
  },
  {
    ...tableBaseColumnDefs[COLUMN_IDS.PERIOD],
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PERIOD'),
    valueGetter: 'data && data.periodOrder',
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    cellClass: ['ovex-PeriodAnnualTargetSalesTable-cell-period'],
    hide: true,
    sortable: false
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_WORKING_GROUP],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_DEALER_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
        valueGetter: getPeriodSumDealerNumber,
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
        valueGetter: getPeriodSumRecommendedNumber,
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        cellClassRules: {
          'ovex-PeriodAnnualTargetSalesTable-cell-numeric--editable': handleEditableRecommendedNumberColumn,
          'mdi': handleEditableRecommendedNumberColumn,
          'ovex-PeriodAnnualTargetSalesTable-cell-numeric--modified': (cellClassParams) => !cellClassParams.node.group && cellClassParams.data.isATSRecommendedNumberModified()
        },
        editable: handleEditableRecommendedNumberColumn,
        valueParser: handleRecommendedValueParser,
        valueSetter: (valueSetterParams) => {
          const dataChanged = handleRecommendedValueSetter(valueSetterParams);
          dataChanged && valueSetterParams.api.refreshClientSideRowModel(ClientSideRowModelSteps.AGGREGATE);
          return dataChanged;
        },
        valueFormatter: handleNumberValueFormatter
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVAL_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
        valueGetter: getPeriodSumApprovalNumber,
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_APPROVED_GROUP],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVED_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
        valueGetter: getPeriodSumApprovedNumber,
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_GROUP],
    headerClass: [],
    openByDefault: doneCountGroupExpanded,
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_INVOICED_COUNT],
        headerClass: [],
        menuTabs: []
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PRODUCTION_COUNT],
        headerClass: [],
        menuTabs: []
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PLANNED_COUNT],
        headerClass: [],
        menuTabs: []
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_NUMBER],
        valueGetter: getPeriodSumDoneNumber,
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_PERCENTAGE],
        valueGetter: (valueGetterParams) => {
          if (valueGetterParams.data.isTotalRow()) {
            const atsApprovedNumber = valueGetterParams.getValue(COLUMN_IDS.AT_ATS_APPROVED_NUMBER);
            const atsStatisticsSoldCount = valueGetterParams.getValue(COLUMN_IDS.STATISTICS_DONE_NUMBER);
            return handleDonePercentNumberValueGetter(valueGetterParams, atsApprovedNumber, atsStatisticsSoldCount);
          }
          return handleDonePercentNumberValueGetter(valueGetterParams, valueGetterParams.data.atsApprovedNumber, valueGetterParams.data.atsStatisticsSoldCount);
        },
        valueFormatter: null,
        aggFunc: handleDonePercentNumberAggFunc,
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_REMAINING_GROUP],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_REMAINING_NUMBER],
        valueGetter: getPeriodSumRemainsNumber,
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_GROUP],
    headerName: [],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT],
        headerClass: [],
        menuTabs: []
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT],
        headerClass: [],
        menuTabs: []
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_COUNT],
        headerClass: [],
        menuTabs: []
      }
    ]
  }
];
