import { pairStringValueFormatter, dateFormatter, number2Formatter } from '../../../../common/utils/formatters';

import PriceGuaranteeReportRowType from './rowModel/PriceGuaranteeReportRowType';
import { availableValuesForRowTypeWithItemColumnIdList } from './PriceGuaranteeReportTable.columnIds';
import { isCellProcessingExportToExcel } from './PriceGuaranteeReportTable.helpers';

export const dateValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  valueFormatterParams.value = dateFormatter(valueFormatterParams.value, formatters);

  return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
};

export const amountValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  valueFormatterParams.value = number2Formatter(valueFormatterParams.value, formatters);

  return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
};

export const accountTypeFormatter = (valueFormatterParams) => {
  if (valueFormatterParams.value) {
    valueFormatterParams.value = valueFormatterParams.context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.SALES_ACCOUNT_TYPE.${valueFormatterParams.value}`);

    return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
  }
  return null;
};

export const commissionClaimTypeFormatter = (valueFormatterParams) => {
  const { context, value } = valueFormatterParams;

  valueFormatterParams.value = context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_HEADER.${value}`);

  return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
};
export const commissionPGIClaimTypeFormatter = (valueFormatterParams) => {
  const { context, value, data } = valueFormatterParams;

  if (PriceGuaranteeReportRowType.isHeaderType(data.getRowType())) {
    return context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_HEADER.${value}`);
  }
  if (PriceGuaranteeReportRowType.isItem(data.getRowType())) {
    return context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_ITEM.${value}`);
  }
  return '';
};
export const cOrderModeFormatter = (valueFormatterParams) => {
  if (valueFormatterParams.value) {
    valueFormatterParams.value = valueFormatterParams.context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.C_ORDER_MODE.${valueFormatterParams.value}`);

    return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
  }
  return null;
};
export const cohPartStatusFormatter = (valueFormatterParams) => {
  if (valueFormatterParams.value) {
    valueFormatterParams.value = valueFormatterParams.context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.COH_PART_STATUS.${valueFormatterParams.value}`);

    return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
  }
  return null;
};
export const pgItemClaimTypeFormatter = (valueFormatterParams) => {
  if (valueFormatterParams.value) {
    valueFormatterParams.value = valueFormatterParams.context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_ITEM.${valueFormatterParams.value}`);

    return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
  }
  return null;
};

export const dealerAreaFormatter = (valueFormatterParams) => {
  valueFormatterParams.value = pairStringValueFormatter(valueFormatterParams.value.dealerAreaCode, valueFormatterParams.value.dealerAreaName);

  return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
};

export const clientNameFormatter = (valueFormatterParams) => {
  valueFormatterParams.value = pairStringValueFormatter(valueFormatterParams.value.clientName, valueFormatterParams.value.clientSurname, ' ');

  return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
};

export const marketingActionCodeOtherFormatter = (valueFormatterParams) => {
  valueFormatterParams.value = valueFormatterParams.value?.join(', ');

  return availableValuesForRowTypeWithItemFormatter(valueFormatterParams);
};

export const availableValuesForRowTypeWithItemFormatter = (valueFormatterParams, autoColumn = false) => {
  const { column, data, value } = valueFormatterParams;
  const rowType = data.getRowType();

  if (!isCellProcessingExportToExcel(valueFormatterParams)) {
    if (PriceGuaranteeReportRowType.isHeaderType(rowType)) {
      return value;
    }
    if (PriceGuaranteeReportRowType.isItem(rowType) && availableValuesForRowTypeWithItemColumnIdList.includes(column.getColId())) {
      return value;
    }
  } else if (PriceGuaranteeReportRowType.isItem(rowType) || PriceGuaranteeReportRowType.isHeader(rowType)) {
    return value;
  }
  return '';
};
