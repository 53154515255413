import AbstractSpecColumnUIDefinition from './AbstractSpecColumnUIDefinition';

class AbstractSpecificationSnapshotGroupColumn {

  /** @abstract */
  getSpecificationSnapshot = () => {
    throw new Error('Function not implemented!');
    // eslint-disable-next-line no-unreachable
    return null;
  }

  /**
   * @abstract
   * @param {AbstractSpecColumnUIDefinition|Object} columnUIDefinition
   * @return {boolean}
   */
  hasSameSnapshot = (columnUIDefinition) => {
    return columnUIDefinition instanceof AbstractSpecColumnUIDefinition
    && columnUIDefinition.getSpecificationSnapshot() === this.getSpecificationSnapshot();
  }

  /** @abstract */
  buildGroupColumnDefinition = (lsi, additionalParams, specColumnUIDefinitionList) => {
    const snapshot = this.getSpecificationSnapshot();

    const childrenList = specColumnUIDefinitionList.map(li => li.cuid.buildColumnDefinition(li.ciColDef, lsi, additionalParams));

    const sumOfSortOrder = specColumnUIDefinitionList.reduce((acc, li) => acc + li.sortOrder, 0);
    const avgSortOrder = sumOfSortOrder / specColumnUIDefinitionList.length;

    return {
      sortOrder: avgSortOrder,
      colDef: {
        colId: snapshot,
        headerName: lsi.getLSIItem(`PRICE_GUARANTEE.TABLE_HEADER.SPECIFICATION_SNAPSHOT.${snapshot}`),
        headerClass: ['ovex-ag-grid--th-center'],
        marryChildren: true,
        children: childrenList
      }
    };
  }
}

export default AbstractSpecificationSnapshotGroupColumn;