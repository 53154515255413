import ButtonType from '../ButtonType';

const ButtonTypeProps = {
  [ButtonType.PRIMARY]: {
    bgStyle: 'filled',
    borderRadius: '16px',
    className: 'ovex-Button--primary',
    colorSchema: 'primary',
    elevation: 0
  },
  [ButtonType.SECONDARY]: {
    bgStyle: 'outline',
    borderRadius: '16px',
    className: 'ovex-Button--secondary',
    colorSchema: 'grey',
    elevation: 0
  },
  [ButtonType.FLOATING]: {
    bgStyle: 'transparent',
    borderRadius: '16px',
    className: 'ovex-Button--floating',
    colorSchema: null,
    elevation: 3
  },
  /**
   * **DEFAULT** props are not "public"
   */
  'DEFAULT': {
    bgStyle: 'filled',
    borderRadius: '2px',
    className: null,
    colorSchema: null,
    elevation: 0
  }
};

export default ButtonTypeProps;