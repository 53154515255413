import { COLUMN_IDS, getColumnsToExport } from './PriceGuaranteeReportTable.columnIds';
import PriceGuaranteeReportRowType from './rowModel/PriceGuaranteeReportRowType';

const handleProcessCellCallback = (params) => {
  if ([
    COLUMN_IDS.COH_ACCOUNT_TYPE,
    COLUMN_IDS.C_ORDER_MODE,
    COLUMN_IDS.COH_PART_STATUS,
    COLUMN_IDS.CPGI_CLAIM_TYPE,
    COLUMN_IDS.PGI_CLAIM_TYPE,
    COLUMN_IDS.C_CLAIM_TYPE,
    COLUMN_IDS.C_DEALER_AREA,
    COLUMN_IDS.C_CLIENT_NAME
  ].includes(params.column.colId)) {
    params.data = params.node.data; // FIXME - Objekt parametru exportu do Excelu nemaji atribut 'data' v koreni objektu, pouze na 'node' atributu. Muze byt odstraneno, pokud to bude na objekt doplneno primo z AG-Grid.
    return params.column.colDef.valueFormatter(params);
  }
  if ([
    COLUMN_IDS.C_VALUATION_DATE,
    COLUMN_IDS.C_INVOICING_DATE_TO_IMPORTER,
    COLUMN_IDS.C_INVOICING_DATE,
    COLUMN_IDS.C_SELLING_DATE,
    COLUMN_IDS.C_ACTIVATION_DATE,
    COLUMN_IDS.C_CALCULATION_DATE
  ].includes(params.column.colId)) {
    return params.value?.toISOString();
  }
  return params.value;
};

const handleShouldRowBeSkipped = ({ node }) => {
  const rowType = node.data.getRowType();
  return PriceGuaranteeReportRowType.isHeaderWithItems(rowType);
};

export const getExportExcelConfig = (pageType) => {

  return {
    processCellCallback: handleProcessCellCallback,
    shouldRowBeSkipped: handleShouldRowBeSkipped,

    columnKeys: getColumnsToExport(pageType),
    skipRowGroups: true,
    fileName: 'OVEX3_report_CG'
  };
};
