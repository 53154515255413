import React from 'react';
import PropTypes from 'prop-types';
import { PriceGuaranteeDTOITM } from '@ovex/price-guarantee-web-api';

import { FilterWrapper, Select } from '../../../../common/components/forms';
import { LsiContext } from '../../../../common/contexts';

import { pgSelectItemsMapper } from './PriceGuaranteeSelector.helpers';

import './PriceGuaranteeSelector.scss';

const propTypes = {
  disabled: PropTypes.bool,
  onChangePriceGuarantee: PropTypes.func.isRequired,
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTOITM)),
  priceGuaranteeSelected: PropTypes.instanceOf(PriceGuaranteeDTOITM)
};

const defaultProps = {
  disabled: false,
  priceGuaranteeList: null,
  priceGuaranteeSelected: null
};

const PriceGuaranteeSelector = (props) => {
  const lsi = React.useContext(LsiContext);

  const onChangePriceGuarantee = props.onChangePriceGuarantee;

  const selectItems = React.useMemo(
    () => {
      return props.priceGuaranteeList?.map(pgSelectItemsMapper(lsi)) || [];
    },
    [props.priceGuaranteeList, lsi]
  );

  const handleChange = (opt) => {
    const item = selectItems.find(i => i.value === opt.value);

    onChangePriceGuarantee(item?.priceGuarantee);
  };

  return (
    <FilterWrapper
      className='ovex-pg-PriceGuaranteeSelector'
    >
      <Select
        className='ovex-pg-PriceGuaranteeSelector-input'
        disabled={props.disabled}
        items={selectItems}
        label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.PRICE_GUARANTEE')}
        name='priceGuarantee'
        onChange={handleChange}
        value={props.priceGuaranteeSelected?.id.toString()}
      />
    </FilterWrapper>
  );
};

PriceGuaranteeSelector.propTypes = propTypes;
PriceGuaranteeSelector.defaultProps = defaultProps;

export default PriceGuaranteeSelector;
