import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonBar, Modal } from '../../../common/components';
import { LsiContext, UserInfoContext } from '../../../common/contexts';
import {
  FilterForm,
  FormsContextSection,
  Select
} from '../../../common/components/forms';
import './UserChangeBidModal.scss';

const propTypes = {
  fetching: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  userInfoCurrent: PropTypes.object
};
const defaultProps = {
  fetching: true,
  onClose: null,
  userInfoCurrent: null
};

const UserChangeBidModal = (props) => {
  const lsi = React.useContext(LsiContext);
  const userInfo = React.useContext(UserInfoContext);

  const { onChangeUserBid, bidList } = props;
  const selectorItemList = [];
  const [bid, setBid] = React.useState(null);

  const handleSubmit = React.useCallback(
    () => {
      onChangeUserBid(bid.value);
    },
    [onChangeUserBid, bid]
  );

  bidList && bidList.forEach(bid => {
    if (userInfo.bid !== bid) {
      selectorItemList.push({
        value: bid,
        bid: bid,
        tooltip: bid,
        content: bid
      });
    }
  });

  return (
    <Modal
      className='ovex-UserInfoModal'
      header={lsi.getLSIItem('SUPPORT.USER_EMIGRATION.HEADER')}
      onClose={props.onClose}
      shown
      size='s'
      stickyBackground={true}
    >
      <FormsContextSection>
        <FilterForm
          onSubmit={handleSubmit}>
          <Select
            className='ovex-ChangeBid-Modal'
            items={selectorItemList}
            name='selectedBid'
            onChange={(choice) => setBid(choice)}
            required={true}
            value={bid?.value}
          />
        </FilterForm>
        <ButtonBar>
          <Button
            className='ovex-ChangeBid-Modal-Button'
            content={lsi.getLSIItem('SUPPORT.USER_EMIGRATION.IMITATE_USER')}
            disabled={!bid}
            onClick={handleSubmit}
            type={Button.type.PRIMARY}
          />
        </ButtonBar>
      </FormsContextSection>
    </Modal>
  );
};

UserChangeBidModal.propTypes = propTypes;
UserChangeBidModal.defaultProps = defaultProps;

export default UserChangeBidModal;