import { PG_COLUMN_IDS } from './PriceGuaranteeTable.columnDefs';

const handleProcessCellCallback = (params) => {
  if ([
    PG_COLUMN_IDS.STATUS,
    PG_COLUMN_IDS.TYPE
  ].includes(params.column.colId)) {
    params.data = params.node.data;
    return params.column.colDef.valueFormatter(params);
  }
  if ([
    PG_COLUMN_IDS.COMMISSION_CREATION_DATE_TO,
    PG_COLUMN_IDS.COMMISSION_SELLING_DATE_TO,
    PG_COLUMN_IDS.CLAIM_CALCULATION_DATE
  ].includes(params.column.colId)) {
    return params.value?.toISOString();
  }
  return params.value;
};

export const exportExcel = {
  processCellCallback: handleProcessCellCallback,

  skipRowGroups: true
};
