import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { Checkbox } from '../../../../../common/components/forms';

import { getQuarterInputName, quarterSorter } from './BalanceComponentForm.utils';

import './BalancePeriodCheckbox.scss';
import { LsiContext } from '../../../../../common/contexts';

const DefaultValues = {};

const propTypes = {
  name: PropTypes.string,
  onUpdate: PropTypes.func,
  isBalanceApplicated: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      correction: PropTypes.bool,
      quarter: PropTypes.number
    })
  )
};

const defaultProps = {
  name: null,
  onUpdate: null
};

const BalancePeriodCheckbox = (props) => {
  const classNames = ['ovex-form-QuarterPeriodCheckbox'];
  const lsi = React.useContext(LsiContext);

  const handleUpdateValue = (val, name, quarter) => {
    return props.onUpdate && props.onUpdate({ correction: val, name: name, quarter: quarter });
  };

  return (
    <Bricks.Row
      className={classNames.join(' ')}
    >
      <Bricks.Row className={'ovex-pvt-balance'}>
        <Bricks.Text className={'uu5-forms-label ovex-pvt-balanceLabel'}>
          {lsi.getLSIItem('PVT.FORM_INPUT_LABEL.BALANCE_PERIOD')}
        </Bricks.Text>
        <Bricks.Div className={'ovex-pvt-balance-checkboxes'}>

          {props.value?.sort(quarterSorter).map(val => {
            const inputName = getQuarterInputName(props.name, val.quarter);
            return (
              <Bricks.Column
                colWidth='xs3'
                key={`${val.quarter + 'Q'}_COLUMN_Checkbox`}
              >
                <Checkbox
                  disabled={val.quarter === 1 ? true : !props.isBalanceApplicated}
                  className={'uu5-forms-checkbox'}
                  inputColWidth='xs3'
                  name={inputName}
                  onChange={(v) => handleUpdateValue(v, inputName, val.quarter)}
                  value={val.correction}
                />
              </Bricks.Column>
            );
          })}
        </Bricks.Div>
      </Bricks.Row>
    </Bricks.Row>
  );
};

BalancePeriodCheckbox.DefaultValues = DefaultValues;

BalancePeriodCheckbox.propTypes = propTypes;
BalancePeriodCheckbox.defaultProps = defaultProps;

export default BalancePeriodCheckbox;
