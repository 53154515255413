import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class PGRGetPriceGuaranteeReportDealerPFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PGR__GET_PRICE_GUARANTEE_REPORT_DEALER);
  }

}

export default PGRGetPriceGuaranteeReportDealerPFC;
