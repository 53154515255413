import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class GetDealerContractListFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} contractId
   */
  constructor(contractId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__GET_DEALER_CONTRACTS_DETAIL);
    this.setParams({
      [ContextConstants.CONTRACT_ID]: contractId
    });
  }

}

export default GetDealerContractListFunctionalityContext;