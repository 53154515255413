import React from 'react';
import { Route, Switch } from 'react-router-dom';

import webModuleRegister from '../webModuleRegister';
import { Home } from '../layouts';
import { NotFoundRoute } from '../packages/common/components/routes';

import PATHS from './paths';

class OvexRoutes extends React.PureComponent {

  render() {
    return (
      <Switch>
        <Route component={Home} exact path={PATHS.ROUTE_PATH_ROOT}/>

        {
          webModuleRegister.getRoutesDataList().map(RoutesData => {
            const pathPrefix = RoutesData.pathPrefix;
            return (
              <Route key={pathPrefix} path={pathPrefix} >
                <RoutesData.component />
              </Route>
            );
          })
        }

        <NotFoundRoute />
      </Switch>
    );
  }
}

export default OvexRoutes;