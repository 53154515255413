
const PriceGuaranteeReportPageType = {
  DEALER: 'dealer',
  IMPORTER: 'importer',
  IMPORTER_EXPORT: 'importer-export',

  isDealer: function (type) { return this.DEALER === type; },
  isImporter: function (type) { return this.IMPORTER === type; },
  isImporterExport: function (type) { return this.IMPORTER_EXPORT === type; },
  isImporterVariant: function (type) { return this.isImporter(type) || this.isImporterExport(type); }
};

export default PriceGuaranteeReportPageType;
