/**
 * Codes from 'vwg.skoda.ovex.annualtarget.privilege.common.Functionality'
 */
const FunctionalityCodes = {
  AT_NEGOTIATIONS__GET_YEARS: 'AT_NEGOTIATIONS__GET_YEARS',
  AT_NEGOTIATIONS__GET_ANNUAL_TARGET_CALENDAR_YEARS: 'AT_NEGOTIATIONS__GET_ANNUAL_TARGET_CALENDAR_YEARS',
  AT_NEGOTIATIONS__CREATE_YEAR: 'AT_NEGOTIATIONS__CREATE_YEAR',
  AT_NEGOTIATIONS__UPDATE_YEAR: 'AT_NEGOTIATIONS__UPDATE_YEAR',
  AT_NEGOTIATIONS__CREATE_NEGOTIATION: 'AT_NEGOTIATIONS__CREATE_NEGOTIATION',
  AT_NEGOTIATIONS__UPDATE_NEGOTIATION: 'AT_NEGOTIATIONS__UPDATE_NEGOTIATION',
  AT_NEGOTIATIONS__UPDATE_ACTIVATED_NEGOTIATION: 'AT_NEGOTIATIONS__UPDATE_ACTIVATED_NEGOTIATION',
  AT_NEGOTIATIONS__NEGOTIATION_SHIFT_STATE: 'AT_NEGOTIATIONS__NEGOTIATION_SHIFT_STATE',
  AT_NEGOTIATIONS__GET_NEGOTIATION_DEALERS: 'AT_NEGOTIATIONS__GET_NEGOTIATION_DEALERS',
  AT_NEGOTIATIONS__GET_MODEL_GROUPS: 'AT_NEGOTIATIONS__GET_MODEL_GROUPS',
  AT_NEGOTIATIONS__GET_PRODUCTION_CAPACITY_RATIO: 'AT_NEGOTIATIONS__GET_PRODUCTION_CAPACITY_RATIO',
  AT_NEGOTIATIONS__CREATE_NEGOTIATION_CLOSURE: 'AT_NEGOTIATIONS__CREATE_NEGOTIATION_CLOSURE',
  AT_NEGOTIATIONS__CANCEL_NEGOTIATION_CLOSURE: 'AT_NEGOTIATIONS__CANCEL_NEGOTIATION_CLOSURE',

  AT_CONTRACTS__GET_DEALERS_CONTRACTS: 'AT_CONTRACTS__GET_DEALERS_CONTRACTS',
  AT_CONTRACTS__GET_DEALER_CONTRACTS: 'AT_CONTRACTS__GET_DEALER_CONTRACTS',
  AT_CONTRACTS__UPDATE_CONTRACT_AT_DEALER: 'AT_CONTRACTS__UPDATE_CONTRACT_AT_DEALER',
  AT_CONTRACTS__UPDATE_CONTRACT_AT_RECOMMENDED: 'AT_CONTRACTS__UPDATE_CONTRACT_AT_RECOMMENDED',
  AT_CONTRACTS__UPDATE_CONTRACT_AT_APPROVAL: 'AT_CONTRACTS__UPDATE_CONTRACT_AT_APPROVAL',
  AT_CONTRACTS__UPDATE_CONTRACT_ATS_DEALER: 'AT_CONTRACTS__UPDATE_CONTRACT_ATS_DEALER',
  AT_CONTRACTS__UPDATE_CONTRACT_ATS_RECOMMENDED: 'AT_CONTRACTS__UPDATE_CONTRACT_ATS_RECOMMENDED',
  AT_CONTRACTS__UPDATE_CONTRACT_ATS_APPROVAL: 'AT_CONTRACTS__UPDATE_CONTRACT_ATS_APPROVAL',
  AT_CONTRACTS__CONTRACT_SHIFT_STATE: 'AT_CONTRACTS__CONTRACT_SHIFT_STATE',
  AT_CONTRACTS__GET_DEALER_CONTRACTS_DETAIL: 'AT_CONTRACTS__GET_DEALER_CONTRACTS_DETAIL',
  AT_CONTRACTS__GET_CONTRACT_PREVIEW: 'AT_CONTRACTS__GET_CONTRACT_PREVIEW',

  AT_VIEWS__GET_DEALER_CONTRACTS_AT_MODEL_GROUPS: 'AT_VIEWS__GET_DEALER_CONTRACTS_AT_MODEL_GROUPS',
  AT_VIEWS__GET_DEALER_CONTRACTS_ATS_PERIODS: 'AT_VIEWS__GET_DEALER_CONTRACTS_ATS_PERIODS',

  AT_CONTRACTS__GET_COMMISSION_LIST_DEALER: 'AT_CONTRACTS__GET_COMMISSION_LIST_DEALER',
  AT_CONTRACTS__GET_COMMISSION_LIST_IMPORTER: 'AT_CONTRACTS__GET_COMMISSION_LIST_IMPORTER',
  AT_CONTRACTS__SAVE_LOAD_PARAMS_DEALER: 'AT_CONTRACTS__SAVE_LOAD_PARAMS_DEALER',
  AT_CONTRACTS__SAVE_LOAD_PARAMS_IMPORTER: 'AT_CONTRACTS__SAVE_LOAD_PARAMS_IMPORTER'
};

export default FunctionalityCodes;
