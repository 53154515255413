import { checkClientByPGType } from '../../../../../../utils/helpers';
import { CommissionStatusIntervalEnum } from '../../../../../../utils/const';
import { simpleNullishValueFormatter } from '../../../../../../../common/utils/formatters';
import { getClientDataFromCommission, clientDataValueFormatter } from '../../ValidationResultHeaderTable.helpers';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class ClientRUID_CZ extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.CLIENT__CZ__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.CLIENT');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return [
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLIENT_PART_1'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLIENT_PART_2'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLIENT_PART_3'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CLIENT_PART_4')
    ];
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    const { priceGuarantee, commission } = context;
    const { commissionPreviousData } = commission;

    if (checkClientByPGType(priceGuarantee.type) && commissionPreviousData.statusInterval === CommissionStatusIntervalEnum.PLANNED) {
      return getClientDataFromCommission(commissionPreviousData);
    }
    return null;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    return simpleNullishValueFormatter(clientDataValueFormatter(value), '-');
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { priceGuarantee, commission } = context;
    const { commissionCurrentData, commissionPreviousData } = commission;

    if (checkClientByPGType(priceGuarantee.type) && commissionPreviousData.statusInterval === CommissionStatusIntervalEnum.PLANNED) {
      return getClientDataFromCommission(commissionCurrentData);
    }
    return null;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    return simpleNullishValueFormatter(clientDataValueFormatter(value), '-');
  }
}

const clientRUID_CZ = new ClientRUID_CZ();

export default clientRUID_CZ;