import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useHistoryOvex = () => {
  const history = useHistory();
  const location = useLocation();
  const currentFullPath = location.pathname + location.search;

  const handlePush = React.useCallback(
    (path, state) => {
      if (path !== currentFullPath) {
        history.push(path, state);
      }
    },
    [history, currentFullPath]
  );

  return React.useMemo(
    () => {
      return {
        ...history,
        push: handlePush
      };
    },
    [history, handlePush]
  );
};

export default useHistoryOvex;
