import NegotiationsContextMenuItems from './NegotiationsContextMenuItems';
import YearSettingsContextMenuItems from './YearSettingsContextMenuItems';

export const getContextMenu = (params) => {
  if (!params.node.group) {
    const data = params.node.data;
    if (data.isYearSettingsRow()) {
      return [
        YearSettingsContextMenuItems.getShowDetailItem(params),
        'separator',
        YearSettingsContextMenuItems.getUpdateItem(params),
        YearSettingsContextMenuItems.getAddNegotiationContractTypeItem(params),
        YearSettingsContextMenuItems.getAddNegotiationAdditionTypeItem(params),
        YearSettingsContextMenuItems.getAddNegotiationClosureTypeItem(params)
      ];
    }
    if (data.isNegotiationContractOrAdditionRow()) {
      return [
        NegotiationsContextMenuItems.getShowNegotiationDetailItem(params),
        'separator',
        NegotiationsContextMenuItems.getUpdateNegotiationItem(params),
        NegotiationsContextMenuItems.getUpdateActivatedNegotiationItem(params),
        NegotiationsContextMenuItems.getNegotiationWorkflowItem(params)
      ];
    }
    if (data.isNegotiationClosureRow()) {
      return [
        NegotiationsContextMenuItems.getShowNegotiationDetailItem(params),
        'separator',
        NegotiationsContextMenuItems.getCancelNegotiationClosureItem(params)
      ];
    }
  }
  return [];
};
