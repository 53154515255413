import React from 'react';

import { ConfirmModal } from '../components';

/**
 * @typedef {Object} UseConfirmModalParams
 * @property {string|null} buttonConfirmLabel
 * @property {string|null} buttonRefuseLabel
 * @property {string|Object|null} content
 * @property {string|null} header
 * @property {function|null} onRefuse
 */

/**
 *
 * @param {function|null} handleConfirmAction
 * @param {UseConfirmModalParams|null} confirmModalParams
 * @return {[function, function]}
 */
const useConfirmModal = (handleConfirmAction, confirmModalParams = null) => {
  const confirmModalRef = React.useRef(null);
  const actionParams = React.useRef(null);

  const _handleConfirmAction = React.useCallback(
    () => handleConfirmAction && handleConfirmAction(actionParams.current),
    [handleConfirmAction]
  );

  const header = confirmModalParams && confirmModalParams.header;
  const content = confirmModalParams && confirmModalParams.content;
  const buttonConfirmLabel = confirmModalParams && confirmModalParams.buttonConfirmLabel;
  const buttonRefuseLabel = confirmModalParams && confirmModalParams.buttonRefuseLabel;
  const onRefuse = confirmModalParams && confirmModalParams.onRefuse;
  const renderConfirmModal = React.useCallback(
    () => {
      return (
        <ConfirmModal
          buttonConfirmLabel={buttonConfirmLabel}
          buttonRefuseLabel={buttonRefuseLabel}
          content={content}
          header={header}
          onConfirm={_handleConfirmAction}
          onRefuse={onRefuse}
          ref_={confirmModalRef}
        />
      );
    },
    [_handleConfirmAction, header, content, buttonConfirmLabel, buttonRefuseLabel, onRefuse]
  );

  /**
   * @type {function(*): void}
   */
  const handleShowConfirmModal = React.useCallback(
    (params) => {
      const { confirmModalProps, ...actionParamsTmp } = params;
      actionParams.current = actionParamsTmp;
      confirmModalRef.current && confirmModalRef.current.open(confirmModalProps);
    },
    []
  );

  return [
    renderConfirmModal,
    handleConfirmAction != null ? handleShowConfirmModal : null
  ];
};

export default useConfirmModal;
