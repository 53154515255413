import { isEmptyArray, isNonEmptyArray } from '../../../common/utils/arrays';

export const isHiddenROC = (filterValues) => {
  if (filterValues != null) {
    const emptyFilterROC = _isEmptyFilterROC(filterValues);
    const emptyFilterRCP = _isEmptyFilterRCP(filterValues);

    return emptyFilterROC !== emptyFilterRCP && (emptyFilterROC && !emptyFilterRCP);
  }
  return false;
};

export const isHiddenRCP = (filterValues) => {
  if (filterValues != null) {
    const emptyFilterROC = _isEmptyFilterROC(filterValues);
    const emptyFilterRCP = _isEmptyFilterRCP(filterValues);

    return emptyFilterROC !== emptyFilterRCP && (!emptyFilterROC && emptyFilterRCP);
  }
  return false;
};

export const isDisabledEditable = (filterValues) => {
  if (filterValues != null) {
    return isNonEmptyArray(filterValues.atPeriodOrderList);
  }
  return false;
};

const _isEmptyFilterROC = (filterValues) => {
  return isEmptyArray(filterValues.modelGroupList) && isEmptyArray(filterValues.atPeriodOrderList);
};

const _isEmptyFilterRCP = (filterValues) => {
  return isEmptyArray(filterValues.atsPeriodOrderList);
};
