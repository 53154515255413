import PriceGuaranteeReportRowType from './PriceGuaranteeReportRowType';

export class PriceGuaranteeReportRow {

  constructor(commissionPGClaimResult, priceGuarantee) {
    const {
      priceGuaranteeId, cOrderId, cOrderMode, claimTypeHeader, cOrderHeaderAccount,
      commission, commissionDealerNumber, commissionCarSpecificationModel,
      commissionCurrentData, commissionPreviousDataStatus, calculationDate
    } = commissionPGClaimResult;

    const hierarchyItem = `${priceGuaranteeId}-${cOrderId}-${cOrderHeaderAccount.accountType}`;

    this.cOrderMode = cOrderMode;
    this.commissionClaimTypeHeader = claimTypeHeader;
    this.commission = commission;
    this.commissionDealerNumber = commissionDealerNumber;
    this.commissionCarSpecificationModel = commissionCarSpecificationModel;
    this.commissionCurrentData = commissionCurrentData;
    this.commissionPreviousDataStatus = commissionPreviousDataStatus;
    this.priceGuarantee = priceGuarantee;
    this.calculationDate = calculationDate;

    this.accountType = cOrderHeaderAccount.accountType;
    this.accountPartData = cOrderHeaderAccount.accountPartData;

    this._rowType = PriceGuaranteeReportRowType.HEADER;
    this.pgItemClaimAmount = 0;

    this.hierarchy = [hierarchyItem];
  }

  setPGItem = (pgItem, pgItemClaimTypeItem, pgItemClaimAmount, pgItemInvoiceAmount) => {
    this.pgItem = pgItem;
    this.pgItemClaimTypeItem = pgItemClaimTypeItem;
    this.pgItemClaimAmount = pgItemClaimAmount;
    this.pgItemInvoiceAmount = pgItemInvoiceAmount;

    this._rowType = PriceGuaranteeReportRowType.ITEM;

    this.hierarchy.push(pgItem.id);

    return this;
  }

  setHeaderWithItems = () => {
    this._rowType = PriceGuaranteeReportRowType.HEADER_WITH_ITEMS;
    this.pgItemClaimAmount = null;
  };

  getRowType = () => this._rowType;

  getCommissionValidatorData = () => ({
    marketingActionCode: this.priceGuarantee.marketingActionCode,
    commissionNumber: this.commission
  });

}

/**
 * @type {Array<String>}
 */
PriceGuaranteeReportRow.prototype.hierarchy = null;
/**
 * @type {ClaimTypeHeaderEnum}
 */
PriceGuaranteeReportRow.prototype.commissionClaimTypeHeader = null;
/**
 * @type {String}
 */
PriceGuaranteeReportRow.prototype.commission = null;
/**
 * @type {String}
 */
PriceGuaranteeReportRow.prototype.commissionDealerNumber = null;
/**
 * @type {String}
 */
PriceGuaranteeReportRow.prototype.commissionCarSpecificationModel = null;
/**
 * @type {CommissionSnapshotDTOPGR}
 */
PriceGuaranteeReportRow.prototype.commissionCurrentData = null;
/**
 * @type {String}
 */
PriceGuaranteeReportRow.prototype.commissionPreviousDataStatus = null;
/**
 * @type {PriceGuaranteeDTOPGR}
 */
PriceGuaranteeReportRow.prototype.priceGuarantee = null;
/**
 * @type {SalesAccountTypeEnum} - nullable
 */
PriceGuaranteeReportRow.prototype.accountType = null;
/**
 * @type {COrderHeaderAccountPartDTOPRG} - nullable
 */
PriceGuaranteeReportRow.prototype.accountPartData = null;
/**
 * @type {PGItemDTOPGR} - nullable
 */
PriceGuaranteeReportRow.prototype.pgItem = null;
/**
 * @type {ClaimTypeItemEnum} - nullable (not null if *pgItem* exists)
 */
PriceGuaranteeReportRow.prototype.pgItemClaimTypeItem = null;
/**
 * @type {Number} - nullable (not null if *pgItem* exists)
 */
PriceGuaranteeReportRow.prototype.pgItemClaimAmount = null;
/**
 * @type {PGItemValidationResultInvoicedDTOPRG} - nullable
 */
PriceGuaranteeReportRow.prototype.pgItemInvoiceAmount = null;
