
class ModuleContextData {

  /**
   * @param {string} code
   * @param {function} privilegeAction
   * @param {function} privilegeSelector
   */
  constructor(code, privilegeAction, privilegeSelector) {
    this.code = code;
    this.privilegeAction = privilegeAction;
    this.privilegeSelector = privilegeSelector;
  }

}

ModuleContextData.prototype.code = undefined;
ModuleContextData.prototype.privilegeAction = undefined;
ModuleContextData.prototype.privilegeSelector = undefined;

export default ModuleContextData;