import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import generatePathCommissionValidator from '../CommissionValidator/generatePathCommissionValidator';
import {
  handleGetPriceGuaranteeListPGR, handleResetPriceGuaranteeReportPGR
} from '../../redux/modules/price-guarantee-report/price-guarantee-report';
import { NewTabContext } from '../../../common/contexts';

import PriceGuaranteeReport from './PriceGuaranteeReport';
import PriceGuaranteeReportPageTypeContext from './PriceGuaranteeReportPageTypeContext';
import { priceGuaranteeReportRowConverter } from './PriceGuaranteeReportTable/rowModel/priceGuaranteeReportRowConverter';

const propTypes = {
  commissionValidatorPath: PropTypes.string,
  onGetPriceGuaranteeReportReduxFn: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  titleLsiCode: PropTypes.string.isRequired
};

const defaultProps = {
  commissionValidatorPath: null
};

const PriceGuaranteeReportConnector = (props) => {
  const openInNewTab = React.useContext(NewTabContext);

  const { onGetPriceGuaranteeReportReduxFn, pageType, titleLsiCode, commissionValidatorPath } = props;

  const selectedState = useSelector(state => {
    return {
      isFetching: state.priceGuarantee.priceGuaranteeReport.isFetching,
      priceGuaranteeList: state.priceGuarantee.priceGuaranteeReport.priceGuaranteeList,
      priceGuaranteeReport: state.priceGuarantee.priceGuaranteeReport.priceGuaranteeReport
    };
  });

  const priceGuaranteeReport = selectedState.priceGuaranteeReport;
  const pgReportConverted = React.useMemo(
    () => {
      return {
        pgReportRowList: priceGuaranteeReportRowConverter(priceGuaranteeReport),
        currency: priceGuaranteeReport?.priceGuaranteeList?.[0].currency // TODO - find better solution
      };
    },
    [priceGuaranteeReport]
  );

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => ({
      handleGetPriceGuaranteeListPGR: () => dispatch(handleGetPriceGuaranteeListPGR()),
      handleGetPriceGuaranteeReportDealerPGR: (filterValues) => dispatch(onGetPriceGuaranteeReportReduxFn(filterValues)),
      handleResetPriceGuaranteeReportPGR: () => dispatch(handleResetPriceGuaranteeReportPGR())
    }),
    [onGetPriceGuaranteeReportReduxFn, dispatch]
  );

  const handleShowCommissionValidator = React.useCallback(
    commissionValidatorPath == null ? null : (
      ({ marketingActionCode, commissionNumber }) => {
        openInNewTab(generatePathCommissionValidator(commissionValidatorPath, marketingActionCode, commissionNumber));
      }
    ),
    [openInNewTab, commissionValidatorPath]
  );

  return (
    <PriceGuaranteeReportPageTypeContext.Provider value={pageType} >
      <PriceGuaranteeReport
        currency={pgReportConverted.currency}
        isFetching={selectedState.isFetching}
        onGetPriceGuaranteeList={callbacks.handleGetPriceGuaranteeListPGR}
        onGetPriceGuaranteeReport={callbacks.handleGetPriceGuaranteeReportDealerPGR}
        onResetPriceGuaranteeReport={callbacks.handleResetPriceGuaranteeReportPGR}
        onShowCommissionValidator={handleShowCommissionValidator}
        pgReportRowList={pgReportConverted.pgReportRowList}
        priceGuaranteeList={selectedState.priceGuaranteeList}
        titleLsiCode={titleLsiCode}
      />
    </PriceGuaranteeReportPageTypeContext.Provider>
  );
};

PriceGuaranteeReportConnector.propTypes = propTypes;
PriceGuaranteeReportConnector.defaultProps = defaultProps;

export default PriceGuaranteeReportConnector;
