import { getViewOrderTypePreviousRowCZ } from '../../orderTypeRow/orderTypeViewByCZ';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class OrderTypePreviousRUID_CZ extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.ORDER_TYPE_PREVIOUS__CZ__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.ORDER_TYPE_PREVIOUS');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.ORDER_TYPE_PREVIOUS');
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    const { priceGuarantee, commission } = context;

    return {
      pgType: priceGuarantee.type,
      statusInterval: commission.commissionPreviousData.statusInterval
    };
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    return getViewOrderTypePreviousRowCZ(value);
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { commission } = context;

    return commission.commissionPreviousData.orderType;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    return value;
  }
}

const orderTypePreviousRUID_CZ = new OrderTypePreviousRUID_CZ();

export default orderTypePreviousRUID_CZ;