import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class PGRGetPriceGuaranteeListPFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PGR__GET_PRICE_GUARANTEES);
  }

}

export default PGRGetPriceGuaranteeListPFC;
