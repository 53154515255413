import React from 'react';
import PropTypes from 'prop-types';
import { CommissionDTO } from '@ovex/annual-target-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { useAlertBusOvex } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';

import CommissionDTOConverter from './CommissionDTOConverter';
import CommissionTable from './CommissionTable/CommissionTable';

const propTypes = {
  commissionList: PropTypes.arrayOf(PropTypes.instanceOf(CommissionDTO)),
  isFetching: PropTypes.bool.isRequired,
  onGetCommissionList: PropTypes.func,
  titleLsiCode: PropTypes.string.isRequired
};

const defaultProps = {
  commissionList: undefined,
  onGetCommissionList: undefined
};

const CommissionList = (props) => {
  const { commissionList, isFetching, titleLsiCode,
    onGetCommissionList } = props;

  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  React.useEffect(
    () => {
      try {
        onGetCommissionList && onGetCommissionList();
      } catch (e) {
        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_COMMISSION_LIST_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetCommissionList, handleAddAlertSimple]
  );

  const commissionRowList = React.useMemo(
    () => CommissionDTOConverter.convertFromCommissionDTOList(commissionList),
    [commissionList]
  );

  return (
    <Page
      className='ovex-at-CommissionList'
      header={lsi.getLSIItem(`ANNUAL_TARGET.PAGE_TITLE.${titleLsiCode}`)}
      loading={isFetching}
    >
      <CommissionTable
        commissionList={commissionRowList}
      />
    </Page>
  );
};

CommissionList.propTypes = propTypes;
CommissionList.defaultProps = defaultProps;

export default CommissionList;
