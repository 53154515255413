import { pairStringValueFormatter } from '../../../../../common/utils/formatters';

import RowUIDefinitionMap from './rowUIDefinition/RowUIDefinitionMap';

export const conditionTypeValueFormatter = (params) => {
  const { data } = params;
  return RowUIDefinitionMap[data.uiDefinition].conditionTypeValueFormatter(params);
};
export const conditionTypeTooltipValueGetter = (params) => {
  const { data } = params;
  return RowUIDefinitionMap[data.uiDefinition].conditionTypeTooltipValueGetter(params);
};

export const requiredStateValueGetter = (params) => {
  const { data } = params;
  return RowUIDefinitionMap[data.uiDefinition].requiredStateValueGetter(params);
};
export const requiredStateValueFormatter = (params) => {
  const { data } = params;
  return RowUIDefinitionMap[data.uiDefinition].requiredStateValueFormatter(params);
};

export const currentStateValueGetter = (params) => {
  const { data } = params;
  return RowUIDefinitionMap[data.uiDefinition].currentStateValueGetter(params);
};
export const currentStateValueFormatter = (params) => {
  const { data } = params;
  return RowUIDefinitionMap[data.uiDefinition].currentStateValueFormatter(params);
};
export const currentStateCellClass = (params) => {
  const { data } = params;
  return RowUIDefinitionMap[data.uiDefinition].currentStateCellClass(params);
};

export const getClientDataFromCommission = (commissionData) => ({
  clientVos: commissionData.clientVos,
  clientId: commissionData.clientId,
  clientName: commissionData.clientName,
  clientSurname: commissionData.clientSurname
});

export const clientDataValueFormatter = (clientData) => {
  if (clientData == null) {
    return null;
  }
  const { clientVos, clientId, clientName, clientSurname } = clientData;

  const clientNameSurname = pairStringValueFormatter(clientName, clientSurname, ' ');
  return [clientVos, clientId, clientNameSurname]
    .map(item => item || '-')
    .join(', ');
};

