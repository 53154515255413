import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrivilege } from '../../../common/hooks';
import { PUBGetPriceGuaranteeItemsPFC } from '../../privilege/context';
import {
  handleClearPriceGuaranteeItemListPUB,
  handleGetPriceGuaranteeListPUB,
  handleGetPriceGuaranteeItemListPUB
} from '../../redux/modules/actions';

import PriceGuaranteeItemsPublished from './PriceGuaranteeItemsPublished';

const PriceGuaranteeItemsPublishedConnector = () => {
  const selectedState = useSelector(state => {
    return {
      isFetching: state.priceGuarantee.pgiPublished.isFetching,
      priceGuaranteeList: state.priceGuarantee.pgiPublished.priceGuaranteeList,
      priceGuaranteeItemList: state.priceGuarantee.pgiPublished.priceGuaranteeItemList
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => ({
      handleClearPriceGuaranteeItemListPUB: () => dispatch(handleClearPriceGuaranteeItemListPUB()),
      handleGetPriceGuaranteeListPUB: () => dispatch(handleGetPriceGuaranteeListPUB()),
      handleGetPriceGuaranteeItemListPUB: (priceGuaranteeId) => dispatch(handleGetPriceGuaranteeItemListPUB(priceGuaranteeId))
    }),
    [dispatch]
  );

  const [ isPrivilegeFetching, setPrivilegeFetching ] = React.useState(true);
  const { handlePrivilegeRequest } = usePrivilege();

  const [ priceGuaranteeListDisplayed, setPriceGuaranteeListDisplayed ] = React.useState(selectedState.priceGuaranteeList);
  React.useEffect(
    () => {
      if (Array.isArray(selectedState.priceGuaranteeList) && selectedState.priceGuaranteeList.length > 0) {
        const pfcList = selectedState.priceGuaranteeList.map(pg => new PUBGetPriceGuaranteeItemsPFC(pg.id));

        const asyncCall = async () => {
          setPrivilegeFetching(true);
          await handlePrivilegeRequest(pfcList);
          /**
           * Wait for evaluate privileges.
           */
          setPriceGuaranteeListDisplayed(selectedState.priceGuaranteeList);
          setPrivilegeFetching(false);
        };
        asyncCall().then();
      } else {
        setPriceGuaranteeListDisplayed(selectedState.priceGuaranteeList);
        setPrivilegeFetching(false);
      }
    },
    [handlePrivilegeRequest, selectedState.priceGuaranteeList]
  );

  return (
    <PriceGuaranteeItemsPublished
      isFetching={selectedState.isFetching || isPrivilegeFetching}
      onCleanPriceGuaranteeItems={callbacks.handleClearPriceGuaranteeItemListPUB}
      onGetPriceGuaranteeItems={callbacks.handleGetPriceGuaranteeItemListPUB}
      onGetPriceGuaranteeList={callbacks.handleGetPriceGuaranteeListPUB}
      priceGuaranteeItemList={selectedState.priceGuaranteeItemList}
      priceGuaranteeList={priceGuaranteeListDisplayed}
    />
  );
};

export default PriceGuaranteeItemsPublishedConnector;
