import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { Code, SwitchSelector, WeekPicker } from '../../../../../common/components/forms';
import { Button, Modal } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';

import './LimitationProductionCorridorCreationForm.scss';

const DefaultValues = {
  getModelMask: (modelGroup) => `${modelGroup}---`
};

const LimitationProductionCorridorCreationForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const [modelMask, setModelMask] = React.useState(DefaultValues.getModelMask(props.modelGroup));
  const [colour, setColour] = React.useState(null);
  const [interior, setInterior] = React.useState(null);
  const [equipmentType, setEquipmentType] = React.useState(null);
  const [equipment, setEquipment] = React.useState(null);
  const [weekFrom, setWeekFrom] = React.useState(null);
  const [weekTo, setWeekTo] = React.useState(null);

  const equipmentTypeSelectorMapper = (equipmentTypeMapper) => ({
    content: equipmentTypeMapper,
    value: equipmentTypeMapper
  });

  const handleSaveEquipmentType = (opt) => {
    setEquipmentType(opt.value);
  };

  const handleGetEquipmentType = () => {
    if (equipmentType != null) {
      return equipmentType.toUpperCase();
    } else {
      if (equipment != null) {
        return 'PKG';
      } else {
        return null;
      }
    }
  };

  const handleSave = () => {
    props.onSave({
      modelGroup: props.modelGroup,
      modelMask: modelMask ? modelMask.toUpperCase() : null,
      colour: colour ? colour.toUpperCase() : null,
      interior: interior ? interior.toUpperCase() : null,
      equipmentType: handleGetEquipmentType(),
      equipment: equipment ? equipment.toUpperCase() : null,
      weekFrom: weekFrom ? weekFrom.toUpperCase() : props.limitWeekFrom,
      weekTo: weekTo ? weekTo.toUpperCase() : props.limitWeekTo
    });
  };

  const validModelMask = modelMask.startsWith(props.modelGroup);

  return (
    <Modal
      className='ovex-pvt-LimitationProductionCorridorCreationForm'
      header={lsi.getLSIItem('PVT.PAGE_TITLE.LIMITATION_NEW')}
      onClose={props.onClose}
      shown={props.shown}
    >
      <Forms.Form
        onCancel={props.onClose}
        onSave={handleSave}
      >
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.GROUP')}
          name='modelGroup'
          readOnly
          value={props.modelGroup}
        />
        <Code
          feedback={validModelMask ? Forms.InputMixin.INITIAL_FEEDBACK : Forms.InputMixin.ERROR_FEEDBACK}
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.MASK')}
          maxLength={6}
          message={validModelMask ? '' : lsi.getLSIItem('PVT.FORM_INVALID_INPUT.MODEL_MASK')}
          name='modelMask'
          onChange={setModelMask}
          pattern='^[a-zA-Z0-9]{3}[a-zA-Z0-9-]{3}$'
          required
          upperCase
          value={modelMask}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.COLOUR')}
          maxLength={4}
          name='colour'
          onChange={setColour}
          pattern='^$|^[a-zA-Z0-9]{4}$'
          upperCase
          value={colour}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.INTERIOR')}
          maxLength={2}
          name='interior'
          onChange={setInterior}
          pattern='^$|^[a-zA-Z0-9]{2}$'
          upperCase
          value={interior}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.EQUIPMENT')}
          maxLength={3}
          name='equipment'
          onChange={setEquipment}
          pattern='^$|^[a-zA-Z0-9]{3}$'
          upperCase
          value={equipment}
        />
        <SwitchSelector
          hidden={equipment == null || equipment === ''}
          items={props.equipmentTypesAvailable.map(equipmentTypeSelectorMapper)}
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.EQUIPMENT_TYPE')}
          name={'equipmentType'}
          onChange={handleSaveEquipmentType}
          value={equipmentType}
        />
        <WeekPicker
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_FROM')}
          name='weekFrom'
          onChange={setWeekFrom}
          tooltip={lsi.getLSIItem('PVT.TOOLTIP.PRODUCTION_CORRIDOR_INPUT_WEEK_DEFAULT_VALUE', props.limitWeekFrom)}
          value={weekFrom}
          weekFrom={props.limitWeekFrom}
          weekTo={weekTo == null ? props.limitWeekTo : weekTo}
        />
        <WeekPicker
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_TO')}
          name='weekTo'
          onChange={setWeekTo}
          tooltip={lsi.getLSIItem('PVT.TOOLTIP.PRODUCTION_CORRIDOR_INPUT_WEEK_DEFAULT_VALUE', props.limitWeekTo)}
          value={weekTo}
          weekFrom={weekFrom == null ? props.limitWeekFrom : weekFrom}
          weekTo={props.limitWeekTo}
        />
        <Forms.Controls
          buttonCancelProps={Button.formControlsProps[Button.type.SECONDARY]}
          buttonSubmitProps={Button.formControlsProps[Button.type.PRIMARY]}
        />
      </Forms.Form>
    </Modal>
  );
});

LimitationProductionCorridorCreationForm.propTypes = {
  equipmentTypesAvailable: PropTypes.arrayOf(PropTypes.string).isRequired,
  limitWeekFrom: PropTypes.string.isRequired,
  limitWeekTo: PropTypes.string.isRequired,
  modelGroup: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  shown: PropTypes.bool
};

LimitationProductionCorridorCreationForm.defaultProps = {
  shown: false
};

export default LimitationProductionCorridorCreationForm;
