import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class RefreshStorageDataPFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PGA__REFRESH_STORAGE_DATA);
  }

}

export default RefreshStorageDataPFC;