import React from 'react';
import PropTypes from 'prop-types';
import { CommissionSpecificationDTOVLD, PriceGuaranteeDTOVLD, ConditionHeaderRowDTOVLD } from '@ovex/price-guarantee-web-api';

import { OvexAGTable } from '../../../../../common/components/ag-grid';
import { LsiContext } from '../../../../../common/contexts';

import { getColumnDefinitions, columnTypes } from './ValidationResultHeaderTable.columnDefs';
import { exportExcel } from './ValidationResultHeaderTable.exportParams';

import './ValidationResultHeaderTable.scss';

const propTypes = {
  commission: PropTypes.instanceOf(CommissionSpecificationDTOVLD).isRequired,
  conditionHeaderRowList: PropTypes.arrayOf(PropTypes.instanceOf(ConditionHeaderRowDTOVLD)).isRequired,
  priceGuarantee: PropTypes.instanceOf(PriceGuaranteeDTOVLD).isRequired
};

const defaultProps = {
};

const ValidationResultHeaderTable = (props) => {
  const lsi = React.useContext(LsiContext);
  const { commission, conditionHeaderRowList, priceGuarantee, calculatedSellingDateTo } = props;

  const tableContext = React.useMemo(
    () => ({
      commission,
      priceGuarantee,
      calculatedSellingDateTo,
      excelExportParams: exportExcel
    }),
    [commission, priceGuarantee, calculatedSellingDateTo]
  );
  const columnDefs = React.useMemo(
    () => getColumnDefinitions(lsi),
    [lsi]
  );

  return (
    <OvexAGTable
      agContext={tableContext}
      className='ovex-pg-ValidationResultHeaderTable'
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      domLayout='autoHeight'
      rowData={conditionHeaderRowList}
    />
  );
};

ValidationResultHeaderTable.propTypes = propTypes;
ValidationResultHeaderTable.defaultProps = defaultProps;

export default ValidationResultHeaderTable;
