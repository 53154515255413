import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const commissionsApi = ApiServiceInstances.annualTarget.commissionsApi;

export const GET_COMMISSION_LIST_DEALER_REQUEST = 'GET_COMMISSION_LIST_DEALER_REQUEST';
export const GET_COMMISSION_LIST_DEALER_SUCCESS = 'GET_COMMISSION_LIST_DEALER_SUCCESS';
export const GET_COMMISSION_LIST_DEALER_FAILURE = 'GET_COMMISSION_LIST_DEALER_FAILURE';

export const GET_COMMISSION_LIST_IMPORTER_REQUEST = 'GET_COMMISSION_LIST_IMPORTER_REQUEST';
export const GET_COMMISSION_LIST_IMPORTER_SUCCESS = 'GET_COMMISSION_LIST_IMPORTER_SUCCESS';
export const GET_COMMISSION_LIST_IMPORTER_FAILURE = 'GET_COMMISSION_LIST_IMPORTER_FAILURE';

const initialState = {
  commissionList: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMISSION_LIST_DEALER_REQUEST:
    case GET_COMMISSION_LIST_IMPORTER_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_COMMISSION_LIST_DEALER_SUCCESS:
    case GET_COMMISSION_LIST_IMPORTER_SUCCESS: {
      return {
        ...state,
        commissionList: action.payload,
        isFetching: false
      };
    }
    case GET_COMMISSION_LIST_DEALER_FAILURE:
    case GET_COMMISSION_LIST_IMPORTER_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetCommissionListForDealer = (commissionsParamsUuid) => {
  return {
    [CALL_API]: {
      apiFunction: () => commissionsApi.getCommissionListForDealerWithHttpInfo(commissionsParamsUuid),
      types: [GET_COMMISSION_LIST_DEALER_REQUEST, GET_COMMISSION_LIST_DEALER_SUCCESS, GET_COMMISSION_LIST_DEALER_FAILURE]
    }
  };
};

export const handleGetCommissionListForImporter = (commissionsParamsUuid) => {
  return {
    [CALL_API]: {
      apiFunction: () => commissionsApi.getCommissionListForImporterWithHttpInfo(commissionsParamsUuid),
      types: [GET_COMMISSION_LIST_IMPORTER_REQUEST, GET_COMMISSION_LIST_IMPORTER_SUCCESS, GET_COMMISSION_LIST_IMPORTER_FAILURE]
    }
  };
};
