import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { LsiContext } from '../../../common/contexts';
import { Page } from '../../../common/components';
import { FormsContextSection } from '../../../common/components/forms';
import { useConfirmModal } from '../../../common/hooks';

import SwapCommissionPvtParams from './SwapCommissionPvtParams/SwapCommissionPvtParams';
import SwapCommissionPvtButtonBar from './SwapCommissionPvtButtonBar/SwapCommissionPvtButtonBar';
import SwapCommissionPvtTable from './SwapCommissionPvtTable/SwapCommissionPvtTable';
import SwapCommissionResultModal from './SwapCommissionResultModal/SwapCommissionResultModal';

import './SwapCommissionPvt.scss';

const propTypes = {
  commission: PropTypes.object,
  commissionList: PropTypes.array,
  isFetching: PropTypes.bool,
  onGetCommissionList: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSwapCommission: PropTypes.func.isRequired
};
const defaultProps = {
  commission: null,
  commissionList: null,
  isFetching: false,
  onGetCommissionList: null,
  onSwapCommission: null
};

const SwapCommissionPvt = (params) => {
  const lsi = useContext(LsiContext);

  const { commission, commissionList, onGetCommissionList, onSwapCommission, onReset, isFetching } = params;

  const [commissionNumber, setCommissionNumber] = useState(null);
  const [selectedCommission, setSelectedCommission] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleGetCommissionList = React.useCallback(
    (formValues) => {
      const commissionNumber = formValues.commissionNumber;
      setCommissionNumber(commissionNumber);
      onGetCommissionList(commissionNumber);
    },
    [onGetCommissionList]
  );

  const handleReset = React.useCallback(
    () => {
      setCommissionNumber(null);
      setSelectedCommission(null);
      onReset();
    },
    [onReset]
  );

  const handleCloseModal = React.useCallback(
    () => {
      setShowModal(false);
      setSelectedCommission(null);
      setCommissionNumber(null);
      onReset();
    },
    [onReset]
  );

  const renderSwapCommissionPvtSuccess = () => {
    return !showModal ? null : (
      <SwapCommissionResultModal
        commissionA={commission}
        commissionB={selectedCommission}
        onClose={handleCloseModal}
        shown={showModal}
      />
    );
  };
  const handleSwapCommission = React.useCallback(async () => {
    const swapCommissionsPvt = {
      commissionA: commission,
      commissionB: selectedCommission
    };
    try {
      await onSwapCommission(swapCommissionsPvt);
      setShowModal(true);
    } catch (e) {
      setSelectedCommission(null);
      throw e;
    }

  }, [commission, onSwapCommission, selectedCommission]);

  const [renderConfirmModal, handleConfirmAction] = useConfirmModal(
    handleSwapCommission,
    { content: lsi.getLSIItem('PVT.CONFIRM_MESSAGE.SWAP_COMMISSION_REQUEST') }
  );

  const handleConfirmActionFix = (commission && selectedCommission) ? handleConfirmAction : null;

  const handleAdeptCommissionSelected = (adeptCommission) => {
    setSelectedCommission(adeptCommission);
  };

  return (
    <Page
      className='ovex-pvt-SwapCommissionPvt'
      header={lsi.getLSIItem('PVT.PAGE_TITLE.SWAP_COMMISSION_PVT')}
      loading={isFetching}
    >
      <FormsContextSection>
        <SwapCommissionPvtParams
          commissionNumber={commissionNumber}
          onReset={handleReset}
          onSubmit={handleGetCommissionList}
        />
        <SwapCommissionPvtButtonBar
          onConfirmAction={handleConfirmActionFix}
        />
      </FormsContextSection>
      <SwapCommissionPvtTable
        commission={commission}
        commissionList={commissionList}
        onAdeptCommissionSelected={handleAdeptCommissionSelected}
      />
      {renderSwapCommissionPvtSuccess()}
      {renderConfirmModal()}
    </Page>
  );
};

SwapCommissionPvt.propTypes = propTypes;
SwapCommissionPvt.defaultProps = defaultProps;

export default SwapCommissionPvt;