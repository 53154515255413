import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class PGRGetPriceGuaranteeReportImporterPFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PGR__GET_PRICE_GUARANTEE_REPORT_IMPORTER);
  }

}

export default PGRGetPriceGuaranteeReportImporterPFC;
