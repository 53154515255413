import { PGISpecificationSnapshotEnum } from '../../../../../../utils/const';
import AbstractSpecificationSnapshotGroupColumn from '../AbstractSpecificationSnapshotGroupColumn';

class CurrentSpecificationSnapshotGroupColumn extends AbstractSpecificationSnapshotGroupColumn {

  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.CURRENT;
  }

}

const currentSpecificationSnapshotGroupColumn = new CurrentSpecificationSnapshotGroupColumn();

export default currentSpecificationSnapshotGroupColumn;