import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class SCPGetCommissionsSwapDealerFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PVT_SCP__GET_COMMISSIONS_SWAP_DEALER);
  }

}

export default SCPGetCommissionsSwapDealerFunctionalityContext;