/* eslint-disable no-unreachable */

class AbstractRowUIDefinition {

  /** @abstract */
  getCode = () => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  excelExportValueFormatter = (processCellForExportParams) => {
    return processCellForExportParams.value;
  }
}

export default AbstractRowUIDefinition;