import { simpleNullishValueFormatter } from '../../../../../../../common/utils/formatters';

import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class ProduceWeekFirstRUID extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.PRODUCE_WEEK_FIRST__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.COMMISSION_PRODUCE_WEEK_FIRST_TO');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRODUCE_FIRST');
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    const { priceGuarantee } = context;

    return priceGuarantee.commissionProduceWeekFirstTo;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.MAX_VALUE', value);
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { commission } = context;

    return commission.commissionCurrentData.produceWeekFirst;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    return simpleNullishValueFormatter(value, '-');
  }
}

const produceWeekFirstRUID = new ProduceWeekFirstRUID();

export default produceWeekFirstRUID;