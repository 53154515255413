import { NegotiationTypeEnum } from '../../../utils/const';

const NegotiationRowType = {
  ADDITION: 'ADDITION',
  CONTRACT: 'CONTRACT',
  CLOSURE: 'CLOSURE',

  YEAR_SETTINGS: 'YEAR_SETTINGS',

  resolveRowTypeFromNegotiationType: (negotiationType) => {
    if (negotiationType === NegotiationTypeEnum.ADDITION) {
      return NegotiationRowType.ADDITION;
    } else if (negotiationType === NegotiationTypeEnum.CONTRACT) {
      return NegotiationRowType.CONTRACT;
    } else if (negotiationType === NegotiationTypeEnum.CLOSURE) {
      return NegotiationRowType.CLOSURE;
    }
  }
};

export default NegotiationRowType;