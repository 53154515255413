import React from 'react';
import PropTypes from 'prop-types';
import { RestrictionWeekLimitsDTO } from '@ovex/pvt-web-api';

import { LsiContext } from '../../../../common/contexts';
import { FilterForm, WeekPicker } from '../../../../common/components/forms';

const propTypes = {
  className: PropTypes.string,
  onFilterSubmit: PropTypes.func.isRequired,
  weekLimits: PropTypes.instanceOf(RestrictionWeekLimitsDTO)
};

const defaultProps = {
  className: null,
  weekLimits: null
};

const AvailableCapacityFilter = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const { className, onFilterSubmit, weekLimits } = props;
  const { bottomMax, middle, topMax } = weekLimits || {};

  const classNames = ['ovex-AvailableCapacityFilter'];
  className && classNames.push(className);

  const [weekFrom, setWeekFrom] = React.useState(bottomMax);
  const [weekTo, setWeekTo] = React.useState(middle);

  const resetWeekFrom = () => {
    setWeekFrom(bottomMax);
  };
  const resetWeekTo = () => {
    setWeekTo(middle);
  };

  React.useEffect(
    resetWeekFrom,
    [bottomMax]
  );
  React.useEffect(
    resetWeekTo,
    [middle]
  );

  const handleSubmit = (formValues) => onFilterSubmit({
    weekFrom: formValues.weekFrom || null,
    weekTo: formValues.weekTo || null
  });

  const handleReset = () => {
    resetWeekFrom();
    resetWeekTo();
  };

  return (
    <FilterForm
      className={classNames.join(' ')}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <WeekPicker
        label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_FROM')}
        name="weekFrom"
        onChange={setWeekFrom}
        value={weekFrom}
        weekFrom={bottomMax}
        weekTo={weekTo || topMax}
        width="220px"
      />
      <WeekPicker
        label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_TO')}
        name="weekTo"
        onChange={setWeekTo}
        value={weekTo}
        weekFrom={weekFrom || bottomMax}
        weekTo={topMax}
        width="220px"
      />
    </FilterForm>
  );
});

AvailableCapacityFilter.propTypes = propTypes;
AvailableCapacityFilter.defaultProps = defaultProps;

export default AvailableCapacityFilter;
