import React from 'react';

import NavMenu from '../../../common/components/nav/NavMenu/NavMenu';
import { LsiContext, ModuleContext } from '../../../common/contexts';
import { modulePathPrefix } from '../../config/moduleConfig';
import moduleContextData from '../../config/moduleContextData';
import {
  GetPriceGuaranteeListPFC,
  ITMGetPriceGuaranteeListPFC,
  PGRGetPriceGuaranteeListPFC, PGRGetPriceGuaranteeReportDealerPFC, PGRGetPriceGuaranteeReportImporterPFC,
  PUBGetPriceGuaranteeListPFC,
  VLDGetPriceGuaranteeListPFC, VLDGetCommissionValidationResultDealerPFC, VLDGetCommissionValidationResultImporterPFC,
  RefreshStorageDataPFC
} from '../../privilege/context';
import modulePaths from '../../routes/modulePaths';

const propTypes = {};
const defaultProps = {};

const refreshStorageDataPFC = new RefreshStorageDataPFC();
const getPriceGuaranteeListPFC = new GetPriceGuaranteeListPFC();
const itmGetPriceGuaranteeListPFC = new ITMGetPriceGuaranteeListPFC();
const pgrGetPriceGuaranteeReportDealerPFCList = [new PGRGetPriceGuaranteeListPFC(), new PGRGetPriceGuaranteeReportDealerPFC()];
const pgrGetPriceGuaranteeReportImporterPFCList = [new PGRGetPriceGuaranteeListPFC(), new PGRGetPriceGuaranteeReportImporterPFC()];
const pubGetPriceGuaranteeListPFC = new PUBGetPriceGuaranteeListPFC();
const vldGetCommissionValidationResultDealerPFCList = [new VLDGetPriceGuaranteeListPFC(), new VLDGetCommissionValidationResultDealerPFC()];
const vldGetCommissionValidationResultImporterPFCList = [new VLDGetPriceGuaranteeListPFC(), new VLDGetCommissionValidationResultImporterPFC()];

const PriceGuaranteeNavMenu = () => {
  const lsi = React.useContext(LsiContext);

  const navMenuItems = React.useMemo(
    () => ([
      {
        label: lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.ITEM_PRICE_GUARANTEE_LIST'),
        privilegeFunctionalityContext: getPriceGuaranteeListPFC,
        route: modulePaths.ROUTE_PATH_PRICE_GUARANTEE_LIST
      },
      {
        label: lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.ITEM_PRICE_GUARANTEE_ITEMS_IMPORTER'),
        privilegeFunctionalityContext: itmGetPriceGuaranteeListPFC,
        route: modulePaths.ROUTE_PATH_PRICE_GUARANTEE_ITEMS_LIST
      },
      {
        label: lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.ITEM_PRICE_GUARANTEE_ITEMS_DEALER'),
        privilegeFunctionalityContext: pubGetPriceGuaranteeListPFC,
        route: modulePaths.ROUTE_PATH_PRICE_GUARANTEE_ITEMS_PUBLISHED_LIST
      },
      {
        label: lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.ITEM_REPORT_PRICE_GUARANTEE_IMPORTER'),
        privilegeFunctionalityContext: pgrGetPriceGuaranteeReportImporterPFCList,
        route: modulePaths.ROUTE_PATH_PRICE_GUARANTEE_REPORT_IMPORTER
      },
      {
        label: lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.ITEM_REPORT_PRICE_GUARANTEE_DEALER'),
        privilegeFunctionalityContext: pgrGetPriceGuaranteeReportDealerPFCList,
        route: modulePaths.ROUTE_PATH_PRICE_GUARANTEE_REPORT_DEALER
      },
      {
        label: lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.ITEM_REPORT_COMMISSION_VALIDATOR_IMPORTER'),
        privilegeFunctionalityContext: vldGetCommissionValidationResultImporterPFCList,
        route: modulePaths.ROUTE_PATH_COMMISSION_VALIDATOR_IMPORTER
      },
      {
        label: lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.ITEM_REPORT_COMMISSION_VALIDATOR_DEALER'),
        privilegeFunctionalityContext: vldGetCommissionValidationResultDealerPFCList,
        route: modulePaths.ROUTE_PATH_COMMISSION_VALIDATOR_DEALER
      },
      {
        label: lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.REFRESH_STORAGE_DATA'),
        privilegeFunctionalityContext: refreshStorageDataPFC,
        route: modulePaths.ROUTE_PATH_PRICE_GUARANTEE_REFRESH_STORAGE_DATA
      }
    ]),
    [lsi]
  );

  return (
    <ModuleContext.Provider value={moduleContextData} >
      <NavMenu
        menuLabel={lsi.getLSIItem('PRICE_GUARANTEE.NAV_MENU.LABEL')}
        navMenuItems={navMenuItems}
        pathPrefix={modulePathPrefix}
      />
    </ModuleContext.Provider>
  );
};

PriceGuaranteeNavMenu.propTypes = propTypes;
PriceGuaranteeNavMenu.defaultProps = defaultProps;

export default PriceGuaranteeNavMenu;
