import React from 'react';
import PropTypes from 'prop-types';
import { mdiAlertOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { PriceGuaranteeDTOVLD } from '@ovex/price-guarantee-web-api';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { LsiContext } from '../../../../common/contexts';
import { dateFormatter } from '../../../../common/utils/formatters';

import './ValidationResultNotFound.scss';

const propTypes = {
  commissionNumber: PropTypes.string,
  marketingActionCode: PropTypes.string,
  priceGuarantee: PropTypes.instanceOf(PriceGuaranteeDTOVLD)
};

const defaultProps = {
  commissionNumber: null,
  marketingActionCode: null,
  priceGuarantee: null
};

const ValidationResultNotFound = (props) => {
  const lsi = React.useContext(LsiContext);
  const { priceGuarantee } = props;

  const commissionCreationDateToFormatted = dateFormatter(priceGuarantee?.commissionCreationDateTo, lsi.getFormatters());

  return (
    <Bricks.Well
      borderRadius='4px'
      className='ovex-ValidationResultNotFound'
      colorSchema='warning'
      elevation='1'
    >
      <Bricks.Div
        className='ovex-ValidationResultNotFound-icon'
      >
        <Icon
          path={mdiAlertOutline}
          size={1.2}
        />
      </Bricks.Div>
      <Bricks.Div
        className='ovex-ValidationResultNotFound-message'
      >
        <p><strong>{lsi.getLSIItem('PRICE_GUARANTEE.ERROR_MESSAGE.VALIDATOR_RESULT_NOTFOUND.MAIN_1')}</strong></p>
        <p>{lsi.getLSIItem('PRICE_GUARANTEE.ERROR_MESSAGE.VALIDATOR_RESULT_NOTFOUND.MAIN_2')}</p>
        <ul>
          <li>{lsi.getLSIItem('PRICE_GUARANTEE.ERROR_MESSAGE.VALIDATOR_RESULT_NOTFOUND.REASON_1')}</li>
          <li>{lsi.getLSIItem('PRICE_GUARANTEE.ERROR_MESSAGE.VALIDATOR_RESULT_NOTFOUND.REASON_2')}</li>
          <li>{lsi.getLSIItem('PRICE_GUARANTEE.ERROR_MESSAGE.VALIDATOR_RESULT_NOTFOUND.REASON_3', [commissionCreationDateToFormatted])}</li>
          <li>{lsi.getLSIItem('PRICE_GUARANTEE.ERROR_MESSAGE.VALIDATOR_RESULT_NOTFOUND.REASON_4', [commissionCreationDateToFormatted])}</li>
          <li>{lsi.getLSIItem('PRICE_GUARANTEE.ERROR_MESSAGE.VALIDATOR_RESULT_NOTFOUND.REASON_5')}</li>
        </ul>
      </Bricks.Div>
    </Bricks.Well>
  );
};

ValidationResultNotFound.propTypes = propTypes;
ValidationResultNotFound.defaultProps = defaultProps;

export default ValidationResultNotFound;
