import { CONTRACT_COLUMN_IDS } from './DealerContractsATModelGroupViewTable.columnDefs';

const handleProcessCellCallback = (params) => {
  if ([CONTRACT_COLUMN_IDS.CONTRACT_NAME, CONTRACT_COLUMN_IDS.CONTRACT_STATUS, CONTRACT_COLUMN_IDS.CONTRACT_MODEL].includes(params.column.colId)) {
    return params.column.colDef.valueFormatter(params);
  }
  return params.value;
};

export const exportExcel = {
  processCellCallback: handleProcessCellCallback,

  skipRowGroups: true,
  fileName: 'OVEX3_report_ROC'
};

export const exportExcelWithAggregation = {
  processCellCallback: handleProcessCellCallback,

  fileName: 'OVEX3_report_ROC_with_aggregation'
};
