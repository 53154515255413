import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const dealersApi = ApiServiceInstances.annualTarget.dealersApi;

export const CLEAN_NEGOTIATION_DEALERS = 'CLEAN_NEGOTIATION_DEALERS';

export const GET_NEGOTIATION_DEALERS_REQUEST = 'GET_NEGOTIATION_DEALERS_REQUEST';
export const GET_NEGOTIATION_DEALERS_SUCCESS = 'GET_NEGOTIATION_DEALERS_SUCCESS';
export const GET_NEGOTIATION_DEALERS_FAILURE = 'GET_NEGOTIATION_DEALERS_FAILURE';

const initialState = {
  dealerList: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEGOTIATION_DEALERS_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_NEGOTIATION_DEALERS_SUCCESS: {
      return { ...state, isFetching: false,
        dealerList: action.payload
      };
    }
    case GET_NEGOTIATION_DEALERS_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case CLEAN_NEGOTIATION_DEALERS:
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleCleanNegotiationDealers = () => {
  return {
    type: CLEAN_NEGOTIATION_DEALERS
  };
};

export const getNegotiationDealers = (year, negotiationType) => {
  return {
    [CALL_API]: {
      apiFunction: dealersApi.getNegotiationDealersWithHttpInfo.bind(dealersApi, year, negotiationType),
      types: [GET_NEGOTIATION_DEALERS_REQUEST, GET_NEGOTIATION_DEALERS_SUCCESS, GET_NEGOTIATION_DEALERS_FAILURE]
    }
  };
};

