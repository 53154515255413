import { getExportExcelSkipRowGroupsItem } from '../../../../common/components/ag-grid/contextMenu/excelExport';
import { handleGetDefaultContextMenuItems } from '../../../../common/components/ag-grid';

import { exportExcel } from './PriceGuaranteeItemPublishedTable.exportParams';

export const handleGetContextMenuItems = (getContextMenuItemsParams ) => {
  const node = getContextMenuItemsParams.node;

  if (node.group) {
    return [
      getExportExcelSkipRowGroupsItem(getContextMenuItemsParams, exportExcel)
    ];
  }
  return getContextMenuReadOnlyMode(getContextMenuItemsParams);
};

const getContextMenuReadOnlyMode = (getContextMenuItemsParams) => {
  return handleGetDefaultContextMenuItems(getContextMenuItemsParams, exportExcel);
};
