import { sumNullableAccumulator } from '../../../utils/helpers';

class DealerContractRow {

  isEditableContractData = () => {
    return this.contract != null && this.contract.isEditableByStatus();
  };

  discardChanges = () => {
    if (this.isEditableContractData()) {
      this.contract.discardChanges();
    }
  };

  _getModelGroupAnnualTargetList = () => this.contract.modelGroupAnnualTargetList.filter(mg => mg.isTotalRow());
  _getPeriodAnnualTargetList = () => this.contract.periodAnnualTargetList.filter(p => p.isPeriodRow());

  getSumATDealerNumber = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atDealerNumber), null);
  getSumATRecommendedNumber = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atRecommendedNumber), null);
  getSumATApprovalNumber = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atApprovalNumber), null);
  getSumATApprovedNumber = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atApprovedNumber), null);
  getSumATStatisticsInvoicedCount = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atStatisticsInvoicedCount), null);
  getSumATStatisticsProductionCount = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atStatisticsProductionCount), null);
  getSumATStatisticsPlannedCount = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atStatisticsPlannedCount), null);
  getSumATStatisticsDoneCount = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.getATStatisticsDoneCount()), null);
  getSumATStatisticsRemainsCount = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.getATStatisticsRemainsCount()), null);
  getSumATStatisticsPotentialProductionCount = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atStatisticsPotentialProductionCount), null);
  getSumATStatisticsPotentialPlannedCount = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.atStatisticsPotentialPlannedCount), null);
  getSumATStatisticsPotentialCount = () => this._getModelGroupAnnualTargetList().reduce((acc, mg) => sumNullableAccumulator(acc, mg.getATStatisticsPotentialCount()), null);

  getSumATSDealerNumber = () => this._getPeriodAnnualTargetList().reduce((acc, p) => sumNullableAccumulator(acc, p.atsDealerNumber), null);
  getSumATSRecommendedNumber = () => this._getPeriodAnnualTargetList().reduce((acc, p) => sumNullableAccumulator(acc, p.atsRecommendedNumber), null);
  getSumATSApprovalNumber = () => this._getPeriodAnnualTargetList().reduce((acc, p) => sumNullableAccumulator(acc, p.atsApprovalNumber), null);
  getSumATSApprovedNumber = () => this._getPeriodAnnualTargetList().reduce((acc, p) => sumNullableAccumulator(acc, p.atsApprovedNumber), null);
  getSumATSStatisticsDoneNumber = () => this._getPeriodAnnualTargetList().reduce((acc, p) => sumNullableAccumulator(acc, p.atsStatisticsSoldCount), null);
  getSumATSStatisticsRemainsNumber = () => this._getPeriodAnnualTargetList().reduce((acc, p) => sumNullableAccumulator(acc, p.atsStatisticsRemainsCount), null);

}

DealerContractRow.prototype.headquartersDealer = undefined;
DealerContractRow.prototype.contract = undefined;

export default DealerContractRow;
