
class PriceGuaranteeItemEditableCell {

  /**
   * @param {*} value
   */
  constructor(value) {
    this._value = value;
    this._valueOriginal = value;
    this._editable = true;
  }

  /**
   * Disable cell editing
   * @return {PriceGuaranteeItemEditableCell}
   */
  nonEditable = () => {
    this._editable = false;
    return this;
  };

  /**
   * Value getter settings.
   * Only for calculated values!
   * @param {Function} valueGetter
   * @return {PriceGuaranteeItemEditableCell}
   */
  valueGetter = (valueGetter) => {
    this._valueGetter = valueGetter;
    return this;
  };

  /**
   * @param {Function} validator
   * @param {Object} params
   * @return {PriceGuaranteeItemEditableCell}
   */
  validator = (validator, params = undefined) => {
    this._validator = validator;
    this._validatorParams = params;
    return this;
  };
  /**
   * @param {Function} parser
   * @return {PriceGuaranteeItemEditableCell}
   */
  parser = (parser) => {
    this._parser = parser;
    return this;
  };
  /**
   * @param {Function} booleanComparator
   * @return {PriceGuaranteeItemEditableCell}
   */
  booleanComparator = (booleanComparator) => {
    this._booleanComparator = booleanComparator;
    return this;
  };

  /**
   * @return {boolean}
   */
  isEditable = () => {
    return this._editable;
  };
  /**
   * @return {boolean}
   */
  isValid = () => {
    if (typeof this._validator === 'function') {
      return !!this._validator(this.getValue(), this._validatorParams);
    }
    return true;
  };
  /**
   * @return {boolean}
   */
  isModified = () => {
    if (this.isEditable()) {
      const value = this.getValue();
      if (typeof this._booleanComparator === 'function') {
        return !this._booleanComparator(value, this._valueOriginal);
      }
      return value !== this._valueOriginal;
    }
    return false;
  };
  /**
   * @param {*} value
   * @return {*}
   */
  parseValue = (value) => {
    const v = typeof value === 'string' ? value.trim() : value;
    if (v == null || v === '') {
      return null;
    }
    if (typeof this._parser === 'function') {
      return this._parser(v);
    }
    return v;
  };

  getValue = () => {
    if (typeof this._valueGetter === 'function') {
      return this._valueGetter();
    }
    return this._value;
  };
  setValue = (value) => {
    this._value = value;
  };

  discardChanges = () => {
    this.setValue(this._valueOriginal);
  };

}

/**
 * @type {String}
 */
PriceGuaranteeItemEditableCell.prototype.colId = undefined;

export default PriceGuaranteeItemEditableCell;
