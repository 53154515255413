import PropTypes from 'prop-types';
import React from 'react';
import {
  ColumnDataDTO,
  ContractsATModelGroupsViewDTO,
  DealerContractsATModelGroupsViewFilterDataDTO
} from '@ovex/annual-target-web-api';

import { FormsContextSection } from '../../../common/components/forms';
import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { useAlertBusOvex } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';

import DealerContractsATModelGroupViewButtonBar from './DealerContractsATModelGroupViewButtonBar/DealerContractsATModelGroupViewButtonBar';
import DealerContractsATModelGroupViewFilter from './DealerContractsATModelGroupViewFilter/DealerContractsATModelGroupViewFilter';
import DealerContractsATModelGroupViewTable from './DealerContractsATModelGroupViewTable/DealerContractsATModelGroupViewTable';
import { exportExcel, exportExcelWithAggregation } from './DealerContractsATModelGroupViewTable/DealerContractsATModelGroupViewTable.exportParams';

import './DealerContractsATModelGroupView.scss';

const propTypes = {
  columnDataList: PropTypes.arrayOf(PropTypes.instanceOf(ColumnDataDTO)),
  contractATModelGroupsViewList: PropTypes.arrayOf(PropTypes.instanceOf(ContractsATModelGroupsViewDTO)),
  filterData: PropTypes.instanceOf(DealerContractsATModelGroupsViewFilterDataDTO),
  isFetching: PropTypes.bool,
  onGetDealerContractsATModelGroupsViewList: PropTypes.func.isRequired,
  valuesFilter: PropTypes.object
};

const defaultProps = {
  columnDataList: null,
  contractATModelGroupsViewList: null,
  filterData: null,
  isFetching: true,
  valuesFilter: null
};

const DealerContractsATModelGroupView = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const valuesFilterSubmitted = props.valuesFilter || DealerContractsATModelGroupViewFilter.valuesDefault;
  const onGetDealerContractsATModelGroupsViewList = props.onGetDealerContractsATModelGroupsViewList;
  const handleReloadDealerContractsATModelGroupsView = React.useCallback(
    (filterValues) => {
      const asyncCall = async () => {
        try {
          await onGetDealerContractsATModelGroupsViewList(filterValues);
        } catch (e) {
          handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_FAILURE', AlertTypeEnum.WARNING);
        }
      };
      asyncCall().then();
    },
    [onGetDealerContractsATModelGroupsViewList, handleAddAlertSimple]
  );
  React.useEffect(
    () => handleReloadDealerContractsATModelGroupsView(DealerContractsATModelGroupViewFilter.valuesDefault),
    [handleReloadDealerContractsATModelGroupsView]
  );

  const aggRef = React.useRef(null);

  const handleExport = React.useCallback(
    () => aggRef.current && aggRef.current.api.exportDataAsExcel(exportExcel),
    []
  );
  const handleExportWithAggregation = React.useCallback(
    () => aggRef.current && aggRef.current.api.exportDataAsExcel(exportExcelWithAggregation),
    []
  );

  return (
    <Page
      className={'ovex-DealerContractsATModelGroupView'}
      header={lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.ANNUAL_TARGET_IMPORTER_VIEW_AT_MODEL_GROUPS')}
      loading={props.isFetching}
    >
      <FormsContextSection>
        <DealerContractsATModelGroupViewFilter
          filterData={props.filterData}
          onSubmit={handleReloadDealerContractsATModelGroupsView}
          valuesFilterSubmitted={valuesFilterSubmitted}
        />
        <DealerContractsATModelGroupViewButtonBar
          onExport={handleExport}
          onExportWithAggregation={handleExportWithAggregation}
        />
      </FormsContextSection>
      <DealerContractsATModelGroupViewTable
        contractATModelGroupsViewList={props.contractATModelGroupsViewList}
        dynamicColumnDataList={props.columnDataList}
        ref={aggRef}
      />
    </Page>
  );
});

DealerContractsATModelGroupView.propTypes = propTypes;
DealerContractsATModelGroupView.defaultProps = defaultProps;

export default DealerContractsATModelGroupView;