const SEPARATOR_CHAR = '_';
const getInputNamePrefix = (name) => name + SEPARATOR_CHAR;

/**
 * Input name for period component.
 * @param {string} name
 * @param {number} idx
 * @param {number} unitOrder
 * @return {string}
 */
export const getQuarterInputName = (name, idx = undefined, unitOrder = undefined) => {
  if (isNaN(idx) && !isNaN(unitOrder)) {
    return getInputNamePrefix(name) + SEPARATOR_CHAR + unitOrder + 'Q';
  }
  return getInputNamePrefix(name) + idx;
};

/**
 * @param a
 * @param b
 * @return {number}
 */
export const quarterSorter = (a, b) => a.unitOrder - b.unitOrder;

