import { ComponentDTO } from '@ovex/privilege-web-api';
import { v4 as uuidv4 } from 'uuid';

/**
 * @abstract
 */
class AbstractFunctionalityContext {

  constructor() {
    if (new.target === AbstractFunctionalityContext) {
      throw new Error('Cannot construct Abstract instances directly');
    }
    this._uuid = uuidv4();
    this._result = null;
    this._webResult = null;
  }

  getUuid = () => {
    return this._uuid;
  };

  /**
   * @param {string} functionalityCode
   */
  setFunctionalityCode = (functionalityCode) => {
    this._functionalityCode = functionalityCode;
  };

  /**
   * @param {object} params
   */
  setParams = (params) => {
    this._params = params;
  };

  /**
   * @param {object} webParams
   */
  setWebParams = (webParams) => {
    this._webParams = webParams;

    webParams != null && this.evaluateWebResult();
  };

  /**
   * @return {object|null} - PrivilegedComponentDTO
   */
  getResult = () => {
    return this._result;
  };

  /**
   * @param {object} result
   */
  setResult = (result) => {
    this._result = result;
  };

  /**
   * @return {boolean}
   */
  hasWebResult = () => {
    return this._webResult != null;
  };

  /**
   * @return {PrivilegeWebResult|null}
   */
  getWebResult = () => {
    return this._webResult;
  };

  /**
   * Override this function if functionality context works with **webParams**.
   */
  evaluateWebResult = () => {
    this._webResult = null;
  };

  getKey = () => {
    const keyArr = [this._functionalityCode];

    if (this._params != null) {
      Object.keys(this._params).forEach(key => {
        const value = this._params[key];
        keyArr.push(key, value);
      });
    }

    return keyArr.join('_');
  };

  /**
   * @return {ComponentDTO}
   */
  toComponentDTO = () => {
    return ComponentDTO.constructFromObject({
      componentId: this._uuid,
      functionalityCode: this._functionalityCode,
      itemIdentifierMap: this._params
    }, null);
  };

  /**
   * @param {string} uuid
   * @return {boolean} *true* if equals, otherwise *false*
   */
  equalsByUuid = (uuid) => {
    return this._uuid === uuid;
  };

}

export default AbstractFunctionalityContext;