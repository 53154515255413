import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetDealerContractsATModelGroupViewListFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_VIEWS__GET_DEALER_CONTRACTS_AT_MODEL_GROUPS);
  }

}

export default GetDealerContractsATModelGroupViewListFunctionalityContext;