import React from 'react';
import PropTypes from 'prop-types';

import { DatePicker } from '../../../forms';

import './AGDatePicker.scss';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.instanceOf(Date)
};

const defaultProps = {
  className: null,
  value: null
};

const AGDatePicker = React.forwardRef((props, ref) => {
  const datePicker = React.useRef();

  React.useEffect(
    () => {
      datePicker.current && datePicker.current.open();
    },
    []
  );

  React.useImperativeHandle(ref, () => ({
    isPopup: () => {
      return true;
    },
    getValue: () => {
      return datePicker.current?.getValue();
    }
  }));

  return (
    <DatePicker
      className='ovex-ag-AGDatePicker'
      inputHeight={`${props.node.rowHeight - 3}px`}
      inputWidth={`${props.column.getActualWidth()-2}px`}
      ref={datePicker}
      value={props.value}
    />
  );
});

AGDatePicker.propTypes = propTypes;
AGDatePicker.defaultProps = defaultProps;

export default AGDatePicker;
