import PropTypes from 'prop-types';
import React from 'react';
import { PriceGuaranteeDTO, AccountingBasisDTO } from '@ovex/price-guarantee-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { useAlertBusOvex, useConfirmModal } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';
import { PriceGuaranteeStatusEnum } from '../../utils/const';

import PriceGuaranteeButtonBar from './PriceGuaranteeModalForms/PriceGuaranteeButtonBar/PriceGuaranteeButtonBar';
import PriceGuaranteeTable from './PriceGuaranteeTable/PriceGuaranteeTable';
import PriceGuaranteeCreationModalForm from './PriceGuaranteeModalForms/PriceGuaranteeCreationModalForm/PriceGuaranteeCreationModalForm';
import PriceGuaranteeModalForm from './PriceGuaranteeModalForms/PriceGuaranteeModalForm/PriceGuaranteeModalForm';
import './PriceGuaranteeList.scss';
import { handleEditableInputs } from './PriceGuaranteeModalForms/PriceGuaranteeEditInputRules';

const propTypes = {
  accountingBasisList: PropTypes.arrayOf(PropTypes.instanceOf(AccountingBasisDTO)),
  isFetching: PropTypes.bool,
  onClearAccountingBasis: PropTypes.func.isRequired,
  onCreatePriceGuarantee: PropTypes.func,
  onGetAccountingBasisList: PropTypes.func.isRequired,
  onGetPriceGuaranteeList: PropTypes.func.isRequired,
  onShiftPriceGuaranteeStatus: PropTypes.func,
  onUpdatePriceGuarantee: PropTypes.func,
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTO))
};

const defaultProps = {
  accountingBasisList: null,
  isFetching: true,
  onCreatePriceGuarantee: undefined,
  onShiftPriceGuaranteeStatus: undefined,
  onUpdatePriceGuarantee: undefined,
  priceGuaranteeList: null
};

const PriceGuaranteeList = (props) => {
  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const onGetPriceGuaranteeList = props.onGetPriceGuaranteeList;
  const handleReloadPriceGuaranteeList = React.useCallback(
    async () => {
      try {
        await onGetPriceGuaranteeList();
      } catch (e) {
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_LIST_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetPriceGuaranteeList, handleAddAlertSimple]
  );
  React.useEffect(
    () => {
      handleReloadPriceGuaranteeList().then();
    },
    [handleReloadPriceGuaranteeList]
  );

  const [ priceGuaranteeCreationModalFormShown, setPriceGuaranteeCreationModalFormShown ] = React.useState({ shown: false });
  const handleShowPriceGuaranteeCreationModalForm = () => setPriceGuaranteeCreationModalFormShown({ shown: true, editRules: handleEditableInputs() });
  const handleHidePriceGuaranteeCreationModalForm = () => setPriceGuaranteeCreationModalFormShown({ shown: false });

  const onCreatePriceGuarantee = props.onCreatePriceGuarantee;
  const handleCreatePriceGuarantee = async (priceGuaranteeNewDTO) => {
    if (!onCreatePriceGuarantee) {
      return;
    }
    try {
      await onCreatePriceGuarantee(priceGuaranteeNewDTO);

      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.CREATE_PRICE_GUARANTEE_SUCCESS');
      handleHidePriceGuaranteeCreationModalForm();
      handleReloadPriceGuaranteeList().then();
    } catch (e) {
      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.CREATE_PRICE_GUARANTEE_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  const renderPriceGuaranteeCreationModalForm = () => {
    return !priceGuaranteeCreationModalFormShown.shown ? null : (
      <PriceGuaranteeCreationModalForm
        accountingBasisList={props.accountingBasisList}
        editRules={priceGuaranteeCreationModalFormShown.editRules}
        onClose={handleHidePriceGuaranteeCreationModalForm}
        onGetAccountingBasisList={props.onGetAccountingBasisList}
        onSave={handleCreatePriceGuarantee}
        shown={priceGuaranteeCreationModalFormShown.shown}
      />
    );
  };

  const [ priceGuaranteeModalForm, setPriceGuaranteeModalForm ] = React.useState({ shown: false, priceGuarantee: null });
  const handleShowPriceGuaranteeModalForm = (priceGuarantee) => setPriceGuaranteeModalForm({ shown: true, priceGuarantee: priceGuarantee, editRules: handleEditableInputs(priceGuarantee.status) });
  const handleHidePriceGuaranteeModalForm = () => {
    props.onClearAccountingBasis();
    setPriceGuaranteeModalForm({ shown: false, priceGuarantee: null });
  };

  const [ priceGuaranteeDetailModal, setPriceGuaranteeDetailModal ] = React.useState({ shown: false, priceGuarantee: null });
  const handleShowPriceGuaranteeDetailModal = (priceGuarantee) => setPriceGuaranteeDetailModal({ shown: true, priceGuarantee: priceGuarantee, editRules: handleEditableInputs('READ_ONLY') });
  const handleHidePriceGuaranteeDetailModal = () => setPriceGuaranteeDetailModal({ shown: false, priceGuarantee: null });

  const onUpdatePriceGuarantee = props.onUpdatePriceGuarantee;
  const handleUpdatePriceGuarantee = async (priceGuaranteeNewDTO) => {
    if (!onUpdatePriceGuarantee) {
      return;
    }
    try {
      await onUpdatePriceGuarantee(priceGuaranteeNewDTO);

      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.UPDATE_PRICE_GUARANTEE_SUCCESS');
      handleHidePriceGuaranteeModalForm();
      handleReloadPriceGuaranteeList().then();
    } catch (e) {
      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.UPDATE_PRICE_GUARANTEE_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  const renderShowPriceGuaranteeModalForm = () => {
    return !priceGuaranteeModalForm.shown ? null : (
      <PriceGuaranteeModalForm
        accountingBasisList={props.accountingBasisList}
        editRules={priceGuaranteeModalForm.editRules}
        onClose={handleHidePriceGuaranteeModalForm}
        onGetAccountingBasisList={props.onGetAccountingBasisList}
        onSave={handleUpdatePriceGuarantee}
        priceGuarantee={priceGuaranteeModalForm.priceGuarantee}
        shown={priceGuaranteeModalForm.shown}
      />
    );
  };

  const renderShowPriceGuaranteeDetailModal = () => {
    return !priceGuaranteeDetailModal.shown ? null : (
      <PriceGuaranteeModalForm
        accountingBasisList={props.accountingBasisList}
        editRules={priceGuaranteeDetailModal.editRules}
        onClose={handleHidePriceGuaranteeDetailModal}
        onGetAccountingBasisList={props.onGetAccountingBasisList}
        priceGuarantee={priceGuaranteeDetailModal.priceGuarantee}
        shown={priceGuaranteeDetailModal.shown}
      />
    );
  };

  const onShiftPriceGuaranteeStatus = props.onShiftPriceGuaranteeStatus;
  const handleShiftPriceGuaranteeStatus = async ({ priceGuaranteeId, priceGuaranteeStatusTo }) => {
    try {
      if (priceGuaranteeStatusTo === PriceGuaranteeStatusEnum.PUBLISHED) {
        handleAddAlertSimple('PRICE_GUARANTEE.WARNING_MESSAGE.PRICE_GUARANTEE_PUBLISH_IN_PROGRESS', AlertTypeEnum.WARNING);
      }
      await onShiftPriceGuaranteeStatus(priceGuaranteeId, priceGuaranteeStatusTo);

      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.SHIFT_PRICE_GUARANTEE_STATUS_SUCCESS');
      handleReloadPriceGuaranteeList().then();
    } catch (e) {
      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.SHIFT_PRICE_GUARANTEE_STATUS_FAILURE', AlertTypeEnum.WARNING);
    }
  };

  const [ renderConfirmShiftModal, handleShowConfirmShiftModal ] = useConfirmModal(handleShiftPriceGuaranteeStatus);
  const handleShowConfirmShiftModalParam = (priceGuaranteeId, priceGuaranteeStatusTo) => {
    let infoMsg;
    if (priceGuaranteeStatusTo === PriceGuaranteeStatusEnum.COMPLETED) {
      infoMsg = lsi.getLSIItem('PRICE_GUARANTEE.CONFIRM_MESSAGE.PG_SHIFT_STATE_TO_COMPLETED');
    }
    const confirmMsg = lsi.getLSIItem(`PRICE_GUARANTEE.CONFIRM_MESSAGE.PRICE_GUARANTEE_SHIFT_STATUS_TO.${priceGuaranteeStatusTo}`);
    handleShowConfirmShiftModal && handleShowConfirmShiftModal({
      confirmModalProps: { content: [<p>{infoMsg}</p>, <p>{confirmMsg}</p>] },
      priceGuaranteeId,
      priceGuaranteeStatusTo
    });
  };

  return (
    <Page
      className='ovex-pg-PriceGuaranteeList'
      header={lsi.getLSIItem('PRICE_GUARANTEE.PAGE_TITLE.PRICE_GUARANTEE_LIST')}
      loading={props.isFetching}
    >
      <PriceGuaranteeButtonBar
        onReload={handleReloadPriceGuaranteeList}
        onShowPriceGuaranteeCreationModalForm={handleShowPriceGuaranteeCreationModalForm}
      />
      <PriceGuaranteeTable
        onShiftPriceGuaranteeStatus={handleShowConfirmShiftModalParam}
        onShowPGDetail={handleShowPriceGuaranteeDetailModal}
        onShowPriceGuaranteeUpdateModalForm={handleShowPriceGuaranteeModalForm}
        priceGuaranteeList={props.priceGuaranteeList}
      />
      {renderPriceGuaranteeCreationModalForm()}
      {renderShowPriceGuaranteeModalForm()}
      {renderShowPriceGuaranteeDetailModal()}
      {renderConfirmShiftModal()}
    </Page>
  );
};

PriceGuaranteeList.propTypes = propTypes;
PriceGuaranteeList.defaultProps = defaultProps;

export default PriceGuaranteeList;
