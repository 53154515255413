import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { Checkbox, Code, WeekPicker } from '../../../../../common/components/forms';
import { Button, Modal } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';

import './ModelGroupProductionCorridorCreationForm.scss';

const ModelGroupProductionCorridorCreationForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const [ modelGroup, setModelGroup ] = React.useState(null);
  const [ weekFrom, setWeekFrom ] = React.useState(null);
  const [ weekTo, setWeekTo ] = React.useState(null);
  const [ annualTarget, setAnnualTarget ] = React.useState(true);
  const [ stockCar, setStockCar ] = React.useState(true);

  const handleSave = () => {
    props.onSave({
      modelGroup: modelGroup ? modelGroup.toUpperCase() : null,
      weekFrom: weekFrom ? weekFrom.toUpperCase() : null,
      weekTo: weekTo ? weekTo.toUpperCase() : null,
      annualTarget: annualTarget,
      stockCar: stockCar
    });
  };

  return (
    <Modal
      className='ovex-pvt-ModelGroupProductionCorridorCreationForm'
      header={lsi.getLSIItem('PVT.PAGE_TITLE.MODEL_GROUP_NEW')}
      onClose={props.onClose}
      shown={props.shown}
    >
      <Forms.Form
        onCancel={props.onClose}
        onSave={handleSave}
      >
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.GROUP')}
          maxLength={3}
          name='modelGroup'
          onChange={setModelGroup}
          pattern='^[a-zA-Z0-9]{3}$'
          required
          upperCase
          value={modelGroup}
        />
        <WeekPicker
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_FROM')}
          name='weekFrom'
          onChange={setWeekFrom}
          required
          value={weekFrom}
          weekTo={weekTo}
        />
        <WeekPicker
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.WEEK_TO')}
          name='weekTo'
          onChange={setWeekTo}
          required
          value={weekTo}
          weekFrom={weekFrom}
        />
        <Checkbox
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.ANNUAL_TARGETS')}
          labelPosition='right'
          name='annualTarget'
          onChange={setAnnualTarget}
          tooltip={lsi.getLSIItem('PVT.TOOLTIP.ANNUAL_TARGETS_CHECKED')}
          value={annualTarget}
        />
        <Checkbox
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.STOCK_CARS')}
          labelPosition='right'
          name='stockCar'
          onChange={setStockCar}
          tooltip={lsi.getLSIItem('PVT.TOOLTIP.STOCK_CAR_CHECKED')}
          value={stockCar}
        />
        <Forms.Controls
          buttonCancelProps={Button.formControlsProps[Button.type.SECONDARY]}
          buttonSubmitProps={Button.formControlsProps[Button.type.PRIMARY]}
        />
      </Forms.Form>
    </Modal>
  );
});

ModelGroupProductionCorridorCreationForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  shown: PropTypes.bool
};

ModelGroupProductionCorridorCreationForm.defaultProps = {
  shown: false
};

export default ModelGroupProductionCorridorCreationForm;
