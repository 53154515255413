import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { LsiContext } from '../../../contexts';

import './DatePicker.scss';

const DateRangePickerComponent = (props, ref) => {
  const lsi = React.useContext(LsiContext);

  const inputAttrs = props.inputHeight != null ? { style: { height: props.inputHeight } } : null;
  const style = props.inputHeight != null ? { minHeight: props.inputHeight } : null;

  return (
    <Forms.DatePicker
      afterRangeMessage={lsi.getLSIItem('COMMON.FORMS.DATE_PICKER.AFTER_RANGE_MESSAGE')}
      beforeRangeMessage={lsi.getLSIItem('COMMON.FORMS.DATE_PICKER.BEFORE_RANGE_MESSAGE')}
      bgStyle='underline'
      className={['ovex-forms-date-picker', props.className].join(' ')}
      colorSchema='green'
      disabled={props.disabled}
      inputAttrs={inputAttrs}
      inputColWidth='xs12'
      inputWidth={props.inputWidth}
      label={props.label}
      labelColWidth='xs12'
      name={props.name}
      nanMessage={lsi.getLSIItem('COMMON.FORMS.DATE_PICKER.NAN_MESSAGE')}
      onChange={props.onChange}
      parent={props.parent}
      readOnly={props.readOnly}
      ref_={ref}
      required={props.required}
      style={style}
      tooltip={props.tooltip}
      value={props.value}
    />
  );
};

const DatePicker = React.forwardRef(DateRangePickerComponent);
DatePicker.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

DateRangePickerComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputHeight: PropTypes.string,
  inputWidth: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
};

DateRangePickerComponent.defaultProps = {
  className: null,
  disabled: false,
  inputHeight: null,
  inputWidth: null,
  label: null,
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  tooltip: null,
  value: null
};

export default DatePicker;
