
/**
 * support module does not contain url path, yet
 * @type {{}}
 */

const propTypes = {};
const defaultProps = {};

const SupportRoutes = () => {

  return null;
};

SupportRoutes.propTypes = propTypes;
SupportRoutes.defaultProps = defaultProps;

export default SupportRoutes;
