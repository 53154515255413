
export const COLUMN_IDS = {
  COMMISSION: 'commission',
  COMMISSION_YEAR: 'commissionYear',
  OWNER_DEALER_NUMBER: 'ownerDealerNumber',
  BRANCH_OWNER_DEALER_NUMBER: 'branchOwnerDealerNumber',
  INVOICED_DEALER_NUMBER: 'invoicedDealerNumber',
  MODEL_GROUP: 'modelGroup',
  STATUS: 'status',
  PRE_PRODUCTION_WEEK: 'preProductionWeek',
  PRODUCTION_WEEK: 'productionWeek',
  INVOICING_DATE: 'invoicingDate',
  SELLING_DATE: 'sellingDate',
  AT_ALWAYS_Q4: 'atAlwaysQ4',
  STATUS_INTERVAL: 'statusInterval',
  AGGREGATION_TYPE: 'aggregationType'
};
