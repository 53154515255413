import React from 'react';
import PropTypes from 'prop-types';
import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import './BooleanIconCellRenderer.scss';

const propTypes = {
  value: PropTypes.bool
};

const defaultProps = {
  value: null
};

const BooleanIconCellRenderer = React.forwardRef((props, ref) => {
  /**
   * Documentation for styling cell renderer component (agReactContainer)
   * @see https://www.ag-grid.com/documentation/react/component-styling/
   */
  React.useImperativeHandle(
    ref,
    () => ({
      getReactContainerClasses: () => (['ovex-pg-BooleanIconCellRenderer-agContainer'])
    })
  );

  let icon;
  if (props.value != null) {
    if (props.value) {
      icon = (
        <Icon
          color='#4ba82e'
          path={mdiCheck}
          size={0.85}
        />
      );
    } else {
      icon = (
        <Icon
          color='gray'
          path={mdiClose}
          size={0.85}
        />
      );
    }
  }

  return (
    <div
      className='ovex-pg-BooleanIconCellRenderer'
    >
      {icon}
    </div>
  );
});

BooleanIconCellRenderer.propTypes = propTypes;
BooleanIconCellRenderer.defaultProps = defaultProps;

export default BooleanIconCellRenderer;
