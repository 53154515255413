import {
  ClaimTypeHeaderFilterEnum as ClaimTypeHeaderFilterEnumClass,
  ClaimTypeItemEnum as ClaimTypeItemEnumClass,
  CommissionStatusIntervalEnum as CommissionStatusIntervalEnumClass,
  ConditionResultHeaderEnum as ConditionResultHeaderEnumClass,
  ConditionResultItemEnum as ConditionResultItemEnumClass,
  CurrencyEnum as CurrencyEnumClass,
  PGISpecificationSnapshotEnum as PGISpecificationSnapshotEnumClass,
  PGISpecificationTypeEnum as PGISpecificationTypeEnumClass,
  PriceGuaranteeStatusEnum as PriceGuaranteeStatusEnumClass,
  PriceGuaranteeTypeEnum as PriceGuaranteeTypeEnumClass,
  SalesAccountTypeEnum as SalesAccountTypeEnumClass,
  SpecificationChangeTypeEnum as SpecificationChangeTypeEnumClass
} from '@ovex/price-guarantee-web-api';

export const PriceGuaranteeStatusEnum = new PriceGuaranteeStatusEnumClass();
export const PriceGuaranteeTypeEnum = new PriceGuaranteeTypeEnumClass();

export const CurrencyEnum = new CurrencyEnumClass();
export const PGISpecificationSnapshotEnum = new PGISpecificationSnapshotEnumClass();
export const PGISpecificationTypeEnum = new PGISpecificationTypeEnumClass();
export const SalesAccountTypeEnum = new SalesAccountTypeEnumClass();
export const SpecificationChangeTypeEnum = new SpecificationChangeTypeEnumClass();

export const ClaimTypeItemEnum = new ClaimTypeItemEnumClass();
export const ClaimTypeHeaderEnum = new ClaimTypeHeaderFilterEnumClass();
export const ConditionResultItemEnum = new ConditionResultItemEnumClass();
export const ConditionResultHeaderEnum = new ConditionResultHeaderEnumClass();

export const CommissionStatusIntervalEnum = new CommissionStatusIntervalEnumClass();

export const PGI_SPECIFICATION_SNAPSHOT_LIST = Object.values(PGISpecificationSnapshotEnum);
export const PGI_SPECIFICATION_TYPE_LIST = Object.values(PGISpecificationTypeEnum);
export const SALES_ACCOUNT_TYPE_LIST = Object.values(SalesAccountTypeEnum);
export const SPECIFICATION_CHANGE_TYPE_LIST = Object.values(SpecificationChangeTypeEnum);
export const SPECIFICATION_CHANGE_TYPE_EMPTY = 'EMPTY';
export const SPECIFICATION_CHANGE_TYPE_WITH_EMPTY_VALUE_LIST = [...SPECIFICATION_CHANGE_TYPE_LIST, SPECIFICATION_CHANGE_TYPE_EMPTY];
export const CLAIM_TYPE_HEADER_ENUM_LIST = Object.values(ClaimTypeHeaderEnum);

export const SPECIFICATION_TYPE_MODEL_LIST = [PGISpecificationTypeEnum.MODEL, PGISpecificationTypeEnum.MODEL_NEG];

export const PGI_SPECIFICATION_TYPE_POSITIVE_LIST = [
  PGISpecificationTypeEnum.MODEL,
  PGISpecificationTypeEnum.COLOUR,
  PGISpecificationTypeEnum.INTERIOR,
  PGISpecificationTypeEnum.PACKAGE
];

export const CURRENCY_SYMBOL = {
  [CurrencyEnum.CZK]: 'Kč',
  [CurrencyEnum.EUR]: '\u20AC'
};

export const PGTypeNoCheckClientList = [
  PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK_SHOW,
  PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK,
  PriceGuaranteeTypeEnum.NOCHECK__STOCK_SHOW
];
export const PGTypeCheckClientList = [
  PriceGuaranteeTypeEnum.CHECK__CLIENT,
  PriceGuaranteeTypeEnum.CHECK__SHOW,
  PriceGuaranteeTypeEnum.CHECK__CLIENT_SHOW
];

export const BID = {
  CZ: '260',
  SK: '654'
};

export const LIMIT_PG_REPORT_EXPORT_ROWS = 220_000;

export const PGI__TOTAL_AMOUNT_NOT_EQUAL = 0;
