import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import webModuleRegister from '../../webModuleRegister';
import { alertBus } from '../modules/main/alertBus';
import { menuItems } from '../modules/main/userMenu';

const rootReducer = (history) => {
  const moduleCombineReducers = webModuleRegister.getReduxDataList().reduce(
    (obj, moduleReduxData) => {
      obj[moduleReduxData.reduxPropName] = moduleReduxData.combineReducers;
      return obj;
    },
    {}
  );

  return combineReducers({
    router: connectRouter(history),

    // main module reducers
    main: combineReducers({
      alertBus: alertBus,
      userMenu: menuItems
    }),

    ...moduleCombineReducers
  });
};

export default rootReducer;