import { UpdateContractAnnualTargetSalesRecommendedFunctionalityContext } from '../../../privilege/context';
import { calculateRemainsNumber } from '../../../utils/statisticsHelpers';
import { AG_GROUP_TOTAL } from '../utils/const';

import PeriodAnnualTargetSalesRow from './PeriodAnnualTargetSalesRow';

const PeriodAnnualTargetSalesConverter = {

  /**
   * @param {Array.<module:model/BranchDTO>} branchList
   * @param {string} contractStatus
   * @param {Array.<string>} roleList - user role list
   * @return {Array.<PeriodAnnualTargetSalesRow>}
   */
  fromBranchList: (branchList, contractStatus, roleList) => {
    if (Array.isArray(branchList) && branchList.length > 0) {
      const returnedFlatMap = branchList.flatMap(branch =>
        branch.periodAnnualTargetSalesList.map(pats => constructFromObject(branch, pats, contractStatus, roleList)));
      const periodOrderList = [...new Set(returnedFlatMap.map(period => period.periodOrder))];
      const virtualRows = periodOrderList.map(period => constructFromPeriod(period));

      return [...virtualRows, ...returnedFlatMap];
    }
    return [];
  }

};

/**
 * @param {module:model/BranchDTO} branch
 * @param {Object|model/PeriodAnnualTargetSalesDTO} data
 * @param {string} contractStatus
 * @param {Array.<string>} roleList - user role list
 * @return {PeriodAnnualTargetSalesRow} The populated <code>PeriodAnnualTargetSalesRow</code> instance.
 */
const constructFromObject = (branch, data, contractStatus, roleList) => {
  if (data) {

    const dealerNumberCode = branch.hasOwnProperty('dealerDetail') ? branch.dealerDetail.dealerNumber : null;
    const row = new PeriodAnnualTargetSalesRow(dealerNumberCode);

    row.id = data.hasOwnProperty('id') ? data.id : null;
    row.version = data.hasOwnProperty('version') ? data.version : null;
    row.dealerNumberCode = dealerNumberCode;
    row.periodOrder = data.hasOwnProperty('periodOrder') ? data.periodOrder : null;
    row.negotiable = data.hasOwnProperty('negotiable') ? data.negotiable : null;

    row.atsDealerNumber = data.hasOwnProperty('atsDealerNumber') ? data.atsDealerNumber : null;
    row.atsRecommendedNumber = data.hasOwnProperty('atsRecommendedNumber') ? data.atsRecommendedNumber : null;
    row.atsApprovalNumber = data.hasOwnProperty('atsApprovalNumber') ? data.atsApprovalNumber : null;
    row.atsApprovedNumber = data.hasOwnProperty('atsApprovedNumber') ? data.atsApprovedNumber : null;
    row.atsStatisticsSoldCount = data.hasOwnProperty('atsStatisticsSoldCount') ? data.atsStatisticsSoldCount : null;

    row._atsRecommendedNumberOriginal = row.atsRecommendedNumber;
    row.atsStatisticsRemainsCount = calculateRemainsNumber(row.atsApprovedNumber, row.atsStatisticsSoldCount);

    row.privilegeFunctionalityContextObject = {
      updateRecommendedFnCtx: new UpdateContractAnnualTargetSalesRecommendedFunctionalityContext(row.id, { contractStatus: contractStatus, negotiable: row.negotiable, roleList: roleList })
    };

    return row;
  }
  return null;
};

const constructFromPeriod = (period) => {
  if (period) {
    const row = new PeriodAnnualTargetSalesRow(AG_GROUP_TOTAL);

    row.periodOrder = period ? period : null;

    row.privilegeFunctionalityContextObject = {};

    return row;
  }
  return null;
};

export default PeriodAnnualTargetSalesConverter;
