import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecPreviousInteriorCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__PREVIOUS__INTERIOR;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.PREVIOUS;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.INTERIOR;
  }
  /**
   * @override
   * @param {CommissionSpecificationDTOVLD} commission
   */
  commissionConverterValueGetter = (commission) => {
    return commission.commissionPreviousData.carSpecification.interior;
  }

}

const specPreviousInteriorCUID = new SpecPreviousInteriorCUID();

export default specPreviousInteriorCUID;