import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecPreviousPackageNegCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__PREVIOUS__PACKAGE_NEG;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.PREVIOUS;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.PACKAGE_NEG;
  }

}

const specPreviousPackageNegCUID = new SpecPreviousPackageNegCUID();

export default specPreviousPackageNegCUID;