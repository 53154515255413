import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { OvexAGTable } from '../../../../../common/components/ag-grid';
import { LsiContext } from '../../../../../common/contexts';
import { NegotiationTypeEnum } from '../../../../utils/const';
import { useAlertBusOvex } from '../../../../../common/hooks';
import { AlertTypeEnum } from '../../../../../common/objects';
import { YEAR_SHIFT_CURRENT_YEAR } from '../NegotiationModalFrom.consts';
import { isReadOnlyInput } from '../NegotiationModalInputRules';

import { COLUMN_IDS, columnTypes, getColumnDefinitionData } from './NegotiationModelGroupList.columnDefs';
import { updateProductionCapacityRatio } from './NegotiationModelGroupListForm.helpers';
import ModelGroupRow from './rowModel/ModelGroupRow';

import './NegotiationModelGroupListForm.scss';

export const InputNames = {
  modelGroupList: 'modelGroupList'
};

const NegotiationModelGroupListFormComponent = (props, ref) => {
  const lsi = React.useContext(LsiContext);
  const readOnly = !props.onSave;
  const { handleAddAlertSimple } = useAlertBusOvex();
  const table = React.useRef(null);

  const { modelGroupList, atPeriodList, yearShiftList, yearCurrent, productionCapacityRatioList, negotiationType } = props;

  const unitOrderList = atPeriodList.map(p => p.unitOrder);

  const modelGroups = React.useMemo(
    () => {
      return modelGroupList.map(mg => new ModelGroupRow(mg, yearShiftList, unitOrderList, productionCapacityRatioList, negotiationType));
    },
    // Other dependencies (unitOrderList, yearShiftList) aren`t necessary here.
    // This only generates the rows, the update is provided by useEffect.
    /* eslint-disable react-hooks/exhaustive-deps */
    [modelGroupList, productionCapacityRatioList]
  );

  const columnDefinitionData = getColumnDefinitionData(lsi, props.negotiationType, yearShiftList, unitOrderList, yearCurrent);

  React.useEffect(
    () => {
      if (table.current != null) {
        const modelGroupsRows = [];
        table.current.api.forEachLeafNode(node => modelGroupsRows.push(node.data));

        updateProductionCapacityRatio(modelGroupsRows, atPeriodList);

        table.current.api.refreshCells({ columns: [...columnDefinitionData.columnIdCurrentYearList, COLUMN_IDS.NEGOTIABLE], force: true });
      }
    },
    // Other dependencies (columnDefinitionData) aren`t necessary here.
    /* eslint-disable react-hooks/exhaustive-deps */
    [atPeriodList]
  );

  React.useImperativeHandle(
    ref,
    () => ({
      isValid: () => {
        const modelGroupsRows = [];
        table.current && table.current.api.forEachLeafNode(node => modelGroupsRows.push(node.data));

        const isValidCurrentYear = modelGroupsRows.every(mg => mg.isValid([YEAR_SHIFT_CURRENT_YEAR], atPeriodList));
        if (!isValidCurrentYear) {
          handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.MODEL_NEGOTIATION_CURRENT_YEAR_SUM_OR_NULL_FAILURE', AlertTypeEnum.WARNING);
          return isValidCurrentYear;
        }

        const followingYearShiftList = yearShiftList.filter(ys => ys !== YEAR_SHIFT_CURRENT_YEAR);
        const isValidFollowingYears = modelGroupsRows.every(mg => mg.isValid(followingYearShiftList, atPeriodList));
        if (!isValidFollowingYears) {
          handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.MODEL_NEGOTIATION_FOLLOWING_YEARS_SUM_OR_NULL_FAILURE', AlertTypeEnum.WARNING);
        }
        return isValidFollowingYears;
      },
      save: () => {
        const modelGroupsRows = [];
        table.current && table.current.api.forEachLeafNode(node => modelGroupsRows.push(node.data));
        props.onSave && props.onSave({ formId: props.formId, values: modelGroupsRows });
      }
    })
  );

  const classNames = ['ovex-NegotiationModelGroupListForm'];
  props.className && classNames.push(props.className);

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      <OvexAGTable
        agContext={{
          atPeriodList: atPeriodList,
          periodColumnIdsToRefresh: columnDefinitionData.columnIdCurrentYearList,
          readOnly: isReadOnlyInput(props.editRules, InputNames.modelGroupList, readOnly)
        }}
        columnDefs={columnDefinitionData.columnDefs}
        columnTypes={columnTypes}
        enableRangeSelection
        height='300px'
        ref={table}
        rowData={modelGroups}
      />
    </Bricks.Div>
  );
};

const NegotiationModelGroupListForm = React.memo(React.forwardRef(NegotiationModelGroupListFormComponent));

NegotiationModelGroupListFormComponent.propTypes = {
  atPeriodList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    negotiable: PropTypes.bool.isRequired,
    unitOrder: PropTypes.number.isRequired
  })).isRequired,
  className: PropTypes.string,
  editRules: PropTypes.arrayOf(PropTypes.string),
  formId: PropTypes.string.isRequired,
  modelGroupList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    modelGroup: PropTypes.string.isRequired,
    modelGroupName: PropTypes.string.isRequired,
    modelMixRatio: PropTypes.number.isRequired,
    negotiable: PropTypes.bool,
    productionCapacityATPeriodList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      productionCapacityRatio: PropTypes.number,
      unitOrder: PropTypes.number.isRequired,
      yearShift: PropTypes.number.isRequired
    }))
  })),
  negotiationType: PropTypes.oneOf(Object.keys(NegotiationTypeEnum)).isRequired,
  onSave: PropTypes.func,
  yearCurrent: PropTypes.number.isRequired,
  yearShiftList: PropTypes.arrayOf(PropTypes.number).isRequired
};

NegotiationModelGroupListFormComponent.defaultProps = {
  className: null,
  editRules: null,
  modelGroupList: null,
  onSave: null
};

export default NegotiationModelGroupListForm;
