import { parseDate, parseNumber } from '../../../../../common/utils/helpers';

import { amountValidator } from './PriceGuaranteeItemEditableCell.validators';

/**
 * @param {String|null} value
 * @return {String|null}
 */
export const specificationValueParser = (value) => {
  if (value == null) {
    return null;
  }
  return value.toUpperCase();
};

/**
 * @param {String|null} value
 * @return {String|Number|null}
 */
export const amountValueParser = (value) => {
  if (value == null) {
    return null;
  }
  if (amountValidator(value)) {
    return parseNumber(value.replace(',', '.'));
  }
  return value;
};

/**
 * @param {Date|String|null} value
 * @return {Date|null}
 */
export const dateValueParser = (value) => {
  if (typeof value === 'string') {
    return parseDate(value);
  }
  return value;
};
