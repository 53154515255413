/**
 * @param lsi
 * @return {Function}
 */
import { FORMATTER_CODES } from '../../../utils/consts';

export const booleanAGGridFormatter = (params) => booleanFormatter(params.value, params.context.agLsi);

/**
 * @param {Boolean|String} value
 * @param {Object} lsi
 * @return {string}
 */
export const booleanFormatter = (value, lsi) => {
  if (value === true || value === 'true') {
    return lsi.getLSIItem('COMMON.ENUM_LABELS.BOOLEAN_VALUE.TRUE');
  }
  if (value === false || value === 'false') {
    return lsi.getLSIItem('COMMON.ENUM_LABELS.BOOLEAN_VALUE.FALSE');
  }
  return '';
};

/**
 * @param {string} labelCode
 * @return {string} highlighted label code
 */
export const labelNotFoundFormatter = (labelCode) => `??${labelCode}??`;

/**
 * @param {*} value
 * @param {string} nullFormat - format of null value, default is empty string
 * @return {string|*}
 */
export const simpleNullishValueFormatter = (value, nullFormat = '') => {
  if (value == null) {
    return nullFormat;
  }
  return value;
};

/**
 * @param {number} number
 * @param {object} formatters
 * @return {string|*}
 */
export const integerFormatter = (number, formatters) => formatValue(number, formatters, FORMATTER_CODES.NUMBER_0_DECIMALS);
/**
 * @param {number} number
 * @param {object} formatters
 * @return {string|*}
 */
export const number2Formatter = (number, formatters) => formatValue(number, formatters, FORMATTER_CODES.NUMBER_2_DECIMALS);

/**
 * @param {number} number
 * @param {object} formatters
 * @return {string|*}
 */
export const percent2Formatter = (number, formatters) => formatValue(number, formatters, FORMATTER_CODES.PERCENT_2_DECIMALS);
/**
 * @param {Date} date
 * @param {object} formatters
 * @return {string|*}
 */
export const dateFormatter = (date, formatters) => formatValue(date, formatters, FORMATTER_CODES.DATE);
/**
 * @param {Date} date
 * @param {object} formatters
 * @return {string|*}
 */
export const dateTimeSecondsFormatter = (date, formatters) => formatValue(date, formatters, FORMATTER_CODES.DATE_TIME_SECONDS);

const formatValue = (value, formatters, code) => {
  if (value == null || formatters == null || code == null || formatters[code] == null) {
    return value;
  }
  return formatters[code].format(value);
};

/**
 * @param {string|null} first
 * @param {string|null} second
 * @param {string} separator
 * @return {string}
 */
export const pairStringValueFormatter = (first, second, separator = ' - ') => {
  if (first != null && second != null) {
    return `${first}${separator}${second}`;
  } else if (first != null) {
    return first;
  }
  return second || '';
};

export const quarterPeriodFormatter = (unitOrder) => unitOrder != null ? `Q${unitOrder}` : unitOrder;
