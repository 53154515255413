import React from 'react';
import PropTypes from 'prop-types';

import { LsiContext } from '../../../common/contexts';

import UserImitationValidatorDzcExists from './UserImitationValidatorDzcExists';
import UserDetailInfo from './UserDetailInfo/UserDetailInfo';
import UserDetailInfoNotFound from './UserDetailInfoNotFound/UserDetailInfoNotFound';

const propTypes = {
  fetching: PropTypes.bool,
  userImitation: PropTypes.object,
  userValidationStatus: PropTypes.string
};
const defaultProps = {
  fetching: true,
  userImitation: null,
  userValidationStatus: null
};

const UserDetailInfoContentResolver = (props) => {
  const lsi = React.useContext(LsiContext);
  const { userValidationStatus, userImitation } = props;

  if (UserImitationValidatorDzcExists.isDataFound(userValidationStatus)) {
    return (
      <UserDetailInfo
        header={lsi.getLSIItem('SUPPORT.USER_IMITATION.USER_OWN')}
        userInfo={userImitation}
      />
    );
  } else if (UserImitationValidatorDzcExists.isNotFound(userValidationStatus)) {
    return (
      <UserDetailInfoNotFound />
    );
  }
  return null;
};

UserDetailInfoContentResolver.propTypes = propTypes;
UserDetailInfoContentResolver.defaultProps = defaultProps;

export default UserDetailInfoContentResolver;