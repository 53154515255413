import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const balanceApi = ApiServiceInstances.pvt.balanceApi;

export const GET_BALANCE_REQUEST = 'GET_BALANCE_REQUEST';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const GET_BALANCE_FAILURE = 'GET_BALANCE_FAILURE';

export const UPDATE_BALANCE_REQUEST = 'UPDATE_BALANCE_REQUEST';
export const UPDATE_BALANCE_SUCCESS = 'UPDATE_BALANCE_SUCCESS';
export const UPDATE_BALANCE_FAILURE = 'UPDATE_BALANCE_FAILURE';

const initialState = {
  balanceData: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BALANCE_REQUEST:
      return { ...state, isFetching: true };
    case GET_BALANCE_SUCCESS:
      return { ...state, isFetching: false, balanceData: action.payload };
    case GET_BALANCE_FAILURE:
      return { ...state, error: action.payload.error, isFetching: false };
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetBalance = () => {
  return {
    [CALL_API]: {
      apiFunction: balanceApi.getBalanceWithHttpInfo.bind(balanceApi),
      types: [GET_BALANCE_REQUEST, GET_BALANCE_SUCCESS, GET_BALANCE_FAILURE]
    }
  };
};

export const handleUpdateBalance = (balanceUpdate) => {
  return {
    [CALL_API]: {
      apiFunction: balanceApi.updateBalanceWithHttpInfo.bind(balanceApi, balanceUpdate),
      types: [UPDATE_BALANCE_REQUEST, UPDATE_BALANCE_SUCCESS, UPDATE_BALANCE_FAILURE]
    }
  };
};
