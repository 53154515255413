import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { handleResetState } from '../../redux/modules/swapCommissionPvt/swap-commission-pvt';

import SwapCommissionPvt from './SwapCommissionPvt';

const propTypes = {
  onGetCommissionListAction: PropTypes.func.isRequired,
  onSwapCommissionAction: PropTypes.func.isRequired
};
const defaultProps = {
  onGetCommissionListAction: null,
  onSwapCommissionAction: null
};

const SwapCommissionPvtConnector = (params) => {

  const { onGetCommissionListAction, onSwapCommissionAction } = params;

  const selectedState = useSelector(state => {
    return {
      commission: state.pvt.swapCommissionPvt.commission,
      commissionList: state.pvt.swapCommissionPvt.commissionSwapAdeptList,
      isFetching: state.pvt.swapCommissionPvt.isFetching
    };
  });

  const dispatch = useDispatch();

  const callbacks = React.useMemo(
    () => {
      return {
        handleReset: () => dispatch(handleResetState()),
        handleGetCommissionList: (commissionNumber) => dispatch(onGetCommissionListAction(commissionNumber)),
        handleSwapCommission: (swapCommissionsPvt) => dispatch(onSwapCommissionAction(swapCommissionsPvt))
      };
    },
    [dispatch, onGetCommissionListAction, onSwapCommissionAction]
  );

  return (
    <SwapCommissionPvt
      commission={selectedState.commission}
      commissionList={selectedState.commissionList}
      isFetching={selectedState.isFetching}
      onGetCommissionList={callbacks.handleGetCommissionList}
      onReset={callbacks.handleReset}
      onSwapCommission={callbacks.handleSwapCommission}
    />

  );
};

SwapCommissionPvtConnector.propTypes = propTypes;
SwapCommissionPvtConnector.defaultProps = defaultProps;
export default SwapCommissionPvtConnector;