import { getExportExcelSkipRowGroupsItem } from './excelExport';

export const handleGetDefaultContextMenuItems = (params, excelExportParams = null) => {
  return [
    'copy',
    'copyWithHeaders',
    'paste',
    'separator',
    getExportExcelSkipRowGroupsItem(params, excelExportParams)
  ];
};
