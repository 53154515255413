import canceledCommissionRUID from './impl/CanceledCommissionRUID';
import claimSendByStatusRUID from './impl/ClaimSendByStatusRUID';
import claimSendByDealerRUID from './impl/ClaimSendByDealerRUID';
import clientRUID_CZ from './impl/ClientRUID_CZ';
import clientRUID_SK from './impl/ClientRUID_SK';
import creationDateRUID from './impl/CreationDateRUID';
import produceWeekFirstRUID from './impl/ProduceWeekFirstRUID';
import dealerRUID_CZ from './impl/DealerRUID_CZ';
import invoicingDateRUID from './impl/InvoicingDateRUID';
import orderTypeCurrentRUID_CZ from './impl/OrderTypeCurrentRUID_CZ';
import orderTypeCurrentRUID_SK from './impl/OrderTypeCurrentRUID_SK';
import orderTypePreviousRUID_CZ from './impl/OrderTypePreviousRUID_CZ';
import orderTypePreviousRUID_SK from './impl/OrderTypePreviousRUID_SK';
import sellingDateRUID from './impl/SellingDateRUID';

const rowCellsUIList = [
  canceledCommissionRUID,
  claimSendByStatusRUID,
  claimSendByDealerRUID,
  clientRUID_CZ,
  clientRUID_SK,
  creationDateRUID,
  produceWeekFirstRUID,
  dealerRUID_CZ,
  invoicingDateRUID,
  orderTypeCurrentRUID_CZ,
  orderTypeCurrentRUID_SK,
  orderTypePreviousRUID_CZ,
  orderTypePreviousRUID_SK,
  sellingDateRUID
];

const RowUIDefinitionMap = rowCellsUIList.reduce(
  (obj, rowUIDefinition) => {
    obj[rowUIDefinition.getCode()] = rowUIDefinition;
    return obj;
  },
  {}
);

Object.freeze(RowUIDefinitionMap);

export default RowUIDefinitionMap;