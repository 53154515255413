import React from 'react';
import PropTypes from 'prop-types';
import './WeekCellRenderer.scss';

import { getCellData } from '../../../../../common/components/ag-grid/OvexAGTable';
import ACRowTypeEnum from '../rowModel/ACRowTypeEnum';
import { LsiContext } from '../../../../../common/contexts';
import { UNLIMITED } from '../../../../utils/const';
import AvailableCapacityRow from '../rowModel/AvailableCapacityRow';

const WeekCellRenderer = (props) => {
  const cellData = getCellData(props);
  const classNamesMain = ['AtWeekCellRenderer'];
  const lsi = React.useContext(LsiContext);

  let valueFormatted;

  if (cellData != null) {
    if (ACRowTypeEnum.isROC(cellData.type)) {
      valueFormatted = cellData.getROCValue();
      if (valueFormatted === UNLIMITED) {
        valueFormatted = lsi.getLSIItem('PVT.LABEL.UNLIMITED');
      }
    } else if (ACRowTypeEnum.isRestriction(cellData.type) || ACRowTypeEnum.isQuota(cellData.type)) {
      valueFormatted = cellData.getQuotaValue();
      valueFormatted !== '0' && classNamesMain.push('AtWeekCellRenderer-total--hasValue');
    } else if (ACRowTypeEnum.isQuotaNonRestrictive(cellData.type)) {
      valueFormatted = cellData.getQuotaNonRestrictiveValue();
      valueFormatted !== '0' && classNamesMain.push('AtWeekCellRenderer-total--hasValue');
    }
  }

  return (
    <div className={classNamesMain.join(' ')}>
      <div className="AtWeekCellRenderer-value">
        {valueFormatted}
      </div>
    </div>
  );
};

WeekCellRenderer.propTypes = {
  colDef: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(AvailableCapacityRow).isRequired
};

WeekCellRenderer.defaultProps = {};

export default WeekCellRenderer;
