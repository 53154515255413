import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrivilege } from '../../../common/hooks';
import {
  getDealerContracts,
  handleUpdateContractAnnualTargetRecommended,
  shiftDealerContractWorkflow
} from '../../redux/modules/actions';

import DealerContracts from './DealerContracts';

const DealerContractsConnector = () => {
  const selectedState = useSelector(state => {
    return {
      dealerContractRows: state.at.dealerContracts.dealerContractRows,
      filterData: state.at.dealerContracts.filterData,
      isFetching: state.at.dealerContracts.isFetching,
      valuesFilter: state.at.dealerContracts.valuesFilter,
      workflowResponse: state.at.dealerContracts.workflowResponse
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        getDealerContracts: (filterParams) => dispatch(getDealerContracts(filterParams)),
        shiftDealerContractWorkflow: (dealerContractWorkflow) => dispatch(shiftDealerContractWorkflow(dealerContractWorkflow)),
        handleUpdateContractAnnualTargetRecommended: (updateDTOList) => dispatch(handleUpdateContractAnnualTargetRecommended(updateDTOList))
      };
    },
    [dispatch]
  );

  const [ displayedRows, setDisplayedRows ] = React.useState(selectedState.dealerContractRows);
  const [ isPrivilegeFetching, setPrivilegeFetching ] = React.useState(true);

  const { handlePrivilegeRequest, handleIsPrivileged } = usePrivilege();

  const fnCtxForShiftWorkflowRef = React.useRef([]);
  const fnCtxForEditingRef = React.useRef([]);
  React.useEffect(
    () => {
      fnCtxForShiftWorkflowRef.current = [];
      fnCtxForEditingRef.current = [];

      if (Array.isArray(selectedState.dealerContractRows) && selectedState.dealerContractRows.length > 0) {
        const functionalityContextList = selectedState.dealerContractRows.flatMap(item => {
          const contract = item.contract;

          fnCtxForShiftWorkflowRef.current.push(
            contract.privilegeFunctionalityContextObject.shiftWorkflowCreatedFnCtx,
            contract.privilegeFunctionalityContextObject.shiftWorkflowAreaAdvisorApprovedFnCtx,
            contract.privilegeFunctionalityContextObject.shiftWorkflowAAManagerApprovedFnCtx,
            contract.privilegeFunctionalityContextObject.shiftWorkflowRejectedFnCtx
          );

          const modelGroupAnnualTargetFnCtxList = contract.modelGroupAnnualTargetList.filter(mg => mg.isEditable()).map(mg => mg.privilegeFunctionalityContextObject.updateRecommendedFnCtx);
          const periodAnnualTargetFnCtxList = contract.periodAnnualTargetList
            .filter(p => p.isPeriodRow())
            .map(mg => mg.privilegeFunctionalityContextObject.updateRecommendedFnCtx);

          fnCtxForEditingRef.current.push(...modelGroupAnnualTargetFnCtxList);
          fnCtxForEditingRef.current.push(...periodAnnualTargetFnCtxList);

          return [
            contract.privilegeFunctionalityContextObject.getDealerContractPreviewFnCtx,
            contract.privilegeFunctionalityContextObject.shiftWorkflowCreatedFnCtx,
            contract.privilegeFunctionalityContextObject.shiftWorkflowAreaAdvisorApprovedFnCtx,
            contract.privilegeFunctionalityContextObject.shiftWorkflowAAManagerApprovedFnCtx,
            contract.privilegeFunctionalityContextObject.shiftWorkflowRejectedFnCtx,
            ...modelGroupAnnualTargetFnCtxList,
            ...periodAnnualTargetFnCtxList
          ];
        });

        const asyncCall = async () => {
          setPrivilegeFetching(true);
          await handlePrivilegeRequest(functionalityContextList);
          /**
           * Wait for evaluate privileges and then set *dealerContractRows* to display.
           * For correct checkbox rendering in ag-grid (checkboxes is re-rendered only on set new data rows)
           */
          setDisplayedRows(selectedState.dealerContractRows);
          setPrivilegeFetching(false);
        };
        asyncCall().then();
      } else {
        setDisplayedRows(selectedState.dealerContractRows);
        setPrivilegeFetching(false);
      }
    },
    [handlePrivilegeRequest, selectedState.dealerContractRows]
  );

  const isEditable = !!fnCtxForEditingRef.current && fnCtxForEditingRef.current.some(fnCtx => handleIsPrivileged(fnCtx));
  const canShiftWorkflow = !!fnCtxForShiftWorkflowRef.current && fnCtxForShiftWorkflowRef.current.some(fnCtx => handleIsPrivileged(fnCtx));

  return (
    <DealerContracts
      canShiftWorkflow={canShiftWorkflow}
      dealerContractRows={displayedRows}
      filterData={selectedState.filterData}
      getDealerContracts={callbacks.getDealerContracts}
      isEditable={isEditable}
      isFetching={selectedState.isFetching || isPrivilegeFetching}
      onShiftDealerContractWorkflow={callbacks.shiftDealerContractWorkflow}
      onUpdateContractAnnualTargetRecommended={callbacks.handleUpdateContractAnnualTargetRecommended}
      valuesFilter={selectedState.valuesFilter}
      workflowResponse={selectedState.workflowResponse}
    />
  );
};

export default DealerContractsConnector;