
export const moduleCode = 'SUPPORT';

/**
 * REST API base path
 *
 * @type {string}
 */
export const apiBasePath = '/support/web-api';

/**
 * Path prefix for module paths (routes)
 *
 * @type {string}
 */
export const modulePathPrefix = '/support';

/**
 * Module name for reducer
 * @type {string}
 */
export const moduleReducerName = 'support';

/**
 * Redux selector for user info.
 *
 * @param state
 * @return UserInfoDTO
 */
export const userInfoSelector = state => state.support.userInfo.userInfo;

/**
 * Redux selector for initialization status.
 *
 * @param state
 * @return {string} of InitializationStatus
 */
export const initializationStatusSelector = state => state.support.initialization.status;