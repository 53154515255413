/**
 * Same mapping as in JAVA:
 *  - vwg.skoda.ovex.priceguarantee.privilege.condition.context.common.ContextConstants
 */
const ContextConstants = {
  PRICE_GUARANTEE_ID: 'priceGuaranteeId',
  PRICE_GUARANTEE_STATUS_TO: 'priceGuaranteeStatusTo',
  PG_ITEM_ID: 'pgItemId'
};

export default ContextConstants;
