import { decimalToPercent } from './helpers';
import { NegotiationTypeEnum } from './const';

/**
 * @param {module:model/ContractDTO|module:model/ContractDetailDTO} contract
 * @param {object} lsi
 * @return {string}
 */
export const contractNameFormatter = (contract, lsi) => {
  if (contract == null || lsi == null) {
    return 'Missing required objects!';
  }
  if (contract.negotiation.type === NegotiationTypeEnum.CLOSURE) {
    return lsi.getLSIItem('ANNUAL_TARGET.LABELS.CLOSURE_YEAR', [contract.negotiation.year]);
  }
  if (contract.additionOrder === 0) {
    return lsi.getLSIItem('ANNUAL_TARGET.LABELS.CONTRACT_YEAR', [contract.negotiation.year]);
  }
  return lsi.getLSIItem('ANNUAL_TARGET.LABELS.CONTRACT_YEAR_ADDITION', [contract.negotiation.year, contract.additionOrder]);
};

/**
 * @param {number} decimalValue
 * @param {number} digits
 * @param {string} nullFormat - format of null value, default is empty string
 * @return {string} formatted value
 */
export const decimalToPercentFormatter = (decimalValue, digits = 0, nullFormat = '') => {
  if (decimalValue == null) {
    return nullFormat;
  }
  return `${decimalToPercent(decimalValue).toFixed(digits)} %`;
};

export const modelGroupFormatter = (params) => {
  if (params.value === null || params.node.group) {
    return params.value;
  } else {
    return params.value + ' - ' + params.data.modelGroupName;
  }
};
