import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class CreateAnnualTargetYearFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_NEGOTIATIONS__CREATE_YEAR);
  }

}

export default CreateAnnualTargetYearFunctionalityContext;