import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const dealerContractsApi = ApiServiceInstances.annualTarget.dealerContractsApi;

export const GET_CONTRACT_PREVIEW_REQUEST = 'GET_CONTRACT_PREVIEW_REQUEST';
export const GET_CONTRACT_PREVIEW_SUCCESS = 'GET_CONTRACT_PREVIEW_SUCCESS';
export const GET_CONTRACT_PREVIEW_FAILURE = 'GET_CONTRACT_PREVIEW_FAILURE';

const initialState = {
  contractPreview: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACT_PREVIEW_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_CONTRACT_PREVIEW_SUCCESS: {
      return { ...state, isFetching: false, contractPreview: action.payload };
    }
    case GET_CONTRACT_PREVIEW_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetDealerContractPreview = (contractId) => {
  return {
    [CALL_API]: {
      apiFunction: dealerContractsApi.getDealerContractPreviewWithHttpInfo.bind(dealerContractsApi, contractId),
      types: [GET_CONTRACT_PREVIEW_REQUEST, GET_CONTRACT_PREVIEW_SUCCESS, GET_CONTRACT_PREVIEW_FAILURE]
    }
  };
};

