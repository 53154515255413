import { PrivilegeApi } from '@ovex/privilege-web-api';
import {
  UserInfoApi
} from '@ovex/about-web-api';
import {
  AnnualTargetYearApi,
  CommissionsApi,
  ContractAnnualTargetApi,
  DealerContractsApi,
  DealersApi,
  ProductionCapacityRatioApi,
  NegotiationsApi,
  ModelGroupsApi,
  ViewsApi
} from '@ovex/annual-target-web-api';

import ApiClientInstances from './ApiClientInstances';

/**
 * Api service instances
 */
const ApiServiceInstances = {
  annualTarget: {
    annualTargetYearApi: new AnnualTargetYearApi(ApiClientInstances.annualTarget),
    commissionsApi: new CommissionsApi(ApiClientInstances.annualTarget),
    contractAnnualTargetApi: new ContractAnnualTargetApi(ApiClientInstances.annualTarget),
    dealersApi: new DealersApi(ApiClientInstances.annualTarget),
    productionCapacityRatioApi: new ProductionCapacityRatioApi(ApiClientInstances.annualTarget),
    dealerContractsApi: new DealerContractsApi(ApiClientInstances.annualTarget),
    modelGroupsApi: new ModelGroupsApi(ApiClientInstances.annualTarget),
    negotiationsApi: new NegotiationsApi(ApiClientInstances.annualTarget),
    viewsApi: new ViewsApi(ApiClientInstances.annualTarget)
  },
  about: {
    userInfoApi: new UserInfoApi(ApiClientInstances.about)
  },
  privilege: {
    privilegeApi: new PrivilegeApi(ApiClientInstances.privilege)
  }
};

export default ApiServiceInstances;
