import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonBar } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';

const propTypes = {
  disabled: PropTypes.bool,
  onReload: PropTypes.func
};

const defaultProps = {
  disabled: false,
  onReload: null
};

const PriceGuaranteeItemsButtonBar = (props) => {
  const lsi = React.useContext(LsiContext);

  return (
    <ButtonBar
      className={'ovex-pg-PriceGuaranteeItemsButtonBar'}
    >
      <Button
        content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.RELOAD')}
        disabled={props.disabled}
        onClick={props.onReload}
        type={Button.type.SECONDARY}
      />
    </ButtonBar>
  );
};

PriceGuaranteeItemsButtonBar.propTypes = propTypes;
PriceGuaranteeItemsButtonBar.defaultProps = defaultProps;

export default PriceGuaranteeItemsButtonBar;
