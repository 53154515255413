import { getViewOrderTypeCurrentRowSK } from '../../orderTypeRow/orderTypeViewBySK';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class OrderTypeCurrentRUID_SK extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.ORDER_TYPE_CURRENT__SK__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.ORDER_TYPE_CURRENT');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.ORDER_TYPE_CURRENT');
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    const { priceGuarantee, commission } = context;

    return {
      pgType: priceGuarantee.type,
      statusInterval: commission.commissionPreviousData.statusInterval
    };
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    return getViewOrderTypeCurrentRowSK(value);
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { commission } = context;

    return commission.commissionCurrentData.orderType;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    return value;
  }
}

const orderTypeCurrentRUID_SK = new OrderTypeCurrentRUID_SK();

export default orderTypeCurrentRUID_SK;