import React from 'react';

import { ModuleRoutes, ProtectedRoute } from '../../common/components/routes';
import { useRouteMatchMemo } from '../../common/hooks';
import moduleContextData from '../config/moduleContextData';
import {
  CommissionListDealer,
  CommissionListImporter,
  ContractPreviewPage,
  DealerAnnualTarget,
  DealerContracts,
  DealerContractsATModelGroupView,
  DealerContractsATSPeriodsView,
  Negotiations
} from '../layouts';
import {
  GetAnnualTargetYearsFunctionalityContext,
  GetDealerContractListFunctionalityContext,
  GetDealerContractPreviewFunctionalityContext,
  GetDealerContractsATModelGroupViewListFunctionalityContext,
  GetDealerContractsATSPeriodsViewListFunctionalityContext,
  GetDealerContractsFunctionalityContext,
  GetDealerContractsListFunctionalityContext,
  GetCommissionListForDealerFunctionalityContext,
  GetCommissionListForImporterFunctionalityContext
} from '../privilege/context';

import modulePaths from './modulePaths';

const propTypes = {};
const defaultProps = {};

const getDealerContractsATModelGroupViewListFunctionalityContext = new GetDealerContractsATModelGroupViewListFunctionalityContext();
const getDealerContractsATSPeriodsViewListFunctionalityContext = new GetDealerContractsATSPeriodsViewListFunctionalityContext();
const getDealerContractsFunctionalityContext = new GetDealerContractsFunctionalityContext();
const getDealerContractsListFunctionalityContext = new GetDealerContractsListFunctionalityContext();
const getAnnualTargetYearsFunctionalityContext = new GetAnnualTargetYearsFunctionalityContext();
const getCommissionListForDealerFunctionalityContext = new GetCommissionListForDealerFunctionalityContext();
const getCommissionListForImporterFunctionalityContext = new GetCommissionListForImporterFunctionalityContext();

const AnnualTargetRoutes = () => {
  const matchDealerContractPreview = useRouteMatchMemo(modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACT_PREVIEW);
  const previewPrivilegeFnCtx = [];
  if (matchDealerContractPreview != null) {
    const contractId = Number(matchDealerContractPreview.params.contractId);
    previewPrivilegeFnCtx.push(new GetDealerContractListFunctionalityContext(contractId));
    previewPrivilegeFnCtx.push(new GetDealerContractPreviewFunctionalityContext(contractId));
  }

  return (
    <ModuleRoutes moduleContextData={moduleContextData} >
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_ANNUAL_TARGET_COMMISSION_LIST_DEALER}
        privilegeFunctionalityContext={getCommissionListForDealerFunctionalityContext}
      >
        <CommissionListDealer />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_ANNUAL_TARGET_COMMISSION_LIST_IMPORTER}
        privilegeFunctionalityContext={getCommissionListForImporterFunctionalityContext}
      >
        <CommissionListImporter />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACT_PREVIEW}
        privilegeFunctionalityContext={previewPrivilegeFnCtx}
      >
        <ContractPreviewPage />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_ANNUAL_TARGET}
        privilegeFunctionalityContext={getDealerContractsFunctionalityContext}
      >
        <DealerAnnualTarget />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_ANNUAL_TARGET_ANNUAL_TARGET}
        privilegeFunctionalityContext={getDealerContractsListFunctionalityContext}
      >
        <DealerContracts />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_ANNUAL_TARGET_NEGOTIATIONS}
        privilegeFunctionalityContext={getAnnualTargetYearsFunctionalityContext}
      >
        <Negotiations />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACTS_VIEW_AT_MODEL_GROUPS}
        privilegeFunctionalityContext={getDealerContractsATModelGroupViewListFunctionalityContext}
      >
        <DealerContractsATModelGroupView />
      </ProtectedRoute>
      <ProtectedRoute
        path={modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACTS_VIEW_ATS_PERIODS}
        privilegeFunctionalityContext={getDealerContractsATSPeriodsViewListFunctionalityContext}
      >
        <DealerContractsATSPeriodsView />
      </ProtectedRoute>
    </ModuleRoutes>
  );
};

AnnualTargetRoutes.propTypes = propTypes;
AnnualTargetRoutes.defaultProps = defaultProps;

export default AnnualTargetRoutes;
