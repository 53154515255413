import { PvtApiClient } from '../../../api';

import { ProductionCorridorTypeEnum } from './index';

class ProductionCorridorRow {

  /**
   * @param {module:model/ProductionCorridorDTO|Object} data
   * @return {ProductionCorridorRow}
   */
  static constructFromObject(data) {
    let row;
    if (data) {
      row = new ProductionCorridorRow();

      // ProductionCorridorDTO properties
      if (data.hasOwnProperty('id')) {
        row['id'] = PvtApiClient.convertToType(data['id'], 'Number');
      }
      if (data.hasOwnProperty('brand')) {
        row['brand'] = PvtApiClient.convertToType(data['brand'], 'String');
      }
      if (data.hasOwnProperty('bid')) {
        row['bid'] = PvtApiClient.convertToType(data['bid'], 'String');
      }
      if (data.hasOwnProperty('modelGroup')) {
        row['modelGroup'] = PvtApiClient.convertToType(data['modelGroup'], 'String');
      }
      if (data.hasOwnProperty('modelMask')) {
        row['modelMask'] = PvtApiClient.convertToType(data['modelMask'], 'String');
      }
      if (data.hasOwnProperty('colour')) {
        row['colour'] = PvtApiClient.convertToType(data['colour'], 'String');
      }
      if (data.hasOwnProperty('interior')) {
        row['interior'] = PvtApiClient.convertToType(data['interior'], 'String');
      }
      if (data.hasOwnProperty('equipment')) {
        row['equipment'] = PvtApiClient.convertToType(data['equipment'], 'String');
      }
      if (data.hasOwnProperty('equipmentType')) {
        row['equipmentType'] = PvtApiClient.convertToType(data['equipmentType'], 'String');
      }
      if (data.hasOwnProperty('weekFrom')) {
        row['weekFrom'] = PvtApiClient.convertToType(data['weekFrom'], 'String');
        row['weekFromOld'] = row['weekFrom'];
      }
      if (data.hasOwnProperty('weekTo')) {
        row['weekTo'] = PvtApiClient.convertToType(data['weekTo'], 'String');
        row['weekToOld'] = row['weekTo'];
      }
      if (data.hasOwnProperty('annualTarget')) {
        row['annualTarget'] = PvtApiClient.convertToType(data['annualTarget'], 'Boolean');
        row['annualTargetOld'] = row['annualTarget'];
      }
      if (data.hasOwnProperty('stockCar')) {
        row['stockCar'] = PvtApiClient.convertToType(data['stockCar'], 'Boolean');
        row['stockCarOld'] = row['stockCar'];
      }

      // own properties + generated week properties

      row['type'] = ProductionCorridorTypeEnum.valueOf(row);
      row['modified'] = false;
      row['archived'] = false;
      row['archivedModelGroup'] = false;
    }

    return row;
  }

  isNew = () => {
    return this.id == null;
  };

  setModifiedData = (formData) => {
    this.modified = true;

    this.weekFrom = formData.weekFrom;
    this.weekTo = formData.weekTo;

    if (ProductionCorridorTypeEnum.isModelGroupRow(this.type)) {
      this.annualTarget = formData.annualTarget;
      this.stockCar = formData.stockCar;
    }
  };

  undoModifiedFlag = () => {
    this.modified = false;

    this.weekFrom = this.weekFromOld;
    this.weekTo = this.weekToOld;
    this.annualTarget = this.annualTargetOld;
    this.stockCar = this.stockCarOld;
  };

  setArchivedFlag = () => {
    this.archived = true;
  };

  undoArchivedFlag = () => {
    this.archived = false;
  };

  setArchivedModelGroupFlag = () => {
    this.archivedModelGroup = true;
  };

  undoArchivedModelGroupFlag = () => {
    this.archivedModelGroup = false;
  };

}

// ProductionCorridorRow properties
ProductionCorridorRow.prototype['id'] = undefined;
ProductionCorridorRow.prototype['brand'] = undefined;
ProductionCorridorRow.prototype['bid'] = undefined;
ProductionCorridorRow.prototype['modelGroup'] = undefined;
ProductionCorridorRow.prototype['modelMask'] = undefined;
ProductionCorridorRow.prototype['colour'] = undefined;
ProductionCorridorRow.prototype['interior'] = undefined;
ProductionCorridorRow.prototype['equipment'] = undefined;
ProductionCorridorRow.prototype['annualTarget'] = undefined;
ProductionCorridorRow.prototype['stockCar'] = undefined;
ProductionCorridorRow.prototype['weekFrom'] = undefined;
ProductionCorridorRow.prototype['weekTo'] = undefined;

ProductionCorridorRow.prototype['annualTargetOld'] = undefined;
ProductionCorridorRow.prototype['stockCarOld'] = undefined;
ProductionCorridorRow.prototype['weekFromOld'] = undefined;
ProductionCorridorRow.prototype['weekToOld'] = undefined;

// own properties + generated week properties
ProductionCorridorRow.prototype['type'] = undefined;
ProductionCorridorRow.prototype['modified'] = undefined;
ProductionCorridorRow.prototype['archived'] = undefined;
ProductionCorridorRow.prototype['archivedModelGroup'] = undefined;

export default ProductionCorridorRow;

