import { PriceGuaranteeTypeEnum } from '../../../../../utils/const';

import OrderTypeViewConst from './orderTypeViewConst';

export const getViewOrderTypePreviousRowSK = ({ pgType }) => ORDER_TYPE_PREVIOUS_SNAPSHOT_VIEW_MAP[pgType];

export const getViewOrderTypeCurrentRowSK = ({ pgType }) => ORDER_TYPE_CURRENT_SNAPSHOT_VIEW_MAP[pgType];

const ORDER_TYPE_PREVIOUS_SNAPSHOT_VIEW_MAP = {
  [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK_SHOW]: OrderTypeViewConst.K_V_H,
  [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK]: OrderTypeViewConst.K_V_H,
  [PriceGuaranteeTypeEnum.NOCHECK__STOCK_SHOW]: OrderTypeViewConst.K_V_H,
  [PriceGuaranteeTypeEnum.CHECK__CLIENT]: OrderTypeViewConst.K,
  [PriceGuaranteeTypeEnum.CHECK__SHOW]: OrderTypeViewConst.V,
  [PriceGuaranteeTypeEnum.CHECK__CLIENT_SHOW]: OrderTypeViewConst.K_V
};

const ORDER_TYPE_CURRENT_SNAPSHOT_VIEW_MAP = {
  [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK_SHOW]: OrderTypeViewConst.K_V,
  [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK]: OrderTypeViewConst.K,
  [PriceGuaranteeTypeEnum.NOCHECK__STOCK_SHOW]: OrderTypeViewConst.V,
  [PriceGuaranteeTypeEnum.CHECK__CLIENT]: OrderTypeViewConst.K,
  [PriceGuaranteeTypeEnum.CHECK__SHOW]: OrderTypeViewConst.V,
  [PriceGuaranteeTypeEnum.CHECK__CLIENT_SHOW]: OrderTypeViewConst.K_V
};
