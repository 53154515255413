import React from 'react';

/**
 * React.useState extension by a callback
 */
const useStateCallback = (initialState) => {
  const [ state, setState ] = React.useState(initialState);
  const callbackRef = React.useRef(null);

  React.useEffect(() => {
    if (callbackRef.current != null) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  const setStateCallback = React.useCallback(
    (newState, callback) => {
      callbackRef.current = callback;
      setState(newState);
    },
    []
  );

  return [ state, setStateCallback ];
};

export default useStateCallback;
