import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import './Loading.scss';

const Loading = ({ loading }) => {

  if (loading) {
    return (
      <Bricks.Loading className='Loading'/>
    );
  }
  return null;
};

Loading.propTypes = {
  loading: PropTypes.bool
};

Loading.defaultProps = {
  loading: false
};

export default Loading;