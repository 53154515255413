import { EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { integerFormatter, percent2Formatter } from '../../../../common/utils/formatters';
import { contractNameFormatter } from '../../../utils/formatters';
import { sumNullableAccumulator } from '../../../utils/helpers';
import { calculateDonePercentNumber, calculateRemainsNumber } from '../../../utils/statisticsHelpers';

export const CONTRACT_COLUMN_IDS = {
  AREA_NAME: 'areaName',
  BRANCH_DEALER_NUMBER: 'branchDealerNumber',
  CONTRACT_NAME: 'contractName',
  CONTRACT_STATUS: 'contractStatus',
  DEALER_NAME: 'dealerName',
  DEALER_NUMBER: 'dealerNumber',
  YEAR: 'year'
};

const TOTAL_COLUMN_IDS = {
  GROUP_ID: 'totalColumnGroup',
  DEALER_NUMBER: 'totalDealerNumber',
  RECOMMENDED_NUMBER: 'totalRecommendedNumber',
  APPROVAL_NUMBER: 'totalApprovalNumber',
  APPROVED_NUMBER: 'totalApprovedNumber',
  DONE_NUMBER: 'totalDoneNumber',
  DONE_PERCENT: 'totalDonePercent',
  REMAINS_NUMBER: 'totalRemainsNumber'
};

const getColumnIdsForDynamicColumn = (code) => {
  return {
    GROUP_ID: `${code}_columnGroup`,
    DEALER_NUMBER: `${code}_dealerNumber`,
    RECOMMENDED_NUMBER: `${code}_recommendedNumber`,
    APPROVAL_NUMBER: `${code}_approvalNumber`,
    APPROVED_NUMBER: `${code}_approvedNumber`,
    DONE_NUMBER: `${code}_doneNumber`,
    DONE_PERCENT: `${code}_donePercent`,
    REMAINS_NUMBER: `${code}_remainsNumber`
  };
};

const fixDataValue = (value) => value != null ? value : 0;

const handleNumberValueFormatter = (valueFormatterParams) => {
  const value = valueFormatterParams.value;
  const formatters = valueFormatterParams.context.agLsi.getFormatters();

  return integerFormatter(value, formatters);
};

const handlePercentValueFormatter = (valueFormatterParams) => {
  const value = valueFormatterParams.value;
  const formatters = valueFormatterParams.context.agLsi.getFormatters();

  return percent2Formatter(value, formatters);
};

export const columnTypes = {
  sortableFilterDefinitionColumn: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  atDataColumn: {
    valueFormatter: handleNumberValueFormatter,
    cellClass: ['ovex-DealerContractsATSPeriodsViewTable-column-cell', EXCEL_STYLES_IDS.NUMBER_0_DECIMALS],
    aggFunc: 'sum',
    width: 110
  },
  atDataColumnPercent: {
    valueFormatter: handlePercentValueFormatter,
    cellClass: ['ovex-DealerContractsATSPeriodsViewTable-column-cell', EXCEL_STYLES_IDS.PERCENT_2_DECIMALS],
    width: 110
  }
};

export const autoGroupColumnDef = {
  valueGetter: (valueGetterParams) => {
    if (valueGetterParams.colDef.showRowGroup === CONTRACT_COLUMN_IDS.YEAR) {
      return handleYearValueGetter(valueGetterParams);
    } else if (valueGetterParams.colDef.showRowGroup === CONTRACT_COLUMN_IDS.AREA_NAME) {
      return handleAreaNameValueGetter(valueGetterParams);
    }
    return null;
  },
  type: ['sortableFilterDefinitionColumn'],
  width: 150,
  pinned: 'left',
  cellRendererParams: {
    suppressCount: true
  }
};

const handleYearValueGetter = (valueGetterParams) => valueGetterParams.data?.contract.negotiation.year;
const handleAreaNameValueGetter = (valueGetterParams) => valueGetterParams.data?.contract.headquartersDealer.areaName;

export const getColumnDefinitions = (lsi, dynamicColumnDataList) => {

  return [
    {
      colId: CONTRACT_COLUMN_IDS.YEAR,
      valueGetter: handleYearValueGetter,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.YEAR'),
      type: ['sortableFilterDefinitionColumn'],
      width: 80,
      rowGroup: true,
      hide: true
    },
    {
      colId: CONTRACT_COLUMN_IDS.AREA_NAME,
      valueGetter: handleAreaNameValueGetter,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.AREA'),
      type: ['sortableFilterDefinitionColumn'],
      width: 160,
      rowGroup: true,
      hide: true
    },
    {
      colId: CONTRACT_COLUMN_IDS.DEALER_NUMBER,
      valueGetter: (valueGetterParams) => valueGetterParams.data?.contract.headquartersDealer.dealerNumber,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DEALER'),
      cellClass: [EXCEL_STYLES_IDS.STRING],
      type: ['sortableFilterDefinitionColumn'],
      width: 120,
      rowGroup: true,
      hide: true
    },
    {
      colId: CONTRACT_COLUMN_IDS.BRANCH_DEALER_NUMBER,
      valueGetter: (valueGetterParams) => valueGetterParams.data?.branchDealerDetail.dealerNumber,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BRANCH'),
      cellClassRules: {
        [EXCEL_STYLES_IDS.STRING]: 'true'
      },
      type: ['sortableFilterDefinitionColumn'],
      width: 125,
      pinned: 'left'
    },
    {
      colId: CONTRACT_COLUMN_IDS.DEALER_NAME,
      valueGetter: (valueGetterParams) => {
        let data = valueGetterParams.data;
        if (valueGetterParams.node.group && valueGetterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueGetterParams.node.allLeafChildren.find(findHeadquartersBranchRowNode);
          data = headquartersBranchRowNode?.data;
        }
        return data?.branchDealerDetail.dealerName;
      },
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.NAME'),
      type: ['sortableFilterDefinitionColumn'],
      width: 200,
      pinned: 'left'
    },
    {
      colId: CONTRACT_COLUMN_IDS.CONTRACT_NAME,
      valueGetter: (valueGetterParams) => valueGetterParams.data?.contract.negotiation.name,
      valueFormatter: (valueFormatterParams) => {
        if (valueFormatterParams.node.group && valueFormatterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueFormatterParams.node.allLeafChildren.find(findHeadquartersBranchRowNode);
          if (headquartersBranchRowNode?.data) {
            return contractNameFormatter(headquartersBranchRowNode.data.contract, valueFormatterParams.context.agLsi);
          }
        }
        if (!valueFormatterParams.node.group) {
          // handler is called from as a standard *valueFormatter* (rendering flow) and also from excel export *processCellCallback*
          const data = valueFormatterParams.data || valueFormatterParams.node.data;
          return contractNameFormatter(data.contract, valueFormatterParams.context.agLsi);
        }
        return '';
      },
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.CONTRACT_ADDITION'),
      type: ['sortableFilterDefinitionColumn'],
      width: 180
    },
    {
      colId: CONTRACT_COLUMN_IDS.CONTRACT_STATUS,
      valueGetter: (valueGetterParams) => {
        let data = valueGetterParams.data;
        if (valueGetterParams.node.group && valueGetterParams.node.leafGroup) {
          const headquartersBranchRowNode = valueGetterParams.node.allLeafChildren.find(findHeadquartersBranchRowNode);
          data = headquartersBranchRowNode?.data;
        }
        return data?.contract.status;
      },
      valueFormatter: (valueFormatterParams) => valueFormatterParams.context.agLsi.getLSIItem('ANNUAL_TARGET.ENUM_LABELS.CONTRACT_STATUS')[valueFormatterParams.value],
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.STATUS'),
      type: ['sortableFilterDefinitionColumn'],
      width: 120
    },
    {
      groupId: TOTAL_COLUMN_IDS.GROUP_ID,
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL_ATS_PERIODS'),
      headerClass: ['ovex-ag-grid--th-center', 'ovex-DealerContractsATSPeriodsViewTable-column-header-total'],
      marryChildren: true,
      children: [
        {
          colId: TOTAL_COLUMN_IDS.DEALER_NUMBER,
          valueGetter: getValueGetterToCalculateTotalValue('atsDealerNumber'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
          headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-working'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.RECOMMENDED_NUMBER,
          valueGetter: getValueGetterToCalculateTotalValue('atsRecommendedNumber'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
          headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-working'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.APPROVAL_NUMBER,
          valueGetter: getValueGetterToCalculateTotalValue('atsApprovalNumber'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
          headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-working'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.APPROVED_NUMBER,
          valueGetter: getValueGetterToCalculateTotalValue('atsApprovedNumber'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
          headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-approved'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.DONE_NUMBER,
          valueGetter: getValueGetterToCalculateTotalValue('atsStatisticsSoldCount'),
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
          headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-statistics'],
          type: ['atDataColumn']
        },
        {
          colId: TOTAL_COLUMN_IDS.DONE_PERCENT,
          valueGetter: (valueGetterParams) => {
            const atsApprovedNumber = valueGetterParams.getValue(TOTAL_COLUMN_IDS.APPROVED_NUMBER);
            const atsStatisticsSoldCount = valueGetterParams.getValue(TOTAL_COLUMN_IDS.DONE_NUMBER);
            return fixDataValue(calculateDonePercentNumber(atsApprovedNumber, atsStatisticsSoldCount));
          },
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
          headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-statistics'],
          type: ['atDataColumnPercent']
        },
        {
          colId: TOTAL_COLUMN_IDS.REMAINS_NUMBER,
          valueGetter: (valueGetterParams) => {
            const atsApprovedNumber = valueGetterParams.getValue(TOTAL_COLUMN_IDS.APPROVED_NUMBER);
            const atsStatisticsSoldCount = valueGetterParams.getValue(TOTAL_COLUMN_IDS.DONE_NUMBER);
            return fixDataValue(calculateRemainsNumber(atsApprovedNumber, atsStatisticsSoldCount));
          },
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
          headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-statistics'],
          type: ['atDataColumn']
        }
      ]
    },
    ...getColumnDefinitionsForDynamicColumnList(lsi, dynamicColumnDataList)
  ];
};

const findHeadquartersBranchRowNode = (childRowNode) => {
  const data = childRowNode.data;
  return !!data && data.contract.headquartersDealer.dealerNumber === data.branchDealerDetail.dealerNumber;
};

const getValueGetterToCalculateTotalValue = (attribute) => (valueGetterParams) => {
  let total;
  if (valueGetterParams.data) {
    const pList = Object.values(valueGetterParams.data.periodAnnualTargetSalesMap);
    total = pList.reduce((acc, mg) => sumNullableAccumulator(acc, mg[attribute]), null);
  }
  return fixDataValue(total);
};

const getColumnDefinitionsForDynamicColumnList = (lsi, dynamicColumnDataList) => {
  if (Array.isArray(dynamicColumnDataList)) {
    return dynamicColumnDataList.map((columnData, idx) => getColumnDefinitionsForDynamicColumn(lsi, columnData, (idx % 2) === 1));
  }
  return [];
};

const getColumnDefinitionsForDynamicColumn = (lsi, columnData, oddColumn) => {
  const columnId = columnData.columnId;
  const dynamicColumnIds = getColumnIdsForDynamicColumn(columnId);
  const oddClass = oddColumn ? 'ovex-DealerContractsATSPeriodsViewTable-column-header-group-odd' : '';

  return {
    groupId: dynamicColumnIds.GROUP_ID,
    headerName: columnData.headerLabel,
    headerClass: ['ovex-ag-grid--th-center', oddClass],
    marryChildren: true,
    children: [
      {
        colId: dynamicColumnIds.DEALER_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atsDealerNumber'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
        headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-working'],
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.RECOMMENDED_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atsRecommendedNumber'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
        headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-working'],
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.APPROVAL_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atsApprovalNumber'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
        headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-working'],
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.APPROVED_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atsApprovedNumber'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
        headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-approved'],
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.DONE_NUMBER,
        valueGetter: getValueGetterHandlerForDynamicColumn(columnId, 'atsStatisticsSoldCount'),
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
        headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-statistics'],
        type: ['atDataColumn']
      },
      {
        colId: dynamicColumnIds.DONE_PERCENT,
        valueGetter: (valueGetterParams) => {
          const atsApprovedNumber = valueGetterParams.getValue(dynamicColumnIds.APPROVED_NUMBER);
          const atsStatisticsSoldCount = valueGetterParams.getValue(dynamicColumnIds.DONE_NUMBER);
          return fixDataValue(calculateDonePercentNumber(atsApprovedNumber, atsStatisticsSoldCount));
        },
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
        headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-statistics'],
        type: ['atDataColumnPercent']
      },
      {
        colId: dynamicColumnIds.REMAINS_NUMBER,
        valueGetter: (valueGetterParams) => {
          const atsApprovedNumber = valueGetterParams.getValue(dynamicColumnIds.APPROVED_NUMBER);
          const atsStatisticsSoldCount = valueGetterParams.getValue(dynamicColumnIds.DONE_NUMBER);
          return fixDataValue(calculateRemainsNumber(atsApprovedNumber, atsStatisticsSoldCount));
        },
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
        headerClass: ['ovex-DealerContractsATSPeriodsViewTable-column-statistics'],
        type: ['atDataColumn']
      }
    ]
  };
};

/**
 * @param {string} columnId - key for the map of period annual target sales data
 * @param {string} attributeName - attribute name in period annual target sales data
 * @return {function} value getter handler
 */
const getValueGetterHandlerForDynamicColumn = (columnId, attributeName) => (valueGetterParams) => {
  const periodAnnualTargetSales = valueGetterParams.data?.periodAnnualTargetSalesMap?.[columnId];
  return fixDataValue(periodAnnualTargetSales?.[attributeName]);
};
