import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { LsiContext } from '../../../contexts';
import SAWeekUtils from '../../../utils/SAWeekUtils';

import './WeekPicker.scss';

function getValue_Override() {
  return this.state.value;
}

const WeekPickerComponent = (props, ref) => {
  const lsi = React.useContext(LsiContext);

  const storeRef = (dpRef) => {
    if (dpRef != null) {
      ref && ref(dpRef);
      dpRef.getValue_ = getValue_Override;
    }
  };

  const handleChange = (opt) => {
    opt.component.onChangeDefault(opt);
    props.onChange != null && props.onChange(opt.value);
  };

  const handleParseDate = (stringDate) => {
    let date = null;
    if (stringDate) {
      if (SAWeekUtils.isSAWeek(stringDate)) {
        date = SAWeekUtils.parseDateFromWeek(stringDate);
        date = date && date.toDate();
      }
    }
    return date;
  };

  const getDateTo = () => {
    const date = SAWeekUtils.endOfWeekFromDate(SAWeekUtils.parseDateFromWeek(props.weekTo));
    return date && date.toDate();
  };

  const style = { width: props.width };
  props.inputHeight != null && (style.minHeight = props.inputHeight);

  return (
    <Forms.DatePicker
      afterRangeMessage={lsi.getLSIItem('COMMON.FORM_INVALID_INPUT.WEEK_AFTER_RANGE', [props.weekTo])}
      beforeRangeMessage={lsi.getLSIItem('COMMON.FORM_INVALID_INPUT.WEEK_BEFORE_RANGE', [props.weekFrom])}
      bgStyle='underline'
      className={['ovex-forms-week-picker', props.className].join(' ')}
      colorSchema='green'
      dateFrom={props.weekFrom}
      dateTo={getDateTo()}
      disabled={props.disabled}
      format={SAWeekUtils.UU5_DATE_PICKER_WEEK_FORMAT}
      inputAttrs={props.inputHeight != null ? { style: { height: props.inputHeight } } : null}
      inputColWidth='xs12'
      inputWidth={props.inputWidth}
      label={props.label}
      labelColWidth='xs12'
      name={props.name}
      nanMessage={lsi.getLSIItem('COMMON.FORM_INVALID_INPUT.WEEK_PICKER_FORMAT')}
      onChange={handleChange}
      parent={props.parent}
      parseDate={handleParseDate}
      readOnly={props.readOnly}
      ref={storeRef}
      required={props.required}
      style={style}
      tooltip={props.tooltip}
      value={props.value}
    />
  );
};

const WeekPicker = React.forwardRef(WeekPickerComponent);
WeekPicker.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

WeekPickerComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputHeight: PropTypes.string,
  inputWidth: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.string,
  weekFrom: PropTypes.string,
  weekTo: PropTypes.string,
  width: PropTypes.string
};

WeekPickerComponent.defaultProps = {
  className: null,
  disabled: false,
  inputHeight: null,
  inputWidth: null,
  label: null,
  name: null,
  onChange: undefined,
  parent: null,
  readOnly: false,
  required: false,
  tooltip: null,
  value: null,
  weekFrom: null,
  weekTo: null,
  width: null
};

export default WeekPicker;
