import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserModalContext } from '../../../../components/user-modal';
import { UserInfoContext } from '../../../common/contexts';
import { handleImitateUserCredentials, handleGetUserInfoWithRoleEvaluation } from '../../redux/modules/actions';
import { handleClearData } from '../../redux/modules/change-user/user-with-role-evaluation';

import UserImitateModal from './UserImitateModal';
import {HOME_PAGE_URL} from "../../utils/const";

const UserImitateModalConnector = () => {
  const userModal = React.useContext(UserModalContext);
  const userInfo = React.useContext(UserInfoContext);

  const selectedState = useSelector(state => {
    return {
      userImitation: state.support.getUserInfoWithRoleEvaluation.userImitation,
      validationStatus: state.support.getUserInfoWithRoleEvaluation.validationStatus
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        onClearData: () => dispatch(handleClearData()),
        onGetUserInfoWithRoleEvaluation: (loginUid) => dispatch(handleGetUserInfoWithRoleEvaluation(loginUid)),
        onImitateUser: async (loginUid) => {
          await dispatch(handleImitateUserCredentials(loginUid));
          window.location = HOME_PAGE_URL;
        }
      };
    },
    [dispatch]
  );

  const handleClose = () => userModal?.setValue(null);
  return (
    <UserImitateModal
      onClearData={callbacks.onClearData}
      onClose={handleClose}
      onGetUserInfoWithRoleEvaluation={callbacks.onGetUserInfoWithRoleEvaluation}
      onImitateUser={callbacks.onImitateUser}
      userImitation={selectedState.userImitation}
      userInfoCurrent={userInfo}
      userValidationStatus={selectedState.validationStatus}
    />
  );
};

export default UserImitateModalConnector;