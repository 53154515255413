import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import './Code.scss';

const CodeComponent = (props, ref) => {
  const classNames = ['ovex-forms-code'];
  props.className && classNames.push(props.className);
  props.valueChanged && classNames.push('ovex-forms-code--value-changed');

  const inputAttrs = {
    maxLength: props.maxLength,
    style: { textTransform: props.upperCase ? 'upperCase' : 'none' }
  };

  const handleChange = (opt) => {
    opt.component.onChangeDefault(opt);
    props.onChange != null && props.onChange(opt.value);
  };

  return (
    <Forms.Text
      bgStyle='underline'
      className={classNames.join(' ')}
      colorSchema='green'
      disabled={props.disabled}
      feedback={props.feedback}
      inputAttrs={inputAttrs}
      inputColWidth='xs12'
      label={props.label}
      labelColWidth='xs12'
      message={props.message}
      name={props.name}
      onChange={props.onChangeTMP || handleChange}
      parent={props.parent}
      pattern={props.pattern}
      readOnly={props.readOnly}
      ref_={ref}
      required={props.required}
      style={{
        width: props.width
      }}
      tooltip={props.tooltip}
      value={props.value}
    />
  );
};

const Code = React.forwardRef(CodeComponent);
Code.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

CodeComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  feedback: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  message: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onChangeTMP: PropTypes.func,
  parent: PropTypes.object,
  pattern: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  upperCase: PropTypes.bool,
  value: PropTypes.string,
  valueChanged: PropTypes.bool,
  width: PropTypes.string
};

CodeComponent.defaultProps = {
  className: null,
  disabled: false,
  feedback: Forms.InputMixin.INITIAL_FEEDBACK,
  label: null,
  maxLength: 32,
  message: null,
  name: null,
  onChange: undefined,
  onChangeTMP: undefined,
  parent: null,
  pattern: null,
  readOnly: false,
  required: false,
  tooltip: null,
  upperCase: false,
  value: '',
  valueChanged: false,
  width: null
};

export default Code;
