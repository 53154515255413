import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonBar, Modal } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { Code, FilterContextButtons, FilterForm, FormsContextSection } from '../../../common/components/forms';

import './UserImitateModal.scss';
import UserDetailInfoContentResolver from './UserDetailInfoContentResolver';

const propTypes = {
  fetching: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  userDesired: PropTypes.object,
  userInfoCurrent: PropTypes.object
};
const defaultProps = {
  fetching: true,
  onClose: null,
  userDesired: null,
  userInfoCurrent: null
};

const UserImitateModal = (props) => {
  const lsi = React.useContext(LsiContext);
  const { onImitateUser, userImitation, onGetUserInfoWithRoleEvaluation,
    onClearData, userValidationStatus } = props;

  const [loginUid, setLoginUid] = React.useState(null);
  const handleSubmit = React.useCallback(
    (formValues) => {
      const loginUid = formValues['loginUid'];
      setLoginUid(loginUid);
      onGetUserInfoWithRoleEvaluation(loginUid);
    },
    [onGetUserInfoWithRoleEvaluation]
  );

  const handleImitate = React.useCallback(
    () => {
      onImitateUser(loginUid);
    },
    [onImitateUser, loginUid]
  );

  const handleReset = React.useCallback(
    () => {
      setLoginUid(null);
      onClearData();
    },
    [onClearData]
  );

  const handleClose = () => {
    onClearData();
    props.onClose();
  };

  return (
    <Modal
      className='ovex-UserImitateModal'
      header={lsi.getLSIItem('SUPPORT.USER_IMITATION.HEADER')}
      onClose={handleClose}
      shown
      size='l'
    >
      <FormsContextSection>
        <FilterForm
          onReset={handleReset}
          onSubmit={handleSubmit}
        >
          <Code
            label={lsi.getLSIItem('SUPPORT.USER_IMITATION.USER_TO_IMITATE')}
            name='loginUid'
            required
            value={loginUid ? loginUid : null}
            width='160px'
          />
        </FilterForm>
        <ButtonBar className={'ovex-ImitateUserModalBar'}>
          <FilterContextButtons />
          <ButtonBar.Separator />
          <Button
            content={lsi.getLSIItem('SUPPORT.USER_IMITATION.IMITATE_USER')}
            disabled={!userImitation}
            onClick={handleImitate}
            type={Button.type.PRIMARY}
          />
        </ButtonBar>
      </FormsContextSection>
      <UserDetailInfoContentResolver
        userImitation={userImitation}
        userValidationStatus={userValidationStatus}
      />
    </Modal>
  );
};

UserImitateModal.propTypes = propTypes;
UserImitateModal.defaultProps = defaultProps;

export default UserImitateModal;