import PropTypes from 'prop-types';
import React from 'react';
import {
  BranchATSPeriodsViewDTO,
  ColumnDataDTO
} from '@ovex/annual-target-web-api';

import { OvexAGTable, RowGroupingDisplayType } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';

import { columnTypes, getColumnDefinitions, autoGroupColumnDef } from './DealerContractsATSPeriodsViewTable.columnDefs';

import './DealerContractsATSPeriodsViewTable.scss';

const propTypes = {
  branchATSPeriodsViewList: PropTypes.arrayOf(PropTypes.instanceOf(BranchATSPeriodsViewDTO)),
  dynamicColumnDataList: PropTypes.arrayOf(PropTypes.instanceOf(ColumnDataDTO))
};

const defaultProps = {
  branchATSPeriodsViewList: null,
  dynamicColumnDataList: null
};

const DealerContractsATSPeriodsViewTable = React.memo(React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);

  const columnDefs = React.useMemo(
    () => getColumnDefinitions(lsi, props.dynamicColumnDataList),
    [lsi, props.dynamicColumnDataList]
  );

  return (
    <OvexAGTable
      autoGroupColumnDef={autoGroupColumnDef}
      className='ovex-DealerContractsATSPeriodsViewTable'
      columnDefs={columnDefs}
      columnTypes={columnTypes}
      enableRangeSelection
      groupDefaultExpanded={1}
      groupDisplayType={RowGroupingDisplayType.MULTIPLE_COLUMNS}
      groupRemoveLowestSingleChildren
      height='550px'
      onRowDataChanged={handleRowDataChanged}
      ref={ref}
      rowClassRules={rowClassRules}
      rowData={props.branchATSPeriodsViewList}
      rowSelection='multiple'
      showOpenedGroup
    />
  );
}));

const rowClassRules = {
  'ovex-DealerContractsATSPeriodsViewTable-single-branch-dealer': (params) => (!params.node.group && params.node.parent.allChildrenCount === 1)
};

const handleRowDataChanged = (params) => {
  const autoColIdList = params.columnApi.columnModel.groupAutoColumns.map(col => col.colId);
  params.columnApi.autoSizeColumns(autoColIdList);
};

DealerContractsATSPeriodsViewTable.propTypes = propTypes;
DealerContractsATSPeriodsViewTable.defaultProps = defaultProps;

export default DealerContractsATSPeriodsViewTable;
