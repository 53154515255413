
const CommissionValidatorPageStatus = {
  LOADING: 'loading',
  EMPTY: 'empty',
  DATA_FOUND: 'dataFound',
  NOT_FOUND: 'notFound',
  ERROR: 'error',

  isLoading: function (status) { return this.LOADING === status; },
  isEmpty: function (status) { return this.EMPTY === status; },
  isDataFound: function (status) { return this.DATA_FOUND === status; },
  isNotFound: function (status) { return this.NOT_FOUND === status; },
  isError: function (status) { return this.ERROR === status; }
};

export default CommissionValidatorPageStatus;
