import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import { useSelector } from 'react-redux';

import { UserModalContext, UserModalEnum } from '../../user-modal';
import { userIcon } from '../../../assets/images';
import { DEVELOPMENT_ENVIRONMENTS } from '../../../utils/consts';
import { Dropdown } from '../../../packages/common/components';
import { LsiContext, UserInfoContext } from '../../../packages/common/contexts';

import './UserMenu.scss';

const UserMenu = () => {
  const environment = window.OVEX3_ENVIRONMENT;
  const lsi = React.useContext(LsiContext);
  const userInfo = React.useContext(UserInfoContext);
  const userModal = React.useContext(UserModalContext);

  const selected = useSelector(state => {
    return {
      menuItems: state.main.userMenu.menuItems
    };
  });

  const menuItems = React.useMemo(
    () => {
      const transformedItems = selected.menuItems.map((menuItem) => {
        return {
          label: lsi.getLSIItem(menuItem.label),
          onClick: () => userModal?.setValue(menuItem.onClick),
          order: menuItem.order
        };
      });
      const items = [
        {
          label: lsi.getLSIItem('MAIN.USER_MENU.USER_INFO'),
          onClick: () => userModal?.setValue(UserModalEnum.USER_INFO),
          order: 0
        },

        ...transformedItems

      ];
      if (DEVELOPMENT_ENVIRONMENTS.includes(environment)) {
        items.push({
          label: lsi.getLSIItem('DEVELOPMENT.USER_MENU.USER_CHANGE'),
          onClick: () => userModal?.setValue(UserModalEnum.DEV_USER_CHANGE),
          order: 40
        });
      }

      items.sort((orderA, orderB) => orderA.order - orderB.order);

      return items;
    },
    [lsi, selected.menuItems, environment, userModal]
  );

  return (
    <React.Fragment>
      <Bricks.Image
        height='32px'
        src={userIcon}
      />
      <Dropdown
        className='ovex-UserMenu'
        iconHidden
        items={menuItems}
        label={userInfo.displayName || userInfo.login}
        tooltip={userInfo.displayName || userInfo.login}
      />
    </React.Fragment>
  );
};

export default UserMenu;