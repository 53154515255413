import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonBar, Modal } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';

const propTypes = {
  fetching: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  userInfoCurrent: PropTypes.object
};
const defaultProps = {
  fetching: true,
  onClose: null,
  userInfoCurrent: null
};

const UserRevertChangesModal = (props) => {
  const lsi = React.useContext(LsiContext);
  const { onRevertUserChanges, onClose } = props;

  const handleRevert = React.useCallback(
    () => {
      onRevertUserChanges();
    },
    [onRevertUserChanges]
  );

  return (
    <Modal
      header={lsi.getLSIItem('SUPPORT.USER_REVERT.HEADER')}
      onClose={onClose}
      shown
      size='m'
      stickyBackground={true}
    >
      <React.Fragment>
        {lsi.getLSIItem('SUPPORT.USER_REVERT.CONFIRM')}
        <ButtonBar
          align={ButtonBar.align.center}
          formControlsBar
        >
          <Button
            content={lsi.getLSIItem('COMMON.BUTTON.NO')}
            onClick={props.onClose}
            type={Button.type.SECONDARY}
          />
          <Button
            content={lsi.getLSIItem('COMMON.BUTTON.YES')}
            onClick={handleRevert}
            type={Button.type.PRIMARY}
          />
        </ButtonBar>
      </React.Fragment>
    </Modal>
  );
};

UserRevertChangesModal.propTypes = propTypes;
UserRevertChangesModal.defaultProps = defaultProps;

export default UserRevertChangesModal;