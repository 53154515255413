import { mdiNotebookPlusOutline, mdiPencilOutline, mdiTextBoxSearchOutline } from '@mdi/js';

import { MenuItemDefBuilder } from '../../../../common/components/ag-grid';
import { NegotiationStatusEnum, NegotiationTypeEnum } from '../../../utils/const';

const YearSettingsContextMenuItems = {
  getShowDetailItem: (params) => {
    return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.SHOW_YEAR_SETTINGS_DETAIL')
      .iconFromPath(mdiTextBoxSearchOutline)
      .action(() => {
        params.context.onShowYearSettingsDetailModalForm(params.node.data);
      })
      .build();
  },
  getUpdateItem: (params) => {
    const pFnCtxObject = params.node.data.privilegeFunctionalityContextObject;

    return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.UPDATE_YEAR_SETTINGS')
      .iconFromPath(mdiPencilOutline)
      .action(() => {
        params.context.onShowYearSettingsUpdateModalForm(params.node.data);
      })
      .privilegeFunctionalityContext(pFnCtxObject.updateAnnualTargetYearFnCtx)
      .build();
  },
  getAddNegotiationAdditionTypeItem: (params) => {
    const pFnCtxObject = params.node.data.privilegeFunctionalityContextObject;

    const data = params.node.parent.allLeafChildren.filter(item => item.data.isNegotiationContractOrAdditionRow() && item.data.negotiationType === NegotiationTypeEnum.CONTRACT && item.data.status !== NegotiationStatusEnum.CANCELED);

    return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.ADD_NEGOTIATION_ADDITIONS')
      .iconFromPath(mdiNotebookPlusOutline)
      .disabled(data.length === 0)
      .action(() => {
        params.context.onShowNegotiationCreationModalForm({
          yearSettingsData: params.node.data,
          negotiationType: NegotiationTypeEnum.ADDITION
        });
      })
      .privilegeFunctionalityContext(pFnCtxObject.createNegotiationAdditionFnCtx)
      .build();
  },
  getAddNegotiationContractTypeItem: (params) => {
    const pFnCtxObject = params.node.data.privilegeFunctionalityContextObject;

    return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.ADD_NEGOTIATION_CONTRACT')
      .iconFromPath(mdiNotebookPlusOutline)
      .action(() => {
        params.context.onShowNegotiationCreationModalForm({
          yearSettingsData: params.node.data,
          negotiationType: NegotiationTypeEnum.CONTRACT
        });
      })
      .privilegeFunctionalityContext(pFnCtxObject.createNegotiationContractFnCtx)
      .build();
  },
  getAddNegotiationClosureTypeItem: (params) => {
    const pFnCtxObject = params.node.data.privilegeFunctionalityContextObject;

    return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.ADD_NEGOTIATION_CLOSURE')
      .iconFromPath(mdiNotebookPlusOutline)
      .action(() => {
        params.context.onCreateNegotiationClosure({
          atYearId: params.node.data.id
        });
      })
      .privilegeFunctionalityContext(pFnCtxObject.createNegotiationClosureFnCtx)
      .build();
  }
};

export default YearSettingsContextMenuItems;
