
const ColumnUIDefinitionCodes = {
  PGID: 'pgid_ciColumn_v1',
  SALES_ACCOUNT_TYPE: 'salesAccountType_ciColumn_v1',
  CLAIM_TYPE: 'claimType_ciColumn_v1',
  C_ORDER_MODE: 'cOrderMode_ciColumn_v1',
  COH_PART_STATUS: 'cohPartStatus_ciColumn_v1',
  CLAIM_AMOUNT: 'claimAmount_ciColumn_v1',
  TOTAL_AMOUNT: 'totalAmount_ciColumn_v1',
  INVOICE_AMOUNT: 'invoiceAmount_ciColumn_v1',
  SPEC__PREVIOUS__MODEL: 'specPreviousModel_ciColumn_v1',
  SPEC__PREVIOUS__MODEL_NEG: 'specPreviousModelNeg_ciColumn_v1',
  SPEC__PREVIOUS__COLOUR: 'specPreviousColour_ciColumn_v1',
  SPEC__PREVIOUS__COLOUR_NEG: 'specPreviousColourNeg_ciColumn_v1',
  SPEC__PREVIOUS__INTERIOR: 'specPreviousInterior_ciColumn_v1',
  SPEC__PREVIOUS__INTERIOR_NEG: 'specPreviousInteriorNeg_ciColumn_v1',
  SPEC__PREVIOUS__PACKAGE: 'specPreviousPackage_ciColumn_v1',
  SPEC__PREVIOUS__PACKAGE_NEG: 'specPreviousPackageNeg_ciColumn_v1',
  SPEC__CURRENT__MODEL: 'specCurrentModel_ciColumn_v1',
  SPEC__CURRENT__MODEL_NEG: 'specCurrentModelNeg_ciColumn_v1',
  SPEC__CURRENT__COLOUR: 'specCurrentColour_ciColumn_v1',
  SPEC__CURRENT__COLOUR_NEG: 'specCurrentColourNeg_ciColumn_v1',
  SPEC__CURRENT__INTERIOR: 'specCurrentInterior_ciColumn_v1',
  SPEC__CURRENT__INTERIOR_NEG: 'specCurrentInteriorNeg_ciColumn_v1',
  SPEC__CURRENT__PACKAGE: 'specCurrentPackage_ciColumn_v1',
  SPEC__CURRENT__PACKAGE_NEG: 'specCurrentPackageNeg_ciColumn_v1',
  PRODUCTION_DATE_FROM: 'productionDateFrom_ciColumn_v1',
  PRODUCTION_DATE_TO: 'productionDateTo_ciColumn_v1',
  PRICE_VALID_FROM: 'priceValidFrom_ciColumn_v1'
};

Object.freeze(ColumnUIDefinitionCodes);

export default ColumnUIDefinitionCodes;