/**
 * Same mapping as in JAVA:
 *  - vwg.skoda.ovex.annualtarget.privilege.condition.context.common.ContextConstants
 */
const ContextConstants = {
  DEALER_NUMBER: 'dealerNumber',
  CONTRACT_ID: 'contractId',
  NEGOTIATION_ID: 'negotiationId',
  NEGOTIATION_TYPE: 'negotiationType',
  AT_YEAR_ID: 'atYearId',
  CONTRACT_AT_ID: 'contractAtId',
  CONTRACT_AT_SALES_PERIOD_ID: 'contractAtSalesPeriodId',
  CONTRACT_STATUS: 'contractStatus',
  CONTRACT_STATUS_TO: 'contractStatusTo'
};

export default ContextConstants;