import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrivilege } from '../../../common/hooks';
import {
  CreatePriceGuaranteePFC,
  UpdatePriceGuaranteePFC,
  ShiftPriceGuaranteeStatusPFC,
  GetPgAccountingPFC
} from '../../privilege/context';
import {
  handleCreatePriceGuarantee,
  handleGetPriceGuaranteeList,
  handleUpdatePriceGuarantee,
  handleShiftPriceGuaranteeStatus,
  handleGetAccountingBasisList
} from '../../redux/modules/actions';
import { PriceGuaranteeStatusEnum } from '../../utils/const';
import { handleClearAccountingBasis } from '../../redux/modules/accounting-basis/accounting-basis';

import PriceGuaranteeList from './PriceGuaranteeList';

const createPriceGuaranteePFC = new CreatePriceGuaranteePFC();

const PriceGuaranteeListConnector = () => {
  const selectedState = useSelector(state => {
    return {
      isFetching: state.priceGuarantee.priceGuarantees.isFetching,
      priceGuaranteeList: state.priceGuarantee.priceGuarantees.priceGuaranteeList,
      accountingBasisList: state.priceGuarantee.accountingBasis.accountingBasisList
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        handleCreatePriceGuarantee: (newDTO) => dispatch(handleCreatePriceGuarantee(newDTO)),
        handleGetPriceGuaranteeList: () => dispatch(handleGetPriceGuaranteeList()),
        handleGetAccountingBasisList: () => dispatch(handleGetAccountingBasisList()),
        handleClearAccountingBasis: () => dispatch(handleClearAccountingBasis()),
        handleUpdatePriceGuarantee: (updateDTO) => dispatch(handleUpdatePriceGuarantee(updateDTO)),
        handleShiftPriceGuaranteeStatus: (priceGuaranteeId, priceGuaranteeStatusTo) => dispatch(handleShiftPriceGuaranteeStatus(priceGuaranteeId, priceGuaranteeStatusTo))
      };
    },
    [dispatch]
  );

  const { handlePrivilegeRequest } = usePrivilege();

  React.useEffect(
    () => {
      handlePrivilegeRequest([createPriceGuaranteePFC]);
    },
    [handlePrivilegeRequest]
  );
  React.useEffect(
    () => {
      if (Array.isArray(selectedState.priceGuaranteeList) && selectedState.priceGuaranteeList.length > 0) {
        const pfcList = selectedState.priceGuaranteeList.flatMap(pg => [
          new UpdatePriceGuaranteePFC(pg.id),
          new GetPgAccountingPFC(),
          /**
           * For details of transitions see the price guarantee workflow
           */
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.CREATED),
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.CONFIRMED),
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.VALIDATED),
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.PUBLISHED),
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.COMPLETED),
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.RETURNED_TO_CORRECTION),
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.CORRECTION_CONFIRMED),
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.CORRECTION_VALIDATED),
          new ShiftPriceGuaranteeStatusPFC(pg.id, PriceGuaranteeStatusEnum.DELETED)
        ]);

        handlePrivilegeRequest(pfcList);
      }
    },
    [handlePrivilegeRequest, selectedState.priceGuaranteeList]
  );

  return (
    <PriceGuaranteeList
      accountingBasisList={selectedState.accountingBasisList}
      isFetching={selectedState.isFetching}
      onClearAccountingBasis={callbacks.handleClearAccountingBasis}
      onCreatePriceGuarantee={callbacks.handleCreatePriceGuarantee}
      onGetAccountingBasisList={callbacks.handleGetAccountingBasisList}
      onGetPriceGuaranteeList={callbacks.handleGetPriceGuaranteeList}
      onShiftPriceGuaranteeStatus={callbacks.handleShiftPriceGuaranteeStatus}
      onUpdatePriceGuarantee={callbacks.handleUpdatePriceGuarantee}
      priceGuaranteeList={selectedState.priceGuaranteeList}
    />
  );
};

export default PriceGuaranteeListConnector;
