
class ApiMiddlewareResponseValue {

  /**
   * @param {object} response
   * @param {object} data
   */
  constructor(response, data) {
    this.response = response;
    this.data = data;
  }
}

ApiMiddlewareResponseValue.prototype.response = undefined;
ApiMiddlewareResponseValue.prototype.data = undefined;

export default ApiMiddlewareResponseValue;