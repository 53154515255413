import React from 'react';

import { handleGetPriceGuaranteeReportDealerPGR } from '../../../redux/modules/actions';
import PriceGuaranteeReportConnector from '../PriceGuaranteeReportConnector';
import PriceGuaranteeReportPageType from '../PriceGuaranteeReportPageType';
import { VLDGetPriceGuaranteeListPFC, VLDGetCommissionValidationResultDealerPFC } from '../../../privilege/context';
import routes from '../../../routes';
import { usePrivilege } from '../../../../common/hooks';

const PriceGuaranteeReportDealerConnector = () => {
  const { handleIsPrivileged } = usePrivilege();

  const commissionValidatorPath = handleIsPrivileged([new VLDGetPriceGuaranteeListPFC(), new VLDGetCommissionValidationResultDealerPFC()])
    ? routes.paths.ROUTE_PATH_COMMISSION_VALIDATOR_DEALER
    : null;

  return (
    <PriceGuaranteeReportConnector
      commissionValidatorPath={commissionValidatorPath}
      onGetPriceGuaranteeReportReduxFn={handleGetPriceGuaranteeReportDealerPGR}
      pageType={PriceGuaranteeReportPageType.DEALER}
      titleLsiCode='REPORT_PRICE_GUARANTEE_DEALER'
    />
  );
};

export default PriceGuaranteeReportDealerConnector;
