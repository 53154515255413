import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleGetDealerContractsATModelGroupsViewList } from '../../redux/modules/actions';

import DealerContractsATModelGroupView from './DealerContractsATModelGroupView';

const DealerContractsATModelGroupViewConnector = () => {
  const selectedState = useSelector(state => {
    return {
      contractATModelGroupsViewList: state.at.views.contractATModelGroupsViewList,
      filterData: state.at.views.filterData,
      columnDataList: state.at.views.columnDataList,
      isFetching: state.at.views.isFetching,
      valuesFilter: state.at.views.valuesFilter
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        handleGetDealerContractsATModelGroupsViewList: (filterParams) => dispatch(handleGetDealerContractsATModelGroupsViewList(filterParams))
      };
    },
    [dispatch]
  );

  return (
    <DealerContractsATModelGroupView
      columnDataList={selectedState.columnDataList}
      contractATModelGroupsViewList={selectedState.contractATModelGroupsViewList}
      filterData={selectedState.filterData}
      isFetching={selectedState.isFetching}
      onGetDealerContractsATModelGroupsViewList={callbacks.handleGetDealerContractsATModelGroupsViewList}
      valuesFilter={selectedState.valuesFilter}
    />
  );
};

export default DealerContractsATModelGroupViewConnector;