import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import Icon from '@mdi/react';

import { LsiContext } from '../../../../common/contexts';
import PvtComparisonEnum from '../SwapCommissionPvtTable/PvtComparisonEnum';

import './SwapCommissionPvtIcon.scss';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    negotiable: PropTypes.bool
  })
};

const defaultProps = {
  className: null,
  value: null
};

const ICON_SIZE = 0.8;

const SwapCommissionPvtIconRenderer = (props) => {
  const { value, node } = props;

  const lsi = useContext(LsiContext);

  const comparison = value.comparison;

  if (PvtComparisonEnum.isEqual(comparison) || node?.isRowPinned()) {
    return null;
  }

  let icon;
  if (PvtComparisonEnum.isLess(comparison)) {
    icon = (
      <Icon
        className='ovex-pvt-SwapCommissionPvtIcon-left'
        path={mdiArrowLeft}
        size={ICON_SIZE}
        title={lsi.getLSIItem('PVT.TOOLTIP.SWAP_COMMISSION_PVT_BACK')}
      />
    );
  }
  if (PvtComparisonEnum.isGreater(comparison)) {
    icon = (
      <Icon
        className='ovex-pvt-SwapCommissionPvtIcon-right'
        path={mdiArrowRight}
        size={ICON_SIZE}
        title={lsi.getLSIItem('PVT.TOOLTIP.SWAP_COMMISSION_PVT_FORWARD')}
      />
    );
  }

  return (
    <div
      className='ovex-pvt-SwapCommissionPvtIcon'
    >
      {icon}
    </div>
  );
};

SwapCommissionPvtIconRenderer.propTypes = propTypes;
SwapCommissionPvtIconRenderer.defaultProps = defaultProps;

export default SwapCommissionPvtIconRenderer;