import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { Code, Select } from '../../../../../common/components/forms';
import { Button, Modal } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import { generateRestrictionTypeOptions } from '../../../../utils/const';

import './RestrictionDefinitionForm.scss';

const DefaultValues = {
  getModelMask: (modelGroup) => `${modelGroup}---`
};

const RestrictionDefinitionCreationForm = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const [ modelMask, setModelMask ] = React.useState(DefaultValues.getModelMask(props.modelGroup));
  const [ restrictionType, setRestrictionType ] = React.useState(null);
  const [ colour, setColour ] = React.useState(null);
  const [ interior, setInterior ] = React.useState(null);
  const [ prNumber, setPrNumber ] = React.useState(null);

  const handleSave = () => {
    props.onSave({
      modelGroup: props.modelGroup,
      restrictionType: restrictionType,
      modelMask: modelMask ? modelMask.toUpperCase() : null,
      colour: colour ? colour.toUpperCase() : null,
      interior: interior ? interior.toUpperCase() : null,
      prNumber: prNumber ? prNumber.toUpperCase() : null
    });
  };

  const validModelMask = modelMask.startsWith(props.modelGroup);

  return (
    <Modal
      className='ovex-pvt-RestrictionDefinitionCreationForm'
      header={lsi.getLSIItem('PVT.PAGE_TITLE.NEW_RESTRICTION')}
      onClose={props.onClose}
      shown={props.shown}
    >
      <Forms.Form
        onCancel={props.onClose}
        onSave={handleSave}
      >
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.GROUP')}
          name='modelGroup'
          readOnly
          required
          value={props.modelGroup}
        />
        <Select
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.RESTRICTION_TYPE')}
          items={generateRestrictionTypeOptions(lsi.getLSIItem('PVT.ENUM_LABELS.RESTRICTION_TYPE'))}
          onChange={(opt)=>setRestrictionType(opt.value)}
          required
          name='restrictionType'
          value={restrictionType}
        />
        <Code
          feedback={validModelMask ? Forms.InputMixin.INITIAL_FEEDBACK : Forms.InputMixin.ERROR_FEEDBACK}
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.MASK')}
          maxLength={6}
          message={validModelMask ? '' : lsi.getLSIItem('PVT.FORM_INVALID_INPUT.MODEL_MASK')}
          name='modelMask'
          onChange={setModelMask}
          pattern='^[a-zA-Z0-9]{3}[a-zA-Z0-9-]{3}$'
          required
          upperCase
          value={modelMask}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.COLOUR')}
          maxLength={4}
          name='colour'
          onChange={setColour}
          pattern='^$|^[a-zA-Z0-9]{4}$'
          upperCase
          value={colour}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.INTERIOR')}
          maxLength={2}
          name='interior'
          onChange={setInterior}
          pattern='^$|^[a-zA-Z0-9]{2}$'
          upperCase
          value={interior}
        />
        <Code
          label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.PR_NUMBER')}
          maxLength={3}
          name='prNumber'
          onChange={setPrNumber}
          pattern='^$|^[a-zA-Z0-9]{3}$'
          upperCase
          value={prNumber}
        />
        <Forms.Controls
          buttonCancelProps={Button.formControlsProps[Button.type.SECONDARY]}
          buttonSubmitProps={Button.formControlsProps[Button.type.PRIMARY]}
        />
      </Forms.Form>
    </Modal>
  );
});

RestrictionDefinitionCreationForm.propTypes = {
  modelGroup: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  shown: PropTypes.bool
};

RestrictionDefinitionCreationForm.defaultProps = {
  shown: false
};

export default RestrictionDefinitionCreationForm;