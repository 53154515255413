import { sortAlphabetically } from '../../common/utils/helpers';

export const userRoleSorter = (imitatedRoleList) => (role1, role2) => {
  const role1Imitated = imitatedRoleList.some((r) => r === role1);
  const role2Imitated = imitatedRoleList.some((r) => r === role2);

  if (role1Imitated && role2Imitated) {
    return sortAlphabetically(true)(role1, role2);
  }
  if (role1Imitated) {
    return -1;
  }
  if (role2Imitated) {
    return 1;
  }
  return sortAlphabetically(true)(role1, role2);
};