import React from 'react';
import { useDispatch } from 'react-redux';

import { usePrivilege, useRouteMatchMemo } from '../../../common/hooks';
import moduleContextData from '../../config/moduleContextData';
import {
  GetAnnualTargetCalendarYearsFunctionalityContext,
  CreateAnnualTargetYearFunctionalityContext,
  GetAnnualTargetYearsFunctionalityContext,
  GetDealerContractsFunctionalityContext,
  GetDealerContractsListFunctionalityContext,
  GetModelGroupsFunctionalityContext,
  GetNegotiationDealersFunctionalityContext,
  GetProductionCapacityRatioFunctionalityContext,
  GetDealerContractListFunctionalityContext,
  GetDealerContractPreviewFunctionalityContext,
  GetDealerContractsATModelGroupViewListFunctionalityContext,
  GetDealerContractsATSPeriodsViewListFunctionalityContext,
  GetCommissionListForDealerFunctionalityContext,
  GetCommissionListForImporterFunctionalityContext,
  SaveCommissionLoadParamsForDealerFunctionalityContext,
  SaveCommissionLoadParamsForImporterFunctionalityContext
} from '../../privilege/context';
import { handlePrivilegeInitialized, handlePrivilegeInitializationFailed } from '../../redux/modules/actions';
import modulePaths from '../../routes/modulePaths';

const propTypes = {};
const defaultProps = {};

const AnnualTargetInitialization = () => {
  const matchDealerContractPreview = useRouteMatchMemo(modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACT_PREVIEW);

  const dispatch = useDispatch();
  const { handlePrivilegeRequest } = usePrivilege(moduleContextData);

  React.useEffect(
    () => {
      const functionalityContextList = [
        new GetAnnualTargetCalendarYearsFunctionalityContext(),
        new CreateAnnualTargetYearFunctionalityContext(),
        new GetAnnualTargetYearsFunctionalityContext(),
        new GetDealerContractsFunctionalityContext(),
        new GetDealerContractsListFunctionalityContext(),
        new GetModelGroupsFunctionalityContext(),
        new GetNegotiationDealersFunctionalityContext(),
        new GetProductionCapacityRatioFunctionalityContext(),
        new GetDealerContractsATModelGroupViewListFunctionalityContext(),
        new GetDealerContractsATSPeriodsViewListFunctionalityContext(),
        new GetCommissionListForDealerFunctionalityContext(),
        new GetCommissionListForImporterFunctionalityContext(),
        new SaveCommissionLoadParamsForDealerFunctionalityContext(),
        new SaveCommissionLoadParamsForImporterFunctionalityContext()
      ];

      if (matchDealerContractPreview != null) {
        const contractIdParam = matchDealerContractPreview.params.contractId;
        const contractId = Number(contractIdParam);
        if (!Number.isNaN(contractId)) {
          functionalityContextList.push(new GetDealerContractListFunctionalityContext(contractId));
          functionalityContextList.push(new GetDealerContractPreviewFunctionalityContext(contractId));
        }
      }

      const asyncCall = async () => {
        try {
          await handlePrivilegeRequest(functionalityContextList);
          dispatch(handlePrivilegeInitialized());
        } catch (e) {
          dispatch(handlePrivilegeInitializationFailed());
        }
      };
      asyncCall().then();
    },
    [handlePrivilegeRequest, dispatch, matchDealerContractPreview]
  );

  return null;
};

AnnualTargetInitialization.propTypes = propTypes;
AnnualTargetInitialization.defaultProps = defaultProps;

export default AnnualTargetInitialization;
