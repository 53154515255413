import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import './Number.scss';

const NumberComponent = (props, ref) => {
  return (
    <Forms.Number
      bgStyle='underline'
      className={['ovex-forms-number', props.className].join(' ')}
      colorSchema='green'
      decimals={props.decimals}
      disabled={props.disabled}
      hidePrefixOnFocus={!props.readOnly && !!props.suffix}
      hideSuffixOnFocus={!props.readOnly && !!props.suffix}
      inputAttrs={{
        autoComplete: 'off'
      }}
      inputColWidth={props.inputColWidth}
      label={props.label}
      labelColWidth={props.labelColWidth}
      max={props.max}
      min={props.min}
      name={props.name}
      onChange={props.onChange}
      parent={props.parent}
      readOnly={props.readOnly}
      ref_={ref}
      required={props.required}
      rounded={props.decimals != null}
      step={props.step}
      suffix={props.suffix}
      tooltip={props.tooltip}
      value={props.value}
      valueType='number'
    />
  );
};

const Number = React.forwardRef(NumberComponent);
Number.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

Number.propTypes = {
  className: PropTypes.string,
  decimals: PropTypes.number,
  disabled: PropTypes.bool,
  inputColWidth: PropTypes.string,
  label: PropTypes.string,
  labelColWidth: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  step: PropTypes.number,
  suffix: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.number
};

Number.defaultProps = {
  className: null,
  decimals: null,
  disabled: false,
  inputColWidth: 'xs12',
  label: null,
  labelColWidth: 'xs12',
  max: null,
  min: null,
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  step: 1,
  suffix: null,
  tooltip: null,
  value: null
};

export default Number;
