/**
 * Regex for validation
 * @type {RegExp}
 */
const PERCENT_2_DECIMALS_REGEX = /^[0-9]{0,3}[.,]?[0-9]{1,2}$/;

/**
 * Regex for validation decimal representation of percent with 2 decimals e.g. 20,52% = 0,2052
 * @type {RegExp}
 */
const DECIMALS_4_PERCENT_REGEX = /^0[.,]?[0-9]{1,4}$/;

export const percentValidator = (value) => PERCENT_2_DECIMALS_REGEX.test(value);

export const decimalPercentValidator = (value) => DECIMALS_4_PERCENT_REGEX.test(value);