import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { useIsPrivileged } from '../../../hooks';
import PrivilegeFunctionalityContextPropType from '../../../privilege/PrivilegeFunctionalityContextPropType';
import ButtonType from '../ButtonType';

import ButtonTypeProps from './ButtonTypeProps';

import './Button.scss';

const propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]).isRequired,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  privilegeFunctionalityContext: PrivilegeFunctionalityContextPropType.propType,
  style: PropTypes.object,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ButtonType)).isRequired
};

const defaultProps = {
  className: null,
  disabled: false,
  hidden: false,
  onClick: undefined,
  privilegeFunctionalityContext: PrivilegeFunctionalityContextPropType.defaultProp,
  style: null,
  tooltip: null
};

const Button = (props) => {
  const hidden = !useIsPrivileged(props);

  const typeProps = ButtonTypeProps[props.type] || ButtonTypeProps.DEFAULT;

  const classNames = ['ovex-Button', typeProps.className];
  if (props.className != null) {
    classNames.push(props.className);
  }

  const disabled = props.disabled || props.onClick == null;
  const tooltip = props.tooltip;

  const buttonComponent = (
    <Bricks.Button
      bgStyle={typeProps.bgStyle}
      borderRadius={typeProps.borderRadius}
      className={classNames.join(' ')}
      colorSchema={typeProps.colorSchema}
      content={props.content}
      disabled={disabled}
      elevation={typeProps.elevation}
      hidden={props.hidden || hidden}
      onClick={props.onClick}
      style={props.style}
      tooltip={tooltip}
    />
  );

  if (disabled && tooltip) {
    // disabled button has set CSS property  pointer-event: none
    // https://stackoverflow.com/questions/47411502/pointer-eventsnone-prevents-title-attribute
    return (
      <span title={tooltip}>
        {buttonComponent}
      </span>
    );
  }

  return buttonComponent;
};

Button.type = ButtonType;
Button.formControlsProps = ButtonTypeProps;

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
