import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { Page } from '../../packages/common/components';
import { LsiContext } from '../../packages/common/contexts';

import './Home.scss';

class Home extends React.PureComponent {

  static contextType = LsiContext;

  render() {
    const lsi = this.context;

    return (
      <Page
        className='ovex-home'
      >
        <Bricks.Well
          className='ovex-home-info'
        >
          <Bricks.Header
            content={lsi.getLSIItem('APP_NAME')}
            level='3'
            style={{ marginTop: 0 }}
          />
          {lsi.getLSIItem('DESCRIPTION')}
        </Bricks.Well>
      </Page>
    );
  }
}

export default Home;
