import {
  AggregationTypeEnum as AggregationTypeEnumClass,
  ContractStatusEnum as ContractStatusEnumClass,
  NegotiationTypeEnum as NegotiationTypeEnumClass,
  NegotiationStatusEnum as NegotiationStatusEnumClass,
  TextBlockEnum as TextBlockEnumClass
} from '@ovex/annual-target-web-api';

export const AggregationTypeEnum = new AggregationTypeEnumClass();
export const ContractStatusEnum = new ContractStatusEnumClass();
export const NegotiationTypeEnum = new NegotiationTypeEnumClass();
export const NegotiationStatusEnum = new NegotiationStatusEnumClass();
export const TextBlockEnum = new TextBlockEnumClass();
