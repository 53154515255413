import { mdiCarHatchback, mdiPackageVariantClosed, mdiPaletteOutline, mdiSteering } from '@mdi/js';

import {
  CURRENCY_SYMBOL,
  PGISpecificationSnapshotEnum,
  PGISpecificationTypeEnum,
  SALES_ACCOUNT_TYPE_LIST,
  SPECIFICATION_CHANGE_TYPE_WITH_EMPTY_VALUE_LIST,
  SPECIFICATION_CHANGE_TYPE_EMPTY
} from '../../../utils/const';
import { dateFormatter, number2Formatter } from '../../../../common/utils/formatters';
import { EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';

import {
  handleEditableCellIsEditable,
  editableCellValueGetter,
  getEditableCell,
  getSpecificationKey,
  editableCellValueParser,
  editableCellValueSetter
} from './PriceGuaranteeItemTable.helpers';
import { PGI_EDITABLE_COLUMN_IDS, PGI_GROUP_COLUMN_IDS, PGI_READ_ONLY_COLUMN_IDS } from './PriceGuaranteeItemTable.columnIds';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  EDITABLE_CELL_CD: 'editableCellDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { buttons: ['reset'], newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.EDITABLE_CELL_CD]: {
    cellClassRules: {
      'ovex-pg-PriceGuaranteeItemTable-cell--editable': handleEditableCellIsEditable,
      'ovex-pg-PriceGuaranteeItemTable-cell--invalid': (cellClassParams) => {
        const editableCell = getEditableCell(cellClassParams);
        return !!editableCell && !editableCell.isValid();
      },
      'ovex-pg-PriceGuaranteeItemTable-cell--modified': (cellClassParams) => {
        const editableCell = getEditableCell(cellClassParams);
        return !!editableCell && editableCell.isModified();
      }
    },
    editable: handleEditableCellIsEditable,
    valueGetter: editableCellValueGetter,
    valueParser: editableCellValueParser,
    valueSetter: editableCellValueSetter
  }
};

export const getColumnDefinitions = (lsi, currency) => {

  return [
    {
      colId: PGI_READ_ONLY_COLUMN_IDS.MODEL_GROUP,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.GROUP'),
      cellClass: ['ovex-pg-PriceGuaranteeItemTable-cell--code'],
      field: 'modelGroup',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      rowGroup: true,
      hide: true,
      flex: 9,
      minWidth: 90
    },
    {
      colId: PGI_READ_ONLY_COLUMN_IDS.PUBLISHED,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PUBLISHED'),
      field: 'published',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      hide: true,
      pinned: 'left',
      width: 100,
      minWidth: 100
    },
    {
      colId: PGI_READ_ONLY_COLUMN_IDS.PGID,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PGID'),
      cellClass: ['ovex-pg-PriceGuaranteeItemTable-cell--code'],
      field: 'pgid',
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      filter: 'agTextColumnFilter',
      pinned: 'left',
      width: 160,
      minWidth: 160
    },
    ...getSpecificationColumnDefinitions(lsi),
    {
      colId: PGI_GROUP_COLUMN_IDS.AMOUNT_GROUP,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.GUARANTEE_AMOUNT', CURRENCY_SYMBOL[currency]),
      headerClass: ['ovex-ag-grid--th-center'],
      marryChildren: true,
      children: [
        {
          colId: PGI_READ_ONLY_COLUMN_IDS.TOTAL_AMOUNT,
          headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.GUARANTEE_TOTAL_AMOUNT'),
          headerTooltip: [lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.INVALID_TOTAL_AMOUNT')],
          cellClass: ['ovex-ag-grid--td-right'],
          valueFormatter: amountValueFormatter,
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
          filter: 'agNumberColumnFilter',
          flex: 12,
          minWidth: 120
        },
        {
          colId: PGI_EDITABLE_COLUMN_IDS.IMPORTER_AMOUNT,
          headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.GUARANTEE_IMPORTER_AMOUNT'),
          cellClass: ['ovex-ag-grid--td-right'],
          valueFormatter: amountValueFormatter,
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
          filter: 'agNumberColumnFilter',
          flex: 14,
          minWidth: 140
        },
        {
          colId: PGI_EDITABLE_COLUMN_IDS.HEADQUARTERS_AMOUNT,
          headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.GUARANTEE_HEADQUARTERS_AMOUNT'),
          cellClass: ['ovex-ag-grid--td-right'],
          valueFormatter: amountValueFormatter,
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
          filter: 'agNumberColumnFilter',
          flex: 14,
          minWidth: 140
        },
        {
          colId: PGI_EDITABLE_COLUMN_IDS.SALES_ACCOUNT_TYPE,
          headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.SALES_ACCOUNT_TYPE'),
          valueFormatter: salesAccountTypeFormatter,
          filterParams: {
            valueFormatter: salesAccountTypeFormatter
          },
          valueSetter: (valueSetterParams) => {
            return editableCellValueSetter(valueSetterParams);
          },
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: { values: SALES_ACCOUNT_TYPE_LIST },
          flex: 12,
          minWidth: 120
        }
      ]
    },
    {
      colId: PGI_EDITABLE_COLUMN_IDS.PRODUCTION_DATE_FROM,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRODUCTION_DATE_FROM'),
      headerTooltip: [lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_1'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_2'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_3')],
      cellClass: ['ovex-ag-grid--td-right'],
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
      flex: 11,
      minWidth: 110
    },
    {
      colId: PGI_EDITABLE_COLUMN_IDS.PRODUCTION_DATE_TO,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRODUCTION_DATE_TO'),
      headerTooltip: [lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_1'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_2'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.DATE_OF_PRODUCTION_PART_3')],
      cellClass: ['ovex-ag-grid--td-right'],
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
      flex: 11,
      minWidth: 110
    },
    {
      colId: PGI_EDITABLE_COLUMN_IDS.PRICE_VALID_FROM,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PRICE_VALID_FROM'),
      headerTooltip: [lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRICE_VALIDITY_PART_1'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.PRICE_VALIDITY_PART_2')],
      cellClass: ['ovex-ag-grid--td-right', EXCEL_STYLES_IDS.DATE],
      valueFormatter: dateValueFormatter,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
      cellEditor: 'datePicker',
      filter: 'agDateColumnFilter',
      flex: 13,
      minWidth: 135
    },
    {
      colId: PGI_EDITABLE_COLUMN_IDS.CHANGE_TYPE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CHANGE_TYPE'),
      headerTooltip: [lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CHANGE_TYPE_PART_1'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CHANGE_TYPE_PART_2'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CHANGE_TYPE_PART_3'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CHANGE_TYPE_PART_4'),
        lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CHANGE_TYPE_PART_5')],
      valueFormatter: changeTypeValueFormatter,
      filterParams: {
        valueFormatter: changeTypeValueFormatter
      },
      valueSetter: (valueSetterParams) => {
        if (valueSetterParams.newValue === SPECIFICATION_CHANGE_TYPE_EMPTY) {
          valueSetterParams.newValue = null;
        }
        return editableCellValueSetter(valueSetterParams);
      },
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: { values: SPECIFICATION_CHANGE_TYPE_WITH_EMPTY_VALUE_LIST },
      flex: 12,
      minWidth: 120
    },
    {
      colId: PGI_EDITABLE_COLUMN_IDS.CHANGE_DESCRIPTION,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CHANGE_DESCRIPTION'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CHANGE_DESCRIPTION'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
      filter: 'agTextColumnFilter',
      flex: 40,
      minWidth: 400
    },
    {
      colId: PGI_EDITABLE_COLUMN_IDS.NOTE,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.NOTE'),
      headerTooltip: lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.NOTE'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
      filter: 'agTextColumnFilter',
      flex: 40,
      minWidth: 400
    }
  ];
};

const SPECIFICATION_SNAPSHOT_COLUMN_ORDER = [
  PGI_GROUP_COLUMN_IDS.PREVIOUS,
  PGI_GROUP_COLUMN_IDS.CURRENT
];

const SPECIFICATION_TYPE_COLOR = null;
const SPECIFICATION_TYPE_NEG_COLOR = 'rgba(160, 0, 0, 0.54)';

const SPECIFICATION_TYPE_COLUMN_META_DATA = [
  {
    type: PGISpecificationTypeEnum.MODEL,
    headerComponentParams: { hiddenText: true, iconPath: mdiCarHatchback, iconColor: SPECIFICATION_TYPE_COLOR },
    flex: 8, minWidth: 80
  },
  {
    type: PGISpecificationTypeEnum.MODEL_NEG,
    headerComponentParams: { hiddenText: true, iconPath: mdiCarHatchback, iconColor: SPECIFICATION_TYPE_NEG_COLOR },
    flex: 8, minWidth: 80
  },
  {
    type: PGISpecificationTypeEnum.COLOUR,
    headerComponentParams: { hiddenText: true, iconPath: mdiPaletteOutline, iconColor: SPECIFICATION_TYPE_COLOR },
    flex: 8, minWidth: 80
  },
  {
    type: PGISpecificationTypeEnum.COLOUR_NEG,
    headerComponentParams: { hiddenText: true, iconPath: mdiPaletteOutline, iconColor: SPECIFICATION_TYPE_NEG_COLOR },
    flex: 8, minWidth: 80
  },
  {
    type: PGISpecificationTypeEnum.INTERIOR,
    headerComponentParams: { hiddenText: true, iconPath: mdiSteering, iconColor: SPECIFICATION_TYPE_COLOR },
    flex: 8, minWidth: 80
  },
  {
    type: PGISpecificationTypeEnum.INTERIOR_NEG,
    headerComponentParams: { hiddenText: true, iconPath: mdiSteering, iconColor: SPECIFICATION_TYPE_NEG_COLOR },
    flex: 8, minWidth: 80
  },
  {
    type: PGISpecificationTypeEnum.PACKAGE,
    headerComponentParams: { hiddenText: true, iconPath: mdiPackageVariantClosed, iconColor: SPECIFICATION_TYPE_COLOR },
    flex: 16, minWidth: 160
  },
  {
    type: PGISpecificationTypeEnum.PACKAGE_NEG,
    headerComponentParams: { hiddenText: true, iconPath: mdiPackageVariantClosed, iconColor: SPECIFICATION_TYPE_NEG_COLOR },
    flex: 16, minWidth: 160
  }
];

const getSpecificationColumnDefinitions = (lsi) => {

  return SPECIFICATION_SNAPSHOT_COLUMN_ORDER.map(snapshot => {
    let headerClass;
    if (snapshot === PGISpecificationSnapshotEnum.PREVIOUS) {
      headerClass = 'ovex-pg-PriceGuaranteeItemTable-column--previous';
    } else if (snapshot === PGISpecificationSnapshotEnum.CURRENT) {
      headerClass = 'ovex-pg-PriceGuaranteeItemTable-column--current';
    }

    return {
      colId: snapshot,
      headerName: lsi.getLSIItem(`PRICE_GUARANTEE.TABLE_HEADER.SPECIFICATION_SNAPSHOT.${snapshot}`),
      headerClass: ['ovex-ag-grid--th-center', headerClass],
      marryChildren: true,
      children: SPECIFICATION_TYPE_COLUMN_META_DATA.map(typeMD => {
        return {
          colId: getSpecificationKey(snapshot, typeMD.type),
          headerName: lsi.getLSIItem(`PRICE_GUARANTEE.TABLE_HEADER.SPECIFICATION_TYPE.${typeMD.type}`),
          headerTooltip: lsi.getLSIItem(`PRICE_GUARANTEE.TABLE_HEADER.SPECIFICATION_TYPE.${typeMD.type}`),
          headerComponent: 'customHeaderLabel',
          headerComponentParams: typeMD.headerComponentParams,
          headerClass: [headerClass],
          cellClass: ['ovex-pg-PriceGuaranteeItemTable-cell--code'],
          tooltipValueGetter: editableCellValueGetter,
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.EDITABLE_CELL_CD],
          filter: 'agTextColumnFilter',
          flex: typeMD.flex,
          minWidth: typeMD.minWidth
        };
      })
    };
  });
};

const salesAccountTypeFormatter = (valueFormatterParams) => {
  const value = valueFormatterParams.value;
  if (value) {
    const lsi = valueFormatterParams.context.agLsi;
    return lsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.SALES_ACCOUNT_TYPE.${value}`);
  }
  return value;
};

const changeTypeValueFormatter = (valueFormatterParams) => {
  const value = valueFormatterParams.value;
  if (value) {
    const lsi = valueFormatterParams.context.agLsi;
    if (value === SPECIFICATION_CHANGE_TYPE_EMPTY) {
      return lsi.getLSIItem('AG_GRID.blanks');
    }
    return lsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CHANGE_TYPE.${value}`);
  }
  return value;
};

const dateValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  return dateFormatter(valueFormatterParams.value, formatters);
};

const amountValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  const value = valueFormatterParams.value;
  const valueFormatted = number2Formatter(value, formatters);
  return 'NaN' === valueFormatted ? value : valueFormatted;
};
