import { connect } from 'react-redux';

import { handleGetAvailableCapacityList } from '../../redux/modules/actions';

import AvailableCapacity from './AvailableCapacity';

const mapStateToProps = state => ({
  isFetching: state.pvt.availableCapacity.isFetching,
  availableCapacityResponse: state.pvt.availableCapacity.availableCapacityResponse
});

const mapDispatchToProps = dispatch => ({
  onGetAvailableCapacityList: (filterData) => dispatch(handleGetAvailableCapacityList(filterData))
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailableCapacity);