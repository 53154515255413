import { ContractStatusEnum } from '../../../utils/const';
import {
  GetDealerContractPreviewFunctionalityContext,
  ShiftDealerContractWorkflowFunctionalityContext
} from '../../../privilege/context';
import ModelGroupAnnualTargetConverter from '../ModelGroupAnnualTargetTable/ModelGroupAnnualTargetConverter';
import PeriodAnnualTargetSalesConverter from '../PeriodAnnualTargetSalesTable/PeriodAnnualTargetSalesConverter';

import ContractRow from './ContractRow';

const ContractConverter = {

  /**
   * @param {Object|module:model/ContractDetailDTO} data The plain JavaScript object bearing properties of interest.
   * @param {Array.<string>} roleList - user role list
   * @return {ContractRow}
   */
  fromObject: (data, roleList) => {
    if (data) {
      const contract = new ContractRow();

      contract.id = data.hasOwnProperty('id') ? data.id : null;

      if (data.hasOwnProperty('negotiation')) {
        contract.negotiation = data['negotiation'];
      }
      if (data.hasOwnProperty('status')) {
        contract.status = data['status'];
      }

      contract.additionOrder = data.hasOwnProperty('additionOrder') ? data.additionOrder : null;

      contract.statisticsTimestamp = data.hasOwnProperty('statisticsTimestamp') ? data.statisticsTimestamp : null;
      contract.statisticsType = data.hasOwnProperty('statisticsType') ? data.statisticsType : null;
      contract.statisticsRequestId = data.hasOwnProperty('statisticsRequestId') ? data.statisticsRequestId : null;

      if (data.hasOwnProperty('modelGroupList')) {
        contract.modelGroupAnnualTargetList = ModelGroupAnnualTargetConverter.fromList(data.modelGroupList, contract.status, roleList);
      }
      if (data.hasOwnProperty('branchList')) {
        contract.periodAnnualTargetList = PeriodAnnualTargetSalesConverter.fromBranchList(data.branchList, contract.status, roleList);
      }

      const shiftWorkflowWebParams = { contractStatus: contract.status };
      contract.privilegeFunctionalityContextObject = {
        getDealerContractPreviewFnCtx: new GetDealerContractPreviewFunctionalityContext(contract.id),
        shiftWorkflowCreatedFnCtx: new ShiftDealerContractWorkflowFunctionalityContext(contract.id, ContractStatusEnum.CREATED, shiftWorkflowWebParams),
        shiftWorkflowAreaAdvisorApprovedFnCtx: new ShiftDealerContractWorkflowFunctionalityContext(contract.id, ContractStatusEnum.AREA_ADVISOR_APPROVED, shiftWorkflowWebParams),
        shiftWorkflowAAManagerApprovedFnCtx: new ShiftDealerContractWorkflowFunctionalityContext(contract.id, ContractStatusEnum.AA_MANAGER_APPROVED, shiftWorkflowWebParams),
        shiftWorkflowRejectedFnCtx: new ShiftDealerContractWorkflowFunctionalityContext(contract.id, ContractStatusEnum.REJECTED, shiftWorkflowWebParams)
      };

      return contract;
    }
    return null;
  }
};

export default ContractConverter;
