import { WebModule, WebModuleInitializationData, WebModuleNavMenuData, WebModuleUserInfoData } from '../web-module';

import AnnualTargetInitialization from './components/AnnualTargetInitialization/AnnualTargetInitialization';
import AnnualTargetNavMenu from './components/AnnualTargetNavMenu/AnnualTargetNavMenu';
import { moduleCode, userInfoSelector, initializationStatusSelector } from './config/moduleConfig';
import reduxData from './redux';
import routesData from './routes';

const navMenuDataList = [
  new WebModuleNavMenuData(200, AnnualTargetNavMenu)
];

const moduleAnnualTarget = new WebModule(
  moduleCode,
  new WebModuleInitializationData(AnnualTargetInitialization, initializationStatusSelector),
  navMenuDataList,
  reduxData,
  routesData,
  new WebModuleUserInfoData(reduxData.actions.handleGetUserInfo, userInfoSelector)
);

export default moduleAnnualTarget;