import { ApiClient as AboutApiClient } from '@ovex/about-web-api';
import { ApiClient as PrivilegeApiClient } from '@ovex/privilege-web-api';
import { ApiClient as PvtApiClient } from '@ovex/pvt-web-api';

import plugins from '../../common/api/plugins';
import { ApisSettings } from '../../common/api';
import { apiBasePath } from '../config/moduleConfig';

/**
 * ApiClient instances
 */
const ApiClientInstances = {
  about: new AboutApiClient(),
  privilege: new PrivilegeApiClient(),

  pvt: new PvtApiClient()
};

ApisSettings.removeDefaultInstance([AboutApiClient, PrivilegeApiClient, PvtApiClient]);
ApisSettings.setBasePath(Object.values(ApiClientInstances), apiBasePath);
ApisSettings.setPlugin(Object.values(ApiClientInstances), plugins);

export default ApiClientInstances;
