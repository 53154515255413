import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { arraysEquals } from '../../../utils/arrays';
import { Button } from '../../../../common/components';
import { OvexAGTable } from '../../ag-grid/OvexAGTable';

import './ListSelector.scss';

const ListSelectorComponent = (props, ref) => {
  const classNames = ['ovex-forms-list-selector', props.className];

  const filterAvailableOptions = React.useCallback(item => !props.value || !props.value.includes(item.value), [props.value]);

  const [ availableOptions, setAvailableOptions ] = React.useState(props.items && props.items.filter(filterAvailableOptions));
  const [ selectedOptions, setSelectedOptions ] = React.useState(props.items && props.items.filter(item => !filterAvailableOptions(item)));

  const onChange = props.onChange ? props.onChange : undefined;
  const handleSetSelectedOptions = React.useCallback((newSelectedOptions) => {
    if (!arraysEquals(selectedOptions, newSelectedOptions, item => item.value)) {
      setSelectedOptions(newSelectedOptions);
      onChange && onChange(newSelectedOptions);
    }
  }, [selectedOptions, setSelectedOptions, onChange]);

  React.useEffect(() => setAvailableOptions(props.items && props.items.filter(filterAvailableOptions)), [props.items, filterAvailableOptions]);
  React.useEffect(() => handleSetSelectedOptions(props.items && props.items.filter(item => !filterAvailableOptions(item))), [props.items, filterAvailableOptions, handleSetSelectedOptions]);

  // "ref_" used from props, because "ref" overridden by UU5
  React.useImperativeHandle(props.ref_, () => ({
    getValue: () => {
      return selectedOptions;
    }
  }));

  const availableOptionsTableRef = React.useRef(null);
  const selectedOptionTableRef = React.useRef(null);
  const handleSetAvailableOptionsTableRef = refTable => availableOptionsTableRef.current = refTable;
  const handleSetSelectedOptionsTableRef = refTable => selectedOptionTableRef.current = refTable;

  const handleClickAllToSelectedOptions = () => {
    setAvailableOptions([]);
    handleSetSelectedOptions([...selectedOptions, ...availableOptions]);
  };
  const handleClickSelectedToSelectedOptions = () => {
    const selectedRows = availableOptionsTableRef.current.api.getSelectedRows();
    setAvailableOptions(availableOptions.filter(item => !selectedRows.find(row => row.value === item.value)));
    handleSetSelectedOptions([...selectedOptions, ...selectedRows]);
  };
  const handleClickSelectedToAvailableOptions = () => {
    const selectedRows = selectedOptionTableRef.current.api.getSelectedRows();
    setAvailableOptions([...availableOptions, ...selectedRows]);
    handleSetSelectedOptions(selectedOptions.filter(item => !selectedRows.find(row => row.value === item.value)));
  };
  const handleClickAllToAvailableOptions = () => {
    setAvailableOptions([...availableOptions, ...selectedOptions]);
    handleSetSelectedOptions([]);
  };

  const contentColumnDef = { colId: 'checkbox', checkboxSelection: true, width: 40, menuTabs: [] };

  return (
    <Bricks.Div
      className={classNames.join(' ')}
      style={{
        height: '300px'
      }}
    >
      <OvexAGTable
        className='ovex-forms-list-selector-table-available-options'
        columnDefs={[contentColumnDef, ...props.columnDefAvailableOptions]}
        columnTypes={props.columnTypes}
        enableRangeSelection={props.enableRangeSelection}
        height='100%'
        onGridReady={handleSetAvailableOptionsTableRef}
        rowData={availableOptions}
        rowSelection='multiple'
        suppressRowClickSelection
      />
      <Bricks.Div className='ovex-forms-list-selector-buttons'>
        <Button
          content='>>'
          onClick={handleClickAllToSelectedOptions}
          type={Button.type.SECONDARY}
        />
        <Button
          content='>'
          onClick={handleClickSelectedToSelectedOptions}
          type={Button.type.SECONDARY}
        />
        <Button
          content='<'
          onClick={handleClickSelectedToAvailableOptions}
          type={Button.type.SECONDARY}
        />
        <Button
          content='<<'
          onClick={handleClickAllToAvailableOptions}
          type={Button.type.SECONDARY}
        />
      </Bricks.Div>
      <OvexAGTable
        className='ovex-forms-list-selector-table-selected-options'
        columnDefs={[contentColumnDef, ...props.columnDefSelectedOptions]}
        columnTypes={props.columnTypes}
        enableRangeSelection={props.enableRangeSelection}
        height='100%'
        onGridReady={handleSetSelectedOptionsTableRef}
        rowData={selectedOptions}
        rowSelection='multiple'
        suppressRowClickSelection
      />
    </Bricks.Div>

  );
};

const ListSelector = React.forwardRef(ListSelectorComponent);
ListSelector.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

ListSelector.propTypes = {
  className: PropTypes.string,
  columnDefAvailableOptions: PropTypes.arrayOf(PropTypes.object),
  columnDefSelectedOptions: PropTypes.arrayOf(PropTypes.object),
  columnTypes: PropTypes.object,
  enableRangeSelection: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  })),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
};

ListSelector.defaultProps = {
  className: null,
  columnDefAvailableOptions: null,
  columnDefSelectedOptions: null,
  columnTypes: null,
  enableRangeSelection: false,
  items: null,
  label: null,
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  value: null
};

export default ListSelector;