import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import {
  RestrictionCreateDTO,
  RestrictionDTO,
  RestrictionWeekAddDTO,
  RestrictionWeekCreateDTO,
  RestrictionWeekDeleteDTO,
  RestrictionWeekUpdateDTO,
  WeekDetailDTO
} from '@ovex/pvt-web-api';

import { OvexAGTable, RowGroupingDisplayType } from '../../../../common/components/ag-grid';
import { ConfirmModal, Tabs } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';

import RestrictionDefinitionCreationForm from './RestrictionDefinitionForm/RestrictionDefinitionCreationForm';
import RestrictionsButtonBar from './RestrictionsButtonBar/RestrictionsButtonBar';
import RestrictionRowTypeEnum from './rowModel/RestrictionRowTypeEnum';
import RestrictionRow from './rowModel/RestrictionRow';
import RestrictionsTableData from './RestrictionsTableData';
import { isWeekColumn } from './restrictionUtils';
import RestrictionsTabEnum from './RestrictionsTabEnum';

import './RestrictionsTable.scss';

class RestrictionsTable extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    onUpdateRestrictions: PropTypes.func.isRequired,
    restrictions: PropTypes.arrayOf(PropTypes.instanceOf(RestrictionDTO)),
    weekDetailList: PropTypes.arrayOf(PropTypes.instanceOf(WeekDetailDTO))
  };

  static defaultProps = {
    restrictions: null,
    weekDetailList: null
  };

  static getDerivedStateFromProps(props, state) {
    if (props.restrictions !== state.prevPropsRestrictions || props.weekDetailList !== state.prevPropsWeekDetailList) {
      return {
        prevPropsRestrictions: props.restrictions,
        prevPropsWeekDetailList: props.weekDetailList,
        restrictionRows: RestrictionsTableData.transformToRestrictionRows(props.restrictions),
        weeks: props.weekDetailList?.map(w => w.pvtWeek)
      };
    }
    return null;
  }

  constructor(props, context) {
    super(props, context);

    this.confirmModalRef = React.createRef();

    this.restrictionsTableData = new RestrictionsTableData();

    this.state = {
      prevPropsRestrictions: null,
      prevPropsWeekDetailList: null,

      editable: false,
      restrictionRows: null,
      selectedTab: RestrictionsTabEnum.NEW_PLAN,
      weeks: null,
      restrictionDefinitionForm: {
        shown: false,
        modelGroup: null
      }
    };
  }

  handleRefTable = (refTable) => {
    this.refTable = refTable;
  };

  handleRefreshRestrictionWeekColumns = () => {
    const { weeks } = this.state;

    this.refTable.api.refreshCells({ columns: weeks, force: true });
  };

  handleEnableEdit = () => {
    const { selectedTab } = this.state;

    const newStatePart = RestrictionsTabEnum.isNewPlan(selectedTab) ? { editable: true } : { editable: true, selectedTab: RestrictionsTabEnum.NEW_PLAN };

    this.setState(newStatePart, this.handleRefreshRestrictionWeekColumns);
  };

  handleCancelEdit = (callback) => {
    this.setState({ editable: false }, callback);
  };

  _hasChanges = () => {
    const data = [];
    this.refTable.api.forEachLeafNode((node) => data.push(node.data));

    return data.some(restrictionRow => {
      return restrictionRow.isNew()
        || restrictionRow.isDeleteFlagChanged()
        || Object.keys(restrictionRow).some(key => isWeekColumn(key) && restrictionRow[key].isChanged());
    });
  };

  handleCancelEditAndDiscardChanges = () => {
    if (this._hasChanges()) {
      this.handleCancelEdit(this.handleDiscardChanges);
    } else {
      this.handleCancelEdit(this.handleRefreshRestrictionWeekColumns);
    }
  };

  handleDiscardChanges = () => {
    const { restrictions } = this.props;

    this.setState({ restrictionRows: RestrictionsTableData.transformToRestrictionRows(restrictions) }, this.handleRefreshRestrictionWeekColumns);
  };

  _hasDataToDelete = () => {
    const data = [];
    this.refTable.api.forEachLeafNode((node) => data.push(node.data));

    return data.some(restrictionRow => restrictionRow.isToDelete());
  };

  handleCheckDeletedDataBeforeSave = () => {
    if (this._hasDataToDelete()) {
      this.confirmModalRef.current.open();
    } else {
      this.handleUpdateRestrictions().then();
    }
  };

  handleUpdateRestrictions = async () => {
    const { onUpdateRestrictions } = this.props;

    const restrictionWeeksUpdate = [];
    const restrictionWeeksDelete = [];
    const restrictionWeeksAdd = [];
    const restrictionsCreate = [];
    const restrictionsDelete = [];
    const restrictionsUndoDelete = [];

    this.refTable.api.forEachLeafNode((node) => {
      const data = node.data;
      if (data.isNew()) {
        const restrictionCreateDTO = RestrictionCreateDTO.constructFromObject(data);
        restrictionCreateDTO['restrictionWeeks'] = [];
        Object.keys(data)
          .filter(key => isWeekColumn(key) && (data[key].isAdded() || data[key].isModified()))
          .forEach(key => {
            const rw = RestrictionWeekCreateDTO.constructFromObject(data[key]);
            restrictionCreateDTO['restrictionWeeks'].push(rw);
          });
        restrictionsCreate.push(restrictionCreateDTO);
      } else {
        Object.keys(data)
          .filter(key => isWeekColumn(key) && data[key].isModified())
          .forEach(key => {
            const rw = RestrictionWeekUpdateDTO.constructFromObject(data[key]);
            restrictionWeeksUpdate.push(rw);
          });

        Object.keys(data)
          .filter(key => isWeekColumn(key) && data[key].isDeleted())
          .forEach(key => {
            const rw = RestrictionWeekDeleteDTO.constructFromObject(data[key]);
            restrictionWeeksDelete.push(rw);
          });

        const restrictionWeeksCreate = [];
        Object.keys(data)
          .filter(key => isWeekColumn(key) && data[key].isAdded())
          .forEach(key => {
            const rw = RestrictionWeekCreateDTO.constructFromObject(data[key]);
            restrictionWeeksCreate.push(rw);
          });
        if(restrictionWeeksCreate.length > 0){
          restrictionWeeksAdd.push(new RestrictionWeekAddDTO(data.id, restrictionWeeksCreate));
        }


        if (data.isDeleteFlagChanged()) {
          if (data.deletedInFuture) {
            restrictionsDelete.push(data.id);
          } else {
            restrictionsUndoDelete.push(data.id);
          }
        }
      }
    });

    if (restrictionsCreate.length > 0 || restrictionsUndoDelete.length > 0 || restrictionsDelete.length > 0
      || restrictionWeeksUpdate.length > 0 || restrictionWeeksDelete.length > 0 || restrictionWeeksAdd.length > 0) {
      try {
        const response = await onUpdateRestrictions({
          restrictionWeeksUpdate: restrictionWeeksUpdate.length > 0 ? restrictionWeeksUpdate : undefined,
          restrictionWeeksDelete: restrictionWeeksDelete.length > 0 ? restrictionWeeksDelete : undefined,
          restrictionWeeksAdd: restrictionWeeksAdd.length > 0 ? restrictionWeeksAdd : undefined,
          restrictionsCreate: restrictionsCreate.length > 0 ? restrictionsCreate : undefined,
          restrictionsDelete: restrictionsDelete.length > 0 ? restrictionsDelete : undefined,
          restrictionsUndoDelete: restrictionsUndoDelete.length > 0 ? restrictionsUndoDelete : undefined
        });
        response && this.handleCancelEdit();
      } catch (e) {
      }
    } else {
      this.handleCancelEdit();
    }
  };

  handleRadiosChange = (opt) => {
    this.setState({ selectedTab: opt.tab.props.name }, this.handleRefreshRestrictionWeekColumns);
  };

  _columns = () => {
    const lsi = this.context;
    const { weekDetailList } = this.props;

    const restrictionColumns = this.restrictionsTableData.restrictionColumns(lsi);
    const restrictionWeekColumns = this.restrictionsTableData.restrictionWeekColumns(weekDetailList, lsi);

    return [...restrictionColumns, ...restrictionWeekColumns];
  };

  handlePostSort = (rowNodes) => {
    const move = (toIndex, fromIndex) => {
      rowNodes.splice(toIndex, 0, rowNodes.splice(fromIndex, 1)[0]);
    };

    let nextInsertPos = 0;
    rowNodes.forEach((rowNode, idx) => {
      if (!rowNode.group) {
        if (RestrictionRowTypeEnum.isModelGroup(rowNode.data.type)) {
          move(nextInsertPos, idx);
          nextInsertPos++;
        }
      }
    });
  };

  handleShowRestrictionDefinitionForm = (modelGroup) => {
    this.setState({ restrictionDefinitionForm: { shown: true, modelGroup: modelGroup } });
  };

  handleCloseRestrictionDefinitionForm = () => {
    this.setState({ restrictionDefinitionForm: { shown: false, modelGroup: null } });
  };

  handleSaveRestrictionDefinition = (restrictionDefinition) => {
    const { weeks } = this.state;
    const row = RestrictionRow.constructNewRow(restrictionDefinition, weeks);

    this.refTable.api.applyTransaction({ add: [row] });

    this.handleCloseRestrictionDefinitionForm();
  };

  render() {
    const lsi = this.context;
    const { editable, selectedTab, restrictionRows, restrictionDefinitionForm } = this.state;

    return (
      <Bricks.Div className='RestrictionsTable'>
        <RestrictionsButtonBar
          editable={editable}
          onCancelEdit={this.handleCancelEditAndDiscardChanges}
          onEnableEdit={this.handleEnableEdit}
          onSave={this.handleCheckDeletedDataBeforeSave}
        />
        <Tabs
          activeName={selectedTab}
          className='RestrictionsTable-tabs'
          onChange={this.handleRadiosChange}
        >
          <Tabs.Item
            header={<Bricks.Span content={lsi.getLSIItem('PVT.BUTTON.NEW_PLAN')} tooltip={lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_TAB_NEW_PLAN')} />}
            name={RestrictionsTabEnum.NEW_PLAN}
          />
          <Tabs.Item
            header={<Bricks.Span content={lsi.getLSIItem('PVT.BUTTON.ACTUAL_PLAN')} tooltip={lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_TAB_ACTUAL_PLAN')} />}
            name={RestrictionsTabEnum.ACTUAL_PLAN}
          />
          <Tabs.Item
            header={<Bricks.Span content={lsi.getLSIItem('PVT.BUTTON.USED')} tooltip={lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_TAB_USED')} />}
            name={RestrictionsTabEnum.USED}
          />
          <Tabs.Item
            header={<Bricks.Span content={lsi.getLSIItem('PVT.BUTTON.FREE_CAPACITY')} tooltip={lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_TAB_FREE_CAPACITY')} />}
            name={RestrictionsTabEnum.DIFFERENCE}
          />
        </Tabs>
        <OvexAGTable
          agContext={{
            editable,
            selectedTab,
            onShowAddForm: this.handleShowRestrictionDefinitionForm
          }}
          columnDefs={this._columns()}
          columnTypes={RestrictionsTableData.columnTypes}
          enableFillHandle
          enableRangeSelection
          getContextMenuItems={RestrictionsTableData.getContextMenuItems}
          getRowHeight={RestrictionsTableData.getRowHeight}
          groupDefaultExpanded={1}
          groupDisplayType={RowGroupingDisplayType.GROUP_ROWS}
          groupHideOpenParents
          height='540px'
          onGridReady={this.handleRefTable}
          postSort={this.handlePostSort}
          rowClassRules={RestrictionsTableData.rowClassRules}
          rowData={restrictionRows}
          rowDataChangeDetectionStrategy='IdentityCheck'
        />
        {restrictionDefinitionForm.shown &&
        <RestrictionDefinitionCreationForm
          modelGroup={restrictionDefinitionForm.modelGroup}
          onClose={this.handleCloseRestrictionDefinitionForm}
          onSave={this.handleSaveRestrictionDefinition}
          shown={restrictionDefinitionForm.shown}
        />
        }
        <ConfirmModal
          content={lsi.getLSIItem('PVT.CONFIRM_MESSAGE.RESTRICTION_DELETE')}
          onConfirm={this.handleUpdateRestrictions}
          ref_={this.confirmModalRef}
        />
      </Bricks.Div>
    );
  }
}

export default RestrictionsTable;
