import React from 'react';

import NavMenu from '../../../common/components/nav/NavMenu/NavMenu';
import { LsiContext, ModuleContext } from '../../../common/contexts';
import { modulePathPrefix } from '../../config/moduleConfig';
import moduleContextData from '../../config/moduleContextData';
import {
  GetAnnualTargetYearsFunctionalityContext,
  GetDealerContractsATModelGroupViewListFunctionalityContext,
  GetDealerContractsATSPeriodsViewListFunctionalityContext,
  GetDealerContractsFunctionalityContext,
  GetDealerContractsListFunctionalityContext
} from '../../privilege/context';
import modulePaths from '../../routes/modulePaths';

const propTypes = {};
const defaultProps = {};

const getDealerContractsATModelGroupViewListFunctionalityContext = new GetDealerContractsATModelGroupViewListFunctionalityContext();
const getDealerContractsATSPeriodsViewListFunctionalityContext = new GetDealerContractsATSPeriodsViewListFunctionalityContext();
const getDealerContractsFunctionalityContext = new GetDealerContractsFunctionalityContext();
const getDealerContractsListFunctionalityContext = new GetDealerContractsListFunctionalityContext();
const getAnnualTargetYearsFunctionalityContext = new GetAnnualTargetYearsFunctionalityContext();

const AnnualTargetNavMenu = () => {
  const lsi = React.useContext(LsiContext);

  const navMenuItems = React.useMemo(
    () => ([
      {
        label: lsi.getLSIItem('ANNUAL_TARGET.NAV_MENU.ITEM_ANNUAL_TARGET_IMPORTER'),
        privilegeFunctionalityContext: getDealerContractsListFunctionalityContext,
        route: modulePaths.ROUTE_PATH_ANNUAL_TARGET_ANNUAL_TARGET
      },
      {
        label: lsi.getLSIItem('ANNUAL_TARGET.NAV_MENU.ITEM_ANNUAL_TARGET'),
        privilegeFunctionalityContext: getDealerContractsFunctionalityContext,
        route: modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_ANNUAL_TARGET
      },
      {
        label: lsi.getLSIItem('ANNUAL_TARGET.NAV_MENU.ITEM_NEGOTIATIONS'),
        privilegeFunctionalityContext: getAnnualTargetYearsFunctionalityContext,
        route: modulePaths.ROUTE_PATH_ANNUAL_TARGET_NEGOTIATIONS
      },
      {
        label: lsi.getLSIItem('ANNUAL_TARGET.NAV_MENU.ITEM_ANNUAL_TARGET_IMPORTER_VIEW_AT_MODEL_GROUPS'),
        privilegeFunctionalityContext: getDealerContractsATModelGroupViewListFunctionalityContext,
        route: modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACTS_VIEW_AT_MODEL_GROUPS
      },
      {
        label: lsi.getLSIItem('ANNUAL_TARGET.NAV_MENU.ITEM_ANNUAL_TARGET_IMPORTER_VIEW_ATS_PERIODS'),
        privilegeFunctionalityContext: getDealerContractsATSPeriodsViewListFunctionalityContext,
        route: modulePaths.ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACTS_VIEW_ATS_PERIODS
      }
    ]),
    [lsi]
  );

  return (
    <ModuleContext.Provider value={moduleContextData} >
      <NavMenu
        menuLabel={lsi.getLSIItem('ANNUAL_TARGET.NAV_MENU.LABEL')}
        navMenuItems={navMenuItems}
        pathPrefix={modulePathPrefix}
      />
    </ModuleContext.Provider>
  );
};

AnnualTargetNavMenu.propTypes = propTypes;
AnnualTargetNavMenu.defaultProps = defaultProps;

export default AnnualTargetNavMenu;