import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class CanceledCommissionRUID extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.CANCELED_COMMISSION__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.CANCELED_COMMISSION');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    return null;
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    return null;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.ACTIVE');
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { commission } = context;

    return commission.commissionCurrentData.operationTypeCanceled;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;

    if (value) {
      return agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.CANCELED');
    }
    return agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.ACTIVE');
  }
}

const canceledCommissionRUID = new CanceledCommissionRUID();

export default canceledCommissionRUID;