import { PGTypeCheckClientList, PGTypeNoCheckClientList } from './const';

export const checkClientByPGType = (priceGuaranteeType) => {
  if (PGTypeNoCheckClientList.includes(priceGuaranteeType)) {
    return false;
  } else if (PGTypeCheckClientList.includes(priceGuaranteeType)) {
    return true;
  }
  return null;
};
