import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class RevertChangesFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.SUPP_USER_CREDENTIALS__REVERT_USER_CHANGES);
  }

}

export default RevertChangesFunctionalityContext;