import { currentYear } from '../../../config/config';

export const InputNames = {
  yearList: 'yearList',
  negotiationId: 'negotiationId',
  modelGroupList: 'modelGroupList',
  atPeriodOrderList: 'atPeriodOrderList'
};

export const SelectOptionMapKeys = {
  yearList: 'yearList',
  negotiationList: 'negotiationList',
  modelGroupList: 'modelGroupList',
  atPeriodOrderList: 'atPeriodOrderList'
};

export const valuesDefault = {
  [InputNames.yearList]: [currentYear.toString()],
  [InputNames.negotiationId]: null,
  [InputNames.modelGroupList]: [],
  [InputNames.atPeriodOrderList]: []
};

export const filterContentValueGetterMap = {
  [SelectOptionMapKeys.yearList]: (filterContent) => filterContent.year.toString(),
  [SelectOptionMapKeys.negotiationList]: (filterContent) => filterContent.negotiationId.toString(),
  [SelectOptionMapKeys.modelGroupList]: (filterContent) => filterContent.modelGroupCode,
  [SelectOptionMapKeys.atPeriodOrderList]: (filterContent) => filterContent.periodOrder.toString()
};

export const filterContentMap = {
  [SelectOptionMapKeys.yearList]: (filterContent, filterValues) => {
    const fc = _getFilterContentValue(filterContent);
    const fv = _getFilterValue(filterValues);

    return _evaluateNegotiationId(fc, fv)
      && _evaluateModelGroupList(fc, fv)
      && _evaluateATPeriodOrderList(fc, fv);
  },
  [SelectOptionMapKeys.negotiationList]: (filterContent, filterValues) => {
    const fc = _getFilterContentValue(filterContent);
    const fv = _getFilterValue(filterValues);

    return _evaluateYearList(fc, fv)
      && _evaluateModelGroupList(fc, fv)
      && _evaluateATPeriodOrderList(fc, fv);
  },
  [SelectOptionMapKeys.modelGroupList]: (filterContent, filterValues) => {
    const fc = _getFilterContentValue(filterContent);
    const fv = _getFilterValue(filterValues);

    return _evaluateYearList(fc, fv)
      && _evaluateNegotiationId(fc, fv)
      && _evaluateATPeriodOrderList(fc, fv);
  },
  [SelectOptionMapKeys.atPeriodOrderList]: (filterContent, filterValues) => {
    const fc = _getFilterContentValue(filterContent);
    const fv = _getFilterValue(filterValues);

    return _evaluateYearList(fc, fv)
      && _evaluateNegotiationId(fc, fv)
      && _evaluateModelGroupList(fc, fv);
  }
};

const _getFilterContentValue = (filterContent) => ({
  year: filterContentValueGetterMap[SelectOptionMapKeys.yearList](filterContent),
  negotiationId: filterContentValueGetterMap[SelectOptionMapKeys.negotiationList](filterContent),
  modelGroupCode: filterContentValueGetterMap[SelectOptionMapKeys.modelGroupList](filterContent),
  periodOrder: filterContentValueGetterMap[SelectOptionMapKeys.atPeriodOrderList](filterContent)
});

const _getFilterValue = (filterValues) => ({
  yearList: filterValues[InputNames.yearList],
  negotiationId: filterValues[InputNames.negotiationId],
  modelGroupList: filterValues[InputNames.modelGroupList],
  periodOrder: filterValues[InputNames.atPeriodOrderList]
});

const _evaluateYearList = (fc, fv) => {
  return Array.isArray(fv.yearList)
    ? fv.yearList.includes(fc.year)
    : true;
};

const _evaluateNegotiationId = (fc, fv) => {
  return fv.negotiationId != null
    ? fv.negotiationId === fc.negotiationId
    : true;
};

const _evaluateModelGroupList = (fc, fv) => {
  return Array.isArray(fv.modelGroupList) && fv.modelGroupList.length > 0
    ? fv.modelGroupList.includes(fc.modelGroupCode)
    : true;
};

const _evaluateATPeriodOrderList = (fc, fv) => {
  return Array.isArray(fv.atPeriodOrderList) && fv.atPeriodOrderList.length > 0
    ? fv.atPeriodOrderList.includes(fc.atPeriodOrder)
    : true;
};
