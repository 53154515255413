import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const dealerContractsApi = ApiServiceInstances.annualTarget.dealerContractsApi;

export const GET_DEALER_CONTRACTS_REQUEST = 'GET_DEALER_CONTRACTS_REQUEST';
export const GET_DEALER_CONTRACTS_SUCCESS = 'GET_DEALER_CONTRACTS_SUCCESS';
export const GET_DEALER_CONTRACTS_FAILURE = 'GET_DEALER_CONTRACTS_FAILURE';

const initialState = {
  dealerContractList: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEALER_CONTRACTS_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_DEALER_CONTRACTS_SUCCESS: {
      return { ...state, isFetching: false, dealerContractList: action.payload };
    }
    case GET_DEALER_CONTRACTS_FAILURE: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetDealerContract = () => {
  return {
    [CALL_API]: {
      apiFunction: dealerContractsApi.getContractListForDealerWithHttpInfo.bind(dealerContractsApi),
      types: [GET_DEALER_CONTRACTS_REQUEST, GET_DEALER_CONTRACTS_SUCCESS, GET_DEALER_CONTRACTS_FAILURE]
    }
  };
};
