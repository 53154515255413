import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';

const propTypes = {
  ref_: PropTypes.func.isRequired
};

const defaultProps = {
};

const AlertBus = React.memo((props) => {

  return (
    <Bricks.AlertBus
      closeTimer={6000}
      offsetTop='76px'
      ref_={props.ref_}
      stacked
    />
  );
});

AlertBus.propTypes = propTypes;
AlertBus.defaultProps = defaultProps;

export default AlertBus;