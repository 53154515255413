import React from 'react';
import PropTypes from 'prop-types';
import { Events } from '@ag-grid-enterprise/all-modules';
import Icon from '@mdi/react';

import './CustomHeaderLabel.scss';

/**
 * Additional props for AG-Grid header component
 * @see https://www.ag-grid.com/react-grid/component-header/#header-component-interface
 */
const propTypes = {
  className: PropTypes.string,
  hiddenText: PropTypes.bool,
  iconColor: PropTypes.string,
  iconPath: PropTypes.string
};

const defaultProps = {
  className: null,
  hiddenText: false,
  iconColor: null,
  iconPath: null
};

/**
 * AG-Grid Header Component
 * @see https://www.ag-grid.com/react-grid/component-header/
 */
const CustomHeaderLabel = React.forwardRef((props, ref) => {
  /**
   * Documentation for styling cell renderer component (agReactContainer)
   * @see https://www.ag-grid.com/documentation/react/component-styling/
   */
  React.useImperativeHandle(
    ref,
    () => ({
      getReactContainerStyle: () => ({ height: '100%', width: '100%' })
    })
  );

  const classNames = ['ag-cell-label-container ovex-ag-CustomHeaderLabel'];
  props.className && classNames.push(props.className);

  const eMenu = React.useRef();

  const handleClickMenu = () => {
    props.showColumnMenu(eMenu.current);
  };
  const renderMenu = () => {
    return !props.enableMenu ? null : (
      <span
        aria-hidden='true'
        className='ag-header-icon ag-header-cell-menu-button ag-header-menu-always-show'
        onClick={handleClickMenu}
        ref={eMenu}
      >
        <span
          className='ag-icon ag-icon-menu'
          role='presentation'
          unselectable='on'
        />
      </span>
    );
  };

  const renderLabelIconText = () => {
    return !props.iconPath ? null : (
      <Icon
        className='ovex-ag-CustomHeaderLabel-icon'
        color={props.iconColor || 'rgba(0, 0, 0, 0.54)'}
        path={props.iconPath}
        size={0.8}
      />
    );
  };

  const [ filterActive, setFilterActive ] = React.useState(false);

  const column = props.column;
  React.useEffect(
    () => {
      const columnFilterChangedListener = () => setFilterActive(column.isFilterActive());

      column.addEventListener(Events.EVENT_FILTER_CHANGED, columnFilterChangedListener);
      return () => column.removeEventListener(Events.EVENT_FILTER_CHANGED, columnFilterChangedListener);
    },
    [column]
  );

  const handleClickSort = (event) => {
    props.progressSort(event.shiftKey);
  };

  const [ sort, setSort ] = React.useState(SortEnum.NO_SORT);
  const [ sortIndex, setSortIndex ] = React.useState(undefined);
  const [ sortMulti, setSortMulti ] = React.useState(false);

  React.useEffect(
    () => {
      const columnSortChangedListener = () => {
        const s = column.getSort();
        if (s === SortEnum.ASC) {
          setSort(SortEnum.ASC);
        } else if (s === SortEnum.DESC) {
          setSort(SortEnum.DESC);
        } else {
          setSort(SortEnum.NO_SORT);
        }
      };

      column.addEventListener(Events.EVENT_SORT_CHANGED, columnSortChangedListener);
      return () => column.removeEventListener(Events.EVENT_SORT_CHANGED, columnSortChangedListener);
    },
    [column]
  );

  const api = props.api;
  React.useEffect(
    () => {
      const gridSortChangedListener = () => {
        const sortedColumns = api.columnModel.getAllDisplayedColumns().filter(c => [SortEnum.ASC, SortEnum.DESC].includes(c.sort));

        setSortIndex(column.getSortIndex());
        setSortMulti(sortedColumns.length > 1);
      };

      api.addEventListener(Events.EVENT_SORT_CHANGED, gridSortChangedListener);
      return () => api.removeEventListener(Events.EVENT_SORT_CHANGED, gridSortChangedListener);
    },
    [api, column]
  );

  const renderSort = () => {
    if (!props.enableSorting) {
      return null;
    }
    const sortOrder = sortIndex != null ? sortIndex + 1 : sortIndex;
    return (
      <React.Fragment>
        <span
          // ref='eSortOrder'
          aria-hidden='true'
          className={`'ag-header-icon ag-header-label-icon ag-sort-order ${sortMulti && sort !== SortEnum.NO_SORT ? '' : 'ag-hidden'}`}
        >
          {sortOrder}
        </span>
        <span
          // ref='eSortAsc'
          aria-hidden='true'
          className={`ag-header-icon ag-header-label-icon ag-sort-ascending-icon ${sort === SortEnum.ASC ? '' : 'ag-hidden'}`}
        >
          <span className='ag-icon ag-icon-asc' role='presentation' unselectable='on' />
        </span>
        <span
          // ref='eSortDesc'
          aria-hidden='true'
          className={`'ag-header-icon ag-header-label-icon ag-sort-descending-icon ${sort === SortEnum.DESC ? '' : 'ag-hidden'}`}
        >
          <span className='ag-icon ag-icon-desc' role='presentation' unselectable='on' />
        </span>
        <span
          // ref='eSortNone'
          aria-hidden='true'
          className='ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden'
        >
          <span className='ag-icon ag-icon-none' role='presentation' unselectable='on' />
        </span>
      </React.Fragment>
    );
  };

  if (sort === SortEnum.ASC) {
    classNames.push('ag-header-cell-sorted-asc');
  } else if (sort === SortEnum.DESC) {
    classNames.push('ag-header-cell-sorted-desc');
  } else {
    classNames.push('ag-header-cell-sorted-none');
  }

  return (
    <div
      className={classNames.join(' ')}
    >
      {renderMenu()}
      <div
        // ref='eLabel'
        className='ag-header-cell-label'
        onClick={handleClickSort}
        role='presentation'
        unselectable='on'
      >
        {renderLabelIconText()}
        <span
          // ref='eText'
          className={`ag-header-cell-text ${props.displayName && !props.hiddenText ? '' : 'ag-hidden'}`}
          unselectable='on'
        >
          {props.displayName}
        </span>
        <span
          // ref='eFilter'
          aria-hidden='true'
          className={`ag-header-icon ag-header-label-icon ag-filter-icon ${filterActive ? '' : 'ag-hidden'}`}
        >
          <span
            className='ag-icon ag-icon-filter'
            role='presentation'
            unselectable='on'
          />
        </span>
        {renderSort()}
      </div>
    </div>
  );
});

const SortEnum = {
  ASC: 'asc',
  DESC: 'desc',
  NO_SORT: 'noSort'
};

CustomHeaderLabel.propTypes = propTypes;
CustomHeaderLabel.defaultProps = defaultProps;

export default CustomHeaderLabel;
