import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import { ContractDetailDTO } from '@ovex/annual-target-web-api';

import { OvexAGTable } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';
import { COLUMN_IDS } from '../utils/tableBaseColumnDefs';
import DealerModelGroupModalError from '../DealerModelGroupModalError/DealerModelGroupModalError';

import DealerModelGroupAnnualTargetRow from './DealerModelGroupAnnualTargetRow';
import {
  columnTypes,
  getAutoGroupColumnDef,
  getColumnDefinitions
} from './DealerModelGroupAnnualTargetTable.columnDefs';
import { handleGetContextMenuItems } from './DealerModelGroupAnnualTargetTable.contextMenu';

import './DealerModelGroupAnnualTargetTable.scss';

const propTypes = {
  className: PropTypes.string,
  contract: PropTypes.instanceOf(ContractDetailDTO),
  editableApprovalNumberColumn: PropTypes.bool,
  editableDealerNumberColumn: PropTypes.bool,
  gridOptions: PropTypes.object,
  modelGroupAnnualTargetList: PropTypes.arrayOf(PropTypes.instanceOf(DealerModelGroupAnnualTargetRow)),
  onShowCommissionListWithParams: PropTypes.func
};

const defaultProps = {
  className: null,
  contract: null,
  editableApprovalNumberColumn: false,
  editableDealerNumberColumn: false,
  gridOptions: null,
  modelGroupAnnualTargetList: [],
  onShowCommissionListWithParams: undefined
};

const DealerModelGroupAnnualTargetTable = React.memo(React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);
  const tableRef = React.useRef(null);

  React.useImperativeHandle(
    ref,
    () => ({
      discardChanges: () => {
        if (!tableRef.current) {
          return;
        }
        const modifiedData = [];

        tableRef.current.api.forEachLeafNode(rowNode => rowNode.data.isModified() && modifiedData.push(rowNode.data));

        if (modifiedData.length > 0) {
          modifiedData.forEach(data => data.discardChanges());
          tableRef.current.api.applyTransaction({
            update: modifiedData
          });
        }
      }
    })
  );

  React.useEffect(
    () => {
      tableRef.current && tableRef.current.api.refreshCells({ columns: [ COLUMN_IDS.AT_ATS_APPROVAL_NUMBER ], force: true });
    },
    [props.editableApprovalNumberColumn]
  );

  React.useEffect(
    () => {
      tableRef.current && tableRef.current.api.refreshCells({ columns: [ COLUMN_IDS.AT_ATS_DEALER_NUMBER ], force: true });
    },
    [props.editableDealerNumberColumn]
  );

  const classNames = ['ovex-DealerModelGroupAnnualTargetTable'];
  props.className && classNames.push(props.className);

  const columnDefs = React.useMemo(
    () => getColumnDefinitions(lsi),
    [lsi]
  );

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      {props.modalProps.errorList && <DealerModelGroupModalError modalProps={props.modalProps}/>}
      <OvexAGTable
        agContext={{
          editableApprovalNumberColumn: props.editableApprovalNumberColumn,
          editableDealerNumberColumn: props.editableDealerNumberColumn,
          onShowCommissionListWithParams: props.onShowCommissionListWithParams,
          statisticsRequestId: props.contract?.statisticsRequestId,
          statisticsType: props.contract?.statisticsType,
          negotiationType: props.contract?.negotiation.type
        }}
        alwaysShowVerticalScroll
        animateRows
        autoGroupColumnDef={getAutoGroupColumnDef(lsi)}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        domLayout='autoHeight'
        enableRangeSelection
        getContextMenuItems={handleGetContextMenuItems}
        gridOptions={props.gridOptions}
        height='200px'
        highlightLeafOddEvenRows
        ref={tableRef}
        rowData={props.modelGroupAnnualTargetList}
        rowDataChangeDetectionStrategy='IdentityCheck'
        suppressMovableColumns
      />
    </Bricks.Div>
  );
}));

DealerModelGroupAnnualTargetTable.propTypes = propTypes;
DealerModelGroupAnnualTargetTable.defaultProps = defaultProps;

export default DealerModelGroupAnnualTargetTable;
