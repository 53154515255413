
const RowUIDefinitionCodes = {
  DEALER__CZ__ROW: 'dealer_chRow_v1',
  ORDER_TYPE_PREVIOUS__CZ__ROW: 'orderTypePrevious_CZ_chRow_v1',
  ORDER_TYPE_PREVIOUS__SK__ROW: 'orderTypePrevious_SK_chRow_v1',
  ORDER_TYPE_CURRENT__CZ__ROW: 'orderTypeCurrent_CZ_chRow_v1',
  ORDER_TYPE_CURRENT__SK__ROW: 'orderTypeCurrent_SK_chRow_v1',
  CLIENT__CZ__ROW: 'client_CZ_chRow_v1',
  CLIENT__SK__ROW: 'client_SK_chRow_v1',
  CREATION_DATE__ROW: 'creationDate_chRow_v1',
  PRODUCE_WEEK_FIRST__ROW: 'produceWeekFirst_chRow_v1',
  INVOICING_DATE__ROW: 'invoicingDate_chRow_v1',
  SELLING_DATE__ROW: 'sellingDate_chRow_v1',
  CLAIM_SEND_BY_STATUS__ROW: 'claimSendByStatus_chRow_v1',
  CLAIM_SEND_BY_DEALER__ROW: 'claimSendByDealer_chRow_v1',
  CANCELED_COMMISSION__ROW: 'canceledCommission_chRow_v1'
};

Object.freeze(RowUIDefinitionCodes);

export default RowUIDefinitionCodes;