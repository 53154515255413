import { modulePathPrefix } from '../config/moduleConfig';

const modulePaths = {
  ROUTE_PATH_ANNUAL_TARGET_COMMISSION_LIST_DEALER: modulePathPrefix + '/dealer/commissions/:commissionsParamsUuid',
  ROUTE_PATH_ANNUAL_TARGET_COMMISSION_LIST_IMPORTER: modulePathPrefix + '/importer/commissions/:commissionsParamsUuid',
  ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACT_PREVIEW: modulePathPrefix + '/dealer/contract-preview/:contractId',
  ROUTE_PATH_ANNUAL_TARGET_DEALER_ANNUAL_TARGET: modulePathPrefix + '/dealer-annual-target',
  ROUTE_PATH_ANNUAL_TARGET_ANNUAL_TARGET: modulePathPrefix + '/annual-target',
  ROUTE_PATH_ANNUAL_TARGET_NEGOTIATIONS: modulePathPrefix + '/negotiations',
  ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACTS_VIEW_AT_MODEL_GROUPS: modulePathPrefix + '/view/at-model-groups',
  ROUTE_PATH_ANNUAL_TARGET_DEALER_CONTRACTS_VIEW_ATS_PERIODS: modulePathPrefix + '/view/ats-periods'
};

export default modulePaths;
