import React from 'react';
import PropTypes from 'prop-types';
import { ChangeDetectionStrategyType } from '@ag-grid-community/react';
import { PriceGuaranteeDTOPUB, PriceGuaranteeItemDTOPUB } from '@ovex/price-guarantee-web-api';

import { CustomHeaderLabel, OvexAGTable, RowGroupingDisplayType } from '../../../../common/components/ag-grid';
import { LsiContext } from '../../../../common/contexts';

import ModelGroupLabelRowInnerRenderer from './ModelGroupLabelRowInnerRenderer/ModelGroupLabelRowInnerRenderer';
import { getColumnDefinitions, columnTypes } from './PriceGuaranteeItemPublishedTable.columnDefs';
import { handleGetContextMenuItems } from './PriceGuaranteeItemPublishedTable.contextMenu';
import { getSpecificationKey } from './PriceGuaranteeItemPublishedTable.helpers';

import './PriceGuaranteeItemPublishedTable.scss';

const propTypes = {
  priceGuarantee: PropTypes.instanceOf(PriceGuaranteeDTOPUB),
  priceGuaranteeItemList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeItemDTOPUB))
};

const defaultProps = {
  priceGuarantee: null,
  priceGuaranteeItemList: null
};

const PriceGuaranteeItemPublishedTable = (props) => {
  const lsi = React.useContext(LsiContext);

  const { priceGuarantee, priceGuaranteeItemList } = props;
  const priceGuaranteeItemRowList = React.useMemo(
    () => priceGuaranteeItemList?.map(pgi => {
      pgi.specificationMap = pgi.specificationList.reduce(
        (map, specification) => {
          const specificationKey = getSpecificationKey(specification.snapshot, specification.type);
          map[specificationKey] = specification.value;
          return map;
        },
        {}
      );
      return pgi;
    }),
    [priceGuaranteeItemList]
  );

  const modelGroupMapRef = React.useRef({});
  modelGroupMapRef.current = getModelGroupMap(priceGuarantee?.modelGroupList);

  const handleDefaultGroupOrderComparator = (nodeA, nodeB) => {
    const orderA = modelGroupMapRef.current[nodeA.key]?.sortOrder;
    const orderB = modelGroupMapRef.current[nodeB.key]?.sortOrder;
    if (orderA < orderB) {
      return -1;
    } else if (orderA > orderB) {
      return 1;
    }
    return 0;
  };

  return (
    <OvexAGTable
      className='ovex-pg-PriceGuaranteeItemPublishedTable'
      columnDefs={getColumnDefinitions(lsi, priceGuarantee?.currency)}
      columnTypes={columnTypes}
      defaultGroupOrderComparator={handleDefaultGroupOrderComparator}
      enableRangeSelection
      frameworkComponents={{
        customHeaderLabel: CustomHeaderLabel,
        modelGroupLabelRowInnerRenderer: ModelGroupLabelRowInnerRenderer
      }}
      getContextMenuItems={handleGetContextMenuItems}
      groupDisplayType={RowGroupingDisplayType.GROUP_ROWS}
      groupRowRendererParams={{
        innerRenderer: 'modelGroupLabelRowInnerRenderer',
        modelGroupMap: modelGroupMapRef.current
      }}
      height='560px'
      rowData={priceGuaranteeItemRowList}
      rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
    />
  );
};

const getModelGroupMap = (modelGroupList) => {
  const mgEntries = modelGroupList?.map(mg => [mg.code, mg]);
  return mgEntries ? Object.fromEntries(mgEntries) : {};
};

PriceGuaranteeItemPublishedTable.propTypes = propTypes;
PriceGuaranteeItemPublishedTable.defaultProps = defaultProps;

export default PriceGuaranteeItemPublishedTable;
