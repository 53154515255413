import moment from 'moment';

const SAWeekUtils = {

  /**
   * Format for moment.js for tranform SA week to moment date.
   */
  MOMENT_WEEK_FORMAT: 'GGGG[W]WW',
  /**
   * Input format for UU5.Forms.DatePicker.
   * Y = year (2020)
   * W = our separator
   * ww = week of year (01-53)
   */
  UU5_DATE_PICKER_WEEK_FORMAT: 'YWww',
  /**
   * Base regular expresion of SA week
   */
  WEEK_REG_EXP: /^[0-9]{4}[W]([0][1-9]|[1-4][0-9]|[5][0-3])$/,
  /**
   * @param {String} week
   * @return {boolean}
   */
  isSAWeek: (week) => {
    return SAWeekUtils.WEEK_REG_EXP.test(week);
  },
  /**
   * @param {String} week
   * @param {String} weekFrom
   * @param {String} weekTo
   * @return {boolean}
   */
  isBetween: (week, weekFrom, weekTo) => {
    const momentWeek = moment(week, SAWeekUtils.MOMENT_WEEK_FORMAT);

    if (!momentWeek.isValid()) {
      return false;
    }

    const momentWeekFrom = moment(weekFrom, SAWeekUtils.MOMENT_WEEK_FORMAT);
    const momentWeekTo = moment(weekTo, SAWeekUtils.MOMENT_WEEK_FORMAT);

    if (momentWeekFrom.isValid() && momentWeekTo.isValid()) {
      return momentWeek.isBetween(momentWeekFrom, momentWeekTo, null, '[]');
    }
    if (momentWeekFrom.isValid() && weekTo == null) {
      return momentWeek.isSameOrAfter(momentWeekFrom);
    }
    if (weekFrom == null && momentWeekTo.isValid()) {
      return momentWeek.isSameOrBefore(momentWeekTo);
    }

    return weekFrom == null && weekTo == null;
  },
  /**
   * @param {String} week
   * @param {String} weekAfter
   * @return {boolean}
   */
  isAfter: (week, weekAfter) => {
    const momentWeek = moment(week, SAWeekUtils.MOMENT_WEEK_FORMAT);

    if (!momentWeek.isValid()) {
      return false;
    }

    const momentWeekAfter = moment(weekAfter, SAWeekUtils.MOMENT_WEEK_FORMAT);

    if (momentWeekAfter.isValid()) {
      return momentWeekAfter.isAfter(momentWeek);
    }

    return false;
  },
  /**
   * @param {String} week
   * @param {String} weekBefore
   * @return {boolean}
   */
  isBefore: (week, weekBefore) => {
    const momentWeek = moment(week, SAWeekUtils.MOMENT_WEEK_FORMAT);

    if (!momentWeek.isValid()) {
      return false;
    }

    const momentWeekBefore = moment(weekBefore, SAWeekUtils.MOMENT_WEEK_FORMAT);

    if (momentWeekBefore.isValid()) {
      return momentWeekBefore.isBefore(momentWeek);
    }

    return false;
  },
  /**
   * @param {Date|moment.Moment} date
   * @return {moment.Moment}
   */
  startOfWeekFromDate: (date) => {
    const monday = moment(date).isoWeekday(1);
    return (monday != null && monday.isValid()) ? monday : null;
  },
  /**
   * @param {Date|moment.Moment} date
   * @return {moment.Moment}
   */
  endOfWeekFromDate: (date) => {
    const sunday = moment(date).isoWeekday(7);
    return (sunday != null && sunday.isValid()) ? sunday : null;
  },
  /**
   * @param {Date} date
   * @return {String}
   */
  convertDateToWeek: (date) => {
    const startDate = SAWeekUtils.startOfWeekFromDate(date);
    return (startDate != null && startDate.isValid()) ? startDate.format(SAWeekUtils.MOMENT_WEEK_FORMAT) : null;
  },
  /**
   * @param {String} week
   * @return {moment.Moment}
   */
  parseDateFromWeek: (week) => {
    if (SAWeekUtils.isSAWeek(week)) {
      const momentDate = moment(week, SAWeekUtils.MOMENT_WEEK_FORMAT);
      if (momentDate.isValid()) {
        return momentDate;
      }
    }
    return null;
  }
};

export default SAWeekUtils;
