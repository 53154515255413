import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';
import CommissionValidatorPageStatus from '../../../layouts/CommissionValidator/CommissionValidatorPageStatus';

const commissionValidatorApi = ApiServiceInstances.priceGuarantee.commissionValidatorApi;

export const GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_REQUEST = 'GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_REQUEST';
export const GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_SUCCESS = 'GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_SUCCESS';
export const GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_FAILURE = 'GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_FAILURE';

export const GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_REQUEST = 'GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_REQUEST';
export const GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_SUCCESS = 'GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_SUCCESS';
export const GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_FAILURE = 'GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_FAILURE';

export const RESET_COMMISSION_VALIDATION_RESULT_VLD = 'RESET_COMMISSION_VALIDATION_RESULT_VLD';

const initialState = {
  pageStatus: CommissionValidatorPageStatus.EMPTY,
  error: null,
  validationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_REQUEST:
    case GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_REQUEST: {
      return {
        ...state,
        pageStatus: CommissionValidatorPageStatus.LOADING
      };
    }
    case GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_SUCCESS:
    case GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_SUCCESS: {
      const pageStatus = action.payload != null ? CommissionValidatorPageStatus.DATA_FOUND : CommissionValidatorPageStatus.NOT_FOUND;
      return {
        ...state,
        pageStatus: pageStatus,
        validationResult: action.payload
      };
    }
    case GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_FAILURE:
    case GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        pageStatus: CommissionValidatorPageStatus.ERROR
      };
    }
    case RESET_COMMISSION_VALIDATION_RESULT_VLD:
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetCommissionValidationResultDealerVLD = (priceGuaranteeId, commissionNumber, order) => {
  return {
    [CALL_API]: {
      apiFunction: () => commissionValidatorApi.getCommissionValidationResultDealerVLDWithHttpInfo(priceGuaranteeId, commissionNumber, { order }),
      types: [GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_REQUEST, GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_SUCCESS, GET_COMMISSION_VALIDATION_RESULT_DEALER_VLD_FAILURE]
    }
  };
};

export const handleGetCommissionValidationResultImporterVLD = (priceGuaranteeId, commissionNumber, order) => {
  return {
    [CALL_API]: {
      apiFunction: () => commissionValidatorApi.getCommissionValidationResultImporterVLDWithHttpInfo(priceGuaranteeId, commissionNumber, { order }),
      types: [GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_REQUEST, GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_SUCCESS, GET_COMMISSION_VALIDATION_RESULT_IMPORTER_VLD_FAILURE]
    }
  };
};

export const handleResetValidationResultVLD = () => {
  return {
    type: RESET_COMMISSION_VALIDATION_RESULT_VLD
  };
};
