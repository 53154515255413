import React from 'react';

import { DEFAULT_LOCALE, LOCALE } from '../../../../utils/consts';

import { getFormatters } from './lsiFormatters';
import LsiObject from './LsiObject';
import LsiVocabulary from './LsiVocabulary';

/**
 * @param {string} locale
 * @return {string}
 */
const getLocale = (locale) => {
  if (locale != null) {
    const lang = locale.substring(0, 2).toLowerCase();
    if (lang === LOCALE.cs) {
      return LOCALE.cs;
    } else if (lang === LOCALE.en) {
      return LOCALE.en;
    } else if (lang === LOCALE.sk) {
      return LOCALE.sk;
    }
  }
  return DEFAULT_LOCALE;
};

/**
 * @param {string} locale
 * @return {LsiObject}
 */
const getLsiContextValue = (locale) => {
  const lsiVocabulary = new LsiVocabulary(getLocale(locale));

  return new LsiObject(
    lsiVocabulary.getLocale,
    lsiVocabulary.getLSIItem,
    getFormatters(lsiVocabulary.getLocale)
  );
};

const LsiContext = React.createContext(null);
LsiContext.getLsiContextValue = getLsiContextValue;

export default LsiContext;