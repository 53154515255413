import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { Checkbox } from '../../../common/components/forms';

import { getQuarterInputName, quarterSorter } from './QuarterComponentForm.utils';

import './QuarterPeriodCheckbox.scss';

const DefaultValues = {
};

const propTypes = {
  controlled: PropTypes.bool,
  correctionMode: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onUpdate: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      correction: PropTypes.bool,
      disabled: PropTypes.bool,
      id: PropTypes.number,
      negotiable: PropTypes.bool,
      unitOrder: PropTypes.number
    })
  )
};

const defaultProps = {
  controlled: false,
  correctionMode: false,
  disabled: false,
  label: null,
  name: null,
  onUpdate: null,
  parent: null,
  readOnly: false,
  required: false,
  tooltip: null,
  value: null
};

const QuarterPeriodCheckbox = (props) => {
  const classNames = ['ovex-form-QuarterPeriodCheckbox'];
  const tooltipClassNames = ['uu5-bricks-icon uu5-forms-label-tooltip mdi mdi-information-outline toolTip-color uu5-forms-input-m'];

  const handleUpdateValue = (val, name, unitOrder) => {
    return props.onUpdate && props.onUpdate({ value: val, name: name, unitOrder: unitOrder });
  };

  return (
    <Bricks.Row
      className={classNames.join(' ')}
    >
      <Bricks.Column
        className='uu5-forms-label chck-label'
        colWidth='xs4'
      >
        {props.label}
        {props.tooltip &&
          <span
            className={tooltipClassNames.join(' ')}
            title={props.tooltip}
          />
        }
      </Bricks.Column>
      {props.value?.sort(quarterSorter).map(val => {
        const inputName = getQuarterInputName(props.name, val.id ? val.id : undefined, val.unitOrder);
        return (
          <Bricks.Column
            colWidth='xs2'
            key={`${val.id ? val.id : val.unitOrder+'Q'}_COLUMN_Checkbox`}
          >
            <Checkbox
              className={'uu5-forms-input uu5-forms-input-m'}
              controlled={props.controlled}
              disabled={(props.correctionMode && val.disabled) || props.disabled}
              inputColWidth='xs1'
              name={inputName}
              onChange={(v) => handleUpdateValue(v, inputName, val.unitOrder)}
              parent={props.parent}
              readOnly={props.readOnly}
              required={props.required}
              value={props.correctionMode ? val.correction : val.negotiable}
            />
          </Bricks.Column>
        );
      })}
    </Bricks.Row>
  );
};

QuarterPeriodCheckbox.DefaultValues = DefaultValues;

QuarterPeriodCheckbox.propTypes = propTypes;
QuarterPeriodCheckbox.defaultProps = defaultProps;

export default QuarterPeriodCheckbox;
