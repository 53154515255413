import { arraysEquals } from './arrays';

/**
 * @param {String} path - Path to required content (separator is dot '.')
 * @param {Object} context
 * @return content from context
 */
export const resolveObjectContent = (path, context = {}) => {
  if (!path) {
    return null;
  }
  let pathFix = path.replace(/\[(\w+)]/g, '.$1'); // convert indexes to properties
  pathFix = pathFix.replace(/^\./, ''); // strip a leading dot
  const keys = pathFix.split('.');

  let result = context;
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    if (result != null && result.hasOwnProperty(key)) {
      result = result[key];
    } else {
      return null;
    }
  }
  return result;
};

/**
 * @param {string} stringNumber
 * @return {null|number}
 */
export const parseNumber = (stringNumber) => {
  if (stringNumber == null || stringNumber === '') {
    return null;
  }
  const num = Number(stringNumber);
  return isNaN(num) ? null : num;
};

/**
 * Fix number value
 * Example: 0.1 + 0.2 = 0.30000000000000004
 *
 * @param {number} number
 * @param {number} digits
 * @return {number}
 */
export const fixNumber = (number, digits) => {
  const f = Math.pow(10, digits);
  return Math.round(number * f) / f;
};

/**
 * @param {string} stringDate - format {@see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format}
 * @return {Date|null}
 */
export const parseDate = (stringDate) => {
  if (stringDate == null) {
    return null;
  }
  const parsedDate = Date.parse(stringDate);
  if (Number.isNaN(parsedDate)) {
    return null;
  }
  return new Date(parsedDate);
};

/**
 * Check if param is string.
 * @param param
 * @return {boolean}
 */
export const isString = (param) => {
  return typeof param === 'string' || param instanceof String;
};

/**
 * Compare for equality a selected property of objects.
 *
 * @param objectA - object A
 * @param objectB - object B
 * @param propName - selected property name
 * @return {boolean} - result
 */
export const equalObjectProperty = (objectA, objectB, propName) => {
  const valueA = objectA?.[propName];
  const valueB = objectB?.[propName];

  if (valueA === valueB || (valueA == null && valueB == null)) {
    return true;
  }
  if (Array.isArray(valueA) && Array.isArray(valueB)) {
    return arraysEquals(valueA, valueB);
  }
  return false;
};

/**
 * Sorting function, that sorts arrays Alphabetically
 * sortAlphabetically(ascending {true/ false}), return sorting function
 * with 2 input params (a, b) => which are params that will be sorted alphabetically
 *
 * @param ascending
 */
export const sortAlphabetically = (ascending) => (a, b) => {
  // equal items sort equally
  if (a === b) {
    return 0;
  }

  // nulls sort after anything else
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }

  // otherwise, if we're ascending, lowest sorts first
  if (ascending) {
    return a < b ? -1 : 1;
  }

  // if descending, highest sorts first
  return a < b ? 1 : -1;
};
