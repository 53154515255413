import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import { resolveCellClass } from '../../ValidationResultPGItemTable.helpers';
import { RowType } from '../../ValidationResultPGItemTable.const';
import { ClaimTypeHeaderEnum, ClaimTypeItemEnum } from '../../../../../../utils/const';
import { isGroupNode, nonGroupNode } from '../../../../../../../common/components/ag-grid';

class ClaimTypeCUID extends AbstractColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.CLAIM_TYPE;
  }
  /** @override */
  buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
    const { visible } = columnUIDefinition;
    const { columnTypeList } = additionalParams;

    return {
      colId: this.getCode(),
      hide: !visible,
      headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.CLAIM_TYPE'),
      cellClass: resolveCellClass(this._evaluateClaimTypeHeaderItemCellClass),
      valueGetter: (valueGetterParams) => {
        const { data } = valueGetterParams;
        if (isGroupNode(valueGetterParams)) {
          const accountPartData = valueGetterParams.node.allLeafChildren[0].data.accountPartData;
          return accountPartData.aggregatedClaim;
        }
        if (nonGroupNode(valueGetterParams) && RowType.isPGItem(data.rowType)) {
          return data.claimTypeItem;
        }
        return null;
      },
      valueFormatter: (valueFormatterParams) => {
        const { data, value, context } = valueFormatterParams;
        if (isGroupNode(valueFormatterParams)) {
          return context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_HEADER.${value}`);
        }
        if (nonGroupNode(valueFormatterParams) && RowType.isPGItem(data.rowType)) {
          return context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_ITEM.${value}`);
        }
        return '';
      },
      filterParams: {
        valueFormatter: ({ value, context }) => {
          return value ? context.agLsi.getLSIItem(`PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_ITEM.${value}`) : '';
        }
      },
      type: [...columnTypeList],
      pinned: 'left',
      width: 120,
      minWidth: 120
    };
  }
  /** @override */
  excelExportValueFormatter = (processCellForExportParams) => {
    return processCellForExportParams.column.colDef.valueFormatter({
      ...processCellForExportParams,
      data: processCellForExportParams.node.data
    });
  }

  _evaluateClaimTypeHeaderItemCellClass = (cellClassParams) => {
    const data = cellClassParams.data;
    if (nonGroupNode(cellClassParams) && RowType.isCommission(data.rowType)) {
      return null;
    }

    const claimType = cellClassParams.value;

    if ([ClaimTypeItemEnum.CONFIRMED, ClaimTypeHeaderEnum.CONFIRMED].includes(claimType)) {
      return 'ovex-pg-ValidationResultPGItemTable-validationResult--sent';
    } else if ([ClaimTypeItemEnum.ELIGIBLE, ClaimTypeHeaderEnum.ELIGIBLE].includes(claimType)) {
      return 'ovex-pg-ValidationResultPGItemTable-validationResult--ok';
    } else if ([ClaimTypeItemEnum.INELIGIBLE, ClaimTypeHeaderEnum.INELIGIBLE].includes(claimType)) {
      return 'ovex-pg-ValidationResultPGItemTable-validationResult--repairable';
    } else if ([ClaimTypeItemEnum.REJECTED, ClaimTypeHeaderEnum.REJECTED].includes(claimType)) {
      return 'ovex-pg-ValidationResultPGItemTable-validationResult--notRepairable';
    } else if ([ClaimTypeItemEnum.ERROR, ClaimTypeHeaderEnum.ERROR].includes(claimType)) {
      return 'ovex-pg-ValidationResultPGItemTable-validationResult--error';
    }
    return null;
  }
}

const claimTypeCUID = new ClaimTypeCUID();

export default claimTypeCUID;