import { isString, parseNumber } from './helpers';
import { decimalPercentValidator, percentValidator } from './validators';

/**
 * @param {String|null} value
 * @return {String|Number|null}
 */
export const percentValue0to100Parser = (value) => {
  // standard decimal number in percent (e.g. 20,52)
  if (value != null && percentValidator(value)) {
    const percent = parseNumber(value.replace(',', '.'));
    return _validateRange(percent) ? percent : null;
  } else if (value != null && decimalPercentValidator(value)) {
    // when used it has been transported as decimal representation for percent value, e.g. 20,52 % = 0.2052
    let percent = isString(value) ? parseNumber(value?.replace(',', '.')) : value;
    percent *= 100;
    return percentValidator(percent.toFixed(2)) && _validateRange(percent) ? percent : null;
  }
  return value;
};

/**
 * @param {number} value number to validate
 * @param {number[]} range Array of 2 numbers
 * @private
 */
const _validateRange = (value, range = [0.00, 100.00]) => {
  return range.length === 2 && value >= range[0] && value <= range[1];
};