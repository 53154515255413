import React from 'react';
import PropTypes from 'prop-types';

import { LsiContext } from '../../../../common/contexts';
import '../UserImitateModal.scss';
import { OvexAGTable } from '../../../../common/components/ag-grid';

import { getColumnDefinitions } from './UserDetailInfo.columDef';
import { getDynamicRows } from './UserDetailInfoHeadertable.rows';

const propTypes = {
  header: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  userInfoCurrent: PropTypes.object
};
const defaultProps = {
  fetching: true,
  onClose: null,
  userInfoCurrent: null
};
const UserDetailInfo = (props) => {
  const lsi = React.useContext(LsiContext);
  const { userInfo } = props;

  const dynamicRows = getDynamicRows(userInfo);

  return (
    <OvexAGTable
      agContext={userInfo}
      columnDefs={getColumnDefinitions(lsi)}
      rowData={dynamicRows}
    />
  );
};

UserDetailInfo.propTypes = propTypes;
UserDetailInfo.defaultProps = defaultProps;

export default UserDetailInfo;