import { WebModule, WebModuleInitializationData, WebModuleNavMenuData, WebModuleUserInfoData } from '../web-module';

import SupportInitialization from './components/SupportInitialization/SupportInitialization';
import { moduleCode, userInfoSelector, initializationStatusSelector } from './config/moduleConfig';
import reduxData from './redux';
import routesData from './routes';
import SupportNavMenu from './components/SupportNavMenu/SupportNavMenu';

const navMenuDataList = [
  new WebModuleNavMenuData(1, SupportNavMenu)
];

const moduleSupport = new WebModule(
  moduleCode,
  new WebModuleInitializationData(SupportInitialization, initializationStatusSelector),
  navMenuDataList,
  reduxData,
  routesData,
  new WebModuleUserInfoData(reduxData.actions.handleGetUserInfo, userInfoSelector)
);

export default moduleSupport;