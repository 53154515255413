import PriceGuaranteeReportPageType from '../PriceGuaranteeReportPageType';

export const COLUMN_IDS = {
  PG_MARKETING_ACTION: 'pg_marketingActionCode',

  // for PGI item values
  PGI_PGID: 'pgi_pgid',
  PGI_CLAIM_TYPE: 'pgi_claimType',
  PGI_CLAIM_AMOUNT: 'pgi_claimAmount',
  PGI_IMPORTER_AMOUNT: 'pgi_importerAmount',
  PGI_HEADQUARTERS_AMOUNT: 'pgi_headquartersAmountAmount',
  PGI_CLAIM_AMOUNT_INVOICED: 'pgi_claimAmountInvoiced',

  // for commission values
  C_COMMISSION_NUMBER: 'c_commissionNumber',
  C_CS_MODEL: 'c_csModel',
  C_STATUS_CURRENT: 'c_statusCurrent',
  C_STATUS_PREVIOUS: 'c_statusPrevious',
  C_CLAIM_TYPE: 'c_claimType',
  C_PRODUCE_WEEK_FIRST: 'c_produceWeekFirst',
  C_PRODUCTION_WEEK: 'c_productionWeek',
  C_PRODUCTION_DATE: 'c_productionDate',
  C_VALUATION_DATE: 'c_valuationDate',
  C_INVOICING_DATE_TO_IMPORTER: 'c_invoicingDateToImporter',
  C_INVOICING_DATE: 'c_invoicingDate',
  C_SELLING_DATE: 'c_sellingDate',
  C_ACTIVATION_DATE: 'c_activationDate',
  C_DEALER_NUMBER: 'c_dealerNumber',
  C_DEALER_AREA: 'c_dealerArea',
  C_CLIENT_VOS: 'c_clientVos',
  C_CLIENT_ID: 'c_clientId',
  C_CLIENT_NAME: 'c_clientName',
  C_MARKETING_ACTION_CODE_OTHER: 'c_marketingActionCodeOther',
  C_CALCULATION_DATE: 'c_calculationDate',

  C_ORDER_MODE: 'co_mode',
  COH_ACCOUNT_TYPE: 'coh_accountType',
  COH_PART_STATUS: 'coh_partStatus',
  C_INVOICE_NUMBER: 'c_invoiceNumber',

  // mix of values in rows from the Commission or PG item
  CPGI_CLAIM_TYPE: 'c_pgi_claimType'
};

export const availableValuesForRowTypeWithItemColumnIdList = [
  COLUMN_IDS.PGI_PGID,
  COLUMN_IDS.PGI_CLAIM_TYPE,
  COLUMN_IDS.PGI_CLAIM_AMOUNT,
  COLUMN_IDS.PGI_IMPORTER_AMOUNT,
  COLUMN_IDS.PGI_HEADQUARTERS_AMOUNT,
  COLUMN_IDS.PGI_CLAIM_AMOUNT_INVOICED
];

export const getColumnsToExport = (pageType) => {
  if (PriceGuaranteeReportPageType.isImporter(pageType)) {
    return COLUMNS_TO_EXPORT.filter(colId => !IMPORTER_EXCLUDED_COLUMNS_TO_EXPORT.includes(colId));
  }
  return COLUMNS_TO_EXPORT;
};

const COLUMNS_TO_EXPORT = [
  COLUMN_IDS.PG_MARKETING_ACTION,
  COLUMN_IDS.C_COMMISSION_NUMBER,
  COLUMN_IDS.COH_ACCOUNT_TYPE,
  COLUMN_IDS.C_CS_MODEL,
  COLUMN_IDS.C_STATUS_CURRENT,
  COLUMN_IDS.C_STATUS_PREVIOUS,
  COLUMN_IDS.PGI_PGID,
  COLUMN_IDS.C_ORDER_MODE,
  COLUMN_IDS.COH_PART_STATUS,
  COLUMN_IDS.CPGI_CLAIM_TYPE,
  COLUMN_IDS.PGI_CLAIM_TYPE,
  COLUMN_IDS.C_CLAIM_TYPE,
  COLUMN_IDS.PGI_CLAIM_AMOUNT,
  COLUMN_IDS.PGI_IMPORTER_AMOUNT,
  COLUMN_IDS.PGI_HEADQUARTERS_AMOUNT,
  COLUMN_IDS.PGI_CLAIM_AMOUNT_INVOICED,
  COLUMN_IDS.C_INVOICING_DATE,
  COLUMN_IDS.C_PRODUCE_WEEK_FIRST,
  COLUMN_IDS.C_PRODUCTION_WEEK,
  COLUMN_IDS.C_PRODUCTION_DATE,
  COLUMN_IDS.C_VALUATION_DATE,
  COLUMN_IDS.C_INVOICING_DATE_TO_IMPORTER,
  COLUMN_IDS.C_INVOICING_DATE,
  COLUMN_IDS.C_INVOICE_NUMBER,
  COLUMN_IDS.C_SELLING_DATE,
  COLUMN_IDS.C_ACTIVATION_DATE,
  COLUMN_IDS.C_DEALER_NUMBER,
  COLUMN_IDS.C_DEALER_AREA,
  COLUMN_IDS.C_CLIENT_VOS,
  COLUMN_IDS.C_CLIENT_ID,
  COLUMN_IDS.C_CLIENT_NAME,
  COLUMN_IDS.C_MARKETING_ACTION_CODE_OTHER,
  COLUMN_IDS.C_CALCULATION_DATE
];

const IMPORTER_EXCLUDED_COLUMNS_TO_EXPORT = [
  COLUMN_IDS.PGI_PGID,
  COLUMN_IDS.PGI_CLAIM_TYPE
];
