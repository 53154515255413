import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class PCGetProductionCorridorListFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PVT_PC__GET_PRODUCTION_CORRIDOR_LIST);
  }

}

export default PCGetProductionCorridorListFunctionalityContext;