
export const EXCEL_STYLES_IDS = {
  DATE: 'ovex-agg-export-date',
  DATE_TIME_MINUTES: 'ovex-agg-export-date-time-minutes',
  DATE_TIME_SECONDS: 'ovex-agg-export-date-time-seconds',
  DATE_SA: 'ovex-agg-export-date-sa',
  NUMBER_0_DECIMALS: 'ovex-agg-export-number0',
  NUMBER_2_DECIMALS: 'ovex-agg-export-number2',
  PERCENT_2_DECIMALS: 'ovex-agg-export-percent2decimals',
  STRING: 'ovex-agg-export-string'
};

export const excelStyles = [
  {
    id: EXCEL_STYLES_IDS.DATE,
    dataType: 'DateTime',
    numberFormat: {
      format: 'dd/mm/yyyy'
    }
  },
  {
    id: EXCEL_STYLES_IDS.DATE_TIME_MINUTES,
    dataType: 'DateTime',
    numberFormat: {
      format: 'dd/mm/yyyy h:mm'
    }
  },
  {
    id: EXCEL_STYLES_IDS.DATE_TIME_SECONDS,
    dataType: 'DateTime',
    numberFormat: {
      format: 'dd/mm/yyyy h:mm:ss'
    }
  },
  {
    id: EXCEL_STYLES_IDS.DATE_SA,
    alignment: {
      horizontal: 'Right'
    }
  },
  {
    id: EXCEL_STYLES_IDS.NUMBER_0_DECIMALS,
    dataType: 'number',
    numberFormat: {
      format: '# ##0'
    }
  },
  {
    id: EXCEL_STYLES_IDS.NUMBER_2_DECIMALS,
    dataType: 'number',
    numberFormat: {
      format: '# ##,##0.00'
    }
  },
  {
    id: EXCEL_STYLES_IDS.PERCENT_2_DECIMALS,
    dataType: 'number',
    numberFormat: {
      format: '0.00 %'
    }
  },
  {
    id: EXCEL_STYLES_IDS.STRING,
    dataType: 'string'
  }
];
