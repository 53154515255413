import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAlertBusOvex, usePrivilege } from '../../../common/hooks';
import { GetAnnualTargetCalendarYearsFunctionalityContext } from '../../privilege/context';
import {
  getAnnualTargetCalendarYears,
  createAnnualTargetYear,
  getAnnualTargetYears,
  handleUpdateAnnualTargetYears,

  createNegotiation,
  updateNegotiation,
  updateActivatedNegotiation,
  shiftNegotiationWorkflow,

  createNegotiationClosure,
  cancelNegotiationClosure,

  handleCleanNegotiationDealers,
  handleCleanProductionCapacityRatio,
  getNegotiationDealers,
  getProductionCapacityRatio,

  handleCleanModelGroups,
  getModelGroups
} from '../../redux/modules/actions';

import Negotiations from './Negotiations';

const isFetching = (state) => {
  return state.at.annualTargetYear.isFetching
    || state.at.dealers.isFetching
    || state.at.productionCapacityRatio.isFetching
    || state.at.modelGroups.isFetching
    || state.at.negotiations.isFetching
    || state.at.negotiationsClosure.isFetching;
};

const NegotiationsConnector = () => {
  const selectedState = useSelector(state => {
    return {
      dealerList: state.at.dealers.dealerList,
      productionCapacityRatioList: state.at.productionCapacityRatio.productionCapacityRatioList,
      isFetching: isFetching(state),
      modelGroupList: state.at.modelGroups.modelGroupList,
      negotiationList: state.at.annualTargetYear.negotiationRowList,
      calendarYearList: state.at.annualTargetYear.calendarYearList
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        createAnnualTargetYear: (newAnnualTargetYearData) => dispatch(createAnnualTargetYear(newAnnualTargetYearData)),
        createNegotiation: (newNegotiationData) => dispatch(createNegotiation(newNegotiationData)),
        getAnnualTargetCalendarYears: () => dispatch(getAnnualTargetCalendarYears()),
        getAnnualTargetYears: (year) => dispatch(getAnnualTargetYears(year)),
        getModelGroups: () => dispatch(getModelGroups()),
        getNegotiationDealers: (year, negotiationType) => dispatch(getNegotiationDealers(year, negotiationType)),
        getProductionCapacityRatio: (year) => dispatch(getProductionCapacityRatio(year)),
        onCancelNegotiationClosure: (negotiationId) => dispatch(cancelNegotiationClosure(negotiationId)),
        onCleanModelGroups: () => dispatch(handleCleanModelGroups()),
        onCleanNegotiationDealers: () => dispatch(handleCleanNegotiationDealers()),
        onCleanProductionCapacityRatio: () => dispatch(handleCleanProductionCapacityRatio()),
        onCreateNegotiationClosure: (atYearId) => dispatch(createNegotiationClosure(atYearId)),
        onUpdateAnnualTargetYears: (atYearId, annualTargetYearUpdateDTO) => dispatch(handleUpdateAnnualTargetYears(atYearId, annualTargetYearUpdateDTO)),
        shiftNegotiationWorkflow: (negotiationWorkflow) => dispatch(shiftNegotiationWorkflow(negotiationWorkflow)),
        updateNegotiation: (updateNegotiationData) => dispatch(updateNegotiation(updateNegotiationData)),
        updateActivatedNegotiation: (updateNegotiationData) => dispatch(updateActivatedNegotiation(updateNegotiationData))
      };
    },
    [dispatch]
  );

  const { handlePrivilegeRequest, handleIsPrivileged } = usePrivilege();

  React.useEffect(
    () => {
      if (Array.isArray(selectedState.negotiationList) && selectedState.negotiationList.length > 0) {
        const functionalityContextList = [];

        selectedState.negotiationList.forEach(item => {
          if (item.isYearSettingsRow()) {
            functionalityContextList.push(item.privilegeFunctionalityContextObject.createNegotiationContractFnCtx);
            functionalityContextList.push(item.privilegeFunctionalityContextObject.createNegotiationAdditionFnCtx);
            functionalityContextList.push(item.privilegeFunctionalityContextObject.createNegotiationClosureFnCtx);
            functionalityContextList.push(item.privilegeFunctionalityContextObject.updateAnnualTargetYearFnCtx);
          } else if (item.isNegotiationContractOrAdditionRow()) {
            functionalityContextList.push(item.privilegeFunctionalityContextObject.updateNegotiationFnCtx);
            functionalityContextList.push(item.privilegeFunctionalityContextObject.updateActivatedNegotiationFnCtx);
            functionalityContextList.push(item.privilegeFunctionalityContextObject.negotiationShiftStateToActivatedFnCtx);
          } else if (item.isNegotiationClosureRow()) {
            functionalityContextList.push(item.privilegeFunctionalityContextObject.cancelNegotiationClosureFnCtx);
          }
        });

        handlePrivilegeRequest(functionalityContextList);
      }
    },
    [handlePrivilegeRequest, selectedState.negotiationList]
  );

  const { handleAddAlertSimple } = useAlertBusOvex();

  return (
    <Negotiations
      calendarYearList={selectedState.calendarYearList}
      createAnnualTargetYear={callbacks.createAnnualTargetYear}
      createNegotiation={callbacks.createNegotiation}
      dealerList={selectedState.dealerList}
      getAnnualTargetCalendarYears={handleIsPrivileged(new GetAnnualTargetCalendarYearsFunctionalityContext()) ? callbacks.getAnnualTargetCalendarYears : undefined}
      getAnnualTargetYears={callbacks.getAnnualTargetYears}
      getModelGroups={callbacks.getModelGroups}
      getNegotiationDealers={callbacks.getNegotiationDealers}
      getProductionCapacityRatio={callbacks.getProductionCapacityRatio}
      isFetching={selectedState.isFetching}
      modelGroupList={selectedState.modelGroupList}
      negotiationList={selectedState.negotiationList}
      onAddAlertSimple={handleAddAlertSimple}
      onCancelNegotiationClosure={callbacks.onCancelNegotiationClosure}
      onCleanModelGroups={callbacks.onCleanModelGroups}
      onCleanNegotiationDealers={callbacks.onCleanNegotiationDealers}
      onCleanProductionCapacityRatio={callbacks.onCleanProductionCapacityRatio}
      onCreateNegotiationClosure={callbacks.onCreateNegotiationClosure}
      onUpdateAnnualTargetYears={callbacks.onUpdateAnnualTargetYears}
      productionCapacityRatioList={selectedState.productionCapacityRatioList}
      shiftNegotiationWorkflow={callbacks.shiftNegotiationWorkflow}
      updateActivatedNegotiation={callbacks.updateActivatedNegotiation}
      updateNegotiation={callbacks.updateNegotiation}
    />
  );
};

export default NegotiationsConnector;
