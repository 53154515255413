import { COLUMN_IDS, tableBaseColumnDefs, COLUMN_GROUP_IDS, tableBaseColumnGroupDefs } from '../utils/tableBaseColumnDefs';
import { modelGroupFormatter } from '../../../utils/formatters';
import { handleEditableRecommendedNumberColumn, handleNumberValueFormatter, handleRecommendedValueParser, handleRecommendedValueSetter } from '../utils/helpers';
import { handleDonePercentNumberAggFunc, handleDonePercentNumberValueGetter } from '../../../utils/statisticsHelpers';
import { AG_GROUP_TOTAL } from '../utils/const';
import { EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { handleInnerRendererTOTAL } from '../utils/helpers';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  DATA_CD: 'dataDefinitionType'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.DATA_CD]: {
    cellClass: ['ovex-ModelGroupAnnualTargetTable-cell-numeric'],
    valueFormatter: (valueFormatterParams) => {
      const { node, data, colDef } = valueFormatterParams;
      if ([COLUMN_IDS.AT_ATS_DEALER_NUMBER, COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER, COLUMN_IDS.AT_ATS_APPROVAL_NUMBER].includes(colDef.colId)) {
        if (node.group && node.key !== AG_GROUP_TOTAL) {
          valueFormatterParams.value = null;
        }
        if (!node.group && data && data.isPeriodRow()) {
          valueFormatterParams.value = null;
        }
      }
      return handleNumberValueFormatter(valueFormatterParams);
    },
    aggFunc: 'sum'
  }
};

export const getAutoGroupColumnDef = (lsi) => {
  return {
    minWidth: tableBaseColumnDefs[COLUMN_IDS.MODEL_GROUP].minWidth,
    width: tableBaseColumnDefs[COLUMN_IDS.MODEL_GROUP].width,
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_GROUP_ROC'),
    valueGetter: 'data && data.modelGroup',
    cellClass: EXCEL_STYLES_IDS.STRING,
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    valueFormatter: modelGroupFormatter,
    cellRendererParams: {
      innerRenderer: handleInnerRendererTOTAL,
      suppressCount: true
    }
  };
};

export const getColumnDefinitions = (lsi, doneCountGroupExpanded, potentialCountGroupExpanded) => [
  {
    valueGetter: 'data && data.groupValue',
    rowGroup: true,
    hide: true
  },
  {
    ...tableBaseColumnDefs[COLUMN_IDS.MODEL_GROUP],
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_GROUP_ROC'),
    valueGetter: 'data && data.modelGroup',
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    width: 280,
    valueFormatter: modelGroupFormatter,
    hide: true
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_WORKING_GROUP],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_DEALER_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
        valueGetter: 'data && data.atDealerNumber',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
        valueGetter: 'data && data.atRecommendedNumber',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        cellClassRules: {
          'ovex-ModelGroupAnnualTargetTable-cell-numeric--editable': handleEditableRecommendedNumberColumn,
          'mdi': handleEditableRecommendedNumberColumn,
          'ovex-ModelGroupAnnualTargetTable-cell-numeric--modified': (cellClassParams) => !cellClassParams.node.group && cellClassParams.data.isATRecommendedNumberModified()
        },
        editable: handleEditableRecommendedNumberColumn,
        valueParser: handleRecommendedValueParser,
        valueSetter: handleRecommendedValueSetter
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVAL_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
        valueGetter: 'data && data.atApprovalNumber',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_APPROVED_GROUP],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVED_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
        valueGetter: 'data && data.atApprovedNumber',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_GROUP],
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
    openByDefault: doneCountGroupExpanded,
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_INVOICED_COUNT],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.INVOICED'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_INVOICED'),
        valueGetter: 'data && data.atStatisticsInvoicedCount',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        columnGroupShow: 'open'
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PRODUCTION_COUNT],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_PRODUCTION'),
        valueGetter: 'data && data.atStatisticsProductionCount',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        columnGroupShow: 'open'
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PLANNED_COUNT],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED'),
        headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_PLANNED'),
        valueGetter: 'data && data.atStatisticsPlannedCount',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        columnGroupShow: 'open'
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL'),
        valueGetter: 'data && data.getATStatisticsDoneCount()',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_PERCENTAGE],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
        valueGetter: (valueGetterParams) => handleDonePercentNumberValueGetter(valueGetterParams, valueGetterParams.data.atApprovedNumber, valueGetterParams.data.getATStatisticsDoneCount()),
        aggFunc: handleDonePercentNumberAggFunc,
        valueFormatter: null,
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_REMAINING_GROUP],
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_REMAINING_NUMBER],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
        valueGetter: 'data && data.getATStatisticsRemainsCount()',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  },
  {
    ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_GROUP],
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.POTENTIAL'),
    openByDefault: potentialCountGroupExpanded,
    children: [
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION'),
        valueGetter: 'data && data.atStatisticsPotentialProductionCount',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        columnGroupShow: 'open'
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED'),
        valueGetter: 'data && data.atStatisticsPotentialPlannedCount',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
        columnGroupShow: 'open'
      },
      {
        ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_COUNT],
        headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL'),
        valueGetter: 'data && data.getATStatisticsPotentialCount()',
        type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
      }
    ]
  }
];
