import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrivilege } from '../../../common/hooks';
import {
  RefreshStorageDataPFC

} from '../../privilege/context';
import {
  handleRefreshUniverseStorageData,
  handleRefreshPGVersionStorageData
} from '../../redux/modules/actions';

import PriceGuaranteeRefreshStorageData from './PriceGuaranteeRefreshStorageData';

const refreshStorageDataPFC = new RefreshStorageDataPFC();

const PriceGuaranteeRefreshStorageDataConnector = () => {

  const selectedState = useSelector(state => {
    return {
      isFetching: state.priceGuarantee.refreshStorageData.isFetching
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        handleRefreshUniverseStorage: (type) => dispatch(handleRefreshUniverseStorageData(type)),
        handleRefreshPGVersionStorage: (type) => dispatch(handleRefreshPGVersionStorageData(type))
      };
    },
    [dispatch]
  );

  const { handlePrivilegeRequest } = usePrivilege();

  React.useEffect(
    () => {
      handlePrivilegeRequest([refreshStorageDataPFC]);
    },
    [handlePrivilegeRequest]
  );

  return (
    <PriceGuaranteeRefreshStorageData
      isFetching={selectedState.isFetching}
      onRefreshPGVersionStorageData={callbacks.handleRefreshPGVersionStorage}
      onRefreshUniverseStorageData={callbacks.handleRefreshUniverseStorage}
    />
  );
};

export default PriceGuaranteeRefreshStorageDataConnector;
