/**
 * Typy objektu {@see module:model/RestrictionDTO}
 *
 * @enum
 */
const ACRowTypeEnum = {

  QUOTA: 'QUOTA',

  QUOTA_NON_RESTRICTIVE: 'QUOTA_NON_RESTRICTIVE',

  ROC: 'ROC',

  RESTRICTION: 'RESTRICTION',

  /**
   * @param {RestrictionTypeEnum} type
   * @return {boolean}
   */
  isQuota: (type) => type === ACRowTypeEnum.QUOTA,

  /**
   * @param {RestrictionTypeEnum} type
   * @return {boolean}
   */
  isRestriction: (type) => type === ACRowTypeEnum.RESTRICTION,
  /**
   * @param {RestrictionTypeEnum} type
   * @return {boolean}
   */
  isQuotaNonRestrictive: (type) => type === ACRowTypeEnum.QUOTA_NON_RESTRICTIVE,
  /**
   * @param {RestrictionTypeEnum} type
   * @return {boolean}
   */
  isROC: (type) => type === ACRowTypeEnum.ROC
};

export default ACRowTypeEnum;