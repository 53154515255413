import ButtonType from '../ButtonType';

const DropdownTypeProps = {
  [ButtonType.PRIMARY]: {
    bgStyle: 'filled',
    borderRadius: '16px',
    className: 'ovex-Dropdown--primary',
    colorSchema: 'primary',
    elevation: 0
  },
  [ButtonType.SECONDARY]: {
    bgStyle: 'outline',
    borderRadius: '16px',
    className: 'ovex-Dropdown--secondary',
    colorSchema: 'grey',
    elevation: 0
  },
  [ButtonType.FLOATING]: {
    bgStyle: 'transparent',
    borderRadius: '16px',
    className: 'ovex-Dropdown--floating',
    colorSchema: null,
    elevation: 3
  },
  /**
   * **DEFAULT** props are not "public"
   */
  'DEFAULT': {
    bgStyle: 'filled',
    borderRadius: '0px',
    className: null,
    colorSchema: null,
    elevation: 0
  }
};

export default DropdownTypeProps;