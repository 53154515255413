import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import './TextArea.scss';

const TextComponent = (props, ref) => {
  const inputAttrs = {
    maxLength: props.maxLength
  };

  return (
    <Forms.TextArea
      bgStyle='underline'
      className={['ovex-forms-TextArea', props.className].join(' ')}
      disabled={props.disabled}
      inputAttrs={inputAttrs}
      inputColWidth='xs12'
      label={props.label}
      labelColWidth='xs12'
      name={props.name}
      onChange={props.onChange}
      parent={props.parent}
      pureRender
      readOnly={props.readOnly}
      ref_={ref}
      required={props.required}
      rows={props.rows}
      tooltip={props.tooltip}
      value={props.value || ''}
    />
  );
};

const TextArea = React.forwardRef(TextComponent);
TextArea.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

TextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  rows: PropTypes.number,
  tooltip: PropTypes.string,
  value: PropTypes.string
};

TextArea.defaultProps = {
  className: null,
  disabled: false,
  label: null,
  maxLength: 512,
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  rows: 5,
  tooltip: null,
  value: ''
};

export default TextArea;