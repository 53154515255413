import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';

import { useIsPrivileged } from '../../../hooks';
import PrivilegeFunctionalityContextPropType from '../../../privilege/PrivilegeFunctionalityContextPropType';

import './IconButton.scss';

const propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool,
  iconColor: PropTypes.string,
  iconPath: PropTypes.string.isRequired,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  privilegeFunctionalityContext: PrivilegeFunctionalityContextPropType.propType
};

const defaultProps = {
  className: null,
  hidden: false,
  iconColor: null,
  iconSize: null,
  onClick: undefined,
  privilegeFunctionalityContext: PrivilegeFunctionalityContextPropType.defaultProp
};

const IconButton = (props) => {
  const hidden = !useIsPrivileged(props);

  const classNames = ['ovex-IconButton'];
  props.className && classNames.push(props.className);

  if (props.hidden || hidden) {
    return null;
  }
  return (
    <button
      className={classNames.join(' ')}
      onClick={props.onClick}
    >
      <Icon
        color={props.iconColor}
        path={props.iconPath}
        size={props.iconSize}
      />
    </button>
  );
};

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default IconButton;
