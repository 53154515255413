
/**
 * Parses an ISO-8601 string representation of a date value.
 * @param {String} str The date value as a string.
 * @returns {Date} The parsed date object.
 */
const parseDate = (str) => {
  return new Date(str);
};

/**
 * **Based on ApiClient from opena pi generator**
 *
 * Converts a value to the specified type.
 * @param {(String|Object)} data The data to convert, as a string or object.
 * @param {(String|Array.<String>|Object.<String, Object>|Function)} type The type to return. Pass a string for simple types
 * or the constructor function for a complex type. Pass an array containing the type name to return an array of that type. To
 * return an object, pass an object with one property whose name is the key type and whose value is the corresponding value type:
 * all properties on <code>data<code> will be converted to this type.
 * @returns An instance of the specified type or null or undefined if data is null or undefined.
 */
const convertToType = (data, type) => {
  if (data === null || data === undefined) {
    return data;
  }

  switch (type) {
    case 'Boolean':
      return Boolean(data);
    case 'Integer':
      return parseInt(data, 10);
    case 'Number':
      return parseFloat(data);
    case 'String':
      return String(data);
    case 'Date':
      return parseDate(String(data));
    case 'Blob':
      return data;
    default:
      if (type === Object) {
        // generic object, return directly
        return data;
      } else if (typeof type.constructFromObject === 'function') {
        // for model type like User and enum class
        return type.constructFromObject(data);
      } else if (Array.isArray(type)) {
        // for array type like: ['String']
        const itemType = type[0];

        return data.map((item) => {
          return convertToType(item, itemType);
        });
      } else if (typeof type === 'object') {
        // for plain object type like: {'String': 'Integer'}
        let keyType, valueType;
        for (let k in type) {
          if (type.hasOwnProperty(k)) {
            keyType = k;
            valueType = type[k];
            break;
          }
        }

        const result = {};
        for (let l in data) {
          if (data.hasOwnProperty(l)) {
            const key = convertToType(l, keyType);
            const value = convertToType(data[l], valueType);
            result[key] = value;
          }
        }

        return result;
      } else {
        // for unknown type, return the data directly
        return data;
      }
  }
};

export default convertToType;