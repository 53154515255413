import React from 'react';

import { ModuleContext } from '../contexts';

/**
 * @param {ModuleContextData} externModuleContextData - use only out of module scope
 */
const useEvaluateModuleContext = (externModuleContextData = null) => {
  /**
     * @type {ModuleContextData}
     */
  let module = React.useContext(ModuleContext);
  if (externModuleContextData != null) {
    module = externModuleContextData;
  }

  if (module == null) {
    console.warn('Module context data is not initialized!');
  }
  return module;
};

export default useEvaluateModuleContext;
