import React from 'react';

import { UserModalContext, UserModalEnum } from '../index';
import UserInfoModal from '../UserInfoModal/UserInfoModal';
import { DEVELOPMENT_ENVIRONMENTS } from '../../../utils/consts';
import DevExt from '../development-extensions';
import UserImitateModalConnector from '../../../packages/support/layouts/UserImitateModal/UserImitateModal.connector';
import UserChangeBidModalConnector from '../../../packages/support/layouts/UserChangeBidModal/UserChangeBidModal.connector';
import UserRevertChangesModalConnector
  from '../../../packages/support/layouts/UserRevertChangesModal/UserRevertChangesModal.connector';

const UserModalSelector = () => {
  const environment = window.OVEX3_ENVIRONMENT;
  const userModal = React.useContext(UserModalContext);
  const value = userModal?.value;

  let ModalComponent = null;
  if (value === UserModalEnum.USER_INFO) {
    ModalComponent = UserInfoModal;
  } else if (value === UserModalEnum.USER_IMITATE) {
    ModalComponent = UserImitateModalConnector;
  } else if (value === UserModalEnum.USER_EMIGRATE) {
    ModalComponent = UserChangeBidModalConnector;
  } else if (value === UserModalEnum.USER_REVERT) {
    ModalComponent = UserRevertChangesModalConnector;
  } else if (DEVELOPMENT_ENVIRONMENTS.includes(environment)) {
    /**
     * Source code of DevExt components are separate from the main source. They must be lazy-loaded.
     * {@see https://reactjs.org/docs/code-splitting.html}
     */
    let DevExtModalComponent;
    if (value === UserModalEnum.DEV_USER_CHANGE) {
      DevExtModalComponent = DevExt.DevUserChangeModal;
    }
    if (DevExtModalComponent != null) {
      ModalComponent = (props) => (
        <React.Suspense fallback={null}>
          <DevExtModalComponent {...props} />
        </React.Suspense>
      );
    }
  }

  const handleClose = () => userModal?.setValue(null);

  return ModalComponent == null ? null : (
    <ModalComponent
      onClose={handleClose}
    />
  );
};

export default UserModalSelector;