import React from 'react';
import PropTypes from 'prop-types';
import { mdiMagnify, mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { LsiContext } from '../../../../contexts';
import { Button } from '../../../index';
import ButtonType from '../../../buttons/ButtonType';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  reloadProps: PropTypes.shape({
    iconPath: PropTypes.string,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    type: PropTypes.oneOf(Object.values(ButtonType))
  })
};

const defaultProps = {
  className: null,
  disabled: false,
  reloadProps: null
};

const iconSize = 0.64;

const FilterContextButtons = (props) => {
  const lsi = React.useContext(LsiContext);

  const classNames = ['ovex-FilterContextButtons'];
  props.className && classNames.push(props.className);

  const reloadButtonProps = prepareReloadButtonProps(lsi, props.reloadProps);

  return (
    <Forms.ContextFormConsumer>
      {
        ({ getForm }) => {
          return (
            <React.Fragment>
              <Button
                content={reloadButtonProps.content}
                disabled={props.disabled}
                key='ovex-FilterContextButtons-btn-reload'
                onClick={() => getForm?.()?.save()}
                tooltip={reloadButtonProps.tooltip}
                type={reloadButtonProps.type}
              />
              <Button
                content={[
                  (<Icon path={mdiRefresh} size={iconSize}/>),
                  lsi.getLSIItem('COMMON.BUTTON.RESET')
                ]}
                disabled={props.disabled}
                key='ovex-FilterContextButtons-btn-reset'
                onClick={() => getForm?.()?.reset()}
                tooltip={lsi.getLSIItem('COMMON.TOOLTIP.FILTER_RESET')}
                type={Button.type.SECONDARY}
              />
            </React.Fragment>
          );
        }
      }
    </Forms.ContextFormConsumer>
  );
};

const prepareReloadButtonProps = (lsi, customProps) => {
  if (customProps) {
    const icon = customProps.iconPath && (<Icon path={customProps.iconPath} size={iconSize} />);
    return {
      content: [
        icon,
        customProps.label
      ],
      tooltip: customProps.tooltip,
      type: customProps.type || Button.type.SECONDARY
    };
  }

  return {
    content: [
      (<Icon path={mdiMagnify} size={iconSize} />),
      lsi.getLSIItem('COMMON.BUTTON.RELOAD')
    ],
    tooltip: lsi.getLSIItem('COMMON.TOOLTIP.FILTER_RELOAD'),
    type: Button.type.SECONDARY
  };
};

FilterContextButtons.propTypes = propTypes;
FilterContextButtons.defaultProps = defaultProps;

export default FilterContextButtons;
