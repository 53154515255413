import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useHistoryOvex, usePrivilege } from '../../../hooks';
import PrivilegeFunctionalityContextPropType from '../../../privilege/PrivilegeFunctionalityContextPropType';
import { Dropdown } from '../../index';

import './NavMenu.scss';

const propTypes = {
  menuLabel: PropTypes.string.isRequired,
  navMenuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    privilegeFunctionalityContext: PrivilegeFunctionalityContextPropType.propType,
    route: PropTypes.string.isRequired
  })).isRequired,
  pathPrefix: PropTypes.string.isRequired
};

const defaultProps = {};

const NavMenu = (props) => {
  const history = useHistoryOvex();
  const location = useLocation();

  const classNames = ['ovex-NavMenu'];
  if (location.pathname.startsWith(props.pathPrefix)) {
    classNames.push('ovex-NavMenu--active');
  }

  const handleGetDropdownItem = React.useCallback(
    (navMenuItem) => ({
      label: navMenuItem.label,
      onClick: () => history.push(navMenuItem.route),
      privilegeFunctionalityContext: navMenuItem.privilegeFunctionalityContext
    }),
    [history]
  );

  const { handleIsPrivileged } = usePrivilege();

  const navMenuItems = React.useMemo(
    () => {
      return props.navMenuItems
        .filter(navMenuItem => handleIsPrivileged(navMenuItem[PrivilegeFunctionalityContextPropType.propName]))
        .map(handleGetDropdownItem);
    },
    [props.navMenuItems, handleIsPrivileged, handleGetDropdownItem]
  );

  if (navMenuItems.length === 0) {
    return null;
  }

  return (
    <Dropdown
      className={classNames.join(' ')}
      items={navMenuItems}
      label={props.menuLabel}
    />
  );
};

NavMenu.propTypes = propTypes;
NavMenu.defaultProps = defaultProps;

export default NavMenu;