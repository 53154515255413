import React from 'react';
import './RestrictionCellRenderer.scss';
import PropTypes from 'prop-types';

import { getCellData } from '../../../../../common/components/ag-grid/OvexAGTable';
import ACRowTypeEnum from '../rowModel/ACRowTypeEnum';
import AvailableCapacityRow from '../rowModel/AvailableCapacityRow';

const RestrictionCellRenderer = (props) => {
  const cellData = getCellData(props);
  let valueFormatted;

  if (cellData != null) {
    if (ACRowTypeEnum.isROC(props.data.type)) {
      valueFormatted = cellData;
    } else {
      valueFormatted = '';
    }
  }

  return (
    <div>
      {valueFormatted}
    </div>
  );
};

RestrictionCellRenderer.propTypes = {
  data: PropTypes.instanceOf(AvailableCapacityRow).isRequired
};

RestrictionCellRenderer.defaultProps = {};

export default RestrictionCellRenderer;
