import React from 'react';
import PropTypes from 'prop-types';
import { CommissionValidationResultDTOVLD } from '@ovex/price-guarantee-web-api';

import ValidationResultHeaderTable from './ValidationResultHeaderTable/ValidationResultHeaderTable';
import ValidationResultPGItemTable from './ValidationResultPGItemTable/ValidationResultPGItemTable';
import ValidationResultPGUniverseInfo from './ValidationResultPGUniverseInfo/ValidationResultPGUniverseInfo';

import './ValidationResult.scss';

const propTypes = {
  validationResult: PropTypes.instanceOf(CommissionValidationResultDTOVLD).isRequired
};

const defaultProps = {
};

const ValidationResult = (props) => {
  const { validationResult } = props;
  const {
    commission, priceGuarantee,
    calculatedSellingDateTo, conditionHeaderRowList,
    cOrderHeaderAccountList, conditionCiColumnDefinitionList,
    pgUniverseInfo
  } = validationResult;

  return (
    <div
      className='ovex-pg-ValidationResult'
    >
      <ValidationResultPGUniverseInfo
        pgUniverseInfo={pgUniverseInfo}
      />
      <ValidationResultHeaderTable
        calculatedSellingDateTo={calculatedSellingDateTo}
        commission={commission}
        conditionHeaderRowList={conditionHeaderRowList}
        priceGuarantee={priceGuarantee}
      />
      <ValidationResultPGItemTable
        commission={commission}
        conditionCiColumnDefinitionList={conditionCiColumnDefinitionList}
        cOrderHeaderAccountList={cOrderHeaderAccountList}
        currency={priceGuarantee.currency}
      />
    </div>
  );
};

ValidationResult.propTypes = propTypes;
ValidationResult.defaultProps = defaultProps;

export default ValidationResult;
