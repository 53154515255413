import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class ITMUpdatePriceGuaranteeItemsPFC extends AbstractFunctionalityContext {

  /**
   * @param {number} priceGuaranteeItemId
   */
  constructor(priceGuaranteeItemId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_ITM__UPDATE_PRICE_GUARANTEE_ITEMS);
    this.setParams({
      [ContextConstants.PG_ITEM_ID]: priceGuaranteeItemId
    });
  }

}

export default ITMUpdatePriceGuaranteeItemsPFC;
