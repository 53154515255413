import React from 'react';
import PropTypes from 'prop-types';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';
import { AnnualTargetYearATSalesPeriodDTO } from '@ovex/annual-target-web-api';

import { Number, SwitchSelector } from '../../../../../common/components/forms';
import { LsiContext } from '../../../../../common/contexts';
import { Periods } from '../../../../components';

const InputNames = {
  coefficient: 'coefficient',
  periodCount: 'periodCount',
  periods: 'periods',
  year: 'year'
};

const DefaultValues = {
  coefficient: 1,
  periodCount: 4,
  mixRatio: 0
};

const yearSelectorMapper = (year) => ({ content: year, value: year });

const YearSettingsHeaderComponent = (props, ref) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = !props.onSave;

  const classNames = ['ovex-YearSettingsHeader'];
  props.className && classNames.push(props.className);

  const formRef = React.useRef(null);
  const handleGetFormRef = React.useCallback(
    (uuFormRef) => {
      formRef.current = uuFormRef;
    },
    []
  );

  React.useImperativeHandle(
    ref,
    () => ({
      isValid: () => formRef.current.isValid(),
      save: () => formRef.current.save()
    })
  );

  const [ periodCount, setPeriodCount ] = React.useState(props.atSalesPeriodList != null ? props.atSalesPeriodList.length : DefaultValues.periodCount);

  const handleChangePeriodCount = readOnly ? undefined : ({ value }) => {
    setPeriodCount(value);
  };

  const onSave = props.onSave;
  const handleSave = React.useCallback(
    ({ values }) => {
      const result = {
        year: values[InputNames.year],
        coefficient: values[InputNames.coefficient],
        periodCount: values[InputNames.periodCount],
        atSalesPeriodList: Periods.parsePeriodListFromInputValues(values, InputNames.periods)
      };

      onSave && onSave({ formId: props.formId, values: result });
    },
    [onSave, props.formId]
  );

  return (
    <Forms.Form
      className={classNames.join(' ')}
      onSave={handleSave}
      ref_={handleGetFormRef}
    >
      <SwitchSelector
        items={props.yearsAvailable ? props.yearsAvailable.map(yearSelectorMapper) : [yearSelectorMapper(props.year)]}
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.YEAR')}
        name={InputNames.year}
        readOnly={readOnly || props.readOnlyYear}
        required
        value={props.year}
      />
      <Number
        decimals={2}
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.COEFFICIENT_RCP_ROC')}
        max={10}
        min={0}
        name={InputNames.coefficient}
        readOnly={readOnly}
        required
        step={0.01}
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COEFFICIENT_RCP_ROC')}
        value={props.coefficient != null ? props.coefficient : DefaultValues.coefficient}
      />
      <Number
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.YEAR_PERIOD_COUNT')}
        max={12}
        min={1}
        name={InputNames.periodCount}
        onChange={handleChangePeriodCount}
        readOnly
        required
        value={periodCount}
      />
      <Periods
        hiddenNegotiable
        name={InputNames.periods}
        periodCount={periodCount}
        readOnly={readOnly}
        required
        value={props.atSalesPeriodList}
      />
    </Forms.Form>
  );
};

const YearSettingsHeader = React.memo(React.forwardRef(YearSettingsHeaderComponent));

YearSettingsHeaderComponent.propTypes = {
  atSalesPeriodList: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.instanceOf(AnnualTargetYearATSalesPeriodDTO)
  ])),
  className: PropTypes.string,
  coefficient: PropTypes.number,
  formId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  readOnlyYear: PropTypes.bool,
  year: PropTypes.number.isRequired,
  yearsAvailable: PropTypes.arrayOf(PropTypes.number)
};

YearSettingsHeaderComponent.defaultProps = {
  atSalesPeriodList: null,
  className: null,
  coefficient: DefaultValues.coefficient,
  onSave: null,
  readOnlyYear: false,
  yearsAvailable: null
};

export default YearSettingsHeader;
