import { percentToDecimal } from '../../utils/helpers';

const SEPARATOR_CHAR = '_';
const getInputNamePrefix = (name) => name + SEPARATOR_CHAR;

/**
 * Input name for period component.
 * @param {string} name
 * @param {number} idx
 * @return {string}
 */
export const getPeriodInputName = (name, idx) => getInputNamePrefix(name) + idx;

/**
 * Input name for minimal achievement ratio input
 * @param {string} name
 * @return {string}
 */
export const getMinAchievementRatioInputName = (name) => getInputNamePrefix(name) + 'minAchievementRatio';
/**
 * Input name for negotiable input
 * @param {string} name
 * @return {string}
 */
export const getNegotiableInputName = (name) => getInputNamePrefix(name) + 'negotiable';
/**
 * Input name for id input (input is hidden)
 * @param {string} name
 * @return {string}
 */
export const getIdInputName = (name) => getInputNamePrefix(name) + 'id';

/**
 * @param a
 * @param b
 * @return {number}
 */
export const periodSorter = (a, b) => a.unitOrder - b.unitOrder;

/**
 * @param {object} values - object contains values of periods inputs, specified with name as prefix
 * @param {string} name - periods name
 * @return {Array.<object>}
 */
export const parsePeriodListFromInputValues = (values, name) => {
  if (values == null) {
    return [];
  }

  const resultObj = {};
  Object.keys(values)
    .filter(key => key.startsWith(getInputNamePrefix(name)))
    .forEach((key) => {
      const [ , unitOrder, inputName ] = key.split(SEPARATOR_CHAR);
      const unitOrderNumber = Number.parseInt(unitOrder);
      if (!Number.isNaN(unitOrderNumber)) {
        if (resultObj[unitOrder] == null) {
          resultObj[unitOrder] = {};
          resultObj[unitOrder].unitOrder = unitOrderNumber;
        }
        if (inputName === 'minAchievementRatio') {
          resultObj[unitOrder][inputName] = percentToDecimal(values[key]);
        } else {
          resultObj[unitOrder][inputName] = values[key];
        }
      }
    });

  return Object.values(resultObj);
};