import React from 'react';

import { Page } from '../../index';
import { LsiContext } from '../../../contexts';

const NotFound = () => {
  const lsi = React.useContext(LsiContext);

  return (
    <Page
      header={lsi.getLSIItem('PAGE_TITLE.NOT_FOUND')}
    >
      {''}
    </Page>
  );
};

export default NotFound;
