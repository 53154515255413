import { WebModule, WebModuleInitializationData, WebModuleNavMenuData, WebModuleUserInfoData } from '../web-module';

import PvtInitialization from './components/PvtInitialization/PvtInitialization';
import PvtNavMenu from './components/PvtNavMenu/PvtNavMenu';
import { moduleCode, userInfoSelector, initializationStatusSelector } from './config/moduleConfig';
import reduxData from './redux';
import routesData from './routes';

const navMenuDataList = [
  new WebModuleNavMenuData(100, PvtNavMenu)
];

const modulePvt = new WebModule(
  moduleCode,
  new WebModuleInitializationData(PvtInitialization, initializationStatusSelector),
  navMenuDataList,
  reduxData,
  routesData,
  new WebModuleUserInfoData(reduxData.actions.handleGetUserInfo, userInfoSelector)
);

export default modulePvt;