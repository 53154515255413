import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import './Checkbox.scss';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputColWidth: PropTypes.string,
  label: PropTypes.string,
  labelColWidth: PropTypes.string,
  labelPosition: PropTypes.oneOf(['left', 'right']),
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf([1, 2]),
  value: PropTypes.bool
};

const defaultProps = {
  className: null,
  disabled: false,
  inputColWidth: null,
  label: null,
  labelColWidth: null,
  labelPosition: 'left',
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  tooltip: null,
  type: 2,
  value: false
};

const CheckboxComponent = (props, ref) => {

  const handleChange = (opt) => {
    opt.component.onChangeDefault(opt);
    props.onChange != null && props.onChange(opt.value);
  };

  return (
    <Forms.Checkbox
      className={['ovex-forms-checkbox', props.className].join(' ')}
      colorSchema='green'
      disabled={props.disabled}
      { ...(!!props.inputColWidth && { inputColWidth: props.inputColWidth }) }
      label={props.label}
      { ...(!!props.labelColWidth && { labelColWidth: props.labelColWidth }) }
      labelPosition={props.labelPosition}
      name={props.name}
      onChange={handleChange}
      parent={props.parent}
      readOnly={props.readOnly}
      ref_={ref}
      required={props.required}
      tooltip={props.tooltip}
      type={props.type || defaultProps.type}
      value={props.value}
    />
  );
};

const Checkbox = React.forwardRef(CheckboxComponent);
Checkbox.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

CheckboxComponent.propTypes = propTypes;
CheckboxComponent.defaultProps = defaultProps;

export default Checkbox;
