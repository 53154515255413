import { COLUMN_IDS } from './ValidationResultHeaderTable.columnDefs';

const handleProcessCellCallback = (params) => {
  if ([
    COLUMN_IDS.CONDITION_TYPE,
    COLUMN_IDS.REQUIRED_STATE,
    COLUMN_IDS.CURRENT_STATE
  ].includes(params.column.colId)) {
    params.data = params.node.data;
    return params.column.colDef.valueFormatter(params);
  }
  return params.value;
};

export const exportExcel = {
  processCellCallback: handleProcessCellCallback,

  skipRowGroups: true
};
