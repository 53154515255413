import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonBar, Dropdown } from '../../../../common/components';
import { FilterContextButtons } from '../../../../common/components/forms';
import { LsiContext } from '../../../../common/contexts';
import { ContractStatusEnum } from '../../../utils/const';

import './DealerContractsButtonBar.scss';

class DealerContractsButtonBar extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    disabledEditable: PropTypes.bool,
    editable: PropTypes.bool,
    onCancelEdit: PropTypes.func,
    onEnableEdit: PropTypes.func,
    onSave: PropTypes.func,
    onShiftDealerContractWorkflow: PropTypes.func,
    shiftWorkflowFnCtx: PropTypes.shape({
      toAAManagerApproved: PropTypes.arrayOf(PropTypes.object),
      toAreaAdvisorApproved: PropTypes.arrayOf(PropTypes.object),
      toCreated: PropTypes.arrayOf(PropTypes.object),
      toRejected: PropTypes.arrayOf(PropTypes.object)
    })
  };

  static defaultProps = {
    disabledEditable: false,
    editable: false,
    onCancelEdit: undefined,
    onEnableEdit: undefined,
    onSave: undefined,
    onShiftDealerContractWorkflow: undefined,
    shiftWorkflowFnCtx: null
  }

  _renderReadButtons = () => {
    const lsi = this.context;
    const { disabledEditable, onEnableEdit, onShiftDealerContractWorkflow, shiftWorkflowFnCtx } = this.props;

    return (
      <React.Fragment>
        { onEnableEdit &&
          <Button
            className='ovex-DealerContractsButtonBar-btn-edit'
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.EDIT')}
            disabled={disabledEditable}
            key='ovex-DealerContractsButtonBar-btn-edit'
            onClick={onEnableEdit}
            tooltip={disabledEditable ? lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_TOOLTIP.ANNUAL_TARGET_IMPORTER_DISABLED_EDITABLE') : null}
            type={Button.type.PRIMARY}
          />
        }
        { onShiftDealerContractWorkflow &&
          <Dropdown
            disabledWhenItemsEmpty
            items={[
              {
                label: lsi.getLSIItem('ANNUAL_TARGET.BUTTON.AREA_ADVISOR_APPROVED'),
                onClick: () => onShiftDealerContractWorkflow(ContractStatusEnum.AREA_ADVISOR_APPROVED),
                privilegeFunctionalityContext: shiftWorkflowFnCtx && shiftWorkflowFnCtx.toAreaAdvisorApproved
              },
              {
                label: lsi.getLSIItem('ANNUAL_TARGET.BUTTON.AA_MANAGER_APPROVED'),
                onClick: () => onShiftDealerContractWorkflow(ContractStatusEnum.AA_MANAGER_APPROVED),
                privilegeFunctionalityContext: shiftWorkflowFnCtx && shiftWorkflowFnCtx.toAAManagerApproved
              },
              {
                label: lsi.getLSIItem('ANNUAL_TARGET.BUTTON.REJECTED'),
                onClick: () => onShiftDealerContractWorkflow(ContractStatusEnum.REJECTED),
                privilegeFunctionalityContext: shiftWorkflowFnCtx && shiftWorkflowFnCtx.toRejected
              },
              {
                label: lsi.getLSIItem('ANNUAL_TARGET.BUTTON.CREATED'),
                onClick: () => onShiftDealerContractWorkflow(ContractStatusEnum.CREATED),
                privilegeFunctionalityContext: shiftWorkflowFnCtx && shiftWorkflowFnCtx.toCreated
              }
            ]}
            key='ovex-DealerContractsButtonBar-dd-shiftWorkflow'
            label={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.SHIFT_WORKFLOW')}
            type={Dropdown.type.PRIMARY}
          />
        }
        <ButtonBar.Separator hidden={!onEnableEdit || !onShiftDealerContractWorkflow} />
        <FilterContextButtons />
      </React.Fragment>
    );
  };

  _renderEditButtons = () => {
    const lsi = this.context;
    const { onCancelEdit, onSave } = this.props;

    return (
      <React.Fragment>
        { onCancelEdit &&
          <Button
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.CANCEL')}
            key='ovex-DealerContractsButtonBar-btn-cancel'
            onClick={onCancelEdit}
            type={Button.type.SECONDARY}
          />
        }
        { onSave &&
          <Button
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.SAVE')}
            key='ovex-DealerContractsButtonBar-btn-save'
            onClick={onSave}
            type={Button.type.PRIMARY}
          />
        }
        <ButtonBar.Separator />
        <FilterContextButtons disabled />
      </React.Fragment>
    );
  };

  render() {
    const { editable } = this.props;

    let buttons;
    if (editable) {
      buttons = this._renderEditButtons();
    } else {
      buttons = this._renderReadButtons();
    }

    return (
      <ButtonBar className='ovex-DealerContractsButtonBar'>
        {buttons}
      </ButtonBar>
    );
  }
}

export default DealerContractsButtonBar;
