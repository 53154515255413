import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const restrictionsApi = ApiServiceInstances.pvt.restrictionsApi;

export const GET_AVAILABLE_CAPACITY_REQUEST = 'GET_AVAILABLE_CAPACITY_REQUEST';
export const GET_AVAILABLE_CAPACITY_SUCCESS = 'GET_AVAILABLE_CAPACITY_SUCCESS';
export const GET_AVAILABLE_CAPACITY_FAILURE = 'GET_AVAILABLE_CAPACITY_FAILURE';

const initialState = {
  availableCapacityResponse: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_CAPACITY_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_AVAILABLE_CAPACITY_SUCCESS: {
      return { ...state, isFetching: false, availableCapacityResponse: action.payload };
    }
    case GET_AVAILABLE_CAPACITY_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetAvailableCapacityList = (opts) => {
  return {
    [CALL_API]: {
      apiFunction: () => restrictionsApi.getDealerRestrictionListWithHttpInfo(opts),
      types: [GET_AVAILABLE_CAPACITY_REQUEST, GET_AVAILABLE_CAPACITY_SUCCESS, GET_AVAILABLE_CAPACITY_FAILURE]
    }
  };
};