import { ApiClient as PrivilegeApiClient } from '@ovex/privilege-web-api';
import { ApiClient as AboutApiClient } from '@ovex/about-web-api';
import { ApiClient as SupportApiClient } from '@ovex/support-web-api';

import plugins from '../../common/api/plugins';
import { ApisSettings } from '../../common/api';
import { apiBasePath } from '../config/moduleConfig';

/**
 * ApiClient instances
 */
const ApiClientInstances = {
  support: new SupportApiClient(),
  about: new AboutApiClient(),
  privilege: new PrivilegeApiClient()
};

ApisSettings.removeDefaultInstance([AboutApiClient, SupportApiClient, PrivilegeApiClient]);
ApisSettings.setBasePath(Object.values(ApiClientInstances), apiBasePath);
ApisSettings.setPlugin(Object.values(ApiClientInstances), plugins);

export default ApiClientInstances;
