import React from 'react';

import { Button, ButtonBar } from '../../../../common/components';
import { FilterContextButtons } from '../../../../common/components/forms';
import { LsiContext } from '../../../../common/contexts';

const PriceGuaranteeReportExportButtonBar = () => {
  const lsi = React.useContext(LsiContext);

  return (
    <ButtonBar
      className='ovex-pg-PriceGuaranteeReportExportButtonBar'
    >
      <FilterContextButtons
        reloadProps={{
          label: lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.EXPORT'),
          type: Button.type.PRIMARY
        }}
      />
    </ButtonBar>
  );
};

export default PriceGuaranteeReportExportButtonBar;
