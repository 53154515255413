import React from 'react';
import { Bricks } from 'uu5g04';
import PropTypes from 'prop-types';
import { DealerRestrictionResponseDTO } from '@ovex/pvt-web-api';

import { LsiContext } from '../../../common/contexts';
import { FilterContextButtons, FormsContextSection } from '../../../common/components/forms';
import './AvailableCapacity.scss';
import { Page } from '../../../common/components';

import AvailableCapacityTable from './AvailableCapacityTable/AvailableCapacityTable';
import AvailableCapacityFilter from './AvailableCapacityFilter/AvailableCapacityFilter';

class AvailableCapacity extends React.PureComponent {
  static contextType = LsiContext;

  static propTypes = {
    availableCapacityResponse: PropTypes.instanceOf(DealerRestrictionResponseDTO),
    isFetching: PropTypes.bool,
    onGetAvailableCapacityList: PropTypes.func.isRequired
  };

  static defaultProps = {
    availableCapacityResponse: null,
    isFetching: true
  };

  componentDidMount() {
    this.handleReloadAvailableCapacity();
  }

  storeAlertRef = (ref) => {
    this.alert = ref;
  };
  handleReloadAvailableCapacity = async (filterData = null) => {
    const { onGetAvailableCapacityList } = this.props;
    try {
      await onGetAvailableCapacityList(filterData);
    } catch (e) {
      const lsi = this.context;
      this.alert.addAlert({
        colorSchema: 'danger',
        content: lsi.getLSIItem('PVT.ERROR_MESSAGE.GET_DEALER_RESTRICTIONS_FAILURE')
      });
    }
  };

  render() {
    const lsi = this.context;
    const { isFetching, availableCapacityResponse } = this.props;
    return (
      <Page
        className={'AvailableCapacity'}
        header={lsi.getLSIItem('PVT.PAGE_TITLE.AVAILABLE_CAPACITY')}
        loading={isFetching}
      >
        <FormsContextSection>
          <AvailableCapacityFilter
            onFilterSubmit={this.handleReloadAvailableCapacity}
            weekLimits={availableCapacityResponse?.weekLimits}
          />
          <Bricks.Div className={'ovex-AvailableCapacityButtonBar'}>
            <FilterContextButtons/>
          </Bricks.Div>
          <AvailableCapacityTable
            restrictionList={availableCapacityResponse?.restrictionList}
            weekDetailList={availableCapacityResponse?.weekDetailList}
          />
        </FormsContextSection>
        <Bricks.AlertBus
          hidden
          ref_={this.storeAlertRef}
        />
      </Page>
    );
  };
}

export default AvailableCapacity;