import React from 'react';
import { Link } from 'react-router-dom';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import webModuleRegister from '../../webModuleRegister';
import { logo } from '../../assets/images';
import { LsiContext } from '../../packages/common/contexts';
import { default as PATHS } from '../../routes/paths';

import UserMenu from './UserMenu/UserMenu';

import './NavBar.scss';

const NavBar = () => {
  const lsi = React.useContext(LsiContext);
  const moduleNavMenuList = webModuleRegister.getNavMenuDataList().map(NavMenuData => (<NavMenuData.component key={NavMenuData.component.name} />));

  return (
    <Bricks.Div
      className='ovex-navbar'
    >
      <Bricks.Div
        className='ovex-navbar-app'
      >
        <Link
          to={PATHS.ROUTE_PATH_ROOT}
        >
          <Bricks.Image
            height='40px'
            src={logo}
          />
        </Link>
        <Bricks.Div
          className='ovex-navbar-app-name'
          content={lsi.getLSIItem('APP_NAME')}
        />
      </Bricks.Div>
      <Bricks.Div
        className='ovex-navbar-app-menu'
      >
        {moduleNavMenuList}
      </Bricks.Div>
      <Bricks.Div
        className='ovex-navbar-user-menu'
      >
        <UserMenu />
      </Bricks.Div>
    </Bricks.Div>
  );
};

export default NavBar;
