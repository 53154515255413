import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';
import { AnnualTargetYearATSalesPeriodDTO, NegotiationATSalesPeriodDTO, NegotiationATPeriodDTO } from '@ovex/annual-target-web-api';

import { LsiContext } from '../../../common/contexts';

import QuarterPeriodCheckbox from './QuarterPeriodCheckbox';
import QuarterPeriodNumber from './QuarterPeriodNumber';

import './QuarterComponentForm.scss';

const DefaultValues = {
};

const propTypes = {
  atPeriodList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationATPeriodDTO)),
  atSalesPeriodList: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.instanceOf(AnnualTargetYearATSalesPeriodDTO),
    PropTypes.instanceOf(NegotiationATSalesPeriodDTO)
  ])).isRequired,
  hiddenATPeriodCorrection: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  readOnlyMinAchievementRatio: PropTypes.bool,
  required: PropTypes.bool
};

const defaultProps = {
  atPeriodList: null,
  hiddenATPeriodCorrection: false,
  label: null,
  name: null,
  parent: null,
  readOnly: false,
  readOnlyMinAchievementRatio: false,
  required: false,
  updateModels: null
};

const periods = [1, 2, 3, 4];

const QuarterComponentForm = (props) => {
  const lsi = React.useContext(LsiContext);
  const classNames = ['ovex-form-QuarterComponentForm'];

  const handleUpdateRoc = (val) => {
    props.onUpdate(val, 'ROC');
  };

  const handleUpdateRocCorrection = (val) => {
    props.onUpdate(val, 'CORR');
  };

  const handleUpdateRcp = (val) => {
    props.onUpdate(val, 'RCP');
  };

  // first column of the first row must be non empty otherwise column will not be rendered because of not filling div content
  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      <Bricks.Row>
        <Bricks.Column
          className='uu5-forms-label label'
          colWidth='xs4'
        >
          <span className={'hidden'}>_</span>
        </Bricks.Column>
        { periods.map(periodNumber => {
          return (
            <Bricks.Column
              className='uu5-forms-label label'
              colWidth='xs2'
              key={periodNumber}
            >
              {`${lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.PERIOD')} ${periodNumber}.`}
            </Bricks.Column>
          );
        })
        }
      </Bricks.Row>
      <QuarterPeriodCheckbox
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.ROC_NEGOTIATION')}
        name={'ROC'}
        onUpdate={handleUpdateRoc}
        readOnly={props.readOnly}
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.AT_PERIOD_NEGOTIABLE')}
        value={props.atPeriodList}
      />
      { !props.hiddenATPeriodCorrection &&
        <QuarterPeriodCheckbox
          correctionMode
          label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.ROC_CORRECTION')}
          name={'CORR'}
          onUpdate={handleUpdateRocCorrection}
          readOnly={props.readOnly}
          tooltip={lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.AT_PERIOD_CORRECTION')}
          value={props.atPeriodList}
        />
      }
      <QuarterPeriodCheckbox
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.RCP_NEGOTIATION')}
        name={'RCP'}
        onUpdate={handleUpdateRcp}
        readOnly={props.readOnly}
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.AT_SALES_PERIOD_NEGOTIABLE')}
        value={props.atSalesPeriodList}
      />
      <QuarterPeriodNumber
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.RCP_PROPOSALS')}
        name={'RCP_ACHIEVEMENT_RATIO'}
        parent={props.parent}
        readOnly={props.readOnly || props.readOnlyMinAchievementRatio}
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.AT_SALES_PERIOD_MIN_ACHIEVEMENT_RATIO')}
        value={props.atSalesPeriodList}
      />
    </Bricks.Div>
  );
};

QuarterComponentForm.DefaultValues = DefaultValues;

QuarterComponentForm.propTypes = propTypes;
QuarterComponentForm.defaultProps = defaultProps;

export default QuarterComponentForm;
