import { FORMATTER_CODES, LOCALE } from '../../../../utils/consts';

export const getFormatters = (onGetLocale) => () => {
  const locale = onGetLocale?.();

  if (locale != null && INTL_FORMATTERS[locale]) {
    return INTL_FORMATTERS[locale];
  }
  return INTL_FORMATTERS[LOCALE.cs];
};

/**
 * Function prepares formatters for given locale
 *
 * @param {string} locale
 * @return {object}
*/
const prepareIntlFormatters = (locale) => {
  return {
    /**
     * Number formatters
     */
    [FORMATTER_CODES.NUMBER]: new Intl.NumberFormat(locale, { minimumFractionDigits: 0, maximumFractionDigits: 5 }), // 0-5 decimal spaces
    [FORMATTER_CODES.NUMBER_0_DECIMALS]: new Intl.NumberFormat(locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 }), // 0 decimal spaces
    [FORMATTER_CODES.NUMBER_2_DECIMALS]: new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), // 2 decimal spaces
    /**
     * Percent formatters
     */
    [FORMATTER_CODES.PERCENT]: new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 5 }), // 0-5 decimal spaces
    [FORMATTER_CODES.PERCENT_0_DECIMALS]: new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }), // 0 decimal spaces
    [FORMATTER_CODES.PERCENT_2_DECIMALS]: new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }), // 2 decimal spaces
    /**
     * Date formatters
     */
    [FORMATTER_CODES.DATE]: new Intl.DateTimeFormat(locale, { ...DTO.DATE__YYYY_MM_DD }),
    /**
     * Time formatters
     */
    [FORMATTER_CODES.TIME_MINUTES]: new Intl.DateTimeFormat(locale, { ...DTO.TIME__HH_MM }),
    [FORMATTER_CODES.TIME_SECONDS]: new Intl.DateTimeFormat(locale, { ...DTO.TIME__HH_mm_ss }),
    [FORMATTER_CODES.TIME_MILLISECONDS]: new Intl.DateTimeFormat(locale, { ...DTO.TIME__HH_mm_ss_SSS }),
    /**
     * Date time formatters
     */
    [FORMATTER_CODES.DATE_TIME_MINUTES]: new Intl.DateTimeFormat(locale, { ...DTO.DATE__YYYY_MM_DD, ...DTO.TIME__HH_MM }),
    [FORMATTER_CODES.DATE_TIME_SECONDS]: new Intl.DateTimeFormat(locale, { ...DTO.DATE__YYYY_MM_DD, ...DTO.TIME__HH_mm_ss }),
    [FORMATTER_CODES.DATE_TIME_MILLISECONDS]: new Intl.DateTimeFormat(locale, { ...DTO.DATE__YYYY_MM_DD, ...DTO.TIME__HH_mm_ss_SSS })
  };
};

/**
 * Date time options
 */
const DTO = {
  DATE__YYYY_MM_DD: { year: 'numeric', month: '2-digit', day: '2-digit' },
  TIME__HH_MM: { hour: '2-digit', minute: '2-digit' },
  TIME__HH_mm_ss: { hour: '2-digit', minute: '2-digit', second: '2-digit' },
  TIME__HH_mm_ss_SSS: { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 }
};

/**
 * Formatters prepared for each locale
 */
const INTL_FORMATTERS = Object.values(LOCALE).reduce(
  (obj, locale) => {
    obj[locale] = prepareIntlFormatters(locale);
    return obj;
  },
  {}
);