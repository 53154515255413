import React from 'react';

import CommissionListConnector from '../CommissionList/CommissionListConnector';
import { handleGetCommissionListForDealer } from '../../redux/modules/actions';

const CommissionListDealerConnector = () => {

  return (
    <CommissionListConnector
      onGetCommissionListReduxFn={handleGetCommissionListForDealer}
      titleLsiCode='COMMISSION_LIST_DEALER'
    />
  );
};

export default CommissionListDealerConnector;
