const COLUMN_IDS = {
  MIN_VALUE: 'MIN_VALUE',
  DEALER: 'DEALER',
  MODEL_GROUP: 'MODEL_GROUP',
  ACTUAL_VALUE: 'ACTUAL_VALUE'
};

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const getColumnDefinitions = (lsi) => {
  return [
    {
      colId: COLUMN_IDS.DEALER,
      valueGetter: 'data && data.DEALER',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DEALER'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      minWidth: 110,
      width: 110,
      sortable: true,
      filter: true,
      filterParams: { newRowsAction: 'keep' },
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
    },
    {
      colId: COLUMN_IDS.MODEL_GROUP,
      valueGetter: 'data && data.MODEL_GROUP',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      minWidth: 90,
      width: 90,
      sortable: true,
      filter: true,
      filterParams: { newRowsAction: 'keep' },
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
    },
    {
      colId: COLUMN_IDS.ACTUAL_VALUE,
      valueGetter: 'data && data.ACTUAL_VALUE',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.ACTUAL_VALUE'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      minWidth: 150,
      width: 150
    },
    {
      colId: COLUMN_IDS.MIN_VALUE,
      valueGetter: 'data && data.MIN_VALUE',
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MIN_VALUE'),
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      minWidth: 160,
      width: 160
    }
  ];
};
