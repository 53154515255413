/* eslint-disable no-unreachable */
import { ClaimTypeHeaderEnum, ConditionResultHeaderEnum } from '../../../../../utils/const';

class AbstractRowUIDefinition {

  /** @abstract */
  getCode = () => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  requiredStateValueGetter = ({ data, context }) => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  requiredStateValueFormatter = ({ data, context, value }) => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  currentStateValueGetter = ({ data, context }) => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  currentStateValueFormatter = ({ data, context, value }) => {
    throw new Error('Function not implemented!');
    return null;
  }
  /** @abstract */
  currentStateCellClass = ({ data, context }) => {
    const { commission } = context;

    switch (data.result) {
      case ConditionResultHeaderEnum.OK:
        return commission.claimTypeHeader === ClaimTypeHeaderEnum.CONFIRMED
          ? 'ovex-pg-ValidationResultHeaderTable-validationResult--ok-sent'
          : 'ovex-pg-ValidationResultHeaderTable-validationResult--ok';
      case ConditionResultHeaderEnum.REPAIRABLE:
        return 'ovex-pg-ValidationResultHeaderTable-validationResult--repairable';
      case ConditionResultHeaderEnum.NOT_REPAIRABLE:
        return 'ovex-pg-ValidationResultHeaderTable-validationResult--notRepairable';
      default:
        return null;
    }
  }
}

export default AbstractRowUIDefinition;