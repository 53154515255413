import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { LsiContext } from '../../../common/contexts';

import { getPeriodInputName, periodSorter, parsePeriodListFromInputValues } from './Periods.utils';
import Period from './Period';

import './Periods.scss';

const PeriodCountDefault = 4;

const propTypes = {
  className: PropTypes.string,
  hiddenNegotiable: PropTypes.bool,
  name: PropTypes.string,
  parent: PropTypes.object,
  periodCount: PropTypes.number,
  readOnly: PropTypes.bool,
  readOnlyMinAchievementRatio: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    minAchievementRatio: PropTypes.number,
    negotiable: PropTypes.bool,
    unitOrder: PropTypes.number.isRequired
  }))
};

const defaultProps = {
  className: null,
  hiddenNegotiable: false,
  name: null,
  parent: null,
  periodCount: PeriodCountDefault,
  readOnly: false,
  readOnlyMinAchievementRatio: false,
  required: false,
  value: null
};

const PeriodsComponent = (props) => {
  const lsi = React.useContext(LsiContext);

  const classNames = ['uu5-forms-input uu5-forms-input-m ovex-form-Periods'];
  props.readOnly && classNames.push('uu5-forms-input-read-only');
  props.className && classNames.push(props.className);

  let periods = props.value != null ? props.value : [];
  periods.sort(periodSorter);
  if (!props.readOnly) {
    const periodCount = props.periodCount != null && props.periodCount > 0 ? props.periodCount : PeriodCountDefault;

    if (periodCount <= periods.length) {
      periods = props.value.slice(0, periodCount);
    } else {
      const emptyPeriodMapper = (v, i) => ({
        unitOrder: periods.length + i + 1,
        minAchievementRatio: Period.DefaultValues.minAchievementRatio,
        negotiable: Period.DefaultValues.negotiable
      });
      periods = [
        ...periods,
        ...Array.from({ length: periodCount - periods.length }, emptyPeriodMapper)
      ];
    }
  }

  const renderPeriods = () => {
    return periods.map((period) => {
      const periodNumber = period.unitOrder;
      const name = getPeriodInputName(props.name, periodNumber);
      return (
        <Period
          hiddenNegotiable={props.hiddenNegotiable}
          key={name}
          label={`${lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.PERIOD')} ${periodNumber}.`}
          name={name}
          parent={props.parent}
          periodNumber={periodNumber}
          readOnly={props.readOnly}
          readOnlyMinAchievementRatio={props.readOnlyMinAchievementRatio}
          value={{
            minAchievementRatio: period.minAchievementRatio,
            negotiable: period.negotiable,
            id: period.id
          }}
        />
      );
    });
  };

  return (
    <Bricks.Div
      className={classNames.join(' ')}
    >
      <Bricks.Div
        className='uu5-forms-label uu5-col-xl12'
      >
        {lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.RCP')}
        <Bricks.Icon
          className='uu5-forms-label-tooltip'
          icon='mdi-information-outline'
          tooltip={lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.AT_SALES_PERIOD_MIN_ACHIEVEMENT_RATIO')}
        />
      </Bricks.Div>
      <div
        className='uu5-forms-input-wrapper uu5-col-xl12'
      >
        {renderPeriods()}
      </div>
    </Bricks.Div>
  );
};

const Periods = React.memo(PeriodsComponent);

PeriodsComponent.propTypes = propTypes;
PeriodsComponent.defaultProps = defaultProps;

Periods.parsePeriodListFromInputValues = parsePeriodListFromInputValues;

export default Periods;
