import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class QRUpdateRestrictionBulkFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PVT_PC__UPDATE_PRODUCTION_CORRIDOR_BULK);
  }

}

export default QRUpdateRestrictionBulkFunctionalityContext;