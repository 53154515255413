import React from 'react';

import SwapCommissionPvtConnector from '../SwapCommissionPvt/SwapCommissionPvtConnector';
import { handleGetCommissionSwapDealer, handleSwapCommissionsPvtDealer } from '../../redux/modules/actions';

const SwapCommissionPvtDealerConnector = () => {

  return (
    <SwapCommissionPvtConnector
      onGetCommissionListAction={handleGetCommissionSwapDealer}
      onSwapCommissionAction={handleSwapCommissionsPvtDealer}
    />
  );
};

export default SwapCommissionPvtDealerConnector;