import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const viewsApi = ApiServiceInstances.annualTarget.viewsApi;

export const GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_REQUEST = 'GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_REQUEST';
export const GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_SUCCESS = 'GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_SUCCESS';
export const GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_FAILURE = 'GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_FAILURE';

export const GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_REQUEST = 'GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_REQUEST';
export const GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_SUCCESS = 'GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_SUCCESS';
export const GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_FAILURE = 'GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_FAILURE';

const initialState = {
  contractATModelGroupsViewList: null,
  branchATSPeriodsViewList: null,
  filterData: null,
  columnDataList: null,
  valuesFilter: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_REQUEST:
    case GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_SUCCESS: {
      const responseData = action.payload;
      const metaData = action.metaData;

      return {
        ...state,
        isFetching: false,
        dealerList: action.payload,
        contractATModelGroupsViewList: responseData.response,
        filterData: responseData.filterData,
        columnDataList: responseData.columnData,
        valuesFilter: metaData.valuesFilter
      };
    }
    case GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_SUCCESS: {
      const responseData = action.payload;
      const metaData = action.metaData;

      return {
        ...state,
        isFetching: false,
        dealerList: action.payload,
        branchATSPeriodsViewList: responseData.response,
        filterData: responseData.filterData,
        columnDataList: responseData.columnData,
        valuesFilter: metaData.valuesFilter
      };
    }
    case GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_FAILURE:
    case GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetDealerContractsATModelGroupsViewList = (opts) => {
  return {
    [CALL_API]: {
      apiFunction: () => viewsApi.getDealerContractsATModelGroupsViewListWithHttpInfo(opts),
      types: [GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_REQUEST, GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_SUCCESS, GET_DEALER_CONTRACTS_AT_MODEL_GROUPS_VIEW_LIST_FAILURE],
      metaData: { valuesFilter: opts }
    }
  };
};

export const handleGetDealerContractsATSPeriodsViewList = (opts) => {
  return {
    [CALL_API]: {
      apiFunction: () => viewsApi.getDealerContractsATSPeriodsViewListWithHttpInfo(opts),
      types: [GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_REQUEST, GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_SUCCESS, GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_FAILURE],
      metaData: { valuesFilter: opts }
    }
  };
};

