import convertToType from '../convertToType';

import AlertItem from './AlertItem';

/**
 * The Alert model module.
 * @module ovex/main/Alert
 */
class Alert {

  /**
   * Constructs a new <code>Alert</code>.
   *
   * @alias module:ovex/main/Alert
   * @param uuid {String}
   * @param alertItemList {Array.<module:ovex/main/AlertItem>} List that must contain at least one item!
   */
  constructor(uuid, alertItemList) {

    Alert.initialize(this, uuid, alertItemList);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, uuid, alertItemList) {
    obj['uuid'] = uuid;
    obj['alertItemList'] = alertItemList;
  }

  /**
   * Constructs a <code>Alert</code> from a plain JavaScript object.
   * Copies all relevant properties from <code>data</code> to a new instance of <code>Alert</code>.
   *
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   *
   * @return {module:ovex/main/Alert} The populated <code>Alert</code> instance.
   */
  static constructFromObject(data) {
    if (data) {
      const obj = new Alert(null, null);

      if (data.hasOwnProperty('uuid')) {
        obj['uuid'] = convertToType(data['uuid'], 'String');
      }
      if (data.hasOwnProperty('errorList')) {
        obj['alertItemList'] = convertToType(data['errorList'], [AlertItem]);
      } else if (data.hasOwnProperty('alertItemList')) {
        obj['alertItemList'] = convertToType(data['alertItemList'], [AlertItem]);
      }

      return obj;
    }
    return null;
  }

}

/**
 * @member {String} uuid
 */
Alert.prototype.uuid = undefined;

/**
 * List that must contain at least one item!
 * @member {Array.<module:ovex/main/AlertItem>} alertItemList
 */
Alert.prototype.alertItemList = undefined;

export default Alert;

