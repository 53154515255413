import { isString, parseNumber } from '../../../../../common/utils/helpers';
import { percent2Formatter, quarterPeriodFormatter } from '../../../../../common/utils/formatters';
import { decimalToPercent, percentToDecimal } from '../../../../utils/helpers';
import { YEAR_SHIFT_CURRENT_YEAR } from '../NegotiationModalFrom.consts';

export const isEditableProdCapacityCell = (agParams) => {
  if (agParams.context.readOnly) {
    return false;
  }

  const periodColumnContext = agParams.colDef.ovexContext;
  if (periodColumnContext.yearShift === YEAR_SHIFT_CURRENT_YEAR) {
    const period = agParams.context.atPeriodList.find(p => p.unitOrder === periodColumnContext.unitOrder);
    return period.negotiable && agParams.data.negotiable;
  }

  return true;
};

export const isEditableNegotiation = (agParams) => {
  return agParams.data.negotiable && !agParams.context.readOnly;
};

export const editableProdCapacityValueGetter = (valueGetterParams) => {
  const value = valueGetterParams.node.data.getValue(valueGetterParams.colDef.ovexContext);
  return value != null
    ? decimalToPercent(value, 2)
    : value;
};

export const productionCapacityTotalValueGetter = (yearShift) => (valueGetterParams) => {
  const value = valueGetterParams.data.getTotal(yearShift);
  return value != null
    ? decimalToPercent(value, 2)
    : value;
};

export const productionCapacityTotalTooltipValueGetter = (yearShift) => (tooltipParams) => {
  if (tooltipParams.location === 'cell') {
    const lsi = tooltipParams.context.agLsi;

    if (!tooltipParams.data.isTotalValid(yearShift)) {
      return yearShift === YEAR_SHIFT_CURRENT_YEAR
        ? lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.AT_PERIOD_MODEL_CURRENT_YEAR_INVALID_TOTAL')
        : lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.AT_PERIOD_MODEL_FOLLOWING_YEARS_INVALID_TOTAL');
    }
  }
  return null;
};

export const editableProdCapacityValueSetter = (valueSetterParams) => {
  if (isString(valueSetterParams.newValue)) {
    valueSetterParams.newValue = editableProdCapacityValueParser(valueSetterParams);
  }
  if (valueSetterParams.newValue != null && valueSetterParams.newValue !== valueSetterParams.oldValue) {
    const newValue = percentToDecimal(valueSetterParams.newValue, 4);
    valueSetterParams.node.data.setValue(newValue, valueSetterParams.colDef.ovexContext);
    return true;
  }
  return false;
};

export const editableProdCapacityValueParser = (valueParserParams) => {
  if (isString(valueParserParams.newValue)) {
    return /^[0-9]{0,4}$|^[0-9]{0,4}[.,][0-9]{1,2}$/.test(valueParserParams.newValue)
      ? parseNumber(valueParserParams.newValue.replace(',', '.'))
      : null;
  }
  return valueParserParams.newValue;
};

export const handleModelMixRatioValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  return percent2Formatter(valueFormatterParams.value, formatters);
};

export const periodHeaderValueGetter = ({ colDef }) => quarterPeriodFormatter(colDef.ovexContext.unitOrder);

export const negotiationValueGetter = (agParams) => {
  return agParams.node.data.negotiable;
};

export const handleNegotiableValueSetter = (valueSetterParams) => {
  if (valueSetterParams.newValue != null) {
    valueSetterParams.data.setNegotiable(valueSetterParams.newValue);
    if (!valueSetterParams.newValue) {
      valueSetterParams.data.productionCapacityATPeriodList.forEach(pc => pc.productionCapacityRatio = null);
      valueSetterParams.api.refreshCells({ columns: valueSetterParams.context.periodColumnIdsToRefresh, force: true });
    }
    return true;
  }
  return false;
};

export const productionCapacityValueFormatter = (valueFormatterParams) => {
  const formatters = valueFormatterParams.context.agLsi.getFormatters();
  const decimal = valueFormatterParams.value != null
    ? percentToDecimal(valueFormatterParams.value, 4)
    : valueFormatterParams.value;
  return percent2Formatter(decimal, formatters);
};

export const updateProductionCapacityRatio = (rows, atPeriodList) => {
  const nonNegotiablePeriodList = atPeriodList.filter(at => !at.negotiable).map(at => at.unitOrder);
  const somePeriodNegotiable = nonNegotiablePeriodList.length !== atPeriodList.length;

  rows.forEach(mg => {
    mg.negotiable = somePeriodNegotiable;

    mg.productionCapacityATPeriodList
      .filter(pc => pc.yearShift === YEAR_SHIFT_CURRENT_YEAR && nonNegotiablePeriodList.includes(pc.unitOrder))
      .forEach(pc => pc.productionCapacityRatio = null);
  });
};
