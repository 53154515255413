import { dateFormatter, simpleNullishValueFormatter } from '../../../../../../../common/utils/formatters';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class InvoicingDateRUID extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.INVOICING_DATE__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.INVOICING_DATE');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.INVOICING_DATE');
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    return null;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    return '-';
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { commission } = context;

    return commission.commissionCurrentData.invoicingDate;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;

    return simpleNullishValueFormatter(dateFormatter(value, agLsi.getFormatters()), '-');
  }
}

const invoicingDateRUID = new InvoicingDateRUID();

export default invoicingDateRUID;