import {
  InputNamesHeader,
  InputNamesDealerList,
  InputNamesModelGroupList
} from './NegotiationFormInputNames';

/**
 * method that handles editable inputs for AT Negotiation modal
 * @param negotiationRowData {object} row data of clicked Negotiation/ if not present then creation mode
 * @returns {object} object with editable inputs
 */
export const handleEditableInputs = (negotiationRowData= null) => {
  if (!negotiationRowData) {
    return [
      InputNamesHeader.name,
      InputNamesHeader.proposalApproval,
      InputNamesHeader.proposalDealer,
      InputNamesHeader.quarters,
      InputNamesDealerList.dealerList,
      InputNamesModelGroupList.modelGroupList
    ];
  } else if (negotiationRowData?.isNegotiationCreated()) {
    return [
      InputNamesHeader.name,
      InputNamesHeader.proposalApproval,
      InputNamesHeader.proposalDealer,
      InputNamesHeader.quarters,
      InputNamesDealerList.dealerList,
      InputNamesModelGroupList.modelGroupList
    ];
  } else if (negotiationRowData?.isNegotiationActivated()) {
    return [
      InputNamesHeader.name,
      InputNamesHeader.proposalApproval,
      InputNamesHeader.proposalDealer
    ];
  }
};
/**
 * Uses editRules, if not present, then uses global read only property
 * @param editRules {array} from {@link handleEditableInputs} function
 * @param inputName {string}
 * @param readOnly {boolean} is readOnly
 * @returns {boolean|boolean}
 */
export const isReadOnlyInput = (editRules, inputName, readOnly) => {
  return editRules ? !editRules.includes(inputName) : readOnly;
};