import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import './Modal.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'auto', 'max']),
  stickyBackground: PropTypes.bool
};

const defaultProps = {
  className: null,
  header: null,
  size: 's',
  stickyBackground: true
};

const Modal = React.memo((props) => {

  const classNames = ['ovex-Modal'];
  props.className && classNames.push(props.className);

  return (
    <Bricks.Modal
      className={classNames.join(' ')}
      forceRender
      header={props.header}
      onClose={props.onClose}
      overflow
      shown={props.shown}
      size={props.size}
      stickyBackground={props.stickyBackground}
    >
      {props.children}
    </Bricks.Modal>
  );
});

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;