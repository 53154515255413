import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonBar, Modal } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';

const propTypes = {
  commissionA: PropTypes.object,
  commissionB: PropTypes.object,
  onClose: PropTypes.func,
  shown: PropTypes.bool
};
const defaultProps = {
  commissionA: null,
  commissionB: null,
  onClose: PropTypes.func,
  shown: false
};

const SwapCommissionResultModal = (props) => {
  const { commissionA, commissionB } = props;
  const lsi = useContext(LsiContext);

  return (
    <Modal
      className='ovex-pvt-SwapCommissionResultModal'
      header={lsi.getLSIItem('PVT.MODAL_HEADER.SWAP_COMMISSION_PVT')}
      onClose={props.onClose}
      shown={props.shown}
      size={'m'}
    >
      <p>
        {lsi.getLSIItem('PVT.CONFIRM_MESSAGE.SWAP_COMMISSION_SUCCESS')}
      </p>
      <p>
        {lsi.getLSIItem('PVT.CONFIRM_MESSAGE.SWAP_COMMISSION_SUCCESS_DETAIL', [commissionA.commission, commissionB.pvt, commissionA.pvt])}
      </p>
      <p>
        {lsi.getLSIItem('PVT.CONFIRM_MESSAGE.SWAP_COMMISSION_SUCCESS_DETAIL', [commissionB.commission, commissionA.pvt, commissionB.pvt])}
      </p>
      <ButtonBar
        align={ButtonBar.align.center}
        formControlsBar
      >
        <Button
          className='ovex-pvt-SwapCommissionResultModal-btn-ok'
          content={lsi.getLSIItem('PVT.BUTTON.OK')}
          onClick={props.onClose}
          type={Button.type.PRIMARY}
        />
      </ButtonBar>
    </Modal>
  );
};

SwapCommissionResultModal.propTypes = propTypes;
SwapCommissionResultModal.defaultProps = defaultProps;
export default SwapCommissionResultModal;