import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleGetDealerContractsATSPeriodsViewList } from '../../redux/modules/actions';

import DealerContractsATSPeriodsView from './DealerContractsATSPeriodsView';

const DealerContractsATSPeriodsViewConnector = () => {

  const selectedState = useSelector(state => {
    return {
      branchATSPeriodsViewList: state.at.views.branchATSPeriodsViewList,
      filterData: state.at.views.filterData,
      columnDataList: state.at.views.columnDataList,
      isFetching: state.at.views.isFetching,
      valuesFilter: state.at.views.valuesFilter
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        handleGetDealerContractsATSPeriodsViewList: (filterParams) => dispatch(handleGetDealerContractsATSPeriodsViewList(filterParams))
      };
    },
    [dispatch]
  );

  return (
    <DealerContractsATSPeriodsView
      branchATSPeriodsViewList={selectedState.branchATSPeriodsViewList}
      columnDataList={selectedState.columnDataList}
      filterData={selectedState.filterData}
      isFetching={selectedState.isFetching}
      onGetDealerContractsATSPeriodsViewList={callbacks.handleGetDealerContractsATSPeriodsViewList}
      valuesFilter={selectedState.valuesFilter}
    />
  );
};

export default DealerContractsATSPeriodsViewConnector;