import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class ShiftPriceGuaranteeStatusPFC extends AbstractFunctionalityContext {

  /**
   * @param {number} priceGuaranteeId
   * @param {string} priceGuaranteeStatusTo
   */
  constructor(priceGuaranteeId, priceGuaranteeStatusTo) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PG__SHIFT_PRICE_GUARANTEE_STATUS);
    this.setParams({
      [ContextConstants.PRICE_GUARANTEE_ID]: priceGuaranteeId,
      [ContextConstants.PRICE_GUARANTEE_STATUS_TO]: priceGuaranteeStatusTo
    });
  }

}

export default ShiftPriceGuaranteeStatusPFC;