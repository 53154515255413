import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Code, FilterForm } from '../../../../common/components/forms';
import { LsiContext } from '../../../../common/contexts';

const propTypes = {
  commissionNumber: PropTypes.string,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const defaultProps = {
  commissionNumber: null
};

const InputNames = {
  commissionNumber: 'commissionNumber'
};

const SwapCommissionPvtParams = (props) => {
  const lsi = useContext(LsiContext);

  const { commissionNumber, onReset, onSubmit } = props;

  const handleSubmit = React.useCallback(
    (formValues) => {
      onSubmit({
        commissionNumber: formValues[InputNames.commissionNumber]
      });
    },
    [onSubmit]
  );

  return (
    <FilterForm
      className='ovex-pvt-SwapCommissionPvtParams'
      onReset={onReset}
      onSubmit={handleSubmit}
    >
      <Code
        label={lsi.getLSIItem('PVT.FORM_INPUT_LABEL.COMMISSION')}
        name={InputNames.commissionNumber}
        required
        value={commissionNumber}
        width='160px'
      />
    </FilterForm>
  );
};

SwapCommissionPvtParams.propTypes = propTypes;
SwapCommissionPvtParams.defaultProps = defaultProps;

export default SwapCommissionPvtParams;