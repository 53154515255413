import { getBaseUri } from '../../../utils/uri';

/**
 * Static function for set parameters to all APIs
 */
class ApisSettings {

  /**
   * @param {Array.<Object>} apiClients
   */
  static removeDefaultInstance(apiClients) {
    if (!Array.isArray(apiClients)) {
      return;
    }
    apiClients.forEach(apiClient => {
      if (apiClient != null) {
        apiClient.instance = undefined;
      }
    });
  }

  /**
   * @param {Array.<Object>} apiClientInstances
   * @param {string} basePath
   */
  static setBasePath(apiClientInstances, basePath) {
    if (!Array.isArray(apiClientInstances)) {
      return;
    }
    apiClientInstances.forEach(apiClientInstance => {
      if (apiClientInstance != null) {
        apiClientInstance.basePath = getBaseUri() + basePath;
      }
    });
  }

  /**
   * @param {Array.<Object>} apiClientInstances
   * @param {Object} plugins
   */
  static setPlugin(apiClientInstances, plugins) {
    if (!Array.isArray(apiClientInstances)) {
      return;
    }
    apiClientInstances.forEach(apiClientInstance => {
      if (apiClientInstance != null) {
        apiClientInstance.plugins = plugins;
      }
    });
  }
}

export default ApisSettings;