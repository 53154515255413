import React from 'react';
import PropTypes from 'prop-types';
import { PriceGuaranteeDTOPGR } from '@ovex/price-guarantee-web-api';

import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { FormsContextSection } from '../../../common/components/forms';
import { useAlertBusOvex } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';
import { CLAIM_TYPE_HEADER_ENUM_LIST } from '../../utils/const';
import PriceGuaranteeReportFilter from '../PriceGuaranteeReport/PriceGuaranteeReportFilter/PriceGuaranteeReportFilter';

import PriceGuaranteeReportExportButtonBar from './ButtonBar/PriceGuaranteeReportExportButtonBar';

import './PriceGuaranteeReportExport.scss';

const propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onExportPriceGuaranteeReport: PropTypes.func.isRequired,
  onGetPriceGuaranteeList: PropTypes.func.isRequired,
  onResetPriceGuaranteeReport: PropTypes.func.isRequired,
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTOPGR)),
  titleLsiCode: PropTypes.string.isRequired
};

const defaultProps = {
  onShowCommissionValidator: null,
  priceGuaranteeList: null
};

const PriceGuaranteeReportExport = (props) => {
  const lsi = React.useContext(LsiContext);
  const {
    isFetching, titleLsiCode,
    priceGuaranteeList,
    onGetPriceGuaranteeList, onExportPriceGuaranteeReport, onResetPriceGuaranteeReport
  } = props;

  const { handleAddAlertSimple } = useAlertBusOvex();

  React.useEffect(
    () => {
      try {
        onGetPriceGuaranteeList();
      } catch (e) {
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_LIST_PGR_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetPriceGuaranteeList, handleAddAlertSimple]
  );

  const handleExportToExcel = React.useCallback(
    async (filterValues) => {
      // TODO OS/PK - export call
      try {
        await onGetPriceGuaranteeList();
        await onExportPriceGuaranteeReport(filterValues);
      } catch (e) {
        handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.GET_PRICE_GUARANTEE_REPORT_PGR_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [onGetPriceGuaranteeList, onExportPriceGuaranteeReport, handleAddAlertSimple]
  );

  return (
    <Page
      className='ovex-pg-PriceGuaranteeReportExport'
      header={lsi.getLSIItem(`PRICE_GUARANTEE.PAGE_TITLE.${titleLsiCode}`)}
      loading={isFetching}
    >
      <FormsContextSection>
        <PriceGuaranteeReportFilter
          claimTypeHeaderList={CLAIM_TYPE_HEADER_ENUM_LIST}
          onLoadPriceGuaranteeReport={handleExportToExcel}
          onResetPriceGuaranteeReport={onResetPriceGuaranteeReport}
          priceGuaranteeList={priceGuaranteeList}
        />
        <PriceGuaranteeReportExportButtonBar />
      </FormsContextSection>
    </Page>
  );
};

PriceGuaranteeReportExport.propTypes = propTypes;
PriceGuaranteeReportExport.defaultProps = defaultProps;

export default PriceGuaranteeReportExport;
