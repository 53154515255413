import { groupByValue } from '../../../../common/utils/arrays';
import { quarterPeriodFormatter } from '../../../../common/utils/formatters';

const WEEK_REGEXP = /^(\d{4})[Ww](\d{2})$/;

const WeekStructureEnum = {
  YEAR: 'YEAR',
  QUARTER: 'QUARTER',
  MONTH: 'MONTH'
};

const LAST_MONTHS_OF_QUARTER = [3, 6, 9, 12]; // number of months

/**
 * @param {Array.<model/WeekDetailDTO>} weekDetailList
 * @return {Array.<Object>} returns array of objects or empty array
 */
export const convertWeekDetailListToViewStructure = (weekDetailList) => {
  _markImportantWeeksOfQuarterAndMonth(weekDetailList);

  const yearGroups = groupByValue(weekDetailList || [], i => i.year);
  const viewStructure = _reconstructArray(yearGroups, WeekStructureEnum.YEAR, null, null);

  viewStructure.forEach(yearObj => {
    const quarterGroups = groupByValue(yearObj.subItemList || [], i => i.quarter);
    yearObj.subItemList = _reconstructArray(quarterGroups, WeekStructureEnum.QUARTER, yearObj.year, null);

    yearObj.subItemList.forEach(quarterObj => {
      const monthGroups = groupByValue(quarterObj.subItemList || [], i => i.month);
      quarterObj.subItemList = _reconstructArray(monthGroups, WeekStructureEnum.MONTH, quarterObj.year, quarterObj.quarter);
    });
  });

  return viewStructure;
};

/**
 * Převede týden ve formátu **yyyyWww** na formát zobrazovaný v hlavičce tabulky **ww/yy**
 * Příklad: 2019W35 -> 35/19
 *
 * @param {String} weekDate
 * @return {String}
 */
export const weekTableHeaderName = (weekDate) => {
  return weekDate.replace(/^\d{2}(\d{2})[Ww](\d{2})$/, '$2/$1');
};

export const isWeekColumn = (week) => {
  return WEEK_REGEXP.test(week);
};

/**
 *
 * @param group {Object} - group of years, quarters, or months
 * @param year {number|null} - year value
 * @param quarter {number|null} - quarter value
 * @param type {WeekStructureEnum}
 * @return {Object}
 * @private
 */
const _reconstructArray = (group, type, year, quarter) => {
  return Object.keys(group).map(key => {
    const list = group[key]; // key can be a year, quarter, or month value (number)
    const keyNumber = Number.parseInt(key);
    const structureObj = {
      type: type,
      year: type === WeekStructureEnum.YEAR ? keyNumber : year,
      quarter: type === WeekStructureEnum.QUARTER ? keyNumber : quarter,
      month: type === WeekStructureEnum.MONTH ? keyNumber : null,
      lastMontOfQuarter: type === WeekStructureEnum.MONTH ? LAST_MONTHS_OF_QUARTER.includes(keyNumber) : null,
      subItemList: list
    };
    structureObj.groupId = _makeGroupId(structureObj);
    return structureObj;
  });
};

const _makeGroupId = (weekColumnStructure) => {
  switch (weekColumnStructure.type) {
    case WeekStructureEnum.YEAR:
      return `${weekColumnStructure.year}`;
    case WeekStructureEnum.QUARTER:
      return `${weekColumnStructure.year}-${quarterPeriodFormatter(weekColumnStructure.quarter)}`;
    case WeekStructureEnum.MONTH:
      return `${weekColumnStructure.year}-${quarterPeriodFormatter(weekColumnStructure.quarter)}-${weekColumnStructure.month.toString().padStart(2, '0')}`;
    default:
      return '';
  }
};

/**
 * Function mark:
 *  - first week of months
 *  - first week of quarters
 *  - last week of months
 *  - last week of quarters
 *
 * @param weekDetailList
 * @private
 */
const _markImportantWeeksOfQuarterAndMonth = (weekDetailList) => {
  if (!Array.isArray(weekDetailList)) {
    return;
  }

  let weekDetailBefore = weekDetailList[0];

  weekDetailList.forEach(weekDetail => {
    weekDetail.firstWeekOfQuarter = weekDetailBefore.quarter !== weekDetail.quarter;
    weekDetail.firstWeekOfMonth = weekDetailBefore.month !== weekDetail.month;

    weekDetailBefore.lastWeekOfQuarter = weekDetailBefore.quarter !== weekDetail.quarter;
    weekDetailBefore.lastWeekOfMonth = weekDetailBefore.month !== weekDetail.month;

    weekDetailBefore = weekDetail;
  });
};
