import { comparePvt } from './PvtComparisonEnum';

export const getRowClass = (params) => {
  if (params.node?.rowPinned === 'top') {
    return 'ovex-pvt-SwapCommissionPvtTable-row--pinned';
  }
};

export const getRowHeight = (params) => {
  if (params.node?.rowPinned === 'top') {
    return 40;
  }
};

export const pvtPositionValueGetter = (valueGetterParams) => {
  const commissionSourcePvt = valueGetterParams.context.commissionSource.pvt;
  const commissionAdeptPvt = valueGetterParams.data.pvt;

  return {
    comparison: comparePvt(commissionAdeptPvt, commissionSourcePvt),
    commissionSourcePvt: commissionSourcePvt,
    commissionAdeptPvt: commissionAdeptPvt
  };
};
