import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class UpdateContractAnnualTargetApprovalFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} contractAtId
   */
  constructor(contractAtId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_CONTRACTS__UPDATE_CONTRACT_AT_APPROVAL);
    this.setParams({
      [ContextConstants.CONTRACT_AT_ID]: contractAtId
    });
  }

}

export default UpdateContractAnnualTargetApprovalFunctionalityContext;