import { ContractAnnualTargetRecommendedUpdateDTO } from '@ovex/annual-target-web-api';

import { AG_GROUP_TOTAL } from '../utils/const';
import { calculateDoneNumber, calculatePotentialNumber, calculateRemainsNumber } from '../../../utils/statisticsHelpers';

class ModelGroupAnnualTargetRow {

  constructor(groupValue) {
    this.groupValue = groupValue;

    this._atRecommendedNumberOriginal = null;
  }

  isTotalRow = () => this.groupValue === AG_GROUP_TOTAL;
  isPeriodRow = () => !this.isTotalRow();

  isEditable = () => this.isTotalRow();

  setRecommendedNumber = (value) => {
    this.atRecommendedNumber = value;
  };

  undoATRecommendedNumber = () => {
    this.atRecommendedNumber = this._atRecommendedNumberOriginal;
  };

  isATRecommendedNumberModified = () => {
    return this.atRecommendedNumber !== this._atRecommendedNumberOriginal && this.isTotalRow();
  };

  getATRecommendedNumberModification = () => {
    const dto = new ContractAnnualTargetRecommendedUpdateDTO(this.id, this.version);
    dto.recommendedNumber = this.atRecommendedNumber;
    return dto;
  }

  getATStatisticsDoneCount = () => {
    return calculateDoneNumber(this.atStatisticsInvoicedCount, this.atStatisticsProductionCount, this.atStatisticsPlannedCount);
  }

  getATStatisticsRemainsCount = () => {
    return calculateRemainsNumber(this.atApprovedNumber, this.getATStatisticsDoneCount());
  }

  getATStatisticsPotentialCount = () => {
    return calculatePotentialNumber(this.atStatisticsPotentialProductionCount, this.atStatisticsPotentialPlannedCount);
  }
}

ModelGroupAnnualTargetRow.prototype.groupValue = undefined; // property for ag-Grid grouping and aggregations

ModelGroupAnnualTargetRow.prototype.id = undefined;
ModelGroupAnnualTargetRow.prototype.version = undefined;
ModelGroupAnnualTargetRow.prototype.modelGroup = undefined;
ModelGroupAnnualTargetRow.prototype.modelGroupName = undefined;
ModelGroupAnnualTargetRow.prototype.negotiable = undefined;

ModelGroupAnnualTargetRow.prototype.atDealerNumber = undefined;
ModelGroupAnnualTargetRow.prototype.atRecommendedNumber = undefined;
ModelGroupAnnualTargetRow.prototype.atApprovalNumber = undefined;
ModelGroupAnnualTargetRow.prototype.atApprovedNumber = undefined;

ModelGroupAnnualTargetRow.prototype.atStatisticsInvoicedCount = undefined;
ModelGroupAnnualTargetRow.prototype.atStatisticsProductionCount = undefined;
ModelGroupAnnualTargetRow.prototype.atStatisticsPlannedCount = undefined;
ModelGroupAnnualTargetRow.prototype.atStatisticsPotentialProductionCount = undefined;
ModelGroupAnnualTargetRow.prototype.atStatisticsPotentialPlannedCount = undefined;

ModelGroupAnnualTargetRow.prototype.privilegeFunctionalityContextObject = undefined;

export default ModelGroupAnnualTargetRow;
