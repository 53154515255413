
const PvtComparisonEnum = {
  LESS: 'less',
  EQUAL: 'equal',
  GREATER: 'greater',

  isLess: (value) => PvtComparisonEnum.LESS === value,
  isEqual: (value) => PvtComparisonEnum.EQUAL === value,
  isGreater: (value) => PvtComparisonEnum.GREATER === value
};
Object.freeze(PvtComparisonEnum);

/**
 * Compare PVT
 *
 * @param {string|null} pvtA
 * @param {string|null} pvtB
 * @return {string} PvtComparisonEnum value
 */
export const comparePvt = (pvtA, pvtB) => {
  if (pvtA === pvtB) {
    return PvtComparisonEnum.EQUAL;
  } else if (pvtA < pvtB || pvtA == null) {
    return PvtComparisonEnum.LESS;
  }
  return PvtComparisonEnum.GREATER;
};

export default PvtComparisonEnum;