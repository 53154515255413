import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecPreviousColourNegCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__PREVIOUS__COLOUR_NEG;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.PREVIOUS;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.COLOUR_NEG;
  }

}

const specPreviousColourNegCUID = new SpecPreviousColourNegCUID();

export default specPreviousColourNegCUID;