import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonBar } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import { QRUpdateRestrictionBulkFunctionalityContext } from '../../../../privilege/context';
import { FilterContextButtons } from '../../../../../common/components/forms';

const qrUpdateRestrictionBulkFunctionalityContext = new QRUpdateRestrictionBulkFunctionalityContext();

class RestrictionsButtonBar extends React.PureComponent {

  static contextType = LsiContext;

  static propTypes = {
    editable: PropTypes.bool,
    onCancelEdit: PropTypes.func.isRequired,
    onEnableEdit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  static defaultProps = {
    editable: false
  };

  _renderReadButtons = () => {
    const lsi = this.context;
    const { onEnableEdit } = this.props;

    return (
      <React.Fragment>
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.EDIT')}
          key='ovex-RestrictionsButtonBar-btn-edit'
          onClick={onEnableEdit}
          privilegeFunctionalityContext={qrUpdateRestrictionBulkFunctionalityContext}
          type={Button.type.PRIMARY}
        />
      </React.Fragment>
    );
  };

  _renderEditButtons = () => {
    const lsi = this.context;
    const { onCancelEdit, onSave } = this.props;

    return (
      <React.Fragment>
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.CANCEL')}
          key='ovex-RestrictionsButtonBar-btn-cancel'
          onClick={onCancelEdit}
          type={Button.type.SECONDARY}
        />
        <Button
          content={lsi.getLSIItem('PVT.BUTTON.SAVE')}
          key='ovex-RestrictionsButtonBar-btn-save'
          onClick={onSave}
          privilegeFunctionalityContext={qrUpdateRestrictionBulkFunctionalityContext}
          type={Button.type.PRIMARY}
        />
      </React.Fragment>
    );
  };

  render() {
    const { editable } = this.props;

    return (
      <ButtonBar className='ovex-RestrictionsButtonBar'>
        {editable ? this._renderEditButtons() : this._renderReadButtons()}
        <ButtonBar.Separator />
        <FilterContextButtons disabled={editable} />
      </ButtonBar>
    );
  }
}

export default RestrictionsButtonBar;
