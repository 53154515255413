import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { FilterContextButtons } from '../../../../common/components/forms';
import { Button, ButtonBar } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';

const propTypes = {
  onConfirmAction: PropTypes.func
};

const defaultProps = {
  onConfirmAction: undefined
};

const SwapCommissionPvtButtonBar = (props) => {
  const lsi = useContext(LsiContext);

  const { onConfirmAction } = props;

  return (
    <ButtonBar
      className='ovex-pvt-SwapCommissionPvtButtonBar'
    >
      <FilterContextButtons />
      <ButtonBar.Separator />
      <Button
        content={lsi.getLSIItem('PVT.BUTTON.SWAP_COMMISSION_PVT')}
        disabled={!onConfirmAction}
        onClick={onConfirmAction}
        type={Button.type.PRIMARY}
      />
    </ButtonBar>
  );
};

SwapCommissionPvtButtonBar.propTypes = propTypes;
SwapCommissionPvtButtonBar.defaultProps = defaultProps;

export default SwapCommissionPvtButtonBar;