import { NegotiationTypeEnum } from '../../utils/const';

const SEPARATOR_CHAR = '_';
const getInputNamePrefix = (name) => name + SEPARATOR_CHAR;

/**
 * Input name for period component.
 * @param {string} name
 * @param {number} idx
 * @param {number} unitOrder
 * @return {string}
 */
export const getQuarterInputName = (name, idx = undefined, unitOrder = undefined) => {
  if (isNaN(idx) && !isNaN(unitOrder)) {
    return getInputNamePrefix(name) + SEPARATOR_CHAR + unitOrder + 'Q';
  }
  return getInputNamePrefix(name) + idx;
};

/**
 * Input name for minimal achievement ratio input
 * @param {string} name
 * @param {number} idx
 * @return {string}
 */
export const getMinAchievementRatioInputName = (name, idx) => getInputNamePrefix(name) + idx + '_minAchievementRatio';
/**
 * @param a
 * @param b
 * @return {number}
 */
export const quarterSorter = (a, b) => a.unitOrder - b.unitOrder;

/**
 * @param {string} name - quarter checkbox name
 * @return {number}
 */
export const parseQuarterId = (name) => {
  const [ , id ] = name.split(SEPARATOR_CHAR);
  return Number.parseInt(id, 10);
};

export const initializeNeg = (list, periodType, negotiationType) => {
  const result = list.map(l => ({ ...l }));
  const firstNegotiable = findFirstNegotiableValue(result);
  if (result.filter(res => res.negotiable != null).length === 0) {
    result.forEach( res => {
      res.negotiable = true;
      if (periodType === 'ROC') {
        res.correction = false;
        res.disabled = true;
      }
      if (periodType === 'RCP' && res.minAchievementRatio == null) {
        res.minAchievementRatio = 0;
      }
    });
  } else {
    const minUnitOrder = Math.min(...result.map(r => r.unitOrder));
    result.forEach( res => {
      //other values should be already initialized
      if (periodType === 'ROC') {
        if (res.correction == null) {
          res.correction = false;
        }
        res.disabled = negotiationType === NegotiationTypeEnum.CONTRACT || res.unitOrder === minUnitOrder || res.unitOrder !== firstNegotiable;
      }
    });
  }
  return result;
};

/**
 * @param {Array.<object>} oldValues unmodified values
 * @param {object} change only changed checkbox
 * @param {string} type type of changed object (ROC, CORR, RCP)
 * @param {object} negotiationType
 * @return {Array.<object>} returns modified Array
 */
export const updateNegValuesValues = (oldValues, change, type, negotiationType) => {
  let result = oldValues.map(v => ({ ...v }));
  let parsedId = parseQuarterId(change.name);
  let unitOrder = change.unitOrder;

  for (let i = 0; i < result.length; i++) {
    if (result[i].id === parsedId) {
      // first we ste negotiable we are currently clicking
      if (type === 'ROC' || type === 'RCP') {
        result[i].negotiable = change.value;
      }
      if (type === 'ROC' && (i+1) < result.length ) {
        // we will unlock correction for next quarter until the Q3
        result[i+1].disabled = negotiationType === NegotiationTypeEnum.CONTRACT || false;
      } else if (type === 'CORR' && negotiationType !== NegotiationTypeEnum.CONTRACT) {
        // stabilization of Correction editation
        result[i].correction = !result[i].correction;
      }
    }

  }

  // switching off previous quarter values
  result.filter(res => res.unitOrder < unitOrder && res.negotiable).forEach(res => {
    if (res.negotiable !== change.value) {
      res.negotiable = !res.negotiable;
    }
    if (type === 'ROC' && negotiationType !== NegotiationTypeEnum.CONTRACT) {
      res.correction = false;
    }
  });

  // set contingency of values forward
  result.filter(res => res.unitOrder > unitOrder && !res.negotiable).forEach(res => {
    if (res.negotiable !== change.value) {
      res.negotiable = !res.negotiable;
    }
    if (type === 'ROC' && negotiationType !== NegotiationTypeEnum.CONTRACT) {
      res.correction = false;
    }
  });

  // clear the incorrectly set corrections
  result.filter(res => res.unitOrder !== findFirstNegotiableValue(result)).forEach(res => {
    res.correction = false;
  });

  // for contract disable correction
  if (negotiationType === NegotiationTypeEnum.CONTRACT ) {
    result.forEach(res => {
      res.disabled = true;
    });
  }

  return result;
};

const findFirstNegotiableValue = (value) => {
  return Array.isArray(value) && value?.find(v => v.negotiable)?.unitOrder;
};

export const getValueForCorrection = (value, negotiationType) => {
  let helperFirstNegotiableValue = findFirstNegotiableValue(value);
  if (value) {
    return value?.map(val => {

      return { ...val, disabled: negotiationType === NegotiationTypeEnum.CONTRACT || helperFirstNegotiableValue === 1 || helperFirstNegotiableValue !== val.unitOrder };
    });
  }
  return value;
};
