import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const contractAnnualTargetApi = ApiServiceInstances.annualTarget.contractAnnualTargetApi;

export const UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_REQUEST = 'UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_REQUEST';
export const UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_SUCCESS = 'UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_SUCCESS';
export const UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_FAILURE = 'UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_FAILURE';

export const UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_REQUEST = 'UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_REQUEST';
export const UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_SUCCESS = 'UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_SUCCESS';
export const UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_FAILURE = 'UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_FAILURE';

export const UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_REQUEST = 'UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_REQUEST';
export const UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_SUCCESS = 'UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_SUCCESS';
export const UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_FAILURE = 'UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_FAILURE';

const initialState = {
  errorDealer: null,
  isFetchingDealer: false,
  errorRecommended: null,
  isFetchingRecommended: false,
  errorApproval: null,
  isFetchingApproval: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_REQUEST: {
      return { ...state, isFetchingDealer: true };
    }
    case UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_SUCCESS: {
      return { ...state, isFetchingDealer: false };
    }
    case UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_FAILURE: {
      return { ...state, errorDealer: action.payload.error, isFetchingDealer: false };
    }
    case UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_REQUEST: {
      return { ...state, isFetchingRecommended: true };
    }
    case UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_SUCCESS: {
      return { ...state, isFetchingRecommended: false };
    }
    case UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_FAILURE: {
      return { ...state, errorRecommended: action.payload.error, isFetchingRecommended: false };
    }
    case UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_REQUEST: {
      return { ...state, isFetchingApproval: true };
    }
    case UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_SUCCESS: {
      return { ...state, isFetchingApproval: false };
    }
    case UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_FAILURE: {
      return { ...state, errorApproval: action.payload.error, isFetchingApproval: false };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleUpdateContractAnnualTargetDealer = (contractATDealerWrapperUpdateDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => contractAnnualTargetApi.updateContractAnnualTargetDealerWithHttpInfo(contractATDealerWrapperUpdateDTO),
      types: [UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_REQUEST, UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_SUCCESS, UPDATE_CONTRACT_ANNUAL_TARGET_DEALER_FAILURE],
      middlewareConfig: {
        validate: function (body) {
          const contractApprovalInsufficient = body.errorList.filter(
            (e) => e.code === 'CONTRACT_AT_APPROVAL_INSUFFICIENT'
          );
          return contractApprovalInsufficient.length === 0 || body.errorList.length !== contractApprovalInsufficient.length;
        }
      }
    }
  };
};

export const handleUpdateContractAnnualTargetRecommended = (contractATRecommendedWrapperUpdateDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => contractAnnualTargetApi.updateContractAnnualTargetRecommendedWithHttpInfo(contractATRecommendedWrapperUpdateDTO),
      types: [UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_REQUEST, UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_SUCCESS, UPDATE_CONTRACT_ANNUAL_TARGET_RECOMMENDED_FAILURE]
    }
  };
};

export const handleUpdateContractAnnualTargetApproval = (contractATApprovalWrapperUpdateDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => contractAnnualTargetApi.updateContractAnnualTargetApprovalWithHttpInfo(contractATApprovalWrapperUpdateDTO),
      types: [UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_REQUEST, UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_SUCCESS, UPDATE_CONTRACT_ANNUAL_TARGET_APPROVAL_FAILURE],
      middlewareConfig: {
        validate: function (body) {
          const contractApprovalInsufficient = body.errorList.filter(
            (e) => e.code === 'CONTRACT_AT_APPROVAL_INSUFFICIENT'
          );
          return contractApprovalInsufficient.length === 0 || body.errorList.length !== contractApprovalInsufficient.length;
        }
      }
    }
  };
};
