import { mdiTextBoxSearchOutline } from '@mdi/js';

import { handleGetDefaultContextMenuItems, MenuItemDefBuilder } from '../../../../common/components/ag-grid';

export const getContextMenuEditMode = (params) => {
  return handleGetDefaultContextMenuItems(params);
};

export const getContextMenuReadOnlyMode = (params) => {
  if (params.node.group) {
    return handleGetDefaultContextMenuItems(params);
  }
  return [
    menuItems.getShowContractPreviewItem(params),
    'separator',
    ...handleGetDefaultContextMenuItems(params)
  ];
};

const menuItems = {
  getShowContractPreviewItem: (params) => {
    const data = params.node.data;

    return new MenuItemDefBuilder(params, 'ANNUAL_TARGET.CONTEXT_MENU.SHOW_DETAIL')
      .iconFromPath(mdiTextBoxSearchOutline)
      .action(() => {
        params.context.onShowContractPreview(data.contract.id);
      })
      .privilegeFunctionalityContext(data.contract.privilegeFunctionalityContextObject.getDealerContractPreviewFnCtx)
      .build();
  }
};
