import PropTypes from 'prop-types';
import React from 'react';

import { Button, Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { useAlertBusOvex } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';

import './PriceGuaranteeRefreshStorageData.scss';

const propTypes = {
  isFetching: PropTypes.bool,
  onRefreshPGVersionStorageData: PropTypes.func.isRequired,
  onRefreshUniverseStorageData: PropTypes.func.isRequired
};

const defaultProps = {
  isFetching: false
};

const PriceGuaranteeRefreshStorageData = (props) => {
  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const handleRefreshPGVersionStorageData = async () => {
    try {
      await props.onRefreshPGVersionStorageData();
      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.REFRESH_STORAGE_SUCCESS');

    } catch (e) {
      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.REFRESH_STORAGE_FAILURE', AlertTypeEnum.ERROR);
    }
  };
  const handleRefreshUniverseStorageData = async () => {
    try {
      await props.onRefreshUniverseStorageData();
      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.REFRESH_STORAGE_SUCCESS');

    } catch (e) {
      handleAddAlertSimple('PRICE_GUARANTEE.ERROR_MESSAGE.REFRESH_STORAGE_FAILURE', AlertTypeEnum.ERROR);
    }
  };

  return (
    <Page
      header={lsi.getLSIItem('PRICE_GUARANTEE.PAGE_TITLE.REFRESH_STORAGE_DATA')}
      loading={props.isFetching}>
      <div className={'ovex-pg-PriceGuaranteeRefreshStorageData'}>
        <Button
          className={'ovex-pg-PriceGuaranteeRefreshStorageData-button'}
          content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.REFRESH_UNIVERSE')}
          onClick={handleRefreshUniverseStorageData}
          type={Button.type.PRIMARY}
        />
        <p className={'ovex-pg-PriceGuaranteeRefreshStorageData-paragraph'}>
          {lsi.getLSIItem('PRICE_GUARANTEE.LABEL.REFRESH_UNIVERSE')}
        </p>
      </div>

      <div className={'ovex-pg-PriceGuaranteeRefreshStorageData'}>
        <Button
          className={'ovex-pg-PriceGuaranteeRefreshStorageData-button'}
          content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.REFRESH_PG_VERSION')}
          onClick={handleRefreshPGVersionStorageData}
          type={Button.type.PRIMARY}
        />
        <p className={'ovex-pg-PriceGuaranteeRefreshStorageData-paragraph'}>
          {lsi.getLSIItem('PRICE_GUARANTEE.LABEL.REFRESH_PG_VERSION')}
        </p>
      </div>

    </Page>
  );
};

PriceGuaranteeRefreshStorageData.propTypes = propTypes;
PriceGuaranteeRefreshStorageData.defaultProps = defaultProps;

export default PriceGuaranteeRefreshStorageData;
