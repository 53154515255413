import { mdiClose, mdiMinus } from '@mdi/js';

import { getExportExcelSkipRowGroupsItem } from '../../../../common/components/ag-grid/contextMenu/excelExport';
import { handleGetDefaultContextMenuItems, MenuItemDefBuilder } from '../../../../common/components/ag-grid';

import { exportExcel } from './PriceGuaranteeItemTable.exportParams';
import { getEditableCell } from './PriceGuaranteeItemTable.helpers';

export const handleGetContextMenuItems = (getContextMenuItemsParams ) => {
  const node = getContextMenuItemsParams.node;
  const editableModelGroup = getContextMenuItemsParams.context.editableModelGroup;

  if (node.group) {
    return [
      getExportExcelSkipRowGroupsItem(getContextMenuItemsParams, exportExcel)
    ];
  }
  if (node.data.isModelGroup(editableModelGroup)) {
    return getContextMenuForEditableModelGroup(getContextMenuItemsParams);
  }
  return getContextMenuReadOnlyMode(getContextMenuItemsParams);
};

const getContextMenuForEditableModelGroup = (getContextMenuItemsParams) => {
  const editableModelGroup = getContextMenuItemsParams.context.editableModelGroup;
  const onAgIsPrivileged = getContextMenuItemsParams.context.onAgIsPrivileged;
  if (getContextMenuItemsParams.node.data.isEditable(editableModelGroup, onAgIsPrivileged)) {
    return getContextMenuEditModeEditableRow(getContextMenuItemsParams);
  }
  return getContextMenuEditModePublishedRow(getContextMenuItemsParams);
};

const handleGetDefaultContextMenuItemsExcelParams = (getContextMenuItemsParams) => handleGetDefaultContextMenuItems(getContextMenuItemsParams, exportExcel);

const getContextMenuEditModeEditableRow = (getContextMenuItemsParams) => {
  return [
    EditableRowContextMenuItems.getUndoModificationCell(getContextMenuItemsParams),
    EditableRowContextMenuItems.getUndoModificationRow(getContextMenuItemsParams),
    EditableRowContextMenuItems.getDelete(getContextMenuItemsParams),
    EditableRowContextMenuItems.getUndoDelete(getContextMenuItemsParams),
    'separator',
    ...handleGetDefaultContextMenuItemsExcelParams(getContextMenuItemsParams)
  ];
};
const getContextMenuEditModePublishedRow = (getContextMenuItemsParams) => {
  return handleGetDefaultContextMenuItemsExcelParams(getContextMenuItemsParams);
};

const getContextMenuReadOnlyMode = (getContextMenuItemsParams) => {
  return handleGetDefaultContextMenuItemsExcelParams(getContextMenuItemsParams);
};

const EditableRowContextMenuItems = {
  getDelete: (getContextMenuItemsParams) => {
    const data = getContextMenuItemsParams.node.data;

    return new MenuItemDefBuilder(getContextMenuItemsParams, 'PRICE_GUARANTEE.CONTEXT_MENU.DELETE_PG_ITEM')
      .iconFromPath(mdiMinus)
      .privilegeFunctionalityContext(data.privilegeFunctionalityContext.deletePriceGuaranteeItemsPFC)
      .action(() => {
        if (data.isRowStatusNew()) {
          getContextMenuItemsParams.api.applyTransaction({ remove: [data] });
        } else {
          data.setDeleteFlag();
          getContextMenuItemsParams.api.applyTransaction({ update: [data] });
        }
      })
      .build();
  },
  getUndoDelete: (getContextMenuItemsParams) => {
    const data = getContextMenuItemsParams.node.data;

    return new MenuItemDefBuilder(getContextMenuItemsParams, 'PRICE_GUARANTEE.CONTEXT_MENU.UNDO_DELETE_PG_ITEM')
      .iconFromPath(mdiClose)
      .privilegeFunctionalityContext(data.privilegeFunctionalityContext.deletePriceGuaranteeItemsPFC)
      .disabled(!data.isDeleteFlag())
      .action(() => {
        data.removeDeleteFlag();
        getContextMenuItemsParams.api.applyTransaction({ update: [data] });
      })
      .build();
  },
  getUndoModificationRow: (getContextMenuItemsParams) => {
    const data = getContextMenuItemsParams.node.data;

    return new MenuItemDefBuilder(getContextMenuItemsParams, 'PRICE_GUARANTEE.CONTEXT_MENU.UNDO_CHANGES_ALL')
      .iconFromPath(mdiClose)
      .privilegeFunctionalityContext(data.privilegeFunctionalityContext.updatePriceGuaranteeItemsPFC)
      .disabled(!data.isCellModified())
      .action(() => {
        data.discardCellChanges();
        getContextMenuItemsParams.api.applyTransaction({ update: [getContextMenuItemsParams.node.data] });
      })
      .build();
  },
  getUndoModificationCell: (getContextMenuItemsParams) => {
    const data = getContextMenuItemsParams.node.data;
    const editableCell = getEditableCell(getContextMenuItemsParams);

    if (editableCell == null) {
      return null;
    }
    return new MenuItemDefBuilder(getContextMenuItemsParams, 'PRICE_GUARANTEE.CONTEXT_MENU.UNDO_CHANGES')
      .iconFromPath(mdiClose)
      .privilegeFunctionalityContext(data.privilegeFunctionalityContext.updatePriceGuaranteeItemsPFC)
      .disabled(!editableCell.isModified())
      .action(() => {
        editableCell.discardChanges();
        getContextMenuItemsParams.api.applyTransaction({ update: [getContextMenuItemsParams.node.data] });
      })
      .build();
  }
};
