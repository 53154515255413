import { UserDetailInfoRowIds } from './UserDetailInfoHeadertable.rows';

export const requiredValueGetter = ({ data, context, propertyName }) => {

  switch (data.rowId) {
    case UserDetailInfoRowIds.LOGIN_UID:
      return context[propertyName].login;
    case UserDetailInfoRowIds.DISPLAY_NAME:
      return context[propertyName].displayName;
    case UserDetailInfoRowIds.BID:
      return context[propertyName].bid;
    case UserDetailInfoRowIds.BRAND:
      return context[propertyName].brand;
    case UserDetailInfoRowIds.DEALER_NUMBER:
      return context[propertyName].dealerNumber;
    default:
      return '';
  }
};

export const fixedRowLsiGetter = ({ value, context, data }) => {
  switch (data.rowId) {
    case UserDetailInfoRowIds.LOGIN_UID:
    case UserDetailInfoRowIds.DISPLAY_NAME:
    case UserDetailInfoRowIds.BID:
    case UserDetailInfoRowIds.BRAND:
    case UserDetailInfoRowIds.DEALER_NUMBER:
      return context.agLsi.getLSIItem(`SUPPORT.ENUM_LABELS.USERINFO_ROW_TYPE.${data.rowId}`);
    default:
      return value;
  }
};

