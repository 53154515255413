import PropTypes from 'prop-types';
import React from 'react';
import { Bricks, Forms } from 'uu5g04';
import 'uu5g04/bricks';
import 'uu5g04/forms';

import './Select.scss';

const propTypes = {
  className: PropTypes.string,
  clearableSingleSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    tooltip: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string.isRequired
  })),
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  selectedContentAsValue: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  valueChanged: PropTypes.bool,
  width: PropTypes.string
};

const defaultProps = {
  className: null,
  clearableSingleSelect: false,
  disabled: false,
  items: null,
  label: null,
  multiple: false,
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  selectedContentAsValue: false,
  tooltip: null,
  value: '',
  valueChanged: false,
  width: null
};

const handleClearSingleSelect = (ref) => {
  if (ref) {
    ref.setValue(null);
    ref.props.onChange && ref.props.onChange({
      value: null,
      component: ref,
      _data: { type: 'changeValue', value: null }
    });
  }
};

const SelectComponent = (props, ref) => {
  const classNames = ['ovex-forms-select'];
  props.className && classNames.push(props.className);
  props.valueChanged && classNames.push('ovex-forms-select--value-changed');

  const clearableSingle = props.clearableSingleSelect && !props.required && !props.readOnly && !props.disabled && !props.multiple;

  const refSelect = React.useRef(null);
  const handleStoreRef = React.useCallback(
    (inputRef) => {
      refSelect.current = inputRef;
      ref && ref(inputRef);
    },
    [ref]
  );

  const selectOptions = React.useMemo(() => {
    return props.items && props.items.map(item => {
      let selectedContent;
      if (clearableSingle) {
        selectedContent = (
          <React.Fragment>
            {props.selectedContentAsValue ? item.value : item.content}
            <Bricks.Link onClick={() => handleClearSingleSelect(refSelect.current)}>
              <Bricks.Icon className='ovex-forms-select-clearable-icon' icon='mdi-close' />
            </Bricks.Link>
          </React.Fragment>
        );
      } else if (props.selectedContentAsValue) {
        selectedContent = item.value;
      }

      return (
        <Forms.Select.Option
          content={item.content}
          key={`${item.value}_${item.content}`}
          selectedContent={selectedContent}
          tooltip={item.tooltip}
          value={item.value}
        />
      );
    });
  }, [props.items, props.selectedContentAsValue, clearableSingle]);

  return (
    <Forms.Select
      bgStyle='underline'
      className={classNames.join(' ')}
      colorSchema='green'
      disabled={props.disabled}
      inputColWidth='xs12'
      label={props.label}
      labelColWidth='xs12'
      multiple={props.multiple}
      name={props.name}
      onChange={props.onChange}
      parent={props.parent}
      readOnly={props.readOnly}
      ref_={handleStoreRef}
      required={props.required}
      style={{
        width: props.width
      }}
      tooltip={props.tooltip}
      value={props.value}
    >
      {selectOptions}
    </Forms.Select>
  );
};

const Select = React.forwardRef(SelectComponent);
Select.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

SelectComponent.propTypes = propTypes;
SelectComponent.defaultProps = defaultProps;

export default Select;
