import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecPreviousModelNegCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__PREVIOUS__MODEL_NEG;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.PREVIOUS;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.MODEL_NEG;
  }

}

const specPreviousModelNegCUID = new SpecPreviousModelNegCUID();

export default specPreviousModelNegCUID;