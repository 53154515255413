import { isEmptyArray, isNonEmptyArray } from '../../../../../common/utils/arrays';

import { PriceGuaranteeReportRow } from './PriceGuaranteeReportRow';

/**
 * @param {PriceGuaranteeReportResponseDTOPGR} pgReport
 * @return {Array.<PriceGuaranteeReportRow>}
 */
export const priceGuaranteeReportRowConverter = (pgReport) => {
  if (pgReport == null) {
    return [];
  }

  const { priceGuaranteeList, pgItemList, commissionPGClaimResultList } = pgReport;

  if (isEmptyArray(commissionPGClaimResultList)) {
    return [];
  }

  const priceGuaranteeMap = convertListToMap(priceGuaranteeList, pg => pg.id);
  const pgItemMap = convertListToMap(pgItemList, pgi => pgi.id);

  return commissionPGClaimResultList.flatMap(
    (commissionPGClaimResult) => commissionPGClaimResultFlatMapper(commissionPGClaimResult, priceGuaranteeMap, pgItemMap)
  );
};

const commissionPGClaimResultFlatMapper = (commissionPGClaimResult, priceGuaranteeMap, pgItemMap) => {
  const result = [];

  const { priceGuaranteeId, cOrderHeaderAccount } = commissionPGClaimResult;
  const priceGuarantee = priceGuaranteeMap[priceGuaranteeId];

  const headerRow = new PriceGuaranteeReportRow(commissionPGClaimResult, priceGuarantee);
  result.push(headerRow);

  const { pgItemList } = cOrderHeaderAccount;

  if (isNonEmptyArray(pgItemList)) {
    headerRow.setHeaderWithItems();

    pgItemList.forEach(pgItemValidationResult => {
      const { pgItemId, claimTypeItem, claimAmount, invoiceAmount } = pgItemValidationResult;
      const pgItem = pgItemMap[pgItemId];

      // add rows for PG items
      result.push(
        new PriceGuaranteeReportRow(commissionPGClaimResult, priceGuarantee)
          .setPGItem(pgItem, claimTypeItem, claimAmount, invoiceAmount)
      );
    });
  }

  return result;
};

/**
 * @param {Array<Object>} list
 * @param {function} keyGetter - function return key value of object
 */
const convertListToMap = (list, keyGetter) => {
  if (list == null || keyGetter == null) {
    return {};
  }
  return list.reduce(
    (map, item) => {
      const key = keyGetter(item);
      map[key] = item;
      return map;
    },
    {}
  );
};
