import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';
import DealerContractConverter from '../../../layouts/DealerContracts/DealerContractsTable/DealerContractConverter';
import { GET_USER_INFO_SUCCESS } from '../about/userInfo/userInfo';

const dealerContractsApi = ApiServiceInstances.annualTarget.dealerContractsApi;

export const GET_DEALER_CONTRACTS_LIST_REQUEST = 'GET_DEALER_CONTRACTS_LIST_REQUEST';
export const GET_DEALER_CONTRACTS_LIST_SUCCESS = 'GET_DEALER_CONTRACTS_LIST_SUCCESS';
export const GET_DEALER_CONTRACTS_LIST_FAILURE = 'GET_DEALER_CONTRACTS_LIST_FAILURE';

export const SHIFT_WF_DEALER_CONTRACTS_REQUEST = 'SHIFT_WF_DEALER_CONTRACTS_REQUEST';
export const SHIFT_WF_DEALER_CONTRACTS_SUCCESS = 'SHIFT_WF_DEALER_CONTRACTS_SUCCESS';
export const SHIFT_WF_DEALER_CONTRACTS_FAILURE = 'SHIFT_WF_DEALER_CONTRACTS_FAILURE';

const initialState = {
  dealerContractList: null,
  dealerContractRows: null,
  filterData: null,
  valuesFilter: null,
  workflowResponse: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO_SUCCESS: {
      // FIXME AOP-1173 - docasne reseni problemu, ze nelze pouzit userRoleList(), pak by to mohlo byt az na ModelGroupAnnualTargetRow.js a PeriodAnnualTargetSalesRow.js
      return {
        ...state,
        roleList: action.payload && action.payload.roleList
      };
    }
    case GET_DEALER_CONTRACTS_LIST_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_DEALER_CONTRACTS_LIST_SUCCESS: {
      const responseData = action.payload;
      const metaData = action.metaData;

      return {
        ...state,
        isFetching: false,
        dealerContractList: responseData.response,
        dealerContractRows: DealerContractConverter.fromList(responseData.response, state.roleList),
        filterData: responseData.filterData,
        valuesFilter: metaData.valuesFilter
      };
    }
    case GET_DEALER_CONTRACTS_LIST_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case SHIFT_WF_DEALER_CONTRACTS_REQUEST: {
      return { ...state, isFetching: true };
    }
    case SHIFT_WF_DEALER_CONTRACTS_SUCCESS: {
      return { ...state, isFetching: false, workflowResponse: action.payload };
    }
    case SHIFT_WF_DEALER_CONTRACTS_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const getDealerContracts = (opts) => {
  return {
    [CALL_API]: {
      apiFunction: () => dealerContractsApi.getContractListForImporterWithHttpInfo(opts),
      types: [GET_DEALER_CONTRACTS_LIST_REQUEST, GET_DEALER_CONTRACTS_LIST_SUCCESS, GET_DEALER_CONTRACTS_LIST_FAILURE],
      metaData: { valuesFilter: opts }
    }
  };
};

export const shiftDealerContractWorkflow = (dealerContractWorkflow) => {
  return {
    [CALL_API]: {
      apiFunction: () => dealerContractsApi.shiftDealerContractWorkflowWithHttpInfo(dealerContractWorkflow),
      types: [SHIFT_WF_DEALER_CONTRACTS_REQUEST, SHIFT_WF_DEALER_CONTRACTS_SUCCESS, SHIFT_WF_DEALER_CONTRACTS_FAILURE],
      middlewareConfig: {
        validate: function (body) {
          const contractApprovalInsufficient = body.errorList.filter(
            (e) => e.code === 'CONTRACT_AT_APPROVAL_INSUFFICIENT'
          );
          return contractApprovalInsufficient.length === 0 || body.errorList.length !== contractApprovalInsufficient.length;
        }
      }
    }
  };
};
