import PrivilegeWebResult from '../../../../common/privilege/PrivilegeWebResult';
import { ContractStatusEnum } from '../../../utils/const';
import AnnualTargetRoles from '../../AnnualTargetRoles';

/**
 * Helper function for evaluate web result for functionality contexts:
 *  - UpdateContractAnnualTargetRecommendedFunctionalityContext.js
 *  - UpdateContractAnnualTargetSalesRecommendedFunctionalityContext.js
 *
 * @param {object} webParams
 * @return {null|PrivilegeWebResult}
 */
const evaluateWebResultRecommendedFnCtx = (webParams) => {
  let result = null;
  if (webParams == null) {
    return result;
  }
  const roleList = webParams.hasOwnProperty('roleList') ? webParams.roleList : null;
  const contractStatus = webParams.hasOwnProperty('contractStatus') ? webParams.contractStatus : null;
  const negotiable = webParams.hasOwnProperty('negotiable') ? webParams.negotiable : null;

  const rejectionReasonList = [];

  if (contractStatus != null && contractStatus !== ContractStatusEnum.CREATED) {
    rejectionReasonList.push(`contract (mg.at) can't be edited in status={${contractStatus}}`);
  }
  if (negotiable === false) {
    rejectionReasonList.push('negotiable flag is *false*');
  }

  if (rejectionReasonList.length === 0 && Array.isArray(roleList)) {
    const hasSomeRequiredRole = [
      AnnualTargetRoles.GEKO,
      AnnualTargetRoles.IMPORTER_AREA_ADVISOR,
      AnnualTargetRoles.IMPORTER_AREA_ADVISOR_MANAGER
    ].some(role => roleList.includes(role));

    if (hasSomeRequiredRole && contractStatus === ContractStatusEnum.CREATED && negotiable === true) {
      result = new PrivilegeWebResult(null);
    }
  }

  if (rejectionReasonList.length > 0) {
    result = new PrivilegeWebResult(rejectionReasonList.join('  && '));
  }
  return result;
};

export default evaluateWebResultRecommendedFnCtx;