import { privilegeEditable } from '../../../../common/components/ag-grid';
import { integerFormatter, simpleNullishValueFormatter } from '../../../../common/utils/formatters';
import { isString, parseNumber } from '../../../../common/utils/helpers';

import { AG_GROUP_TOTAL } from './const';

export const handleEditableApprovalNumberColumn = (params) => {
  return params.context.editableApprovalNumberColumn
    && !params.node.group
    && params.data.isEditable()
    && privilegeEditable(params, params.data.privilegeFunctionalityContextObject.updateApprovalFnCtx);
};
export const handleEditableDealerNumberColumn = (params) => {
  return params.context.editableDealerNumberColumn
    && !params.node.group
    && params.data.isEditable()
    && privilegeEditable(params, params.data.privilegeFunctionalityContextObject.updateDealerFnCtx);
};

export const handleValueParser = (valueParserParams) => {
  return /^[0-9]{1,5}$/.test(valueParserParams.newValue) ? parseNumber(valueParserParams.newValue) : null;
};

export const handleValueSetter = (setterName) => (valueSetterParams) => {
  if (isString(valueSetterParams.newValue)) {
    valueSetterParams.newValue = handleValueParser(valueSetterParams);
  }
  if (valueSetterParams.newValue != null && valueSetterParams.newValue !== valueSetterParams.oldValue) {
    valueSetterParams.data[setterName](valueSetterParams.newValue);
    return true;
  }
  return false;
};

export const handleInnerRendererTOTAL = (params) => {
  if (params.node.group && params.value === AG_GROUP_TOTAL) {
    return params.context.agLsi.getLSIItem('AG_GRID.ovexTotal');
  }
  return params.valueFormatted === null ? params.value : params.valueFormatted;
};

export const filterErrorContractAtApprovalInsufficient = (error) => {
  return error.body?.errorList?.filter((e) => e.code === 'CONTRACT_AT_APPROVAL_INSUFFICIENT');
};

export const handleNumberValueFormatter = (valueFormatterParams) => {
  const value = valueFormatterParams.value;
  const formatters = valueFormatterParams.context.agLsi.getFormatters();

  return simpleNullishValueFormatter(integerFormatter(value, formatters), '-');
};
