import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonBar } from '../../../../common/components';
import { FilterContextButtons } from '../../../../common/components/forms';
import { LsiContext } from '../../../../common/contexts';

const propTypes = {
  onExport: PropTypes.func,
  onExportWithAggregation: PropTypes.func
};

const defaultProps = {
  onExport: undefined,
  onExportWithAggregation: undefined
};

const DealerContractsATSPeriodsViewButtonBar = (props) => {
  const lsi = React.useContext(LsiContext);

  return (
    <ButtonBar className='ovex-DealerContractsATSPeriodsViewButtonBar'>
      <Button
        content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.EXPORT')}
        onClick={props.onExport}
        type={Button.type.PRIMARY}
      />
      <Button
        content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.EXPORT_WITH_AGGREGATION')}
        onClick={props.onExportWithAggregation}
        type={Button.type.PRIMARY}
      />
      <ButtonBar.Separator />
      <FilterContextButtons />
    </ButtonBar>
  );
};

DealerContractsATSPeriodsViewButtonBar.propTypes = propTypes;
DealerContractsATSPeriodsViewButtonBar.defaultProps = defaultProps;

export default DealerContractsATSPeriodsViewButtonBar;
