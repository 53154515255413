/**
 * @enum
 */
const RestrictionsTabEnum = {

  /**
   * Označení záložky pro zobrazení *současného* výrobního plánu.
   */
  ACTUAL_PLAN: 'ACTUAL_PLAN',
  /**
   * Označení záložky pro zobrazení *nového/budoucího* výrobního plánu.
   */
  NEW_PLAN: 'NEW_PLAN',
  /**
   * Označení záložky pro zobrazení *využitých/čerpaných* výrobních kapacit.
   */
  USED: 'USED',
  /**
   * Označení záložky pro zobrazení *rozdílu* výrobních kapacit.
   */
  DIFFERENCE: 'DIFFERENCE',

  /**
   * @param {string} tab
   * @return {boolean}
   */
  isActualPlan: (tab) => tab === RestrictionsTabEnum.ACTUAL_PLAN,
  /**
   * @param {string} tab
   * @return {boolean}
   */
  isNewPlan: (tab) => tab === RestrictionsTabEnum.NEW_PLAN,
  /**
   * @param {string} tab
   * @return {boolean}
   */
  isUsed: (tab) => tab === RestrictionsTabEnum.USED,
  /**
   * @param {string} tab
   * @return {boolean}
   */
  isDifference: (tab) => tab === RestrictionsTabEnum.DIFFERENCE
};

export default RestrictionsTabEnum;