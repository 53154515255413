import claimAmountCUID from './impl/ClaimAmountCUID';
import claimTypeCUID from './impl/ClaimTypeCUID';
import cohPartStatusCUID from './impl/CohPartStatusCUID';
import cOrderModeCUID from './impl/COrderModeCUID';
import invoiceAmountCUID from './impl/InvoiceAmountCUID';
import priceValidFromCUID from './impl/PriceValidFromCUID';
import productionDateFromCUID from './impl/ProductionDateFromCUID';
import productionDateToCUID from './impl/ProductionDateToCUID';
import salesAccountTypeCUID from './impl/SalesAccountTypeCUID';
import specCurrentColourCUID from './impl/SpecCurrentColourCUID';
import specCurrentColourNegCUID from './impl/SpecCurrentColourNegCUID';
import specCurrentInteriorCUID from './impl/SpecCurrentInteriorCUID';
import specCurrentInteriorNegCUID from './impl/SpecCurrentInteriorNegCUID';
import specCurrentModelCUID from './impl/SpecCurrentModelCUID';
import specCurrentModelNegCUID from './impl/SpecCurrentModelNegCUID';
import specCurrentPackageCUID from './impl/SpecCurrentPackageCUID';
import specCurrentPackageNegCUID from './impl/SpecCurrentPackageNegCUID';
import specPreviousColourCUID from './impl/SpecPreviousColourCUID';
import specPreviousColourNegCUID from './impl/SpecPreviousColourNegCUID';
import specPreviousInteriorCUID from './impl/SpecPreviousInteriorCUID';
import specPreviousInteriorNegCUID from './impl/SpecPreviousInteriorNegCUID';
import specPreviousModelCUID from './impl/SpecPreviousModelCUID';
import specPreviousModelNegCUID from './impl/SpecPreviousModelNegCUID';
import specPreviousPackageCUID from './impl/SpecPreviousPackageCUID';
import specPreviousPackageNegCUID from './impl/SpecPreviousPackageNegCUID';
import pgidCUID from './impl/PgidCUID';
import totalAmountCUID from './impl/TotalAmountCUID';

const rowCellsUIList = [
  claimAmountCUID,
  claimTypeCUID,
  cohPartStatusCUID,
  cOrderModeCUID,
  invoiceAmountCUID,
  priceValidFromCUID,
  productionDateFromCUID,
  productionDateToCUID,
  salesAccountTypeCUID,
  specCurrentColourCUID,
  specCurrentColourNegCUID,
  specCurrentInteriorCUID,
  specCurrentInteriorNegCUID,
  specCurrentModelCUID,
  specCurrentModelNegCUID,
  specCurrentPackageCUID,
  specCurrentPackageNegCUID,
  specPreviousColourCUID,
  specPreviousColourNegCUID,
  specPreviousInteriorCUID,
  specPreviousInteriorNegCUID,
  specPreviousModelCUID,
  specPreviousModelNegCUID,
  specPreviousPackageCUID,
  specPreviousPackageNegCUID,
  pgidCUID,
  totalAmountCUID
];

const ColumnUIDefinitionMap = rowCellsUIList.reduce(
  (obj, columnUIDefinition) => {
    obj[columnUIDefinition.getCode()] = columnUIDefinition;
    return obj;
  },
  {}
);

Object.freeze(ColumnUIDefinitionMap);

export default ColumnUIDefinitionMap;