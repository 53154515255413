import {RowType} from './ValidationResultPGItemTable.const';

/**
 * @param {CommissionSpecificationDTOVLD} commission
 * @param {Object} sumAmounts
 * @param {Array<AbstractSpecColumnUIDefinition>} specSnapshotCUIDList
 */
export const getRowFromCommission = (commission, sumAmounts, specSnapshotCUIDList) => {
  const commissionCurrentData = commission.commissionCurrentData;

  const specificationMap = specSnapshotCUIDList.reduce(
    (map, cuid) => {
      map[cuid.getCode()] = cuid.commissionConverterValueGetter(commission);
      return map;
    },
    {}
  );

  const row = {
    rowType: RowType.COMMISSION,
    claimTypeHeader: commission.claimTypeHeader,
    specificationMap: specificationMap,
    productionDateFrom: commissionCurrentData.productionDate,
    productionDateTo: commissionCurrentData.productionDate,
    priceValidFrom: commissionCurrentData.valuationDate
  };

  return [row];
};
