import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetAnnualTargetCalendarYearsFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_NEGOTIATIONS__GET_ANNUAL_TARGET_CALENDAR_YEARS);
  }

}

export default GetAnnualTargetCalendarYearsFunctionalityContext;