/**
 * @param {Date|null} dateA
 * @param {Date|null} dateB
 * @return {boolean} *true* if dateA and dateB are equal, otherwise *false*
 */
export const dateComparator = (dateA, dateB) => {
  if (dateA != null && dateB != null) {
    return dateA.getDate() === dateB.getDate()
      && dateA.getMonth() === dateB.getMonth()
      && dateA.getFullYear() === dateB.getFullYear();
  }
  return dateA === dateB || (dateA == null && dateB == null);
};
