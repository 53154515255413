import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const supportApi = ApiServiceInstances.support.userCredentialsApi;

export const REVERT_USER_REQUEST = 'REVERT_USER_REQUEST';
export const REVERT_USER_SUCCESS = 'REVERT_USER_SUCCESS';
export const REVERT_USER_FAILURE = 'REVERT_USER_FAILURE';

const initialState = {
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REVERT_USER_REQUEST: {
      return { ...state, isFetching: true };
    }
    case REVERT_USER_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case REVERT_USER_FAILURE: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    default:
      return state;
  }
};

export const handleRevertUserChanges = () => {
  return {
    [CALL_API]: {
      apiFunction: supportApi.revertUserChangesWithHttpInfo.bind(supportApi),
      types: [REVERT_USER_REQUEST, REVERT_USER_SUCCESS, REVERT_USER_FAILURE]
    }
  };
};
