
class ApiMiddlewareRejectReason {

  /**
   * @param {object} error
   * @param {module:ovex/main/Alert} alert
   */
  constructor(error, alert) {
    this.error = error;
    this.alert = alert;
  }
}

ApiMiddlewareRejectReason.prototype.error = undefined;
ApiMiddlewareRejectReason.prototype.alert = undefined;

export default ApiMiddlewareRejectReason;