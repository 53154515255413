import { handleSaveCommissionsLoadParamsForDealer } from '../../../redux/modules/actions';
import routes from '../../../routes';

import useShowCommissionListWithParams from './useShowCommissionListWithParams';

const useShowCommissionListWithParamsForDealer = () => {
  return useShowCommissionListWithParams(handleSaveCommissionsLoadParamsForDealer, routes.paths.ROUTE_PATH_ANNUAL_TARGET_COMMISSION_LIST_DEALER);
};

export default useShowCommissionListWithParamsForDealer;
