import React from 'react';
import PropTypes from 'prop-types';
import { DealerContractFilterDataDTO } from '@ovex/annual-target-web-api';

import { FilterForm, Select } from '../../../../common/components/forms';
import { LsiContext } from '../../../../common/contexts';
import { equalObjectProperty } from '../../../../common/utils/helpers';

import {
  InputNames,
  SelectOptionMapKeys,
  valuesDefault,
  filterContentValueGetterMap,
  filterContentMap
} from './DealerContractsFilter.helpers';

const propTypes = {
  className: PropTypes.string,
  filterData: PropTypes.instanceOf(DealerContractFilterDataDTO),
  onSubmit: PropTypes.func.isRequired,
  valuesFilterSubmitted: PropTypes.object
};

const defaultProps = {
  className: null,
  filterData: null,
  valuesFilterSubmitted: null
};

const DealerContractsFilter = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const classNames = ['ovex-DealerContractsFilter'];
  props.className && classNames.push(props.className);

  const filterData = props.filterData;

  const filterBuilder = React.useMemo(
    () => {
      if (filterData == null) {
        return null;
      }
      return new FilterForm.FilterOptionsBuilder(filterData.filterContentList, filterData.selectOptionMap, filterContentMap, filterContentValueGetterMap);
    },
    [filterData]
  );

  const [values, setValues] = React.useState(valuesDefault);
  const [selectOptionMap, setSelectOptionMap] = React.useState({});
  React.useEffect(
    () => {
      let som;
      if (filterBuilder) {
        som = filterBuilder.getFilteredOptions(values);
      } else {
        som = filterData?.selectOptionMap;
      }
      setSelectOptionMap(som);
    },
    [filterData, filterBuilder, values]
  );

  const handleSubmit = (formValues) => {
    setValues(formValues);
    props.onSubmit(formValues);
  };
  const handleReset = React.useCallback(
    () => setValues(valuesDefault),
    []
  );

  const handleOnSelectChange = React.useCallback(
    (opt) => {
      opt.component.onChangeDefault(opt, () => {
        const name = opt.component.props.name;
        const valueNew = opt.component.getValue();

        setValues((prevValues) => ({ ...prevValues, [name]: valueNew }));
      });
    },
    []
  );

  return (
    <FilterForm
      className={classNames.join(' ')}
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Select
        items={selectOptionMap?.[SelectOptionMapKeys.yearList]}
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.YEAR')}
        multiple
        name={InputNames.yearList}
        onChange={handleOnSelectChange}
        required
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_TOOLTIP.YEAR_CONTRACT')}
        value={values[InputNames.yearList]}
        valueChanged={!equalObjectProperty(values, props.valuesFilterSubmitted, InputNames.yearList)}
        width='250px'
      />
      <Select
        clearableSingleSelect
        items={selectOptionMap?.[SelectOptionMapKeys.negotiationList]}
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.NEGOTIATION')}
        name={InputNames.negotiationId}
        onChange={handleOnSelectChange}
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_TOOLTIP.NEGOTIATION')}
        value={values[InputNames.negotiationId]}
        valueChanged={!equalObjectProperty(values, props.valuesFilterSubmitted, InputNames.negotiationId)}
        width='250px'
      />
      <Select
        disabled={selectOptionMap?.[SelectOptionMapKeys.modelGroupList]?.length === 0}
        items={selectOptionMap?.[SelectOptionMapKeys.modelGroupList]}
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.MODEL_GROUPS')}
        multiple
        name={InputNames.modelGroupList}
        onChange={handleOnSelectChange}
        selectedContentAsValue
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_TOOLTIP.MODEL_GROUPS')}
        value={values[InputNames.modelGroupList]}
        valueChanged={!equalObjectProperty(values, props.valuesFilterSubmitted, InputNames.modelGroupList)}
        width='250px'
      />
      <Select
        disabled={selectOptionMap?.[SelectOptionMapKeys.atPeriodOrderList]?.length === 0}
        items={selectOptionMap?.[SelectOptionMapKeys.atPeriodOrderList]}
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.PERIODS_AT')}
        multiple
        name={InputNames.atPeriodOrderList}
        onChange={handleOnSelectChange}
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_TOOLTIP.PERIODS_AT')}
        value={values[InputNames.atPeriodOrderList]}
        valueChanged={!equalObjectProperty(values, props.valuesFilterSubmitted, InputNames.atPeriodOrderList)}
        width='150px'
      />
      <Select
        disabled={selectOptionMap?.[SelectOptionMapKeys.atsPeriodOrderList]?.length === 0}
        items={selectOptionMap?.[SelectOptionMapKeys.atsPeriodOrderList]}
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.PERIODS_ATS')}
        multiple
        name={InputNames.atsPeriodOrderList}
        onChange={handleOnSelectChange}
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_TOOLTIP.PERIODS_ATS')}
        value={values[InputNames.atsPeriodOrderList]}
        valueChanged={!equalObjectProperty(values, props.valuesFilterSubmitted, InputNames.atsPeriodOrderList)}
        width='150px'
      />
      <Select
        items={[
          { value: 'true', content: lsi.getLSIItem('COMMON.ENUM_LABELS.BOOLEAN_VALUE.TRUE') },
          { value: 'false', content: lsi.getLSIItem('COMMON.ENUM_LABELS.BOOLEAN_VALUE.FALSE') }
        ]}
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.SHOW_CANCELED_CONTRACT')}
        name={InputNames.canceledContracts}
        onChange={handleOnSelectChange}
        tooltip={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_TOOLTIP.SHOW_CANCELED_CONTRACT')}
        value={values[InputNames.canceledContracts]}
        valueChanged={!equalObjectProperty(values, props.valuesFilterSubmitted, InputNames.canceledContracts)}
        width='130px'
      />
    </FilterForm>
  );
});

DealerContractsFilter.propTypes = propTypes;
DealerContractsFilter.defaultProps = defaultProps;

DealerContractsFilter.valuesDefault = valuesDefault;

export default DealerContractsFilter;
