import { dateFormatter, simpleNullishValueFormatter } from '../../../../../../../common/utils/formatters';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class SellingDateRUID extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.SELLING_DATE__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.SELLING_DATE');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return [
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.SELLING_DATE_PART_1'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.SELLING_DATE_PART_2'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.SELLING_DATE_PART_3'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.SELLING_DATE_PART_4'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.SELLING_DATE_PART_5'),
      agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.SELLING_DATE_PART_6')
    ];
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    const { calculatedSellingDateTo } = context;

    return calculatedSellingDateTo;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return simpleNullishValueFormatter(value && agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.MAX_VALUE', dateFormatter(value, agLsi.getFormatters())), '-');
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { commission } = context;

    return commission.commissionCurrentData.sellingDate;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;

    return simpleNullishValueFormatter(dateFormatter(value, agLsi.getFormatters()), '-');
  }
}

const sellingDateRUID = new SellingDateRUID();

export default sellingDateRUID;