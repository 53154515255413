import React from 'react';

import './Footer.scss';

const Footer = () => {

  return (
    <div
      className='ovex-footer'
    >
      <div>
        {'© ŠKODA AUTO a.s. 2020'}
      </div>
      <div>
        {new Date().toLocaleString()}
      </div>
    </div>
  );
};

export default Footer;