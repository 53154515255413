import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import './FilterWrapper.scss';

const propTypes = {
  children: PropTypes.oneOfType([ PropTypes.element, PropTypes.arrayOf(PropTypes.element) ]).isRequired,
  className: PropTypes.string,
  parent: PropTypes.object
};

const defaultProps = {
  className: null,
  parent: null
};

const FilterWrapper = (props) => {

  const classNames = ['ovex-FilterWrapper'];
  props.className && classNames.push(props.className);

  return (
    <Bricks.Div
      className={classNames.join(' ')}
      parent={props.parent}
    >
      {props.children}
    </Bricks.Div>
  );
};

FilterWrapper.propTypes = propTypes;
FilterWrapper.defaultProps = defaultProps;

export default FilterWrapper;
