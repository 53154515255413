import { PGI_EDITABLE_COLUMN_IDS } from '../PriceGuaranteeItemTable.columnIds';

import { constructRowFromDTO, constructNewRow, constructVirtualRow, constructUpdateDTOFromRow, constructNewDTOFromRow } from './PriceGuaranteeItemRow.converters';

const RowStatusEnum = {
  NEW: 'NEW',
  SAVED: 'SAVED',
  PUBLISHED: 'PUBLISHED',
  VIRTUAL: 'VIRTUAL'
};

const EDITABLE_ROW_STATUSES = [RowStatusEnum.NEW, RowStatusEnum.SAVED, RowStatusEnum.PUBLISHED];

class PriceGuaranteeItemRow {

  /**
   * @param {String} rowStatus
   */
  constructor(rowStatus = RowStatusEnum.VIRTUAL) {
    this._deleteFlag = false;
    this._rowStatus = rowStatus;
    this.editableCells = {};
    this.privilegeFunctionalityContext = {
      deletePriceGuaranteeItemsPFC: null,
      updatePriceGuaranteeItemsPFC: null
    };
  }

  isDeleteFlag = () => this._deleteFlag;
  isModelGroup = (modelGroup) => this.modelGroup === modelGroup;

  isEditable = (editableModelGroup, onIsPrivileged) => {
    return this.isRowStatusEditable()
      && this.isModelGroup(editableModelGroup)
      && !!onIsPrivileged
      && (
        onIsPrivileged(this.privilegeFunctionalityContext.updatePriceGuaranteeItemsPFC)
        || onIsPrivileged(this.privilegeFunctionalityContext.updatePriceGuaranteeItemsPFC)
      );
  };

  isModified = () => {
    return this.isDeleteFlag() || this.isRowStatusNew() || this.isCellModified();
  };

  isCellModified = () => {
    return this._editableCellList().some(editableCell => editableCell.isModified());
  };

  isValid = () => {
    return !this._editableCellList().some(editableCell => !editableCell.isValid());
  };

  setDeleteFlag = () => {
    this._deleteFlag = true;
  };
  removeDeleteFlag = () => {
    this._deleteFlag = false;
  };

  discardChanges = () => {
    this.removeDeleteFlag();
    this.discardCellChanges();
  };

  discardCellChanges = () => {
    this._editableCellList().forEach(editableCell => editableCell.discardChanges());
  };

  isRowStatusEditable = () => EDITABLE_ROW_STATUSES.includes(this._rowStatus);
  isRowStatusNew = () => RowStatusEnum.NEW === this._rowStatus;
  isRowStatusVirtual = () => RowStatusEnum.VIRTUAL === this._rowStatus;

  getTotalAmount = () => {
    const importerAmountEC = this.editableCells[PGI_EDITABLE_COLUMN_IDS.IMPORTER_AMOUNT];
    const headquartersAmountEC = this.editableCells[PGI_EDITABLE_COLUMN_IDS.HEADQUARTERS_AMOUNT];
    if (importerAmountEC?.isValid() && headquartersAmountEC?.isValid()) {
      return importerAmountEC.getValue() + headquartersAmountEC.getValue();
    }
    return null;
  };

  _editableCellList = () => Object.values(this.editableCells);

  getFirstInvalidColumnId = () => {
    const [ cellKey ] = Object.entries(this.editableCells).find(([, editableCell]) => !editableCell.isValid()) ?? [];
    return cellKey;
  };

  getNewDTO = () => constructNewDTOFromRow(this);
  getUpdateDTO = () => constructUpdateDTOFromRow(this);

  static RowStatusEnum = RowStatusEnum;

  static constructFromDTO = constructRowFromDTO;
  static createNewRow = constructNewRow;
  static createVirtualRow = constructVirtualRow;

}
/**
 * @type {Number}
 */
PriceGuaranteeItemRow.prototype.id = undefined;
/**
 * @type {Number}
 */
PriceGuaranteeItemRow.prototype.priceGuaranteeId = undefined;
/**
 * @type {String}
 */
PriceGuaranteeItemRow.prototype.pgid = undefined;
/**
 * @type {String}
 */
PriceGuaranteeItemRow.prototype.modelGroup = undefined;
/**
 * @type {Boolean}
 */
PriceGuaranteeItemRow.prototype.published = undefined;
/**
 * @type {Object} map of editable cells
 */
PriceGuaranteeItemRow.prototype.editableCells = undefined;
/**
 * @type {Object} map of editable cells
 */
PriceGuaranteeItemRow.prototype.privilegeFunctionalityContext = undefined;

export default PriceGuaranteeItemRow;
