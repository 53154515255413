import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { Number } from '../../../common/components/forms';
import { decimalToPercent } from '../../utils/helpers';

import { getMinAchievementRatioInputName, quarterSorter } from './QuarterComponentForm.utils';

import './QuarterPeriodNumber.scss';

const DefaultValues = {
};

const propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  readOnlyMinAchievementRatio: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    minAchievementRatio: PropTypes.number,
    negotiable: PropTypes.bool,
    unitOrder: PropTypes.number
  }))
};

const defaultProps = {
  label: null,
  name: null,
  parent: null,
  readOnly: false,
  readOnlyMinAchievementRatio: false,
  required: false,
  tooltip: null,
  value: null
};

const QuarterPeriodNumber = (props) => {
  const classNames = ['ovex-form-QuarterPeriodNumber'];
  const tooltipClassNames = ['uu5-bricks-icon uu5-forms-label-tooltip mdi mdi-information-outline toolTip-color uu5-forms-input-m'];

  return (
    <Bricks.Row
      className={classNames.join(' ')}
      display='flex'
    >
      <Bricks.Column
        className='uu5-forms-label number-label'
        colWidth='xs4'
      >
        {props.label}
        {props.tooltip &&
          <span
            className={tooltipClassNames.join(' ')}
            title={props.tooltip}
          />
        }
      </Bricks.Column>
      {props.value?.sort(quarterSorter).map(val => {
        return (
          <Bricks.Column
            colWidth='xs2'
            key={val.id +'Q_COLUMN_NUMBER'}
          >
            <Number
              className={'uu5-forms-input uu5-forms-input-m'}
              decimals={2}
              inputColWidth='xs12'
              max={100}
              min={0}
              name={getMinAchievementRatioInputName(props.name, val.id)}
              parent={props.parent}
              readOnly={props.readOnly || props.readOnlyMinAchievementRatio}
              required={props.required}
              suffix='%'
              value={decimalToPercent(val.minAchievementRatio, 0)}
            />
          </Bricks.Column>
        );
      })}

    </Bricks.Row>
  );
};

QuarterPeriodNumber.DefaultValues = DefaultValues;

QuarterPeriodNumber.propTypes = propTypes;
QuarterPeriodNumber.defaultProps = defaultProps;

export default QuarterPeriodNumber;
