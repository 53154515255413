import React from 'react';
import { useDispatch } from 'react-redux';

import { usePrivilege } from '../../../common/hooks';
import moduleContextData from '../../config/moduleContextData';
import {
  GetAvailableBidsFunctionalityContext,
  GetImitateUserByUidFunctionalityContext,
  RevertChangesFunctionalityContext
} from '../../privilege/context';
import { handlePrivilegeInitialized, handlePrivilegeInitializationFailed, handleGetUserInfoCurrent } from '../../redux/modules/actions';
import useSetMenuItems from '../../../common/hooks/useSetMenuItems';
import { UserModalEnum } from '../../../../components/user-modal';

const propTypes = {};
const defaultProps = {};

const getUserInfoCurrent = new GetImitateUserByUidFunctionalityContext();
const getAvailableBids = new GetAvailableBidsFunctionalityContext();
const revertChanges = new RevertChangesFunctionalityContext();
const SupportInitialization = () => {
  const dispatch = useDispatch();
  const menuItems = React.useMemo( () => (
    [
      {
        label: 'SUPPORT.USER_IMITATION.HEADER',
        privilegeFunctionalityContext: getUserInfoCurrent,
        onClick: UserModalEnum.USER_IMITATE,
        order: 10
      },
      {
        label: 'SUPPORT.USER_EMIGRATION.HEADER',
        privilegeFunctionalityContext: getAvailableBids,
        onClick: UserModalEnum.USER_EMIGRATE,
        order: 20
      },
      {
        label: 'SUPPORT.USER_REVERT.HEADER',
        privilegeFunctionalityContext: revertChanges,
        onClick: UserModalEnum.USER_REVERT,
        order: 30
      }
    ]
  ), []);

  const { handleIsPrivileged, handlePrivilegeRequest } = usePrivilege(moduleContextData);
  const { handleSetMenuItem } = useSetMenuItems(moduleContextData);
  React.useEffect(
    () => {
      const functionalityContextList = [
        getUserInfoCurrent,
        getAvailableBids,
        revertChanges
      ];

      const asyncCall = async () => {
        try {
          await handlePrivilegeRequest(functionalityContextList);
          if (handleIsPrivileged(getUserInfoCurrent)) {
            dispatch(handleGetUserInfoCurrent());
          }
          dispatch(handlePrivilegeInitialized());
          await handleSetMenuItem(menuItems);
        } catch (e) {
          dispatch(handlePrivilegeInitializationFailed());
        }
      };
      asyncCall().then();
    },
    [handlePrivilegeRequest, dispatch, handleIsPrivileged, handleSetMenuItem, menuItems]
  );

  return null;
};

SupportInitialization.propTypes = propTypes;
SupportInitialization.defaultProps = defaultProps;

export default SupportInitialization;
