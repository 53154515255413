import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetImitateUserByUidFunctionalityContext extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.SUPP_USER_CREDENTIALS__GET_IMITATE_USER_CREDENTIALS);
  }

}

export default GetImitateUserByUidFunctionalityContext;