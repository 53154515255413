import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class VLDGetPriceGuaranteeListPFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_VLD__GET_PRICE_GUARANTEES);
  }

}

export default VLDGetPriceGuaranteeListPFC;
