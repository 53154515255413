import { GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE } from '../about/userInfo/userInfo';
import InitializationStatus from '../../../../common/utils/InitializationStatus';

const PRIVILEGE_INITIALIZATION_SUCCESS = 'SUPPORT__PRIVILEGE_INITIALIZATION_SUCCESS';
const PRIVILEGE_INITIALIZATION_FAILURE = 'SUPPORT__PRIVILEGE_INITIALIZATION_FAILURE';

const initialState = {
  status: InitializationStatus.NOT_STARTED
};

export default (state = initialState, action) => {
  switch (action.type) {
    // userInfo data is stored in the userInfo reducer
    case GET_USER_INFO_REQUEST: {
      return { ...state, status: InitializationStatus.STARTED };
    }
    case GET_USER_INFO_SUCCESS: {
      const status = action.payload != null ? InitializationStatus.COMPLETED_USER_INFO : InitializationStatus.FAILED;
      return { ...state, status: status };
    }
    case PRIVILEGE_INITIALIZATION_SUCCESS: {
      return { ...state, status: InitializationStatus.COMPLETED };
    }
    case GET_USER_INFO_FAILURE:
    case PRIVILEGE_INITIALIZATION_FAILURE: {
      return { ...state, status: InitializationStatus.FAILED };
    }
    default:
      return state;
  }
};

export const handlePrivilegeInitialized = () => {
  return {
    type: PRIVILEGE_INITIALIZATION_SUCCESS
  };
};

export const handlePrivilegeInitializationFailed = () => {
  return {
    type: PRIVILEGE_INITIALIZATION_FAILURE
  };
};
