/**
 * @link https://www.ag-grid.com/javascript-grid-context-menu/
 */
class MenuItemDefBuilder {

  /**
   *
   * @param {object} contextMenuItemsParams
   * @param {string} lsiCode
   */
  constructor(contextMenuItemsParams, lsiCode) {
    this._contextMenuItemsParams = contextMenuItemsParams;
    this._handleIsPrivileged = contextMenuItemsParams.context.onAgIsPrivileged;
    this._lsi = contextMenuItemsParams.context.agLsi;

    this._lsiCode = lsiCode;
  }

  /**
   * Build context menu item. {@link https://www.ag-grid.com/javascript-grid-context-menu/}
   *
   * @return context MenuItemDef
   */
  build = () => {
    const hidden = !this._handleIsPrivileged(this._privilegeFnCtx);

    if (hidden) {
      return null;
    }

    const name = this._name != null ? this._name : this._lsi.getLSIItem(this._lsiCode);

    return {
      name: name,
      icon: this._icon,
      disabled: this._disabled,
      action: this._action,
      tooltip: this._tooltip
    };
  };

  /**
   * @param {object} privilegeFnCtx - object based on AbstractFunctionalityContext
   * @return {MenuItemDefBuilder}
   */
  privilegeFunctionalityContext = (privilegeFnCtx) => {
    this._privilegeFnCtx = privilegeFnCtx;
    return this;
  };

  /**
   * @param {string} name
   * @return {MenuItemDefBuilder}
   */
  name = (name) => {
    this._name = name;
    return this;
  };

  /**
   * @param {boolean} disabled
   * @return {MenuItemDefBuilder}
   */
  disabled = (disabled) => {
    this._disabled = disabled;
    return this;
  };

  /**
   * @param {function} action
   * @return {MenuItemDefBuilder}
   */
  action = (action) => {
    this._action = action;
    return this;
  };

  /**
   * @param {HTMLElement|string} icon
   * @return {MenuItemDefBuilder}
   */
  icon = (icon) => {
    this._icon = icon;
    return this;
  };

  /**
   * @param {string} path
   * @return {MenuItemDefBuilder}
   */
  iconFromPath = (path) => {
    this._icon = renderSVGIcon(path);
    return this;
  };

  /**
   * @param {string} tooltip
   * @return {MenuItemDefBuilder}
   */
  tooltip = (tooltip) => {
    this._tooltip = tooltip;
    return this;
  };

}

MenuItemDefBuilder.prototype._contextMenuItemsParams = undefined;
MenuItemDefBuilder.prototype._handleIsPrivileged = undefined;
MenuItemDefBuilder.prototype._lsi = undefined;

MenuItemDefBuilder.prototype._lsiCode = undefined;

MenuItemDefBuilder.prototype._name = undefined;
MenuItemDefBuilder.prototype._disabled = undefined;
MenuItemDefBuilder.prototype._action = undefined;
MenuItemDefBuilder.prototype._icon = undefined;
MenuItemDefBuilder.prototype._tooltip = undefined;

/**
 * @param {string} iconPath
 * @return {string}
 */
const renderSVGIcon = (iconPath) => {
  return iconPath && `
<!--        <svg viewBox="0 0 24 24" style="height: 0.8rem; width: 0.8rem">-->
        <svg viewBox="0 0 24 24" style="height: 16px; width: 16px; margin-bottom: -3px">
            <path d="${iconPath}" />
        </svg>
    `;
};

export default MenuItemDefBuilder;
