import PropTypes from 'prop-types';
import React from 'react';
import {
  BranchATSPeriodsViewDTO,
  ColumnDataDTO,
  DealerContractsATSPeriodsFilterDataDTO
} from '@ovex/annual-target-web-api';

import { FormsContextSection } from '../../../common/components/forms';
import { Page } from '../../../common/components';
import { LsiContext } from '../../../common/contexts';
import { useAlertBusOvex } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';

import DealerContractsATSPeriodsViewButtonBar from './DealerContractsATSPeriodsViewButtonBar/DealerContractsATSPeriodsViewButtonBar';
import DealerContractsATSPeriodsViewFilter from './DealerContractsATSPeriodsViewFilter/DealerContractsATSPeriodsViewFilter';
import DealerContractsATSPeriodsViewTable from './DealerContractsATSPeriodsViewTable/DealerContractsATSPeriodsViewTable';
import { exportExcel, exportExcelWithAggregation } from './DealerContractsATSPeriodsViewTable/DealerContractsATSPeriodsViewTable.exportParams';

import './DealerContractsATSPeriodsView.scss';

const propTypes = {
  branchATSPeriodsViewList: PropTypes.arrayOf(PropTypes.instanceOf(BranchATSPeriodsViewDTO)),
  columnDataList: PropTypes.arrayOf(PropTypes.instanceOf(ColumnDataDTO)),
  filterData: PropTypes.instanceOf(DealerContractsATSPeriodsFilterDataDTO),
  isFetching: PropTypes.bool,
  onGetDealerContractsATSPeriodsViewList: PropTypes.func.isRequired,
  valuesFilter: PropTypes.object
};

const defaultProps = {
  branchATSPeriodsViewList: null,
  columnDataList: null,
  filterData: null,
  isFetching: true,
  valuesFilter: null
};

const DealerContractsATSPeriodsView = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const valuesFilterSubmitted = props.valuesFilter || DealerContractsATSPeriodsViewFilter.valuesDefault;
  const onGetDealerContractsATSPeriodsViewList = props.onGetDealerContractsATSPeriodsViewList;
  const handleReloadDealerContractsATSPeriodsView = React.useCallback(
    (filterValues) => {
      const asyncCall = async () => {
        try {
          await onGetDealerContractsATSPeriodsViewList(filterValues);
        } catch (e) {
          handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_DEALER_CONTRACTS_ATS_PERIODS_VIEW_LIST_FAILURE', AlertTypeEnum.WARNING);
        }
      };
      asyncCall().then();
    },
    [onGetDealerContractsATSPeriodsViewList, handleAddAlertSimple]
  );
  React.useEffect(
    () => handleReloadDealerContractsATSPeriodsView(DealerContractsATSPeriodsViewFilter.valuesDefault),
    [handleReloadDealerContractsATSPeriodsView]
  );

  const aggRef = React.useRef(null);

  const handleExport = React.useCallback(
    () => aggRef.current && aggRef.current.api.exportDataAsExcel(exportExcel),
    []
  );
  const handleExportWithAggregation = React.useCallback(
    () => aggRef.current && aggRef.current.api.exportDataAsExcel(exportExcelWithAggregation),
    []
  );

  return (
    <Page
      className={'ovex-DealerContractsATSPeriodsView'}
      header={lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.ANNUAL_TARGET_IMPORTER_VIEW_ATS_PERIODS')}
      loading={props.isFetching}
    >
      <FormsContextSection>
        <DealerContractsATSPeriodsViewFilter
          filterData={props.filterData}
          onSubmit={handleReloadDealerContractsATSPeriodsView}
          valuesFilterSubmitted={valuesFilterSubmitted}
        />
        <DealerContractsATSPeriodsViewButtonBar
          onExport={handleExport}
          onExportWithAggregation={handleExportWithAggregation}
        />
      </FormsContextSection>
      <DealerContractsATSPeriodsViewTable
        branchATSPeriodsViewList={props.branchATSPeriodsViewList}
        dynamicColumnDataList={props.columnDataList}
        ref={aggRef}
      />
    </Page>
  );
});

DealerContractsATSPeriodsView.propTypes = propTypes;
DealerContractsATSPeriodsView.defaultProps = defaultProps;

export default DealerContractsATSPeriodsView;