import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecCurrentInteriorNegCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__CURRENT__INTERIOR_NEG;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.CURRENT;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.INTERIOR_NEG;
  }

}

const specCurrentInteriorNegCUID = new SpecCurrentInteriorNegCUID();

export default specCurrentInteriorNegCUID;