import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonBar } from '../../../../common/components';
import { FilterContextButtons } from '../../../../common/components/forms';
import { LsiContext } from '../../../../common/contexts';

const propTypes = {
  onExport: PropTypes.func

};

const defaultProps = {
  onExport: undefined
};

const PriceGuaranteeReportButtonBar = (props) => {
  const lsi = React.useContext(LsiContext);
  const { onExport } = props;

  return (
    <ButtonBar
      className='ovex-pg-PriceGuaranteeReportButtonBar'
    >
      <Button
        content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.EXPORT')}
        onClick={onExport}
        type={Button.type.PRIMARY}
      />
      <ButtonBar.Separator />
      <FilterContextButtons />
    </ButtonBar>
  );
};

PriceGuaranteeReportButtonBar.propTypes = propTypes;
PriceGuaranteeReportButtonBar.defaultProps = defaultProps;

export default PriceGuaranteeReportButtonBar;
