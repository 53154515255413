import React from 'react';
import PropTypes from 'prop-types';
import './BaseTooltip.scss';

const propTypes = {
  className: PropTypes.string
};

const defaultProps = {
  className: null
};

/**
this component is only wrapper for Tooltip
*/
const BaseTooltip = (props) => {

  return (
    <div className={['BaseTooltip', props.className].join(' ')}>
      {props.children}
    </div>
  );
};

BaseTooltip.propTypes = propTypes;
BaseTooltip.defaultProps = defaultProps;

export default BaseTooltip;