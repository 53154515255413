import React from 'react';
import PropTypes from 'prop-types';
import { AnnualTargetSalesPreviewTableContentDTO, AnnualTargetSalesPreviewTableFooterDTO, AnnualTargetSalesPreviewTableHeaderDTO } from '@ovex/annual-target-web-api';

import { OvexAGTable } from '../../../../common/components/ag-grid';

const propTypes = {
  heading: PropTypes.string.isRequired,
  tableContent: PropTypes.arrayOf(PropTypes.instanceOf(AnnualTargetSalesPreviewTableContentDTO)).isRequired,
  tableFooter: PropTypes.instanceOf(AnnualTargetSalesPreviewTableFooterDTO).isRequired,
  tableHeader: PropTypes.instanceOf(AnnualTargetSalesPreviewTableHeaderDTO).isRequired
};

const defaultProps = {};

const agDefaultColDef = {
  suppressMovable: true,
  menuTabs: []
};

const getPeriodColumn = (idx, periodHeaderList) => ({
  headerName: periodHeaderList[idx].headerName,
  valueGetter: (valueGetterParams) => {
    return valueGetterParams.data && valueGetterParams.data.atSalesPeriodList && valueGetterParams.data.atSalesPeriodList[idx].periodNumber;
  },
  width: 100,
  cellStyle: params => params.data.rowType === 'footer' ? { 'font-weight': 'bold' } : { 'font-weight': '' },
  type: 'numericColumn'
});

const ContractAnnualTargetSalesPreviewTable = (props) => {

  const columnDefs = React.useMemo(() => ([
    {
      headerName: props.tableHeader.branchDealerNumber,
      valueGetter: 'data && data.branchDealerNumber',
      width: 194,
      cellStyle: params => params.data.rowType === 'footer' ? { 'text-align': 'right' } : { 'font-weight': 'bold' }
    },
    getPeriodColumn(0, props.tableHeader.periodHeaderList),
    getPeriodColumn(1, props.tableHeader.periodHeaderList),
    getPeriodColumn(2, props.tableHeader.periodHeaderList),
    getPeriodColumn(3, props.tableHeader.periodHeaderList),
    {
      headerName: props.tableHeader.annualTargetSalesBranchTotalNumber,
      valueGetter: 'data && data.branchTotalNumber',
      width: 120,
      cellStyle: { 'font-weight': 'bold' },
      type: 'numericColumn'
    }
  ]),
  [props.tableHeader]
  );

  const rowData = React.useMemo(() => ([
    ...props.tableContent,
    // footer object must correspond with content object
    {
      rowType: 'footer',
      branchDealerNumber: props.tableFooter.annualTargetSalesTotal,
      atSalesPeriodList: props.tableFooter.periodTotalList,
      branchTotalNumber: props.tableFooter.totalNumber
    }
  ]), [props.tableContent, props.tableFooter]);

  return (
    <div
      className='ovex-at-ContractPreview-tableBlock'
    >
      <div
        className='ovex-at-ContractPreview-tableBlock-header'
      >
        {props.heading}
      </div>
      <div
        className='ovex-at-ContractPreview-tableBlock-table'
      >
        <OvexAGTable
          columnDefs={columnDefs}
          defaultColDef={agDefaultColDef}
          domLayout='print'
          rowData={rowData}
        />
      </div>
    </div>
  );
};

ContractAnnualTargetSalesPreviewTable.propTypes = propTypes;
ContractAnnualTargetSalesPreviewTable.defaultProps = defaultProps;

export default ContractAnnualTargetSalesPreviewTable;
