
const AnnualTargetRoles = {
  AMS: 'OVEX3.AMS',
  GEKO: 'OVEX3.AT-GEKO',
  DEALER_SALES_MANAGER: 'OVEX3.AT-DEALER-SALES-MANAGER',
  DEALER_STATUTORY_REPRESENTATIVE: 'OVEX3.AT-DEALER-STATUTORY-REPRESENTATIVE',
  IMPORTER_AREA_ADVISOR: 'OVEX3.AT-IMPORTER-AREA-ADVISOR',
  IMPORTER_AREA_ADVISOR_MANAGER: 'OVEX3.AT-IMPORTER-AREA-ADVISOR-MANAGER',
  IMPORTER_PLANNING_MANAGER: 'OVEX3.AT-IMPORTER-PLANNING-MANAGER'
};

export default AnnualTargetRoles;