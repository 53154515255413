import { Common } from 'uu5g04';

import vocabulary_cs from '../../../../assets/vocabulary/vocabulary_cs.json';
import vocabulary_en from '../../../../assets/vocabulary/vocabulary_en.json';
import vocabulary_sk from '../../../../assets/vocabulary/vocabulary_sk.json';
//
import { LOCALE } from '../../../../utils/consts';
import { resolveObjectContent } from '../../utils/helpers';
import { labelNotFoundFormatter } from '../../utils/formatters';

const getVocabulary = (locale) => {
  if (locale === LOCALE.cs) {
    return vocabulary_cs;
  } else if (locale === LOCALE.en) {
    return vocabulary_en;
  } else if (locale === LOCALE.sk) {
    return vocabulary_sk;
  }
  return vocabulary_cs;
};

class LsiVocabulary {

  constructor(locale) {
    this.locale = locale;
    this.vocabulary = getVocabulary(locale);
    Common.Tools.setLanguage(locale);
  }

  getLocale = () => {
    return this.locale;
  };

  getLSIItem = (label, params) => {
    let result = resolveObjectContent(label, this.vocabulary);
    result = this._replaceParams(result, params);

    return result ? result : labelNotFoundFormatter(label);
  };

  _replaceParams = (result, params) => {
    if (result && params) {
      result = Common.Tools.formatString(result, params);
    }
    return result;
  };
}

export default LsiVocabulary;