import PropTypes from 'prop-types';
import React from 'react';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import './Checkboxes.scss';

const CheckboxesComponent = (props, ref) => {

  const handleChange = (opt) => {
    opt.component.onChangeDefault(opt);
    props.onChange != null && props.onChange(opt.value);
  };

  return (
    <Forms.Checkboxes
      className={['ovex-forms-checkboxes', props.className].join(' ')}
      colorSchema='green'
      colWidth={props.colWidth}
      { ...(!!props.inputColWidth && { inputColWidth: props.inputColWidth }) }
      label={props.label}
      { ...(!!props.labelColWidth && { labelColWidth: props.labelColWidth }) }
      name={props.name}
      onChange={handleChange}
      parent={props.parent}
      readOnly={props.readOnly}
      ref_={ref}
      required={props.required}
      selectionBackground={false}
      tooltip={props.tooltip}
      type={props.type}
      value={props.value}
    />
  );
};

const Checkboxes = React.forwardRef(CheckboxesComponent);
Checkboxes.isUu5PureComponent = true; // for compatibility with uu5 content-mixin and transfer the parent to children (content-mixin#expandChildProps)

CheckboxesComponent.propTypes = {
  className: PropTypes.string,
  colWidth: PropTypes.string,
  inputColWidth: PropTypes.string,
  label: PropTypes.string,
  labelColWidth: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  parent: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf([1, 2]),
  value: PropTypes.object
};

CheckboxesComponent.defaultProps = {
  className: null,
  colWidth: null,
  decimals: null,
  inputColWidth: null,
  label: null,
  labelColWidth: null,
  name: null,
  onChange: null,
  parent: null,
  readOnly: false,
  required: false,
  tooltip: null,
  type: 1,
  value: false
};

export default Checkboxes;
