import PropTypes from 'prop-types';
import React from 'react';
import { Bricks, Forms } from 'uu5g04';
import 'uu5g04/bricks';
import 'uu5g04/forms';
import { AnnualTargetYearATSalesPeriodDTO, NegotiationATSalesPeriodDTO, NegotiationATPeriodDTO } from '@ovex/annual-target-web-api';

import { DateRangePicker, Number, Text } from '../../../../../common/components/forms';
import { LsiContext } from '../../../../../common/contexts';
import { NegotiationTypeEnum } from '../../../../utils/const';
import { QuarterComponentForm } from '../../../../components';
import { updateNegValuesValues, getValueForCorrection, initializeNeg } from '../../../../components/QuarterPeriods/QuarterComponentForm.utils';
import { isReadOnlyInput } from '../NegotiationModalInputRules';

import './NegotiationHeaderForm.scss';

export const InputNames = {
  coefficient: 'coefficient',
  name: 'name',
  quarters: 'quarters',
  proposalApproval: 'proposalApproval',
  proposalApprovalFromIdx: 0,
  proposalApprovalToIdx: 1,
  proposalDealer: 'proposalDealer',
  proposalDealerFromIdx: 0,
  proposalDealerToIdx: 1,
  year: 'year'
};

const NegotiationHeaderFormComponent = (props, ref) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = !props.onSave;

  const negotiationType = props.negotiationType;

  const classNames = ['ovex-NegotiationHeaderForm'];
  props.className && classNames.push(props.className);

  const formRef = React.useRef(null);
  const handleGetFormRef = React.useCallback(
    (uuFormRef) => {
      formRef.current = uuFormRef;
    },
    []
  );

  React.useImperativeHandle(
    ref,
    () => ({
      isValid: () => formRef.current.isValid(),
      save: () => formRef.current.save()
    })
  );

  const [ atSalesPeriodList, setAtSalesPeriodList ] = React.useState( null);
  const [ atPeriodList, setAtPeriodList ] = React.useState(null);
  const [ negotiationName, setNegotiationName ] = React.useState(null);
  const [ proposalDealerFrom, setProposalDealerFrom ] = React.useState(null);
  const [ proposalDealerTo, setProposalDealerTo ] = React.useState(null);
  const [ proposalApprovalFrom, setProposalApprovalFrom ] = React.useState(null);
  const [ proposalApprovalTo, setProposalApprovalTo ] = React.useState(null);

  React.useEffect(
    () => {
      setAtPeriodList(initializeNeg(props.atPeriodList, 'ROC', negotiationType));
    },
    [props.atPeriodList, negotiationType]
  );

  React.useEffect(
    () => {
      setAtSalesPeriodList(initializeNeg(props.atSalesPeriodList, 'RCP', negotiationType));
    },
    [props.atSalesPeriodList, negotiationType]
  );

  React.useEffect(
    () => {
      setNegotiationName(props.negotiationName);
    },
    [props.negotiationName]
  );

  React.useEffect(
    () => {
      setProposalDealerFrom(props.proposalDealerFrom);
    },
    [props.proposalDealerFrom]
  );

  React.useEffect(
    () => {
      setProposalDealerTo(props.proposalDealerTo);
    },
    [props.proposalDealerTo]
  );

  React.useEffect(
    () => {
      setProposalApprovalFrom(props.proposalApprovalFrom);
    },
    [props.proposalApprovalFrom]
  );

  React.useEffect(
    () => {
      setProposalApprovalTo(props.proposalApprovalTo);
    },
    [props.proposalApprovalTo]
  );

  const onSave = props.onSave;
  const handleSave = React.useCallback(
    () => {

      const result = {
        name: negotiationName,
        proposalApprovalFrom: proposalApprovalFrom,
        proposalApprovalTo: proposalApprovalTo,
        proposalDealerFrom: proposalDealerFrom,
        proposalDealerTo: proposalDealerTo,
        atSalesPeriodList: atSalesPeriodList,
        atPeriodList: atPeriodList
      };

      onSave && onSave({ formId: props.formId, values: result });
    },
    [onSave, props.formId, atSalesPeriodList, atPeriodList, negotiationName, proposalDealerFrom,
      proposalDealerTo, proposalApprovalFrom, proposalApprovalTo]
  );

  const handleUpdatePeriodLists = (value, type) => {
    if (type === 'ROC' || type === 'CORR') {
      const updatedPerList = getValueForCorrection(updateNegValuesValues(atPeriodList, value, type, negotiationType), negotiationType);
      setAtPeriodList(updatedPerList);
      if (type === 'ROC') {
        props.updateModels && props.updateModels(updatedPerList);
      }
    } else if (type === 'RCP') {
      setAtSalesPeriodList(updateNegValuesValues(atSalesPeriodList, value, type, negotiationType));
    }
  };

  const handleChangeNegName = (opt) => {
    setNegotiationName(opt.value);
  };

  const handleChangeRangeDealer = (opt) => {
    setProposalDealerFrom(opt.value[InputNames.proposalDealerFromIdx]);
    setProposalDealerTo(opt.value[InputNames.proposalDealerToIdx]);
  };

  const handleChangeRangeApproval = (opt) => {
    setProposalApprovalFrom(opt.value[InputNames.proposalApprovalFromIdx]);
    setProposalApprovalTo(opt.value[InputNames.proposalApprovalToIdx]);
  };

  return (
    <Forms.Form
      className={classNames.join(' ')}
      onSave={handleSave}
      ref_={handleGetFormRef}
    >
      <Text
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.YEAR')}
        name={InputNames.year}
        readOnly={isReadOnlyInput(props.editRules, InputNames.year, readOnly)}
        value={props.year.toString()}
      />
      <Text
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.NAME')}
        maxLength={30}
        name={InputNames.name}
        onChange={handleChangeNegName}
        readOnly={isReadOnlyInput(props.editRules, InputNames.name, readOnly)}
        required
        value={negotiationName}
      />
      { negotiationType !== NegotiationTypeEnum.CLOSURE &&
        <>
          <Bricks.Row>
            <Bricks.Column colWidth='xs6'>
              <DateRangePicker
                label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.PROPOSAL_DEALER_SETTINGS')}
                name={InputNames.proposalDealer}
                onChange={handleChangeRangeDealer}
                readOnly={isReadOnlyInput(props.editRules, InputNames.proposalDealer, readOnly)}
                required
                value={[proposalDealerFrom, proposalDealerTo]}
              />
            </Bricks.Column>
            <Bricks.Column colWidth='xs6'>
              <DateRangePicker
                label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.PROPOSAL_APPROVAL_SETTINGS')}
                name={InputNames.proposalApproval}
                onChange={handleChangeRangeApproval}
                readOnly={isReadOnlyInput(props.editRules, InputNames.proposalApproval, readOnly)}
                required
                value={[proposalApprovalFrom, proposalApprovalTo]}
              />
            </Bricks.Column>
          </Bricks.Row>
          <Number
            decimals={2}
            label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.COEFFICIENT_RCP_ROC')}
            max={10}
            min={0}
            name={InputNames.coefficient}
            readOnly={isReadOnlyInput(props.editRules, InputNames.coefficient, readOnly)}
            required
            step={0.01}
            tooltip={lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COEFFICIENT_RCP_ROC')}
            value={props.coefficient}
          />
          <QuarterComponentForm
            atPeriodList={atPeriodList}
            atSalesPeriodList={atSalesPeriodList}
            hiddenATPeriodCorrection={negotiationType === NegotiationTypeEnum.CONTRACT}
            onUpdate={handleUpdatePeriodLists}
            readOnly={isReadOnlyInput(props.editRules, InputNames.quarters, readOnly)}
            readOnlyMinAchievementRatio
          />
        </>
      }
    </Forms.Form>
  );
};

const NegotiationHeaderForm = React.memo(React.forwardRef(NegotiationHeaderFormComponent));

NegotiationHeaderFormComponent.propTypes = {
  atPeriodList: PropTypes.arrayOf(PropTypes.instanceOf(NegotiationATPeriodDTO)).isRequired,
  atSalesPeriodList: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.instanceOf(AnnualTargetYearATSalesPeriodDTO),
    PropTypes.instanceOf(NegotiationATSalesPeriodDTO)
  ])).isRequired,
  coefficient: PropTypes.number.isRequired,
  creationMode: PropTypes.bool,
  editRules: PropTypes.arrayOf(PropTypes.string),
  formId: PropTypes.string.isRequired,
  negotiationName: PropTypes.string,
  negotiationType: PropTypes.oneOf(Object.keys(NegotiationTypeEnum)).isRequired,
  onSave: PropTypes.func,
  proposalApprovalFrom: PropTypes.instanceOf(Date),
  proposalApprovalTo: PropTypes.instanceOf(Date),
  proposalDealerFrom: PropTypes.instanceOf(Date),
  proposalDealerTo: PropTypes.instanceOf(Date),
  updateModels: PropTypes.func,
  year: PropTypes.number.isRequired
};

NegotiationHeaderFormComponent.defaultProps = {
  creationMode: false,
  editRules: null,
  negotiationName: null,
  negotiationType: null,
  onSave: null,
  proposalApprovalFrom: null,
  proposalApprovalTo: null,
  proposalDealerFrom: null,
  proposalDealerTo: null,
  updateModels: null
};

export default NegotiationHeaderForm;
