import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class CreatePriceGuaranteePFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PG__CREATE_PRICE_GUARANTEE);
  }

}

export default CreatePriceGuaranteePFC;