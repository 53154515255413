
const InitializationStatus = {
  /**
   * Status when initialization not started.
   */
  NOT_STARTED: 'NOT_STARTED',

  /**
   * Status when initialization started.
   */
  STARTED: 'STARTED',

  /**
   * Status when the user is logged in and his userInfo is loaded.
   */
  COMPLETED_USER_INFO: 'COMPLETED_USER_INFO',
  /**
   * Status when the privilege is checked for logged user.
   * @deprecated - initialization of privilege are actually last step, so it is not necessary to use this
   */
  COMPLETED_PRIVILEGE: 'COMPLETED_PRIVILEGE',

  /**
   * Final status when initialization successfully finished.
   */
  COMPLETED: 'COMPLETED',

  /**
   * Final status when initialization failed.
   */
  FAILED: 'FAILED',

  isFinalStatus: (status) => [InitializationStatus.COMPLETED, InitializationStatus.FAILED].includes(status)
};

export default InitializationStatus;