import { PrivilegeApi } from '@ovex/privilege-web-api';
import {
  UserInfoApi
} from '@ovex/about-web-api';
import {
  PriceGuaranteeItemsApi,
  PriceGuaranteeItemsPublishedApi,
  PriceGuaranteesApi,
  CommissionValidatorApi,
  PriceGuaranteeReportApi,
  PriceGuaranteesAccountingApi,
  PriceGuaranteeRefreshStorageApi
} from '@ovex/price-guarantee-web-api';

import ApiClientInstances from './ApiClientInstances';

/**
 * Api service instances
 */
const ApiServiceInstances = {
  about: {
    userInfoApi: new UserInfoApi(ApiClientInstances.about)
  },
  privilege: {
    privilegeApi: new PrivilegeApi(ApiClientInstances.privilege)
  },

  priceGuarantee: {
    priceGuaranteeItemsApi: new PriceGuaranteeItemsApi(ApiClientInstances.priceGuarantee),
    priceGuaranteeItemsPublishedApi: new PriceGuaranteeItemsPublishedApi(ApiClientInstances.priceGuarantee),
    priceGuaranteeApi: new PriceGuaranteesApi(ApiClientInstances.priceGuarantee),
    commissionValidatorApi: new CommissionValidatorApi(ApiClientInstances.priceGuarantee),
    priceGuaranteeReportApi: new PriceGuaranteeReportApi(ApiClientInstances.priceGuarantee),
    priceGuaranteeAccountingBasisApi: new PriceGuaranteesAccountingApi(ApiClientInstances.priceGuarantee),
    priceGuaranteeRefreshApi: new PriceGuaranteeRefreshStorageApi(ApiClientInstances.priceGuarantee)
  }
};

export default ApiServiceInstances;
