import {
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiAccountCheckOutline,
  mdiAccountEditOutline,
  mdiCloseCircleOutline,
  mdiEarth, mdiTextBoxSearchOutline
} from '@mdi/js';

import {
  UpdatePriceGuaranteePFC,
  ShiftPriceGuaranteeStatusPFC,
  GetPriceGuaranteeListPFC
} from '../../../privilege/context';
import { MenuItemDefBuilder } from '../../../../common/components/ag-grid';
import { getExportExcelSkipRowGroupsItem } from '../../../../common/components/ag-grid/contextMenu/excelExport';
import { PriceGuaranteeStatusEnum } from '../../../utils/const';

import { exportExcel } from './PriceGuaranteeTable.exportParams';

export const getContextMenuItems = (params) => {
  return [
    PriceGuaranteeMenuItems.getUpdatePriceGuaranteeItem(params),
    PriceGuaranteeMenuItems.getDetailPriceGuaranteeItem(params),
    'separator',
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToConfirmedItem(params),
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToValidatedItem(params),
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToPublishedItem(params),
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToCreatedItem(params),
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToReturnedToCorrectionItem(params),
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToCorrectionConfirmedItem(params),
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToCorrectionValidatedItem(params),
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToCompletedItem(params),
    PriceGuaranteeMenuItems.getShiftPriceGuaranteeStatusToDeletedItem(params),
    'separator',
    getExportExcelSkipRowGroupsItem(params, exportExcel)
  ];
};

const PriceGuaranteeMenuItems = {
  getUpdatePriceGuaranteeItem: (params) => {
    const data = params.node.data;
    return new MenuItemDefBuilder(params, 'PRICE_GUARANTEE.CONTEXT_MENU.EDIT')
      .iconFromPath(mdiPencilOutline)
      .action(() => params.context?.onShowPriceGuaranteeUpdateModalForm(data))
      .privilegeFunctionalityContext(new UpdatePriceGuaranteePFC(data.id))
      .build();
  },
  getDetailPriceGuaranteeItem: (params) => {
    const data = params.node.data;
    return new MenuItemDefBuilder(params, 'PRICE_GUARANTEE.CONTEXT_MENU.SHOW_DETAIL')
      .iconFromPath(mdiTextBoxSearchOutline)
      .action(() => params.context?.onShowPGDetail(data))
      .privilegeFunctionalityContext(new GetPriceGuaranteeListPFC())
      .build();
  },
  getShiftPriceGuaranteeStatusToConfirmedItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.CONFIRMED),
  getShiftPriceGuaranteeStatusToValidatedItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.VALIDATED),
  getShiftPriceGuaranteeStatusToPublishedItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.PUBLISHED),
  getShiftPriceGuaranteeStatusToCreatedItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.CREATED),
  getShiftPriceGuaranteeStatusToReturnedToCorrectionItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.RETURNED_TO_CORRECTION),
  getShiftPriceGuaranteeStatusToCorrectionConfirmedItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.CORRECTION_CONFIRMED),
  getShiftPriceGuaranteeStatusToCorrectionValidatedItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.CORRECTION_VALIDATED),
  getShiftPriceGuaranteeStatusToCompletedItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.COMPLETED),
  getShiftPriceGuaranteeStatusToDeletedItem: (params) => getShiftPriceGuaranteeStatusToItem(params, PriceGuaranteeStatusEnum.DELETED)
};

const getShiftPriceGuaranteeStatusToItem = (params, priceGuaranteeStatusTo) => {
  const dataId = params.node.data.id;
  return new MenuItemDefBuilder(params, `PRICE_GUARANTEE.CONTEXT_MENU.PRICE_GUARANTEE_SHIFT_STATUS_TO.${priceGuaranteeStatusTo}`)
    .iconFromPath(getIconPathForStatus(priceGuaranteeStatusTo))
    .action(() => params.context?.onShiftPriceGuaranteeStatus(dataId, priceGuaranteeStatusTo))
    .privilegeFunctionalityContext(new ShiftPriceGuaranteeStatusPFC(dataId, priceGuaranteeStatusTo))
    .build();
};

const getIconPathForStatus = (status) => {
  switch (status) {
    case PriceGuaranteeStatusEnum.CONFIRMED:
    case PriceGuaranteeStatusEnum.CORRECTION_CONFIRMED:
    case PriceGuaranteeStatusEnum.VALIDATED:
    case PriceGuaranteeStatusEnum.CORRECTION_VALIDATED: {
      return mdiAccountCheckOutline;
    }
    case PriceGuaranteeStatusEnum.COMPLETED: {
      return mdiCloseCircleOutline;
    }
    case PriceGuaranteeStatusEnum.PUBLISHED: {
      return mdiEarth;
    }
    case PriceGuaranteeStatusEnum.CREATED:
    case PriceGuaranteeStatusEnum.RETURNED_TO_CORRECTION: {
      return mdiAccountEditOutline;
    }
    case PriceGuaranteeStatusEnum.DELETED: {
      return mdiDeleteOutline;
    }
    default: {
      return null;
    }
  }
};
