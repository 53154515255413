import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import { packageListFormatter } from '../../ValidationResultPGItemTable.helpers';

class SpecCurrentPackageCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__CURRENT__PACKAGE;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.CURRENT;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.PACKAGE;
  }
  /**
   * @override
   * @param {CommissionSpecificationDTOVLD} commission
   */
  commissionConverterValueGetter = (commission) => {
    return packageListFormatter(commission.commissionCurrentData.carSpecification.packageList);
  }

}

const specCurrentPackageCUID = new SpecCurrentPackageCUID();

export default specCurrentPackageCUID;