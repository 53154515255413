/**
 * @param {Array<*>} allLeafChildren - list of AG-Grid nodes
 * @return {number}
 */
export const calculateModelGroupChildCount = (allLeafChildren) => {
  return allLeafChildren ? allLeafChildren.filter(childNode => childNode.data.isRowStatusEditable()).length : 0;
};

export const getVirtualRows = (allLeafChildren) => {
  return allLeafChildren && allLeafChildren
    .filter(childNode => childNode.data.isRowStatusVirtual())
    .map(childNode => childNode.data);
};

/**
 * @param {Array.<*>} allLeafChildren - list of AG Grid row nodes
 * @param gridApi - AG Grid API
 * @param virtualRowList - list of virtual rows
 */
export const discardChangesBeforeEditEnd = (allLeafChildren, gridApi, virtualRowList) => {
  const dataToRemoveList = [];
  const dataToUpdateList = [];
  allLeafChildren.forEach(nodeChild => {
    const rowData = nodeChild.data;

    if (rowData.isModified()) {
      if (rowData.isRowStatusNew()) {
        dataToRemoveList.push(rowData);
      } else {
        rowData.discardChanges();
        dataToUpdateList.push(rowData);
      }
    }
  });
  gridApi.applyTransaction({
    add: virtualRowList,
    remove: dataToRemoveList,
    update: dataToUpdateList
  });
};

/**
 * @param {Array.<*>} allLeafChildren - list of AG Grid row nodes
 * @param gridApi - AG Grid API
 */
export const selectFirstInvalidCell = (allLeafChildren, gridApi) => {
  const firstInvalidNode = allLeafChildren.find(nodeChild => !nodeChild.data.isValid());
  const firstInvalidColumnId = firstInvalidNode?.data.getFirstInvalidColumnId();

  if (firstInvalidNode != null && firstInvalidColumnId != null) {
    const rowIndex = firstInvalidNode.rowIndex;
    gridApi.ensureIndexVisible(rowIndex, 'middle');
    gridApi.ensureColumnVisible(firstInvalidColumnId);

    gridApi.setFocusedCell(rowIndex, firstInvalidColumnId, null);

    gridApi.flashCells({ rowNodes: [firstInvalidNode], columns: [firstInvalidColumnId] });
  }
};
