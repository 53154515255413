import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { usePrivilege } from '../../../hooks';
import PrivilegeFunctionalityContextPropType from '../../../privilege/PrivilegeFunctionalityContextPropType';
import ButtonType from '../ButtonType';

import DropdownTypeProps from './DropdownTypeProps';

import './Dropdown.scss';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledWhenItemsEmpty: PropTypes.bool,
  iconHidden: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    privilegeFunctionalityContext: PrivilegeFunctionalityContextPropType.propType
  })),
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  type: PropTypes.oneOf(Object.values(ButtonType))
};

const defaultProps = {
  className: null,
  disabled: false,
  disabledWhenItemsEmpty: false,
  iconHidden: false,
  items: null,
  style: null,
  type: null
};

const Dropdown = (props) => {
  const typeProps = DropdownTypeProps[props.type] || DropdownTypeProps.DEFAULT;

  const classNames = ['ovex-Dropdown', typeProps.className];
  if (props.className != null) {
    classNames.push(props.className);
  }

  const { handleIsPrivileged } = usePrivilege();
  const dropdownItems = props.items && props.items.filter(item => handleIsPrivileged(item[PrivilegeFunctionalityContextPropType.propName]));

  const disabledInternal = props.disabledWhenItemsEmpty && (dropdownItems != null && dropdownItems.length === 0);

  return (
    <Bricks.Dropdown
      bgStyle={typeProps.bgStyle}
      borderRadius={typeProps.borderRadius}
      className={classNames.join(' ')}
      colorSchema={typeProps.colorSchema}
      disabled={props.disabled || disabledInternal}
      elevation={typeProps.elevation}
      iconHidden={props.iconHidden}
      items={dropdownItems}
      label={props.label}
      style={props.style}
    />
  );
};

Dropdown.type = ButtonType;

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;