import PropTypes from 'prop-types';
import React from 'react';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { isString } from '../../utils/helpers';
import { Loading } from '../../../common/components';
import { LsiContext, TitleContext } from '../../../common/contexts';

import './Page.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  loading: PropTypes.bool,
  title: PropTypes.string
};

const defaultProps = {
  className: undefined,
  header: undefined,
  loading: undefined,
  title: undefined
};

const pageTitleFormatter = (lsi) => (title) => {
  const titlePrefix = lsi.getLSIItem('APP_NAME');
  if (title) {
    return `${titlePrefix} | ${title}`;
  }
  return titlePrefix;
};

const Page = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const setTitle = React.useContext(TitleContext);

  React.useEffect(
    () => {
      let title;
      if (props.title) {
        title = props.title;
      } else if (isString(props.header)) {
        title = props.header;
      }
      setTitle(pageTitleFormatter(lsi)(title));
    },
    [props.title, props.header, lsi, setTitle]
  );

  return (
    <Bricks.Container
      className={['ovex-page', props.className].join(' ')}
      header={props.header}
      level='2'
      noSpacing
    >
      { props.loading != null &&
        <Loading loading={props.loading} />
      }
      {props.children}
    </Bricks.Container>
  );
});

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
