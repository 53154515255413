import React from 'react';
import PropTypes from 'prop-types';
import { mdiAlertOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Bricks } from 'uu5g04';

import { LsiContext } from '../../../../common/contexts';
import './UserDetailInfoNotFound.scss';

const propTypes = {
  header: PropTypes.string,
  userInfo: PropTypes.object,
  userInfoCurrent: PropTypes.object
};
const defaultProps = {
  fetching: true,
  header: null,
  onClose: null,
  userInfo: null,
  userInfoCurrent: null
};
const UserDetailInfoNotFound = () => {
  const lsi = React.useContext(LsiContext);

  return (
    <Bricks.Well
      borderRadius='4px'
      className='ovex-UserDetailInfoNotFound'
      colorSchema='warning'
      elevation='1'
    >
      <Bricks.Div
        className='ovex-UserDetailInfoNotFound-icon'
      >
        <Icon
          path={mdiAlertOutline}
          size={1.2}
        />
      </Bricks.Div>
      <Bricks.Div
        className='ovex-UserDetailInfoNotFound-message'
      >
        <p>{lsi.getLSIItem('SUPPORT.WARNING.NO_DATA')}</p>
      </Bricks.Div>
    </Bricks.Well>
  );
};

UserDetailInfoNotFound.propTypes = propTypes;
UserDetailInfoNotFound.defaultProps = defaultProps;

export default UserDetailInfoNotFound;