import React, { forwardRef, useImperativeHandle } from 'react';

import './AGGridTooltip.scss';

import OvexTooltip from '../../../tooltips/OvexToolTip/OvexTooltip';

/**
this tooltip component is modified example for AG-grid and restyled to suite our needs
*/
export default forwardRef((props, ref) => {

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['AGGridTooltip'];
      }
    };
  });

  return (
    <OvexTooltip value={props.value} />
  );
});
