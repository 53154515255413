import React from 'react';

import NavMenu from '../../../common/components/nav/NavMenu/NavMenu';
import { LsiContext, ModuleContext } from '../../../common/contexts';
import { modulePathPrefix } from '../../config/moduleConfig';
import moduleContextData from '../../config/moduleContextData';
import modulePaths from '../../routes/modulePaths';
import {
  ACGetDealerRestrictionListFunctionalityContext,
  PCGetProductionCorridorListFunctionalityContext,
  QRGetRestrictionListFunctionalityContext,
  SCPGetCommissionsSwapDealerFunctionalityContext,
  SCPGetCommissionsSwapImporterFunctionalityContext
} from '../../privilege/context';

const propTypes = {};

const defaultProps = {};

const pcGetProductionCorridorListFunctionalityContext = new PCGetProductionCorridorListFunctionalityContext();
const qrGetRestrictionListFunctionalityContext = new QRGetRestrictionListFunctionalityContext();
const scpGetCommissionsSwapDealerFunctionalityContext = new SCPGetCommissionsSwapDealerFunctionalityContext();
const scpGetCommissionsSwapImporterFunctionalityContext = new SCPGetCommissionsSwapImporterFunctionalityContext();
const acGetDealerRestrictionListFunctionalityContext = new ACGetDealerRestrictionListFunctionalityContext();

const PvtNavMenu = () => {
  const lsi = React.useContext(LsiContext);

  const navMenuItems = React.useMemo(
    () => ([
      {
        label: lsi.getLSIItem('PVT.NAV_MENU.ITEM_QUOTAS_AND_RESTRICTIONS'),
        privilegeFunctionalityContext: qrGetRestrictionListFunctionalityContext,
        route: modulePaths.ROUTE_PATH_PVT_RESTRICTIONS
      },
      {
        label: lsi.getLSIItem('PVT.NAV_MENU.AVAILABLE_CAPACITY'),
        privilegeFunctionalityContext: acGetDealerRestrictionListFunctionalityContext,
        route: modulePaths.ROUTE_PATH_PVT_AVAILABLE_CAPACITY_DEALER
      },
      {
        label: lsi.getLSIItem('PVT.NAV_MENU.ITEM_PRODUCTION_CORRIDOR'),
        privilegeFunctionalityContext: pcGetProductionCorridorListFunctionalityContext,
        route: modulePaths.ROUTE_PATH_PVT_PRODUCTION_CORRIDOR
      },
      {
        label: lsi.getLSIItem('PVT.NAV_MENU.SWAP_COMMISSION_PVT'),
        privilegeFunctionalityContext: scpGetCommissionsSwapImporterFunctionalityContext,
        route: modulePaths.ROUTE_PATH_PVT_IMPORTER_SWAP_COMMISSION_PVT
      },
      {
        label: lsi.getLSIItem('PVT.NAV_MENU.SWAP_COMMISSION_PVT'),
        privilegeFunctionalityContext: scpGetCommissionsSwapDealerFunctionalityContext,
        route: modulePaths.ROUTE_PATH_PVT_DEALER_SWAP_COMMISSION_PVT
      }
    ]),
    [lsi]
  );

  return (
    <ModuleContext.Provider value={moduleContextData} >
      <NavMenu
        menuLabel={lsi.getLSIItem('PVT.NAV_MENU.LABEL')}
        navMenuItems={navMenuItems}
        pathPrefix={modulePathPrefix}
      />
    </ModuleContext.Provider>
  );
};

PvtNavMenu.propTypes = propTypes;
PvtNavMenu.defaultProps = defaultProps;

export default PvtNavMenu;