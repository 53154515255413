import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonBar } from '../../../../../common/components';
import { LsiContext } from '../../../../../common/contexts';
import { CreatePriceGuaranteePFC } from '../../../../privilege/context';

const propTypes = {
  onReload: PropTypes.func,
  onShowPriceGuaranteeCreationModalForm: PropTypes.func
};

const defaultProps = {
  onReload: null,
  onShowPriceGuaranteeCreationModalForm: null
};

const createPriceGuaranteePFC = new CreatePriceGuaranteePFC();

const PriceGuaranteeButtonBar = (props) => {
  const lsi = React.useContext(LsiContext);

  return (
    <ButtonBar
      className={'ovex-pg-PriceGuaranteeButtonBar'}
    >
      <Button
        content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.ADD_PRICE_GUARANTEE')}
        onClick={props.onShowPriceGuaranteeCreationModalForm}
        privilegeFunctionalityContext={createPriceGuaranteePFC}
        type={Button.type.PRIMARY}
      />
      <Button
        content={lsi.getLSIItem('PRICE_GUARANTEE.BUTTON.RELOAD')}
        onClick={props.onReload}
        type={Button.type.SECONDARY}
      />
    </ButtonBar>
  );
};

PriceGuaranteeButtonBar.propTypes = propTypes;
PriceGuaranteeButtonBar.defaultProps = defaultProps;

export default PriceGuaranteeButtonBar;