import { connect } from 'react-redux';

import { handleGetRestrictionList, handleUpdateRestrictionBulk } from '../../redux/modules/actions';

import Restrictions from './Restrictions';

const mapStateToProps = state => ({
  isFetching: state.pvt.restrictions.isFetching,
  restrictionResponse: state.pvt.restrictions.restrictionResponse
});

const mapDispatchToProps = dispatch => ({
  onGetRestrictionList: (filterData) => dispatch(handleGetRestrictionList(filterData)),
  onUpdateRestrictionBulk: (restrictionsBulkUpdate) => dispatch(handleUpdateRestrictionBulk(restrictionsBulkUpdate))
});

export default connect(mapStateToProps, mapDispatchToProps)(Restrictions);
