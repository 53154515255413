import { isGroupNode, nonGroupNode } from '../../../../../common/components/ag-grid';

import ColumnUIDefinitionMap from './columnUIDefinition/ColumnUIDefinitionMap';
import AbstractSpecColumnUIDefinition from './columnUIDefinition/AbstractSpecColumnUIDefinition';
import previousSpecificationSnapshotGroupColumn from './columnUIDefinition/group/PreviousSpecificationSnapshotGroupColumn';
import currentSpecificationSnapshotGroupColumn from './columnUIDefinition/group/CurrentSpecificationSnapshotGroupColumn';
import { resolveCellClass } from './ValidationResultPGItemTable.helpers';
import { RowType } from './ValidationResultPGItemTable.const';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { buttons: ['reset'], newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  }
};

export const getColumnDefinitionsData = (conditionCiColumnDefinitionList, lsi, currency) => {
  const additionalColumnParams = {
    currency: currency,
    columnTypeList: [COLUMN_TYPE_ENUM.DEFAULT_CD]
  };

  const leafColumnUIDefinitionList = conditionCiColumnDefinitionList
    .map((def, index) => ({ sortOrder: index, cuid: ColumnUIDefinitionMap[def.uiDefinition], ciColDef: def }));

  // 1. CUID non specification
  const nonGroupCUIDList = leafColumnUIDefinitionList
    .filter(i => !(i.cuid instanceof AbstractSpecColumnUIDefinition))
    .map(i => ({ sortOrder: i.sortOrder, colDef: i.cuid.buildColumnDefinition(i.ciColDef, lsi, additionalColumnParams) }));

  // 2. CUID specification with PREVIOUS snapshot
  const specPreviousSnapshotCUIDList = leafColumnUIDefinitionList
    .filter(i => previousSpecificationSnapshotGroupColumn.hasSameSnapshot(i.cuid));
  const specPreviousSnapshotGroupColumnDefinition = previousSpecificationSnapshotGroupColumn.buildGroupColumnDefinition(lsi, additionalColumnParams, specPreviousSnapshotCUIDList);

  // 3. CUID specification with CURRENT snapshot
  const specCurrentSnapshotCUIDList = leafColumnUIDefinitionList
    .filter(i => currentSpecificationSnapshotGroupColumn.hasSameSnapshot(i.cuid));
  const specCurrentSnapshotGroupColumnDefinition = currentSpecificationSnapshotGroupColumn.buildGroupColumnDefinition(lsi, additionalColumnParams, specCurrentSnapshotCUIDList);

  const columnDefinitionList = [
    ...nonGroupCUIDList,
    specPreviousSnapshotGroupColumnDefinition,
    specCurrentSnapshotGroupColumnDefinition
  ]
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map(i => i.colDef);

  return {
    columnDefinitionList: columnDefinitionList,
    specSnapshotCUIDList: [...specPreviousSnapshotCUIDList, ...specCurrentSnapshotCUIDList].map(i => i.cuid)
  };
};

export const getAutoGroupColumnDef = (lsi) => {
  return {
    headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.PGID'),
    cellClass: resolveCellClass(''),
    valueGetter: valueGetterParams => {
      if (nonGroupNode(valueGetterParams) && RowType.isPGItem(valueGetterParams.data.rowType)) {
        return valueGetterParams.data.pgid;
      }
      return '';
    },
    valueFormatter: valueFormatterParams => {
      const data = valueFormatterParams.data;
      if (isGroupNode(valueFormatterParams)) {
        return valueFormatterParams.value;
      }
      if (RowType.isCommission(data.rowType)) {
        return valueFormatterParams.context.agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_ITEM_ROW_TYPE.COMMISSION');
      } else if (RowType.isPGItem(data.rowType)) {
        return valueFormatterParams.value;
      }
      return '';
    },
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    filter: 'agTextColumnFilter',
    pinned: 'left',
    width: 200
  };
};
