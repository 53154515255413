import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import AbstractColumnUIDefinition from '../AbstractColumnUIDefinition';
import {CURRENCY_SYMBOL} from '../../../../../../utils/const';
import {amountValueFormatter, resolveCellClass} from '../../ValidationResultPGItemTable.helpers';
import {isGroupNode, nonGroupNode} from "../../../../../../../common/components/ag-grid";
import {RowType} from "../../ValidationResultPGItemTable.const";

class InvoiceAmountCUID extends AbstractColumnUIDefinition {

    /** @override */
    getCode = () => {
        return ColumnUIDefinitionCodes.INVOICE_AMOUNT;
    }
    /** @override */
    buildColumnDefinition = (columnUIDefinition, lsi, additionalParams) => {
        const {visible} = columnUIDefinition;
        const {columnTypeList, currency} = additionalParams;

        return {
            colId: this.getCode(),
            hide: !visible,
            headerName: lsi.getLSIItem('PRICE_GUARANTEE.TABLE_HEADER.AMOUNT_INVOICED', CURRENCY_SYMBOL[currency]),
            cellClass: resolveCellClass('ovex-ag-grid--td-right'),
            valueGetter: (valueGetterParams) => {
                const {data} = valueGetterParams;
                if (isGroupNode(valueGetterParams)) {
                    const accountPartData = valueGetterParams.node.allLeafChildren[0].data.accountPartData;
                    return accountPartData.invoiceAmount === null ? "-" : accountPartData.invoiceAmount;
                }
                if (nonGroupNode(valueGetterParams) && RowType.isPGItem(data.rowType)) {
                    return data.invoiceAmount === null ? "-" : data.invoiceAmount;
                }
                return null;
            },
            valueFormatter: amountValueFormatter,
            type: [...columnTypeList],
            filter: 'agNumberColumnFilter',
            width: 180,
            minWidth: 120
        };
    }
}

const invoiceAmountCUID = new InvoiceAmountCUID();

export default invoiceAmountCUID;