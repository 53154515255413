import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './uu5-environment';
import './uu5-settings';

import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import Root from './components/app-root';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  // TMP - *StrictMode* disabled because issues are in UU5 lib
  // <React.StrictMode>
  //   <Root />
  // </React.StrictMode>,
  <Root />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
