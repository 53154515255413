
class WebModuleRoutesData {

  /**
   * @param {String} pathPrefix
   * @param {Object} paths - map of route paths
   * @param {Function} component - React function component
   */
  constructor(pathPrefix, paths, component) {
    this.pathPrefix = pathPrefix;
    this.paths = paths;
    this.component = component;
  }

}

/**
 * @type {String}
 */
WebModuleRoutesData.prototype.pathPrefix = undefined;
/**
 * @type {Object} map of route paths
 */
WebModuleRoutesData.prototype.paths = undefined;
/**
 * @type {Function} React function component
 */
WebModuleRoutesData.prototype.component = undefined;

export default WebModuleRoutesData;