import { Environment } from 'uu5g04';

/**
 * Color schemas
 */
Environment.changeColorSchema('primary', 'green-rich'); // default blue-rich
Environment.changeColorSchema('success', 'blue'); // default green
Environment.changeColorSchema('info', 'green'); // default blue
// Environment.changeColorSchema('warning', 'orange'); // default orange
// Environment.changeColorSchema('danger', 'red'); // default red

/**
 * Statistics
 */
Environment.disableStatistics();