import React from 'react';

import { ModuleRoutes, ProtectedRoute } from '../../common/components/routes';
import moduleContextData from '../config/moduleContextData';
import {
  CommissionValidatorDealer,
  CommissionValidatorImporter,
  PriceGuaranteeItems,
  PriceGuaranteeItemsPublished,
  PriceGuaranteeList,
  PriceGuaranteeReportDealer,
  PriceGuaranteeReportImporter,
  PriceGuaranteeRecovery
} from '../layouts';
import {
  GetPriceGuaranteeListPFC,
  ITMGetPriceGuaranteeListPFC,
  PGRGetPriceGuaranteeListPFC, PGRGetPriceGuaranteeReportDealerPFC, PGRGetPriceGuaranteeReportImporterPFC,
  PUBGetPriceGuaranteeListPFC,
  VLDGetPriceGuaranteeListPFC, VLDGetCommissionValidationResultDealerPFC, VLDGetCommissionValidationResultImporterPFC
} from '../privilege/context';

import modulePaths from './modulePaths';

const propTypes = {};
const defaultProps = {};

const getPriceGuaranteeListPFC = new GetPriceGuaranteeListPFC();
const itmGetPriceGuaranteeListPFC = new ITMGetPriceGuaranteeListPFC();
const pgrGetPriceGuaranteeReportDealerPFCList = [new PGRGetPriceGuaranteeListPFC(), new PGRGetPriceGuaranteeReportDealerPFC()];
const pgrGetPriceGuaranteeReportImporterPFCList = [new PGRGetPriceGuaranteeListPFC(), new PGRGetPriceGuaranteeReportImporterPFC()];
const pubGetPriceGuaranteeListPFC = new PUBGetPriceGuaranteeListPFC();
const vldGetCommissionValidationResultDealerPFCList = [new VLDGetPriceGuaranteeListPFC(), new VLDGetCommissionValidationResultDealerPFC()];
const vldGetCommissionValidationResultImporterPFCList = [new VLDGetPriceGuaranteeListPFC(), new VLDGetCommissionValidationResultImporterPFC()];
const getDisasterRecovery = new GetPriceGuaranteeListPFC();

const PriceGuaranteeRoutes = () => {

  return (
    <ModuleRoutes moduleContextData={moduleContextData} >
      <ProtectedRoute
        exact
        path={modulePaths.ROUTE_PATH_COMMISSION_VALIDATOR_DEALER}
        privilegeFunctionalityContext={vldGetCommissionValidationResultDealerPFCList}
      >
        <CommissionValidatorDealer />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={modulePaths.ROUTE_PATH_COMMISSION_VALIDATOR_IMPORTER}
        privilegeFunctionalityContext={vldGetCommissionValidationResultImporterPFCList}
      >
        <CommissionValidatorImporter />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={modulePaths.ROUTE_PATH_PRICE_GUARANTEE_ITEMS_LIST}
        privilegeFunctionalityContext={itmGetPriceGuaranteeListPFC}
      >
        <PriceGuaranteeItems />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={modulePaths.ROUTE_PATH_PRICE_GUARANTEE_REPORT_DEALER}
        privilegeFunctionalityContext={pgrGetPriceGuaranteeReportDealerPFCList}
      >
        <PriceGuaranteeReportDealer />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={modulePaths.ROUTE_PATH_PRICE_GUARANTEE_REPORT_IMPORTER}
        privilegeFunctionalityContext={pgrGetPriceGuaranteeReportImporterPFCList}
      >
        <PriceGuaranteeReportImporter />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={modulePaths.ROUTE_PATH_PRICE_GUARANTEE_ITEMS_PUBLISHED_LIST}
        privilegeFunctionalityContext={pubGetPriceGuaranteeListPFC}
      >
        <PriceGuaranteeItemsPublished />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={modulePaths.ROUTE_PATH_PRICE_GUARANTEE_LIST}
        privilegeFunctionalityContext={getPriceGuaranteeListPFC}
      >
        <PriceGuaranteeList />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={modulePaths.ROUTE_PATH_PRICE_GUARANTEE_REFRESH_STORAGE_DATA}
        privilegeFunctionalityContext={getDisasterRecovery}
      >
        <PriceGuaranteeRecovery />
      </ProtectedRoute>
    </ModuleRoutes>
  );
};

PriceGuaranteeRoutes.propTypes = propTypes;
PriceGuaranteeRoutes.defaultProps = defaultProps;

export default PriceGuaranteeRoutes;
