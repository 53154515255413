import React from 'react';
import PropTypes from 'prop-types';
import { mdiHandRight } from '@mdi/js';
import Icon from '@mdi/react';
import './WeekCellRenderer.scss';

import { getCellData } from '../../../../../common/components/ag-grid/OvexAGTable';
import { LsiContext } from '../../../../../common/contexts';
import { simpleNullishValueFormatter } from '../../../../../common/utils/formatters';
import RestrictionRowTypeEnum from '../rowModel/RestrictionRowTypeEnum';
import RestrictionRow from '../rowModel/RestrictionRow';
import RestrictionsTabEnum from '../RestrictionsTabEnum';
import { RestrictionTypeEnum } from '../../../../utils/const';

const WeekCellRenderer = (props) => {
  const lsi = React.useContext(LsiContext);
  const cellData = getCellData(props);
  const classNamesMain = ['WeekCellRenderer'];
  const classNamesTotal = ['WeekCellRenderer-total'];

  let valueFormatted;
  let icon;
  let iconTooltip;

  if (cellData != null) {
    valueFormatted = cellData.getTabValue(props.context.selectedTab);
    switch (props.context.selectedTab) {
      case RestrictionsTabEnum.NEW_PLAN: {
        if (cellData.isChanged() && cellData.isAddedAndEdited()) {
          classNamesTotal.push('WeekCellRenderer-total--modified');
        }
        if (cellData.isNumberTotalDifferent()) {
          classNamesTotal.push('WeekCellRenderer-total--differencePlan');
          iconTooltip = lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_WEEK_NUMBER_TOTAL_DIFFERENT');
        }

        if(props.data.added && props.data.restrictionType === RestrictionTypeEnum.T && !cellData.isChanged()) {
          classNamesTotal.push('WeekCellRenderer-total--differencePlan');
          iconTooltip = lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_WEEK_NUMBER_ADD');
        }

        break;
      }
      case RestrictionsTabEnum.ACTUAL_PLAN: {
        if (cellData.containsManualAssignment()) {
          icon = mdiHandRight;
        }
        break;
      }
      case RestrictionsTabEnum.USED: {
        break;
      }
      case RestrictionsTabEnum.DIFFERENCE: {
        if (cellData.isFull()) {
          classNamesTotal.push('WeekCellRenderer-total--full');
          iconTooltip = lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_WEEK_FULL');
        }
        break;
      }
      default: {
        valueFormatted = null;
      }
    }
  } else if (props.data.added && props.data.restrictionType === RestrictionTypeEnum.T && props.context.selectedTab === RestrictionsTabEnum.NEW_PLAN) {
    classNamesTotal.push('WeekCellRenderer-total--differencePlan');
    iconTooltip = lsi.getLSIItem('PVT.TOOLTIP.RESTRICTION_WEEK_NUMBER_ADD');
  }

  const getCommonValue = () => {
    if (!RestrictionRowTypeEnum.isModelGroup(props.data.type)) {
      return null;
    }
    switch (props.context.selectedTab) {
      case RestrictionsTabEnum.NEW_PLAN: {
        return props.node.parent.allLeafChildren
          .filter(node => node.data.modelGroup === props.data.modelGroup)
          .reduce((accumulator, currentRowNode) => {
            const currentRowValue = currentRowNode.data[cellData?.week]?.getTabCommonValue(props.context.selectedTab);
            if (currentRowValue != null) {
              if (currentRowNode.data.id === props.node.data.id) {
                return accumulator + currentRowValue;
              } else {
                if (currentRowNode.data.deletedInFuture) {
                  return accumulator;
                } else {
                  return accumulator - currentRowValue;
                }
              }
            }
            return accumulator;
          }, null);
      }
      case RestrictionsTabEnum.ACTUAL_PLAN: {
        return props.node.parent.allLeafChildren
          .filter(node => node.data.modelGroup === props.data.modelGroup)
          .reduce((accumulator, currentRowNode) => {
            const currentRowValue = currentRowNode.data[cellData?.week]?.getTabCommonValue(props.context.selectedTab);
            if (currentRowValue != null) {
              if (currentRowNode.data.id === props.node.data.id) {
                return accumulator + currentRowValue;
              }
              return accumulator - currentRowValue;
            }
            return accumulator;
          }, null);
      }
      case RestrictionsTabEnum.USED:
      case RestrictionsTabEnum.DIFFERENCE: {
        return cellData?.getTabCommonValue(props.context.selectedTab);
      }
      default: {
        return null;
      }
    }
  };

  const valueFormatter = value => simpleNullishValueFormatter(value, '-');

  return (
    <div className={classNamesMain.join(' ')} title={iconTooltip}>
      <div className={classNamesTotal.join(' ')}>
        {icon &&
          <Icon
            className='WeekCellRenderer-icon'
            path={icon}
            size={0.6}
          />
        }
        <div className='WeekCellRenderer-value'>
          {valueFormatter(valueFormatted)}
        </div>
      </div>
      { RestrictionRowTypeEnum.isModelGroup(props.data.type) &&
        <div className='WeekCellRenderer-common'>
          <div className='WeekCellRenderer-value'>
            {valueFormatter(getCommonValue())}
          </div>
        </div>
      }
    </div>
  );
};

WeekCellRenderer.propTypes = {
  colDef: PropTypes.instanceOf(Object).isRequired,
  context: PropTypes.shape({
    selectedTab: PropTypes.string
  }).isRequired,
  data: PropTypes.instanceOf(RestrictionRow).isRequired
};

WeekCellRenderer.defaultProps = {};

export default WeekCellRenderer;
