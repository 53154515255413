import { mdiCarHatchback, mdiPackageVariantClosed, mdiPaletteOutline, mdiSteering } from '@mdi/js';

import { PGISpecificationTypeEnum } from '../../../../../utils/const';

const SPECIFICATION_TYPE_ICON_COLOR = null;
const SPECIFICATION_TYPE_ICON_COLOR_NEG = 'rgba(160, 0, 0, 0.54)';

const specificationTypeColumnMetadata = {
  [PGISpecificationTypeEnum.MODEL]: {
    headerComponentParams: { hiddenText: true, iconPath: mdiCarHatchback, iconColor: SPECIFICATION_TYPE_ICON_COLOR },
    flex: 8,
    minWidth: 80
  },
  [PGISpecificationTypeEnum.MODEL_NEG]: {
    headerComponentParams: { hiddenText: true, iconPath: mdiCarHatchback, iconColor: SPECIFICATION_TYPE_ICON_COLOR_NEG },
    flex: 8,
    minWidth: 80
  },
  [PGISpecificationTypeEnum.COLOUR]: {
    headerComponentParams: { hiddenText: true, iconPath: mdiPaletteOutline, iconColor: SPECIFICATION_TYPE_ICON_COLOR },
    flex: 8,
    minWidth: 80
  },
  [PGISpecificationTypeEnum.COLOUR_NEG]: {
    headerComponentParams: { hiddenText: true, iconPath: mdiPaletteOutline, iconColor: SPECIFICATION_TYPE_ICON_COLOR_NEG },
    flex: 8,
    minWidth: 80
  },
  [PGISpecificationTypeEnum.INTERIOR]: {
    headerComponentParams: { hiddenText: true, iconPath: mdiSteering, iconColor: SPECIFICATION_TYPE_ICON_COLOR },
    flex: 8,
    minWidth: 80
  },
  [PGISpecificationTypeEnum.INTERIOR_NEG]: {
    headerComponentParams: { hiddenText: true, iconPath: mdiSteering, iconColor: SPECIFICATION_TYPE_ICON_COLOR_NEG },
    flex: 8,
    minWidth: 80
  },
  [PGISpecificationTypeEnum.PACKAGE]: {
    headerComponentParams: { hiddenText: true, iconPath: mdiPackageVariantClosed, iconColor: SPECIFICATION_TYPE_ICON_COLOR },
    flex: 16,
    minWidth: 160
  },
  [PGISpecificationTypeEnum.PACKAGE_NEG]: {
    headerComponentParams: { hiddenText: true, iconPath: mdiPackageVariantClosed, iconColor: SPECIFICATION_TYPE_ICON_COLOR_NEG },
    flex: 16,
    minWidth: 160
  }
};

Object.freeze(specificationTypeColumnMetadata);

export default specificationTypeColumnMetadata;