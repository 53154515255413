
/**
 * Function transform decimal value for percent to percentage value.
 *
 * @param {number} decimalNumber
 * @param {number|null} decimalDigits - The number of digits to truncate after the decimal point. If *digits* is null, percentage number will returned as is.
 * @return {number} percent number
 */
export const decimalToPercent = (decimalNumber, decimalDigits = null) => {
  const percent = decimalNumber * 100;

  if (decimalDigits == null) {
    return percent;
  }
  return Number(percent.toFixed(decimalDigits));
};

/**
 * Function transform percentage value to decimal value for percent.
 *
 * @param {number} percentNumber
 * @param {number|null} decimalDigits - The number of digits to appear truncate the decimal point. If *digits* is null, decimal number will returned as is.
 * @return {number} decimal number
 */
export const percentToDecimal = (percentNumber, decimalDigits = 2) => {
  const decimal = percentNumber / 100;

  if (decimalDigits == null) {
    return decimal;
  }
  return Number(decimal.toFixed(decimalDigits));
};

/**
 *
 * @param {number|null} acc - accumulated value
 * @param {number|null} value - current value
 * @return {number|null} new accumulated value
 */
export const sumNullableAccumulator = (acc, value) => value == null ? acc : acc + value;
