import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';
import { packageListFormatter } from '../../ValidationResultPGItemTable.helpers';

class SpecPreviousPackageCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__PREVIOUS__PACKAGE;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.PREVIOUS;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.PACKAGE;
  }
  /**
   * @override
   * @param {CommissionSpecificationDTOVLD} commission
   */
  commissionConverterValueGetter = (commission) => {
    return packageListFormatter(commission.commissionPreviousData.carSpecification.packageList);
  }

}

const specPreviousPackageCUID = new SpecPreviousPackageCUID();

export default specPreviousPackageCUID;