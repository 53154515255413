import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const userCredentialsApi = ApiServiceInstances.support.userCredentialsApi;

export const GET_AVAILABLE_BID_LIST_REQUEST = 'GET_AVAILABLE_BID_LIST_REQUEST';
export const GET_AVAILABLE_BID_LIST_SUCCESS = 'GET_AVAILABLE_BID_LIST_SUCCESS';
export const GET_AVAILABLE_BID_LIST_FAILURE = 'GET_AVAILABLE_BID_LIST_FAILURE';

const initialState = {
  bidList: null,
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_BID_LIST_REQUEST: {
      return { ...state, isFetching: true };
    }
    case GET_AVAILABLE_BID_LIST_SUCCESS: {
      return { ...state, isFetching: false, bidList: action.payload };
    }
    case GET_AVAILABLE_BID_LIST_FAILURE: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    default:
      return state;
  }
};

export const handleGetAvailableBidList = () => {
  return {
    [CALL_API]: {
      apiFunction: () => userCredentialsApi.getAvailableBidListWithHttpInfo(),
      types: [GET_AVAILABLE_BID_LIST_REQUEST, GET_AVAILABLE_BID_LIST_SUCCESS, GET_AVAILABLE_BID_LIST_FAILURE]
    }
  };
};
