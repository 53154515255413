/**
 * @param {string} path - dealer/importer path for commission validator
 * @param {string} marketingActionCode
 * @param {string} commissionNumber
 * @param {string} order
 * @return {string}
 */
const generatePathCommissionValidator = (path, marketingActionCode, commissionNumber, order = null) => {
    if (marketingActionCode == null && commissionNumber == null && order == null) {
        return path;
    }
    if (order == null) {
        return `${path}?marketingActionCode=${marketingActionCode}&commissionNumber=${commissionNumber}`;
    } else {
        return `${path}?marketingActionCode=${marketingActionCode}&commissionNumber=${commissionNumber}&order=${order}`
    }
};

export default generatePathCommissionValidator;
