
class WebModuleNavMenuData {

  /**
   * @param {Number} order
   * @param {Function} component - React function component
   */
  constructor(order, component) {
    this.order = order;
    this.component = component;
  }

}

/**
 * @type {Number}
 */
WebModuleNavMenuData.prototype.order = undefined;
/**
 * @type {Function} React function component
 */
WebModuleNavMenuData.prototype.component = undefined;

export default WebModuleNavMenuData;
