import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePrivilege } from '../../../common/hooks';
import { ITMCreatePriceGuaranteeItemsPFC, ITMDeletePriceGuaranteeItemsPFC, ITMGetPriceGuaranteeItemsPFC, ITMUpdatePriceGuaranteeItemsPFC } from '../../privilege/context';
import {
  handleClearPriceGuaranteeItemsITM,
  handleGetPriceGuaranteeListITM,
  handleGetPriceGuaranteeItemsITM,
  handleUpdateBulkPriceGuaranteeItemsITM
} from '../../redux/modules/actions';

import PriceGuaranteeItems from './PriceGuaranteeItems';

const PriceGuaranteeItemsConnector = () => {
  const selectedState = useSelector(state => {
    return {
      isFetching: state.priceGuarantee.priceGuaranteeItems.isFetching,
      priceGuaranteeList: state.priceGuarantee.priceGuaranteeItems.priceGuaranteeList,
      priceGuaranteeItemsResponse: state.priceGuarantee.priceGuaranteeItems.priceGuaranteeItemsResponse
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => ({
      handleClearPriceGuaranteeItemsITM: () => dispatch(handleClearPriceGuaranteeItemsITM()),
      handleGetPriceGuaranteeListITM: () => dispatch(handleGetPriceGuaranteeListITM()),
      handleGetPriceGuaranteeItemsITM: (priceGuaranteeId) => dispatch(handleGetPriceGuaranteeItemsITM(priceGuaranteeId)),
      handleUpdateBulkPriceGuaranteeItemsITM: (bulkUpdateDTO) => dispatch(handleUpdateBulkPriceGuaranteeItemsITM(bulkUpdateDTO))
    }),
    [dispatch]
  );

  const [ isPrivilegeFetching, setPrivilegeFetching ] = React.useState(true);
  const { handlePrivilegeRequest } = usePrivilege();

  const [ priceGuaranteeListDisplayed, setPriceGuaranteeListDisplayed ] = React.useState(selectedState.priceGuaranteeList);
  React.useEffect(
    () => {
      if (Array.isArray(selectedState.priceGuaranteeList) && selectedState.priceGuaranteeList.length > 0) {
        const pfcList = selectedState.priceGuaranteeList.map(pg => new ITMGetPriceGuaranteeItemsPFC(pg.id));

        const asyncCall = async () => {
          setPrivilegeFetching(true);
          await handlePrivilegeRequest(pfcList);
          /**
           * Wait for evaluate privileges.
           */
          setPriceGuaranteeListDisplayed(selectedState.priceGuaranteeList);
          setPrivilegeFetching(false);
        };
        asyncCall().then();
      } else {
        setPriceGuaranteeListDisplayed(selectedState.priceGuaranteeList);
        setPrivilegeFetching(false);
      }
    },
    [handlePrivilegeRequest, selectedState.priceGuaranteeList]
  );

  const [ priceGuaranteeItemsResponseDisplayed, setPriceGuaranteeItemsResponseDisplayed ] = React.useState(selectedState.priceGuaranteeItemsResponse);
  React.useEffect(
    () => {
      if (selectedState.priceGuaranteeItemsResponse != null) {
        const { priceGuarantee, pgItemList } = selectedState.priceGuaranteeItemsResponse;
        const pfcList = [
          new ITMCreatePriceGuaranteeItemsPFC(priceGuarantee.id),
          ...pgItemList.flatMap(pgi => [
            new ITMUpdatePriceGuaranteeItemsPFC(pgi.id),
            new ITMDeletePriceGuaranteeItemsPFC(pgi.id)
          ])
        ];

        const asyncCall = async () => {
          setPrivilegeFetching(true);
          await handlePrivilegeRequest(pfcList);
          /**
           * Wait for evaluate privileges.
           */
          setPriceGuaranteeItemsResponseDisplayed(selectedState.priceGuaranteeItemsResponse);
          setPrivilegeFetching(false);
        };
        asyncCall().then();
      } else {
        setPriceGuaranteeItemsResponseDisplayed(selectedState.priceGuaranteeItemsResponse);
        setPrivilegeFetching(false);
      }
    },
    [handlePrivilegeRequest, selectedState.priceGuaranteeItemsResponse]
  );

  return (
    <PriceGuaranteeItems
      isFetching={selectedState.isFetching || isPrivilegeFetching}
      onCleanPriceGuaranteeItems={callbacks.handleClearPriceGuaranteeItemsITM}
      onGetPriceGuaranteeItems={callbacks.handleGetPriceGuaranteeItemsITM}
      onGetPriceGuaranteeList={callbacks.handleGetPriceGuaranteeListITM}
      onUpdateBulkPriceGuaranteeItems={callbacks.handleUpdateBulkPriceGuaranteeItemsITM}
      priceGuaranteeItemsResponse={priceGuaranteeItemsResponseDisplayed}
      priceGuaranteeList={priceGuaranteeListDisplayed}
    />
  );
};

export default PriceGuaranteeItemsConnector;
