
class WebModuleUserInfoData {

  /**
   * @param {Function} action - action to load user info data
   * @param {Function} selector - selector to get user info data from redux store
   */
  constructor(action, selector) {
    this.action = action;
    this.selector = selector;
  }

}

/**
 * @type {Function} action to load user info data
 */
WebModuleUserInfoData.prototype.action = undefined;
/**
 * @type {Function} selector to get user info data from redux store
 */
WebModuleUserInfoData.prototype.selector = undefined;

export default WebModuleUserInfoData;