import { ContractStatusEnum } from '../../../utils/const';

class ContractRow {

  isEditableByStatus = () => {
    return [
      ContractStatusEnum.CREATED,
      ContractStatusEnum.DIRECTOR_APPROVED,
      ContractStatusEnum.AREA_ADVISOR_APPROVED,
      ContractStatusEnum.REJECTED
    ].includes(this.status);
  };

  discardChanges = () => {
    this.modelGroupAnnualTargetList.forEach(mg => mg.undoATRecommendedNumber());
    this.periodAnnualTargetList.forEach(mg => mg.undoATSRecommendedNumber());
  };
}

ContractRow.prototype.id = undefined;
ContractRow.prototype.negotiation = undefined;
ContractRow.prototype.status = undefined;
ContractRow.prototype.additionOrder = undefined;
ContractRow.prototype.statisticsTimestamp = undefined;
ContractRow.prototype.statisticsType = undefined;
ContractRow.prototype.statisticsRequestId = undefined;

ContractRow.prototype.modelGroupAnnualTargetList = undefined;
ContractRow.prototype.periodAnnualTargetList = undefined;

ContractRow.prototype.privilegeFunctionalityContextObject = undefined;

export default ContractRow;
