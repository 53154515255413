import { UpdateContractAnnualTargetRecommendedFunctionalityContext } from '../../../privilege/context';
import { AG_GROUP_TOTAL } from '../utils/const';
import { quarterPeriodFormatter } from '../../../../common/utils/formatters';

import ModelGroupAnnualTargetRow from './ModelGroupAnnualTargetRow';

const ModelGroupAnnualTargetConverter = {

  /**
   * @param {Array.<module:model/ContractATModelGroupDTO>} modelGroupAnnualTargetList
   * @param {string} contractStatus
   * @param {Array.<string>} roleList - user role list
   * @return {Array.<ModelGroupAnnualTargetRow>}
   */
  fromList: (modelGroupAnnualTargetList, contractStatus, roleList) => {
    return modelGroupAnnualTargetList == null ? [] : modelGroupAnnualTargetList.flatMap(mg => {
      return [
        constructFromObject(mg, contractStatus, roleList),
        ...mg.atPeriodList.map(atPeriod => constructFromPeriod(mg, atPeriod))
      ];
    });
  }

};

/**
 * @param {Object|module:model/ContractATModelGroupDTOContractATModelGroupDTO} data
 * @param {string} contractStatus
 * @param {Array.<string>} roleList - user role list
 * @return {ModelGroupAnnualTargetRow} The populated <code>ModelGroupAnnualTargetRow</code> instance.
 */
const constructFromObject = (data, contractStatus, roleList) => {
  if (data == null) {
    return null;
  }
  const row = new ModelGroupAnnualTargetRow(AG_GROUP_TOTAL);

  row.id = data.hasOwnProperty('id') ? data.id : null;
  row.version = data.hasOwnProperty('version') ? data.version : null;
  row.modelGroup = data.hasOwnProperty('modelGroup') ? data.modelGroup : null;
  row.modelGroupName = data.hasOwnProperty('modelGroupName') ? data.modelGroupName : null;
  row.negotiable = data.hasOwnProperty('negotiable') ? data.negotiable : null;

  row.atDealerNumber = data.hasOwnProperty('atDealerNumber') ? data.atDealerNumber : null;
  row.atRecommendedNumber = data.hasOwnProperty('atRecommendedNumber') ? data.atRecommendedNumber : null;
  row.atApprovalNumber = data.hasOwnProperty('atApprovalNumber') ? data.atApprovalNumber : null;
  row.atApprovedNumber = data.hasOwnProperty('atApprovedNumber') ? data.atApprovedNumber : null;

  row.atStatisticsInvoicedCount = data.hasOwnProperty('atStatisticsInvoicedCount') ? data.atStatisticsInvoicedCount : null;
  row.atStatisticsProductionCount = data.hasOwnProperty('atStatisticsProductionCount') ? data.atStatisticsProductionCount : null;
  row.atStatisticsPlannedCount = data.hasOwnProperty('atStatisticsPlannedCount') ? data.atStatisticsPlannedCount : null;
  row.atStatisticsPotentialProductionCount = data.hasOwnProperty('atStatisticsPotentialProductionCount') ? data.atStatisticsPotentialProductionCount : null;
  row.atStatisticsPotentialPlannedCount = data.hasOwnProperty('atStatisticsPotentialPlannedCount') ? data.atStatisticsPotentialPlannedCount : null;

  row._atRecommendedNumberOriginal = row.atRecommendedNumber;

  row.privilegeFunctionalityContextObject = {
    updateRecommendedFnCtx: new UpdateContractAnnualTargetRecommendedFunctionalityContext(row.id, { contractStatus: contractStatus, negotiable: row.negotiable, roleList: roleList })
  };

  return row;
};

/**
 * @param {module:model/ContractModelGroupDTO} data
 * @param {module:model/ContractATModelGroupPeriodDTO} periodData
 * @return {ModelGroupAnnualTargetRow} The populated <code>DealerModelGroupAnnualTargetRow</code> instance.
 */
const constructFromPeriod = (data, periodData) => {
  if (data == null || periodData == null) {
    return null;
  }

  const row = new ModelGroupAnnualTargetRow(quarterPeriodFormatter(periodData.unitOrder));

  row.id = data.hasOwnProperty('id') ? periodData.id : null;
  row.version = data.hasOwnProperty('version') ? data.version : null;
  row.modelGroup = data.hasOwnProperty('modelGroup') ? data.modelGroup : null;
  row.modelGroupName = data.hasOwnProperty('modelGroupName') ? data.modelGroupName : null;
  row.negotiable = data.hasOwnProperty('negotiable') ? data.negotiable : null;

  row.atDealerNumber = periodData.hasOwnProperty('atDealerNumber') ? periodData.atDealerNumber : null;
  row.atRecommendedNumber = periodData.hasOwnProperty('atRecommendedNumber') ? periodData.atRecommendedNumber : null;
  row.atApprovalNumber = periodData.hasOwnProperty('atApprovalNumber') ? periodData.atApprovalNumber : null;
  row.atApprovedNumber = periodData.hasOwnProperty('atApprovedNumber') ? periodData.atApprovedNumber : null;

  row.atStatisticsInvoicedCount = periodData.hasOwnProperty('atStatisticsInvoicedCount') ? periodData.atStatisticsInvoicedCount : null;
  row.atStatisticsProductionCount = periodData.hasOwnProperty('atStatisticsProductionCount') ? periodData.atStatisticsProductionCount : null;
  row.atStatisticsPlannedCount = periodData.hasOwnProperty('atStatisticsPlannedCount') ? periodData.atStatisticsPlannedCount : null;
  row.atStatisticsPotentialProductionCount = periodData.hasOwnProperty('atStatisticsPotentialProductionCount') ? periodData.atStatisticsPotentialProductionCount : null;
  row.atStatisticsPotentialPlannedCount = periodData.hasOwnProperty('atStatisticsPotentialPlannedCount') ? periodData.atStatisticsPotentialPlannedCount : null;

  row._atRecommendedNumberOriginal = row.atRecommendedNumber;

  row.privilegeFunctionalityContextObject = {};

  return row;
};

export default ModelGroupAnnualTargetConverter;
