import DealerContractRow from './DealerContractRow';
import ContractConverter from './ContractConverter';

const DealerContractConverter = {

  /**
   * @param {Array.<module:model/ContractDetailDTO>} dealerContractsList
   * @param {Array.<string>} roleList - user role list
   * @return {Array.<DealerContractRow>}
   */
  fromList: (dealerContractsList, roleList) => {
    if (Array.isArray(dealerContractsList) && dealerContractsList.length > 0) {
      return dealerContractsList.map(dealerContract => {
        return constructFromObject(dealerContract, roleList);
      });
    }
    return [];
  }

};

/**
 * @param {Object} data The plain JavaScript object bearing properties of interest.
 * @param {Array.<string>} roleList - user role list
 * @return {DealerContractRow} The populated <code>DealerContractRow</code> instance.
 */
const constructFromObject = (data, roleList) => {
  if (data) {
    const obj = new DealerContractRow();

    obj.headquartersDealer = data.hasOwnProperty('headquartersDealer') ? data.headquartersDealer : null;
    obj.contract = ContractConverter.fromObject(data, roleList);

    return obj;
  }
  return null;
};

export default DealerContractConverter;
