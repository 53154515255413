import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';
import ContextConstants from '../ContextConstants';

class UpdateNegotiationFunctionalityContext extends AbstractFunctionalityContext {

  /**
   * @param {number} negotiationId
   */
  constructor(negotiationId) {
    super();

    this.setFunctionalityCode(FunctionalityCodes.AT_NEGOTIATIONS__UPDATE_NEGOTIATION);
    this.setParams({
      [ContextConstants.NEGOTIATION_ID]: negotiationId
    });
  }

}

export default UpdateNegotiationFunctionalityContext;