import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const userCredentialsApi = ApiServiceInstances.support.userCredentialsApi;

export const EMIGRATE_USER_REQUEST = 'EMIGRATE_USER_REQUEST';
export const EMIGRATE_USER_SUCCESS = 'EMIGRATE_USER_SUCCESS';
export const EMIGRATE_USER_FAILURE = 'EMIGRATE_USER_FAILURE';

const initialState = {
  error: null,
  isFetching: false
};


export default (state = initialState, action) => {
  switch (action.type) {
    case EMIGRATE_USER_REQUEST: {
      return { ...state, isFetching: true };
    }
    case EMIGRATE_USER_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case EMIGRATE_USER_FAILURE: {
      return { ...state, isFetching: false, error: action.payload.error };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleChangeUserBid = (bid) => {
  return {
    [CALL_API]: {
      apiFunction: userCredentialsApi.changeUserBidWithHttpInfo.bind(userCredentialsApi, bid),
      types: [EMIGRATE_USER_REQUEST, EMIGRATE_USER_SUCCESS, EMIGRATE_USER_FAILURE]
    }
  };
};
