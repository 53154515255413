import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { LsiContext } from '../../contexts';
import { Button } from '../index';

import './ConfirmModal.scss';

const propTypes = {
  buttonConfirmLabel: PropTypes.string,
  buttonRefuseLabel: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  header: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onRefuse: PropTypes.string,
  ref_: PropTypes.object
};

const defaultProps = {
  buttonConfirmLabel: null,
  buttonRefuseLabel: null,
  className: null,
  content: null,
  header: null,
  onRefuse: undefined,
  ref_: undefined
};

const ConfirmModal = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const classNames = ['ovex-ConfirmModal'];
  props.className && classNames.push(props.className);

  return (
    <Bricks.ConfirmModal
      className={classNames.join(' ')}
      confirmButtonProps={{
        ...Button.formControlsProps[Button.type.PRIMARY],
        content: props.buttonConfirmLabel ? props.buttonConfirmLabel : lsi.getLSIItem('COMMON.BUTTON.YES')
      }}
      content={props.content ? props.content : lsi.getLSIItem('COMMON.CONFIRM_MESSAGE.CONFIRM_ACTION')}
      header={props.header}
      onConfirm={props.onConfirm}
      onRefuse={props.onRefuse}
      ref_={props.ref_}
      refuseButtonProps={{
        ...Button.formControlsProps[Button.type.SECONDARY],
        content: props.buttonRefuseLabel ? props.buttonRefuseLabel : lsi.getLSIItem('COMMON.BUTTON.NO')
      }}
    />
  );
});

ConfirmModal.propTypes = propTypes;
ConfirmModal.defaultProps = defaultProps;

export default ConfirmModal;