import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const priceGuaranteeApi = ApiServiceInstances.priceGuarantee.priceGuaranteeAccountingBasisApi;

export const GET_ACCOUNTING_BASIS_LIST_REQUEST = 'GET_ACCOUNTING_BASIS_LIST_REQUEST';
export const GET_ACCOUNTING_BASIS_LIST_SUCCESS = 'GET_ACCOUNTING_BASIS_LIST_SUCCESS';
export const GET_ACCOUNTING_BASIS_LIST_FAILURE = 'GET_ACCOUNTING_BASIS_LIST_FAILURE';
export const DEFAULT_STATE = 'DEFAULT_STATE';

const initialState = {
  isFetching: false,
  error: null,
  accountingBasisList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTING_BASIS_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_ACCOUNTING_BASIS_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        accountingBasisList: action.payload
      };
    }
    case GET_ACCOUNTING_BASIS_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case LOCATION_CHANGE:
    case DEFAULT_STATE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleGetAccountingBasisList = () => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeApi.getAccountingBasisListWithHttpInfo(),
      types: [GET_ACCOUNTING_BASIS_LIST_REQUEST, GET_ACCOUNTING_BASIS_LIST_SUCCESS, GET_ACCOUNTING_BASIS_LIST_FAILURE]
    }
  };
};

export const handleClearAccountingBasis = () => {
  return {
    type: DEFAULT_STATE
  };
};

