import moduleAnnualTarget from './packages/annual-target';
import modulePriceGuarantee from './packages/price-guarantee';
import modulePvt from './packages/pvt';
import moduleSupport from './packages/support';
//
import { WebModuleRegister } from './packages/web-module';

const webModuleRegister = new WebModuleRegister();

webModuleRegister.register(moduleSupport);
webModuleRegister.register(moduleAnnualTarget);
webModuleRegister.register(modulePriceGuarantee);
webModuleRegister.register(modulePvt);

export default webModuleRegister;
