import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const negotiationsApi = ApiServiceInstances.annualTarget.negotiationsApi;

export const CREATE_NEGOTIATIONS_REQUEST = 'CREATE_NEGOTIATIONS_REQUEST';
export const CREATE_NEGOTIATIONS_SUCCESS = 'CREATE_NEGOTIATIONS_SUCCESS';
export const CREATE_NEGOTIATIONS_FAILURE = 'CREATE_NEGOTIATIONS_FAILURE';

export const UPDATE_NEGOTIATIONS_REQUEST = 'UPDATE_NEGOTIATIONS_REQUEST';
export const UPDATE_NEGOTIATIONS_SUCCESS = 'UPDATE_NEGOTIATIONS_SUCCESS';
export const UPDATE_NEGOTIATIONS_FAILURE = 'UPDATE_NEGOTIATIONS_FAILURE';

export const UPDATE_ACTIVATED_NEGOTIATIONS_REQUEST = 'UPDATE_NEGOTIATIONS_REQUEST';
export const UPDATE_ACTIVATED_NEGOTIATIONS_SUCCESS = 'UPDATE_NEGOTIATIONS_SUCCESS';
export const UPDATE_ACTIVATED_NEGOTIATIONS_FAILURE = 'UPDATE_NEGOTIATIONS_FAILURE';

export const SHIFT_WF_NEGOTIATIONS_REQUEST = 'SHIFT_WF_NEGOTIATIONS_REQUEST';
export const SHIFT_WF_NEGOTIATIONS_SUCCESS = 'SHIFT_WF_NEGOTIATIONS_SUCCESS';
export const SHIFT_WF_NEGOTIATIONS_FAILURE = 'SHIFT_WF_NEGOTIATIONS_FAILURE';

const initialState = {
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEGOTIATIONS_REQUEST:
    case UPDATE_NEGOTIATIONS_REQUEST:
    case UPDATE_ACTIVATED_NEGOTIATIONS_REQUEST:
    case SHIFT_WF_NEGOTIATIONS_REQUEST: {
      return { ...state, isFetching: true };
    }
    case CREATE_NEGOTIATIONS_SUCCESS:
    case UPDATE_NEGOTIATIONS_SUCCESS:
    case UPDATE_ACTIVATED_NEGOTIATIONS_SUCCESS:
    case SHIFT_WF_NEGOTIATIONS_SUCCESS: {
      return { ...state, isFetching: false };
    }
    case CREATE_NEGOTIATIONS_FAILURE:
    case UPDATE_NEGOTIATIONS_FAILURE:
    case UPDATE_ACTIVATED_NEGOTIATIONS_FAILURE:
    case SHIFT_WF_NEGOTIATIONS_FAILURE: {
      return { ...state, error: action.payload.error, isFetching: false };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const createNegotiation = (newNegotiationData) => {
  return {
    [CALL_API]: {
      apiFunction: negotiationsApi.createNegotiationWithHttpInfo.bind(negotiationsApi, newNegotiationData),
      types: [CREATE_NEGOTIATIONS_REQUEST, CREATE_NEGOTIATIONS_SUCCESS, CREATE_NEGOTIATIONS_FAILURE]
    }
  };
};

export const updateNegotiation = (updateNegotiationData) => {
  return {
    [CALL_API]: {
      apiFunction: negotiationsApi.updateNegotiationWithHttpInfo.bind(negotiationsApi, updateNegotiationData.id, updateNegotiationData),
      types: [UPDATE_NEGOTIATIONS_REQUEST, UPDATE_NEGOTIATIONS_SUCCESS, UPDATE_NEGOTIATIONS_FAILURE]
    }
  };
};

export const updateActivatedNegotiation = (updateNegotiationData) => {
  return {
    [CALL_API]: {
      apiFunction: negotiationsApi.updateActivatedNegotiationWithHttpInfo.bind(negotiationsApi, updateNegotiationData.id, updateNegotiationData),
      types: [UPDATE_ACTIVATED_NEGOTIATIONS_REQUEST, UPDATE_ACTIVATED_NEGOTIATIONS_SUCCESS, UPDATE_ACTIVATED_NEGOTIATIONS_FAILURE]
    }
  };
};

export const shiftNegotiationWorkflow = (negotiationWorkflow) => {
  return {
    [CALL_API]: {
      apiFunction: negotiationsApi.negotiationShiftStateWithHttpInfo.bind(negotiationsApi, negotiationWorkflow),
      types: [SHIFT_WF_NEGOTIATIONS_REQUEST, SHIFT_WF_NEGOTIATIONS_SUCCESS, SHIFT_WF_NEGOTIATIONS_FAILURE]
    }
  };
};
