import React from 'react';
import PropTypes from 'prop-types';
import { Bricks } from 'uu5g04';
import 'uu5g04/bricks';

import { Checkbox, Number } from '../../../common/components/forms';
import { decimalToPercent } from '../../utils/helpers';

import {
  getMinAchievementRatioInputName,
  getNegotiableInputName,
  getIdInputName
} from './Periods.utils';

import './Period.scss';

const getColumnWidth = (hiddenNegotiable, readOnlyMinAchievementRatio) => {
  if (hiddenNegotiable) {
    return ['100%', '0'];
  }
  if (readOnlyMinAchievementRatio) {
    return ['50%', '50%'];
  }
  return ['65%', '35%'];
};

const DefaultValues = {
  minAchievementRatio: 0,
  negotiable: true
};

const propTypes = {
  hiddenNegotiable: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  parent: PropTypes.object,
  periodNumber: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  readOnlyMinAchievementRatio: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.shape({
    id: PropTypes.number,
    minAchievementRatio: PropTypes.number,
    negotiable: PropTypes.bool
  })
};

const defaultProps = {
  hiddenNegotiable: false,
  label: null,
  name: null,
  parent: null,
  readOnly: false,
  readOnlyMinAchievementRatio: false,
  required: false,
  value: null
};

const Period = (props) => {
  const classNames = ['uu5-forms-input uu5-forms-input-m ovex-form-Period'];

  const valueMinAchievementRatio = props.value != null ? props.value.minAchievementRatio : DefaultValues.minAchievementRatio;
  const valueNegotiable = props.value != null ? props.value.negotiable : DefaultValues.negotiable;
  const valueId = props.value != null ? props.value.id : null;

  const columnWidth = getColumnWidth(props.hiddenNegotiable, props.readOnlyMinAchievementRatio);

  return (
    <Bricks.Row
      className={classNames.join(' ')}
      display='flex'
    >
      <Bricks.Column
        width={columnWidth[0]}
      >
        <Number
          decimals={2}
          inputColWidth={props.hiddenNegotiable ? 'xs9' : 'xs8'}
          label={props.label}
          labelColWidth={props.hiddenNegotiable ? 'xs3' : 'xs4'}
          max={100}
          min={0}
          name={getMinAchievementRatioInputName(props.name)}
          parent={props.parent}
          readOnly={props.readOnly || props.readOnlyMinAchievementRatio}
          required={props.required}
          suffix='%'
          value={decimalToPercent(valueMinAchievementRatio, 0)}
        />
      </Bricks.Column>
      { !props.hiddenNegotiable &&
        <Bricks.Column
          width={columnWidth[1]}
        >
          <Checkbox
            inputColWidth='xs4'
            labelColWidth='xs8'
            labelPosition='right'
            name={getNegotiableInputName(props.name)}
            parent={props.parent}
            readOnly={props.readOnly}
            required={props.required}
            value={valueNegotiable}
          />
        </Bricks.Column>
      }
      <Bricks.Column hidden >
        <Number
          name={getIdInputName(props.name)}
          parent={props.parent}
          readOnly
          value={valueId}
        />
      </Bricks.Column>
    </Bricks.Row>
  );
};

Period.DefaultValues = DefaultValues;

Period.propTypes = propTypes;
Period.defaultProps = defaultProps;

export default Period;
