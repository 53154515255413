import React from 'react';
import { useDispatch } from 'react-redux';

import { NewTabContext } from '../../../../common/contexts';
import { useAlertBusOvex } from '../../../../common/hooks';
import { AlertTypeEnum } from '../../../../common/objects';
import generatePathCommissionList from '../generatePathCommissionList';

const useShowCommissionListWithParams = (handleSaveCommissionsLoadParamsReduxFn, basePath) => {
  const dispatch = useDispatch();

  const openInNewTab = React.useContext(NewTabContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  return React.useCallback(
    async (commissionLoadParams) => {
      try {
        const responseData = await dispatch(handleSaveCommissionsLoadParamsReduxFn(commissionLoadParams));
        const uuid = responseData.data;

        openInNewTab(generatePathCommissionList(basePath, uuid));
      } catch (e) {
        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.SAVE_COMMISSION_LOAD_PARAMS_FAILURE', AlertTypeEnum.WARNING);
      }
    },
    [dispatch, handleSaveCommissionsLoadParamsReduxFn, openInNewTab, basePath, handleAddAlertSimple]
  );
};

export default useShowCommissionListWithParams;
