import { handleEditableApprovalNumberColumn, handleEditableDealerNumberColumn, handleInnerRendererTOTAL, handleNumberValueFormatter, handleValueParser, handleValueSetter } from '../utils/helpers';
import { COLUMN_IDS, tableBaseColumnDefs, COLUMN_GROUP_IDS, tableBaseColumnGroupDefs } from '../utils/tableBaseColumnDefs';
import { EXCEL_STYLES_IDS } from '../../../../common/components/ag-grid';
import { handleDonePercentNumberAggFunc, handleDonePercentNumberValueGetter } from '../../../utils/statisticsHelpers';
import { modelGroupFormatter } from '../../../utils/formatters';
import { AG_GROUP_TOTAL } from '../utils/const';

const COLUMN_TYPE_ENUM = {
  DEFAULT_CD: 'defaultDefinitionColumn',
  DATA_CD: 'dataDefinitionColumn'
};

export const columnTypes = {
  [COLUMN_TYPE_ENUM.DEFAULT_CD]: {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab']
  },
  [COLUMN_TYPE_ENUM.DATA_CD]: {
    cellClass: ['ovex-DealerModelGroupAnnualTargetTable-cell-numeric'],
    valueFormatter: (valueFormatterParams) => {
      const { node, data, colDef } = valueFormatterParams;
      if ([COLUMN_IDS.AT_ATS_DEALER_NUMBER, COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER, COLUMN_IDS.AT_ATS_APPROVAL_NUMBER].includes(colDef.colId)) {
        if (node.group && node.key !== AG_GROUP_TOTAL) {
          valueFormatterParams.value = null;
        }
        if (!node.group && data.isPeriodRow()) {
          valueFormatterParams.value = null;
        }
      }
      return handleNumberValueFormatter(valueFormatterParams);
    },
    aggFunc: 'sum'
  }
};

export const getAutoGroupColumnDef = (lsi) => {
  return {
    width: tableBaseColumnDefs[COLUMN_IDS.MODEL_GROUP].width,
    headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_GROUP_ROC'),
    valueGetter: 'data && data.modelGroup',
    cellClass: EXCEL_STYLES_IDS.STRING,
    type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
    valueFormatter: modelGroupFormatter,
    cellRendererParams: {
      innerRenderer: handleInnerRendererTOTAL,
      suppressCount: true
    },
    pinned: 'left'
  };
};

export const getColumnDefinitions = (lsi) => {
  return [
    {
      valueGetter: 'data && data.groupValue',
      rowGroup: true,
      hide: true
    },
    {
      ...tableBaseColumnDefs[COLUMN_IDS.MODEL_GROUP],
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.MODEL_GROUP_ROC'),
      valueGetter: 'data && data.modelGroup',
      cellClass: EXCEL_STYLES_IDS.STRING,
      type: [COLUMN_TYPE_ENUM.DEFAULT_CD],
      hide: true
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_WORKING_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_DEALER_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TENTATIVE'),
          valueGetter: 'data && data.atDealerNumber',
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
          cellClassRules: {
            'ovex-DealerModelGroupAnnualTargetTable-cell-numeric--editable': handleEditableDealerNumberColumn,
            'mdi': handleEditableDealerNumberColumn,
            'ovex-DealerModelGroupAnnualTargetTable-cell-numeric--modified': (cellClassParams) => !cellClassParams.node.group && cellClassParams.data.isATDealerNumberModified()
          },
          editable: handleEditableDealerNumberColumn,
          valueParser: handleValueParser,
          valueSetter: handleValueSetter('setATDealerNumber')
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_RECOMMENDED_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.RECOMMENDED'),
          valueGetter: 'data && data.atRecommendedNumber',
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVAL_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.BINDING'),
          valueGetter: 'data && data.atApprovalNumber',
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD],
          cellClassRules: {
            'ovex-DealerModelGroupAnnualTargetTable-cell-numeric--editable': handleEditableApprovalNumberColumn,
            'mdi': handleEditableApprovalNumberColumn,
            'ovex-DealerModelGroupAnnualTargetTable-cell-numeric--modified': (cellClassParams) => !cellClassParams.node.group && cellClassParams.data.isATApprovalNumberModified()
          },
          editable: handleEditableApprovalNumberColumn,
          valueParser: handleValueParser,
          valueSetter: handleValueSetter('setATApprovalNumber')
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_APPROVED_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.AT_ATS_APPROVED_NUMBER],
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.APPROVED'),
          valueGetter: 'data && data.atApprovedNumber',
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_GROUP],
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE'),
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_INVOICED_COUNT],
          valueGetter: 'data && data.atStatisticsInvoicedCount',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.INVOICED'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_INVOICED'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PRODUCTION_COUNT],
          valueGetter: 'data && data.atStatisticsProductionCount',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_PRODUCTION'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_PLANNED_COUNT],
          valueGetter: 'data && data.atStatisticsPlannedCount',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED'),
          headerTooltip: lsi.getLSIItem('ANNUAL_TARGET.TOOLTIP.COMMISSION_STATUS_PLANNED'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_NUMBER],
          valueGetter: 'data && data.getATStatisticsDoneCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_DONE_PERCENT_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_DONE_PERCENTAGE],
          valueGetter: (valueGetterParams) => handleDonePercentNumberValueGetter(valueGetterParams, valueGetterParams.data.atApprovedNumber, valueGetterParams.data.getATStatisticsDoneCount()),
          valueFormatter: null,
          aggFunc: handleDonePercentNumberAggFunc,
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.DONE_PERCENT'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_REMAINING_GROUP],
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_REMAINING_NUMBER],
          valueGetter: 'data && data.getATStatisticsRemainsCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.REMAINS'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    },
    {
      ...tableBaseColumnGroupDefs[COLUMN_GROUP_IDS.STATISTICS_POTENTIAL_GROUP],
      headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.POTENTIAL'),
      children: [
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PRODUCTION_COUNT],
          valueGetter: 'data && data.atStatisticsPotentialProductionCount',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PRODUCTION'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_PLANNED_COUNT],
          valueGetter: 'data && data.atStatisticsPotentialPlannedCount',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.PLANNED'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        },
        {
          ...tableBaseColumnDefs[COLUMN_IDS.STATISTICS_POTENTIAL_COUNT],
          valueGetter: 'data && data.getATStatisticsPotentialCount()',
          headerName: lsi.getLSIItem('ANNUAL_TARGET.TABLE_HEADER.TOTAL'),
          type: [COLUMN_TYPE_ENUM.DEFAULT_CD, COLUMN_TYPE_ENUM.DATA_CD]
        }
      ]
    }
  ];
};
