
export const moduleCode = 'PRICE_GUARANTEE';

/**
 * REST API base path
 *
 * @type {string}
 */
export const apiBasePath = '/price-guarantee/web-api';

/**
 * Path prefix for module paths (routes)
 *
 * @type {string}
 */
export const modulePathPrefix = '/price-guarantee';

/**
 * Module name for reducer
 * @type {string}
 */
export const moduleReducerName = 'priceGuarantee';

/**
 * Redux selector for user info.
 *
 * @param state
 * @return UserInfoDTO
 */
export const userInfoSelector = state => state.priceGuarantee.userInfo.userInfo;

/**
 * Redux selector for initialization status.
 *
 * @param state
 * @return {string} of InitializationStatus
 */
export const initializationStatusSelector = state => state.priceGuarantee.initialization.status;