import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../utils/const';

import { getSpecificationKey } from './PriceGuaranteeItemTable.helpers';

export const PGI_GROUP_COLUMN_IDS = {
  [PGISpecificationSnapshotEnum.PREVIOUS]: PGISpecificationSnapshotEnum.PREVIOUS,
  [PGISpecificationSnapshotEnum.CURRENT]: PGISpecificationSnapshotEnum.CURRENT,
  AMOUNT_GROUP: 'AMOUNT_GROUP'
};

export const PGI_READ_ONLY_COLUMN_IDS = {
  PGID: 'pgid',
  MODEL_GROUP: 'modelGroup',
  PUBLISHED: 'published',
  TOTAL_AMOUNT: 'totalAmount'
};

export const PGI_SPECIFICATION_COLUMN_IDS = Object.fromEntries(
  Object.values(PGISpecificationSnapshotEnum)
    .flatMap(snapshot => Object.values(PGISpecificationTypeEnum)
      .map(type => {
        const specificationKey = getSpecificationKey(snapshot, type);
        return [specificationKey, specificationKey]; // entry
      })
    )
);

export const PGI_EDITABLE_COLUMN_IDS = {
  ...PGI_SPECIFICATION_COLUMN_IDS,
  IMPORTER_AMOUNT: 'importerAmount',
  HEADQUARTERS_AMOUNT: 'headquartersAmount',
  SALES_ACCOUNT_TYPE: 'salesAccountType',
  PRODUCTION_DATE_FROM: 'productionDateFrom',
  PRODUCTION_DATE_TO: 'productionDateTo',
  PRICE_VALID_FROM: 'priceValidFrom',
  CHANGE_TYPE: 'changeType',
  CHANGE_DESCRIPTION: 'changeDescription',
  NOTE: 'note'
};

export const PGI_EDITABLE_COLUMN_ID_LIST = Object.values(PGI_EDITABLE_COLUMN_IDS);
