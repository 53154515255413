import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const priceGuaranteeApi = ApiServiceInstances.priceGuarantee.priceGuaranteeApi;

export const CREATE_PRICE_GUARANTEE_REQUEST = 'CREATE_PRICE_GUARANTEE_REQUEST';
export const CREATE_PRICE_GUARANTEE_SUCCESS = 'CREATE_PRICE_GUARANTEE_SUCCESS';
export const CREATE_PRICE_GUARANTEE_FAILURE = 'CREATE_PRICE_GUARANTEE_FAILURE';

export const GET_PRICE_GUARANTEE_LIST_REQUEST = 'GET_PRICE_GUARANTEE_LIST_REQUEST';
export const GET_PRICE_GUARANTEE_LIST_SUCCESS = 'GET_PRICE_GUARANTEE_LIST_SUCCESS';
export const GET_PRICE_GUARANTEE_LIST_FAILURE = 'GET_PRICE_GUARANTEE_LIST_FAILURE';

export const UPDATE_PRICE_GUARANTEE_REQUEST = 'UPDATE_PRICE_GUARANTEE_REQUEST';
export const UPDATE_PRICE_GUARANTEE_SUCCESS = 'UPDATE_PRICE_GUARANTEE_SUCCESS';
export const UPDATE_PRICE_GUARANTEE_FAILURE = 'UPDATE_PRICE_GUARANTEE_FAILURE';

export const SHIFT_PRICE_GUARANTEE_STATUS_REQUEST = 'SHIFT_PRICE_GUARANTEE_STATUS_REQUEST';
export const SHIFT_PRICE_GUARANTEE_STATUS_SUCCESS = 'SHIFT_PRICE_GUARANTEE_STATUS_SUCCESS';
export const SHIFT_PRICE_GUARANTEE_STATUS_FAILURE = 'SHIFT_PRICE_GUARANTEE_STATUS_FAILURE';

const initialState = {
  isFetching: false,
  error: null,
  priceGuaranteeList: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PRICE_GUARANTEE_REQUEST:
    case GET_PRICE_GUARANTEE_LIST_REQUEST:
    case UPDATE_PRICE_GUARANTEE_REQUEST:
    case SHIFT_PRICE_GUARANTEE_STATUS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case CREATE_PRICE_GUARANTEE_SUCCESS:
    case UPDATE_PRICE_GUARANTEE_SUCCESS:
    case SHIFT_PRICE_GUARANTEE_STATUS_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }
    case GET_PRICE_GUARANTEE_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        priceGuaranteeList: action.payload
      };
    }
    case CREATE_PRICE_GUARANTEE_FAILURE:
    case GET_PRICE_GUARANTEE_LIST_FAILURE:
    case UPDATE_PRICE_GUARANTEE_FAILURE:
    case SHIFT_PRICE_GUARANTEE_STATUS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleCreatePriceGuarantee = (priceGuaranteeNewDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeApi.createPriceGuaranteeWithHttpInfo(priceGuaranteeNewDTO),
      types: [CREATE_PRICE_GUARANTEE_REQUEST, CREATE_PRICE_GUARANTEE_SUCCESS, CREATE_PRICE_GUARANTEE_FAILURE]
    }
  };
};

export const handleGetPriceGuaranteeList = () => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeApi.getPriceGuaranteeListWithHttpInfo(),
      types: [GET_PRICE_GUARANTEE_LIST_REQUEST, GET_PRICE_GUARANTEE_LIST_SUCCESS, GET_PRICE_GUARANTEE_LIST_FAILURE]
    }
  };
};

export const handleUpdatePriceGuarantee = (priceGuaranteeUpdateDTO) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeApi.updatePriceGuaranteeWithHttpInfo(priceGuaranteeUpdateDTO.id, priceGuaranteeUpdateDTO),
      types: [UPDATE_PRICE_GUARANTEE_REQUEST, UPDATE_PRICE_GUARANTEE_SUCCESS, UPDATE_PRICE_GUARANTEE_FAILURE]
    }
  };
};

export const handleShiftPriceGuaranteeStatus = (priceGuaranteeId, priceGuaranteeStatusTo) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeApi.shiftPriceGuaranteeStatusWithHttpInfo(priceGuaranteeId, priceGuaranteeStatusTo),
      types: [SHIFT_PRICE_GUARANTEE_STATUS_REQUEST, SHIFT_PRICE_GUARANTEE_STATUS_SUCCESS, SHIFT_PRICE_GUARANTEE_STATUS_FAILURE]
    }
  };
};
