import { PriceGuaranteeItemNewDTOITM, PriceGuaranteeItemUpdateDTOITM } from '@ovex/price-guarantee-web-api';

import { ANY_SINGLE_CHARACTER } from '../../../../../../utils/consts';
import { ITMDeletePriceGuaranteeItemsPFC, ITMUpdatePriceGuaranteeItemsPFC } from '../../../../privilege/context';
import { PGI_SPECIFICATION_SNAPSHOT_LIST, PGI_SPECIFICATION_TYPE_LIST, PGISpecificationTypeEnum, SPECIFICATION_TYPE_MODEL_LIST } from '../../../../utils/const';
import { getSpecificationKey } from '../PriceGuaranteeItemTable.helpers';
import { PGI_EDITABLE_COLUMN_IDS, PGI_READ_ONLY_COLUMN_IDS } from '../PriceGuaranteeItemTable.columnIds';

import PriceGuaranteeItemRow from './PriceGuaranteeItemRow';
import PriceGuaranteeItemEditableCell from './PriceGuaranteeItemEditableCell';
import { dateComparator } from './PriceGuaranteeItemEditableCell.comparators';
import { amountValueParser, dateValueParser, specificationValueParser } from './PriceGuaranteeItemEditableCell.parsers';
import {
  amountValidator,
  PGISpecificationTypeValidators,
  productionDateFromValidator,
  productionDateToValidator,
  salesAccountTypeValidator,
  totalAmountValidator
} from './PriceGuaranteeItemEditableCell.validators';

/**
 * @param {PriceGuaranteeItemDTOITM} dto
 * @return {PriceGuaranteeItemRow|null}
 */
export const constructRowFromDTO = (dto) => {
  if (dto == null) {
    return null;
  }
  const id = dto.id;
  const published = dto.published;
  let rowStatus;
  if (id != null) {
    rowStatus = published ? PriceGuaranteeItemRow.RowStatusEnum.PUBLISHED : PriceGuaranteeItemRow.RowStatusEnum.SAVED;
  }

  const row = new PriceGuaranteeItemRow(rowStatus);

  row.id = id;
  row.priceGuaranteeId = dto.priceGuaranteeId;
  row.pgid = dto.pgid;
  row.modelGroup = dto.modelGroup;
  row.published = published;

  const specificationMap = dto.specificationList.reduce(
    (map, specification) => {
      const specificationKey = getSpecificationKey(specification.snapshot, specification.type);
      map[specificationKey] = specification.value;
      return map;
    },
    {}
  );
  PGI_SPECIFICATION_SNAPSHOT_LIST.forEach(snapshot => {
    PGI_SPECIFICATION_TYPE_LIST.forEach(type => {
      const specificationKey = getSpecificationKey(snapshot, type);
      const value = specificationMap[specificationKey] ?? null;
      const validatorParam = SPECIFICATION_TYPE_MODEL_LIST.includes(type) ? { modelGroup: row.modelGroup } : null;

      row.editableCells[specificationKey] = new PriceGuaranteeItemEditableCell(value)
        .validator(PGISpecificationTypeValidators[type], validatorParam)
        .parser(specificationValueParser);
    });
  });

  row.editableCells[PGI_EDITABLE_COLUMN_IDS.PRODUCTION_DATE_FROM] = new PriceGuaranteeItemEditableCell(dto.productionDateFrom)
    .validator(productionDateFromValidator);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.PRODUCTION_DATE_TO] = new PriceGuaranteeItemEditableCell(dto.productionDateTo)
    .validator(productionDateToValidator);
  row.editableCells[PGI_READ_ONLY_COLUMN_IDS.TOTAL_AMOUNT] = new PriceGuaranteeItemEditableCell(null)
    .nonEditable()
    .valueGetter(row.getTotalAmount)
    .validator(totalAmountValidator);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.IMPORTER_AMOUNT] = new PriceGuaranteeItemEditableCell(dto.importerAmount)
    .validator(amountValidator)
    .parser(amountValueParser);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.HEADQUARTERS_AMOUNT] = new PriceGuaranteeItemEditableCell(dto.headquartersAmount)
    .validator(amountValidator)
    .parser(amountValueParser);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.SALES_ACCOUNT_TYPE] = new PriceGuaranteeItemEditableCell(dto.salesAccountType)
    .validator(salesAccountTypeValidator);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.PRICE_VALID_FROM] = new PriceGuaranteeItemEditableCell(dto.priceValidFrom)
    .booleanComparator(dateComparator)
    .parser(dateValueParser);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.CHANGE_TYPE] = new PriceGuaranteeItemEditableCell(dto.changeType);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.CHANGE_DESCRIPTION] = new PriceGuaranteeItemEditableCell(dto.changeDescription);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.NOTE] = new PriceGuaranteeItemEditableCell(dto.note);

  row.privilegeFunctionalityContext = {
    deletePriceGuaranteeItemsPFC: new ITMDeletePriceGuaranteeItemsPFC(id),
    updatePriceGuaranteeItemsPFC: new ITMUpdatePriceGuaranteeItemsPFC(id)
  };

  return row;
};

export const constructNewRow = (priceGuaranteeId, modelGroup) => {
  const row = new PriceGuaranteeItemRow(PriceGuaranteeItemRow.RowStatusEnum.NEW);

  row.priceGuaranteeId = priceGuaranteeId;
  row.modelGroup = modelGroup;

  PGI_SPECIFICATION_SNAPSHOT_LIST.forEach(snapshot => {
    PGI_SPECIFICATION_TYPE_LIST.forEach(type => {
      const specificationKey = getSpecificationKey(snapshot, type);
      const value = type === PGISpecificationTypeEnum.MODEL ? modelGroup.padEnd(6, ANY_SINGLE_CHARACTER) : null;
      const validatorParam = SPECIFICATION_TYPE_MODEL_LIST.includes(type) ? { modelGroup: row.modelGroup } : null;

      row.editableCells[specificationKey] = new PriceGuaranteeItemEditableCell(value)
        .validator(PGISpecificationTypeValidators[type], validatorParam)
        .parser(specificationValueParser);
    });
  });

  row.editableCells[PGI_EDITABLE_COLUMN_IDS.PRODUCTION_DATE_FROM] = new PriceGuaranteeItemEditableCell(null)
    .validator(productionDateFromValidator);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.PRODUCTION_DATE_TO] = new PriceGuaranteeItemEditableCell(null)
    .validator(productionDateToValidator);
  row.editableCells[PGI_READ_ONLY_COLUMN_IDS.TOTAL_AMOUNT] = new PriceGuaranteeItemEditableCell(null)
    .nonEditable()
    .valueGetter(row.getTotalAmount)
    .validator(totalAmountValidator);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.IMPORTER_AMOUNT] = new PriceGuaranteeItemEditableCell(null)
    .validator(amountValidator)
    .parser(amountValueParser);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.HEADQUARTERS_AMOUNT] = new PriceGuaranteeItemEditableCell(null)
    .validator(amountValidator)
    .parser(amountValueParser);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.SALES_ACCOUNT_TYPE] = new PriceGuaranteeItemEditableCell(null)
    .validator(salesAccountTypeValidator);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.PRICE_VALID_FROM] = new PriceGuaranteeItemEditableCell(null)
    .booleanComparator(dateComparator)
    .parser(dateValueParser);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.CHANGE_TYPE] = new PriceGuaranteeItemEditableCell(null);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.CHANGE_DESCRIPTION] = new PriceGuaranteeItemEditableCell(null);
  row.editableCells[PGI_EDITABLE_COLUMN_IDS.NOTE] = new PriceGuaranteeItemEditableCell(null);

  return row;
};

export const constructVirtualRow = (modelGroup) => {
  const row = new PriceGuaranteeItemRow(PriceGuaranteeItemRow.RowStatusEnum.VIRTUAL);

  row.modelGroup = modelGroup;

  return row;
};

export const constructNewDTOFromRow = (row) => {
  return PriceGuaranteeItemNewDTOITM.constructFromObject({
    priceGuaranteeId: row.priceGuaranteeId,
    modelGroup: row.modelGroup,
    ...prepareDataFromEditableCells(row.editableCells)
  });
};

export const constructUpdateDTOFromRow = (row) => {
  return row && PriceGuaranteeItemUpdateDTOITM.constructFromObject({
    id: row.id,
    ...prepareDataFromEditableCells(row.editableCells)
  });
};

const prepareDataFromEditableCells = (editableCells) => {
  return {
    specificationList: getSpecificationList(editableCells),
    productionDateFrom: editableCells[PGI_EDITABLE_COLUMN_IDS.PRODUCTION_DATE_FROM].getValue(),
    productionDateTo: editableCells[PGI_EDITABLE_COLUMN_IDS.PRODUCTION_DATE_TO].getValue(),
    importerAmount: editableCells[PGI_EDITABLE_COLUMN_IDS.IMPORTER_AMOUNT].getValue(),
    headquartersAmount: editableCells[PGI_EDITABLE_COLUMN_IDS.HEADQUARTERS_AMOUNT].getValue(),
    salesAccountType: editableCells[PGI_EDITABLE_COLUMN_IDS.SALES_ACCOUNT_TYPE].getValue(),
    priceValidFrom: editableCells[PGI_EDITABLE_COLUMN_IDS.PRICE_VALID_FROM].getValue(),
    changeType: editableCells[PGI_EDITABLE_COLUMN_IDS.CHANGE_TYPE].getValue(),
    changeDescription: editableCells[PGI_EDITABLE_COLUMN_IDS.CHANGE_DESCRIPTION].getValue(),
    note: editableCells[PGI_EDITABLE_COLUMN_IDS.NOTE].getValue()
  };
};

const getSpecificationList = (editableCells) => {
  const specificationList = [];
  PGI_SPECIFICATION_SNAPSHOT_LIST.forEach(snapshot => {
    PGI_SPECIFICATION_TYPE_LIST.forEach(type => {
      const value = editableCells[getSpecificationKey(snapshot, type)]?.getValue();
      if (value != null && value !== '') {
        specificationList.push({ snapshot, type, value });
      }
    });
  });
  return specificationList;
};
