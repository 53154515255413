
import store from '../redux/store';

import { INITIAL_STATE } from './consts';

export const openInNewTab = (uri) => {
  const state = store.getState();
  const initialState = {
    // TODO - vyresit zpetnou transformaci ze stringu na object (class) - (redux/store/index)
    main: state.main,

    userInfo: state.pvt.userInfo
  };

  sessionStorage.setItem(INITIAL_STATE, JSON.stringify({} || initialState)); // odebrat '{} ||'

  window.open(uri, '_blank');
};
