import { generatePath } from 'react-router-dom';

const generatePathCommissionList = (basePath, commissionsParamsUuid) => {
  return generatePath(
    basePath,
    { commissionsParamsUuid: commissionsParamsUuid }
  );
};

export default generatePathCommissionList;
