import { CommissionStatusIntervalEnum, PriceGuaranteeTypeEnum } from '../../../../../utils/const';

import OrderTypeViewConst from './orderTypeViewConst';

export const getViewOrderTypePreviousRowCZ = ({ pgType, statusInterval }) => ORDER_TYPE_PREVIOUS_SNAPSHOT_VIEW_MAP[getStatusIntervalGroup(statusInterval)][pgType];

export const getViewOrderTypeCurrentRowCZ = ({ pgType, statusInterval }) => ORDER_TYPE_CURRENT_SNAPSHOT_VIEW_MAP[getStatusIntervalGroup(statusInterval)][pgType];

const StatusIntervalGroup = {
  PLANNED_GROUP: 'PLANNED_GROUP',
  PRODUCTION_INVOICED_SOLD_GROUP: 'PRODUCTION_INVOICED_SOLD_GROUP'
};

const ORDER_TYPE_PREVIOUS_SNAPSHOT_VIEW_MAP = {
  [StatusIntervalGroup.PLANNED_GROUP]: {
    [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK_SHOW]: OrderTypeViewConst.K_V_H,
    [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK]: OrderTypeViewConst.K_V_H,
    [PriceGuaranteeTypeEnum.NOCHECK__STOCK_SHOW]: OrderTypeViewConst.K_V_H,
    [PriceGuaranteeTypeEnum.CHECK__CLIENT]: OrderTypeViewConst.K,
    [PriceGuaranteeTypeEnum.CHECK__SHOW]: OrderTypeViewConst.V,
    [PriceGuaranteeTypeEnum.CHECK__CLIENT_SHOW]: OrderTypeViewConst.K_V
  },
  [StatusIntervalGroup.PRODUCTION_INVOICED_SOLD_GROUP]: {
    [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK_SHOW]: OrderTypeViewConst.K_V_H,
    [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK]: OrderTypeViewConst.K_V_H,
    [PriceGuaranteeTypeEnum.NOCHECK__STOCK_SHOW]: OrderTypeViewConst.K_V_H,
    [PriceGuaranteeTypeEnum.CHECK__CLIENT]: OrderTypeViewConst.K_V_H,
    [PriceGuaranteeTypeEnum.CHECK__SHOW]: OrderTypeViewConst.K_V_H,
    [PriceGuaranteeTypeEnum.CHECK__CLIENT_SHOW]: OrderTypeViewConst.K_V_H
  }
};

const ORDER_TYPE_CURRENT_SNAPSHOT_VIEW_MAP = {
  [StatusIntervalGroup.PLANNED_GROUP]: {
    [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK_SHOW]: OrderTypeViewConst.K_V,
    [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK]: OrderTypeViewConst.K,
    [PriceGuaranteeTypeEnum.NOCHECK__STOCK_SHOW]: OrderTypeViewConst.V,
    [PriceGuaranteeTypeEnum.CHECK__CLIENT]: OrderTypeViewConst.K,
    [PriceGuaranteeTypeEnum.CHECK__SHOW]: OrderTypeViewConst.V,
    [PriceGuaranteeTypeEnum.CHECK__CLIENT_SHOW]: OrderTypeViewConst.K_V
  },
  [StatusIntervalGroup.PRODUCTION_INVOICED_SOLD_GROUP]: {
    [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK_SHOW]: OrderTypeViewConst.K_V,
    [PriceGuaranteeTypeEnum.NOCHECK__CLIENT_STOCK]: OrderTypeViewConst.K_V,
    [PriceGuaranteeTypeEnum.NOCHECK__STOCK_SHOW]: OrderTypeViewConst.K_V,
    [PriceGuaranteeTypeEnum.CHECK__CLIENT]: OrderTypeViewConst.K_V,
    [PriceGuaranteeTypeEnum.CHECK__SHOW]: OrderTypeViewConst.K_V,
    [PriceGuaranteeTypeEnum.CHECK__CLIENT_SHOW]: OrderTypeViewConst.K_V
  }
};

const getStatusIntervalGroup = (statusInterval) => {
  if (statusInterval === CommissionStatusIntervalEnum.PLANNED) {
    return StatusIntervalGroup.PLANNED_GROUP;
  }
  if ([CommissionStatusIntervalEnum.PRODUCTION, CommissionStatusIntervalEnum.INVOICED, CommissionStatusIntervalEnum.SOLD].includes(statusInterval)) {
    return StatusIntervalGroup.PRODUCTION_INVOICED_SOLD_GROUP;
  }
};
