import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';
import PrivilegeCache from '../../../../common/privilege/PrivilegeCache';

const privilegeApi = ApiServiceInstances.privilege.privilegeApi;

export const PRIVILEGED_COMPONENTS_REQUEST = 'SUPPORT__PRIVILEGED_COMPONENTS_REQUEST';
export const PRIVILEGED_COMPONENTS_SUCCESS = 'SUPPORT__PRIVILEGED_COMPONENTS_SUCCESS';
export const PRIVILEGED_COMPONENTS_FAILURE = 'SUPPORT__PRIVILEGED_COMPONENTS_FAILURE';

const initialState = {
  cacheWrapper: { cache: new PrivilegeCache() },
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRIVILEGED_COMPONENTS_REQUEST: {
      const cache = state.cacheWrapper.cache;
      const fnCtxListForEvaluation = action.metaData.fnCtxListForEvaluation;

      fnCtxListForEvaluation.forEach(fc => cache.addPendingFunctionalityContext(fc));

      return state;
    }
    case PRIVILEGED_COMPONENTS_SUCCESS: {
      const cache = state.cacheWrapper.cache;
      const privilegedComponentList = action.payload;
      const fnCtxListWithWebResult = action.metaData.fnCtxListWithWebResult;

      fnCtxListWithWebResult.forEach(fc => cache.addFunctionalityContextWithWebResult(fc));
      privilegedComponentList.forEach(pc => cache.setResultToFunctionalityContext(pc));

      return {
        ...state,
        cacheWrapper: { cache: cache }
      };
    }
    case PRIVILEGED_COMPONENTS_FAILURE: {
      return {
        ...state,
        error: action.payload.error
      };
    }
    default:
      return state;
  }
};

/**
 * @param {Array.<object>} functionalityContextList - objects based on *AbstractFunctionalityContext*
 * @return
 */
export const handlePrivilegedComponents = (functionalityContextList) => {
  const fnCtxListWithWebResult = [];
  const fnCtxListForEvaluation = [];
  functionalityContextList.forEach(fc => {
    if (fc.hasWebResult()) {
      fnCtxListWithWebResult.push(fc);
    } else {
      fnCtxListForEvaluation.push(fc);
    }
  });

  const componentList = fnCtxListForEvaluation.map(po => po.toComponentDTO());
  return {
    [CALL_API]: {
      apiFunction: () => privilegeApi.privilegedComponentsWithHttpInfo(componentList),
      types: [PRIVILEGED_COMPONENTS_REQUEST, PRIVILEGED_COMPONENTS_SUCCESS, PRIVILEGED_COMPONENTS_FAILURE],
      metaData: {
        fnCtxListWithWebResult: fnCtxListWithWebResult,
        fnCtxListForEvaluation: fnCtxListForEvaluation
      }
    }
  };
};
