import AbstractFunctionalityContext from '../../../common/privilege/AbstractFunctionalityContext';
import FunctionalityCodes from '../FunctionalityCodes';

class GetAccountingBasisPFC extends AbstractFunctionalityContext {

  constructor() {
    super();

    this.setFunctionalityCode(FunctionalityCodes.PG_PG__GET_ACCOUNTING_BASIS);
  }

}

export default GetAccountingBasisPFC;