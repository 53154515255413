import {
  PGI__TOTAL_AMOUNT_NOT_EQUAL,
  PGISpecificationTypeEnum,
  SALES_ACCOUNT_TYPE_LIST
} from '../../../../utils/const';

const MODEL_REGEX = /^[A-Z0-9]{2}[A-Z0-9-]{4}(?:[/][A-Z0-9]{2}[A-Z0-9-]{4})*$/;
const COLOUR_REGEX = /^[A-Z0-9-]{4}(?<![-]{4})(?:[/][A-Z0-9-]{4}(?<![-]{4}))*$/;
const INTERIOR_REGEX = /^[A-Z0-9-]{2}(?<![-]{2})(?:[/][A-Z0-9-]{2}(?<![-]{2}))*$/;
const PACKAGE_REGEX = /^[A-Z0-9]{3}([+/][A-Z0-9]{3})*$/;

const MODEL_GROUP_LENGTH_2 = 2;
const MODEL_GROUP_LENGTH_3 = 3;
const STRING_REGEX_MODEL_GROUP_PARAM = 'MODEL_GROUP';
const MODEL_2_STRING_REGEX = `^${STRING_REGEX_MODEL_GROUP_PARAM}[A-Z0-9-]{4}(?:[/]${STRING_REGEX_MODEL_GROUP_PARAM}[A-Z0-9-]{4})*$`;
const MODEL_3_STRING_REGEX = `^${STRING_REGEX_MODEL_GROUP_PARAM}[A-Z0-9-]{3}(?:[/]${STRING_REGEX_MODEL_GROUP_PARAM}[A-Z0-9-]{3})*$`;

const MBV_DATE_REGEX = /^[0-9]{2}(?:[0][1-9]|[1-4][0-9]|[5][0-3])[1-7]$/;

const NUMBER_REGEX = /^[-]?[0-9]{0,10}[.,]?[0-9]{1,2}$/;

const test = (value, regexp) => regexp.test(value);
const testNullable = (value, regexp) => value == null || value === '' || regexp.test(value);

const modelRegexpByParams = (validatorParams) => {
  const modelGroup = validatorParams?.modelGroup;
  if (modelGroup?.length === MODEL_GROUP_LENGTH_2) {
    return new RegExp(MODEL_2_STRING_REGEX.replaceAll(STRING_REGEX_MODEL_GROUP_PARAM, modelGroup));
  } else if (modelGroup?.length === MODEL_GROUP_LENGTH_3) {
    return new RegExp(MODEL_3_STRING_REGEX.replaceAll(STRING_REGEX_MODEL_GROUP_PARAM, modelGroup));
  }
  return MODEL_REGEX;
};

export const PGISpecificationTypeValidators = {
  [PGISpecificationTypeEnum.MODEL]: (value, validatorParams) => test(value, modelRegexpByParams(validatorParams)),
  [PGISpecificationTypeEnum.MODEL_NEG]: (value, validatorParams) => testNullable(value, modelRegexpByParams(validatorParams)),
  [PGISpecificationTypeEnum.COLOUR]: (value) => testNullable(value, COLOUR_REGEX),
  [PGISpecificationTypeEnum.COLOUR_NEG]: (value) => testNullable(value, COLOUR_REGEX),
  [PGISpecificationTypeEnum.INTERIOR]: (value) => testNullable(value, INTERIOR_REGEX),
  [PGISpecificationTypeEnum.INTERIOR_NEG]: (value) => testNullable(value, INTERIOR_REGEX),
  [PGISpecificationTypeEnum.PACKAGE]: (value) => testNullable(value, PACKAGE_REGEX),
  [PGISpecificationTypeEnum.PACKAGE_NEG]: (value) => testNullable(value, PACKAGE_REGEX)
};

export const salesAccountTypeValidator = (value) => SALES_ACCOUNT_TYPE_LIST.includes(value);
export const productionDateFromValidator = (value) => test(value, MBV_DATE_REGEX);
export const productionDateToValidator = (value) => testNullable(value, MBV_DATE_REGEX);
export const amountValidator = (value) => test(value, NUMBER_REGEX);

export const totalAmountValidator = (value) => {
  return value == null || value !== PGI__TOTAL_AMOUNT_NOT_EQUAL;
};
