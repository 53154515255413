import React from 'react';
import PropTypes from 'prop-types';
import { PriceGuaranteeDTOPGR } from '@ovex/price-guarantee-web-api';

import { FilterForm, Select } from '../../../../common/components/forms';
import { LsiContext } from '../../../../common/contexts';
import Code from '../../../../common/components/forms/Code/Code';
import { equalObjectProperty } from '../../../../common/utils/helpers';
import PriceGuaranteeReportPageType from '../PriceGuaranteeReportPageType';
import PriceGuaranteeReportPageTypeContext from '../PriceGuaranteeReportPageTypeContext';

import { InputNames, getDefaultValues, convertFilterValues } from './PriceGuaranteeReportFilter.helpers';

const propTypes = {
  claimTypeHeaderList: PropTypes.arrayOf(PropTypes.string),
  onLoadPriceGuaranteeReport: PropTypes.func.isRequired,
  onResetPriceGuaranteeReport: PropTypes.func.isRequired,
  priceGuaranteeList: PropTypes.arrayOf(PropTypes.instanceOf(PriceGuaranteeDTOPGR))
};

const defaultProps = {
  claimTypeHeaderList: null,
  commissionNumber: null,
  marketingActionCode: null,
  priceGuaranteeList: null
};

const PriceGuaranteeReportFilter = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const pageType = React.useContext(PriceGuaranteeReportPageTypeContext);

  const {
    claimTypeHeaderList, priceGuaranteeList,
    onLoadPriceGuaranteeReport, onResetPriceGuaranteeReport
  } = props;

  const [valuesFilterSubmitted, setValuesFilterSubmitted] = React.useState(getDefaultValues(pageType));
  const [values, setValues] = React.useState(getDefaultValues(pageType));

  const handleSubmit = React.useCallback(
    (filterValues) => {
      setValuesFilterSubmitted(filterValues);
      return onLoadPriceGuaranteeReport(convertFilterValues(filterValues, pageType));
    },
    [onLoadPriceGuaranteeReport, pageType]
  );

  const handleReset = React.useCallback(
    () => {
      setValues(getDefaultValues(pageType));
      return onResetPriceGuaranteeReport();
    },
    [onResetPriceGuaranteeReport, pageType]
  );

  const maSelectItems = React.useMemo(
    () => {
      return priceGuaranteeList?.map(pg => ({ value: pg.id.toString(), content: pg.marketingActionCode })) || [];
    },
    [priceGuaranteeList]
  );

  const cthSelectItems = React.useMemo(
    () => {
      return claimTypeHeaderList?.map(ct => ({ value: ct, content: lsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.CLAIM_TYPE_HEADER.' + ct) })) || [];
    },
    [claimTypeHeaderList, lsi]
  );

  const handleChangeInput = React.useCallback(
    (opt) => {
      opt.component.onChangeDefault(opt, () => {
        const name = opt.component.props.name;
        const valueNew = opt.component.getValue();

        setValues((prevValues) => ({ ...prevValues, [name]: valueNew }));
      });
    },
    []
  );

  return (
    <FilterForm
      className='ovex-pg-PriceGuaranteeReportFilter'
      onReset={handleReset}
      onSubmit={handleSubmit}
    >
      <Select
        items={maSelectItems}
        label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.PRICE_GUARANTEE')}
        multiple={PriceGuaranteeReportPageType.isDealer(pageType)}
        name={InputNames.priceGuaranteeIdList}
        onChange={handleChangeInput}
        required
        value={values[InputNames.priceGuaranteeIdList]}
        valueChanged={!equalObjectProperty(values, valuesFilterSubmitted, InputNames.priceGuaranteeIdList)}
        width='260px'
      />
      <Select
        items={cthSelectItems}
        label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.CLAIM_TYPE_HEADER')}
        multiple
        name={InputNames.claimTypeHeaderList}
        onChange={handleChangeInput}
        tooltip={lsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.TYPE_OF_COMMISSION_SELECT')}
        value={values[InputNames.claimTypeHeaderList]}
        valueChanged={!equalObjectProperty(values, valuesFilterSubmitted, InputNames.claimTypeHeaderList)}
        width='260px'
      />
      { PriceGuaranteeReportPageType.isImporterVariant(pageType) &&
        <Code
          label={lsi.getLSIItem('PRICE_GUARANTEE.FORM_INPUT_LABEL.DEALER')}
          maxLength={5}
          name={InputNames.dealerNumber}
          onChangeTMP={handleChangeInput}
          pattern='^$|^[0-9]{4}[0-9A-Za-z]$'
          upperCase
          value={values[InputNames.dealerNumber]}
          valueChanged={!equalObjectProperty(values, valuesFilterSubmitted, InputNames.dealerNumber)}
          width='160px'
        />
      }
    </FilterForm>
  );
});

PriceGuaranteeReportFilter.propTypes = propTypes;
PriceGuaranteeReportFilter.defaultProps = defaultProps;

export default PriceGuaranteeReportFilter;
