import React from 'react';
import PropTypes from 'prop-types';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { FilterWrapper } from '../index';

import FilterOptionsBuilder from './FilterOptionsBuilder';

const propTypes = {
  children: PropTypes.oneOfType([ PropTypes.element, PropTypes.arrayOf(PropTypes.element) ]).isRequired,
  className: PropTypes.string,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

const defaultProps = {
  className: null,
  onReset: undefined
};

const FilterForm = (props) => {

  const classNames = ['ovex-FilterForm'];
  props.className && classNames.push(props.className);

  const handleSave = ({ values }) => {
    props.onSubmit(values);
  };
  const handleFormReset = () => {
    props.onReset && props.onReset();
  };

  return (
    <Forms.ContextForm
      className={classNames.join(' ')}
      onReset={handleFormReset}
      onSave={handleSave}
    >
      <FilterWrapper>
        {props.children}
      </FilterWrapper>
    </Forms.ContextForm>
  );
};

FilterForm.propTypes = propTypes;
FilterForm.defaultProps = defaultProps;

FilterForm.FilterOptionsBuilder = FilterOptionsBuilder;

export default FilterForm;
