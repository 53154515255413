import React from 'react';
import PropTypes from 'prop-types';
import { Forms } from 'uu5g04';
import 'uu5g04/forms';

import { TextArea } from '../../../../../common/components/forms';
import { LsiContext } from '../../../../../common/contexts';
import { TextBlockEnum } from '../../../../utils/const';

const TextItemPropType = PropTypes.shape({
  block: PropTypes.oneOf(Object.values(TextBlockEnum)).isRequired,
  text: PropTypes.string
});

const propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  textMap: PropTypes.shape({
    [TextBlockEnum.N01]: TextItemPropType,
    [TextBlockEnum.N02]: TextItemPropType
  })
};

const defaultProps = {
  className: null,
  onSave: null,
  textMap: null
};

const YearSettingsTexts = React.memo(React.forwardRef((props, ref) => {
  const lsi = React.useContext(LsiContext);

  const readOnly = !props.onSave;

  const classNames = ['ovex-YearSettingsTexts'];
  props.className && classNames.push(props.className);

  const formRef = React.useRef(null);
  const handleGetFormRef = React.useCallback(
    (uuFormRef) => {
      formRef.current = uuFormRef;
    },
    []
  );

  React.useImperativeHandle(
    ref,
    () => ({
      isValid: () => formRef.current.isValid(),
      save: () => formRef.current.save()
    })
  );

  const onSave = props.onSave;
  const handleSave = React.useCallback(
    ({ values }) => {
      const result = {
        textMap: {}
      };

      Object.values(TextBlockEnum).forEach(block => {
        result.textMap[block] = {
          block: block,
          text: values[block]
        };
      });

      onSave && onSave({ formId: props.formId, values: result });
    },
    [onSave, props.formId]
  );

  const textMap = props.textMap;

  return (
    <Forms.Form
      className={classNames.join(' ')}
      onSave={handleSave}
      ref_={handleGetFormRef}
    >
      <TextArea
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.BASE_AGREEMENT')}
        maxLength={1000} // DB tables [at_year_text, contract_text, negotiation_text].text column char limit
        name={TextBlockEnum.N01}
        readOnly={readOnly}
        required
        value={textMap && textMap[TextBlockEnum.N01] && textMap[TextBlockEnum.N01].text}
      />
      <TextArea
        label={lsi.getLSIItem('ANNUAL_TARGET.FORM_INPUT_LABEL.ADDITIONAL_AGREEMENT')}
        maxLength={1000} // DB tables [at_year_text, contract_text, negotiation_text].text column char limit
        name={TextBlockEnum.N02}
        readOnly={readOnly}
        required
        value={textMap && textMap[TextBlockEnum.N02] && textMap[TextBlockEnum.N02].text}
      />
    </Forms.Form>
  );
}));

YearSettingsTexts.propTypes = propTypes;
YearSettingsTexts.defaultProps = defaultProps;

export default YearSettingsTexts;
