import { mdiClose, mdiMinus, mdiPlus } from '@mdi/js';

import { MenuItemDefBuilder } from '../../../../common/components/ag-grid';
import { getCellData } from '../../../../common/components/ag-grid/OvexAGTable';
import { getExportExcelSkipRowGroupsItem } from '../../../../common/components/ag-grid/contextMenu/excelExport';
import { exportExcel } from './RestrictionsTableData.exportParams';

import RestrictionTypeEnum from './rowModel/RestrictionRowTypeEnum';
import { isWeekColumn } from './restrictionUtils';

export const getContextMenuEditMode = (params) => {
  if (isWeekColumn(params.column.colId)) {
    return [
      RestrictionTableContextMenuItems.getUndoUpdateRestrictionWeekItem(params),
      'separator',
      'copy',
      'copyWithHeaders'
    ];
  } else {
    if (RestrictionTypeEnum.isModelGroup(params.node.data.type)) {
      return [
        RestrictionTableContextMenuItems.getAddRestrictionItem(params)
      ];
    } else if (RestrictionTypeEnum.isRestriction(params.node.data.type)) {
      return [
        RestrictionTableContextMenuItems.getAddRestrictionItem(params),
        RestrictionTableContextMenuItems.getDeleteRestrictionItem(params),
        'separator',
        RestrictionTableContextMenuItems.getUndoDeleteRestrictionItem(params)
      ];
    }
  }
  return [];
};

export const getContextMenuReadOnlyMode = (params) => {
  return [
    'copy',
    'copyWithHeaders',
    'separator',
    getExportExcelSkipRowGroupsItem(params, exportExcel)
  ];
};

const RestrictionTableContextMenuItems = {
  getAddRestrictionItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.ADD_RESTRICTION')
      .iconFromPath(mdiPlus)
      .action(() => {
        params.context.onShowAddForm(params.node.data.modelGroup);
      })
      .build();
  },
  getDeleteRestrictionItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.DELETE_RESTRICTION')
      .iconFromPath(mdiMinus)
      .disabled(params.node.data.deletedInFuture)
      .action(() => {
        const data = params.node.data;
        if (data.isNew()) {
          params.api.applyTransaction({ remove: [data] });
        } else {
          data.setDeleteFlag();
          params.api.applyTransaction({ update: [data] });
        }
      })
      .build();
  },
  getUndoDeleteRestrictionItem: (params) => {
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.UNDO_DELETE_RESTRICTION')
      .iconFromPath(mdiClose)
      .disabled(!params.node.data.deletedInFuture)
      .action(() => {
        const data = params.node.data;
        data.undoDeleteFlag();

        params.api.applyTransaction({ update: [data] });
      })
      .build();
  },
  getUndoUpdateRestrictionWeekItem: (params) => {
    const cellData = getCellData(params);
    return new MenuItemDefBuilder(params, 'PVT.CONTEXT_MENU.UNDO_EDIT')
      .iconFromPath(mdiClose)
      .disabled(!!cellData ? (!cellData.isChanged() ): true)
      .action(() => {
        cellData && cellData.undoUpdateNumberTotal();
        const data = params.node.data;
        params.api.applyTransaction({ update: [data] });
      })
      .build();
  }
};
