import { LOCATION_CHANGE } from 'connected-react-router';

import { ApiServiceInstances } from '../../../api';
import { CALL_API } from '../../../../../redux/middleware';

const priceGuaranteeRecoveryApi = ApiServiceInstances.priceGuarantee.priceGuaranteeRefreshApi;

export const REFRESH_UNIVERSE_STORAGE_REQUEST = 'RECOVER_RECOVERY_TYPE_REQUEST';
export const REFRESH_UNIVERSE_STORAGE_SUCCESS = 'RECOVER_RECOVERY_TYPE_SUCCESS';
export const REFRESH_UNIVERSE_STORAGE_FAILURE = 'RECOVER_RECOVERY_TYPE_FAILURE';

export const REFRESH_PG_VERSION_STORAGE_REQUEST = 'RECOVER_RECOVERY_TYPE_REQUEST';
export const REFRESH_PG_VERSION_STORAGE_SUCCESS = 'RECOVER_RECOVERY_TYPE_SUCCESS';
export const REFRESH_PG_VERSION_STORAGE_FAILURE = 'RECOVER_RECOVERY_TYPE_FAILURE';

const initialState = {
  isFetching: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_UNIVERSE_STORAGE_REQUEST:
    case REFRESH_PG_VERSION_STORAGE_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case REFRESH_UNIVERSE_STORAGE_SUCCESS:
    case REFRESH_PG_VERSION_STORAGE_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }
    case REFRESH_UNIVERSE_STORAGE_FAILURE:
    case REFRESH_PG_VERSION_STORAGE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false
      };
    }
    case LOCATION_CHANGE: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export const handleRefreshPGVersionStorageData = (recoveryType) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeRecoveryApi.refreshPGVersionStorageDataWithHttpInfo(recoveryType),
      types: [REFRESH_PG_VERSION_STORAGE_REQUEST, REFRESH_PG_VERSION_STORAGE_SUCCESS, REFRESH_PG_VERSION_STORAGE_FAILURE]
    }
  };
};

export const handleRefreshUniverseStorageData = (recoveryType) => {
  return {
    [CALL_API]: {
      apiFunction: () => priceGuaranteeRecoveryApi.refreshUniverseStorageDataWithHttpInfo(recoveryType),
      types: [REFRESH_UNIVERSE_STORAGE_REQUEST, REFRESH_UNIVERSE_STORAGE_SUCCESS, REFRESH_UNIVERSE_STORAGE_FAILURE]
    }
  };
};
