import { PGISpecificationSnapshotEnum, PGISpecificationTypeEnum } from '../../../../../../utils/const';
import AbstractSpecColumnUIDefinition from '../AbstractSpecColumnUIDefinition';
import ColumnUIDefinitionCodes from '../ColumnUIDefinitionCodes';

class SpecCurrentColourCUID extends AbstractSpecColumnUIDefinition {

  /** @override */
  getCode = () => {
    return ColumnUIDefinitionCodes.SPEC__CURRENT__COLOUR;
  }
  /** @override */
  getSpecificationSnapshot = () => {
    return PGISpecificationSnapshotEnum.CURRENT;
  }
  /** @override */
  getSpecificationType = () => {
    return PGISpecificationTypeEnum.COLOUR;
  }
  /**
   * @override
   * @param {CommissionSpecificationDTOVLD} commission
   */
  commissionConverterValueGetter = (commission) => {
    return commission.commissionCurrentData.carSpecification.colour;
  }

}

const specCurrentColourCUID = new SpecCurrentColourCUID();

export default specCurrentColourCUID;