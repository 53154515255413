import { dateFormatter } from '../../../../../../../common/utils/formatters';
import AbstractRowUIDefinition from '../AbstractRowUIDefinition';
import RowUIDefinitionCodes from '../RowUIDefinitionCodes';

class CreationDateRUID extends AbstractRowUIDefinition {

  /** @override */
  getCode = () => {
    return RowUIDefinitionCodes.CREATION_DATE__ROW;
  }
  /** @override */
  conditionTypeValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.ENUM_LABELS.VALIDATION_RESULT_HEADER_ROW_TYPE.COMMISSION_CREATION_DATE_TO');
  }
  /** @override */
  conditionTypeTooltipValueGetter = ({ value, context }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.TOOLTIP.CREATE_COMMISSION');
  }
  /** @override */
  requiredStateValueGetter = ({ data, context }) => {
    const { priceGuarantee } = context;

    return priceGuarantee.commissionCreationDateTo;
  }
  /** @override */
  requiredStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;
    return agLsi.getLSIItem('PRICE_GUARANTEE.LABEL.MAX_VALUE', dateFormatter(value, agLsi.getFormatters()));
  }
  /** @override */
  currentStateValueGetter = ({ data, context }) => {
    const { commission } = context;

    return commission.commissionCurrentData.creationDate;
  }
  /** @override */
  currentStateValueFormatter = ({ data, context, value }) => {
    const { agLsi } = context;

    return dateFormatter(value, agLsi.getFormatters());
  }
}

const creationDateRUID = new CreationDateRUID();

export default creationDateRUID;