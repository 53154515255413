export const findQuotas = (restrictionList) => {
  return restrictionList.filter(item => item.restrictionType === 'Q');
};
export const findQuotaByModelGroup = (filteredList, modelGroup) => {
  return filteredList.find(item => item.modelGroup === modelGroup);
};
export const countModelGroupWithRestrictions = (restrictionList) => {
  return restrictionList.reduce((acc, item) => {
    if (item.restrictionType === 'S' || item.restrictionType === 'T') {
      acc[item.modelGroup] = (acc[item.modelGroup] || 0) + 1;
    }
    return acc;
  }, {});
};
